import React from 'react';

import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { OrdersTable } from '@backed-fi/admin/src/app/domain/Brokerage/pages/Brokerage/Orders/components/OrdersTable';

// region Graph

gql`
  query interactionDetailsOrders($interactionId: String!) {
    interaction(
      where: {
        id: $interactionId
      }
    ) {
      id

      orders {
        id

        totalValue
        totalShares
        pricePerShare

        collateral {
          symbol
          priceCurrency
        }

        side
        status
      }
    }
  }
`;

// endregion

export const InteractionOrdersPage: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  return (
    <Box>
      <OrdersTable
        hideFilters
        disablePagination
        interactionId={params.interactionId}
      />
    </Box>
  );
};
