import { Box, Drawer, IconButton } from '@mui/material';
import { Logo } from '@backed-fi/primitives';
import React from 'react';
import { AdminNavigation } from '@backed-fi/admin/src/app/layouts/AdminNavigation';
import { useToggle } from '@backed-fi/hooks';
import MenuIcon from '@mui/icons-material/MenuRounded';
import { EnvironmentLabel } from '@backed-fi/admin/src/app/components/EnvironmentLabel';

export const AdminMobileHeader = () => {
  const drawer = useToggle();

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100vw',
          backgroundColor: 'utility.elementBackground',
          borderBottom: '1px solid',
          borderColor: 'utility.borderSubtle',

          padding: '1rem 2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <IconButton onClick={drawer.setTrue}>
            <MenuIcon
              sx={{
                color: 'utility.textLowContrast'
              }}
            />
          </IconButton>

          <Logo full />
        </Box>

        <Box>
          {/* <EnvironmentLabel /> */}
        </Box>
      </Box>

      <Drawer
        anchor='left'
        {...drawer}
      >
        <AdminNavigation
          inDrawer
          onNavigate={drawer.setFalse}
        />
      </Drawer>
    </React.Fragment>
  );
};
