import React from 'react';
import { z } from 'zod';
import { ethers } from 'ethers';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@mui/material';

import { customBlockchainAddressValidator, registerField } from '@backed-fi/shared';
import {
  BlockchainNetwork,
  FlowType
} from '@backed-fi/graphql';

import { FlowNetwork } from '../shared/FlowNetwork';
import { MintFlowReceipient } from './MintFlowReceipient';
import { FlowInformation } from '../shared/FlowInformation';
import { MintFlowAmount } from './MintFlowAmount';

interface Props {
  previous?: MintFlowSchema;

  token: {
    id: string;
    name: string;
    symbol: string;
    icon?: string | null;
    collateral: {
      ISINNumber?: string | null;
    };
    deployments: {
      id: string;
      network: BlockchainNetwork;
    }[];
  };

  blockchainAddresses: {
    id: string;
    description: string;
    address: string;
  }[];

  onSetup: (setup: MintFlowSchema) => void;
}

const schema = z.object({
  deploymentId: z.string(),

  recipientWallet: customBlockchainAddressValidator('Desination wallet is required', 'Destination wallet format is not valid'),

  amount: z
    .string()
    .refine((val) => parseInt(val) > 0, {
      message: 'Value must be positive integer'
    })
    .transform((amount) => {
      return ethers.utils.parseEther(amount || '0').toString();
    })
});


export type MintFlowSchema = z.infer<typeof schema>;

export const MintFlowSetup: React.FC<Props> = ({
  token,
  blockchainAddresses,
  previous,
  onSetup
}) => {
  const form = useForm<MintFlowSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      deploymentId: previous?.deploymentId || token.deployments[0].id,
      ...(previous && {
        ...previous,
        amount: ethers.utils.formatEther(previous.amount)
      })
    }
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    onSetup(data);
  });

  return (
    <>
      <Typography
        variant="subtitleSmall"
        sx={{
          marginTop: '12px'
        }}
      >
        Tokens can be minted to any wallet that you have previously added to
        your address book, or you can enter a new wallet address below.
      </Typography>

      <FlowInformation
        flowType={FlowType.Mint}
        token={token}
      />

      <MintFlowAmount
        symbol={token.symbol}
        inputProps={registerField(form, 'amount')}
      />
      <FlowNetwork
        selected={form.getValues('deploymentId')}
        networks={token.deployments}
        onSelect={(id) => form.setValue('deploymentId', id)}
      />
      <MintFlowReceipient
        selected={form.getValues('recipientWallet')}
        addresses={blockchainAddresses}
        inputProps={registerField(form, 'recipientWallet')}
        onSelect={(address) => form.setValue('recipientWallet', address)}
      />

      <Box
        sx={{
          pt: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          onClick={async () => {
            if (await form.trigger()) {
              handleSubmit();
            }
          }}
        >
          Review Operation
        </Button>
      </Box>
    </>
  );
};
