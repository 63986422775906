import { gql } from '@apollo/client';

import { CommentsQuery, ComplianceCommentsWhereInput, useCommentsQuery } from '@backed-fi/graphql';
import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { CreateCommentField } from './CreateCommentField';
import formatRelative from 'date-fns/formatRelative';
import { Title } from '@backed-fi/compound';

const Graph = gql`
  query comments($where: ComplianceCommentsWhereInput, $pageSize: Int = 10) {
    complianceComments(
      where: $where,
      pageSize: $pageSize
    ) {
      id
      createdAt

      title
      comment

      admin {
        id

        firstName
        lastName
      }
    }
    }
`;

interface Props extends ComplianceCommentsWhereInput {
  maxComments?: number;

  /**
   * The maximum height that the comments box will take. Does not include the
   * text field for adding new comments. Additional comments are accessible with
   * scroll.
   */
  maxHeight?: string;
}

export const Comments: React.FC<Props> = ({
  maxComments,
  maxHeight,
  ...where
}) => {
  const {
    data,
    loading
  } = useCommentsQuery({
    variables: {
      where,
      pageSize: maxComments
    }
  });

  // ---- Destructuring ---- //
  const { complianceComments } = (data || {}) as CommentsQuery;


  return (
    <Box
      sx={({ palette }) => ({
        width: '100%',

        '& > *:not(:nth-last-child(-n+2))': {
          paddingBottom: '1rem',
          borderBottom: `1px dashed ${palette.utility.borderElement}`
        }
      })}
    >
      <Box
        sx={{
          ...(maxHeight && ({
            maxHeight,
            display: 'flex',
            overflow: 'scroll',
            flexDirection: 'column-reverse'
          }))
        }}
      >
        {complianceComments?.length ? (
          complianceComments.map(({
            admin,
            ...comment
          }) => (
            <Box
              id={comment.id}
              key={comment.id}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  my: '1rem'
                }}
              >
                <Avatar>
                  {admin.firstName[0]}{admin.lastName[0]}
                </Avatar>

                <Title
                  small
                  boxed
                  title={`${admin.firstName} ${admin.lastName}`}
                  subtitle={formatRelative(new Date(comment.createdAt), new Date())}
                />
              </Box>

              <Typography>
                {comment.comment}
              </Typography>
            </Box>
          ))
        ) : (
          <Box>
            {loading ? 'Loading' : 'No comments yet'}
          </Box>
        )}

      </Box>

      <Box
        sx={{
          marginTop: '1rem'
        }}
      >
        <CreateCommentField
          refreshQueries={[
            'comments'
          ]}

          {...where}
        />
      </Box>
    </Box>
  );
};
