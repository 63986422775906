import React from 'react';
import { Route } from 'react-router-dom';

import { DeploymentsHomePage } from '@backed-fi/admin/src/app/domain/Deployment/DeploymentsHomePage';
import { DeploymentCreatePage } from '@backed-fi/admin/src/app/domain/Deployment/DeploymentCreatePage';
import { DeploymentDetailsPage } from '@backed-fi/admin/src/app/domain/Deployment/DeploymentDetailsPage';

export const DeploymentPages = (
  <Route path='deployments'>
    <Route
      index
      element={
        <DeploymentsHomePage />
      }
    />

    <Route
      path='create'
      element={
        <DeploymentCreatePage />
      }
    />

    <Route
      path='details/:id'
      element={
        <DeploymentDetailsPage />
      }
    />
  </Route>
);
