import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

import {
  DeploymentDetailsQuery,
  DeploymentStatus,
  useDeploymentDetailsQuery,
  useTokenListLazyQuery,
} from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

type ComponentProps = {
  deploymentId: string;
  symbol: string;
};

const CreateTokenLoading: React.FC<ComponentProps> = ({
  deploymentId,
  symbol,
}) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { data } = useDeploymentDetailsQuery({
    pollInterval: 3_000,
    variables: {
      id: deploymentId,
    },
  });
  const [, { refetch }] = useTokenListLazyQuery({});

  const { deployment } = (data || {}) as DeploymentDetailsQuery;

  React.useEffect(() => {
    if (data) {
      const status = data.deployment.status;

      if (
        status === DeploymentStatus.Failed ||
        status === DeploymentStatus.FailedPartially
      ) {
        snackbar.enqueueSnackbar('Deployment has failed', { variant: 'error' });
        navigate('/blockchain/tokens/all');
      } else if (status === DeploymentStatus.Completed) {
        navigate(
          `/blockchain/tokens/details/${data.deployment.token.id}/overview`
        );
        refetch();
      }
    }
  }, [data?.deployment.status]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4rem',
      }}
    >
      <Typography fontSize={28} fontWeight={600}>
        Creating {symbol} Token
      </Typography>
      <Typography fontSize={14} sx={{ color: '#747B89' }}>
        Deployment in progress, please wait...
      </Typography>
      <CircularProgress sx={{ marginTop: '2rem' }} />
    </Box>
  );
};

export default CreateTokenLoading;
