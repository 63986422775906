import React, { useState } from 'react';
import { z } from 'zod';
import { ethers } from 'ethers';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@mui/material';

import { registerField } from '@backed-fi/shared';
import {
  BlockchainNetwork,
  FlowType
} from '@backed-fi/graphql';
import { FlowNetwork } from '../shared/FlowNetwork';
import { FlowInformation } from '../shared/FlowInformation';
import { BurnFlowAmount } from './BurnFlowAmount';

interface Props {
  previous?: BurnFlowSchema;

  token: {
    id: string;
    name: string;
    symbol: string;
    icon?: string | null;
    collateral: {
      ISINNumber?: string | null;
    };
    deployments: {
      id: string;
      network: BlockchainNetwork;
      burnAddress: string;
      burnSupply: string;
    }[];
  };

  onSetup: (setup: BurnFlowSchema) => void;
}

const schema = (supply: string) => z.object({
  deploymentId: z.string(),

  amount: z
    .string()
    .refine((val) => parseInt(val) > 0, {
      message: 'Value must be positive integer'
    })
    .refine((val) => ethers.utils.parseEther(val || '0').lte(supply), {
      message: 'Value cannot exceed burn balance'
    })
    .transform((amount) => {
      return ethers.utils.parseEther(amount || '0').toString();
    })
});

export type BurnFlowSchema = z.infer<ReturnType<typeof schema>>;

export const BurnFlowSetup: React.FC<Props> = ({
  token,
  previous,
  onSetup
}) => {
  const [burnData, setBurnData] = useState<{
    burnAddress: string;
    burnSupply: string;
  }>();

  const form = useForm<BurnFlowSchema>({

    resolver: zodResolver(schema(burnData?.burnSupply || '0')),
    defaultValues: {
      deploymentId: previous?.deploymentId ?? token.deployments[0].id,
      ...(previous && {
        amount: ethers.utils.formatEther(previous.amount)
      })
    }
  });

  const onNetworkChange = (id: string) => {
    const deployment = token.deployments.find(
      (d) => d.id === id
    );

    if (deployment) {
      form.setValue('deploymentId', deployment.id);

      setBurnData({
        burnAddress: deployment.burnAddress,
        burnSupply: deployment.burnSupply
      });
    }
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    onSetup(data);
  });

  return (
    <>
      <Typography
        variant="subtitleSmall"
        sx={{
          marginTop: '12px'
        }}
      >
        Only tokens in the burn-wallet can be burned. In order to proceed with a
        burning operation, send the amount of {token.symbol} to be burned to the
        burn-wallet.
      </Typography>

      <FlowInformation
        flowType={FlowType.Burn}
        token={token}
      />

      <FlowNetwork
        selected={form.getValues('deploymentId')}
        networks={token.deployments}
        onSelect={(id) => onNetworkChange(id)}
      />

      <BurnFlowAmount
        {...burnData!}
        symbol={token.symbol}
        inputProps={registerField(form, 'amount')}
      />

      <Box
        sx={{
          pt: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          onClick={async () => {
            if (await form.trigger()) {
              handleSubmit();
            }
          }}
        >
          Review Operation
        </Button>
      </Box>
    </>
  );
};
