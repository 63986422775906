import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid/themeAugmentation';

export const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    hideFooter: true,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableDensitySelector: true
  },
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: '#121419',
        border: 'none',
        backgroundColor: '#fff !important',
        boxShadow: '0 0.25rem 0.75rem #12141912',

        '.MuiDataGrid-row:hover': {
          backgroundColor: '#F2F4F880'
        },

        '.MuiDataGrid-cell': {
          padding: '0 1.5rem'
        },

        '.MuiDataGrid-columnHeader': {
          padding: '0 1.5rem'
        },

        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600
        }
      };
    },

    columnSeparator: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.borderHovered,
        borderColor: utility.borderHovered
      };
    },

    withBorderColor: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        borderColor: '#E2E5EB'
      };
    },

    footerContainer: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: '#121419'

        // svg: {
        //   color: utility.textHighContrast
        // }
      };
    },

    overlay: {
      background: 'unset'
    }
  }
};
