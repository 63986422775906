import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useClientFileLazyQuery, useClientFilesQuery } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { TreeItem, TreeView } from '@mui/lab';

import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';

const Graph = gql`
  query ClientFiles($clientId: ID!) {
    client(clientId: $clientId) {
      id

      files
    }
  }

  query ClientFile(
    $path: String!,
    $clientId: String!
  ) {
    file(
      path: $path,
      clientId: $clientId
    ) {
      path
      authorisedUrl
    }
  }
`;

// region Helpers

interface Node {
  label: string;
  path: string;
  children?: Record<string, Node>;
}

const convertToTreeData = (paths: string[]): Node => {
  const treeData: Node = {
    label: 'Root',
    path: '',
    children: {}
  };

  paths.forEach(path => {
    const pathArr = path.split('/')
      // Remove the client path
      .slice(3, -1);

    let node = treeData;
    pathArr.forEach((dir, index) => {
      const path = pathArr.slice(0, index + 1).join('/');
      if (!node.children) {
        node.children = {} as any;
      }

      // @ts-ignore
      if (!node.children[dir]) {
        // @ts-ignore
        node.children[dir] = { label: dir, path };
      }
      // @ts-ignore
      node = node.children[dir];
    });
  });

  return treeData;
};

const renderTree = (node: Node, onNodeClick?: (node: Node) => any): JSX.Element => {
  let label = node.label;
  if (!label) {
    const parts = node.path.split('/');
    label = parts[parts.length - 1];
  }

  // region Actions

  const onClick = () => {
    if (typeof onNodeClick === 'function') {
      onNodeClick(node);
    }
  };

  // endregion

  return (
    <TreeItem
      key={node.path}
      nodeId={node.path}
      label={label}
      onClick={onClick}
    >
      {node.children && Object.keys(node.children).map((key) => renderTree(node.children![key], onNodeClick))}
    </TreeItem>
  );
};

// endregion

export const ClientFilesPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  // region State

  const [selectedFile, setSelectedFile] = React.useState<{
    name: string;
    path: string;
  }>();

  // endregion

  // region Networking


  const [fetchFile, { loading: fetchingFile, data: fetchedFileQuery }] = useClientFileLazyQuery();
  const { data } = useClientFilesQuery({
    variables: {
      clientId: params.id!
    }
  });

  const { files } = (data?.client || { files: [] });
  const fetchedFile = fetchedFileQuery?.file;

  // endregion

  // region Actions

  const onTreeNodeClick = (node: Node) => {
    if (!node.children) {
      setSelectedFile({
        path: node.path,
        name: node.label
      });
    }
  };

  // endregion

  // region Effect

  React.useEffect(() => {
    if (selectedFile) {
      fetchFile({
        variables: {
          path: selectedFile.path,
          clientId: params.id!
        }
      });
    }
  }, [selectedFile]);

  // endregion


  return (
    <Box>
      {/*{files.}*/}

      <Box
        sx={{
          gap: '2rem',
          display: 'grid',
          gridTemplateColumns: '1fr 3fr'
        }}
      >
        <Box
          sx={{
            height: '50px'
          }}
        />

        <Box
          sx={{
            textTransform: 'capitalize'
          }}
        >
          {selectedFile && (
            <React.Fragment>
              <Typography variant='titleSmall'>
                {selectedFile.name}
              </Typography>

              <Typography variant='subtitleSmall'>
                {selectedFile.path}
              </Typography>
            </React.Fragment>
          )}
        </Box>

        <Box>
          {(!!files.length) && (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {renderTree(convertToTreeData(files), onTreeNodeClick)}
            </TreeView>
          )}
        </Box>

        <Box>
          {!selectedFile && (
            <Box
              sx={{
                gap: '1rem',
                width: '100%',
                height: '200px',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'primary.textLowContrast'
              }}
            >
              <FindInPageRoundedIcon
                sx={{
                  fontSize: '4rem',
                  opacity: '0.6'
                }}
              />

              <Typography
                variant='titleSmall'
              >
                Select File
              </Typography>
            </Box>
          )}

          {selectedFile && (
            <React.Fragment>
              {!fetchedFile && (
                <Box
                  sx={{
                    gap: '1rem',
                    width: '100%',
                    height: '200px',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress />

                  <Typography>
                    Fetching your file..
                  </Typography>
                </Box>
              )}

              {fetchedFile && (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      component='embed'
                      src={fetchedFile?.authorisedUrl}
                      sx={{
                        minWidth: '300px',
                        minHeight: '400px',
                        borderRadius: '5px',
                      }}
                    />
                  </Box>

                  <Button
                    onClick={() => {
                      window.open(fetchedFile?.authorisedUrl, '_blank')
                        ?.focus();
                    }}
                    sx={{
                      my: '1rem',
                      float: 'right'
                    }}
                  >
                    Download
                  </Button>
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};
