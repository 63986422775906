import React from 'react';

import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';

import { useOneTimeAuthentication } from '../../authentication/oneTimeCodeAuthentication';
import { useUrlQuery } from '@backed-fi/hooks';
import { useSnackbar } from 'notistack';


// region Form Schema

const FormSchema = z.object({
  email: z.string()
    .email()
    .nonempty()
});

// endregion

export const AuthenticatePage = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const query = useUrlQuery();

  const {
    startEmailChallenge,
    authenticateWithEmailChallenge,
    loading
  } = useOneTimeAuthentication();

  // region State

  const [emailRequested, setEmailRequested] = React.useState(false);
  const [authenticating, setAuthenticating] = React.useState(false);

  // endregion

  // region Form Control

  const {
    formState,
    ...form
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  const { errors } = formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        query.has('challengeId') &&
        query.has('privateCode')
      ) {
        setAuthenticating(true);

        authenticateWithEmailChallenge(
          query.get('challengeId')!,
          query.get('privateCode')!
        )
          .then((result) => {
            setTimeout(() => {
              navigate(result.redirectTo ?? '/');
            }, 1000);
          })
          .catch((e) => {
            console.error(e);

            snackbar.enqueueSnackbar('Something went wrong. Please try again', {
              variant: 'error'
            });
          })
          .finally(() => {
            setTimeout(() => {
              setAuthenticating(false);
            }, 1000);
          });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  // endregion

  // region Actions

  const onStartEmailChallenge = form.handleSubmit(async (data) => {
    try {
      await startEmailChallenge(data.email);
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
        variant: 'error'
      });

      return;
    };


    setEmailRequested(true);
  });

  // endregion

  return (
    <Box
      sx={{
        backgroundColor: '#121419',
        backgroundImage: 'url(/assets/images/backgrounds/body-background.svg)',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100wv',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100vw',
          maxWidth: '30rem'
        }}
      >
        {/* <DomainRestricted dev local stage sandbox>
          <Alert
            variant='outlined'
            severity='warning'
            sx={{
              mb: '1rem',
              width: '100%'
            }}
          >
            {config.isDev && 'Development Environment'}
            {config.isStage && 'Staging Environment'}
          </Alert>
        </DomainRestricted> */}

        <Box
          sx={{
            color: '#fff'
          }}
        >
          <Box
            src={'/assets/images/logos/backed.svg'}
            component="img"
            width={235}
            height={56}
            sx={{
              display: 'block',
              width: 'auto',
              height: '3rem',
              margin: '0 auto 2rem'
            }}
          />
        </Box>

        <Box
          sx={{
            padding: '2rem',
            backgroundColor: '#fff',
            borderRadius: '1rem'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {/* <Logo />

            {!emailRequested && (
              <Typography variant='title'>Welcome to Backed.fi</Typography>
            )} */}

            <Box
              sx={{
                width: '100%'
              }}
            >
              {(authenticating) && (
                <Box
                  sx={{
                    textAlign: 'center',
                    gap: '1rem',
                    display: 'flex',
                    padding: '1rem 0',
                    flexFlow: 'column',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress />

                  <Box>
                    <Typography
                      align='center'
                      variant='title'
                      fontWeight='normal'
                      fontSize='1.5rem'
                    >
                      Please Wait
                    </Typography>
                    <Typography variant='subtitleSmall'>
                      Signing you in...
                    </Typography>
                  </Box>
                </Box>
              )}

              {!authenticating && (
                <React.Fragment>
                  {emailRequested ? (
                    <Box
                      sx={{
                        margin: '1rem 0'
                      }}
                    >
                      <Typography
                        align='center'
                        variant='title'
                        fontWeight='normal'
                        fontSize='1.5rem'
                        sx={{
                          marginBottom: '1rem'
                        }}
                      >
                        Check Your Email
                      </Typography>

                      <Typography
                        align='center'
                        variant='subtitleSmall'
                      >
                        We've sent you a temporary login link. Please check your email at{' '}
                        <Box component='span' sx={{ textDecoration: 'underline' }}>{form.getValues('email')}</Box>
                      </Typography>
                    </Box>
                  ) : (
                    <Box component='form'>
                      <TextField
                        fullWidth
                        label='Email Address'
                        sx={{
                          flex: 1,
                          marginBottom: '1rem'
                        }}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        {...form.register('email')}
                      />

                      <LoadingButton
                        fullWidth
                        type='submit'
                        variant='contained'
                        loading={loading}
                        onClick={onStartEmailChallenge}
                        onSubmit={onStartEmailChallenge}
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  )}
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#747B89',
            textAlign: 'center',
            marginTop: '2rem'
          }}
        >
          2024 &copy; Backed Finance. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
