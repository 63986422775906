import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import EditIcon from '@mui/icons-material/Edit';
import { AdminPermission, useUpdateBlockchainAddressMutation } from '@backed-fi/graphql';
import { Protector } from '@backed-fi/compound';

gql`
  mutation updateBlockchainAddress($input: UpdateBlockchainAddressInput!) {
    updateBlockchainAddress(input: $input)
  }
`;


// region Schema

const schema = z.object({
  /**
   * Short description of the address being created
   */
  description: z.string()
    .min(1, 'Name is required')
});

// endregion


type Props = {
  blockchainAddressId: string;
  name: string;
};

export const EditBlockchainAddressDialog: React.FC<Props> = ({ blockchainAddressId, name }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const [updateBlockchainAddress, { loading }] = useUpdateBlockchainAddressMutation();

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      description: name
    }
  });

  const { errors } = form.formState;

  // endregion


  // region Actions
  const onCloseInternal: typeof dialog.onClose = (...args) => {
    form.reset();


    if (typeof dialog.onClose === 'function') {
      dialog.onClose(...args);
    }
  };

  const onUpdate = form.handleSubmit(async (data) => {
    try {
      await updateBlockchainAddress({
        awaitRefetchQueries: true,
        refetchQueries: [
          'blockchainAddresses',
          'blockchainAddress'
        ],
        variables: {
          input: {
            blockchainAddressId,
            ...data
          }
        }
      });

      onCloseInternal();

      snackbar.enqueueSnackbar('Successfully updated address', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while updating address', {
        variant: 'error'
      });
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Protector
        hide
        permissions={[AdminPermission.BlockchainManage]}
      >
        <Tooltip title="Edit blockchain address">
          <IconButton
            onClick={dialog.setTrue}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Protector>

      {dialog.open && (
        <Dialog
          fullWidth
          maxWidth="sm"
          sx={{
            '.MuiDialog-paper': {
              borderRadius: '12px'
            }
          }}
          {...dialog}
          onClose={onCloseInternal}
        >
          <DialogContent
            sx={{
              padding: '32px'
            }}
          >
            <Typography variant="title">Edit Address</Typography>

            <Typography
              variant="subtitleSmall"
              sx={{
                margin: '16px 0'
              }}
            >
              Please provide updated blockchain address information
            </Typography>

            <Controller
              name="description"
              control={form.control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  fullWidth
                  label="Name"
                  sx={{
                    margin: '4px 0'
                  }}
                  {...field}
                  error={!!errors.description}
                />

              )}
            />

            <Box
              sx={{
                mt: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Box mr={1}>
                <Button
                  onClick={dialog.onClose}
                >
                  Cancel
                </Button>
              </Box>
              <LoadingButton
                loading={loading}
                onClick={onUpdate}
              >
                Edit
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog >
      )}
    </React.Fragment>
  );
};
