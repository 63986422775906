import * as z from 'zod';

export const customBlockchainAddressValidator = (
  requiredError: string = 'Please provide wallet address',
  formatError: string = 'The provided address is not valid') => z.string()
    .min(1, requiredError)
    .regex(/^0x[a-fA-F0-9]{40}$/, formatError)
    .transform<string>((address) => address.toLowerCase());

export const blockchainAddressValidator = customBlockchainAddressValidator();
