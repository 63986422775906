import { Box, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { useInjectionContext } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionContext';
import { FeeInjectionSchema } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionSchema';
import { NavigationButtons } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/NavigationButtons';

export const FeesAndInvoices: React.FC = () => {
  const context = useInjectionContext();

  // region From Control

  const form = useForm<z.infer<typeof FeeInjectionSchema>>({
    resolver: zodResolver(FeeInjectionSchema),
    defaultValues: context.fee
  });

  const { errors } = form.formState;
  const shouldCollect = form.watch('shouldCollect');

  form.watch((data) => {
    const parsedData = FeeInjectionSchema.parse(data);

    context.setInjectionState((p) => ({
      ...p,
      fee: parsedData
    }));
  });

  // endregion

  return (
    <Box>
      <Box
        sx={{
          mb: '1rem',
          gap: '.5rem',
          display: 'grid',
          gridTemplateColumns: '1fr'
        }}
      >
        {/*<FormControlLabel*/}
        {/*  sx={{*/}
        {/*    ml: 0*/}
        {/*  }}*/}
        {/*  label="Collect Fees"*/}
        {/*  control={*/}
        {/*    <Checkbox*/}
        {/*      {...form.register('shouldCollect')}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}

        {(shouldCollect || true) && (
          <Box
            sx={{
              m: '1rem',
              maxWidth: '500px',
              display: 'grid',
              gridTemplateColumns: '1fr'
            }}
          >
            {/*<EnumSelect*/}
            {/*  size="small"*/}
            {/*  label="Network"*/}
            {/*  enum={BlockchainNetwork}*/}
            {/*  error={!!errors.network}*/}
            {/*  helperText={errors.network?.message}*/}
            {/*  {...form.register('network')}*/}
            {/*/>*/}

            {/*<TextField*/}
            {/*  size="small"*/}
            {/*  label="Fee Amount"*/}
            {/*  error={!!errors.amount}*/}
            {/*  helperText={errors.amount?.message ?? 'The value should be in wei'}*/}
            {/*  {...form.register('amount')}*/}
            {/*/>*/}

            <TextField
              size="small"
              label="Fee Value"
              error={!!errors.value}
              helperText={errors.value?.message ?? 'The value of the fee in USD.'}
              {...form.register('value')}
            />
          </Box>
        )}

        {/*<FormControlLabel*/}
        {/*  sx={{*/}
        {/*    ml: 0*/}
        {/*  }}*/}
        {/*  label="Generate Invoice"*/}
        {/*  control={*/}
        {/*    <Checkbox*/}
        {/*      {...form.register('shouldGenerateInvoice')}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <NavigationButtons />
      </Box>
    </Box>
  );
};
