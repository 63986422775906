import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { usePaginationModel } from '@backed-fi/hooks';

import { FlowType, useFlowsExecutionsListQuery } from '@backed-fi/graphql';
import { AssetInteraction, Title, TransactionActions } from '@backed-fi/compound';
import { DateTimeFormatter } from '@backed-fi/shared';

import { FlowTypeBadge } from './FlowTypeBadge';

// region Graph

gql`
  query flowsExecutionsList(
    $page: Int,
    $pageSize: Int,
    $where: FlowExecutionWhereInput
  ) {
    flowExecutions(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id

        createdAt

        flowType
        status

        mint {
          id
          variant
          amount
          hash
          explorerUrl

          tokenDeployment {
            network
            token {
              name
              symbol
              icon
            }
          }
        }

        burn {
          id
          variant
          amount
          hash
          explorerUrl

          tokenDeployment {
            network
            token {
              name
              symbol
              icon
            }
          }
        }
      }

      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  /**
   * Show only flows, that include at least one of
   * this token's deployments
   */
  tokenId?: string;

  clientId?: string;
}

// endregion


export const FlowsExecutionsTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  // region Pagination

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(props.disablePagination);

  // endregion

  // region Networking

  const query = useFlowsExecutionsListQuery({
    notifyOnNetworkStatusChange: true,

    // If we are on the first page - poll, otherwise - don't
    pollInterval: paginationVariables.page
      ? undefined
      : 5000,

    variables: {
      ...paginationVariables,
      where: {
      }
    }
  });

  const {
    data,
    previousData,
    loading,
    error
  } = query;

  // endregion

  const companyData = data || previousData;

  const finalData = React.useMemo(() => {
    if (companyData) {
      return {
        page: companyData.flowExecutions.page,
        nodes: companyData.flowExecutions.nodes.filter((f) => f.burn || f.mint).map((f) => ({
          id: f.id,
          createdAt: f.createdAt,
          type: f.flowType,
          ...(() => {
            switch (f.flowType) {
              case FlowType.Mint:
                return {
                  token: {
                    name: f.mint!.tokenDeployment.token.name,
                    icon: f.mint!.tokenDeployment.token.icon,
                    symbol: f.mint!.tokenDeployment.token.symbol
                  },
                  hash: f.mint!.hash,
                  explorerUrl: f.mint!.explorerUrl,
                  network: f.mint!.tokenDeployment.network,
                  amount: f.mint!.amount
                };
              case FlowType.Burn:
                return {
                  token: {
                    name: f.burn!.tokenDeployment.token.name,
                    icon: f.burn!.tokenDeployment.token.icon,
                    symbol: f.burn!.tokenDeployment.token.symbol
                  },
                  hash: f.burn!.hash,
                  explorerUrl: f.burn!.explorerUrl,
                  network: f.burn!.tokenDeployment.network,
                  amount: f.burn!.amount
                };
              default:
                return null;
            }
          })()
        }))
      };
    }

    return null;
  },
    [companyData]
  );

  return (
    <Box
      sx={{
        height: '100%'
      }}>
      <Box
        mb={1}
        gap={1}
        display="flex"
        sx={{
          justifyContent: 'end'
        }}>
      </Box>
      <Box>
        <DataGrid
          {...pagination}
          autoHeight
          loading={loading}
          rows={finalData?.nodes ?? []}
          rowCount={finalData?.page.totalNodes}
          onRowClick={({ row }) => {
            navigate(`/activities/details/${row.id}`);
          }}
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {!error && <Title
                  title="No activities"
                  subtitle='There are no activities in the system yet'
                />}
                {error && <Title
                  title="Failed to fetch activities"
                  subtitle={error.message}
                />}
              </Box>
            )
          }}
          columns={[
            {
              minWidth: 350,
              field: 'id',
              headerName: 'ID'
            },
            {
              width: 200,
              field: 'type',
              headerName: 'Type',
              renderCell: ({ value }) => (
                <FlowTypeBadge type={value} />
              )
            },
            {
              minWidth: 400,
              flex: 1,
              field: 'amount',
              headerName: 'Activity',
              renderCell: ({ row }) => (
                <AssetInteraction
                  asset={
                    {
                      amount: row.amount,
                      type: 'Blockchain',
                      decimals: 18,
                      id: row.id,
                      network: row.network!,
                      tokenSymbol: row.token!.symbol,
                      icon: row.token!.icon,
                      assetType: 'BackedToken' as any
                    }
                  }
                />
              )
            },
            {
              width: 250,
              field: 'createdAt',
              headerName: 'Date',
              valueFormatter: ({ value }) => (DateTimeFormatter.format(value))
            },
            {
              width: 250,
              field: 'transactionHash',
              headerName: 'Hash',
              renderCell: ({ row }) => (
                <TransactionActions hash={row.hash!} explorerUrl={row.explorerUrl!} />
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
