import React from 'react';
import { Alert, Box, Tooltip, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { AdminNotificationsQuery, useAdminNotificationsQuery, useDashboardDataQuery } from '@backed-fi/graphql';
import { useAdminContext } from '../../context/AdminContext';
import CurrentAumChart from '../Dashboard/components/CurrentAumChart';
import AumOverTimeChart from '../Dashboard/components/AumOverTimeChart';
import TokensPerNetworkChart from '../Dashboard/components/TokensPerNetworkChart';
import AumPerNetworkChart from '../Dashboard/components/AumPerNetworkChart';
import { ContentTile } from '@backed-fi/compound';

const Graph = gql`
  query adminNotifications {
    notifications(
      where: {
        domain: System,
        isAcknowledged: false
      }
    ) {
      id

      title
      type
    }
  }

  query dashboardData {
    riskAssessments (
      where: {
        status: Active
      }
    ) {
      id

      client {
        id
        name
      }
    }
  }
`;

export const DashboardPage: React.FC = () => {
  const adminContext = useAdminContext();
  const { data } = useDashboardDataQuery();
  const { data: notificationData } = useAdminNotificationsQuery();

  // ---- Destructuring ---- //
  const { notifications } = (notificationData || {}) as AdminNotificationsQuery;

  return (
    <Box>
      <Typography variant='title' fontWeight={400}>
        Welcome {adminContext?.firstName}
      </Typography>

      <Typography variant='subtitleSmall'>
        Logged in via {adminContext?.email}{' '}
        <Tooltip title={adminContext.permissions.join(', ')}>
          <Box component='span' sx={{ cursor: 'help' }}>({adminContext.permissions.length} Permissions)</Box>
        </Tooltip>
      </Typography>

      {/* <Typography variant='subtitleSmall'>
        {notifications?.length
          ? `There ${notifications.length > 1 ? 'are' : 'is'} ${notifications.length} notification${notifications.length !== 1 ? 's' : ''} pending.`
          : 'There are no pending notifications.'
        }
      </Typography> */}

      {!!data?.riskAssessments?.length && (
        <Box my='8px'>
          <Alert severity='info'>
            There are risk assessments that are pending filling
          </Alert>
        </Box>
      )}

      {/* <Divider variant="middle" /> */}

      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          margin: '2rem 0 3rem',
          gridTemplateColumns: '1fr 1fr'
        }}
      >
        {[
          <CurrentAumChart key={1} />,
          <AumOverTimeChart key={2} />,
          <TokensPerNetworkChart key={3} />,
          <AumPerNetworkChart key={4} />
        ].map((card, index) => (
          <ContentTile>{card}</ContentTile>
        ))}
      </Box>
    </Box>
  );
};
