import React from 'react';

import { RefundPages } from './Refunds/RefundPages';
import { InjectionPages } from './Injections/InjectionPages';
import { InteractionPages } from './Interactions/InteractionPages';

export const InteractionRoutes = (
  <>
    {RefundPages}
    {InjectionPages}
    {InteractionPages}
  </>
);
