import React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { OracleDetailsPage } from '@backed-fi/admin/src/app/domain/Blockchain/oracles/page/OracleDetailsPage';
import { Protector } from '@backed-fi/compound';
import { CompanyFeature } from '@backed-fi/graphql';

export const OracleRoutes = (
  <Route path="oracles" element={
    <Protector companyFeature={CompanyFeature.BlockchainAdvanced}>
      <Outlet />
    </Protector>
  }>
    <Route
      path=":id/details"
      element={
        <OracleDetailsPage />
      }
    />
  </Route>
);
