import React from 'react';

import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { CryptoFormatter, registerField } from '@backed-fi/shared';
import { InfoLabel } from '@backed-fi/compound';

interface Props {
  symbol: string;
  burnSupply: string;
  burnAddress: string;
  inputProps: ReturnType<typeof registerField>;
}
export const BurnFlowAmount: React.FC<Props> = ({
  symbol,
  burnAddress,
  burnSupply,
  inputProps
}) => {
  return (
    <FormControl
      fullWidth
      sx={{
        margin: '0.5rem 0',
        '.MuiOutlinedInput-notchedOutline': {
          borderBottom: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }
      }}
      error={inputProps.error}
    >
      <InputLabel
        sx={{
          color: '#747B89 !important',

          '&.Mui-focused:not(.Mui-error)': {
            color: '#0066ff !important'
          }
        }}
        error={inputProps.error}
      >
        Burn Amount
      </InputLabel>
      <OutlinedInput
        {...inputProps}
        sx={{}}
        label={'Burn Amount'}
        endAdornment={
          <InputAdornment sx={{ maxWidth: '150px' }} position="end">
            <Typography sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{symbol}</Typography>
          </InputAdornment>
        }
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',

          padding: '8px 14px',

          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <InfoLabel
          label="Burn balance"
          content={
            <Typography>
              {CryptoFormatter.format(burnSupply, 18, symbol)}
            </Typography>
          }
        />
        <InfoLabel
          label="Burn wallet"
          content={<Typography variant="code">{burnAddress}</Typography>}
          copy
          copyValue={burnAddress}
        />
      </Box>
      {inputProps.helperText && (
        <FormHelperText error={inputProps.error}>
          {inputProps.helperText.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
};
