import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField
} from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import {
  AdminPermission,
  useCreateCompanyMutation
} from '@backed-fi/graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { Protector, Title } from '@backed-fi/compound';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

gql`

  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
    }
  }
`;


// region Form Schema

const Schema = z.object({
  name: z.string(),
  appHostPrefix: z.string(),
  superAdminEmail: z.string()
});

type Schema = z.infer<typeof Schema>;

// endregion


type Props = {
};

export const CreateCompanyDialog: React.FC<Props> = ({ }) => {
  const dialog = useToggle();
  const isCreating = useToggle();
  const snackbar = useSnackbar();


  const [createCompany] = useCreateCompanyMutation();

  // region Form Control

  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors } = form.formState;

  // endregion

  const onCloseInternal: typeof dialog.onClose = (...args) => {
    form.reset();


    if (typeof dialog.onClose === 'function') {
      dialog.onClose(...args);
    }
  };

  // region On Create

  const onCreate = form.handleSubmit(async (data) => {
    try {
      isCreating.setTrue();

      await createCompany({
        awaitRefetchQueries: true,
        refetchQueries: [
          'companiesListPage'
        ],
        variables: {
          input: {
            ...data
          }
        }
      });


      onCloseInternal();

      snackbar.enqueueSnackbar('Successfully created new company', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while creating a company', {
        variant: 'error'
      });
    } finally {
      isCreating.setFalse();
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Protector
        hide
        permissions={[AdminPermission.CompaniesManage]}
      >
        <Button onClick={dialog.setTrue}>
          Create new
        </Button>
      </Protector>

      {dialog.open && (
        <Dialog
          {...dialog}
          onClose={onCloseInternal}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Create Company"
              subtitle="Provide new company details"
            />

            {/* region Labels */}
            <Controller
              name="name"
              control={form.control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  sx={{
                    marginTop: '24px'
                  }}
                  fullWidth
                  label="Name"
                  error={!!errors?.name}
                  helperText={errors?.name?.message ?? 'Name of the company'}
                  {...field}
                />

              )}
            />

            <Controller
              name="appHostPrefix"
              control={form.control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  fullWidth
                  label="Url prefix"
                  error={!!errors?.appHostPrefix}
                  helperText={errors?.appHostPrefix?.message ?? 'Web Url prefix for company'}
                  {...field}
                />

              )}
            />

            <Controller
              name="superAdminEmail"
              control={form.control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  fullWidth
                  label="Admin Email"
                  error={!!errors?.superAdminEmail}
                  helperText={errors?.superAdminEmail?.message ?? 'Email of main admin of company'}
                  {...field}
                />

              )}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={isCreating.value}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={isCreating.value}
                onClick={onCreate}
              >
                Create
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
