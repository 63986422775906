import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { gql } from '@apollo/client';
import { Box, Grid, Skeleton } from '@mui/material';

import { useCompanyOverviewQuery, useUploadLogoMutation } from '@backed-fi/graphql';

import { ContentTile, InfoLabel, Title } from '@backed-fi/compound';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';
import { ImageUpload } from '../components/ImageUpload';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditCompanyDialog } from '../components/EditCompanyDialog';


const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

gql`
  query companyOverview($companyId: String!) {
    company(where: {
        id: $companyId
      }) {
        id
        name
        logo
        appHostPrefix
      }
  }

  mutation uploadLogo($input: UploadLogoInput!) {
    uploadLogo(input: $input)
  }
`;

const schema = z.object({
  /**
   * The address that is being created
   */
  img: z.any()
    .refine((files) => files?.length === 0, 'Image is required.') // if no file files?.length === 0, if file files?.length === 1
    .refine(
      (files) => files?.[0]?.size >= MAX_FILE_SIZE,
      'Max file size is 5MB.'
    ) // this should be greater than or equals (>=) not less that or equals (<=)
    .refine(
      (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
      '.jpg, .jpeg, .png and .webp files are accepted.'
    )
});

export const CompanyOverviewPage: React.FC = () => {
  // ---- Hooks ---- //
  const params = useParams<{ id: string }>();
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });
  const formData = form.watch();
  useEffect(() => {
    if (formData.img instanceof File) {
      uploadLogo({
        variables: {
          input: {
            id: params.id,
            logo: formData.img
          }
        }
      });
    }
  }, [formData.img]);
  const { id: companyId } = useCompanyContext();

  // ---- Networking ---- //
  const { data } = useCompanyOverviewQuery({
    variables: {
      companyId: params.id ?? companyId
    }
  });
  // const [updateCompany] = useUpdateCompanyMutation();
  const [uploadLogo] = useUploadLogoMutation();

  useEffect(() => {
    form.setValue('img', data?.company.logo!);
  }, [data]);
  // ---- Safe destructuring ---- //
  const { company } = data || {};

  return (
    <Grid container>
      <Grid item xs={12} xl={6}>
        {/* // ---- Cards quick glance ---- // */}

        <Box display="flex">
          {/* // ---- Personal Information ---- // */}
          <ContentTile sx={{
            display: 'flex',
            flexGrow: '1'
          }}>
            <Box flexGrow={1}>
              <Title title="Company overview" />
              <Box display='flex' sx={{
                marginTop: '16px',
                gap: '1.5rem'
              }}>
                {company &&
                  <>
                    <Box display='flex' sx={{ alignItems: 'center' }}>
                      <ImageUpload
                        control={form.control}
                        name="img"
                      />
                    </Box>
                    <Box flex={1}>
                      <InfoLabel
                        label="Name"
                        content={company?.name}
                      />
                      <InfoLabel
                        label="Web domain prefix"
                        content={company?.appHostPrefix}
                      />
                    </Box>
                  </>
                }
                {!company &&
                  <Skeleton animation="wave"></Skeleton>
                }
              </Box>
            </Box>
            <Box sx={{ marginLeft: '32px' }}>
              {company && <EditCompanyDialog id={company.id} name={company.name} />}
            </Box>
          </ContentTile>
        </Box>
      </Grid >
    </Grid >
  );
};

export default CompanyOverviewPage;
