import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { PageHeading, Protector } from "@backed-fi/compound";
import { AdminPermission, useAdminsQuery } from "@backed-fi/graphql";
import { DataGrid } from "@mui/x-data-grid";
import { AddAdminDialog } from "@backed-fi/admin/src/app/domain/Internal/Admins/components/AddAdminDialog";
import { usePaginationModel, useToggle } from "@backed-fi/hooks";
import { AdminPermissions } from "./components/AdminPermissions";
import { DeleteAdminDialog } from "./components/DeleteAdminDialog";

export const AdminsListPage: React.FC = () => {
  const addDialogToggle = useToggle();
  const { paginationVariables, ...pagination } = usePaginationModel();

  // region Networking

  const query = useAdminsQuery({
    variables: {
      ...paginationVariables
    },
  });

  // endregion

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 6rem)",
      }}
    >
      {/* region Dialogs */}

      <AddAdminDialog {...addDialogToggle} />
      {/* endregion */}

      <PageHeading
        title="System users"
      >
        <Protector permissions={[AdminPermission.SystemManage]} hide>
          <Button onClick={addDialogToggle.setTrue}>Add User</Button>
        </Protector>
      </PageHeading>

      <DataGrid
        {...pagination}
        autoHeight
        loading={query.loading}
        rows={query.data?.admins?.nodes || []}
        rowCount={query.data?.admins?.page.totalNodes}
        columns={[
          {
            flex: 1,
            minWidth: 300,
            field: "name",
            headerName: "Name",
            renderCell: ({ row }) => (
              <Typography>
                {row.firstName} {row.lastName}
              </Typography>
            ),
          },
          {
            flex: 1,
            minWidth: 300,
            field: "email",
            headerName: "Email",
            renderCell: ({ value }) => <Typography>{value}</Typography>,
          },
          {
            flex: 1,
            minWidth: 300,
            field: "permissions",
            headerName: "Permissions",
            renderCell: ({ row }) => (
              <AdminPermissions admin={row}></AdminPermissions>
            ),
          },
          {
            flex: 1,
            minWidth: 300,
            field: "id",
            headerName: "",
            renderCell: ({ row }) => (
              <DeleteAdminDialog admin={row} />
            ),
          },
        ]}
        sx={{
          flexGrow: 1,
        }}
      />
    </Box>
  );
};
