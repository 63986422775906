import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import FlareIcon from '@mui/icons-material/Flare';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MenuIcon from '@mui/icons-material/Menu';

import { AdminPermission, BlockchainNetwork } from '@backed-fi/graphql';
import { useToggle } from '@backed-fi/hooks';
import { ContentTile, InfoLabel, Protector } from '@backed-fi/compound';
import { MintFlowDialog } from '../../../Flows/components/mint/MintFlowDialog';
import { BurnFlowDialog } from '../../../Flows/components/burn/BurnFlowDialog';

interface Props {
  token: {
    id: string;
    name: string;
    symbol: string;
    icon?: string | null;
    isin?: string | null;
    totalSupply: string;
    circulatingSupply: string;
    totalValue: string;
    circulatingValue: string;
    deployments: { network: BlockchainNetwork }[]
  }
}

export const TokenCard: React.FC<Props> = ({ token }) => {
  const navigate = useNavigate();

  const mintToggle = useToggle();
  const burnToggle = useToggle();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Actions

  const onMint = () => {
    mintToggle.setOpen(true);

    handleClose();
  };
  const onBurn = () => {
    burnToggle.setOpen(true);

    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <ContentTile sx={{
    display: 'flex',
    flexGrow: '1'

  }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexGrow: '1'
    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '32px',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => navigate(`/blockchain/tokens/details/${token.id}/overview`)}>
          <Avatar
            sx={{
              width: '80px',
              height: '80px'
            }}
            alt={token.symbol}
            src={token.icon ?? `/assets/icons/assets/${token.symbol}.svg`} />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '16px'
          }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'end'
              }}
              flex={1}
              variant='subtitle'>
              {token.symbol}
            </Typography>
            <Typography
              sx={{
                display: 'flex'
              }}
              flex={1}
              variant='subtitleSmall'>
              {token.name}
            </Typography>
            {token.isin &&
              <Typography
                sx={{ display: 'flex' }}
                flex={1}
                variant='subtitleSmall'>
                ISIN: {token.isin}
              </Typography>}
          </Box>
        </Box>
        <InfoLabel
          label='Networks' content={
            <>
              {token.deployments.map((d, index) => (
                <Box
                  key={index}
                  src={`/assets/icons/assets/native/${d.network}.svg`}
                  sx={{
                    marginTop: '4px',
                    marginRight: '8px'
                  }}
                  component='img'
                  width={32}
                  height={32}
                />))}
            </>}>
        </InfoLabel>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <InfoLabel
          sx={{
            marginTop: '0',
            whiteSpace: 'nowrap'
          }}
          label='Circulating supply'
          content={token.circulatingSupply} />
        <InfoLabel
          sx={{
            marginTop: '0',
            whiteSpace: 'nowrap'
          }}
          label='Circulating value'
          content={token.circulatingValue} />
        <InfoLabel
          sx={{
            marginTop: '0',
            whiteSpace: 'nowrap'
          }}
          label='Total supply'
          content={token.totalSupply} />
        <InfoLabel
          sx={{
            marginTop: '0',
            whiteSpace: 'nowrap'
          }}
          label='Total value'
          content={token.totalValue} />
      </Box>
    </Box>
    <Box sx={{ marginLeft: '32px' }}>
      <>
        {mintToggle.open && <MintFlowDialog toggle={mintToggle} tokenId={token.id} />}
        {burnToggle.open && <BurnFlowDialog toggle={burnToggle} tokenId={token.id} />}
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >

          <MenuItem onClick={onMint}>
            <FlareIcon sx={{ paddingRight: '8px' }}></FlareIcon>
            Mint
          </MenuItem>
          <MenuItem onClick={onBurn}>
            <LocalFireDepartmentIcon sx={{ paddingRight: '8px' }}></LocalFireDepartmentIcon>
            Burn
          </MenuItem>

        </Menu>
        <Protector permissions={[AdminPermission.FlowsManage]} hide>
          <IconButton onClick={(event) => handleClick(event)}>
            <MenuIcon></MenuIcon>
          </IconButton>
        </Protector>
      </>
    </Box>
  </ContentTile >;
};
