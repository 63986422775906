import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Protector } from '@backed-fi/compound';

export const TokenDetailsLayout = () => {
  return (
    <Protector>
      <Box
        sx={{
          mt: '2rem',
          flex: 1
        }}
      >
        <React.Suspense fallback={'Loading the code...'}>
          <Outlet />
        </React.Suspense>
      </Box>
    </Protector>
  );
};
