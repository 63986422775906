import React from 'react';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';
import { CompanyFeature } from '@backed-fi/graphql';
import { DashboardPage } from '../Dashboard/DashboardPage';
import { Navigate } from 'react-router-dom';


export const HomePage: React.FC = () => {
  const company = useCompanyContext();

  return <>
    {
      company.features.some(f => f === CompanyFeature.Dashboard) && <DashboardPage />
    }
    {
      !company.features.some(f => f === CompanyFeature.Dashboard) && <Navigate to={'/blockchain/tokens/all'} />
    }
  </>
};
