import React from "react";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { registerField } from "@backed-fi/shared";

interface Props {
  selected: string;

  addresses: {
    address: string;
    description: string;
  }[];

  inputProps: ReturnType<typeof registerField>;

  onSelect: (address: string) => void;
}

export const MintFlowReceipient: React.FC<Props> = ({
  selected,
  addresses,
  inputProps,
  onSelect,
}) => {
  const [open, setOpen] = React.useState(false);  
  const [address, setAddress] = React.useState<string>(selected || "");
  
  const inputRef = React.useRef<HTMLInputElement>();

  const shrink = !!(address?.length > 0);
  console.log(shrink);

  const handleSelect = (address: string) => {
    setAddress(address);
    setOpen(false);
  };

  React.useEffect(() => {
    onSelect(address);
  }, [address]);

  return (
    <FormControl fullWidth sx={{ margin: "0.5rem 0" }}>
      <InputLabel
        error={inputProps.error}
        sx={{
          color: "#747B89 !important",

          "&.Mui-focused:not(.Mui-error)": {
            color: "#0066ff !important",
          },
        }}
      >
        Desination Wallet
      </InputLabel>
      <OutlinedInput
        error={inputProps.error}
        value={address}
        onChange={(event) => setAddress(event.target.value)}
        ref={inputRef}
        label="Desination Wallet"
        placeholder="Select or enter a wallet address"
        sx={{
          paddingRight: "6px",
        }}
        endAdornment={
          <InputAdornment position="end">
            <Button
              startIcon={<ImportContactsIcon />}
              size="small"
              variant="text"
              sx={{ paddingX: "8px" }}
              onClick={() => setOpen(true)}
            >
              Address Book
            </Button>
          </InputAdornment>
        }
      />
      {inputProps.helperText && (
        <FormHelperText error={inputProps.error}>
          {inputProps.helperText.toString()}
        </FormHelperText>
      )}
      <Menu
        sx={{
          marginTop: "8px",
        }}
        anchorEl={inputRef.current!}
        open={open}
        onClose={() => setOpen(false)}
      >
        {addresses.map((a, index) => (
          <>
            <MenuItem
              sx={{
                padding: "8px",
                width: "642px",
                margin: "0 8px",
                borderRadius: "8px",
                ...(address === a.address && {
                  backgroundColor: "#CCE0FF",
                }),
                ":hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.04)",
                  ...(address === a.address && {
                    backgroundColor: "#CCE0FF",
                  }),
                },
              }}
              key={index}
              onClick={() => handleSelect(a.address)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: "1",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      font: "normal normal normal 12px/15px Inter",
                      color: "#747B89",
                    }}
                  >
                    {a.description}
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal normal 14px/18px JetBrains Mono",
                    }}
                  >
                    {a.address}
                  </Typography>
                </Box>
                {address === a.address && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ color: "rgb(25, 118, 210);" }}
                    ></CheckCircleIcon>
                  </Box>
                )}
              </Box>
            </MenuItem>
            {index !== addresses.length - 1 && <Divider />}
          </>
        ))}
      </Menu>
    </FormControl>
  );
};
