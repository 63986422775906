import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ActivateExternalWalletBySigningMessageInput = {
  signedMessage: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type ActivateExternalWalletManuallyInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  externalWalletId: Scalars['String']['input'];
};

export type ActivateToptLayerInput = {
  code: Scalars['String']['input'];
};

export type Admin = BaseEntity & {
  __typename?: 'Admin';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isMFAEnabled: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  permissions: Array<AdminPermission>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminConnection = {
  __typename?: 'AdminConnection';
  nodes: Array<Admin>;
  page: PageInfo;
};

export enum AdminPermission {
  AmlApproval = 'AML_Approval',
  AdminAccess = 'Admin_Access',
  BlockchainManage = 'Blockchain_Manage',
  BlockchainRead = 'Blockchain_Read',
  ClientManage = 'Client_Manage',
  ClientRead = 'Client_Read',
  CompaniesManage = 'Companies_Manage',
  ComplianceManage = 'Compliance_Manage',
  ComplianceRead = 'Compliance_Read',
  ComplianceReadSensitive = 'Compliance_ReadSensitive',
  DeveloperManage = 'Developer_Manage',
  DirectorApproval = 'Director_Approval',
  FinancialsExecutor = 'Financials_Executor',
  FinancialsManage = 'Financials_Manage',
  FinancialsRead = 'Financials_Read',
  FlowsManage = 'Flows_Manage',
  FlowsRead = 'Flows_Read',
  InteractionManage = 'Interaction_Manage',
  InteractionRead = 'Interaction_Read',
  OperationalApproval = 'Operational_Approval',
  ProductManage = 'Product_Manage',
  ProductRead = 'Product_Read',
  SystemManage = 'System_Manage',
  SystemPermissionsManage = 'System_Permissions_Manage',
  SystemRead = 'System_Read'
}

/** Interfaced, used on all entities that are owned by a admin */
export type AdminRelation = {
  /** The admin, that owns this entity */
  admin: Admin;
  /** The ID of the admin, that owns this entity */
  adminId: Scalars['String']['output'];
};

export type AdminsWhereInput = {
  AND?: InputMaybe<Array<AdminsWhereInput>>;
  OR?: InputMaybe<Array<AdminsWhereInput>>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
};

export type ApplyOracleAnswerUpdateInput = {
  priceUpdateId: Scalars['String']['input'];
};

export type ApproveDocumentDeletionRequestInput = {
  requestId: Scalars['String']['input'];
};

export type ApproveIncomingTransactionInput = {
  transactionId: Scalars['String']['input'];
};

export type ApproveOutgoingTransactionInput = {
  transactionId: Scalars['String']['input'];
};

export type ArchiveVerificationInput = {
  verificationId: Scalars['String']['input'];
};

export type AssetReserves = {
  __typename?: 'AssetReserves';
  collateral: Collateral;
  inTransit: Scalars['String']['output'];
  provider: AssetReservesProvider;
  reserves: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  tokensInCirculation: Scalars['String']['output'];
};

export enum AssetReservesProvider {
  Chainlink = 'Chainlink',
  TheNetworkFirm = 'TheNetworkFirm'
}

export type AuthenticateEmailChallengeInput = {
  browserId: Scalars['String']['input'];
  challengeId: Scalars['String']['input'];
  privateCode: Scalars['String']['input'];
};

export type AuthenticateWithPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthenticateWithTransitionTokenInput = {
  browserId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  authenticationToken?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorReference?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
  transitionToken?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = BaseEntity & {
  __typename?: 'BankAccount';
  bankAddress: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  nameOnAccount: Scalars['String']['output'];
  swift: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  verificationStatus: BankAccountVerificationStatus;
};

export enum BankAccountVerificationStatus {
  Failed = 'Failed',
  NotVerified = 'NotVerified',
  Verified = 'Verified'
}

export type BankAccountsWhereInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type BankTransfer = BaseEntity & {
  __typename?: 'BankTransfer';
  amount: Scalars['Decimal']['output'];
  bankAccount?: Maybe<BankAccount>;
  bankAccountId?: Maybe<Scalars['String']['output']>;
  client: Client;
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currency: FiatCurrency;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  paymentForInteractionId?: Maybe<Scalars['String']['output']>;
  payoutForInteractionId?: Maybe<Scalars['String']['output']>;
  status: BankTransferStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type BankTransferConnection = {
  __typename?: 'BankTransferConnection';
  nodes: Array<BankTransfer>;
  page: PageInfo;
};

export enum BankTransferStatus {
  Completed = 'Completed',
  Executed = 'Executed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type BankTransfersWhereInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  paymentForInteractionId?: InputMaybe<Scalars['String']['input']>;
  payoutForInteractionId?: InputMaybe<Scalars['String']['input']>;
};

export type BaseEntity = {
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type BlockchainAddress = BaseEntity & {
  __typename?: 'BlockchainAddress';
  address: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  networks: Array<BlockchainNetwork>;
  tokenBalances: Array<BlockchainAddressTokenBalance>;
  type: BlockchainAddressType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type BlockchainAddressTokenBalance = {
  __typename?: 'BlockchainAddressTokenBalance';
  balance: Array<BlockchainAddressTokenNetworkBalance>;
  token: Token;
  tokenId: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type BlockchainAddressTokenNetworkBalance = {
  __typename?: 'BlockchainAddressTokenNetworkBalance';
  balance: Scalars['String']['output'];
  contractAddress: Scalars['String']['output'];
  network: BlockchainNetwork;
  walletAddress: Scalars['String']['output'];
};

export enum BlockchainAddressType {
  Contract = 'Contract',
  ManualOperations = 'ManualOperations',
  Safe = 'Safe',
  SanctionsList = 'SanctionsList',
  Wallet = 'Wallet'
}

export type BlockchainAddressWhereInput = {
  type?: InputMaybe<BlockchainAddressType>;
};

/** Enum, containing all blockchain networks supported by the system */
export enum BlockchainNetwork {
  Arbitrum = 'Arbitrum',
  Avalanche = 'Avalanche',
  Base = 'Base',
  BinanceSmartChain = 'BinanceSmartChain',
  Ethereum = 'Ethereum',
  Fantom = 'Fantom',
  Gnosis = 'Gnosis',
  Polygon = 'Polygon'
}

export type BlockchainNetworkConfiguration = BaseEntity & {
  __typename?: 'BlockchainNetworkConfiguration';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  executor: SystemWallet;
  executorId: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  issuanceFeeWalletAddress: Scalars['String']['output'];
  microTransactionWalletAddress?: Maybe<Scalars['String']['output']>;
  nativeCurrency: NativeCurrency;
  network: BlockchainNetwork;
  redemptionFeeWalletAddress: Scalars['String']['output'];
  refundFeeWalletAddress: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  workingCapitalWalletAddress: Scalars['String']['output'];
};

export type BlockchainNetworksFilter = {
  equals?: InputMaybe<Array<InputMaybe<BlockchainNetwork>>>;
  has?: InputMaybe<BlockchainNetwork>;
  hasEvery?: InputMaybe<Array<InputMaybe<BlockchainNetwork>>>;
  hasSome?: InputMaybe<Array<InputMaybe<BlockchainNetwork>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BlockchainReport = BaseEntity & {
  __typename?: 'BlockchainReport';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  createdFor: BlockchainReportFor;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  reportFiles?: Maybe<Scalars['JSON']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportPayload?: Maybe<Scalars['JSON']['output']>;
  status: BlockchainReportStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum BlockchainReportFor {
  ExternalWallet = 'ExternalWallet',
  IncomingTransactions = 'IncomingTransactions'
}

export enum BlockchainReportStatus {
  Completed = 'Completed',
  Generating = 'Generating'
}

export type Broker = BaseEntity & {
  __typename?: 'Broker';
  clearanceAccounts: Array<ClearanceAccount>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  representative?: Maybe<Scalars['String']['output']>;
  settlementStrategy?: Maybe<SettlementStrategy>;
  tradingEngineSupport?: Maybe<Scalars['Boolean']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type BrokerConnection = {
  __typename?: 'BrokerConnection';
  nodes: Array<Broker>;
  page: PageInfo;
};

export type Burn = BaseEntity & TokenDeploymentTransaction & {
  __typename?: 'Burn';
  amount: Scalars['Decimal']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  explorerUrl?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  tokenDeployment: TokenDeployment;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  variant: BurnVariant;
};

export enum BurnVariant {
  Automated = 'Automated',
  Manual = 'Manual'
}

export type CanSystemWalletSatisfyOrderExecution = {
  __typename?: 'CanSystemWalletSatisfyOrderExecution';
  /** Can system wallet satisfy gas requirements for execution */
  gas: Scalars['Boolean']['output'];
  /** Can system wallet satisfy tokens for execution */
  tokens: Scalars['Boolean']['output'];
};

export type CanSystemWalletSatisfyOrderExecutionInput = {
  orderId: Scalars['String']['input'];
  pricePerShare: Scalars['String']['input'];
  totalShares: Scalars['Int']['input'];
};

export type CanSystemWalletSatisfyOutgoingTransactionExecution = {
  __typename?: 'CanSystemWalletSatisfyOutgoingTransactionExecution';
  /** Can system wallet satisfy gas requirements for execution */
  gas: Scalars['Boolean']['output'];
  /** Can system wallet satisfy tokens for execution */
  tokens: Scalars['Boolean']['output'];
};

export type CanSystemWalletSatisfyOutgoingTransactionExecutionInput = {
  outgoingTransactionId: Scalars['String']['input'];
};

export type ClearanceAccount = BaseEntity & {
  __typename?: 'ClearanceAccount';
  broker: Broker;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  depositoryCode?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  institutionBic?: Maybe<Scalars['String']['output']>;
  institutionName?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ClearanceAccountConnection = {
  __typename?: 'ClearanceAccountConnection';
  nodes: Array<ClearanceAccount>;
  page: PageInfo;
};

export type ClearanceAccountWhereUniqueInput = {
  id: Scalars['String']['input'];
};

/** Object type, providing all the shared relations between the user and organisations */
export type Client = BaseEntity & {
  __typename?: 'Client';
  acceptsMarketingCommunication: Scalars['Boolean']['output'];
  /** List of all the addresses, that the client has provided us */
  addresses: Array<PhysicalAddress>;
  classification: ClientClassification;
  closure?: Maybe<ClientClosure>;
  complianceReview?: Maybe<ComplianceReview>;
  /** The email at which this client can be contacted */
  contactEmail: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  documents: Array<Document>;
  externalWallets: Array<ExternalWallet>;
  /** List of all the features that are enabled for that user */
  features: Array<ClientFeature>;
  files: Array<Scalars['String']['output']>;
  /** The root folder of the client */
  folder?: Maybe<DocumentFolder>;
  frozenUntil?: Maybe<Scalars['DateTime']['output']>;
  hasWalletRegistered: Scalars['Boolean']['output'];
  humanId: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** Boolean, specifying if the client is a professional investor */
  isProfessional: Scalars['Boolean']['output'];
  jurisdiction: Country;
  /** The usage limits of the client */
  limits: UsageLimits;
  /** The name of the client. Used for display purposes */
  name: Scalars['String']['output'];
  physicalAddress?: Maybe<PhysicalAddress>;
  preferences: ClientPreferences;
  questionnaires: Array<ClientQuestionnaire>;
  redemptionWallet: SweepingWallet;
  /** List of all the risk assessments performed for that client */
  riskAssessments: Array<RiskAssessment>;
  /** List of all the security logs for that client */
  securityLogs: Array<SecurityLog>;
  shouldActivateWallet: Scalars['Boolean']['output'];
  shouldRegisterWallet: Scalars['Boolean']['output'];
  statistics: ClientInteractionStatistics;
  status: ClientStatus;
  type: ClientType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  usage: ClientUsage;
  users: Array<User>;
  verificationProfile?: Maybe<VerificationProfile>;
};


/** Object type, providing all the shared relations between the user and organisations */
export type ClientDocumentsArgs = {
  type?: InputMaybe<DocumentType>;
};


/** Object type, providing all the shared relations between the user and organisations */
export type ClientQuestionnairesArgs = {
  where?: InputMaybe<ClientQuestionnaireWhereInput>;
};


/** Object type, providing all the shared relations between the user and organisations */
export type ClientRedemptionWalletArgs = {
  network: BlockchainNetwork;
};


/** Object type, providing all the shared relations between the user and organisations */
export type ClientSecurityLogsArgs = {
  where?: InputMaybe<SecurityLogsWhereInput>;
};

export enum ClientClassification {
  Domiciliary = 'Domiciliary',
  Operating = 'Operating',
  Unknown = 'Unknown'
}

export type ClientClosure = BaseEntity & {
  __typename?: 'ClientClosure';
  admin: Admin;
  adminId: Scalars['String']['output'];
  client: Client;
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  nodes: Array<Client>;
  page: PageInfo;
};

export enum ClientFeature {
  ApiAccess = 'ApiAccess',
  Issuance = 'Issuance',
  PlatformAccess = 'PlatformAccess',
  Redemption = 'Redemption'
}

export type ClientInteractionStatistics = ITokenInteractionStatistics & {
  __typename?: 'ClientInteractionStatistics';
  counter: Scalars['Int']['output'];
  currentValue: Scalars['Decimal']['output'];
  fees: Scalars['Decimal']['output'];
  issuances: InteractionBaseStatisticsType;
  lastOrder: Scalars['DateTime']['output'];
  maxValue: Scalars['Decimal']['output'];
  redemptions: InteractionBaseStatisticsType;
};

/** Interfaced, used on all entities that are owned by a client */
export type ClientOwned = {
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
};

export type ClientPreferences = BaseEntity & {
  __typename?: 'ClientPreferences';
  bankAccountPayoutCurrency: FiatCurrency;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  defaultBankAccount?: Maybe<BankAccount>;
  defaultBankAccountId?: Maybe<Scalars['String']['output']>;
  defaultStablecoin?: Maybe<Stablecoin>;
  defaultStablecoinId?: Maybe<Scalars['String']['output']>;
  executionMode: ExecutionMode;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  payoutMethod: PayoutMethod;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientQuestionnaire = BaseEntity & {
  __typename?: 'ClientQuestionnaire';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  result?: Maybe<ClientQuestionnaireResult>;
  status: ClientQuestionnaireStatus;
  type: ClientQuestionnaireType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ClientQuestionnaireResult {
  Fail = 'Fail',
  Pass = 'Pass'
}

export enum ClientQuestionnaireStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export enum ClientQuestionnaireType {
  OnboardingQuestionnaire = 'OnboardingQuestionnaire'
}

export type ClientQuestionnaireWhereInput = {
  type?: InputMaybe<ClientQuestionnaireType>;
};

export enum ClientStatus {
  Active = 'Active',
  Closed = 'Closed',
  Onboarding = 'Onboarding',
  Rejected = 'Rejected'
}

export type ClientStatusFilter = {
  equals?: InputMaybe<ClientStatus>;
  in?: InputMaybe<Array<ClientStatus>>;
  not?: InputMaybe<ClientStatus>;
  notIn?: InputMaybe<Array<ClientStatus>>;
};

export enum ClientType {
  Foundation = 'Foundation',
  Fund = 'Fund',
  Individual = 'Individual',
  Institution = 'Institution'
}

export type ClientUsage = {
  __typename?: 'ClientUsage';
  issuanceDailyUsage: Scalars['Decimal']['output'];
  issuanceMonthlyUsage: Scalars['Decimal']['output'];
  redemptionDailyUsage: Scalars['Decimal']['output'];
  redemptionMonthlyUsage: Scalars['Decimal']['output'];
  usages: Array<Usage>;
};

export type ClientsFilter = {
  AND?: InputMaybe<Array<ClientsFilter>>;
  NOT?: InputMaybe<Array<ClientsFilter>>;
  OR?: InputMaybe<Array<ClientsFilter>>;
  clientName?: InputMaybe<StringFilter>;
  clientType?: InputMaybe<ClientType>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<StringFilter>;
  humanId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<ClientStatus>;
  statusFilter?: InputMaybe<ClientStatusFilter>;
  type?: InputMaybe<ClientType>;
};

export type CloseClientInput = {
  clientId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type Collateral = BaseEntity & {
  __typename?: 'Collateral';
  ISINName?: Maybe<Scalars['String']['output']>;
  ISINNumber?: Maybe<Scalars['String']['output']>;
  balance: Scalars['Int']['output'];
  clearanceAccounts: Array<ClearanceAccount>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  custodyAccounts: Array<CustodyAccountFacet>;
  exchange?: Maybe<Exchange>;
  exchangeId?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  inTransitBalance: Scalars['Int']['output'];
  lastKnownPrice: Scalars['Decimal']['output'];
  oracles: Array<Oracle>;
  pendingBalance: Scalars['Int']['output'];
  /** The price of the collateral in its native currency */
  price?: Maybe<Scalars['Float']['output']>;
  priceCurrency: FiatCurrency;
  publiclyListed?: Maybe<Scalars['Boolean']['output']>;
  symbol: Scalars['String']['output'];
  tokens: Array<Token>;
  tradingViewId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  usedBalance: Scalars['String']['output'];
};

export type Company = BaseEntity & {
  __typename?: 'Company';
  adminPermissions: Array<AdminPermission>;
  appHostPrefix: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  features: Array<CompanyFeature>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  nodes: Array<Company>;
  page: PageInfo;
};

export enum CompanyFeature {
  ApiAccess = 'API_Access',
  Blockchain = 'Blockchain',
  BlockchainAdvanced = 'Blockchain_Advanced',
  Brokerage = 'Brokerage',
  Clients = 'Clients',
  Compliance = 'Compliance',
  Custody = 'Custody',
  Dashboard = 'Dashboard',
  Fees = 'Fees',
  Flows = 'Flows',
  Interactions = 'Interactions',
  InteractionsBankTransfers = 'Interactions_Bank_Transfers',
  Product = 'Product',
  System = 'System',
  SystemRoles = 'SystemRoles',
  Utils = 'Utils'
}

/** Interfaced, used on all entities that are owned by a company */
export type CompanyRelation = {
  /** The company, that owns this entity */
  company: Company;
  /** The ID of the company, that owns this entity */
  companyId: Scalars['String']['output'];
};

export type CompanyWhereUniqueInput = {
  /** The ID of the client that you want to retrieve. If it is not provided the currently used company will be returned */
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CompanysWhereInput = {
  AND?: InputMaybe<Array<CompanysWhereInput>>;
  OR?: InputMaybe<Array<CompanysWhereInput>>;
  name?: InputMaybe<StringFilter>;
};

export type ComplianceComment = BaseEntity & {
  __typename?: 'ComplianceComment';
  admin: Admin;
  adminId: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ComplianceCommentConnectionsInput = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  incomingTransactionId?: InputMaybe<Scalars['String']['input']>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  riskAssessmentId?: InputMaybe<Scalars['String']['input']>;
};

export type ComplianceCommentsWhereInput = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  incomingTransactionId?: InputMaybe<Scalars['String']['input']>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  riskAssessmentId?: InputMaybe<Scalars['String']['input']>;
};

export type ComplianceReview = BaseEntity & {
  __typename?: 'ComplianceReview';
  client: Client;
  complianceOfficer?: Maybe<Admin>;
  complianceOfficerId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  featuresAllowed: Array<ClientFeature>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isProfessional: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  status: ComplianceReviewStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  verificationProfile: VerificationProfile;
  verificationProfileId: Scalars['String']['output'];
};

export enum ComplianceReviewStatus {
  Active = 'Active',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type ContactInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ContractFactory = BaseEntity & {
  __typename?: 'ContractFactory';
  address: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  networks: Array<BlockchainNetwork>;
  ownership: Scalars['JSON']['output'];
  type: ContractFactoryType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ContractFactoryType {
  AutoFeeMultiTenantTokenFactory = 'AutoFeeMultiTenantTokenFactory',
  AutoFeeTokenFactory = 'AutoFeeTokenFactory',
  OracleFactory = 'OracleFactory',
  TokenFactory = 'TokenFactory',
  Unknown = 'Unknown'
}

export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Unknown = 'Unknown',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type CreateAdminUserInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions: Array<AdminPermission>;
};

export type CreateBankAccountInput = {
  bankAddress: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  swift: Scalars['String']['input'];
};

export type CreateBlockchainAddressInput = {
  address: Scalars['String']['input'];
  addressType: BlockchainAddressType;
  description: Scalars['String']['input'];
};

export type CreateClientInput = {
  /** Boolean, specifying if the client is okay with receiving marketing communication */
  acceptsMarketingCommunication?: InputMaybe<Scalars['Boolean']['input']>;
  classification?: InputMaybe<ClientClassification>;
  /** The type of client that will be created */
  type: ClientType;
  /** Data, needed for adding the first user of that client */
  user: CreateUserInput;
};

export type CreateClientResult = {
  __typename?: 'CreateClientResult';
  client?: Maybe<Client>;
  result: CreateClientResultEnum;
};

export enum CreateClientResultEnum {
  NotInvited = 'NotInvited',
  Successful = 'Successful',
  Unsuccessful = 'Unsuccessful'
}

export type CreateCollateralInput = {
  ISINName: Scalars['String']['input'];
  ISINNumber: Scalars['String']['input'];
  incoreClientDepositNumber?: InputMaybe<Scalars['String']['input']>;
  symbol: Scalars['String']['input'];
  tradingViewId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyInput = {
  appHostPrefix: Scalars['String']['input'];
  name: Scalars['String']['input'];
  superAdminEmail: Scalars['String']['input'];
};

export type CreateComplianceCommentInput = {
  comment: Scalars['String']['input'];
  connections: ComplianceCommentConnectionsInput;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateComplianceReviewInput = {
  clientId: Scalars['String']['input'];
};

export type CreateCustodyAccountFacetInput = {
  accountId: Scalars['String']['input'];
  label: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
  type: CustodyAccountFacetType;
};

export type CreateDeploymentInput = {
  deployOraclesOn: Array<OracleContractDeploymentInput>;
  tokenContracts: Array<TokenContractDeploymentInput>;
  tokenId: Scalars['String']['input'];
};

export type CreateDocumentFolderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type CreateDocumentInput = {
  clientId: Scalars['String']['input'];
  document: Scalars['Upload']['input'];
  folderId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateEodFormInput = {
  brokerId?: InputMaybe<Scalars['String']['input']>;
  collateralId?: InputMaybe<Scalars['String']['input']>;
  orderSide?: InputMaybe<OrderSide>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  settlementDate?: InputMaybe<Scalars['String']['input']>;
  settlementId?: InputMaybe<Scalars['String']['input']>;
  tradeDate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExternalWalletInput = {
  signedMessage: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type CreateExternalWalletManuallyInput = {
  address: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExternalWalletMicroTransactionInput = {
  walletAddress: Scalars['String']['input'];
};

export type CreateExternalWalletSigningMessageInput = {
  type: ExternalWalletSigningMessageType;
  /** The address of the wallet that the client wants to register */
  walletAddress: Scalars['String']['input'];
};

export type CreateFlowExecutionInput = {
  flowExecutionPayload: Scalars['JSON']['input'];
  flowType: FlowType;
};

export type CreateInteractionReportInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  interactionId: Scalars['String']['input'];
  type: InteractionReportType;
};

export type CreateInvitationInput = {
  /** The email address of the client that you want to invite */
  email: Scalars['String']['input'];
  /** Boolean, allowing to resend the invitation to the email */
  resend?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateNetworkSimulatorRuleInput = {
  id: Scalars['String']['input'];
  pathRegex: Scalars['String']['input'];
  payloadRegex: Scalars['String']['input'];
  transformScript: Scalars['String']['input'];
};

export type CreateOracleInput = {
  address: Scalars['String']['input'];
  collateralId: Scalars['String']['input'];
  forwarderAddress?: InputMaybe<Scalars['String']['input']>;
  network: BlockchainNetwork;
  updateSchedule: OracleUpdateSchedule;
};

export type CreateRefundInput = {
  interactionId: Scalars['String']['input'];
  reasonForRefund: Scalars['String']['input'];
};

export type CreateRiskAssessmentInput = {
  bypassAutomaticChecks?: InputMaybe<Scalars['Boolean']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  verificationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoleInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissions: Array<AdminPermission>;
};

export type CreateServiceAccountInput = {
  ipRange?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<AdminPermission>;
  title: Scalars['String']['input'];
};

export type CreateSystemWalletInput = {
  /** List of blockchain networks, on which this wallet can be used */
  network?: InputMaybe<Array<BlockchainNetwork>>;
  /** The private key, if importing wallet. If not provided a random wallet will be created */
  privateKey?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTokenDeploymentInput = {
  /** The address of the account, that will be marked as burner of the deployed contract */
  burnerAddress: Scalars['String']['input'];
  /** The address of the account, that will be marked as minter of the deployed contract */
  minterAddress: Scalars['String']['input'];
  network: BlockchainNetwork;
  /** The address of the cold wallet, that will be marked as owner of the deployed contract */
  ownerAddress: Scalars['String']['input'];
  /** The address of the account, that will be marked as pauser of the deployed contract */
  pauserAddress: Scalars['String']['input'];
  /** The address of the smart contract that is oracle for sanctions screening */
  sanctionListAddress: Scalars['String']['input'];
  systemWalletId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type CreateTokenInput = {
  /** Fee accrual period in seconds */
  accrualSchedule?: InputMaybe<Scalars['Int']['input']>;
  /** Annual fee accrued on token */
  annualFee?: InputMaybe<Scalars['Int']['input']>;
  /** Flag, whether given token will have constant ratio */
  constantRatio: Scalars['Boolean']['input'];
  /** Short description of the token used for UI purposes */
  description: Scalars['String']['input'];
  icon?: InputMaybe<Scalars['Upload']['input']>;
  /** Starting point of fee accrual */
  initialFeeAccrualTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  /** Unique isin of the token. */
  isin?: InputMaybe<Scalars['String']['input']>;
  /** Display name of the token */
  name: Scalars['String']['input'];
  /** Unique symbol of the token. Must match the symbol of the deployed contracts */
  tokenSymbol: Scalars['String']['input'];
};

/** The input, used for creating new user */
export type CreateUserInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** Valid date string */
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Country>;
  secondName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVerificationTokenInput = {
  externalClientId: Scalars['String']['input'];
  verificationId: Scalars['String']['input'];
};

export enum CurrencyType {
  Crypto = 'Crypto',
  Fiat = 'Fiat'
}

export type CustodyAccount = BaseEntity & {
  __typename?: 'CustodyAccount';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** Events, that are related to that account */
  events: Array<CustodyAccountEvent>;
  externalId: Scalars['String']['output'];
  facets: Array<CustodyAccountFacet>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type CustodyAccountEvent = BaseEntity & {
  __typename?: 'CustodyAccountEvent';
  changeAmount: Scalars['Decimal']['output'];
  changeCurrency: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  flowDirection?: Maybe<CustodyAccountEventFlowDirection>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was created */
  tookPlaceAt: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum CustodyAccountEventFlowDirection {
  Credit = 'Credit',
  Debit = 'Debit'
}

export type CustodyAccountFacet = BaseEntity & {
  __typename?: 'CustodyAccountFacet';
  accountId?: Maybe<Scalars['String']['output']>;
  asset: Scalars['String']['output'];
  balance: Scalars['Decimal']['output'];
  balanceChanges: Array<CustodyAccountFacetBalanceChange>;
  collateral?: Maybe<Collateral>;
  collateralId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isWorkingCapital?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  type: CustodyAccountFacetType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type CustodyAccountFacetBalanceChange = BaseEntity & {
  __typename?: 'CustodyAccountFacetBalanceChange';
  balance: Scalars['Decimal']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum CustodyAccountFacetType {
  Fiat = 'Fiat',
  Security = 'Security'
}

export type CustodyAccountTransfer = BaseEntity & {
  __typename?: 'CustodyAccountTransfer';
  amount: Scalars['Float']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  from: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  status: CustodyAccountTransferStatus;
  to: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum CustodyAccountTransferStatus {
  Completed = 'Completed',
  Pending = 'Pending'
}

export type CustodyAccountWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type DateFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeactivateExternalWalletInput = {
  externalWalletId: Scalars['String']['input'];
};

export type DefineTokenCollateralInput = {
  ISINName: Scalars['String']['input'];
  ISINNumber: Scalars['String']['input'];
  publiclyListed: Scalars['Boolean']['input'];
  startingNav?: InputMaybe<Scalars['Float']['input']>;
  symbol: Scalars['String']['input'];
};

export type DeleteBlockchainAddressInput = {
  blockchainAddressId: Scalars['String']['input'];
};

export type DeleteDocumentFolderInput = {
  folderId: Scalars['String']['input'];
};

export type DeleteFeeConfigurationInput = {
  feeConfigurationId: Scalars['String']['input'];
};

export type DeleteNetworkSimulatorRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteRoleInput = {
  roleId: Scalars['String']['input'];
};

export type Deployment = AdminRelation & BaseEntity & {
  __typename?: 'Deployment';
  /** The admin, that owns this entity */
  admin: Admin;
  /** The ID of the admin, that owns this entity */
  adminId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  status: DeploymentStatus;
  tasks: Array<DeploymentTask>;
  token: Token;
  tokenId: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type DeploymentConnection = {
  __typename?: 'DeploymentConnection';
  nodes: Array<Deployment>;
  page: PageInfo;
};

export type DeploymentInput = {
  network: BlockchainNetwork;
};

export enum DeploymentStatus {
  Completed = 'Completed',
  Created = 'Created',
  Failed = 'Failed',
  FailedPartially = 'FailedPartially',
  InProgress = 'InProgress'
}

export type DeploymentTask = BaseEntity & {
  __typename?: 'DeploymentTask';
  contractAddress?: Maybe<Scalars['String']['output']>;
  contractType: DeploymentTaskContractType;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  network: BlockchainNetwork;
  payload: Scalars['JSON']['output'];
  status: DeploymentStatus;
  steps: Scalars['JSON']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum DeploymentTaskContractType {
  Oracle = 'Oracle',
  Token = 'Token'
}

export type DeploymentsWhereInput = {
  tokenId?: InputMaybe<Scalars['String']['input']>;
};

export type DisableAdminInput = {
  adminId: Scalars['String']['input'];
};

export type DisableServiceAccountMutationInput = {
  serviceAccountId: Scalars['String']['input'];
};

export type Document = BaseEntity & {
  __typename?: 'Document';
  client: Client;
  clientId: Scalars['String']['output'];
  comments: Array<ComplianceComment>;
  commentsCount: Scalars['Int']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isPendingDeletion: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DocumentType>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<Admin>;
  uploaderId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentDeletionRequest = BaseEntity & {
  __typename?: 'DocumentDeletionRequest';
  approvers: Array<Admin>;
  archivedDocument: Scalars['JSON']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  requester: Admin;
  requesterId: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  wasExecuted: Scalars['Boolean']['output'];
};

export type DocumentFolder = BaseEntity & {
  __typename?: 'DocumentFolder';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  files: Array<Document>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isImmutable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<DocumentFolder>;
  parentId?: Maybe<Scalars['String']['output']>;
  subFolders: Array<DocumentFolder>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum DocumentType {
  ManualClientProfile = 'ManualClientProfile',
  ManualProfessionalInvestorForm = 'ManualProfessionalInvestorForm',
  ManualRiskAssessment = 'ManualRiskAssessment',
  Other = 'Other'
}

export type DocumentsWhereInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
};

export type EodData = {
  __typename?: 'EodData';
  url: Scalars['String']['output'];
};

export type Event = BaseEntity & {
  __typename?: 'Event';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  failures?: Maybe<Scalars['JSON']['output']>;
  /** Registered handlers */
  handlers: Array<Maybe<Scalars['String']['output']>>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  payload?: Maybe<Scalars['JSON']['output']>;
  /** Retry attempts */
  retryAttempts: Array<Maybe<EventRetryAttempt>>;
  status: EventStatus;
  type: EventType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type EventConnection = {
  __typename?: 'EventConnection';
  nodes: Array<Event>;
  page: PageInfo;
};

export type EventRetryAttempt = BaseEntity & {
  __typename?: 'EventRetryAttempt';
  comment?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  forFailures?: Maybe<Scalars['JSON']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum EventStatus {
  FailedFinal = 'FailedFinal',
  FailedWillRetry = 'FailedWillRetry',
  Pending = 'Pending',
  Processing = 'Processing',
  Succeed = 'Succeed'
}

export enum EventType {
  AlmLimitThresholdCrossed = 'ALMLimitThresholdCrossed',
  AmlLimitThresholdCrossed = 'AMLLimitThresholdCrossed',
  AdminCreated = 'AdminCreated',
  AdminManuallyUpdated = 'AdminManuallyUpdated',
  AlchemyPingReceived = 'AlchemyPingReceived',
  BalanceUpdated = 'BalanceUpdated',
  BankAccountCreated = 'BankAccountCreated',
  BankTransferCreated = 'BankTransferCreated',
  BankTransferExecuted = 'BankTransferExecuted',
  BlockchainAddressCreated = 'BlockchainAddressCreated',
  BlockchainAddressDeleted = 'BlockchainAddressDeleted',
  BlockchainAddressUpdated = 'BlockchainAddressUpdated',
  BlockchainReportGenerated = 'BlockchainReportGenerated',
  BlockchainReportRequested = 'BlockchainReportRequested',
  BlockchainTransactionReportRequested = 'BlockchainTransactionReportRequested',
  BlockchainWalletReportRequested = 'BlockchainWalletReportRequested',
  ClientCreated = 'ClientCreated',
  ClientFrozen = 'ClientFrozen',
  ClientInvitationCreated = 'ClientInvitationCreated',
  ClientManuallyUpdated = 'ClientManuallyUpdated',
  ClientStatusUpdated = 'ClientStatusUpdated',
  ClientTransactionPreferencesUpdated = 'ClientTransactionPreferencesUpdated',
  ClientUnfrozen = 'ClientUnfrozen',
  CompanyCreated = 'CompanyCreated',
  ComplianceReviewApproved = 'ComplianceReviewApproved',
  ComplianceReviewCreated = 'ComplianceReviewCreated',
  ComplianceReviewRejected = 'ComplianceReviewRejected',
  CustodyAccountEventCreated = 'CustodyAccountEventCreated',
  CustodyAccountFacetBalanceChanged = 'CustodyAccountFacetBalanceChanged',
  CustodyAccountTransferCompleted = 'CustodyAccountTransferCompleted',
  DeploymentCompleted = 'DeploymentCompleted',
  DeploymentRequested = 'DeploymentRequested',
  DeploymentTaskCompleted = 'DeploymentTaskCompleted',
  DeploymentTaskCreated = 'DeploymentTaskCreated',
  DepositToConversionExchange = 'DepositToConversionExchange',
  DocumentDeleted = 'DocumentDeleted',
  DocumentDeletionApproved = 'DocumentDeletionApproved',
  DocumentDeletionRequested = 'DocumentDeletionRequested',
  DocumentFinalized = 'DocumentFinalized',
  EmailChallengeRequested = 'EmailChallengeRequested',
  EmsOrderExecuted = 'EmsOrderExecuted',
  EmsOrderRejected = 'EmsOrderRejected',
  ExternalWalletActivated = 'ExternalWalletActivated',
  ExternalWalletAwaitingForProofOfControl = 'ExternalWalletAwaitingForProofOfControl',
  ExternalWalletCreated = 'ExternalWalletCreated',
  ExternalWalletDeactivated = 'ExternalWalletDeactivated',
  ExternalWalletRegistrationRepeated = 'ExternalWalletRegistrationRepeated',
  ExternalWalletScored = 'ExternalWalletScored',
  ExternalWalletSigningMessageCreated = 'ExternalWalletSigningMessageCreated',
  FeeAmended = 'FeeAmended',
  FeeUpdated = 'FeeUpdated',
  FlowExecutionBurnCreated = 'FlowExecutionBurnCreated',
  FlowExecutionBurnUpdated = 'FlowExecutionBurnUpdated',
  FlowExecutionCreated = 'FlowExecutionCreated',
  FlowExecutionMintCreated = 'FlowExecutionMintCreated',
  FlowExecutionMintUpdated = 'FlowExecutionMintUpdated',
  FlowExecutionUpdated = 'FlowExecutionUpdated',
  IncomingTransactionApproved = 'IncomingTransactionApproved',
  IncomingTransactionChecked = 'IncomingTransactionChecked',
  IncomingTransactionConfirmed = 'IncomingTransactionConfirmed',
  IncomingTransactionCreated = 'IncomingTransactionCreated',
  IncomingTransactionForApproval = 'IncomingTransactionForApproval',
  IncomingTransactionInjected = 'IncomingTransactionInjected',
  IncomingTransactionLinkedToExternalWallet = 'IncomingTransactionLinkedToExternalWallet',
  IncomingTransactionManualVerificationRequired = 'IncomingTransactionManualVerificationRequired',
  IncomingTransactionScored = 'IncomingTransactionScored',
  IncomingTransactionValueVerified = 'IncomingTransactionValueVerified',
  IncomingTransactionWithNativeTokensReceived = 'IncomingTransactionWithNativeTokensReceived',
  InteractionCreated = 'InteractionCreated',
  InteractionInjected = 'InteractionInjected',
  InteractionReportCreated = 'InteractionReportCreated',
  InteractionReportRemoved = 'InteractionReportRemoved',
  InteractionUpdated = 'InteractionUpdated',
  InternalTransactionCreated = 'InternalTransactionCreated',
  InternalTransactionExecuted = 'InternalTransactionExecuted',
  InternalTransactionFailed = 'InternalTransactionFailed',
  IssuanceCreated = 'IssuanceCreated',
  IssuanceInjected = 'IssuanceInjected',
  IssuanceStatusUpdated = 'IssuanceStatusUpdated',
  LoginAttemptCreated = 'LoginAttemptCreated',
  OracleAnswerStatusUpdated = 'OracleAnswerStatusUpdated',
  OracleAnswerUpdateRequested = 'OracleAnswerUpdateRequested',
  OracleAnswerUpdated = 'OracleAnswerUpdated',
  OrderCreated = 'OrderCreated',
  OrderExecuted = 'OrderExecuted',
  OrderInjected = 'OrderInjected',
  OrderMarkedForManualExecution = 'OrderMarkedForManualExecution',
  OrderReadyForExecution = 'OrderReadyForExecution',
  OrderRequirementsChecked = 'OrderRequirementsChecked',
  OrderSimulated = 'OrderSimulated',
  OrderSubmitted = 'OrderSubmitted',
  OrderSubmittedManually = 'OrderSubmittedManually',
  OutgoingTransactionApproved = 'OutgoingTransactionApproved',
  OutgoingTransactionCreated = 'OutgoingTransactionCreated',
  OutgoingTransactionExecuted = 'OutgoingTransactionExecuted',
  OutgoingTransactionFailed = 'OutgoingTransactionFailed',
  OutgoingTransactionForApproval = 'OutgoingTransactionForApproval',
  OutgoingTransactionInjected = 'OutgoingTransactionInjected',
  OutgoingTransactionManualVerificationRequired = 'OutgoingTransactionManualVerificationRequired',
  OutgoingTransactionRejected = 'OutgoingTransactionRejected',
  OutgoingTransactionValueVerified = 'OutgoingTransactionValueVerified',
  ProofOfReservesUndercollateralisationOccurred = 'ProofOfReservesUndercollateralisationOccurred',
  QuestionnaireFinalised = 'QuestionnaireFinalised',
  RedemptionCreated = 'RedemptionCreated',
  RedemptionInjected = 'RedemptionInjected',
  RedemptionStatusUpdated = 'RedemptionStatusUpdated',
  RefundCreated = 'RefundCreated',
  RefundStatusUpdated = 'RefundStatusUpdated',
  RiskAssessmentCreated = 'RiskAssessmentCreated',
  RiskAssessmentFilled = 'RiskAssessmentFilled',
  RiskAssessmentUpdated = 'RiskAssessmentUpdated',
  RoleCreated = 'RoleCreated',
  RoleDeleted = 'RoleDeleted',
  RoleUpdated = 'RoleUpdated',
  SafeCreated = 'SafeCreated',
  ServiceAccountCreated = 'ServiceAccountCreated',
  ServiceAccountDisabled = 'ServiceAccountDisabled',
  ServiceAccountExpired = 'ServiceAccountExpired',
  ServiceAccountUpdated = 'ServiceAccountUpdated',
  SettlementCustodyAccountEventNotMatched = 'SettlementCustodyAccountEventNotMatched',
  SettlementSubmitted = 'SettlementSubmitted',
  StaleSettlementsDetected = 'StaleSettlementsDetected',
  SumsubPingReceived = 'SumsubPingReceived',
  SupplyControlOperationRequested = 'SupplyControlOperationRequested',
  SupplyControlOperationStatusChanged = 'SupplyControlOperationStatusChanged',
  SupplyControlSignatureProvided = 'SupplyControlSignatureProvided',
  SweepCompleted = 'SweepCompleted',
  SweepingWalletCreated = 'SweepingWalletCreated',
  SystemReportCreated = 'SystemReportCreated',
  SystemTransactionFromKnownAddressDetected = 'SystemTransactionFromKnownAddressDetected',
  SystemTransactionToKnownAddressDetected = 'SystemTransactionToKnownAddressDetected',
  SystemUnrecognizedTransactionDetected = 'SystemUnrecognizedTransactionDetected',
  SystemWalletCreated = 'SystemWalletCreated',
  SystemWalletUnderfunded = 'SystemWalletUnderfunded',
  TokenDeploymentContractDeployed = 'TokenDeploymentContractDeployed',
  TokenDeploymentContractValidated = 'TokenDeploymentContractValidated',
  TokenDeploymentCreated = 'TokenDeploymentCreated',
  TokenDeploymentOwnershipChange = 'TokenDeploymentOwnershipChange',
  TokenMultiplierChanged = 'TokenMultiplierChanged',
  TokenRegistered = 'TokenRegistered',
  UndercollateralisationOccurred = 'UndercollateralisationOccurred',
  UsageFrozen = 'UsageFrozen',
  UsageLimitsUpdated = 'UsageLimitsUpdated',
  UsageNotWithinSystemLowerLimits = 'UsageNotWithinSystemLowerLimits',
  UsageNotWithinSystemUpperLimits = 'UsageNotWithinSystemUpperLimits',
  UsageWithinLimits = 'UsageWithinLimits',
  UserCreated = 'UserCreated',
  UserMultiFactorEnabled = 'UserMultiFactorEnabled',
  UserPasswordRemoved = 'UserPasswordRemoved',
  UserPasswordSet = 'UserPasswordSet',
  UserUpdated = 'UserUpdated',
  VerificationCreated = 'VerificationCreated',
  VerificationFailed = 'VerificationFailed',
  VerificationPending = 'VerificationPending',
  VerificationSucceeded = 'VerificationSucceeded',
  VerificationTierUpdated = 'VerificationTierUpdated'
}

export type EventWhereInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventStatus>;
  type?: InputMaybe<EventType>;
};

export type EventWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type Exchange = BaseEntity & {
  __typename?: 'Exchange';
  abbreviation: Scalars['String']['output'];
  bankHolidays: Array<Scalars['DateTime']['output']>;
  closeTime: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isOpen: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  openTime: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  workingDays: Array<WeekDay>;
};

export type ExecuteBankTransferInput = {
  amount: Scalars['Decimal']['input'];
  bankAccountId?: InputMaybe<Scalars['String']['input']>;
  bankTransferId: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type ExecuteInjectionInput = {
  injectionId: Scalars['String']['input'];
};

export type ExecuteOrderInput = {
  brokerId: Scalars['String']['input'];
  exchangeRate: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  pricePerShare: Scalars['String']['input'];
  providerReferenceId: Scalars['String']['input'];
  settlementDelay: Scalars['Int']['input'];
  totalShares: Scalars['Float']['input'];
};

export type ExecuteRefundInput = {
  refundId: Scalars['String']['input'];
};

export type ExecuteTradeInput = {
  amount: Scalars['Int']['input'];
  baseAssetId: Scalars['String']['input'];
  brokerId: Scalars['String']['input'];
  quoteAssetId: Scalars['String']['input'];
  side: TradeSide;
};

export enum ExecutionMode {
  EndOfDay = 'EndOfDay',
  Market = 'Market'
}

export type ExpectedPayout = {
  __typename?: 'ExpectedPayout';
  collateral: Collateral;
  /** Shortage of collateral required to safisfy interaction needs */
  collateralShortage: Scalars['Decimal']['output'];
  /** Amount of fee to be collected */
  feeAmount: Scalars['Decimal']['output'];
  /** Symbol of fee currency transferred */
  feeSymbol: Scalars['String']['output'];
  /** Amount that given collateral can cover */
  overcollateral: Scalars['Decimal']['output'];
  /** Amount that given collateral can cover */
  payoutAmount: Scalars['Decimal']['output'];
  /** Decimal of currency transferred */
  payoutDecimals?: Maybe<Scalars['Int']['output']>;
  /** Symbol of currency transferred */
  payoutSymbol: Scalars['String']['output'];
  /** Indicates whether payout is in crypto or fiat */
  payoutType: CurrencyType;
};

/** Object, representing a wallet that interacts with the system in any way, but is not operated by it */
export type ExternalWallet = BaseEntity & ClientOwned & {
  __typename?: 'ExternalWallet';
  /** The address, at which the wallet is located */
  address: Scalars['String']['output'];
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  reports: Array<Maybe<BlockchainReport>>;
  scores: Array<ExternalWalletScore>;
  status: ExternalWalletStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ExternalWalletMicroTransaction = BaseEntity & ClientOwned & {
  __typename?: 'ExternalWalletMicroTransaction';
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  externalWallet?: Maybe<ExternalWallet>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  network?: Maybe<BlockchainNetwork>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  verifyAmount: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type ExternalWalletScore = BaseEntity & {
  __typename?: 'ExternalWalletScore';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  externalWallet?: Maybe<ExternalWallet>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  network?: Maybe<BlockchainNetwork>;
  payload?: Maybe<Scalars['JSON']['output']>;
  provider?: Maybe<ExternalWalletScoreProvider>;
  score: Scalars['Int']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

/** Enum, containing all external wallet score providers */
export enum ExternalWalletScoreProvider {
  Coinfirm = 'Coinfirm',
  Scorechain = 'Scorechain'
}

export type ExternalWalletSigningMessage = BaseEntity & ClientOwned & {
  __typename?: 'ExternalWalletSigningMessage';
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  signedMessage?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  walletAddress: Scalars['String']['output'];
};

/** Enum, containing all external wallet signing message types */
export enum ExternalWalletSigningMessageType {
  Activate = 'Activate',
  Create = 'Create'
}

/** Enum, containing all external wallet statuses */
export enum ExternalWalletStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  WaitingForProofOfControl = 'WaitingForProofOfControl'
}

export type Fee = BaseEntity & {
  __typename?: 'Fee';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currency: FiatCurrency;
  /** The fee that was taken in US cents */
  fee: Scalars['Decimal']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  internalTransaction?: Maybe<InternalTransaction>;
  invoice?: Maybe<Invoice>;
  principal: Scalars['Decimal']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type FeeAccrual = BaseEntity & {
  __typename?: 'FeeAccrual';
  circulatingSupply: Scalars['Decimal']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  nominalFeeCollected: Scalars['Decimal']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type FeeConfigurationsWhereInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
};

export enum FeeTier {
  IndividualDefault = 'IndividualDefault',
  InstitutionalDefault = 'InstitutionalDefault'
}

export type FeesConfiguration = BaseEntity & {
  __typename?: 'FeesConfiguration';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  issuanceInitialFee: Scalars['Int']['output'];
  issuanceMinimumFee: Scalars['Int']['output'];
  issuancePercentageFee: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  redemptionInitialFee: Scalars['Int']['output'];
  redemptionMinimumFee: Scalars['Int']['output'];
  redemptionPercentageFee: Scalars['Float']['output'];
  tier?: Maybe<FeeTier>;
  tokenDeployment?: Maybe<TokenDeployment>;
  tokenDeploymentId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type FeesConfigurationConnection = {
  __typename?: 'FeesConfigurationConnection';
  nodes: Array<FeesConfiguration>;
  page: PageInfo;
};

export enum FiatCurrency {
  Chf = 'CHF',
  Eur = 'EUR',
  Usd = 'USD'
}

export type File = {
  __typename?: 'File';
  authorisedUrl: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export enum FilteringMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type FinaliseClientQuestionnaireInput = {
  questionnaireId: Scalars['ID']['input'];
};

export type FlagsUpdatePayload = {
  push?: InputMaybe<Array<InteractionFlag>>;
  set?: InputMaybe<Array<InteractionFlag>>;
};

export type FlowExecution = BaseEntity & {
  __typename?: 'FlowExecution';
  burn?: Maybe<Burn>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  flowType: FlowType;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  mint?: Maybe<Mint>;
  status: FlowExecutionStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type FlowExecutionConnection = {
  __typename?: 'FlowExecutionConnection';
  nodes: Array<FlowExecution>;
  page: PageInfo;
};

export enum FlowExecutionStatus {
  Completed = 'Completed',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing'
}

export type FlowExecutionWhereInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FlowExecutionWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export enum FlowType {
  Burn = 'Burn',
  Mint = 'Mint'
}

export enum GaslessStrategy {
  Authorization = 'Authorization',
  BasicTransfer = 'BasicTransfer',
  Delegated = 'Delegated',
  Permit = 'Permit'
}

export type GenerateInvoicePdfInput = {
  invoiceId: Scalars['String']['input'];
};

export type GetExternalWalletsWhere = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  network?: InputMaybe<BlockchainNetwork>;
};

export type GetSweepingWalletsWhere = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  network?: InputMaybe<BlockchainNetwork>;
};

export type GetSystemWalletsWhereInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type IInteractionStatistics = {
  counter: Scalars['Int']['output'];
  fees: Scalars['Decimal']['output'];
  value: Scalars['Decimal']['output'];
};

export type ITokenInteractionStatistics = {
  counter: Scalars['Int']['output'];
  currentValue: Scalars['Decimal']['output'];
  fees: Scalars['Decimal']['output'];
  lastOrder: Scalars['DateTime']['output'];
  maxValue: Scalars['Decimal']['output'];
};

export type ImportTokenDeploymentInput = {
  address: Scalars['String']['input'];
  network: BlockchainNetwork;
  systemWalletId: Scalars['String']['input'];
};

export type IncomingTransaction = BaseEntity & ClientOwned & {
  __typename?: 'IncomingTransaction';
  amount: Scalars['String']['output'];
  benefactorAddress: Scalars['String']['output'];
  beneficiaryAddress: Scalars['String']['output'];
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** Compliance comments linked to this transaction */
  comments: Array<Maybe<ComplianceComment>>;
  confirmations: Scalars['Int']['output'];
  confirmationsNeeded: Scalars['Int']['output'];
  contractAddress: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  decimals: Scalars['Int']['output'];
  displayAmount: Scalars['String']['output'];
  /** A URL that leads to explorer page about that transaction */
  explorerUrl: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  interaction?: Maybe<Interaction>;
  interactionId?: Maybe<Scalars['String']['output']>;
  isDepegged: Scalars['Boolean']['output'];
  isHighRiskScore?: Maybe<Scalars['Boolean']['output']>;
  network: BlockchainNetwork;
  problems: Array<IncomingTransactionProblem>;
  problemsChecked: Scalars['Boolean']['output'];
  refund?: Maybe<Refund>;
  report?: Maybe<BlockchainReport>;
  score?: Maybe<Scalars['Int']['output']>;
  scoreDetails?: Maybe<Scalars['JSON']['output']>;
  scoreProvider?: Maybe<IncomingTransactionScoreProvider>;
  status: IncomingTransactionStatus;
  tokenDeployment?: Maybe<TokenDeployment>;
  tokenDeploymentId?: Maybe<Scalars['String']['output']>;
  tokenSymbol: Scalars['String']['output'];
  type: IncomingTransactionType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Decimal']['output'];
  valueCurrency: FiatCurrency;
};

export type IncomingTransactionConnection = {
  __typename?: 'IncomingTransactionConnection';
  nodes: Array<IncomingTransaction>;
  page: PageInfo;
};

export type IncomingTransactionInjectInput = {
  /** The hash of the transaction */
  hash?: InputMaybe<Scalars['String']['input']>;
  incomingTransactionId?: InputMaybe<Scalars['String']['input']>;
  /** The network on which the transaction has been created */
  network?: InputMaybe<BlockchainNetwork>;
  /** Transaction score provided by external provider */
  score?: InputMaybe<Scalars['Int']['input']>;
  /** External provider that has provided transaction score */
  scoreProvider?: InputMaybe<IncomingTransactionScoreProvider>;
};

export enum IncomingTransactionProblem {
  ExternalWalletNotActive = 'ExternalWalletNotActive',
  ExternalWalletNotRegistered = 'ExternalWalletNotRegistered',
  ExternalWalletOfacAssociatedRisks = 'ExternalWalletOFACAssociatedRisks',
  ExternalWalletOwnerMissmatch = 'ExternalWalletOwnerMissmatch',
  FeatureDisabled = 'FeatureDisabled',
  RiskScoreOutOfBound = 'RiskScoreOutOfBound',
  SweepingWalletAssetMissmatch = 'SweepingWalletAssetMissmatch',
  UnsupportedAsset = 'UnsupportedAsset',
  UnsupportedStablecoin = 'UnsupportedStablecoin'
}

/** Enum, containing all incoming transaction score providers */
export enum IncomingTransactionScoreProvider {
  Coinfirm = 'Coinfirm',
  Scorechain = 'Scorechain'
}

export enum IncomingTransactionStatus {
  Approved = 'Approved',
  Collected = 'Collected',
  Confirmed = 'Confirmed',
  PendingApproval = 'PendingApproval',
  PendingCollection = 'PendingCollection',
  PendingValueVerification = 'PendingValueVerification',
  Received = 'Received',
  Refunded = 'Refunded'
}

export enum IncomingTransactionType {
  BackedToken = 'BackedToken',
  Stablecoin = 'Stablecoin',
  Unknown = 'Unknown'
}

export type IncomingTransactionWhereInput = {
  AND?: InputMaybe<Array<IncomingTransactionWhereInput>>;
  NOT?: InputMaybe<Array<IncomingTransactionWhereInput>>;
  OR?: InputMaybe<Array<IncomingTransactionWhereInput>>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<StringFilter>;
  clientType?: InputMaybe<ClientType>;
  hash?: InputMaybe<StringFilter>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<BlockchainNetwork>;
  status?: InputMaybe<IncomingTransactionStatus>;
  tokenSymbol?: InputMaybe<StringFilter>;
  type?: InputMaybe<IncomingTransactionType>;
};

export type InjectVerificationInput = {
  clientId: Scalars['String']['input'];
  vendorApplicantId: Scalars['String']['input'];
  vendorVerificationId: Scalars['String']['input'];
};

export type Injection = BaseEntity & {
  __typename?: 'Injection';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  interaction?: Maybe<Interaction>;
  interactionId?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  status: InjectionStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum InjectionStatus {
  Completed = 'Completed',
  Created = 'Created',
  CreatingIncomingTransactions = 'CreatingIncomingTransactions',
  CreatingInteraction = 'CreatingInteraction',
  CreatingOutgoingTransactions = 'CreatingOutgoingTransactions',
  ExecutingOutgoingTransactions = 'ExecutingOutgoingTransactions',
  Failed = 'Failed',
  RegisteringWallets = 'RegisteringWallets'
}

export type Interaction = BaseEntity & ClientOwned & {
  __typename?: 'Interaction';
  actualTotalValue: Scalars['Decimal']['output'];
  automatedChecks: Array<InteractionAutomatedCheck>;
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** Compliance comments linked to this interaction */
  comments: Array<Maybe<ComplianceComment>>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currency: FiatCurrency;
  events: Array<Event>;
  expectedTotalValue: Scalars['Decimal']['output'];
  failureReason?: Maybe<InteractionFailureReason>;
  fee?: Maybe<Fee>;
  flags: Array<InteractionFlag>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  incomingTransactions: Array<IncomingTransaction>;
  incomingTransfers: Array<BankTransfer>;
  internalTransactions: Array<InternalTransaction>;
  invoice?: Maybe<Invoice>;
  isInjected: Scalars['Boolean']['output'];
  orders: Array<Order>;
  outgoingTransactions: Array<OutgoingTransaction>;
  outgoingTransfers: Array<BankTransfer>;
  payout: PayoutMethod;
  reports: Array<InteractionReport>;
  status: InteractionStatus;
  tokenDeployments: Array<TokenDeployment>;
  type: InteractionType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  usage?: Maybe<Usage>;
};

export enum InteractionAutomatedCheck {
  AmlLimits = 'AMLLimits'
}

export type InteractionBaseStatisticsType = IInteractionStatistics & {
  __typename?: 'InteractionBaseStatisticsType';
  counter: Scalars['Int']['output'];
  fees: Scalars['Decimal']['output'];
  value: Scalars['Decimal']['output'];
};

export type InteractionConnection = {
  __typename?: 'InteractionConnection';
  nodes: Array<Interaction>;
  page: PageInfo;
};

export enum InteractionFailureReason {
  InteractionTypeNotEnabled = 'InteractionTypeNotEnabled',
  ManuallyBlocked = 'ManuallyBlocked',
  OverLimits = 'OverLimits',
  UnderLimits = 'UnderLimits',
  UnsupportedAsset = 'UnsupportedAsset'
}

export enum InteractionFlag {
  AmlLimitThresholdCrossed = 'AMLLimitThresholdCrossed',
  HighRisk = 'HighRisk'
}

export type InteractionPerClientStatistics = IInteractionStatistics & {
  __typename?: 'InteractionPerClientStatistics';
  clientId: Scalars['String']['output'];
  clientName: Scalars['String']['output'];
  counter: Scalars['Int']['output'];
  fees: Scalars['Decimal']['output'];
  value: Scalars['Decimal']['output'];
};

export type InteractionReport = BaseEntity & {
  __typename?: 'InteractionReport';
  comments: Array<ComplianceComment>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  type: InteractionReportType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum InteractionReportType {
  Internal = 'Internal',
  Mros = 'MROS'
}

export type InteractionStatistics = IInteractionStatistics & {
  __typename?: 'InteractionStatistics';
  counter: Scalars['Int']['output'];
  fees: Scalars['Decimal']['output'];
  perClient: Array<Maybe<InteractionPerClientStatistics>>;
  value: Scalars['Decimal']['output'];
};

export enum InteractionStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  ManualActionRequired = 'ManualActionRequired',
  PendingIncomingTransactionProcessing = 'PendingIncomingTransactionProcessing',
  PendingInternalFundsMovement = 'PendingInternalFundsMovement',
  PendingLimitsCheck = 'PendingLimitsCheck',
  PendingOrdersExecution = 'PendingOrdersExecution',
  PendingPayout = 'PendingPayout',
  PendingWalletRegistration = 'PendingWalletRegistration',
  RefundPending = 'RefundPending',
  Refunded = 'Refunded'
}

export type InteractionStatusFilter = {
  equals?: InputMaybe<InteractionStatus>;
  in?: InputMaybe<Array<InteractionStatus>>;
  not?: InputMaybe<InteractionStatus>;
  notIn?: InputMaybe<Array<InteractionStatus>>;
};

export type InteractionTransactionInjectDefinitionInput = {
  hash: Scalars['String']['input'];
  network: BlockchainNetwork;
};

export enum InteractionType {
  Custom = 'Custom',
  Issuance = 'Issuance',
  Redemption = 'Redemption',
  Unknown = 'Unknown'
}

export type InteractionWhereInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  failureReason?: InputMaybe<InteractionFailureReason>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InteractionStatusFilter>;
  /** Return all the interactions, for token, on all chains */
  tokenId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InteractionType>;
};

export type InteractionWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type InternalTransaction = BaseEntity & {
  __typename?: 'InternalTransaction';
  action: InternalTransactionAction;
  /** Naming mess-up, adding this to keep inline with the other types of transaction */
  amount: Scalars['String']['output'];
  benefactorAddress: Scalars['String']['output'];
  beneficiaryAddress?: Maybe<Scalars['String']['output']>;
  contractAddress: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  decimals: Scalars['Int']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** A URL that leads to explorer page about that transaction */
  explorerUrl?: Maybe<Scalars['String']['output']>;
  gaslessStrategy: GaslessStrategy;
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  network: BlockchainNetwork;
  status: InternalTransactionStatus;
  tokenAmount: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum InternalTransactionAction {
  IssuanceFeeCollection = 'IssuanceFeeCollection',
  RedemptionFeeCollection = 'RedemptionFeeCollection',
  RefundFeeCollection = 'RefundFeeCollection',
  Sweeping = 'Sweeping'
}

export type InternalTransactionConnection = {
  __typename?: 'InternalTransactionConnection';
  nodes: Array<InternalTransaction>;
  page: PageInfo;
};

export enum InternalTransactionStatus {
  Created = 'Created',
  Failed = 'Failed',
  Submitted = 'Submitted',
  Succeeded = 'Succeeded'
}

export type InternalTransactionsWhereInput = {
  AND?: InputMaybe<Array<InternalTransactionsWhereInput>>;
  NOT?: InputMaybe<Array<InternalTransactionsWhereInput>>;
  OR?: InputMaybe<Array<InternalTransactionsWhereInput>>;
  executorId?: InputMaybe<Scalars['String']['input']>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<BlockchainNetwork>;
};

export type Invitation = BaseEntity & {
  __typename?: 'Invitation';
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isClaimed: Scalars['Boolean']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationConnection = {
  __typename?: 'InvitationConnection';
  nodes: Array<Invitation>;
  page: PageInfo;
};

export type InvitationsFilter = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type Invoice = BaseEntity & {
  __typename?: 'Invoice';
  VATAmount: Scalars['Int']['output'];
  VATPercent: Scalars['Float']['output'];
  client: Client;
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The URL, from which the PDF version of the invoice can be downloaded */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  fee: Fee;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['Int']['output'];
  isPaid: Scalars['Boolean']['output'];
  path?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ManagementFee = BaseEntity & {
  __typename?: 'ManagementFee';
  amount: Scalars['Decimal']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  feeAccruals: Array<FeeAccrual>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  token: Token;
  tokenId: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type MarkSettlementAsExecutedInput = {
  custodyAccountEventId: Scalars['String']['input'];
  settlementId: Scalars['String']['input'];
};

export type Mint = BaseEntity & TokenDeploymentTransaction & {
  __typename?: 'Mint';
  amount: Scalars['Decimal']['output'];
  blockchainAddress: BlockchainAddress;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  explorerUrl?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  tokenDeployment: TokenDeployment;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  variant: MintVariant;
};

export enum MintVariant {
  Automated = 'Automated',
  Manual = 'Manual'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateExternalWalletBySigningMessage: ExternalWallet;
  activateExternalWalletManually: ExternalWallet;
  activateTOPTLayer: Scalars['Boolean']['output'];
  applyOracleAnswerUpdate: Scalars['Boolean']['output'];
  approveDocumentDeletion?: Maybe<Scalars['Boolean']['output']>;
  approveIncomingTransaction: Scalars['Boolean']['output'];
  approveOutgoingTransaction: Scalars['Boolean']['output'];
  /** Mutation, allowing the compliance admin to create archive of given verification. */
  archiveVerification: Scalars['Boolean']['output'];
  authenticateWithEmailChallenge: AuthenticationResult;
  authenticateWithPassword: AuthenticationResult;
  authenticateWithTransitionToken: AuthenticationResult;
  closeClient: ClientClosure;
  contact: Scalars['Boolean']['output'];
  createAdmin?: Maybe<Admin>;
  createBankAccount: BankAccount;
  createBlockchainAddress: BlockchainAddress;
  createClient: CreateClientResult;
  createCollateral: Collateral;
  createCompany?: Maybe<Company>;
  createComplianceComment: ComplianceComment;
  createComplianceReview: ComplianceReview;
  /** Command, used for creating a new facet */
  createCustodyAccountFacet: CustodyAccountFacet;
  createDeployment: Deployment;
  createDocument: Document;
  createDocumentFolder: DocumentFolder;
  /** Mutation, allowing us to create Incore EOD form. */
  createEODForm?: Maybe<EodData>;
  createExternalWalletBySigningMessage: ExternalWallet;
  createExternalWalletManually: ExternalWallet;
  createExternalWalletMicroTransaction: ExternalWalletMicroTransaction;
  createExternalWalletSigningMessage: ExternalWalletSigningMessage;
  /** Mutation, allowing us to create flow execution. */
  createFlowExecution: FlowExecution;
  createInteractionReport: InteractionReport;
  createInvitation?: Maybe<Invitation>;
  /** Command used to creating network simulator rules */
  createNetworkSimulatorRule: NetworkSimulatorRule;
  createOracle: Oracle;
  createRefund: Refund;
  createRiskAssessment: RiskAssessment;
  createRole: Role;
  createServiceAccount: ServiceAccountDetails;
  /** Mutation, used for creating new hot system wallet */
  createSystemWallet: SystemWallet;
  createTOPTLayer: Scalars['String']['output'];
  /** Creates new token deployment into the system. It will not be made public until fully configured and published */
  createToken?: Maybe<Deployment>;
  createTokenDeployment: TokenDeployment;
  createUser?: Maybe<User>;
  /** Mutation, used for initialising verification for the client */
  createVerification: Verification;
  createVerificationToken?: Maybe<VerificationToken>;
  deactivateExternalWallet: ExternalWallet;
  deleteBlockchainAddress: Scalars['Boolean']['output'];
  deleteDocumentFolder: Scalars['Boolean']['output'];
  deleteFeeConfiguration: Scalars['Boolean']['output'];
  /** Command used to deleting network simulator rules */
  deleteNetworkSimulatorRule: NetworkSimulatorRule;
  deleteRole: Scalars['Boolean']['output'];
  disableAdmin?: Maybe<Admin>;
  disableServiceAccount?: Maybe<Scalars['Boolean']['output']>;
  executeBankTransfer: BankTransfer;
  executeInjection?: Maybe<Injection>;
  /** Command, used for adding the execution data of a manually submitted order */
  executeOrder: Order;
  executeRefund: Scalars['Boolean']['output'];
  /** Command used for manually executing trade */
  executeTrade: Trade;
  fileRiskAssessment: RiskAssessment;
  finaliseClientQuestionnaire: ClientQuestionnaire;
  generateInvoicePDF: Invoice;
  importTokenDeployment: TokenDeployment;
  injectVerification: Verification;
  /** Command, used for manually marking settlement as executed */
  markSettlementAsExecuted: Settlement;
  /** Command for patching custody account facet */
  patchCustodyAccountFacet: CustodyAccountFacet;
  provideSupplyControlOperationSignature: Scalars['Boolean']['output'];
  publishToken: Token;
  refreshVerification: Scalars['Boolean']['output'];
  registerContractFactory?: Maybe<ContractFactory>;
  registerOracleForwarder: OracleForwarder;
  removeInteractionReport: Scalars['Boolean']['output'];
  removeUserPassword: Scalars['Boolean']['output'];
  requestDocumentDeletion: Scalars['Boolean']['output'];
  requestSupplyControlOperation: SupplyControlOperation;
  resetClientQuestionnaire: Scalars['Boolean']['output'];
  retriggerIncomingTransactionConfirmation: Scalars['Boolean']['output'];
  retryEvent: EventRetryAttempt;
  setPasswordPromptFeature: Scalars['Boolean']['output'];
  setTransferBankAccount: BankTransfer;
  setUserPassword: Scalars['Boolean']['output'];
  startEmailChallenge: StartEmailChallengeResult;
  submitComplianceReview: ComplianceReview;
  /** Command, used to marking an order as manually submitted */
  submitOrder: Order;
  toggleRole: Scalars['Boolean']['output'];
  unfreezeClient: Client;
  updateAdmin?: Maybe<Admin>;
  updateBlockchainAddress: Scalars['Boolean']['output'];
  updateClient: Client;
  updateClientFeatures: Client;
  updateClientPreferences?: Maybe<ClientPreferences>;
  updateClientQuestionnaire: ClientQuestionnaire;
  updateClientUsageLimits?: Maybe<UsageLimits>;
  updateCollateralCustodyAccount: Collateral;
  updateCompany?: Maybe<Company>;
  updateCompanyFeatures: Company;
  updateComplianceReview: ComplianceReview;
  updateDefaultBankAccount?: Maybe<Scalars['Boolean']['output']>;
  updateDocument: Document;
  updateDocumentFolder: DocumentFolder;
  updateInteraction: Interaction;
  updateOracleAnswer: Scalars['Boolean']['output'];
  updateRiskAssessment: RiskAssessment;
  updateRole: Scalars['Boolean']['output'];
  updateServiceAccount?: Maybe<ServiceAccount>;
  /** Updates token multiplier */
  updateTokenMultiplier: Scalars['Boolean']['output'];
  uploadLogo: Scalars['Boolean']['output'];
  upsertClearanceAccount: ClearanceAccount;
  upsertFeeConfiguration: FeesConfiguration;
  upsertInjection?: Maybe<Injection>;
  verifyIncomingTransactionValue: Scalars['Boolean']['output'];
  verifyOutgoingTransactionValue: Scalars['Boolean']['output'];
};


export type MutationActivateExternalWalletBySigningMessageArgs = {
  input: ActivateExternalWalletBySigningMessageInput;
};


export type MutationActivateExternalWalletManuallyArgs = {
  input: ActivateExternalWalletManuallyInput;
};


export type MutationActivateToptLayerArgs = {
  input: ActivateToptLayerInput;
};


export type MutationApplyOracleAnswerUpdateArgs = {
  input: ApplyOracleAnswerUpdateInput;
};


export type MutationApproveDocumentDeletionArgs = {
  input: ApproveDocumentDeletionRequestInput;
};


export type MutationApproveIncomingTransactionArgs = {
  input: ApproveIncomingTransactionInput;
};


export type MutationApproveOutgoingTransactionArgs = {
  input: ApproveOutgoingTransactionInput;
};


export type MutationArchiveVerificationArgs = {
  input: ArchiveVerificationInput;
};


export type MutationAuthenticateWithEmailChallengeArgs = {
  input: AuthenticateEmailChallengeInput;
};


export type MutationAuthenticateWithPasswordArgs = {
  input: AuthenticateWithPasswordInput;
};


export type MutationAuthenticateWithTransitionTokenArgs = {
  input: AuthenticateWithTransitionTokenInput;
};


export type MutationCloseClientArgs = {
  input: CloseClientInput;
};


export type MutationContactArgs = {
  input: ContactInput;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminUserInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationCreateBlockchainAddressArgs = {
  input: CreateBlockchainAddressInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateCollateralArgs = {
  input: CreateCollateralInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateComplianceCommentArgs = {
  input: CreateComplianceCommentInput;
};


export type MutationCreateComplianceReviewArgs = {
  input: CreateComplianceReviewInput;
};


export type MutationCreateCustodyAccountFacetArgs = {
  input: CreateCustodyAccountFacetInput;
};


export type MutationCreateDeploymentArgs = {
  input: CreateDeploymentInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentFolderArgs = {
  input: CreateDocumentFolderInput;
};


export type MutationCreateEodFormArgs = {
  input: CreateEodFormInput;
};


export type MutationCreateExternalWalletBySigningMessageArgs = {
  input: CreateExternalWalletInput;
};


export type MutationCreateExternalWalletManuallyArgs = {
  input: CreateExternalWalletManuallyInput;
};


export type MutationCreateExternalWalletMicroTransactionArgs = {
  input: CreateExternalWalletMicroTransactionInput;
};


export type MutationCreateExternalWalletSigningMessageArgs = {
  input: CreateExternalWalletSigningMessageInput;
};


export type MutationCreateFlowExecutionArgs = {
  input: CreateFlowExecutionInput;
};


export type MutationCreateInteractionReportArgs = {
  input: CreateInteractionReportInput;
};


export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};


export type MutationCreateNetworkSimulatorRuleArgs = {
  input: CreateNetworkSimulatorRuleInput;
};


export type MutationCreateOracleArgs = {
  input: CreateOracleInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateRiskAssessmentArgs = {
  input: CreateRiskAssessmentInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateServiceAccountArgs = {
  input: CreateServiceAccountInput;
};


export type MutationCreateSystemWalletArgs = {
  input?: InputMaybe<CreateSystemWalletInput>;
};


export type MutationCreateTokenArgs = {
  collateral: DefineTokenCollateralInput;
  deployment: DeploymentInput;
  token: CreateTokenInput;
};


export type MutationCreateTokenDeploymentArgs = {
  input: CreateTokenDeploymentInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVerificationTokenArgs = {
  input: CreateVerificationTokenInput;
};


export type MutationDeactivateExternalWalletArgs = {
  input: DeactivateExternalWalletInput;
};


export type MutationDeleteBlockchainAddressArgs = {
  input: DeleteBlockchainAddressInput;
};


export type MutationDeleteDocumentFolderArgs = {
  input: DeleteDocumentFolderInput;
};


export type MutationDeleteFeeConfigurationArgs = {
  input: DeleteFeeConfigurationInput;
};


export type MutationDeleteNetworkSimulatorRuleArgs = {
  input: DeleteNetworkSimulatorRuleInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDisableAdminArgs = {
  input: DisableAdminInput;
};


export type MutationDisableServiceAccountArgs = {
  input: DisableServiceAccountMutationInput;
};


export type MutationExecuteBankTransferArgs = {
  input: ExecuteBankTransferInput;
};


export type MutationExecuteInjectionArgs = {
  input: ExecuteInjectionInput;
};


export type MutationExecuteOrderArgs = {
  input: ExecuteOrderInput;
};


export type MutationExecuteRefundArgs = {
  input: ExecuteRefundInput;
};


export type MutationExecuteTradeArgs = {
  input: ExecuteTradeInput;
};


export type MutationFileRiskAssessmentArgs = {
  concludedRiskLevel: RiskLevel;
  id: Scalars['ID']['input'];
};


export type MutationFinaliseClientQuestionnaireArgs = {
  input: FinaliseClientQuestionnaireInput;
};


export type MutationGenerateInvoicePdfArgs = {
  input: GenerateInvoicePdfInput;
};


export type MutationImportTokenDeploymentArgs = {
  input: ImportTokenDeploymentInput;
};


export type MutationInjectVerificationArgs = {
  input: InjectVerificationInput;
};


export type MutationMarkSettlementAsExecutedArgs = {
  input: MarkSettlementAsExecutedInput;
};


export type MutationPatchCustodyAccountFacetArgs = {
  input: PatchCustodyAccountFacetMutationInput;
};


export type MutationProvideSupplyControlOperationSignatureArgs = {
  input: ProvideSupplyControlOperationSignatureInput;
};


export type MutationPublishTokenArgs = {
  tokenId: Scalars['ID']['input'];
};


export type MutationRefreshVerificationArgs = {
  verificationId: Scalars['ID']['input'];
};


export type MutationRegisterContractFactoryArgs = {
  input: RegisterContractFactoryInput;
};


export type MutationRegisterOracleForwarderArgs = {
  input: RegisterOracleForwarderInput;
};


export type MutationRemoveInteractionReportArgs = {
  input: RemoveInteractionReportInput;
};


export type MutationRemoveUserPasswordArgs = {
  input: RemoveUserPasswordInput;
};


export type MutationRequestDocumentDeletionArgs = {
  input: RequestDocumentDeletionInput;
};


export type MutationRequestSupplyControlOperationArgs = {
  input: RequestSupplyControlOperationInput;
};


export type MutationResetClientQuestionnaireArgs = {
  input: ResetClientQuestionnaireInput;
};


export type MutationRetriggerIncomingTransactionConfirmationArgs = {
  input: RetriggerIncomingTransactionConfirmationInput;
};


export type MutationRetryEventArgs = {
  input: RetryEventMutationInput;
};


export type MutationSetTransferBankAccountArgs = {
  input: SetTransferBankAccountInput;
};


export type MutationSetUserPasswordArgs = {
  input: SetUserPasswordInput;
};


export type MutationStartEmailChallengeArgs = {
  input: StartEmailChallengeInput;
};


export type MutationSubmitComplianceReviewArgs = {
  input: SubmitComplianceReviewInput;
};


export type MutationSubmitOrderArgs = {
  input: SubmitOrderInput;
};


export type MutationToggleRoleArgs = {
  input: ToggleRoleInput;
};


export type MutationUnfreezeClientArgs = {
  input: UnfreezeClientInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateBlockchainAddressArgs = {
  input: UpdateBlockchainAddressInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateClientFeaturesArgs = {
  input: UpdateClientFeaturesInput;
};


export type MutationUpdateClientPreferencesArgs = {
  input: UpdateClientPreferencesInput;
};


export type MutationUpdateClientQuestionnaireArgs = {
  input: UpdateClientQuestionnaireInput;
};


export type MutationUpdateClientUsageLimitsArgs = {
  input: UpdateClientUsageLimitsInput;
};


export type MutationUpdateCollateralCustodyAccountArgs = {
  input: UpdateCollateralCustodyAccountInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyFeaturesArgs = {
  input: UpdateCompanyFeaturesInput;
};


export type MutationUpdateComplianceReviewArgs = {
  input: UpdateComplianceReviewInput;
};


export type MutationUpdateDefaultBankAccountArgs = {
  input: UpdateDefaultBankAccountInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: UpdateDocumentFolderInput;
};


export type MutationUpdateInteractionArgs = {
  data: UpdateInteractionDataInput;
  where: InteractionWhereUniqueInput;
};


export type MutationUpdateOracleAnswerArgs = {
  input: UpdateOracleAnswerInput;
};


export type MutationUpdateRiskAssessmentArgs = {
  input: UpdateRiskAssessmentInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateServiceAccountArgs = {
  input: UpdateServiceAccountInput;
};


export type MutationUpdateTokenMultiplierArgs = {
  input: UpdateTokenMultiplierInput;
};


export type MutationUploadLogoArgs = {
  input: UploadLogoInput;
};


export type MutationUpsertClearanceAccountArgs = {
  input: UpsertClearanceAccountInput;
};


export type MutationUpsertFeeConfigurationArgs = {
  input: UpsertFeeConfigurationInput;
};


export type MutationUpsertInjectionArgs = {
  input: UpsertInjectionInput;
};


export type MutationVerifyIncomingTransactionValueArgs = {
  input: VerifyIncomingTransactionValueInput;
};


export type MutationVerifyOutgoingTransactionValueArgs = {
  input: VerifyOutgoingTransactionValueInput;
};

/** Enum, containing all blockchain networks native currencies */
export enum NativeCurrency {
  Avax = 'AVAX',
  Bnb = 'BNB',
  Eth = 'ETH',
  Ftm = 'FTM',
  Matic = 'MATIC',
  Xdai = 'XDAI'
}

export type NetworkSimulatorRule = BaseEntity & {
  __typename?: 'NetworkSimulatorRule';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  pathRegex?: Maybe<Scalars['String']['output']>;
  payloadRegex?: Maybe<Scalars['String']['output']>;
  transformScript?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkSimulatorRuleConnection = {
  __typename?: 'NetworkSimulatorRuleConnection';
  nodes: Array<NetworkSimulatorRule>;
  page: PageInfo;
};

export type Notification = BaseEntity & {
  __typename?: 'Notification';
  blocks: Scalars['JSON']['output'];
  content?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  domain: NotificationDomain;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isAcknowledged: Scalars['Boolean']['output'];
  severity: NotificationSeverity;
  title: Scalars['String']['output'];
  type: NotificationType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum NotificationDomain {
  Client = 'Client',
  System = 'System'
}

export enum NotificationSeverity {
  Critical = 'Critical',
  Default = 'Default',
  High = 'High'
}

export enum NotificationType {
  AccountApproved = 'AccountApproved',
  AccountCreated = 'AccountCreated',
  BankAccountCreated = 'BankAccountCreated',
  BankTransferSent = 'BankTransferSent',
  CollateralisationReport = 'CollateralisationReport',
  Compliance = 'Compliance',
  ComplianceAml = 'ComplianceAML',
  Critical = 'Critical',
  CustodyAccountEventCreated = 'CustodyAccountEventCreated',
  CustodyAccountFacetBalanceChanged = 'CustodyAccountFacetBalanceChanged',
  CustomerOnboarding = 'CustomerOnboarding',
  CustomerOnboardingRejected = 'CustomerOnboardingRejected',
  DailyInteractions = 'DailyInteractions',
  DailyPendingSettlementsSummary = 'DailyPendingSettlementsSummary',
  DepeggedIncomingTransactionDetected = 'DepeggedIncomingTransactionDetected',
  DepeggedIssuanceDetected = 'DepeggedIssuanceDetected',
  DepeggedRedemptionDetected = 'DepeggedRedemptionDetected',
  EmailCodeRequested = 'EmailCodeRequested',
  EndOfDaySettlementForm = 'EndOfDaySettlementForm',
  Finance = 'Finance',
  General = 'General',
  HighMediumRiskTransaction = 'HighMediumRiskTransaction',
  InfrastructureLevel = 'InfrastructureLevel',
  LimitsReached = 'LimitsReached',
  MfaEnabled = 'MFAEnabled',
  NewOffSystemOrder = 'NewOffSystemOrder',
  NewOrderForExecution = 'NewOrderForExecution',
  OracleUpdateFailed = 'OracleUpdateFailed',
  OrderAutomaticalyExecuted = 'OrderAutomaticalyExecuted',
  OrderScheduledForManualExecution = 'OrderScheduledForManualExecution',
  RefundIssued = 'RefundIssued',
  SettlementCustodyAccountEventNotMatched = 'SettlementCustodyAccountEventNotMatched',
  StaleSettlementsDetected = 'StaleSettlementsDetected',
  TransactionBelowLowerLimit = 'TransactionBelowLowerLimit',
  TransactionCompleted = 'TransactionCompleted',
  TransactionFailed = 'TransactionFailed',
  TransactionFromInactiveWallet = 'TransactionFromInactiveWallet',
  TransactionFromUnregisteredWallet = 'TransactionFromUnregisteredWallet',
  TransactionReceived = 'TransactionReceived',
  TransactionUnsupportedAsset = 'TransactionUnsupportedAsset',
  WalletActivated = 'WalletActivated',
  WalletObsolete = 'WalletObsolete',
  WalletRegistered = 'WalletRegistered'
}

export type NotificationWhereInput = {
  domain?: InputMaybe<NotificationDomain>;
  isAcknowledged?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Oracle = BaseEntity & {
  __typename?: 'Oracle';
  address: Scalars['String']['output'];
  answerUpdates: Array<OracleAnswerUpdate>;
  collateral: Collateral;
  collateralId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  forwarder?: Maybe<OracleForwarder>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  latestAnswer: Scalars['String']['output'];
  latestTimestamp: Scalars['Int']['output'];
  managedBy: OracleManagedBy;
  name: Scalars['String']['output'];
  network: BlockchainNetwork;
  symbol: Scalars['String']['output'];
  /** List of all the tokens that this oracle tracks the price for */
  tokens: Array<Token>;
  type: OracleType;
  updateSchedule: OracleUpdateSchedule;
  updateScheduleOnWeekend: Scalars['Boolean']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  version: OracleVersion;
};


export type OracleAnswerUpdatesArgs = {
  onlyPending?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OracleAddressNetworkUniqueInput = {
  /** The address of the oracle contract */
  address: Scalars['String']['input'];
  /** The network, on which the oracle contract is deployed */
  network: BlockchainNetwork;
};

export type OracleAnswerUpdate = BaseEntity & {
  __typename?: 'OracleAnswerUpdate';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  explorerUrl?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  newAnswer: Scalars['String']['output'];
  oracleId: Scalars['String']['output'];
  previousAnswer: Scalars['String']['output'];
  status: OracleAnswerUpdateStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum OracleAnswerUpdateStatus {
  Applied = 'Applied',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type OracleConnection = {
  __typename?: 'OracleConnection';
  nodes: Array<Oracle>;
  page: PageInfo;
};

export type OracleContractDeploymentInput = {
  network: BlockchainNetwork;
  updateSchedule: OracleUpdateSchedule;
  updaterWallet: Scalars['String']['input'];
};

export type OracleForwarder = BaseEntity & {
  __typename?: 'OracleForwarder';
  address: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  network: BlockchainNetwork;
  oracle: Oracle;
  oracleId: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum OracleManagedBy {
  Chainlink = 'Chainlink',
  Internal = 'Internal'
}

export enum OracleType {
  Price = 'Price',
  Reserves = 'Reserves'
}

export enum OracleUpdateSchedule {
  Disabled = 'Disabled',
  Fast = 'Fast',
  Medium = 'Medium',
  Slow = 'Slow'
}

export enum OracleVersion {
  External = 'External',
  V1 = 'V1',
  V2 = 'V2'
}

export type OracleWhereInput = {
  /** Return oracles only for that collateral */
  collateralId?: InputMaybe<Scalars['String']['input']>;
  /** Return only oracles that are deployed on that network */
  network?: InputMaybe<BlockchainNetwork>;
};

export type OracleWhereUniqueInput = {
  address_network?: InputMaybe<OracleAddressNetworkUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Order = BaseEntity & {
  __typename?: 'Order';
  collateral: Collateral;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** Events, that are related to that order */
  events: Array<Event>;
  /** The date, at which order was executed */
  executedAt?: Maybe<Scalars['DateTime']['output']>;
  executionPath?: Maybe<OrderExecutionPath>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  interaction: Interaction;
  interactionId: Scalars['String']['output'];
  pricePerShare?: Maybe<Scalars['Decimal']['output']>;
  settlement?: Maybe<Settlement>;
  sharesToTrade?: Maybe<Scalars['Int']['output']>;
  side: OrderSide;
  status: OrderStatus;
  /** The amount of shares bought */
  totalShares: Scalars['Float']['output'];
  totalValue?: Maybe<Scalars['Decimal']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  nodes: Array<Order>;
  page: PageInfo;
};

export enum OrderExecutionPath {
  ExecutableImmediately = 'ExecutableImmediately',
  RequiresManualIntervention = 'RequiresManualIntervention',
  RequiresShortAutomatedSteps = 'RequiresShortAutomatedSteps'
}

export enum OrderSide {
  Buy = 'Buy',
  Sell = 'Sell'
}

export enum OrderStatus {
  Batched = 'Batched',
  Created = 'Created',
  Executed = 'Executed',
  Failed = 'Failed',
  MarkedForManualExecution = 'MarkedForManualExecution',
  ReadyForExecution = 'ReadyForExecution',
  RequirementsCheckedSuccesfully = 'RequirementsCheckedSuccesfully',
  SkippedExecution = 'SkippedExecution',
  Submitted = 'Submitted',
  SubmittedManually = 'SubmittedManually'
}

export type OrdersWhereInput = {
  AND?: InputMaybe<Array<OrdersWhereInput>>;
  NOT?: InputMaybe<Array<OrdersWhereInput>>;
  OR?: InputMaybe<Array<OrdersWhereInput>>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
};

export type OutgoingTransaction = BaseEntity & ClientOwned & {
  __typename?: 'OutgoingTransaction';
  amount: Scalars['String']['output'];
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  contractAddress: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  decimals: Scalars['Int']['output'];
  displayAmount: Scalars['String']['output'];
  /** A URL that leads to explorer page about that transaction */
  explorerUrl?: Maybe<Scalars['String']['output']>;
  gasUsed?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  interaction: Interaction;
  isDepegged: Scalars['Boolean']['output'];
  network: BlockchainNetwork;
  receiverAddress: Scalars['String']['output'];
  refundId?: Maybe<Scalars['String']['output']>;
  /** If the outgoing transaction carried stablecoins this is the address of the contract of the stablecoins that were transferred */
  stablecoinContractAddress?: Maybe<Scalars['String']['output']>;
  status: OutgoingTransactionStatus;
  token?: Maybe<TokenDeployment>;
  tokenId?: Maybe<Scalars['String']['output']>;
  tokenSymbol: Scalars['String']['output'];
  type: OutgoingTransactionType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Decimal']['output'];
  valueCurrency: FiatCurrency;
};

export type OutgoingTransactionConnection = {
  __typename?: 'OutgoingTransactionConnection';
  nodes: Array<OutgoingTransaction>;
  page: PageInfo;
};

export type OutgoingTransactionInjectInput = {
  /** The hash of the transaction */
  hash: Scalars['String']['input'];
  /** The network on which the transaction has been created */
  network: BlockchainNetwork;
};

export enum OutgoingTransactionStatus {
  Approved = 'Approved',
  Created = 'Created',
  Executed = 'Executed',
  Executing = 'Executing',
  Failed = 'Failed',
  PendingApproval = 'PendingApproval',
  PendingValueVerification = 'PendingValueVerification',
  Rejected = 'Rejected'
}

export enum OutgoingTransactionType {
  BackedToken = 'BackedToken',
  Delegated = 'Delegated',
  Injected = 'Injected',
  Stablecoin = 'Stablecoin'
}

export type OutgoingTransactionsWhereInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<StringFilter>;
  clientType?: InputMaybe<ClientType>;
  interactionId?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<BlockchainNetwork>;
  status?: InputMaybe<OutgoingTransactionStatus>;
  tokenSymbol?: InputMaybe<StringFilter>;
  type?: InputMaybe<OutgoingTransactionType>;
};

export type OverrideWalletCheckInput = {
  /** Override benefitiary sweeping wallet check */
  sweepingWallet: Scalars['Boolean']['input'];
  /** Override system wallet check */
  systemWallet: Scalars['Boolean']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  pageSize: Scalars['Int']['output'];
  totalNodes: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginationInput = {
  /** The page that you want to get */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** The amount of items, that you want to get */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PatchCustodyAccountFacetMutationInput = {
  accountFacetId: Scalars['String']['input'];
  isWorkingCapital?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export enum PayoutMethod {
  BankPayout = 'BankPayout',
  BlockchainPayout = 'BlockchainPayout'
}

export type PhysicalAddress = BaseEntity & {
  __typename?: 'PhysicalAddress';
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  firstLine: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  secondLine?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type PhysicalAddressPayload = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  firstLine: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
};

export type ProvideSupplyControlOperationSignatureInput = {
  address: Scalars['String']['input'];
  operationId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  admins?: Maybe<AdminConnection>;
  assetReserves: Array<AssetReserves>;
  /** Get a bank account by ID */
  bankAccount?: Maybe<BankAccount>;
  bankAccounts: Array<BankAccount>;
  bankTransfer: BankTransfer;
  bankTransfers: BankTransferConnection;
  blockchainAddress: BlockchainAddress;
  blockchainAddresses: Array<BlockchainAddress>;
  blockchainNetworkConfiguration: BlockchainNetworkConfiguration;
  brokers: BrokerConnection;
  canSystemWalletSatisfyOrderExecution?: Maybe<CanSystemWalletSatisfyOrderExecution>;
  canSystemWalletSatisfyOutgoingTransactionExecution?: Maybe<CanSystemWalletSatisfyOutgoingTransactionExecution>;
  clearanceAccount?: Maybe<ClearanceAccount>;
  clearanceAccounts: ClearanceAccountConnection;
  client: Client;
  /** Returns the client preferences for the currently authenticated and selected client */
  clientPreferences: ClientPreferences;
  /** Get a list of all the clients */
  clients: ClientConnection;
  collateral: Collateral;
  collaterals: Array<Collateral>;
  companies?: Maybe<CompanyConnection>;
  company: Company;
  complianceComments: Array<ComplianceComment>;
  complianceReview: ComplianceReview;
  custodyAccount: CustodyAccount;
  custodyAccounts: Array<CustodyAccount>;
  custodyAccountsTransfers: Array<CustodyAccountTransfer>;
  deployment: Deployment;
  deployments?: Maybe<DeploymentConnection>;
  document: Document;
  documentDeletionRequest: DocumentDeletionRequest;
  documents: Array<Document>;
  event?: Maybe<Event>;
  /** Get a list of all the events, paginated */
  events: EventConnection;
  externalWallet?: Maybe<ExternalWallet>;
  externalWallets: Array<ExternalWallet>;
  factories: Array<ContractFactory>;
  feeConfiguration?: Maybe<FeesConfiguration>;
  feeConfigurations: FeesConfigurationConnection;
  file?: Maybe<File>;
  flowExecution?: Maybe<FlowExecution>;
  /** Get a list of all the flow executions, paginated */
  flowExecutions: FlowExecutionConnection;
  folder: DocumentFolder;
  incomingTransaction: IncomingTransaction;
  incomingTransactions: IncomingTransactionConnection;
  injection: Injection;
  injectionTransactionDefinitionQuery: Array<TransactionInjectionDefinition>;
  interaction?: Maybe<Interaction>;
  interactionExpectedPayout: ExpectedPayout;
  /** Get a list of all the interactions, paginated */
  interactions: InteractionConnection;
  internalTransactions: InternalTransactionConnection;
  invitations: InvitationConnection;
  networkSimulatorRules: NetworkSimulatorRuleConnection;
  notifications: Array<Notification>;
  oracle: Oracle;
  oracleAnswerUpdate: OracleAnswerUpdate;
  oracles: OracleConnection;
  order?: Maybe<Order>;
  orders: OrderConnection;
  outgoingTransaction: OutgoingTransaction;
  outgoingTransactions: OutgoingTransactionConnection;
  refund?: Maybe<Refund>;
  refunds: RefundConnection;
  riskAssessment: RiskAssessment;
  riskAssessments: Array<RiskAssessment>;
  role: Role;
  /** Get a list of all the roles */
  roles: RoleConnection;
  securityLogs: Array<SecurityLog>;
  serviceAccounts?: Maybe<ServiceAccountConnection>;
  settlements: SettlementConnection;
  stablecoins: Array<Stablecoin>;
  supplyControlOperation: SupplyControlOperation;
  supplyControlOperations: SupplyControlOperationConnection;
  sweepingWallet: SweepingWallet;
  sweepingWallets?: Maybe<SweepingWalletConnection>;
  systemConfiguration: SystemConfiguration;
  systemInfo?: Maybe<SystemInfo>;
  systemWallet: SystemWallet;
  systemWallets: Array<SystemWallet>;
  token: Token;
  tokenDeployment?: Maybe<TokenDeployment>;
  tokenDeployments: Array<TokenDeployment>;
  tokenStatistics: Array<TokenStatistics>;
  tokens: Array<Token>;
  /** Get all the trades */
  trades: Array<Trade>;
  /** Get all the trading assets */
  tradingAssets: Array<TradingAsset>;
  /** Query, used for fetching one user by ID, if provided, or the currently signed user if not */
  user?: Maybe<User>;
  users: UserConnection;
  verificationProfile: VerificationProfile;
  verifications: Array<Verification>;
};


export type QueryAdminArgs = {
  adminId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminsWhereInput>;
};


export type QueryBankAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBankAccountsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankAccountsWhereInput>;
};


export type QueryBankTransferArgs = {
  bankTransferId: Scalars['String']['input'];
};


export type QueryBankTransfersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankTransfersWhereInput>;
};


export type QueryBlockchainAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlockchainAddressesArgs = {
  where?: InputMaybe<BlockchainAddressWhereInput>;
};


export type QueryBlockchainNetworkConfigurationArgs = {
  network: BlockchainNetwork;
};


export type QueryBrokersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCanSystemWalletSatisfyOrderExecutionArgs = {
  input: CanSystemWalletSatisfyOrderExecutionInput;
};


export type QueryCanSystemWalletSatisfyOutgoingTransactionExecutionArgs = {
  input: CanSystemWalletSatisfyOutgoingTransactionExecutionInput;
};


export type QueryClearanceAccountArgs = {
  where: ClearanceAccountWhereUniqueInput;
};


export type QueryClearanceAccountsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryClientsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientsFilter>;
};


export type QueryCollateralArgs = {
  collateralId?: InputMaybe<Scalars['String']['input']>;
  collateralSymbol?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompaniesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanysWhereInput>;
};


export type QueryCompanyArgs = {
  where?: InputMaybe<CompanyWhereUniqueInput>;
};


export type QueryComplianceCommentsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComplianceCommentsWhereInput>;
};


export type QueryComplianceReviewArgs = {
  complianceReviewId: Scalars['ID']['input'];
};


export type QueryCustodyAccountArgs = {
  where: CustodyAccountWhereUniqueInput;
};


export type QueryDeploymentArgs = {
  id: Scalars['String']['input'];
};


export type QueryDeploymentsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeploymentsWhereInput>;
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentDeletionRequestArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryDocumentsArgs = {
  where?: InputMaybe<DocumentsWhereInput>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryExternalWalletArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryExternalWalletsArgs = {
  page?: InputMaybe<PaginationInput>;
  where?: InputMaybe<GetExternalWalletsWhere>;
};


export type QueryFeeConfigurationArgs = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<FeeTier>;
  tokenDeploymentId: Scalars['String']['input'];
};


export type QueryFeeConfigurationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeeConfigurationsWhereInput>;
};


export type QueryFileArgs = {
  clientId: Scalars['String']['input'];
  path: Scalars['String']['input'];
};


export type QueryFlowExecutionArgs = {
  where: FlowExecutionWhereUniqueInput;
};


export type QueryFlowExecutionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FlowExecutionWhereInput>;
};


export type QueryFolderArgs = {
  id: Scalars['String']['input'];
};


export type QueryIncomingTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type QueryIncomingTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomingTransactionWhereInput>;
};


export type QueryInjectionArgs = {
  id: Scalars['String']['input'];
};


export type QueryInjectionTransactionDefinitionQueryArgs = {
  input: InteractionTransactionInjectDefinitionInput;
};


export type QueryInteractionArgs = {
  where: InteractionWhereUniqueInput;
};


export type QueryInteractionExpectedPayoutArgs = {
  collateralId: Scalars['String']['input'];
  interactionId: Scalars['String']['input'];
  pricePerShare: Scalars['String']['input'];
  tradedShares: Scalars['Int']['input'];
};


export type QueryInteractionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InteractionWhereInput>;
};


export type QueryInternalTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InternalTransactionsWhereInput>;
};


export type QueryInvitationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvitationsFilter>;
};


export type QueryNetworkSimulatorRulesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryOracleArgs = {
  where: OracleWhereUniqueInput;
};


export type QueryOracleAnswerUpdateArgs = {
  id: Scalars['String']['input'];
};


export type QueryOraclesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OracleWhereInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrdersWhereInput>;
};


export type QueryOutgoingTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type QueryOutgoingTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutgoingTransactionsWhereInput>;
};


export type QueryRefundArgs = {
  where: RefundWhereUniqueInput;
};


export type QueryRefundsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRiskAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRiskAssessmentsArgs = {
  where?: InputMaybe<RiskAssessmentsWhereInput>;
};


export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type QueryRolesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySecurityLogsArgs = {
  where?: InputMaybe<SecurityLogsWhereInput>;
};


export type QueryServiceAccountsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySettlementsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SettlementWhereInput>;
};


export type QuerySupplyControlOperationArgs = {
  where: SupplyControlOperationWhereUnique;
};


export type QuerySupplyControlOperationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySweepingWalletArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySweepingWalletsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GetSweepingWalletsWhere>;
};


export type QuerySystemConfigurationArgs = {
  key: SystemConfigurationKey;
};


export type QuerySystemWalletArgs = {
  walletId: Scalars['String']['input'];
};


export type QuerySystemWalletsArgs = {
  where?: InputMaybe<GetSystemWalletsWhereInput>;
};


export type QueryTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTokenDeploymentArgs = {
  tokenDeploymentId: Scalars['String']['input'];
};


export type QueryTokenDeploymentsArgs = {
  where?: InputMaybe<TokenDeploymentWhereInput>;
};


export type QueryTokenStatisticsArgs = {
  isHistory?: InputMaybe<Scalars['Boolean']['input']>;
  isLatest?: InputMaybe<Scalars['Boolean']['input']>;
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTokensArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  includeUnpublished?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersFilter>;
};


export type QueryVerificationProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Refund = BaseEntity & {
  __typename?: 'Refund';
  client: Client;
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The reason why this refund was created */
  creationReason: RefundCreationReason;
  /** Optional value, holding the reason why this refund has failed */
  failureReason?: Maybe<RefundFailureReason>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  incomingTransactions: Array<IncomingTransaction>;
  outgoingTransactions: Array<OutgoingTransaction>;
  /** The reason why this refund was created */
  reason: Scalars['String']['output'];
  refundDefinition: Scalars['JSON']['output'];
  /** The current processing status of the refund */
  status: RefundStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type RefundConnection = {
  __typename?: 'RefundConnection';
  nodes: Array<Refund>;
  page: PageInfo;
};

export enum RefundCreationReason {
  IncomingTransactionWithProblems = 'IncomingTransactionWithProblems',
  IssuanceFailed = 'IssuanceFailed',
  Manual = 'Manual',
  RedemptionFailed = 'RedemptionFailed',
  Unknown = 'Unknown'
}

export enum RefundFailureReason {
  RefundTransactionFailed = 'RefundTransactionFailed',
  RefundValueBiggerThanTransactionValue = 'RefundValueBiggerThanTransactionValue',
  RefundValueNegative = 'RefundValueNegative'
}

export enum RefundStatus {
  Available = 'Available',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Skipped = 'Skipped',
  Succeeded = 'Succeeded'
}

export type RefundWhereUniqueInput = {
  interactionId?: InputMaybe<Scalars['String']['input']>;
  refundId?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterContractFactoryInput = {
  address: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type RegisterOracleForwarderInput = {
  address: Scalars['String']['input'];
  oracleId: Scalars['String']['input'];
};

export type RemoveInteractionReportInput = {
  interactionReportId: Scalars['String']['input'];
};

export type RemoveUserPasswordInput = {
  userId: Scalars['String']['input'];
};

export type RequestDocumentDeletionInput = {
  documentId: Scalars['String']['input'];
};

export type RequestSupplyControlOperationInput = {
  amount: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  executionStrategy: SupplyControlOperationExecutionStrategy;
  recipientWalletId?: InputMaybe<Scalars['String']['input']>;
  type: SupplyControlOperationType;
};

export type ResetClientQuestionnaireInput = {
  questionnaireId: Scalars['String']['input'];
};

export type RetriggerIncomingTransactionConfirmationInput = {
  transactionId: Scalars['String']['input'];
};

export type RetryEventMutationInput = {
  comment: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
};

export type RiskAssessment = BaseEntity & {
  __typename?: 'RiskAssessment';
  /** List of checks, available for the type of principal of this assessment */
  availableChecks: Array<RiskAssessmentCheck>;
  client: Client;
  clientType: ClientType;
  concludedRisk: RiskLevel;
  concludedRiskFormatted: Scalars['String']['output'];
  /** The risk points that the principal bears, as concluded by the check. If the check is still active they stay at 0 */
  concludedRiskPoints: Scalars['Int']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  filedBy?: Maybe<Admin>;
  filedById?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isBusinessActivityInHighRiskCountry?: Maybe<Scalars['Boolean']['output']>;
  isBusinessActivityInHighRiskCountryNotes?: Maybe<Scalars['String']['output']>;
  isBusinessActivityInHighRiskSector?: Maybe<Scalars['Boolean']['output']>;
  isBusinessActivityInHighRiskSectorNotes?: Maybe<Scalars['String']['output']>;
  isBusinessActivityInNonServicedCountry?: Maybe<Scalars['Boolean']['output']>;
  isBusinessActivityInNonServicedCountryNotes?: Maybe<Scalars['String']['output']>;
  isBusinessActivityStructureComplex?: Maybe<Scalars['Boolean']['output']>;
  isBusinessActivityStructureComplexNotes?: Maybe<Scalars['String']['output']>;
  isDomicileNonServicedCountryBased?: Maybe<Scalars['Boolean']['output']>;
  isDomicileNonServicedCountryBasedNotes?: Maybe<Scalars['String']['output']>;
  isDomiciledInHighRiskCountry?: Maybe<Scalars['Boolean']['output']>;
  isDomiciledInHighRiskCountryNotes?: Maybe<Scalars['String']['output']>;
  isDomiciledInMonitoredTerritory?: Maybe<Scalars['Boolean']['output']>;
  isDomiciledInMonitoredTerritoryNotes?: Maybe<Scalars['String']['output']>;
  isFrequentTransactor?: Maybe<Scalars['Boolean']['output']>;
  isFrequentTransactorNotes?: Maybe<Scalars['String']['output']>;
  isHighRiskForOtherReasons?: Maybe<Scalars['Boolean']['output']>;
  isHighRiskForOtherReasonsNotes?: Maybe<Scalars['String']['output']>;
  isHighWealth?: Maybe<Scalars['Boolean']['output']>;
  isHighWealthNotes?: Maybe<Scalars['String']['output']>;
  isPoliticallyExposed?: Maybe<Scalars['Boolean']['output']>;
  isPoliticallyExposedNotes?: Maybe<Scalars['String']['output']>;
  isUnderInvestigation?: Maybe<Scalars['Boolean']['output']>;
  isUnderInvestigationNotes?: Maybe<Scalars['String']['output']>;
  /** General notes that the AML officer may have had */
  notes?: Maybe<Scalars['String']['output']>;
  status: RiskAssessmentStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  verification: Verification;
  verificationProfile: VerificationProfile;
};

export type RiskAssessmentCheck = {
  __typename?: 'RiskAssessmentCheck';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reject: Scalars['Boolean']['output'];
  riskFactor: Scalars['Int']['output'];
};

export enum RiskAssessmentStatus {
  Active = 'Active',
  Completed = 'Completed',
  Processing = 'Processing'
}

export type RiskAssessmentsWhereInput = {
  status?: InputMaybe<RiskAssessmentStatus>;
};

export enum RiskLevel {
  CategoryOne = 'CategoryOne',
  CategoryThree = 'CategoryThree',
  CategoryTwo = 'CategoryTwo',
  HighRisk = 'HighRisk',
  Rejected = 'Rejected',
  StandardRisk = 'StandardRisk',
  Unknown = 'Unknown'
}

export type Role = BaseEntity & {
  __typename?: 'Role';
  admins: Array<Admin>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<AdminPermission>;
  type: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  nodes: Array<Role>;
  page: PageInfo;
};

export enum RoleType {
  CreatedByAdmin = 'CreatedByAdmin',
  PredefinedBySystem = 'PredefinedBySystem'
}

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SecurityLog = BaseEntity & {
  __typename?: 'SecurityLog';
  actor?: Maybe<Admin>;
  actorId?: Maybe<Scalars['String']['output']>;
  actorType: SecurityLogActor;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  type: SecurityLogType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum SecurityLogActor {
  Admin = 'Admin',
  System = 'System',
  Unknown = 'Unknown'
}

export enum SecurityLogType {
  DocumentCreated = 'DocumentCreated',
  DocumentFinalized = 'DocumentFinalized',
  FeatureToggled = 'FeatureToggled',
  OrderManuallyExecuted = 'OrderManuallyExecuted',
  OrderManuallySubmitted = 'OrderManuallySubmitted',
  RiskAssessmentCreated = 'RiskAssessmentCreated',
  RiskAssessmentFiled = 'RiskAssessmentFiled',
  RiskAssessmentUpdated = 'RiskAssessmentUpdated',
  RoleToggled = 'RoleToggled',
  TokenCreated = 'TokenCreated'
}

export type SecurityLogsWhereInput = {
  type?: InputMaybe<SecurityLogType>;
};

/** Object, representing service account */
export type ServiceAccount = BaseEntity & {
  __typename?: 'ServiceAccount';
  accessKey?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  permissions: Array<AdminPermission>;
  status: ServiceAccountStatus;
  title: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type ServiceAccountConnection = {
  __typename?: 'ServiceAccountConnection';
  nodes: Array<ServiceAccount>;
  page: PageInfo;
};

export type ServiceAccountDetails = {
  __typename?: 'ServiceAccountDetails';
  key: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export enum ServiceAccountStatus {
  Active = 'Active',
  DisabledByAdmin = 'DisabledByAdmin',
  DisabledByClient = 'DisabledByClient',
  Expired = 'Expired'
}

export type SetTransferBankAccountInput = {
  bankAccountId: Scalars['String']['input'];
  bankTransferId: Scalars['String']['input'];
};

export type SetUserPasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
};

export type Settlement = BaseEntity & {
  __typename?: 'Settlement';
  collateral: Collateral;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The URL, from which the PDF version of the EoD can be downloaded */
  endOfDayFormDownloadUrl?: Maybe<Scalars['String']['output']>;
  endOfDayFormPath?: Maybe<Scalars['String']['output']>;
  executedAt?: Maybe<Scalars['DateTime']['output']>;
  fiatCurrency: Scalars['String']['output'];
  finalFiatDiff?: Maybe<Scalars['Int']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** Orders, that are related to that settlement */
  orders: Array<Order>;
  startedAt: Scalars['DateTime']['output'];
  status: SettlementStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type SettlementConnection = {
  __typename?: 'SettlementConnection';
  nodes: Array<Settlement>;
  page: PageInfo;
};

export enum SettlementStatus {
  Created = 'Created',
  Executed = 'Executed',
  Submitted = 'Submitted'
}

export type SettlementStatusFilter = {
  in?: InputMaybe<Array<InputMaybe<SettlementStatus>>>;
};

export enum SettlementStrategy {
  AggregatedWithinDay = 'AggregatedWithinDay',
  Instantaneous = 'Instantaneous',
  PerOrder = 'PerOrder'
}

export type SettlementWhereInput = {
  status?: InputMaybe<SettlementStatusFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

/** The data representation of stablecoin */
export type Stablecoin = BaseEntity & {
  __typename?: 'Stablecoin';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

/** The data representation of stablecoin */
export type StablecoinConfig = {
  __typename?: 'StablecoinConfig';
  address: Scalars['String']['output'];
  chainId: Scalars['Int']['output'];
  decimals: Scalars['Int']['output'];
  minimumTransactionValue: Scalars['String']['output'];
  network: BlockchainNetwork;
  symbol: Scalars['String']['output'];
};

export type StartEmailChallengeInput = {
  browserId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  /** This option force send a magic email even if the user has password set */
  forceSend?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type StartEmailChallengeResult = {
  __typename?: 'StartEmailChallengeResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<FilteringMode>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitComplianceReviewInput = {
  complianceReviewId: Scalars['String']['input'];
  status: ComplianceReviewStatus;
};

export type SubmitOrderInput = {
  /** The ID of the order that we want to mark as submitted (manually) */
  orderId: Scalars['ID']['input'];
  priceAtSubmission: Scalars['String']['input'];
  sharesToTrade: Scalars['Int']['input'];
};

export type SupplyControlOperation = BaseEntity & {
  __typename?: 'SupplyControlOperation';
  amount: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  deployment: TokenDeployment;
  deploymentId: Scalars['String']['output'];
  eip712Domain: Scalars['JSON']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  possibleSigners: Array<Scalars['String']['output']>;
  recipientWallet?: Maybe<BlockchainAddress>;
  recipientWalletId?: Maybe<Scalars['String']['output']>;
  signatures: Array<SupplyControlOperationSignature>;
  status: SupplyControlOperationStatus;
  transactionPayload: Scalars['JSON']['output'];
  type: SupplyControlOperationType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplyControlOperationConnection = {
  __typename?: 'SupplyControlOperationConnection';
  nodes: Array<SupplyControlOperation>;
  page: PageInfo;
};

export enum SupplyControlOperationExecutionStrategy {
  Multisig = 'Multisig',
  Wallet = 'Wallet'
}

export type SupplyControlOperationSignature = BaseEntity & {
  __typename?: 'SupplyControlOperationSignature';
  /** The address that supplied the signature */
  address: Scalars['String']['output'];
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']['output']>;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum SupplyControlOperationStatus {
  AwaitingExecution = 'AwaitingExecution',
  AwaitingNonceTurn = 'AwaitingNonceTurn',
  AwaitingSignatures = 'AwaitingSignatures',
  Completed = 'Completed',
  Created = 'Created',
  Executing = 'Executing',
  Failed = 'Failed',
  Prepearing = 'Prepearing'
}

export enum SupplyControlOperationType {
  Burn = 'Burn',
  Mint = 'Mint'
}

export type SupplyControlOperationWhereUnique = {
  id: Scalars['String']['input'];
};

export type SweepingWallet = BaseEntity & {
  __typename?: 'SweepingWallet';
  /** The address of the sweeping wallet (on all ethereum-like networks) */
  address: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** External service providing sweeping wallet */
  externalService: Scalars['String']['output'];
  /** Sweeping wallet's id in the external service */
  externalServiceId: Scalars['String']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** List of all the networks on which the wallet is available */
  networks: Array<BlockchainNetwork>;
  token?: Maybe<Token>;
  /** The ID of the token, for which this sweeping wallet can be used to trigger interaction */
  tokenId?: Maybe<Scalars['String']['output']>;
  /** Sweeping wallet type i.e Issuance or Redemption */
  type: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type SweepingWalletConnection = {
  __typename?: 'SweepingWalletConnection';
  nodes: Array<SweepingWallet>;
  page: PageInfo;
};

export type SystemConfiguration = BaseEntity & {
  __typename?: 'SystemConfiguration';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isPublic: Scalars['Boolean']['output'];
  key: SystemConfigurationKey;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['JSON']['output'];
};

export enum SystemConfigurationKey {
  CollateralsAutomaticallyExecuted = 'CollateralsAutomaticallyExecuted',
  DatabaseSeeded = 'DatabaseSeeded',
  EmsAutomationEnabled = 'EmsAutomationEnabled',
  IncoreAutomationEnabled = 'IncoreAutomationEnabled',
  JurisdictionsAllowed = 'JurisdictionsAllowed',
  TermsAndConditionsIndividual = 'TermsAndConditionsIndividual',
  TermsAndConditionsInstitution = 'TermsAndConditionsInstitution'
}

export type SystemInfo = {
  __typename?: 'SystemInfo';
  supportedCountries: Array<Maybe<Country>>;
};

export type SystemWallet = BaseEntity & {
  __typename?: 'SystemWallet';
  /** The address of the wallet */
  address: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** Optional description, used for explanation of what that wallet is */
  description?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  nativeBalances: Array<SystemWalletNativeBalance>;
  /** Title, used to add identifying info about the wallet. May be generated by the system */
  title: Scalars['String']['output'];
  tokenBalances: Array<SystemWalletTokenBalance>;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type SystemWalletNativeBalance = {
  __typename?: 'SystemWalletNativeBalance';
  address: Scalars['String']['output'];
  balance: Scalars['String']['output'];
  network: BlockchainNetwork;
};

export type SystemWalletTokenBalance = {
  __typename?: 'SystemWalletTokenBalance';
  balance: Array<SystemWalletTokenNetworkBalance>;
  token: Token;
  tokenId: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type SystemWalletTokenNetworkBalance = {
  __typename?: 'SystemWalletTokenNetworkBalance';
  balance: Scalars['String']['output'];
  contractAddress: Scalars['String']['output'];
  network: BlockchainNetwork;
  walletAddress: Scalars['String']['output'];
};

export type ToggleRoleInput = {
  adminId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

/** The data representation of blockchain token contract */
export type Token = BaseEntity & CompanyRelation & {
  __typename?: 'Token';
  collateral: Collateral;
  /** The company, that owns this entity */
  company: Company;
  /** The ID of the company, that owns this entity */
  companyId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currentMultiplier?: Maybe<Scalars['Decimal']['output']>;
  /** List of all the available networks on one token */
  deployments: Array<TokenDeployment>;
  /** The description of the token */
  description?: Maybe<Scalars['String']['output']>;
  /** URL that leads to icon of the asset */
  icon?: Maybe<Scalars['String']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isPublic: Scalars['Boolean']['output'];
  isin?: Maybe<Scalars['String']['output']>;
  lastMultiplierUpdateTimestamp: Scalars['DateTime']['output'];
  managementFee?: Maybe<ManagementFee>;
  /** Minimum transaction value for token deployment */
  minimumTransactionValue: Scalars['String']['output'];
  /** The name of the token */
  name: Scalars['String']['output'];
  pendingBalance: Scalars['String']['output'];
  statistics: TokenInteractionStatistics;
  /** Access the already existing sweeping wallet for the currently authenticated client or creates new one for them for that token */
  sweepingWallet: SweepingWallet;
  symbol: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};


/** The data representation of blockchain token contract */
export type TokenStatisticsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};


/** The data representation of blockchain token contract */
export type TokenSweepingWalletArgs = {
  network: BlockchainNetwork;
};

export type TokenBalance = {
  __typename?: 'TokenBalance';
  /** The address of the balance holder */
  address: Scalars['String']['output'];
  /** The balance */
  balance: Scalars['String']['output'];
  /** The network on which the token balance is */
  network: BlockchainNetwork;
  token: TokenDeployment;
  /** The address of the contract */
  tokenAddress: Scalars['String']['output'];
};

export type TokenContractDeploymentInput = {
  burnerAddress: Scalars['String']['input'];
  minterAddress: Scalars['String']['input'];
  network: BlockchainNetwork;
  ownerAddress: Scalars['String']['input'];
  pauserAddress: Scalars['String']['input'];
  sanctionListAddress: Scalars['String']['input'];
};

/** Representation of token on individual blockchain network */
export type TokenDeployment = BaseEntity & {
  __typename?: 'TokenDeployment';
  /** The address of the deployed contract, if the deployment was performed successfully */
  address?: Maybe<Scalars['String']['output']>;
  burnAddress: Scalars['String']['output'];
  burnSupply: Scalars['String']['output'];
  burnerAddress: Scalars['String']['output'];
  chainId: Scalars['Int']['output'];
  circulatingSupply: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  deploymentStatus: TokenDeploymentStatus;
  /** Client side extension, showing the fee configuration that the client should expect */
  feeConfiguration: FeesConfiguration;
  /** Admin side extension, showing all the fee configuration for this deployment */
  feeConfigurations: Array<FeesConfiguration>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  minterAddress: Scalars['String']['output'];
  /** List of all past changes of multiplier */
  multiplierChanges: Array<TokenMultiplierChange>;
  network: BlockchainNetwork;
  ownerAddress: Scalars['String']['output'];
  pauserAddress: Scalars['String']['output'];
  stablecoinConfigs: Array<StablecoinConfig>;
  /** Sweeping wallet, that the user may use to issue the selected deployment */
  sweepingWallet: SweepingWallet;
  systemWallet: SystemWallet;
  systemWalletId: Scalars['String']['output'];
  token: Token;
  tokenId: Scalars['String']['output'];
  totalSupply: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum TokenDeploymentStatus {
  Created = 'Created',
  Deployed = 'Deployed',
  Deploying = 'Deploying',
  Successful = 'Successful',
  Unsuccessful = 'Unsuccessful',
  Validating = 'Validating'
}

export type TokenDeploymentTransaction = {
  explorerUrl?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  tokenDeployment: TokenDeployment;
};

export type TokenDeploymentWhereInput = {
  tokenId?: InputMaybe<Scalars['ID']['input']>;
};

export type TokenInteractionPerClientStatistics = ITokenInteractionStatistics & {
  __typename?: 'TokenInteractionPerClientStatistics';
  clientId: Scalars['String']['output'];
  clientName: Scalars['String']['output'];
  counter: Scalars['Int']['output'];
  currentValue: Scalars['Decimal']['output'];
  fees: Scalars['Decimal']['output'];
  lastOrder: Scalars['DateTime']['output'];
  maxValue: Scalars['Decimal']['output'];
};

export type TokenInteractionStatistics = ITokenInteractionStatistics & {
  __typename?: 'TokenInteractionStatistics';
  counter: Scalars['Int']['output'];
  currentValue: Scalars['Decimal']['output'];
  fees: Scalars['Decimal']['output'];
  issuances: InteractionStatistics;
  lastOrder: Scalars['DateTime']['output'];
  maxValue: Scalars['Decimal']['output'];
  perClient?: Maybe<Array<TokenInteractionPerClientStatistics>>;
  redemptions: InteractionStatistics;
};

/** The data representation of token multiplier change */
export type TokenMultiplierChange = BaseEntity & {
  __typename?: 'TokenMultiplierChange';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  executedAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  multiplier: Scalars['Decimal']['output'];
  previousMultiplier: Scalars['Decimal']['output'];
  reason: TokenMultiplierChangeReason;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum TokenMultiplierChangeReason {
  Dividend = 'Dividend',
  FeeAccrual = 'FeeAccrual',
  Split = 'Split'
}

export type TokenStatistics = {
  __typename?: 'TokenStatistics';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  statistics: Scalars['JSON']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type Trade = BaseEntity & {
  __typename?: 'Trade';
  amount: Scalars['Int']['output'];
  baseAsset?: Maybe<TradingAsset>;
  baseAssetId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  exchangeRate?: Maybe<Scalars['Int']['output']>;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  providerTradeId?: Maybe<Scalars['String']['output']>;
  quoteAsset?: Maybe<TradingAsset>;
  quoteAssetId: Scalars['String']['output'];
  side: TradeSide;
  status: TradeStatus;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum TradeSide {
  Buy = 'Buy',
  Sell = 'Sell'
}

export enum TradeStatus {
  Created = 'Created',
  Filled = 'Filled',
  Rejected = 'Rejected'
}

export type TradingAsset = BaseEntity & {
  __typename?: 'TradingAsset';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: TradingAssetType;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export enum TradingAssetType {
  Fiat = 'Fiat',
  Security = 'Security'
}

export type TransactionInjectionDefinition = {
  __typename?: 'TransactionInjectionDefinition';
  amount: Scalars['String']['output'];
  benefactorAddress: Scalars['String']['output'];
  beneficiaryAddress: Scalars['String']['output'];
  blockTimestamp: Scalars['Int']['output'];
  client?: Maybe<Client>;
  contractAddress: Scalars['String']['output'];
  decimals: Scalars['Int']['output'];
  hash: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  network: BlockchainNetwork;
  symbol: Scalars['String']['output'];
};

export type UnfreezeClientInput = {
  clientId: Scalars['String']['input'];
};

export type UpdateAdminInput = {
  adminId: Scalars['String']['input'];
  update: UpdateAdminPayloadInput;
};

export type UpdateAdminPayloadInput = {
  permissions: Array<AdminPermission>;
};

export type UpdateBlockchainAddressInput = {
  blockchainAddressId: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type UpdateClientFeaturesInput = {
  clientId: Scalars['String']['input'];
  featuresToAdd?: InputMaybe<Array<ClientFeature>>;
  featuresToRemove?: InputMaybe<Array<ClientFeature>>;
  message: Scalars['String']['input'];
};

export type UpdateClientInput = {
  clientId: Scalars['String']['input'];
  update: UpdateClientPayloadInput;
};

export type UpdateClientPayloadInput = {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientPreferencesInput = {
  bankAccountPayoutCurrency?: InputMaybe<FiatCurrency>;
  defaultStablecoinId?: InputMaybe<Scalars['String']['input']>;
  executionMode?: InputMaybe<ExecutionMode>;
  payoutMethod?: InputMaybe<PayoutMethod>;
};

export type UpdateClientQuestionnaireInput = {
  payload: Scalars['JSON']['input'];
  questionnaireId: Scalars['String']['input'];
};

export type UpdateClientUsageLimitsInput = {
  clientId: Scalars['String']['input'];
  /** The new usage limits tier */
  usageTier: UsageLimitTier;
};

export type UpdateCollateralCustodyAccountInput = {
  accounts: Array<Scalars['String']['input']>;
  collateralId: Scalars['String']['input'];
};

export type UpdateCompanyFeaturesInput = {
  companyId: Scalars['String']['input'];
  featuresToAdd?: InputMaybe<Array<CompanyFeature>>;
  featuresToRemove?: InputMaybe<Array<CompanyFeature>>;
};

export type UpdateCompanyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateComplianceReviewInput = {
  complianceReviewId: Scalars['String']['input'];
  featuresAllowed: Array<ClientFeature>;
  isProfessional: Scalars['Boolean']['input'];
  notes: Scalars['String']['input'];
};

export type UpdateDefaultBankAccountInput = {
  bankAccountId: Scalars['String']['input'];
};

export type UpdateDocumentFolderInput = {
  changeDescriptionTo?: InputMaybe<Scalars['String']['input']>;
  changeNameTo?: InputMaybe<Scalars['String']['input']>;
  changeParentTo?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['String']['input'];
};

export type UpdateDocumentInput = {
  changeFolderTo?: InputMaybe<Scalars['String']['input']>;
  changeNameTo?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['String']['input'];
};

export type UpdateInteractionDataInput = {
  flags?: InputMaybe<FlagsUpdatePayload>;
  updateProducts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOracleAnswerInput = {
  oracleId: Scalars['String']['input'];
};

export type UpdateRiskAssessmentInput = {
  id: Scalars['ID']['input'];
  isBusinessActivityInHighRiskCountry?: InputMaybe<Scalars['Boolean']['input']>;
  isBusinessActivityInHighRiskCountryNotes?: InputMaybe<Scalars['String']['input']>;
  isBusinessActivityInHighRiskSector?: InputMaybe<Scalars['Boolean']['input']>;
  isBusinessActivityInHighRiskSectorNotes?: InputMaybe<Scalars['String']['input']>;
  isBusinessActivityInNonServicedCountry?: InputMaybe<Scalars['Boolean']['input']>;
  isBusinessActivityInNonServicedCountryNotes?: InputMaybe<Scalars['String']['input']>;
  isBusinessActivityStructureComplex?: InputMaybe<Scalars['Boolean']['input']>;
  isBusinessActivityStructureComplexNotes?: InputMaybe<Scalars['String']['input']>;
  isDomicileNonServicedCountryBased?: InputMaybe<Scalars['Boolean']['input']>;
  isDomicileNonServicedCountryBasedNotes?: InputMaybe<Scalars['String']['input']>;
  isDomiciledInHighRiskCountry?: InputMaybe<Scalars['Boolean']['input']>;
  isDomiciledInHighRiskCountryNotes?: InputMaybe<Scalars['String']['input']>;
  isDomiciledInMonitoredTerritory?: InputMaybe<Scalars['Boolean']['input']>;
  isDomiciledInMonitoredTerritoryNotes?: InputMaybe<Scalars['String']['input']>;
  isFrequentTransactor?: InputMaybe<Scalars['Boolean']['input']>;
  isFrequentTransactorNotes?: InputMaybe<Scalars['String']['input']>;
  isHighRiskForOtherReasons?: InputMaybe<Scalars['Boolean']['input']>;
  isHighRiskForOtherReasonsNotes?: InputMaybe<Scalars['String']['input']>;
  isHighWealth?: InputMaybe<Scalars['Boolean']['input']>;
  isHighWealthNotes?: InputMaybe<Scalars['String']['input']>;
  isPoliticallyExposed?: InputMaybe<Scalars['Boolean']['input']>;
  isPoliticallyExposedNotes?: InputMaybe<Scalars['String']['input']>;
  isUnderInvestigation?: InputMaybe<Scalars['Boolean']['input']>;
  isUnderInvestigationNotes?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  roleId: Scalars['String']['input'];
  update: UpdateRolePayloadInput;
};

export type UpdateRolePayloadInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<AdminPermission>>;
};

export type UpdateServiceAccountInput = {
  serviceAccountId: Scalars['String']['input'];
  update: UpdateServiceAccountPayloadInput;
};

export type UpdateServiceAccountPayloadInput = {
  permissions: Array<AdminPermission>;
};

export type UpdateTokenMultiplierInput = {
  /** Change of multiplier in bips */
  changeBips: Scalars['Int']['input'];
  /** Reason for current change */
  reason: TokenMultiplierChangeReason;
  /** The ID of the token */
  tokenId: Scalars['String']['input'];
};

export type UploadLogoInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  logo: Scalars['Upload']['input'];
};

export type UpsertClearanceAccountInput = {
  brokerId?: InputMaybe<Scalars['String']['input']>;
  clearanceAccountId?: InputMaybe<Scalars['String']['input']>;
  collateralId: Scalars['String']['input'];
  depositoryCode?: InputMaybe<Scalars['String']['input']>;
  institutionBic?: InputMaybe<Scalars['String']['input']>;
  institutionName?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertFeeConfigurationInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  issuanceInitialFee: Scalars['Int']['input'];
  issuanceMinimumFee: Scalars['Int']['input'];
  issuancePercentageFee: Scalars['Float']['input'];
  redemptionInitialFee: Scalars['Int']['input'];
  redemptionMinimumFee: Scalars['Int']['input'];
  redemptionPercentageFee: Scalars['Float']['input'];
  tier?: InputMaybe<FeeTier>;
  tokenDeploymentId: Scalars['String']['input'];
};

export type UpsertInjectionInput = {
  payload: Scalars['JSON']['input'];
};

/** A single usage object for client */
export type Usage = BaseEntity & {
  __typename?: 'Usage';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  /** The type of action that was used */
  usageOf: InteractionType;
  value: Scalars['Decimal']['output'];
};

export enum UsageLimitTier {
  Institutions = 'Institutions',
  Professionals = 'Professionals',
  Retail = 'Retail',
  Trader = 'Trader',
  Unlimited = 'Unlimited',
  Whale = 'Whale'
}

export type UsageLimits = BaseEntity & {
  __typename?: 'UsageLimits';
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  dailyTransactionLimit: Scalars['BigInt']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  monthlyTransactionLimit: Scalars['BigInt']['output'];
  tier: UsageLimitTier;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

/** The base user object */
export type User = BaseEntity & {
  __typename?: 'User';
  bankAccounts: Array<BankAccount>;
  /** The personal client, for that user */
  client: Client;
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The current email of the user. Available only to themself */
  email: Scalars['String']['output'];
  features: Array<UserFeature>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasPasswordSet: Scalars['Boolean']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  passwordLastChange?: Maybe<Scalars['DateTime']['output']>;
  /** The date, on which the user last accepted the privacy policy */
  privacyPolicyAccepted: Scalars['DateTime']['output'];
  secondName?: Maybe<Scalars['String']['output']>;
  /** The date, on which the user last accepted the terms */
  termsAccepted: Scalars['DateTime']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  page: PageInfo;
};

export enum UserFeature {
  SetPasswordPromptDismissed = 'SetPasswordPromptDismissed'
}

export type UsersFilter = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type Verification = BaseEntity & {
  __typename?: 'Verification';
  client?: Maybe<Client>;
  clientType: ClientType;
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  status: VerificationStatus;
  tier: VerificationTier;
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  vendor: VerificationVendor;
  vendorApplicantId?: Maybe<Scalars['String']['output']>;
  /** The token, provided from the vendor in order for the user to complete the verification in their system */
  vendorToken?: Maybe<Scalars['String']['output']>;
  vendorVerificationId?: Maybe<Scalars['String']['output']>;
  verificationProfile: VerificationProfile;
  verificationProfileId: Scalars['String']['output'];
};

export type VerificationProfile = BaseEntity & ClientOwned & {
  __typename?: 'VerificationProfile';
  approved: Scalars['Boolean']['output'];
  assessments: Array<RiskAssessment>;
  /** The client, that owns this entity */
  client: Client;
  /** The ID of the client, that owns this entity */
  clientId: Scalars['String']['output'];
  /** The date, at which the item was created */
  createdAt: Scalars['DateTime']['output'];
  currentTier: VerificationTier;
  /** The main identifier of the item */
  id: Scalars['String']['output'];
  riskLevel: RiskLevel;
  riskLevelFormatted: Scalars['String']['output'];
  /** The date, at which the item was last modified */
  updatedAt: Scalars['DateTime']['output'];
  verifications: Array<Verification>;
};

export enum VerificationStatus {
  Pending = 'Pending',
  Successful = 'Successful',
  Unsuccessful = 'Unsuccessful'
}

export enum VerificationTier {
  BasicKnowYourBusinessDomiciliary = 'BasicKnowYourBusinessDomiciliary',
  BasicKnowYourBusinessOperating = 'BasicKnowYourBusinessOperating',
  Individual = 'Individual',
  Initial = 'Initial'
}

export type VerificationToken = {
  __typename?: 'VerificationToken';
  expirationDate: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export enum VerificationVendor {
  InHouse = 'InHouse',
  SumSub = 'SumSub'
}

export type VerifyIncomingTransactionValueInput = {
  exchangeRate: Scalars['Decimal']['input'];
  transactionId: Scalars['String']['input'];
};

export type VerifyOutgoingTransactionValueInput = {
  exchangeRate: Scalars['Decimal']['input'];
  transactionId: Scalars['String']['input'];
};

export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type CommandMenuQueryVariables = Exact<{
  filter?: InputMaybe<ClientsFilter>;
}>;


export type CommandMenuQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', nodes: Array<{ __typename?: 'Client', id: string, name: string, contactEmail: string }> } };

export type CommandMenuClientsSearchQueryVariables = Exact<{
  filter?: InputMaybe<ClientsFilter>;
}>;


export type CommandMenuClientsSearchQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', nodes: Array<{ __typename?: 'Client', id: string, humanId: string, name: string, type: ClientType, status: ClientStatus, isProfessional: boolean, contactEmail: string }> } };

export type CommandMenuTransactionsSearchQueryVariables = Exact<{
  filter?: InputMaybe<IncomingTransactionWhereInput>;
}>;


export type CommandMenuTransactionsSearchQuery = { __typename?: 'Query', incomingTransactions: { __typename?: 'IncomingTransactionConnection', nodes: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, network: BlockchainNetwork, interaction?: { __typename?: 'Interaction', id: string } | null, client: { __typename?: 'Client', id: string, name: string } }> } };

export type CommentsQueryVariables = Exact<{
  where?: InputMaybe<ComplianceCommentsWhereInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CommentsQuery = { __typename?: 'Query', complianceComments: Array<{ __typename?: 'ComplianceComment', id: string, createdAt: any, title?: string | null, comment: string, admin: { __typename?: 'Admin', id: string, firstName: string, lastName: string } }> };

export type CreateComplianceCommentMutationVariables = Exact<{
  input: CreateComplianceCommentInput;
}>;


export type CreateComplianceCommentMutation = { __typename?: 'Mutation', createComplianceComment: { __typename?: 'ComplianceComment', id: string } };

export type AccessDocumentQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type AccessDocumentQuery = { __typename?: 'Query', document: { __typename?: 'Document', id: string, name: string, createdAt: any, updatedAt: any, isPendingDeletion: boolean, url?: string | null, commentsCount: number, uploader?: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string } | null, client: { __typename?: 'Client', id: string, name: string, classification: ClientClassification } } };

export type FolderQueryVariables = Exact<{
  folderId: Scalars['String']['input'];
}>;


export type FolderQuery = { __typename?: 'Query', folder: { __typename?: 'DocumentFolder', id: string, createdAt: any, updatedAt: any, name: string, description?: string | null, subFolders: Array<{ __typename?: 'DocumentFolder', id: string, createdAt: any, updatedAt: any, name: string, description?: string | null }>, files: Array<{ __typename?: 'Document', id: string, createdAt: any, updatedAt: any, name: string }> } };

export type UploadDocumentMutationVariables = Exact<{
  input: CreateDocumentInput;
}>;


export type UploadDocumentMutation = { __typename?: 'Mutation', createDocument: { __typename?: 'Document', id: string } };

export type DocumentDeletionRequestQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;


export type DocumentDeletionRequestQuery = { __typename?: 'Query', documentDeletionRequest: { __typename?: 'DocumentDeletionRequest', id: string, createdAt: any, note?: string | null, wasExecuted: boolean, requesterId: string, document?: { __typename?: 'Document', url?: string | null } | null, requester: { __typename?: 'Admin', id: string, firstName: string, lastName: string }, approvers: Array<{ __typename?: 'Admin', id: string, firstName: string, lastName: string }> } };

export type ApproveDeletionRequestMutationVariables = Exact<{
  input: ApproveDocumentDeletionRequestInput;
}>;


export type ApproveDeletionRequestMutation = { __typename?: 'Mutation', approveDocumentDeletion?: boolean | null };

export type CreateFolderMutationVariables = Exact<{
  input: CreateDocumentFolderInput;
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', createDocumentFolder: { __typename?: 'DocumentFolder', id: string } };

export type UpdateFolderMutationVariables = Exact<{
  input: UpdateDocumentFolderInput;
}>;


export type UpdateFolderMutation = { __typename?: 'Mutation', updateDocumentFolder: { __typename?: 'DocumentFolder', id: string } };

export type DeleteFolderMutationVariables = Exact<{
  input: DeleteDocumentFolderInput;
}>;


export type DeleteFolderMutation = { __typename?: 'Mutation', deleteDocumentFolder: boolean };

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput;
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string } };

export type RequestDocumentDeletionMutationVariables = Exact<{
  input: RequestDocumentDeletionInput;
}>;


export type RequestDocumentDeletionMutation = { __typename?: 'Mutation', requestDocumentDeletion: boolean };

export type CreateToptLayerMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateToptLayerMutation = { __typename?: 'Mutation', createTOPTLayer: string };

export type ActivateToptLayerMutationVariables = Exact<{
  input: ActivateToptLayerInput;
}>;


export type ActivateToptLayerMutation = { __typename?: 'Mutation', activateTOPTLayer: boolean };

export type SystemWalletBalancesQueryVariables = Exact<{
  network: BlockchainNetwork;
}>;


export type SystemWalletBalancesQuery = { __typename?: 'Query', blockchainNetworkConfiguration: { __typename?: 'BlockchainNetworkConfiguration', executor: { __typename?: 'SystemWallet', id: string, nativeBalances: Array<{ __typename?: 'SystemWalletNativeBalance', network: BlockchainNetwork, balance: string }>, tokenBalances: Array<{ __typename?: 'SystemWalletTokenBalance', token: { __typename?: 'Token', id: string, symbol: string, name: string }, balance: Array<{ __typename?: 'SystemWalletTokenNetworkBalance', network: BlockchainNetwork, balance: string }> }> } } };

export type AdminContextQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminContextQuery = { __typename?: 'Query', admin?: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string, permissions: Array<AdminPermission>, isMFAEnabled: boolean } | null };

export type BankTransfersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankTransfersWhereInput>;
}>;


export type BankTransfersQuery = { __typename?: 'Query', bankTransfers: { __typename?: 'BankTransferConnection', nodes: Array<{ __typename?: 'BankTransfer', id: string, amount: any, status: BankTransferStatus, currency: FiatCurrency, payoutForInteractionId?: string | null, paymentForInteractionId?: string | null, client: { __typename?: 'Client', id: string, name: string } }>, page: { __typename?: 'PageInfo', pageSize: number, totalPages: number, totalNodes: number } } };

export type ExecuteBankTransferDetailsQueryVariables = Exact<{
  bankTransferId: Scalars['String']['input'];
}>;


export type ExecuteBankTransferDetailsQuery = { __typename?: 'Query', bankTransfer: { __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency, bankAccount?: { __typename?: 'BankAccount', id: string, bankName: string, nameOnAccount: string, iban: string, swift: string } | null, client: { __typename?: 'Client', preferences: { __typename?: 'ClientPreferences', defaultBankAccount?: { __typename?: 'BankAccount', id: string, bankName: string, nameOnAccount: string, iban: string, swift: string } | null } } } };

export type SetTransferBankAccountMutationVariables = Exact<{
  input: SetTransferBankAccountInput;
}>;


export type SetTransferBankAccountMutation = { __typename?: 'Mutation', setTransferBankAccount: { __typename?: 'BankTransfer', id: string } };

export type ExecuteBankTransferMutationVariables = Exact<{
  input: ExecuteBankTransferInput;
}>;


export type ExecuteBankTransferMutation = { __typename?: 'Mutation', executeBankTransfer: { __typename?: 'BankTransfer', id: string } };

export type BlockchainAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BlockchainAddressQuery = { __typename?: 'Query', blockchainAddress: { __typename?: 'BlockchainAddress', id: string, address: string, networks: Array<BlockchainNetwork>, type: BlockchainAddressType, description: string, tokenBalances: Array<{ __typename?: 'BlockchainAddressTokenBalance', token: { __typename?: 'Token', name: string, symbol: string, icon?: string | null }, balance: Array<{ __typename?: 'BlockchainAddressTokenNetworkBalance', balance: string, network: BlockchainNetwork }> }> } };

export type BlockchainAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockchainAddressesQuery = { __typename?: 'Query', blockchainAddresses: Array<{ __typename?: 'BlockchainAddress', id: string, address: string, networks: Array<BlockchainNetwork>, type: BlockchainAddressType, description: string }> };

export type AddBlockchainAddressMutationVariables = Exact<{
  input: CreateBlockchainAddressInput;
}>;


export type AddBlockchainAddressMutation = { __typename?: 'Mutation', addBlockchainAddress: { __typename?: 'BlockchainAddress', id: string } };

export type DeleteBlockchainAddressMutationVariables = Exact<{
  input: DeleteBlockchainAddressInput;
}>;


export type DeleteBlockchainAddressMutation = { __typename?: 'Mutation', deleteBlockchainAddress: boolean };

export type UpdateBlockchainAddressMutationVariables = Exact<{
  input: UpdateBlockchainAddressInput;
}>;


export type UpdateBlockchainAddressMutation = { __typename?: 'Mutation', updateBlockchainAddress: boolean };

export type OracleDetailsQueryVariables = Exact<{
  oracleId: Scalars['String']['input'];
}>;


export type OracleDetailsQuery = { __typename?: 'Query', oracle: { __typename?: 'Oracle', id: string, network: BlockchainNetwork, address: string, symbol: string, version: OracleVersion, isActive: boolean, managedBy: OracleManagedBy, updateSchedule: OracleUpdateSchedule, updateScheduleOnWeekend: boolean, latestTimestamp: number, type: OracleType, answerUpdates: Array<{ __typename?: 'OracleAnswerUpdate', id: string, createdAt: any, updatedAt: any, previousAnswer: string, newAnswer: string, status: OracleAnswerUpdateStatus, hash?: string | null, explorerUrl?: string | null, admin?: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string } | null }>, forwarder?: { __typename?: 'OracleForwarder', id: string, network: BlockchainNetwork, address: string } | null } };

export type TokenDeploymentOwnershipQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenDeploymentOwnershipQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, ownerAddress: string, pauserAddress: string, burnerAddress: string, minterAddress: string }> }> };

export type SupplyControlOperationDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SupplyControlOperationDetailsQuery = { __typename?: 'Query', supplyControlOperation: { __typename?: 'SupplyControlOperation', id: string, type: SupplyControlOperationType, amount: string, status: SupplyControlOperationStatus, hash?: string | null, possibleSigners: Array<string>, recipientWallet?: { __typename?: 'BlockchainAddress', address: string } | null, signatures: Array<{ __typename?: 'SupplyControlOperationSignature', id: string, address: string, admin?: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string } | null }>, deployment: { __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, address?: string | null, minterAddress: string, burnerAddress: string, systemWallet: { __typename?: 'SystemWallet', address: string }, token: { __typename?: 'Token', name: string, symbol: string } } } };

export type AllSupplyControlOperationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AllSupplyControlOperationsQuery = { __typename?: 'Query', supplyControlOperations: { __typename?: 'SupplyControlOperationConnection', nodes: Array<{ __typename?: 'SupplyControlOperation', id: string, type: SupplyControlOperationType, status: SupplyControlOperationStatus, amount: string, deployment: { __typename?: 'TokenDeployment', network: BlockchainNetwork, token: { __typename?: 'Token', name: string, symbol: string } } }>, page: { __typename?: 'PageInfo', totalNodes: number } } };

export type SupplyControlOperationSignaturePayloadQueryVariables = Exact<{
  operationId: Scalars['String']['input'];
}>;


export type SupplyControlOperationSignaturePayloadQuery = { __typename?: 'Query', supplyControlOperation: { __typename?: 'SupplyControlOperation', id: string, eip712Domain: any, possibleSigners: Array<string> } };

export type ProvideSupplyControlOperationSignatureMutationVariables = Exact<{
  input: ProvideSupplyControlOperationSignatureInput;
}>;


export type ProvideSupplyControlOperationSignatureMutation = { __typename?: 'Mutation', provideSupplyControlOperationSignature: boolean };

export type SupplyControlDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplyControlDialogQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string }> };

export type SupplyControlTokenDeploymentQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type SupplyControlTokenDeploymentQuery = { __typename?: 'Query', tokenDeployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork }> };

export type RequestSupplyControlOperationMutationVariables = Exact<{
  input: RequestSupplyControlOperationInput;
}>;


export type RequestSupplyControlOperationMutation = { __typename?: 'Mutation', requestSupplyControlOperation: { __typename?: 'SupplyControlOperation', id: string } };

export type IncomingTransactionDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type IncomingTransactionDetailsQuery = { __typename?: 'Query', incomingTransaction: { __typename?: 'IncomingTransaction', id: string, hash: string, index: number, network: BlockchainNetwork, type: IncomingTransactionType, amount: string, status: IncomingTransactionStatus, contractAddress: string, benefactorAddress: string, beneficiaryAddress: string, problems: Array<IncomingTransactionProblem>, problemsChecked: boolean, score?: number | null, scoreDetails?: any | null, scoreProvider?: IncomingTransactionScoreProvider | null, report?: { __typename?: 'BlockchainReport', id: string, reportId?: string | null, reportFiles?: any | null, reportPayload?: any | null } | null } };

export type IncomingTransactionsQueryVariables = Exact<{
  where?: InputMaybe<IncomingTransactionWhereInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type IncomingTransactionsQuery = { __typename?: 'Query', incomingTransactions: { __typename?: 'IncomingTransactionConnection', nodes: Array<{ __typename?: 'IncomingTransaction', id: string, createdAt: any, hash: string, explorerUrl: string, index: number, amount: string, status: IncomingTransactionStatus, type: IncomingTransactionType, displayAmount: string, tokenSymbol: string, network: BlockchainNetwork, score?: number | null, scoreProvider?: IncomingTransactionScoreProvider | null, problems: Array<IncomingTransactionProblem>, client: { __typename?: 'Client', id: string, name: string }, tokenDeployment?: { __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, token: { __typename?: 'Token', name: string, symbol: string } } | null, refund?: { __typename?: 'Refund', id: string, status: RefundStatus } | null, interaction?: { __typename?: 'Interaction', id: string, status: InteractionStatus, failureReason?: InteractionFailureReason | null } | null }>, page: { __typename?: 'PageInfo', currentPage: number, totalNodes: number, totalPages: number } } };

export type RetriggerIncomingTransactionConfirmationMutationVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type RetriggerIncomingTransactionConfirmationMutation = { __typename?: 'Mutation', retriggerIncomingTransactionConfirmation: boolean };

export type ApproveIncomingTransactionQueryQueryVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type ApproveIncomingTransactionQueryQuery = { __typename?: 'Query', incomingTransaction: { __typename?: 'IncomingTransaction', id: string, network: BlockchainNetwork, amount: string, decimals: number, tokenSymbol: string, value: any, valueCurrency: FiatCurrency, client: { __typename?: 'Client', name: string, jurisdiction: Country } } };

export type ApproveIncomingTransactionMutationVariables = Exact<{
  input: ApproveIncomingTransactionInput;
}>;


export type ApproveIncomingTransactionMutation = { __typename?: 'Mutation', approveIncomingTransaction: boolean };

export type InternalTransactionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InternalTransactionsWhereInput>;
}>;


export type InternalTransactionsQuery = { __typename?: 'Query', internalTransactions: { __typename?: 'InternalTransactionConnection', nodes: Array<{ __typename?: 'InternalTransaction', id: string, createdAt: any, updatedAt: any, action: InternalTransactionAction, status: InternalTransactionStatus, gaslessStrategy: GaslessStrategy, network: BlockchainNetwork, hash?: string | null, explorerUrl?: string | null }>, page: { __typename?: 'PageInfo', currentPage: number, totalNodes: number, totalPages: number } } };

export type ValueVerificationOutgoingTransactionQueryVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type ValueVerificationOutgoingTransactionQuery = { __typename?: 'Query', outgoingTransaction: { __typename?: 'OutgoingTransaction', id: string, network: BlockchainNetwork, amount: string, decimals: number, tokenSymbol: string, value: any, valueCurrency: FiatCurrency, isDepegged: boolean } };

export type VerifyOutgoingTransactionValueMutationVariables = Exact<{
  input: VerifyOutgoingTransactionValueInput;
}>;


export type VerifyOutgoingTransactionValueMutation = { __typename?: 'Mutation', verifyOutgoingTransactionValue: boolean };

export type ValueVerificationIncomingTransactionQueryVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type ValueVerificationIncomingTransactionQuery = { __typename?: 'Query', incomingTransaction: { __typename?: 'IncomingTransaction', id: string, network: BlockchainNetwork, amount: string, decimals: number, tokenSymbol: string, value: any, valueCurrency: FiatCurrency, isDepegged: boolean } };

export type VerifyIncomingTransactionValueMutationVariables = Exact<{
  input: VerifyIncomingTransactionValueInput;
}>;


export type VerifyIncomingTransactionValueMutation = { __typename?: 'Mutation', verifyIncomingTransactionValue: boolean };

export type ApproveOutgoingTransactionQueryQueryVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type ApproveOutgoingTransactionQueryQuery = { __typename?: 'Query', outgoingTransaction: { __typename?: 'OutgoingTransaction', id: string, network: BlockchainNetwork, amount: string, decimals: number, tokenSymbol: string, receiverAddress: string, contractAddress: string, interaction: { __typename?: 'Interaction', type: InteractionType } } };

export type CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables = Exact<{
  input: CanSystemWalletSatisfyOutgoingTransactionExecutionInput;
}>;


export type CanSystemWalletSatisfyOutgoingTransactionExecutionQuery = { __typename?: 'Query', canSystemWalletSatisfyOutgoingTransactionExecution?: { __typename?: 'CanSystemWalletSatisfyOutgoingTransactionExecution', gas: boolean, tokens: boolean } | null };

export type ApproveOutgoingTransactionMutationVariables = Exact<{
  input: ApproveOutgoingTransactionInput;
}>;


export type ApproveOutgoingTransactionMutation = { __typename?: 'Mutation', approveOutgoingTransaction: boolean };

export type OutgoingTransactionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutgoingTransactionsWhereInput>;
}>;


export type OutgoingTransactionsQuery = { __typename?: 'Query', outgoingTransactions: { __typename?: 'OutgoingTransactionConnection', nodes: Array<{ __typename?: 'OutgoingTransaction', id: string, createdAt: any, type: OutgoingTransactionType, hash?: string | null, amount: string, displayAmount: string, status: OutgoingTransactionStatus, network: BlockchainNetwork, explorerUrl?: string | null, refundId?: string | null, stablecoinContractAddress?: string | null, client: { __typename?: 'Client', id: string, name: string }, token?: { __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, token: { __typename?: 'Token', symbol: string, name: string } } | null }>, page: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalNodes: number } } };

export type OrderDvpFormQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;


export type OrderDvpFormQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, settlement?: { __typename?: 'Settlement', id: string, endOfDayFormDownloadUrl?: string | null } | null } | null };

export type OrderExecutionDetailsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;


export type OrderExecutionDetailsQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, side: OrderSide, status: OrderStatus, totalValue?: any | null, totalShares: number, sharesToTrade?: number | null, pricePerShare?: any | null, interaction: { __typename?: 'Interaction', id: string, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', amount: string }> }, collateral: { __typename?: 'Collateral', id: string, symbol: string, price?: number | null, balance: number, usedBalance: string, pendingBalance: number, priceCurrency: FiatCurrency, tokens: Array<{ __typename?: 'Token', pendingBalance: string }> } } | null };

export type InteractionExpectedPayoutQueryVariables = Exact<{
  tradedShares: Scalars['Int']['input'];
  pricePerShare: Scalars['String']['input'];
  interactionId: Scalars['String']['input'];
  collateralId: Scalars['String']['input'];
}>;


export type InteractionExpectedPayoutQuery = { __typename?: 'Query', interactionExpectedPayout: { __typename?: 'ExpectedPayout', payoutAmount: any, payoutSymbol: string, payoutDecimals?: number | null, payoutType: CurrencyType, feeAmount: any, feeSymbol: string, collateralShortage: any, overcollateral: any, collateral: { __typename?: 'Collateral', symbol: string } } };

export type CanSystemWalletSatisfyOrderExecutionQueryVariables = Exact<{
  input: CanSystemWalletSatisfyOrderExecutionInput;
}>;


export type CanSystemWalletSatisfyOrderExecutionQuery = { __typename?: 'Query', canSystemWalletSatisfyOrderExecution?: { __typename?: 'CanSystemWalletSatisfyOrderExecution', gas: boolean, tokens: boolean } | null };

export type BrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type BrokersQuery = { __typename?: 'Query', brokers: { __typename?: 'BrokerConnection', nodes: Array<{ __typename?: 'Broker', id: string, name?: string | null, settlementStrategy?: SettlementStrategy | null }> } };

export type SubmitOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  sharesToTrade: Scalars['Int']['input'];
  priceAtSubmission: Scalars['String']['input'];
}>;


export type SubmitOrderMutation = { __typename?: 'Mutation', submitOrder: { __typename?: 'Order', id: string } };

export type ExecuteOrderMutationVariables = Exact<{
  input: ExecuteOrderInput;
}>;


export type ExecuteOrderMutation = { __typename?: 'Mutation', executeOrder: { __typename?: 'Order', id: string } };

export type OrdersListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrdersWhereInput>;
}>;


export type OrdersListQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', nodes: Array<{ __typename?: 'Order', id: string, createdAt: any, updatedAt: any, side: OrderSide, status: OrderStatus, totalValue?: any | null, totalShares: number, pricePerShare?: any | null, interactionId: string, executionPath?: OrderExecutionPath | null, collateral: { __typename?: 'Collateral', symbol: string } }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number } } };

export type OrderDetailsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;


export type OrderDetailsQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, createdAt: any, updatedAt: any, executedAt?: any | null, side: OrderSide, status: OrderStatus, totalValue?: any | null, totalShares: number, pricePerShare?: any | null, collateral: { __typename?: 'Collateral', id: string, symbol: string }, events: Array<{ __typename?: 'Event', id: string, createdAt: any, updatedAt: any, type: EventType }> } | null };

export type CollateralsQueryVariables = Exact<{ [key: string]: never; }>;


export type CollateralsQuery = { __typename?: 'Query', collaterals: Array<{ __typename?: 'Collateral', id: string, symbol: string }> };

export type CreateEodFormMutationVariables = Exact<{
  input: CreateEodFormInput;
}>;


export type CreateEodFormMutation = { __typename?: 'Mutation', createEODForm?: { __typename?: 'EodData', url: string } | null };

export type PendingStatementsQueryVariables = Exact<{
  where: CustodyAccountWhereUniqueInput;
}>;


export type PendingStatementsQuery = { __typename?: 'Query', custodyAccount: { __typename?: 'CustodyAccount', events: Array<{ __typename?: 'CustodyAccountEvent', tookPlaceAt: any, changeAmount: any, changeCurrency: string, description: string, id: string, type: string }> } };

export type MarkSettlementAsExecutedMutationVariables = Exact<{
  input: MarkSettlementAsExecutedInput;
}>;


export type MarkSettlementAsExecutedMutation = { __typename?: 'Mutation', markSettlementAsExecuted: { __typename?: 'Settlement', id: string } };

export type SettlementsListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SettlementsListQuery = { __typename?: 'Query', settlements: { __typename?: 'SettlementConnection', nodes: Array<{ __typename?: 'Settlement', id: string, status: SettlementStatus, startedAt: any, executedAt?: any | null, fiatCurrency: string, finalFiatDiff?: number | null, endOfDayFormDownloadUrl?: string | null, collateral: { __typename?: 'Collateral', id: string, symbol: string, custodyAccounts: Array<{ __typename?: 'CustodyAccountFacet', type: CustodyAccountFacetType, asset: string, accountId?: string | null }> }, orders: Array<{ __typename?: 'Order', id: string, side: OrderSide, totalShares: number, pricePerShare?: any | null, interaction: { __typename?: 'Interaction', client: { __typename?: 'Client', name: string } } }> }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number } } };

export type BasicClientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BasicClientQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, status: ClientStatus, frozenUntil?: any | null } };

export type ClientBankAccountsAndWalletsQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
  withScore: Scalars['Boolean']['input'];
}>;


export type ClientBankAccountsAndWalletsQuery = { __typename?: 'Query', bankAccounts: Array<{ __typename?: 'BankAccount', id: string, createdAt: any, updatedAt: any, iban: string, verificationStatus: BankAccountVerificationStatus }>, externalWallets: Array<{ __typename?: 'ExternalWallet', id: string, createdAt: any, updatedAt: any, address: string, status: ExternalWalletStatus, scores?: Array<{ __typename?: 'ExternalWalletScore', id: string, score: number, provider?: ExternalWalletScoreProvider | null, network?: BlockchainNetwork | null, payload?: any | null }>, reports: Array<{ __typename?: 'BlockchainReport', id: string, createdAt: any, updatedAt: any, reportId?: string | null, reportPayload?: any | null, reportFiles?: any | null } | null> }> };

export type ClientFolderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClientFolderQuery = { __typename?: 'Query', client: { __typename?: 'Client', folder?: { __typename?: 'DocumentFolder', id: string, name: string, description?: string | null } | null } };

export type ClientFeaturesQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientFeaturesQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, features: Array<ClientFeature>, isProfessional: boolean, verificationProfile?: { __typename?: 'VerificationProfile', approved: boolean } | null, securityLogs: Array<{ __typename?: 'SecurityLog', id: string, createdAt: any, payload: any, message?: string | null, actorType: SecurityLogActor, actor?: { __typename?: 'Admin', id: string, firstName: string, lastName: string } | null }> } };

export type UpdateClientFeaturesMutationVariables = Exact<{
  input: UpdateClientFeaturesInput;
}>;


export type UpdateClientFeaturesMutation = { __typename?: 'Mutation', updateClientFeatures: { __typename?: 'Client', id: string } };

export type ClientFilesQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientFilesQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, files: Array<string> } };

export type ClientFileQueryVariables = Exact<{
  path: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
}>;


export type ClientFileQuery = { __typename?: 'Query', file?: { __typename?: 'File', path: string, authorisedUrl: string } | null };

export type ClientOverviewQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientOverviewQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, type: ClientType, humanId: string, jurisdiction: Country, createdAt: any, updatedAt: any, frozenUntil?: any | null, contactEmail: string, addresses: Array<{ __typename?: 'PhysicalAddress', isVerified: boolean, firstLine: string, secondLine?: string | null, city?: string | null, region?: string | null, postalCode?: string | null, country: string }>, verificationProfile?: { __typename?: 'VerificationProfile', riskLevel: RiskLevel, currentTier: VerificationTier } | null } };

export type ClientStatisticsQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientStatisticsQuery = { __typename?: 'Query', client: { __typename?: 'Client', statistics: { __typename?: 'ClientInteractionStatistics', currentValue: any, maxValue: any, lastOrder: any, counter: number, fees: any, issuances: { __typename?: 'InteractionBaseStatisticsType', value: any, counter: number }, redemptions: { __typename?: 'InteractionBaseStatisticsType', value: any, counter: number } } }, tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, icon?: string | null, statistics: { __typename?: 'TokenInteractionStatistics', currentValue: any, maxValue: any, lastOrder: any, counter: number, fees: any, issuances: { __typename?: 'InteractionStatistics', value: any, counter: number }, redemptions: { __typename?: 'InteractionStatistics', value: any, counter: number } } }> };

export type SweepingWalletsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  clientId: Scalars['ID']['input'];
}>;


export type SweepingWalletsQuery = { __typename?: 'Query', sweepingWallets?: { __typename?: 'SweepingWalletConnection', nodes: Array<{ __typename?: 'SweepingWallet', id: string, address: string, externalServiceId: string, externalService: string, type: string, token?: { __typename?: 'Token', symbol: string, icon?: string | null } | null }>, page: { __typename?: 'PageInfo', pageSize: number, totalPages: number, totalNodes: number } } | null };

export type GetUsageAndLimitsOfClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type GetUsageAndLimitsOfClientQuery = { __typename?: 'Query', client: { __typename?: 'Client', limits: { __typename?: 'UsageLimits', id: string, dailyTransactionLimit: any, monthlyTransactionLimit: any, tier: UsageLimitTier }, usage: { __typename?: 'ClientUsage', usages: Array<{ __typename?: 'Usage', value: any, usageOf: InteractionType }> } } };

export type UpdateClientUsageLimitsTierMutationVariables = Exact<{
  input: UpdateClientUsageLimitsInput;
}>;


export type UpdateClientUsageLimitsTierMutation = { __typename?: 'Mutation', updateClientUsageLimits?: { __typename?: 'UsageLimits', id: string } | null };

export type ClientUsersQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientUsersQuery = { __typename?: 'Query', client: { __typename?: 'Client', users: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, hasPasswordSet: boolean }> } };

export type RemoveUserPasswordMutationVariables = Exact<{
  input: RemoveUserPasswordInput;
}>;


export type RemoveUserPasswordMutation = { __typename?: 'Mutation', removeUserPassword: boolean };

export type ClientsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientsFilter>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', nodes: Array<{ __typename?: 'Client', id: string, createdAt: any, name: string, type: ClientType, isProfessional: boolean, jurisdiction: Country, status: ClientStatus, frozenUntil?: any | null, features: Array<ClientFeature>, verificationProfile?: { __typename?: 'VerificationProfile', riskLevel: RiskLevel, currentTier: VerificationTier } | null }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CloseClientMutationVariables = Exact<{
  input: CloseClientInput;
}>;


export type CloseClientMutation = { __typename?: 'Mutation', closeClient: { __typename?: 'ClientClosure', id: string } };

export type ClientClosureQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientClosureQuery = { __typename?: 'Query', client: { __typename?: 'Client', closure?: { __typename?: 'ClientClosure', id: string, createdAt: any, reason: string, admin: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string } } | null } };

export type CreateComplianceReviewMutationVariables = Exact<{
  input: CreateComplianceReviewInput;
}>;


export type CreateComplianceReviewMutation = { __typename?: 'Mutation', createComplianceReview: { __typename?: 'ComplianceReview', id: string } };

export type ArchiveVerificationMutationVariables = Exact<{
  input: ArchiveVerificationInput;
}>;


export type ArchiveVerificationMutation = { __typename?: 'Mutation', archiveVerification: boolean };

export type ResetQuestionnaireMutationVariables = Exact<{
  input: ResetClientQuestionnaireInput;
}>;


export type ResetQuestionnaireMutation = { __typename?: 'Mutation', resetClientQuestionnaire: boolean };

export type CreateVerificationTokenMutationVariables = Exact<{
  input: CreateVerificationTokenInput;
}>;


export type CreateVerificationTokenMutation = { __typename?: 'Mutation', createVerificationToken?: { __typename?: 'VerificationToken', token: string, expirationDate: any } | null };

export type ClientComplianceQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientComplianceQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, status: ClientStatus, isProfessional: boolean, questionnaires: Array<{ __typename?: 'ClientQuestionnaire', id: string, type: ClientQuestionnaireType, status: ClientQuestionnaireStatus, payload: any }>, verificationProfile?: { __typename?: 'VerificationProfile', id: string, currentTier: VerificationTier, riskLevel: RiskLevel, riskLevelFormatted: string, verifications: Array<{ __typename?: 'Verification', id: string, createdAt: any, updatedAt: any, status: VerificationStatus, tier: VerificationTier, isArchived: boolean, vendor: VerificationVendor, vendorApplicantId?: string | null }> } | null, riskAssessments: Array<{ __typename?: 'RiskAssessment', id: string, createdAt: any, updatedAt: any, concludedRisk: RiskLevel, concludedRiskPoints: number, concludedRiskFormatted: string, status: RiskAssessmentStatus, clientType: ClientType, filedBy?: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string } | null }> } };

export type ClientComplianceReviewQueryQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type ClientComplianceReviewQueryQuery = { __typename?: 'Query', client: { __typename?: 'Client', complianceReview?: { __typename?: 'ComplianceReview', id: string, createdAt: any, updatedAt: any, status: ComplianceReviewStatus, notes: string, isProfessional: boolean, complianceOfficer?: { __typename?: 'Admin', id: string, firstName: string, lastName: string } | null } | null } };

export type CreateClientAssessmentMutationVariables = Exact<{
  input: CreateRiskAssessmentInput;
}>;


export type CreateClientAssessmentMutation = { __typename?: 'Mutation', createRiskAssessment: { __typename?: 'RiskAssessment', id: string } };

export type InjectVerificationMutationVariables = Exact<{
  input: InjectVerificationInput;
}>;


export type InjectVerificationMutation = { __typename?: 'Mutation', injectVerification: { __typename?: 'Verification', id: string } };

export type ManuallyCreateClientMutationVariables = Exact<{
  input: CreateClientInput;
}>;


export type ManuallyCreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'CreateClientResult', result: CreateClientResultEnum, client?: { __typename?: 'Client', id: string } | null } };

export type ClientFeeOverridesQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
}>;


export type ClientFeeOverridesQuery = { __typename?: 'Query', feeConfigurations: { __typename?: 'FeesConfigurationConnection', nodes: Array<{ __typename?: 'FeesConfiguration', id: string, issuanceInitialFee: number, issuanceMinimumFee: number, issuancePercentageFee: number, redemptionInitialFee: number, redemptionMinimumFee: number, redemptionPercentageFee: number, tokenDeployment?: { __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, token: { __typename?: 'Token', name: string, symbol: string } } | null }> } };

export type DeleteClientFeeOverrideMutationVariables = Exact<{
  input: DeleteFeeConfigurationInput;
}>;


export type DeleteClientFeeOverrideMutation = { __typename?: 'Mutation', deleteFeeConfiguration: boolean };

export type ClientFeesConfigurationDialogDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientFeesConfigurationDialogDataQuery = { __typename?: 'Query', tokenDeployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, token: { __typename?: 'Token', id: string, name: string } }> };

export type CurrentClientFeesConfigurationQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  tokenDeploymentId: Scalars['String']['input'];
}>;


export type CurrentClientFeesConfigurationQuery = { __typename?: 'Query', feeConfiguration?: { __typename?: 'FeesConfiguration', id: string, issuanceInitialFee: number, issuanceMinimumFee: number, issuancePercentageFee: number, redemptionInitialFee: number, redemptionMinimumFee: number, redemptionPercentageFee: number } | null };

export type UpsertClientFeesConfigMutationVariables = Exact<{
  input: UpsertFeeConfigurationInput;
}>;


export type UpsertClientFeesConfigMutation = { __typename?: 'Mutation', upsertFeeConfiguration: { __typename?: 'FeesConfiguration', id: string } };

export type ActivateExternalWalletManuallyMutationVariables = Exact<{
  input: ActivateExternalWalletManuallyInput;
}>;


export type ActivateExternalWalletManuallyMutation = { __typename?: 'Mutation', activateExternalWalletManually: { __typename?: 'ExternalWallet', id: string } };

export type AddClientUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type AddClientUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string } | null };

export type CreateExternalWalletManuallyMutationVariables = Exact<{
  input: CreateExternalWalletManuallyInput;
}>;


export type CreateExternalWalletManuallyMutation = { __typename?: 'Mutation', createExternalWalletManually: { __typename?: 'ExternalWallet', id: string } };

export type UnfreezeClientMutationVariables = Exact<{
  input: UnfreezeClientInput;
}>;


export type UnfreezeClientMutation = { __typename?: 'Mutation', unfreezeClient: { __typename?: 'Client', id: string } };

export type UpdateClientEmailMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  updatedEmail: Scalars['String']['input'];
}>;


export type UpdateClientEmailMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', id: string, contactEmail: string } };

export type AdminsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminsWhereInput>;
}>;


export type AdminsQuery = { __typename?: 'Query', admins?: { __typename?: 'AdminConnection', nodes: Array<{ __typename?: 'Admin', id: string, createdAt: any, updatedAt: any, firstName: string, lastName: string, email: string, permissions: Array<AdminPermission> }>, page: { __typename?: 'PageInfo', currentPage: number, totalNodes: number, totalPages: number } } | null };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany?: { __typename?: 'Company', id: string } | null };

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename?: 'Company', id: string } | null };

export type CompaniesListPageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CompaniesListPageQuery = { __typename?: 'Query', companies?: { __typename?: 'CompanyConnection', page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean }, nodes: Array<{ __typename?: 'Company', id: string, createdAt: any, name: string, appHostPrefix: string, logo?: string | null }> } | null };

export type BasicCompanyQueryVariables = Exact<{
  where: CompanyWhereUniqueInput;
}>;


export type BasicCompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string } };

export type CompanyFeaturesQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type CompanyFeaturesQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, features: Array<CompanyFeature> } };

export type UpdateCompanyFeaturesMutationVariables = Exact<{
  input: UpdateCompanyFeaturesInput;
}>;


export type UpdateCompanyFeaturesMutation = { __typename?: 'Mutation', updateCompanyFeatures: { __typename?: 'Company', id: string } };

export type CompanyOverviewQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type CompanyOverviewQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string, logo?: string | null, appHostPrefix: string } };

export type UploadLogoMutationVariables = Exact<{
  input: UploadLogoInput;
}>;


export type UploadLogoMutation = { __typename?: 'Mutation', uploadLogo: boolean };

export type CompanyTokensQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyTokensQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, icon?: string | null, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork }> }> };

export type CompanyUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersFilter>;
}>;


export type CompanyUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', nodes: Array<{ __typename?: 'User', id: string, createdAt: any, updatedAt: any, firstName?: string | null, lastName?: string | null, email: string }>, page: { __typename?: 'PageInfo', currentPage: number, totalNodes: number, totalPages: number } } };

export type GetRiskAssessmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRiskAssessmentsQuery = { __typename?: 'Query', riskAssessments: Array<{ __typename?: 'RiskAssessment', id: string, createdAt: any, updatedAt: any, concludedRisk: RiskLevel, concludedRiskPoints: number, status: RiskAssessmentStatus, clientType: ClientType, verificationProfile: { __typename?: 'VerificationProfile', riskLevel: RiskLevel, client: { __typename?: 'Client', name: string, type: ClientType } } }> };

export type DetailedRiskAssessmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DetailedRiskAssessmentQuery = { __typename?: 'Query', riskAssessment: { __typename?: 'RiskAssessment', id: string, notes?: string | null, isBusinessActivityInNonServicedCountry?: boolean | null, isBusinessActivityInNonServicedCountryNotes?: string | null, isDomicileNonServicedCountryBased?: boolean | null, isDomicileNonServicedCountryBasedNotes?: string | null, isPoliticallyExposed?: boolean | null, isPoliticallyExposedNotes?: string | null, isDomiciledInMonitoredTerritory?: boolean | null, isDomiciledInMonitoredTerritoryNotes?: string | null, isUnderInvestigation?: boolean | null, isUnderInvestigationNotes?: string | null, isHighRiskForOtherReasons?: boolean | null, isHighRiskForOtherReasonsNotes?: string | null, isDomiciledInHighRiskCountry?: boolean | null, isDomiciledInHighRiskCountryNotes?: string | null, isBusinessActivityInHighRiskCountry?: boolean | null, isBusinessActivityInHighRiskCountryNotes?: string | null, isBusinessActivityInHighRiskSector?: boolean | null, isBusinessActivityInHighRiskSectorNotes?: string | null, isBusinessActivityStructureComplex?: boolean | null, isBusinessActivityStructureComplexNotes?: string | null, isFrequentTransactor?: boolean | null, isFrequentTransactorNotes?: string | null, isHighWealth?: boolean | null, isHighWealthNotes?: string | null, availableChecks: Array<{ __typename?: 'RiskAssessmentCheck', name: string, reject: boolean, displayName: string, description: string, riskFactor: number }>, verification: { __typename?: 'Verification', clientType: ClientType, vendorApplicantId?: string | null, client?: { __typename?: 'Client', id: string, name: string } | null } } };

export type UpdateRiskAssessmentMutationVariables = Exact<{
  input: UpdateRiskAssessmentInput;
}>;


export type UpdateRiskAssessmentMutation = { __typename?: 'Mutation', updateRiskAssessment: { __typename?: 'RiskAssessment', id: string } };

export type FileRiskAssessmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  concludedRiskLevel: RiskLevel;
}>;


export type FileRiskAssessmentMutation = { __typename?: 'Mutation', fileRiskAssessment: { __typename?: 'RiskAssessment', status: RiskAssessmentStatus } };

export type RiskAssessmentDetailsQueryVariables = Exact<{
  riskAssessmentId: Scalars['ID']['input'];
}>;


export type RiskAssessmentDetailsQuery = { __typename?: 'Query', riskAssessment: { __typename?: 'RiskAssessment', id: string, createdAt: any, updatedAt: any, concludedRisk: RiskLevel, concludedRiskPoints: number, status: RiskAssessmentStatus, isBusinessActivityInHighRiskCountry?: boolean | null, isBusinessActivityInHighRiskCountryNotes?: string | null, isBusinessActivityInHighRiskSector?: boolean | null, isBusinessActivityInHighRiskSectorNotes?: string | null, isBusinessActivityInNonServicedCountry?: boolean | null, isBusinessActivityInNonServicedCountryNotes?: string | null, isBusinessActivityStructureComplex?: boolean | null, isBusinessActivityStructureComplexNotes?: string | null, isDomicileNonServicedCountryBased?: boolean | null, isDomicileNonServicedCountryBasedNotes?: string | null, isDomiciledInHighRiskCountry?: boolean | null, isDomiciledInHighRiskCountryNotes?: string | null, isDomiciledInMonitoredTerritory?: boolean | null, isDomiciledInMonitoredTerritoryNotes?: string | null, isFrequentTransactor?: boolean | null, isFrequentTransactorNotes?: string | null, isHighRiskForOtherReasons?: boolean | null, isHighRiskForOtherReasonsNotes?: string | null, isHighWealth?: boolean | null, isHighWealthNotes?: string | null, isPoliticallyExposed?: boolean | null, isPoliticallyExposedNotes?: string | null, isUnderInvestigation?: boolean | null, isUnderInvestigationNotes?: string | null, filedBy?: { __typename?: 'Admin', id: string, firstName: string, lastName: string } | null, availableChecks: Array<{ __typename?: 'RiskAssessmentCheck', name: string, riskFactor: number, description: string, displayName: string }>, client: { __typename?: 'Client', id: string, name: string } } };

export type ClientRiskAssessmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClientRiskAssessmentQuery = { __typename?: 'Query', riskAssessment: { __typename?: 'RiskAssessment', verification: { __typename?: 'Verification', clientType: ClientType, client?: { __typename?: 'Client', id: string, name: string, isProfessional: boolean, type: ClientType, classification: ClientClassification, questionnaires: Array<{ __typename?: 'ClientQuestionnaire', id: string, status: ClientQuestionnaireStatus, payload: any }> } | null } } };

export type DocumentDetailsQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type DocumentDetailsQuery = { __typename?: 'Query', document: { __typename?: 'Document', id: string, url?: string | null, type?: DocumentType | null, client: { __typename?: 'Client', id: string, name: string, type: ClientType } } };

export type DocumentsListQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsListQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', id: string, type?: DocumentType | null, client: { __typename?: 'Client', id: string, name: string, type: ClientType } }> };

export type ComplianceReviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ComplianceReviewQuery = { __typename?: 'Query', complianceReview: { __typename?: 'ComplianceReview', id: string, createdAt: any, updatedAt: any, notes: string, isProfessional: boolean, featuresAllowed: Array<ClientFeature>, client: { __typename?: 'Client', id: string, name: string } } };

export type UpdateComplianceReviewMutationVariables = Exact<{
  input: UpdateComplianceReviewInput;
}>;


export type UpdateComplianceReviewMutation = { __typename?: 'Mutation', updateComplianceReview: { __typename?: 'ComplianceReview', id: string } };

export type SubmitComplianceReviewMutationVariables = Exact<{
  input: SubmitComplianceReviewInput;
}>;


export type SubmitComplianceReviewMutation = { __typename?: 'Mutation', submitComplianceReview: { __typename?: 'ComplianceReview', id: string } };

export type VerificationProfileDetailsQueryVariables = Exact<{
  profileId: Scalars['ID']['input'];
}>;


export type VerificationProfileDetailsQuery = { __typename?: 'Query', verificationProfile: { __typename?: 'VerificationProfile', id: string } };

export type VerificationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type VerificationsListQuery = { __typename?: 'Query', verifications: Array<{ __typename?: 'Verification', id: string, isArchived: boolean, createdAt: any, updatedAt: any, tier: VerificationTier, vendor: VerificationVendor, vendorApplicantId?: string | null, vendorVerificationId?: string | null, clientType: ClientType, status: VerificationStatus, client?: { __typename?: 'Client', id: string, name: string, jurisdiction: Country } | null }> };

export type AdminNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, title: string, type: NotificationType }> };

export type DashboardDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDataQuery = { __typename?: 'Query', riskAssessments: Array<{ __typename?: 'RiskAssessment', id: string, client: { __typename?: 'Client', id: string, name: string } }> };

export type GetTokenStatisticsQueryVariables = Exact<{
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
  isLatest?: InputMaybe<Scalars['Boolean']['input']>;
  isHistory?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetTokenStatisticsQuery = { __typename?: 'Query', tokenStatistics: Array<{ __typename?: 'TokenStatistics', createdAt: any, statistics: any }> };

export type TokenDeploymentDetailsQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type TokenDeploymentDetailsQuery = { __typename?: 'Query', token: { __typename?: 'Token', id: string, name: string, symbol: string, isPublic: boolean, description?: string | null, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, address?: string | null }>, collateral: { __typename?: 'Collateral', oracles: Array<{ __typename?: 'Oracle', network: BlockchainNetwork, isActive: boolean }> } } };

export type CreateDeploymentMutationVariables = Exact<{
  input: CreateDeploymentInput;
}>;


export type CreateDeploymentMutation = { __typename?: 'Mutation', createDeployment: { __typename?: 'Deployment', id: string } };

export type DeploymentDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeploymentDetailsQuery = { __typename?: 'Query', deployment: { __typename?: 'Deployment', id: string, status: DeploymentStatus, admin: { __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string }, tasks: Array<{ __typename?: 'DeploymentTask', id: string, createdAt: any, updatedAt: any, network: BlockchainNetwork, status: DeploymentStatus, steps: any, payload: any, contractType: DeploymentTaskContractType }>, token: { __typename?: 'Token', id: string, name: string, symbol: string } } };

export type DeploymentsHomeQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeploymentsWhereInput>;
}>;


export type DeploymentsHomeQuery = { __typename?: 'Query', deployments?: { __typename?: 'DeploymentConnection', nodes: Array<{ __typename?: 'Deployment', id: string, createdAt: any, status: DeploymentStatus, admin: { __typename?: 'Admin', id: string, firstName: string, lastName: string }, token: { __typename?: 'Token', id: string, name: string, symbol: string } }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number } } | null };

export type CustodyAccountDetailsQueryVariables = Exact<{
  where: CustodyAccountWhereUniqueInput;
}>;


export type CustodyAccountDetailsQuery = { __typename?: 'Query', custodyAccount: { __typename?: 'CustodyAccount', id: string, externalId: string, facets: Array<{ __typename?: 'CustodyAccountFacet', id: string, type: CustodyAccountFacetType, asset: string, balance: any, label: string, balanceChanges: Array<{ __typename?: 'CustodyAccountFacetBalanceChange', createdAt: any, balance: any }> }>, events: Array<{ __typename?: 'CustodyAccountEvent', id: string, tookPlaceAt: any, description: string, type: string, changeAmount: any, changeCurrency: string, flowDirection?: CustodyAccountEventFlowDirection | null }> } };

export type AccountsListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsListPageQuery = { __typename?: 'Query', custodyAccounts: Array<{ __typename?: 'CustodyAccount', id: string, externalId: string, facets: Array<{ __typename?: 'CustodyAccountFacet', id: string, balance: any, label: string, type: CustodyAccountFacetType, asset: string, isWorkingCapital?: boolean | null, collateral?: { __typename?: 'Collateral', symbol: string } | null }> }> };

export type PatchCustodyAccountFacetMutationVariables = Exact<{
  input: PatchCustodyAccountFacetMutationInput;
}>;


export type PatchCustodyAccountFacetMutation = { __typename?: 'Mutation', patchCustodyAccountFacet: { __typename?: 'CustodyAccountFacet', id: string } };

export type CollateralisationPageDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CollateralisationPageDataQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string }> };

export type WorkingCapitalQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkingCapitalQuery = { __typename?: 'Query', systemWallets: Array<{ __typename?: 'SystemWallet', id: string, title: string, description?: string | null, nativeBalances: Array<{ __typename?: 'SystemWalletNativeBalance', address: string, balance: string, network: BlockchainNetwork }>, tokenBalances: Array<{ __typename?: 'SystemWalletTokenBalance', balance: Array<{ __typename?: 'SystemWalletTokenNetworkBalance', network: BlockchainNetwork, balance: string, contractAddress: string, walletAddress: string }>, token: { __typename?: 'Token', id: string, name: string, symbol: string, isPublic: boolean } }> }> };

export type FlowsExecutionsListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FlowExecutionWhereInput>;
}>;


export type FlowsExecutionsListQuery = { __typename?: 'Query', flowExecutions: { __typename?: 'FlowExecutionConnection', nodes: Array<{ __typename?: 'FlowExecution', id: string, createdAt: any, flowType: FlowType, status: FlowExecutionStatus, mint?: { __typename?: 'Mint', id: string, variant: MintVariant, amount: any, hash?: string | null, explorerUrl?: string | null, tokenDeployment: { __typename?: 'TokenDeployment', network: BlockchainNetwork, token: { __typename?: 'Token', name: string, symbol: string, icon?: string | null } } } | null, burn?: { __typename?: 'Burn', id: string, variant: BurnVariant, amount: any, hash?: string | null, explorerUrl?: string | null, tokenDeployment: { __typename?: 'TokenDeployment', network: BlockchainNetwork, token: { __typename?: 'Token', name: string, symbol: string, icon?: string | null } } } | null }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type BurnFlowDialogQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type BurnFlowDialogQuery = { __typename?: 'Query', token: { __typename?: 'Token', id: string, name: string, symbol: string, icon?: string | null, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, circulatingSupply: string, totalSupply: string, burnAddress: string, burnSupply: string }>, collateral: { __typename?: 'Collateral', ISINNumber?: string | null } } };

export type MintFlowDialogQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type MintFlowDialogQuery = { __typename?: 'Query', token: { __typename?: 'Token', id: string, name: string, symbol: string, icon?: string | null, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, circulatingSupply: string }>, collateral: { __typename?: 'Collateral', ISINNumber?: string | null } }, blockchainAddresses: Array<{ __typename?: 'BlockchainAddress', id: string, address: string, description: string }> };

export type FlowExecutionDetailsQueryVariables = Exact<{
  flowId: Scalars['String']['input'];
}>;


export type FlowExecutionDetailsQuery = { __typename?: 'Query', flowExecution?: { __typename?: 'FlowExecution', status: FlowExecutionStatus } | null };

export type FlowExecutionDetailsOverviewQueryVariables = Exact<{
  flowId: Scalars['String']['input'];
}>;


export type FlowExecutionDetailsOverviewQuery = { __typename?: 'Query', flowExecution?: { __typename?: 'FlowExecution', id: string, createdAt: any, status: FlowExecutionStatus, flowType: FlowType, mint?: { __typename?: 'Mint', amount: any, hash?: string | null, explorerUrl?: string | null, blockchainAddress: { __typename?: 'BlockchainAddress', address: string }, tokenDeployment: { __typename?: 'TokenDeployment', network: BlockchainNetwork, token: { __typename?: 'Token', id: string, icon?: string | null, symbol: string, name: string, collateral: { __typename?: 'Collateral', ISINNumber?: string | null } } } } | null, burn?: { __typename?: 'Burn', amount: any, hash?: string | null, explorerUrl?: string | null, tokenDeployment: { __typename?: 'TokenDeployment', network: BlockchainNetwork, burnAddress: string, token: { __typename?: 'Token', id: string, icon?: string | null, symbol: string, name: string, collateral: { __typename?: 'Collateral', ISINNumber?: string | null } } } } | null } | null };

export type FlowSupportedTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowSupportedTokensQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, icon?: string | null, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork }> }> };

export type CreateFlowExecutionMutationVariables = Exact<{
  input: CreateFlowExecutionInput;
}>;


export type CreateFlowExecutionMutation = { __typename?: 'Mutation', createFlowExecution: { __typename?: 'FlowExecution', id: string } };

export type UpsertInjectionMutationVariables = Exact<{
  input: UpsertInjectionInput;
}>;


export type UpsertInjectionMutation = { __typename?: 'Mutation', upsertInjection?: { __typename?: 'Injection', id: string } | null };

export type ClientsAutocompleteSearchQueryVariables = Exact<{
  where?: InputMaybe<ClientsFilter>;
}>;


export type ClientsAutocompleteSearchQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', nodes: Array<{ __typename?: 'Client', id: string, name: string }> } };

export type CollateralAutocompleteSearchQueryVariables = Exact<{ [key: string]: never; }>;


export type CollateralAutocompleteSearchQuery = { __typename?: 'Query', collaterals: Array<{ __typename?: 'Collateral', id: string, symbol: string }> };

export type InjectIncomingTransactionDefinitionQueryVariables = Exact<{
  input: InteractionTransactionInjectDefinitionInput;
}>;


export type InjectIncomingTransactionDefinitionQuery = { __typename?: 'Query', injectionTransactionDefinitionQuery: Array<{ __typename?: 'TransactionInjectionDefinition', index: number, hash: string, network: BlockchainNetwork, contractAddress: string, benefactorAddress: string, beneficiaryAddress: string, blockTimestamp: number, decimals: number, amount: string, symbol: string, client?: { __typename?: 'Client', id: string, name: string } | null }> };

export type InjectionDetailsQueryVariables = Exact<{
  injectionId: Scalars['String']['input'];
}>;


export type InjectionDetailsQuery = { __typename?: 'Query', injection: { __typename?: 'Injection', id: string, status: InjectionStatus, payload: any } };

export type InjectionClientQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;


export type InjectionClientQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, humanId: string, name: string, jurisdiction: Country, contactEmail: string } };

export type ExecuteInjectionMutationVariables = Exact<{
  input: ExecuteInjectionInput;
}>;


export type ExecuteInjectionMutation = { __typename?: 'Mutation', executeInjection?: { __typename?: 'Injection', id: string, interaction?: { __typename?: 'Interaction', id: string } | null } | null };

export type InteractionStatusQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type InteractionStatusQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', status: InteractionStatus } | null };

export type InteractionDetailsInvoiceQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type InteractionDetailsInvoiceQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', id: string, fee?: { __typename?: 'Fee', id: string, fee: any, principal: any, currency: FiatCurrency, invoice?: { __typename?: 'Invoice', id: string, isPaid: boolean, invoiceNumber: number, downloadUrl?: string | null } | null, internalTransaction?: { __typename?: 'InternalTransaction', hash?: string | null, explorerUrl?: string | null, network: BlockchainNetwork, status: InternalTransactionStatus } | null } | null } | null };

export type RegenerateInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;


export type RegenerateInvoiceMutation = { __typename?: 'Mutation', generateInvoicePDF: { __typename?: 'Invoice', id: string } };

export type InteractionDetailsOrdersQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type InteractionDetailsOrdersQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', id: string, orders: Array<{ __typename?: 'Order', id: string, totalValue?: any | null, totalShares: number, pricePerShare?: any | null, side: OrderSide, status: OrderStatus, collateral: { __typename?: 'Collateral', symbol: string, priceCurrency: FiatCurrency } }> } | null };

export type InteractionDetailsOverviewQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type InteractionDetailsOverviewQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', id: string, createdAt: any, updatedAt: any, type: InteractionType, status: InteractionStatus, failureReason?: InteractionFailureReason | null, flags: Array<InteractionFlag>, isInjected: boolean, currency: FiatCurrency, actualTotalValue: any, expectedTotalValue: any, fee?: { __typename?: 'Fee', id: string, fee: any, principal: any } | null, client: { __typename?: 'Client', id: string, humanId: string, name: string, jurisdiction: Country, isProfessional: boolean, contactEmail: string, classification: ClientClassification, usage: { __typename?: 'ClientUsage', issuanceDailyUsage: any, issuanceMonthlyUsage: any, redemptionDailyUsage: any, redemptionMonthlyUsage: any } }, events: Array<{ __typename?: 'Event', id: string, createdAt: any, type: EventType, payload?: any | null }> } | null };

export type InteractionDetailsLayoutQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type InteractionDetailsLayoutQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', id: string, createdAt: any, updatedAt: any, type: InteractionType, status: InteractionStatus, flags: Array<InteractionFlag> } | null };

export type CreateRefundMutationVariables = Exact<{
  input: CreateRefundInput;
}>;


export type CreateRefundMutation = { __typename?: 'Mutation', createRefund: { __typename?: 'Refund', id: string } };

export type UpdateInteractionProductsMutationVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type UpdateInteractionProductsMutation = { __typename?: 'Mutation', updateInteraction: { __typename?: 'Interaction', id: string } };

export type InteractionsMonitoringQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type InteractionsMonitoringQuery = { __typename?: 'Query', interactions: { __typename?: 'InteractionConnection', nodes: Array<{ __typename?: 'Interaction', id: string, createdAt: any, type: InteractionType, status: InteractionStatus, currency: FiatCurrency, flags: Array<InteractionFlag>, automatedChecks: Array<InteractionAutomatedCheck>, actualTotalValue: any, expectedTotalValue: any, comments: Array<{ __typename?: 'ComplianceComment', comment: string } | null>, reports: Array<{ __typename?: 'InteractionReport', id: string, type: InteractionReportType, comments: Array<{ __typename?: 'ComplianceComment', comment: string }> }>, client: { __typename?: 'Client', id: string, name: string, contactEmail: string }, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, network: BlockchainNetwork, amount: string, value: any, decimals: number, explorerUrl: string, type: IncomingTransactionType, score?: number | null, scoreDetails?: any | null, scoreProvider?: IncomingTransactionScoreProvider | null, isHighRiskScore?: boolean | null, problemsChecked: boolean, tokenSymbol: string }>, outgoingTransactions: Array<{ __typename?: 'OutgoingTransaction', id: string, hash?: string | null, network: BlockchainNetwork, explorerUrl?: string | null, createdAt: any, tokenSymbol: string, amount: string, type: OutgoingTransactionType, displayAmount: string, value: any, decimals: number }>, fee?: { __typename?: 'Fee', fee: any, currency: FiatCurrency } | null }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CreateInteractionReportMutationVariables = Exact<{
  input: CreateInteractionReportInput;
}>;


export type CreateInteractionReportMutation = { __typename?: 'Mutation', createInteractionReport: { __typename?: 'InteractionReport', id: string } };

export type RemoveInteractionReportMutationVariables = Exact<{
  input: RemoveInteractionReportInput;
}>;


export type RemoveInteractionReportMutation = { __typename?: 'Mutation', removeInteractionReport: boolean };

export type UpdateInteractionMutationVariables = Exact<{
  data: UpdateInteractionDataInput;
  where: InteractionWhereUniqueInput;
}>;


export type UpdateInteractionMutation = { __typename?: 'Mutation', updateInteraction: { __typename?: 'Interaction', id: string } };

export type RefundsListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RefundsListQuery = { __typename?: 'Query', refunds: { __typename?: 'RefundConnection', nodes: Array<{ __typename?: 'Refund', id: string, status: RefundStatus, reason: string, failureReason?: RefundFailureReason | null, creationReason: RefundCreationReason, refundDefinition: any, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', decimals: number, tokenSymbol: string }>, client: { __typename?: 'Client', id: string, name: string, isProfessional: boolean } }>, page: { __typename?: 'PageInfo', totalNodes: number } } };

export type RefundDetailsQueryVariables = Exact<{
  refundId: Scalars['String']['input'];
}>;


export type RefundDetailsQuery = { __typename?: 'Query', refund?: { __typename?: 'Refund', id: string, status: RefundStatus, reason: string, creationReason: RefundCreationReason, refundDefinition: any, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, benefactorAddress: string, beneficiaryAddress: string, amount: string, network: BlockchainNetwork, problems: Array<IncomingTransactionProblem>, tokenSymbol: string, decimals: number, displayAmount: string, contractAddress: string }>, client: { __typename?: 'Client', id: string, name: string, frozenUntil?: any | null, verificationProfile?: { __typename?: 'VerificationProfile', riskLevel: RiskLevel, currentTier: VerificationTier } | null } } | null };

export type ExecuteRefundMutationVariables = Exact<{
  input: ExecuteRefundInput;
}>;


export type ExecuteRefundMutation = { __typename?: 'Mutation', executeRefund: boolean };

export type InteractionBankTransferDetailsFragment = { __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency };

export type InteractionsListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InteractionWhereInput>;
}>;


export type InteractionsListQuery = { __typename?: 'Query', interactions: { __typename?: 'InteractionConnection', nodes: Array<{ __typename?: 'Interaction', id: string, createdAt: any, type: InteractionType, payout: PayoutMethod, status: InteractionStatus, currency: FiatCurrency, flags: Array<InteractionFlag>, actualTotalValue: any, expectedTotalValue: any, client: { __typename?: 'Client', id: string, name: string, contactEmail: string }, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, network: BlockchainNetwork, amount: string, decimals: number, explorerUrl: string, type: IncomingTransactionType, tokenSymbol: string }>, outgoingTransactions: Array<{ __typename?: 'OutgoingTransaction', id: string, hash?: string | null, network: BlockchainNetwork, explorerUrl?: string | null, createdAt: any, tokenSymbol: string, type: OutgoingTransactionType, amount: string, decimals: number }>, incomingTransfers: Array<{ __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency }>, outgoingTransfers: Array<{ __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency }> }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CompanyAdminPermissionsQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type CompanyAdminPermissionsQuery = { __typename?: 'Query', company: { __typename?: 'Company', adminPermissions: Array<AdminPermission> } };

export type CreateAdminMutationVariables = Exact<{
  input: CreateAdminUserInput;
}>;


export type CreateAdminMutation = { __typename?: 'Mutation', createAdmin?: { __typename?: 'Admin', id: string } | null };

export type UpdateAdminMutationVariables = Exact<{
  input: UpdateAdminInput;
}>;


export type UpdateAdminMutation = { __typename?: 'Mutation', updateAdmin?: { __typename?: 'Admin', id: string } | null };

export type DisableAdminMutationVariables = Exact<{
  input: DisableAdminInput;
}>;


export type DisableAdminMutation = { __typename?: 'Mutation', disableAdmin?: { __typename?: 'Admin', id: string } | null };

export type UpdateServiceAccountMutationVariables = Exact<{
  input: UpdateServiceAccountInput;
}>;


export type UpdateServiceAccountMutation = { __typename?: 'Mutation', updateServiceAccount?: { __typename?: 'ServiceAccount', id: string } | null };

export type CreateAdminServiceAccountMutationVariables = Exact<{
  input: CreateServiceAccountInput;
}>;


export type CreateAdminServiceAccountMutation = { __typename?: 'Mutation', createServiceAccount: { __typename?: 'ServiceAccountDetails', key: string, secret: string } };

export type DisableServiceAccountMutationVariables = Exact<{
  input: DisableServiceAccountMutationInput;
}>;


export type DisableServiceAccountMutation = { __typename?: 'Mutation', disableServiceAccount?: boolean | null };

export type ApiAccesesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ApiAccesesQuery = { __typename?: 'Query', serviceAccounts?: { __typename?: 'ServiceAccountConnection', page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean }, nodes: Array<{ __typename?: 'ServiceAccount', id: string, title: string, status: ServiceAccountStatus, createdAt: any, updatedAt: any, permissions: Array<AdminPermission> }> } | null };

export type EventDetailsQueryVariables = Exact<{
  where: EventWhereUniqueInput;
}>;


export type EventDetailsQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, createdAt: any, type: EventType, payload?: any | null, status: EventStatus, failures?: any | null, retryAttempts: Array<{ __typename?: 'EventRetryAttempt', id: string, createdAt: any, comment?: string | null, forFailures?: any | null } | null> } | null };

export type EventsListQueryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
}>;


export type EventsListQueryQuery = { __typename?: 'Query', events: { __typename?: 'EventConnection', nodes: Array<{ __typename?: 'Event', id: string, createdAt: any, type: EventType, status: EventStatus }>, page: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalNodes: number } } };

export type RetryEventMutationVariables = Exact<{
  input: RetryEventMutationInput;
}>;


export type RetryEventMutation = { __typename?: 'Mutation', retryEvent: { __typename?: 'EventRetryAttempt', id: string } };

export type FactoriesListQueryVariables = Exact<{ [key: string]: never; }>;


export type FactoriesListQuery = { __typename?: 'Query', factories: Array<{ __typename?: 'ContractFactory', id: string, address: string, networks: Array<BlockchainNetwork>, type: ContractFactoryType, ownership: any, description: string }> };

export type RegisterFactoryMutationVariables = Exact<{
  input: RegisterContractFactoryInput;
}>;


export type RegisterFactoryMutation = { __typename?: 'Mutation', registerContractFactory?: { __typename?: 'ContractFactory', id: string } | null };

export type FeesConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type FeesConfigurationQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, feeConfigurations: Array<{ __typename?: 'FeesConfiguration', id: string, tier?: FeeTier | null, notes?: string | null, issuanceInitialFee: number, issuanceMinimumFee: number, issuancePercentageFee: number, redemptionInitialFee: number, redemptionMinimumFee: number, redemptionPercentageFee: number }> }> }> };

export type TokenDeploymentFeesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type TokenDeploymentFeesQuery = { __typename?: 'Query', tokenDeployment?: { __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, token: { __typename?: 'Token', name: string }, feeConfigurations: Array<{ __typename?: 'FeesConfiguration', id: string, tier?: FeeTier | null, notes?: string | null, issuanceInitialFee: number, issuanceMinimumFee: number, issuancePercentageFee: number, redemptionInitialFee: number, redemptionMinimumFee: number, redemptionPercentageFee: number }> } | null };

export type UpsertFeeConfigurationMutationVariables = Exact<{
  input: UpsertFeeConfigurationInput;
}>;


export type UpsertFeeConfigurationMutation = { __typename?: 'Mutation', upsertFeeConfiguration: { __typename?: 'FeesConfiguration', id: string } };

export type RoleDetailsQueryVariables = Exact<{
  where: RoleWhereUniqueInput;
}>;


export type RoleDetailsQuery = { __typename?: 'Query', company: { __typename?: 'Company', adminPermissions: Array<AdminPermission> }, role: { __typename?: 'Role', id: string, name: string, description: string, permissions: Array<AdminPermission>, admins: Array<{ __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string }> } };

export type UnassignRoleMutationVariables = Exact<{
  input: ToggleRoleInput;
}>;


export type UnassignRoleMutation = { __typename?: 'Mutation', toggleRole: boolean };

export type RolesListQueryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RolesListQueryQuery = { __typename?: 'Query', roles: { __typename?: 'RoleConnection', nodes: Array<{ __typename?: 'Role', id: string, name: string, description: string, type: string, permissions: Array<AdminPermission>, admins: Array<{ __typename?: 'Admin', email: string, firstName: string, lastName: string }> }>, page: { __typename?: 'PageInfo', currentPage: number, totalPages: number, totalNodes: number } } };

export type AdminsForRoleQueryVariables = Exact<{
  where?: InputMaybe<AdminsWhereInput>;
}>;


export type AdminsForRoleQuery = { __typename?: 'Query', admins?: { __typename?: 'AdminConnection', nodes: Array<{ __typename?: 'Admin', id: string, firstName: string, lastName: string, email: string }> } | null };

export type AssignRoleMutationVariables = Exact<{
  input: ToggleRoleInput;
}>;


export type AssignRoleMutation = { __typename?: 'Mutation', toggleRole: boolean };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: string } };

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: boolean };

export type SystemWalletsListQueryVariables = Exact<{
  where: GetSystemWalletsWhereInput;
}>;


export type SystemWalletsListQuery = { __typename?: 'Query', systemWallets: Array<{ __typename?: 'SystemWallet', id: string, address: string, title: string, description?: string | null, tokenBalances: Array<{ __typename?: 'SystemWalletTokenBalance', token: { __typename?: 'Token', symbol: string }, balance: Array<{ __typename?: 'SystemWalletTokenNetworkBalance', balance: string, network: BlockchainNetwork }> }> }> };

export type GenerateSystemWalletMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateSystemWalletMutation = { __typename?: 'Mutation', createSystemWallet: { __typename?: 'SystemWallet', id: string } };

export type DeployTokenMutationVariables = Exact<{
  token: CreateTokenInput;
  collateral: DefineTokenCollateralInput;
  deployment: DeploymentInput;
}>;


export type DeployTokenMutation = { __typename?: 'Mutation', createToken?: { __typename?: 'Deployment', id: string } | null };

export type DeployTokenDataQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type DeployTokenDataQuery = { __typename?: 'Query', token: { __typename?: 'Token', id: string, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork }> } };

export type TokenSupportingStructuresQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenSupportingStructuresQuery = { __typename?: 'Query', systemWallets: Array<{ __typename?: 'SystemWallet', id: string, title: string, address: string }> };

export type CreateTokenDeploymentMutationVariables = Exact<{
  input: CreateTokenDeploymentInput;
}>;


export type CreateTokenDeploymentMutation = { __typename?: 'Mutation', createTokenDeployment: { __typename?: 'TokenDeployment', id: string } };

export type ImportTokenDeploymentMutationVariables = Exact<{
  input: ImportTokenDeploymentInput;
}>;


export type ImportTokenDeploymentMutation = { __typename?: 'Mutation', importTokenDeployment: { __typename?: 'TokenDeployment', tokenId: string } };

export type ApplyOracleAnswerUpdateMutationVariables = Exact<{
  input: ApplyOracleAnswerUpdateInput;
}>;


export type ApplyOracleAnswerUpdateMutation = { __typename?: 'Mutation', applyOracleAnswerUpdate: boolean };

export type TokenCollateralizationDetailsQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type TokenCollateralizationDetailsQuery = { __typename?: 'Query', token: { __typename?: 'Token', id: string, symbol: string, pendingBalance: string, collateral: { __typename?: 'Collateral', symbol: string, price?: number | null, balance: number, usedBalance: string, pendingBalance: number, tokens: Array<{ __typename?: 'Token', id: string, pendingBalance: string, deployments: Array<{ __typename?: 'TokenDeployment', circulatingSupply: string, totalSupply: string }> }> } } };

export type ClearanceAccountDetailsQueryVariables = Exact<{
  input: ClearanceAccountWhereUniqueInput;
}>;


export type ClearanceAccountDetailsQuery = { __typename?: 'Query', clearanceAccount?: { __typename?: 'ClearanceAccount', id: string, institutionName?: string | null, depositoryCode?: string | null, institutionBic?: string | null, broker: { __typename?: 'Broker', id: string, name?: string | null } } | null };

export type CollateralCustodyQueryVariables = Exact<{
  collateralId: Scalars['String']['input'];
}>;


export type CollateralCustodyQuery = { __typename?: 'Query', collateral: { __typename?: 'Collateral', id: string, symbol: string, clearanceAccounts: Array<{ __typename?: 'ClearanceAccount', id: string, institutionName?: string | null, depositoryCode?: string | null, institutionBic?: string | null, broker: { __typename?: 'Broker', id: string, name?: string | null } }> } };

export type BrokersClearanceAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrokersClearanceAccountsQuery = { __typename?: 'Query', brokers: { __typename?: 'BrokerConnection', nodes: Array<{ __typename?: 'Broker', id: string, name?: string | null, clearanceAccounts: Array<{ __typename?: 'ClearanceAccount', id: string, institutionName?: string | null, institutionBic?: string | null, depositoryCode?: string | null }> }> } };

export type UpsertClearanceAccountMutationVariables = Exact<{
  input: UpsertClearanceAccountInput;
}>;


export type UpsertClearanceAccountMutation = { __typename?: 'Mutation', upsertClearanceAccount: { __typename?: 'ClearanceAccount', id: string } };

export type PriceUpdateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PriceUpdateQuery = { __typename?: 'Query', oracleAnswerUpdate: { __typename?: 'OracleAnswerUpdate', id: string, createdAt: any, newAnswer: string, previousAnswer: string } };

export type CreateCustodyAccountFacetMutationVariables = Exact<{
  input: CreateCustodyAccountFacetInput;
}>;


export type CreateCustodyAccountFacetMutation = { __typename?: 'Mutation', createCustodyAccountFacet: { __typename?: 'CustodyAccountFacet', id: string } };

export type CreateOracleMutationVariables = Exact<{
  input: CreateOracleInput;
}>;


export type CreateOracleMutation = { __typename?: 'Mutation', createOracle: { __typename?: 'Oracle', id: string } };

export type RegisterOracleForwarderMutationVariables = Exact<{
  input: RegisterOracleForwarderInput;
}>;


export type RegisterOracleForwarderMutation = { __typename?: 'Mutation', registerOracleForwarder: { __typename?: 'OracleForwarder', id: string } };

export type UpdateTokenMultiplierMutationVariables = Exact<{
  input: UpdateTokenMultiplierInput;
}>;


export type UpdateTokenMultiplierMutation = { __typename?: 'Mutation', updateTokenMultiplier: boolean };

export type TokenCollateralCustodyQueryVariables = Exact<{
  tokenId: Scalars['String']['input'];
}>;


export type TokenCollateralCustodyQuery = { __typename?: 'Query', collateral: { __typename?: 'Collateral', id: string, symbol: string, priceCurrency: FiatCurrency, clearanceAccounts: Array<{ __typename?: 'ClearanceAccount', id: string, institutionName?: string | null, depositoryCode?: string | null, institutionBic?: string | null, updatedAt: any, broker: { __typename?: 'Broker', id: string, name?: string | null } }> } };

export type AvailableCustodyAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableCustodyAccountsQuery = { __typename?: 'Query', custodyAccounts: Array<{ __typename?: 'CustodyAccount', externalId: string, label?: string | null, facets: Array<{ __typename?: 'CustodyAccountFacet', id: string, type: CustodyAccountFacetType, asset: string, collateral?: { __typename?: 'Collateral', id: string } | null }> }> };

export type UpdateCollateralCustodyAccountMutationVariables = Exact<{
  input: UpdateCollateralCustodyAccountInput;
}>;


export type UpdateCollateralCustodyAccountMutation = { __typename?: 'Mutation', updateCollateralCustodyAccount: { __typename?: 'Collateral', id: string } };

export type TokenCollateralizationQueryVariables = Exact<{
  tokenId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type TokenCollateralizationQuery = { __typename?: 'Query', token: { __typename?: 'Token', symbol: string, currentMultiplier?: any | null, lastMultiplierUpdateTimestamp: any, collateral: { __typename?: 'Collateral', symbol: string }, managementFee?: { __typename?: 'ManagementFee', amount: any, feeAccruals: Array<{ __typename?: 'FeeAccrual', id: string, createdAt: any, nominalFeeCollected: any }> } | null, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, multiplierChanges: Array<{ __typename?: 'TokenMultiplierChange', id: string, executedAt: any, multiplier: any, reason: TokenMultiplierChangeReason, previousMultiplier: any }> }> } };

export type TokenDeploymentsQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type TokenDeploymentsQuery = { __typename?: 'Query', tokenDeployments: Array<{ __typename?: 'TokenDeployment', id: string, deploymentStatus: TokenDeploymentStatus, address?: string | null, network: BlockchainNetwork }> };

export type TokenCollateralQueryVariables = Exact<{
  tokenId: Scalars['String']['input'];
}>;


export type TokenCollateralQuery = { __typename?: 'Query', collateral: { __typename?: 'Collateral', id: string, symbol: string, oracles: Array<{ __typename?: 'Oracle', id: string, updateSchedule: OracleUpdateSchedule, isActive: boolean, type: OracleType, network: BlockchainNetwork, address: string, forwarder?: { __typename?: 'OracleForwarder', address: string } | null, answerUpdates: Array<{ __typename?: 'OracleAnswerUpdate', id: string, newAnswer: string, previousAnswer: string }> }> } };

export type UpdateOracleAnswerMutationVariables = Exact<{
  input: UpdateOracleAnswerInput;
}>;


export type UpdateOracleAnswerMutation = { __typename?: 'Mutation', updateOracleAnswer: boolean };

export type TokenDetailQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type TokenDetailQuery = { __typename?: 'Query', token: { __typename?: 'Token', name: string, symbol: string, description?: string | null, icon?: string | null, isin?: string | null, collateral: { __typename?: 'Collateral', ISINNumber?: string | null, ISINName?: string | null, symbol: string, lastKnownPrice: any, publiclyListed?: boolean | null }, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, address?: string | null, circulatingSupply: string, totalSupply: string }> } };

export type TokenSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TokenSettingsQuery = { __typename?: 'Query', token: { __typename?: 'Token', name: string, description?: string | null, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, deploymentStatus: TokenDeploymentStatus }> } };

export type PublishTokenMutationVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type PublishTokenMutation = { __typename?: 'Mutation', publishToken: { __typename?: 'Token', id: string } };

export type TokenStatisticsQueryVariables = Exact<{
  tokenId: Scalars['ID']['input'];
}>;


export type TokenStatisticsQuery = { __typename?: 'Query', token: { __typename?: 'Token', statistics: { __typename?: 'TokenInteractionStatistics', currentValue: any, maxValue: any, counter: number, fees: any, lastOrder: any, perClient?: Array<{ __typename?: 'TokenInteractionPerClientStatistics', clientId: string, clientName: string, currentValue: any, lastOrder: any, maxValue: any, fees: any, counter: number }> | null, issuances: { __typename?: 'InteractionStatistics', value: any, fees: any, counter: number }, redemptions: { __typename?: 'InteractionStatistics', value: any, fees: any, counter: number } } } };

export type TokenListQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenListQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, description?: string | null, icon?: string | null, isin?: string | null, isPublic: boolean, collateral: { __typename?: 'Collateral', ISINNumber?: string | null, price?: number | null, priceCurrency: FiatCurrency }, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, address?: string | null, circulatingSupply: string, totalSupply: string, stablecoinConfigs: Array<{ __typename?: 'StablecoinConfig', symbol: string }> }> }> };

export type CreateClientInvitationMutationVariables = Exact<{
  input: CreateInvitationInput;
}>;


export type CreateClientInvitationMutation = { __typename?: 'Mutation', createInvitation?: { __typename?: 'Invitation', id: string } | null };

export type InvitationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvitationsFilter>;
}>;


export type InvitationsQuery = { __typename?: 'Query', invitations: { __typename?: 'InvitationConnection', nodes: Array<{ __typename?: 'Invitation', id: string, createdAt: any, updatedAt: any, email: string, isClaimed: boolean, claimedAt?: any | null }>, page: { __typename?: 'PageInfo', totalNodes: number } } };

export type TradingAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type TradingAssetsQuery = { __typename?: 'Query', tradingAssets: Array<{ __typename?: 'TradingAsset', id: string, name: string, type: TradingAssetType }> };

export type TradingBrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type TradingBrokersQuery = { __typename?: 'Query', brokers: { __typename?: 'BrokerConnection', nodes: Array<{ __typename?: 'Broker', id: string, name?: string | null, tradingEngineSupport?: boolean | null }> } };

export type ExecuteTradeMutationVariables = Exact<{
  input: ExecuteTradeInput;
}>;


export type ExecuteTradeMutation = { __typename?: 'Mutation', executeTrade: { __typename?: 'Trade', id: string } };

export type TradesListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type TradesListPageQuery = { __typename?: 'Query', trades: Array<{ __typename?: 'Trade', id: string, createdAt: any, amount: number, side: TradeSide, providerTradeId?: string | null, provider?: string | null, exchangeRate?: number | null, status: TradeStatus, baseAsset?: { __typename?: 'TradingAsset', name: string } | null, quoteAsset?: { __typename?: 'TradingAsset', name: string } | null }> };

export type UsersListPageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersFilter>;
}>;


export type UsersListPageQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean }, nodes: Array<{ __typename?: 'User', id: string, createdAt: any, firstName?: string | null, secondName?: string | null, lastName?: string | null, email: string, client: { __typename?: 'Client', id: string, name: string } }> } };

export type NetworkSimulatorRuleListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type NetworkSimulatorRuleListQuery = { __typename?: 'Query', networkSimulatorRules: { __typename?: 'NetworkSimulatorRuleConnection', nodes: Array<{ __typename?: 'NetworkSimulatorRule', id: string, createdAt: any, updatedAt: any, pathRegex?: string | null, payloadRegex?: string | null, transformScript?: string | null }>, page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CreateNetworkSimulatorRuleMutationVariables = Exact<{
  input: CreateNetworkSimulatorRuleInput;
}>;


export type CreateNetworkSimulatorRuleMutation = { __typename?: 'Mutation', createNetworkSimulatorRule: { __typename?: 'NetworkSimulatorRule', id: string } };

export type DeleteNetworkSimulatorRuleMutationVariables = Exact<{
  input: DeleteNetworkSimulatorRuleInput;
}>;


export type DeleteNetworkSimulatorRuleMutation = { __typename?: 'Mutation', deleteNetworkSimulatorRule: { __typename?: 'NetworkSimulatorRule', id: string } };

export type DissmisSetPasswordPromptMutationVariables = Exact<{ [key: string]: never; }>;


export type DissmisSetPasswordPromptMutation = { __typename?: 'Mutation', setPasswordPromptFeature: boolean };

export type ContactUsMutationVariables = Exact<{
  input: ContactInput;
}>;


export type ContactUsMutation = { __typename?: 'Mutation', contact: boolean };

export type ExternalWalletsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type ExternalWalletsDropdownQuery = { __typename?: 'Query', externalWallets: Array<{ __typename?: 'ExternalWallet', id: string, address: string }> };

export type AvailableDeploymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableDeploymentsQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork }> }> };

export type ActivateExternalWalletBySigningMessageMutationVariables = Exact<{
  input: ActivateExternalWalletBySigningMessageInput;
}>;


export type ActivateExternalWalletBySigningMessageMutation = { __typename?: 'Mutation', activateExternalWalletBySigningMessage: { __typename?: 'ExternalWallet', id: string } };

export type CreateExternalWalletBySigningMessageMutationVariables = Exact<{
  input: CreateExternalWalletInput;
}>;


export type CreateExternalWalletBySigningMessageMutation = { __typename?: 'Mutation', createExternalWalletBySigningMessage: { __typename?: 'ExternalWallet', id: string } };

export type DeactivateExternalWalletMutationVariables = Exact<{
  input: DeactivateExternalWalletInput;
}>;


export type DeactivateExternalWalletMutation = { __typename?: 'Mutation', deactivateExternalWallet: { __typename?: 'ExternalWallet', id: string } };

export type CreateExternalWalletMicroTransactionMutationVariables = Exact<{
  input: CreateExternalWalletMicroTransactionInput;
}>;


export type CreateExternalWalletMicroTransactionMutation = { __typename?: 'Mutation', createExternalWalletMicroTransaction: { __typename?: 'ExternalWalletMicroTransaction', id: string, verifyAmount: string, walletAddress: string } };

export type MicroTransactionWalletAddressQueryVariables = Exact<{
  network: BlockchainNetwork;
}>;


export type MicroTransactionWalletAddressQuery = { __typename?: 'Query', blockchainNetworkConfiguration: { __typename?: 'BlockchainNetworkConfiguration', microTransactionWalletAddress?: string | null, nativeCurrency: NativeCurrency } };

export type CreateExternalWalletSigningMessageMutationVariables = Exact<{
  input: CreateExternalWalletSigningMessageInput;
}>;


export type CreateExternalWalletSigningMessageMutation = { __typename?: 'Mutation', createExternalWalletSigningMessage: { __typename?: 'ExternalWalletSigningMessage', id: string, message: string, walletAddress: string } };

export type AssetReservesQueryVariables = Exact<{ [key: string]: never; }>;


export type AssetReservesQuery = { __typename?: 'Query', assetReserves: Array<{ __typename?: 'AssetReserves', symbol: string, provider: AssetReservesProvider, reserves: string, inTransit: string, tokensInCirculation: string, timestamp: any }> };

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, collateral: { __typename?: 'Collateral', tradingViewId?: string | null } }> };

export type IssueDetailsQueryVariables = Exact<{
  network: BlockchainNetwork;
}>;


export type IssueDetailsQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, chainId: number, address?: string | null, stablecoinConfigs: Array<{ __typename?: 'StablecoinConfig', symbol: string, decimals: number, address: string, network: BlockchainNetwork, chainId: number, minimumTransactionValue: string }> }>, collateral: { __typename?: 'Collateral', exchange?: { __typename?: 'Exchange', isOpen: boolean } | null }, sweepingWallet: { __typename?: 'SweepingWallet', id: string, address: string } }> };

export type RedemptionWalletQueryVariables = Exact<{
  network: BlockchainNetwork;
}>;


export type RedemptionWalletQuery = { __typename?: 'Query', client: { __typename?: 'Client', redemptionWallet: { __typename?: 'SweepingWallet', id: string, address: string, networks: Array<BlockchainNetwork> } }, tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, minimumTransactionValue: string, deployments: Array<{ __typename?: 'TokenDeployment', id: string, network: BlockchainNetwork, chainId: number, address?: string | null }> }> };

export type UpdateQuestionnaireMutationVariables = Exact<{
  input: UpdateClientQuestionnaireInput;
}>;


export type UpdateQuestionnaireMutation = { __typename?: 'Mutation', updateClientQuestionnaire: { __typename?: 'ClientQuestionnaire', id: string } };

export type CreateClientMutationVariables = Exact<{
  input: CreateClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'CreateClientResult', result: CreateClientResultEnum } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string } | null };

export type FinaliseQuestionnaireMutationVariables = Exact<{
  input: FinaliseClientQuestionnaireInput;
}>;


export type FinaliseQuestionnaireMutation = { __typename?: 'Mutation', finaliseClientQuestionnaire: { __typename?: 'ClientQuestionnaire', id: string, result?: ClientQuestionnaireResult | null } };

export type OnboardingRoutingQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingRoutingQuery = { __typename?: 'Query', client: { __typename?: 'Client', status: ClientStatus, questionnaires: Array<{ __typename?: 'ClientQuestionnaire', id: string, status: ClientQuestionnaireStatus, payload: any }> } };

export type CreateVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateVerificationMutation = { __typename?: 'Mutation', createVerification: { __typename?: 'Verification', vendorToken?: string | null } };

export type TransactionFeesQueryVariables = Exact<{ [key: string]: never; }>;


export type TransactionFeesQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, chainId: number, feeConfiguration: { __typename?: 'FeesConfiguration', id: string, issuanceInitialFee: number, issuanceMinimumFee: number, issuancePercentageFee: number, redemptionInitialFee: number, redemptionMinimumFee: number, redemptionPercentageFee: number } }> }> };

export type AccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDetailsQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, type: ClientType, jurisdiction: Country }, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string } | null };

export type ApiSettingsDataQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ApiSettingsDataQuery = { __typename?: 'Query', serviceAccounts?: { __typename?: 'ServiceAccountConnection', page: { __typename?: 'PageInfo', totalNodes: number, totalPages: number, currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean }, nodes: Array<{ __typename?: 'ServiceAccount', id: string, createdAt: any, updatedAt: any, status: ServiceAccountStatus, title: string }> } | null };

export type FetchDefaultBankAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchDefaultBankAccountQuery = { __typename?: 'Query', clientPreferences: { __typename?: 'ClientPreferences', defaultBankAccountId?: string | null } };

export type UpdateDefaultBankAccountMutationVariables = Exact<{
  input: UpdateDefaultBankAccountInput;
}>;


export type UpdateDefaultBankAccountMutation = { __typename?: 'Mutation', updateDefaultBankAccount?: boolean | null };

export type FetchAllBankAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllBankAccountsQuery = { __typename?: 'Query', bankAccounts: Array<{ __typename?: 'BankAccount', id: string, description: string, nameOnAccount: string, iban: string, swift: string, bankName: string, bankAddress: string }> };

export type CreateBankAccountMutationVariables = Exact<{
  input: CreateBankAccountInput;
}>;


export type CreateBankAccountMutation = { __typename?: 'Mutation', createBankAccount: { __typename?: 'BankAccount', id: string } };

export type RegisteredWalletsQueryVariables = Exact<{ [key: string]: never; }>;


export type RegisteredWalletsQuery = { __typename?: 'Query', externalWallets: Array<{ __typename?: 'ExternalWallet', id: string, createdAt: any, updatedAt: any, status: ExternalWalletStatus, address: string }> };

export type SetUserPasswordMutationVariables = Exact<{
  input: SetUserPasswordInput;
}>;


export type SetUserPasswordMutation = { __typename?: 'Mutation', setUserPassword: boolean };

export type CreateServiceAccountMutationVariables = Exact<{
  input: CreateServiceAccountInput;
}>;


export type CreateServiceAccountMutation = { __typename?: 'Mutation', createServiceAccount: { __typename?: 'ServiceAccountDetails', key: string } };

export type DepositPageRedemptionDataQueryVariables = Exact<{
  network: BlockchainNetwork;
}>;


export type DepositPageRedemptionDataQuery = { __typename?: 'Query', client: { __typename?: 'Client', redemptionWallet: { __typename?: 'SweepingWallet', id: string, address: string } } };

export type DepositPageIssuanceDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DepositPageIssuanceDataQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, name: string, symbol: string, deployments: Array<{ __typename?: 'TokenDeployment', network: BlockchainNetwork, stablecoinConfigs: Array<{ __typename?: 'StablecoinConfig', symbol: string }>, sweepingWallet: { __typename?: 'SweepingWallet', id: string, address: string } }> }> };

export type LoadClientPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadClientPreferencesQuery = { __typename?: 'Query', stablecoins: Array<{ __typename?: 'Stablecoin', id: string, symbol: string }>, clientPreferences: { __typename?: 'ClientPreferences', id: string, payoutMethod: PayoutMethod, executionMode: ExecutionMode, bankAccountPayoutCurrency: FiatCurrency, defaultStablecoin?: { __typename?: 'Stablecoin', id: string, symbol: string } | null }, bankAccounts: Array<{ __typename?: 'BankAccount', id: string, iban: string, verificationStatus: BankAccountVerificationStatus }> };

export type UpdateClientPreferencesMutationVariables = Exact<{
  input: UpdateClientPreferencesInput;
}>;


export type UpdateClientPreferencesMutation = { __typename?: 'Mutation', updateClientPreferences?: { __typename?: 'ClientPreferences', id: string } | null };

export type ClientInteractionsQueryVariables = Exact<{
  where?: InputMaybe<InteractionWhereInput>;
}>;


export type ClientInteractionsQuery = { __typename?: 'Query', interactions: { __typename?: 'InteractionConnection', nodes: Array<{ __typename?: 'Interaction', id: string, createdAt: any, status: InteractionStatus, type: InteractionType, failureReason?: InteractionFailureReason | null, orders: Array<{ __typename?: 'Order', pricePerShare?: any | null }>, fee?: { __typename?: 'Fee', fee: any, principal: any } | null, tokenDeployments: Array<{ __typename?: 'TokenDeployment', token: { __typename?: 'Token', symbol: string } }>, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, amount: string, confirmations: number, confirmationsNeeded: number, displayAmount: string, tokenSymbol: string, network: BlockchainNetwork, explorerUrl: string, decimals: number, type: IncomingTransactionType, refund?: { __typename?: 'Refund', status: RefundStatus } | null }>, outgoingTransactions: Array<{ __typename?: 'OutgoingTransaction', amount: string, displayAmount: string, type: OutgoingTransactionType }> }> } };

export type ClientInteractionDetailsQueryVariables = Exact<{
  interactionId: Scalars['String']['input'];
}>;


export type ClientInteractionDetailsQuery = { __typename?: 'Query', interaction?: { __typename?: 'Interaction', id: string, status: InteractionStatus, type: InteractionType, createdAt: any, tokenDeployments: Array<{ __typename?: 'TokenDeployment', token: { __typename?: 'Token', symbol: string } }>, incomingTransactions: Array<{ __typename?: 'IncomingTransaction', id: string, hash: string, network: BlockchainNetwork, amount: string, decimals: number, explorerUrl: string, type: IncomingTransactionType, tokenSymbol: string }>, outgoingTransactions: Array<{ __typename?: 'OutgoingTransaction', id: string, hash?: string | null, network: BlockchainNetwork, explorerUrl?: string | null, createdAt: any, tokenSymbol: string, type: OutgoingTransactionType, amount: string, decimals: number }>, fee?: { __typename?: 'Fee', id: string, fee: any, principal: any } | null, orders: Array<{ __typename?: 'Order', id: string, createdAt: any, pricePerShare?: any | null }>, invoice?: { __typename?: 'Invoice', id: string, downloadUrl?: string | null } | null, incomingTransfers: Array<{ __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency }>, outgoingTransfers: Array<{ __typename?: 'BankTransfer', id: string, amount: any, currency: FiatCurrency }> } | null };

export type AuthResultFragment = { __typename?: 'AuthenticationResult', successful: boolean, destination?: string | null, transitionToken?: string | null, authenticationToken?: string | null, errorCode?: string | null, errorMessage?: string | null, errorReference?: string | null };

export type AuthenticateWithTransitionTokenMutationVariables = Exact<{
  input: AuthenticateWithTransitionTokenInput;
}>;


export type AuthenticateWithTransitionTokenMutation = { __typename?: 'Mutation', authenticateWithTransitionToken: { __typename?: 'AuthenticationResult', successful: boolean, destination?: string | null, transitionToken?: string | null, authenticationToken?: string | null, errorCode?: string | null, errorMessage?: string | null, errorReference?: string | null } };

export type StartEmailChallengeMutationVariables = Exact<{
  input: StartEmailChallengeInput;
}>;


export type StartEmailChallengeMutation = { __typename?: 'Mutation', startEmailChallenge: { __typename?: 'StartEmailChallengeResult', successful: boolean, errorMessage?: string | null, hasPassword?: boolean | null } };

export type AuthenticateWithEmailChallengeMutationVariables = Exact<{
  input: AuthenticateEmailChallengeInput;
}>;


export type AuthenticateWithEmailChallengeMutation = { __typename?: 'Mutation', authenticateWithEmailChallenge: { __typename?: 'AuthenticationResult', successful: boolean, destination?: string | null, transitionToken?: string | null, authenticationToken?: string | null, errorCode?: string | null, errorMessage?: string | null, errorReference?: string | null } };

export type AuthenticateWithPasswordChallengeMutationVariables = Exact<{
  input: AuthenticateWithPasswordInput;
}>;


export type AuthenticateWithPasswordChallengeMutation = { __typename?: 'Mutation', authenticateWithPassword: { __typename?: 'AuthenticationResult', successful: boolean, destination?: string | null, transitionToken?: string | null, authenticationToken?: string | null, errorCode?: string | null, errorMessage?: string | null, errorReference?: string | null } };

export type GetClientContextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientContextQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, name: string, type: ClientType, status: ClientStatus, classification: ClientClassification, jurisdiction: Country, features: Array<ClientFeature>, hasWalletRegistered: boolean, isProfessional: boolean, shouldRegisterWallet: boolean, shouldActivateWallet: boolean, externalWallets: Array<{ __typename?: 'ExternalWallet', address: string, status: ExternalWalletStatus }>, verificationProfile?: { __typename?: 'VerificationProfile', currentTier: VerificationTier, approved: boolean } | null, limits: { __typename?: 'UsageLimits', tier: UsageLimitTier, dailyTransactionLimit: any, monthlyTransactionLimit: any }, usage: { __typename?: 'ClientUsage', issuanceDailyUsage: any, redemptionDailyUsage: any, issuanceMonthlyUsage: any, redemptionMonthlyUsage: any } } };

export type GetCompanyContextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyContextQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string, logo?: string | null, features: Array<CompanyFeature> } };

export type GetUserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserContextQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, features: Array<UserFeature>, hasPasswordSet: boolean, passwordLastChange?: any | null } | null };

export type SystemConfigurationQueryVariables = Exact<{
  key: SystemConfigurationKey;
}>;


export type SystemConfigurationQuery = { __typename?: 'Query', systemConfiguration: { __typename?: 'SystemConfiguration', value: any } };

export const InteractionBankTransferDetailsFragmentDoc = gql`
    fragment InteractionBankTransferDetails on BankTransfer {
  id
  amount
  currency
}
    `;
export const AuthResultFragmentDoc = gql`
    fragment authResult on AuthenticationResult {
  successful
  destination
  transitionToken
  authenticationToken
  errorCode
  errorMessage
  errorReference
}
    `;
export const CommandMenuDocument = gql`
    query CommandMenu($filter: ClientsFilter) {
  clients(where: $filter, pageSize: 5) {
    nodes {
      id
      name
      contactEmail
    }
  }
}
    `;

/**
 * __useCommandMenuQuery__
 *
 * To run a query within a React component, call `useCommandMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandMenuQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommandMenuQuery(baseOptions?: Apollo.QueryHookOptions<CommandMenuQuery, CommandMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandMenuQuery, CommandMenuQueryVariables>(CommandMenuDocument, options);
      }
export function useCommandMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandMenuQuery, CommandMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandMenuQuery, CommandMenuQueryVariables>(CommandMenuDocument, options);
        }
export function useCommandMenuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommandMenuQuery, CommandMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommandMenuQuery, CommandMenuQueryVariables>(CommandMenuDocument, options);
        }
export type CommandMenuQueryHookResult = ReturnType<typeof useCommandMenuQuery>;
export type CommandMenuLazyQueryHookResult = ReturnType<typeof useCommandMenuLazyQuery>;
export type CommandMenuSuspenseQueryHookResult = ReturnType<typeof useCommandMenuSuspenseQuery>;
export type CommandMenuQueryResult = Apollo.QueryResult<CommandMenuQuery, CommandMenuQueryVariables>;
export const CommandMenuClientsSearchDocument = gql`
    query commandMenuClientsSearch($filter: ClientsFilter) {
  clients(where: $filter) {
    nodes {
      id
      humanId
      name
      type
      status
      isProfessional
      contactEmail
    }
  }
}
    `;

/**
 * __useCommandMenuClientsSearchQuery__
 *
 * To run a query within a React component, call `useCommandMenuClientsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandMenuClientsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandMenuClientsSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommandMenuClientsSearchQuery(baseOptions?: Apollo.QueryHookOptions<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>(CommandMenuClientsSearchDocument, options);
      }
export function useCommandMenuClientsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>(CommandMenuClientsSearchDocument, options);
        }
export function useCommandMenuClientsSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>(CommandMenuClientsSearchDocument, options);
        }
export type CommandMenuClientsSearchQueryHookResult = ReturnType<typeof useCommandMenuClientsSearchQuery>;
export type CommandMenuClientsSearchLazyQueryHookResult = ReturnType<typeof useCommandMenuClientsSearchLazyQuery>;
export type CommandMenuClientsSearchSuspenseQueryHookResult = ReturnType<typeof useCommandMenuClientsSearchSuspenseQuery>;
export type CommandMenuClientsSearchQueryResult = Apollo.QueryResult<CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables>;
export const CommandMenuTransactionsSearchDocument = gql`
    query commandMenuTransactionsSearch($filter: IncomingTransactionWhereInput) {
  incomingTransactions(where: $filter, pageSize: 3) {
    nodes {
      id
      hash
      network
      interaction {
        id
      }
      client {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCommandMenuTransactionsSearchQuery__
 *
 * To run a query within a React component, call `useCommandMenuTransactionsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandMenuTransactionsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandMenuTransactionsSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommandMenuTransactionsSearchQuery(baseOptions?: Apollo.QueryHookOptions<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>(CommandMenuTransactionsSearchDocument, options);
      }
export function useCommandMenuTransactionsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>(CommandMenuTransactionsSearchDocument, options);
        }
export function useCommandMenuTransactionsSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>(CommandMenuTransactionsSearchDocument, options);
        }
export type CommandMenuTransactionsSearchQueryHookResult = ReturnType<typeof useCommandMenuTransactionsSearchQuery>;
export type CommandMenuTransactionsSearchLazyQueryHookResult = ReturnType<typeof useCommandMenuTransactionsSearchLazyQuery>;
export type CommandMenuTransactionsSearchSuspenseQueryHookResult = ReturnType<typeof useCommandMenuTransactionsSearchSuspenseQuery>;
export type CommandMenuTransactionsSearchQueryResult = Apollo.QueryResult<CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables>;
export const CommentsDocument = gql`
    query comments($where: ComplianceCommentsWhereInput, $pageSize: Int = 10) {
  complianceComments(where: $where, pageSize: $pageSize) {
    id
    createdAt
    title
    comment
    admin {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export function useCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsSuspenseQueryHookResult = ReturnType<typeof useCommentsSuspenseQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const CreateComplianceCommentDocument = gql`
    mutation createComplianceComment($input: CreateComplianceCommentInput!) {
  createComplianceComment(input: $input) {
    id
  }
}
    `;
export type CreateComplianceCommentMutationFn = Apollo.MutationFunction<CreateComplianceCommentMutation, CreateComplianceCommentMutationVariables>;

/**
 * __useCreateComplianceCommentMutation__
 *
 * To run a mutation, you first call `useCreateComplianceCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComplianceCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComplianceCommentMutation, { data, loading, error }] = useCreateComplianceCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateComplianceCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateComplianceCommentMutation, CreateComplianceCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComplianceCommentMutation, CreateComplianceCommentMutationVariables>(CreateComplianceCommentDocument, options);
      }
export type CreateComplianceCommentMutationHookResult = ReturnType<typeof useCreateComplianceCommentMutation>;
export type CreateComplianceCommentMutationResult = Apollo.MutationResult<CreateComplianceCommentMutation>;
export type CreateComplianceCommentMutationOptions = Apollo.BaseMutationOptions<CreateComplianceCommentMutation, CreateComplianceCommentMutationVariables>;
export const AccessDocumentDocument = gql`
    query accessDocument($documentId: ID!) {
  document(id: $documentId) {
    id
    name
    createdAt
    updatedAt
    isPendingDeletion
    url
    commentsCount
    uploader {
      id
      firstName
      lastName
      email
    }
    client {
      id
      name
      classification
    }
  }
}
    `;

/**
 * __useAccessDocumentQuery__
 *
 * To run a query within a React component, call `useAccessDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useAccessDocumentQuery(baseOptions: Apollo.QueryHookOptions<AccessDocumentQuery, AccessDocumentQueryVariables> & ({ variables: AccessDocumentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessDocumentQuery, AccessDocumentQueryVariables>(AccessDocumentDocument, options);
      }
export function useAccessDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessDocumentQuery, AccessDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessDocumentQuery, AccessDocumentQueryVariables>(AccessDocumentDocument, options);
        }
export function useAccessDocumentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccessDocumentQuery, AccessDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccessDocumentQuery, AccessDocumentQueryVariables>(AccessDocumentDocument, options);
        }
export type AccessDocumentQueryHookResult = ReturnType<typeof useAccessDocumentQuery>;
export type AccessDocumentLazyQueryHookResult = ReturnType<typeof useAccessDocumentLazyQuery>;
export type AccessDocumentSuspenseQueryHookResult = ReturnType<typeof useAccessDocumentSuspenseQuery>;
export type AccessDocumentQueryResult = Apollo.QueryResult<AccessDocumentQuery, AccessDocumentQueryVariables>;
export const FolderDocument = gql`
    query folder($folderId: String!) {
  folder(id: $folderId) {
    id
    createdAt
    updatedAt
    name
    description
    subFolders {
      id
      createdAt
      updatedAt
      name
      description
    }
    files {
      id
      createdAt
      updatedAt
      name
    }
  }
}
    `;

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useFolderQuery(baseOptions: Apollo.QueryHookOptions<FolderQuery, FolderQueryVariables> & ({ variables: FolderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
      }
export function useFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
        }
export function useFolderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FolderQuery, FolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
        }
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>;
export type FolderSuspenseQueryHookResult = ReturnType<typeof useFolderSuspenseQuery>;
export type FolderQueryResult = Apollo.QueryResult<FolderQuery, FolderQueryVariables>;
export const UploadDocumentDocument = gql`
    mutation uploadDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    id
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const DocumentDeletionRequestDocument = gql`
    query documentDeletionRequest($documentId: String!) {
  documentDeletionRequest(documentId: $documentId) {
    id
    createdAt
    note
    wasExecuted
    document {
      url
    }
    requesterId
    requester {
      id
      firstName
      lastName
    }
    approvers {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useDocumentDeletionRequestQuery__
 *
 * To run a query within a React component, call `useDocumentDeletionRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDeletionRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDeletionRequestQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentDeletionRequestQuery(baseOptions: Apollo.QueryHookOptions<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables> & ({ variables: DocumentDeletionRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>(DocumentDeletionRequestDocument, options);
      }
export function useDocumentDeletionRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>(DocumentDeletionRequestDocument, options);
        }
export function useDocumentDeletionRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>(DocumentDeletionRequestDocument, options);
        }
export type DocumentDeletionRequestQueryHookResult = ReturnType<typeof useDocumentDeletionRequestQuery>;
export type DocumentDeletionRequestLazyQueryHookResult = ReturnType<typeof useDocumentDeletionRequestLazyQuery>;
export type DocumentDeletionRequestSuspenseQueryHookResult = ReturnType<typeof useDocumentDeletionRequestSuspenseQuery>;
export type DocumentDeletionRequestQueryResult = Apollo.QueryResult<DocumentDeletionRequestQuery, DocumentDeletionRequestQueryVariables>;
export const ApproveDeletionRequestDocument = gql`
    mutation approveDeletionRequest($input: ApproveDocumentDeletionRequestInput!) {
  approveDocumentDeletion(input: $input)
}
    `;
export type ApproveDeletionRequestMutationFn = Apollo.MutationFunction<ApproveDeletionRequestMutation, ApproveDeletionRequestMutationVariables>;

/**
 * __useApproveDeletionRequestMutation__
 *
 * To run a mutation, you first call `useApproveDeletionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDeletionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDeletionRequestMutation, { data, loading, error }] = useApproveDeletionRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveDeletionRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveDeletionRequestMutation, ApproveDeletionRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveDeletionRequestMutation, ApproveDeletionRequestMutationVariables>(ApproveDeletionRequestDocument, options);
      }
export type ApproveDeletionRequestMutationHookResult = ReturnType<typeof useApproveDeletionRequestMutation>;
export type ApproveDeletionRequestMutationResult = Apollo.MutationResult<ApproveDeletionRequestMutation>;
export type ApproveDeletionRequestMutationOptions = Apollo.BaseMutationOptions<ApproveDeletionRequestMutation, ApproveDeletionRequestMutationVariables>;
export const CreateFolderDocument = gql`
    mutation createFolder($input: CreateDocumentFolderInput!) {
  createDocumentFolder(input: $input) {
    id
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const UpdateFolderDocument = gql`
    mutation updateFolder($input: UpdateDocumentFolderInput!) {
  updateDocumentFolder(input: $input) {
    id
  }
}
    `;
export type UpdateFolderMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, options);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation deleteFolder($input: DeleteDocumentFolderInput!) {
  deleteDocumentFolder(input: $input)
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const RequestDocumentDeletionDocument = gql`
    mutation requestDocumentDeletion($input: RequestDocumentDeletionInput!) {
  requestDocumentDeletion(input: $input)
}
    `;
export type RequestDocumentDeletionMutationFn = Apollo.MutationFunction<RequestDocumentDeletionMutation, RequestDocumentDeletionMutationVariables>;

/**
 * __useRequestDocumentDeletionMutation__
 *
 * To run a mutation, you first call `useRequestDocumentDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDocumentDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDocumentDeletionMutation, { data, loading, error }] = useRequestDocumentDeletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestDocumentDeletionMutation(baseOptions?: Apollo.MutationHookOptions<RequestDocumentDeletionMutation, RequestDocumentDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestDocumentDeletionMutation, RequestDocumentDeletionMutationVariables>(RequestDocumentDeletionDocument, options);
      }
export type RequestDocumentDeletionMutationHookResult = ReturnType<typeof useRequestDocumentDeletionMutation>;
export type RequestDocumentDeletionMutationResult = Apollo.MutationResult<RequestDocumentDeletionMutation>;
export type RequestDocumentDeletionMutationOptions = Apollo.BaseMutationOptions<RequestDocumentDeletionMutation, RequestDocumentDeletionMutationVariables>;
export const CreateToptLayerDocument = gql`
    mutation createTOPTLayer {
  createTOPTLayer
}
    `;
export type CreateToptLayerMutationFn = Apollo.MutationFunction<CreateToptLayerMutation, CreateToptLayerMutationVariables>;

/**
 * __useCreateToptLayerMutation__
 *
 * To run a mutation, you first call `useCreateToptLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateToptLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createToptLayerMutation, { data, loading, error }] = useCreateToptLayerMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateToptLayerMutation(baseOptions?: Apollo.MutationHookOptions<CreateToptLayerMutation, CreateToptLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateToptLayerMutation, CreateToptLayerMutationVariables>(CreateToptLayerDocument, options);
      }
export type CreateToptLayerMutationHookResult = ReturnType<typeof useCreateToptLayerMutation>;
export type CreateToptLayerMutationResult = Apollo.MutationResult<CreateToptLayerMutation>;
export type CreateToptLayerMutationOptions = Apollo.BaseMutationOptions<CreateToptLayerMutation, CreateToptLayerMutationVariables>;
export const ActivateToptLayerDocument = gql`
    mutation activateTOPTLayer($input: ActivateTOPTLayerInput!) {
  activateTOPTLayer(input: $input)
}
    `;
export type ActivateToptLayerMutationFn = Apollo.MutationFunction<ActivateToptLayerMutation, ActivateToptLayerMutationVariables>;

/**
 * __useActivateToptLayerMutation__
 *
 * To run a mutation, you first call `useActivateToptLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateToptLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateToptLayerMutation, { data, loading, error }] = useActivateToptLayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateToptLayerMutation(baseOptions?: Apollo.MutationHookOptions<ActivateToptLayerMutation, ActivateToptLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateToptLayerMutation, ActivateToptLayerMutationVariables>(ActivateToptLayerDocument, options);
      }
export type ActivateToptLayerMutationHookResult = ReturnType<typeof useActivateToptLayerMutation>;
export type ActivateToptLayerMutationResult = Apollo.MutationResult<ActivateToptLayerMutation>;
export type ActivateToptLayerMutationOptions = Apollo.BaseMutationOptions<ActivateToptLayerMutation, ActivateToptLayerMutationVariables>;
export const SystemWalletBalancesDocument = gql`
    query SystemWalletBalances($network: BlockchainNetwork!) {
  blockchainNetworkConfiguration(network: $network) {
    executor {
      id
      nativeBalances {
        network
        balance
      }
      tokenBalances {
        token {
          id
          symbol
          name
        }
        balance {
          network
          balance
        }
      }
    }
  }
}
    `;

/**
 * __useSystemWalletBalancesQuery__
 *
 * To run a query within a React component, call `useSystemWalletBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemWalletBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemWalletBalancesQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useSystemWalletBalancesQuery(baseOptions: Apollo.QueryHookOptions<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables> & ({ variables: SystemWalletBalancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>(SystemWalletBalancesDocument, options);
      }
export function useSystemWalletBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>(SystemWalletBalancesDocument, options);
        }
export function useSystemWalletBalancesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>(SystemWalletBalancesDocument, options);
        }
export type SystemWalletBalancesQueryHookResult = ReturnType<typeof useSystemWalletBalancesQuery>;
export type SystemWalletBalancesLazyQueryHookResult = ReturnType<typeof useSystemWalletBalancesLazyQuery>;
export type SystemWalletBalancesSuspenseQueryHookResult = ReturnType<typeof useSystemWalletBalancesSuspenseQuery>;
export type SystemWalletBalancesQueryResult = Apollo.QueryResult<SystemWalletBalancesQuery, SystemWalletBalancesQueryVariables>;
export const AdminContextDocument = gql`
    query adminContext {
  admin {
    id
    firstName
    lastName
    email
    permissions
    isMFAEnabled
  }
}
    `;

/**
 * __useAdminContextQuery__
 *
 * To run a query within a React component, call `useAdminContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminContextQuery(baseOptions?: Apollo.QueryHookOptions<AdminContextQuery, AdminContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminContextQuery, AdminContextQueryVariables>(AdminContextDocument, options);
      }
export function useAdminContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminContextQuery, AdminContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminContextQuery, AdminContextQueryVariables>(AdminContextDocument, options);
        }
export function useAdminContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminContextQuery, AdminContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminContextQuery, AdminContextQueryVariables>(AdminContextDocument, options);
        }
export type AdminContextQueryHookResult = ReturnType<typeof useAdminContextQuery>;
export type AdminContextLazyQueryHookResult = ReturnType<typeof useAdminContextLazyQuery>;
export type AdminContextSuspenseQueryHookResult = ReturnType<typeof useAdminContextSuspenseQuery>;
export type AdminContextQueryResult = Apollo.QueryResult<AdminContextQuery, AdminContextQueryVariables>;
export const BankTransfersDocument = gql`
    query bankTransfers($page: Int, $pageSize: Int, $where: BankTransfersWhereInput) {
  bankTransfers(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      amount
      status
      currency
      payoutForInteractionId
      paymentForInteractionId
      client {
        id
        name
      }
    }
    page {
      pageSize
      totalPages
      totalNodes
    }
  }
}
    `;

/**
 * __useBankTransfersQuery__
 *
 * To run a query within a React component, call `useBankTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankTransfersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBankTransfersQuery(baseOptions?: Apollo.QueryHookOptions<BankTransfersQuery, BankTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BankTransfersQuery, BankTransfersQueryVariables>(BankTransfersDocument, options);
      }
export function useBankTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BankTransfersQuery, BankTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BankTransfersQuery, BankTransfersQueryVariables>(BankTransfersDocument, options);
        }
export function useBankTransfersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BankTransfersQuery, BankTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BankTransfersQuery, BankTransfersQueryVariables>(BankTransfersDocument, options);
        }
export type BankTransfersQueryHookResult = ReturnType<typeof useBankTransfersQuery>;
export type BankTransfersLazyQueryHookResult = ReturnType<typeof useBankTransfersLazyQuery>;
export type BankTransfersSuspenseQueryHookResult = ReturnType<typeof useBankTransfersSuspenseQuery>;
export type BankTransfersQueryResult = Apollo.QueryResult<BankTransfersQuery, BankTransfersQueryVariables>;
export const ExecuteBankTransferDetailsDocument = gql`
    query ExecuteBankTransferDetails($bankTransferId: String!) {
  bankTransfer(bankTransferId: $bankTransferId) {
    id
    amount
    currency
    bankAccount {
      id
      bankName
      nameOnAccount
      iban
      swift
    }
    client {
      preferences {
        defaultBankAccount {
          id
          bankName
          nameOnAccount
          iban
          swift
        }
      }
    }
  }
}
    `;

/**
 * __useExecuteBankTransferDetailsQuery__
 *
 * To run a query within a React component, call `useExecuteBankTransferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecuteBankTransferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecuteBankTransferDetailsQuery({
 *   variables: {
 *      bankTransferId: // value for 'bankTransferId'
 *   },
 * });
 */
export function useExecuteBankTransferDetailsQuery(baseOptions: Apollo.QueryHookOptions<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables> & ({ variables: ExecuteBankTransferDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>(ExecuteBankTransferDetailsDocument, options);
      }
export function useExecuteBankTransferDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>(ExecuteBankTransferDetailsDocument, options);
        }
export function useExecuteBankTransferDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>(ExecuteBankTransferDetailsDocument, options);
        }
export type ExecuteBankTransferDetailsQueryHookResult = ReturnType<typeof useExecuteBankTransferDetailsQuery>;
export type ExecuteBankTransferDetailsLazyQueryHookResult = ReturnType<typeof useExecuteBankTransferDetailsLazyQuery>;
export type ExecuteBankTransferDetailsSuspenseQueryHookResult = ReturnType<typeof useExecuteBankTransferDetailsSuspenseQuery>;
export type ExecuteBankTransferDetailsQueryResult = Apollo.QueryResult<ExecuteBankTransferDetailsQuery, ExecuteBankTransferDetailsQueryVariables>;
export const SetTransferBankAccountDocument = gql`
    mutation setTransferBankAccount($input: SetTransferBankAccountInput!) {
  setTransferBankAccount(input: $input) {
    id
  }
}
    `;
export type SetTransferBankAccountMutationFn = Apollo.MutationFunction<SetTransferBankAccountMutation, SetTransferBankAccountMutationVariables>;

/**
 * __useSetTransferBankAccountMutation__
 *
 * To run a mutation, you first call `useSetTransferBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransferBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransferBankAccountMutation, { data, loading, error }] = useSetTransferBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransferBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<SetTransferBankAccountMutation, SetTransferBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransferBankAccountMutation, SetTransferBankAccountMutationVariables>(SetTransferBankAccountDocument, options);
      }
export type SetTransferBankAccountMutationHookResult = ReturnType<typeof useSetTransferBankAccountMutation>;
export type SetTransferBankAccountMutationResult = Apollo.MutationResult<SetTransferBankAccountMutation>;
export type SetTransferBankAccountMutationOptions = Apollo.BaseMutationOptions<SetTransferBankAccountMutation, SetTransferBankAccountMutationVariables>;
export const ExecuteBankTransferDocument = gql`
    mutation executeBankTransfer($input: ExecuteBankTransferInput!) {
  executeBankTransfer(input: $input) {
    id
  }
}
    `;
export type ExecuteBankTransferMutationFn = Apollo.MutationFunction<ExecuteBankTransferMutation, ExecuteBankTransferMutationVariables>;

/**
 * __useExecuteBankTransferMutation__
 *
 * To run a mutation, you first call `useExecuteBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeBankTransferMutation, { data, loading, error }] = useExecuteBankTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteBankTransferMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteBankTransferMutation, ExecuteBankTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteBankTransferMutation, ExecuteBankTransferMutationVariables>(ExecuteBankTransferDocument, options);
      }
export type ExecuteBankTransferMutationHookResult = ReturnType<typeof useExecuteBankTransferMutation>;
export type ExecuteBankTransferMutationResult = Apollo.MutationResult<ExecuteBankTransferMutation>;
export type ExecuteBankTransferMutationOptions = Apollo.BaseMutationOptions<ExecuteBankTransferMutation, ExecuteBankTransferMutationVariables>;
export const BlockchainAddressDocument = gql`
    query blockchainAddress($id: ID!) {
  blockchainAddress(id: $id) {
    id
    address
    networks
    type
    description
    tokenBalances {
      token {
        name
        symbol
        icon
      }
      balance {
        balance
        network
      }
    }
  }
}
    `;

/**
 * __useBlockchainAddressQuery__
 *
 * To run a query within a React component, call `useBlockchainAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockchainAddressQuery(baseOptions: Apollo.QueryHookOptions<BlockchainAddressQuery, BlockchainAddressQueryVariables> & ({ variables: BlockchainAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockchainAddressQuery, BlockchainAddressQueryVariables>(BlockchainAddressDocument, options);
      }
export function useBlockchainAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockchainAddressQuery, BlockchainAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockchainAddressQuery, BlockchainAddressQueryVariables>(BlockchainAddressDocument, options);
        }
export function useBlockchainAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BlockchainAddressQuery, BlockchainAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlockchainAddressQuery, BlockchainAddressQueryVariables>(BlockchainAddressDocument, options);
        }
export type BlockchainAddressQueryHookResult = ReturnType<typeof useBlockchainAddressQuery>;
export type BlockchainAddressLazyQueryHookResult = ReturnType<typeof useBlockchainAddressLazyQuery>;
export type BlockchainAddressSuspenseQueryHookResult = ReturnType<typeof useBlockchainAddressSuspenseQuery>;
export type BlockchainAddressQueryResult = Apollo.QueryResult<BlockchainAddressQuery, BlockchainAddressQueryVariables>;
export const BlockchainAddressesDocument = gql`
    query blockchainAddresses {
  blockchainAddresses {
    id
    address
    networks
    type
    description
  }
}
    `;

/**
 * __useBlockchainAddressesQuery__
 *
 * To run a query within a React component, call `useBlockchainAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockchainAddressesQuery(baseOptions?: Apollo.QueryHookOptions<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>(BlockchainAddressesDocument, options);
      }
export function useBlockchainAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>(BlockchainAddressesDocument, options);
        }
export function useBlockchainAddressesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>(BlockchainAddressesDocument, options);
        }
export type BlockchainAddressesQueryHookResult = ReturnType<typeof useBlockchainAddressesQuery>;
export type BlockchainAddressesLazyQueryHookResult = ReturnType<typeof useBlockchainAddressesLazyQuery>;
export type BlockchainAddressesSuspenseQueryHookResult = ReturnType<typeof useBlockchainAddressesSuspenseQuery>;
export type BlockchainAddressesQueryResult = Apollo.QueryResult<BlockchainAddressesQuery, BlockchainAddressesQueryVariables>;
export const AddBlockchainAddressDocument = gql`
    mutation addBlockchainAddress($input: CreateBlockchainAddressInput!) {
  addBlockchainAddress: createBlockchainAddress(input: $input) {
    id
  }
}
    `;
export type AddBlockchainAddressMutationFn = Apollo.MutationFunction<AddBlockchainAddressMutation, AddBlockchainAddressMutationVariables>;

/**
 * __useAddBlockchainAddressMutation__
 *
 * To run a mutation, you first call `useAddBlockchainAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBlockchainAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBlockchainAddressMutation, { data, loading, error }] = useAddBlockchainAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBlockchainAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddBlockchainAddressMutation, AddBlockchainAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBlockchainAddressMutation, AddBlockchainAddressMutationVariables>(AddBlockchainAddressDocument, options);
      }
export type AddBlockchainAddressMutationHookResult = ReturnType<typeof useAddBlockchainAddressMutation>;
export type AddBlockchainAddressMutationResult = Apollo.MutationResult<AddBlockchainAddressMutation>;
export type AddBlockchainAddressMutationOptions = Apollo.BaseMutationOptions<AddBlockchainAddressMutation, AddBlockchainAddressMutationVariables>;
export const DeleteBlockchainAddressDocument = gql`
    mutation DeleteBlockchainAddress($input: DeleteBlockchainAddressInput!) {
  deleteBlockchainAddress(input: $input)
}
    `;
export type DeleteBlockchainAddressMutationFn = Apollo.MutationFunction<DeleteBlockchainAddressMutation, DeleteBlockchainAddressMutationVariables>;

/**
 * __useDeleteBlockchainAddressMutation__
 *
 * To run a mutation, you first call `useDeleteBlockchainAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockchainAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockchainAddressMutation, { data, loading, error }] = useDeleteBlockchainAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBlockchainAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockchainAddressMutation, DeleteBlockchainAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockchainAddressMutation, DeleteBlockchainAddressMutationVariables>(DeleteBlockchainAddressDocument, options);
      }
export type DeleteBlockchainAddressMutationHookResult = ReturnType<typeof useDeleteBlockchainAddressMutation>;
export type DeleteBlockchainAddressMutationResult = Apollo.MutationResult<DeleteBlockchainAddressMutation>;
export type DeleteBlockchainAddressMutationOptions = Apollo.BaseMutationOptions<DeleteBlockchainAddressMutation, DeleteBlockchainAddressMutationVariables>;
export const UpdateBlockchainAddressDocument = gql`
    mutation updateBlockchainAddress($input: UpdateBlockchainAddressInput!) {
  updateBlockchainAddress(input: $input)
}
    `;
export type UpdateBlockchainAddressMutationFn = Apollo.MutationFunction<UpdateBlockchainAddressMutation, UpdateBlockchainAddressMutationVariables>;

/**
 * __useUpdateBlockchainAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBlockchainAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockchainAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockchainAddressMutation, { data, loading, error }] = useUpdateBlockchainAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlockchainAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlockchainAddressMutation, UpdateBlockchainAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlockchainAddressMutation, UpdateBlockchainAddressMutationVariables>(UpdateBlockchainAddressDocument, options);
      }
export type UpdateBlockchainAddressMutationHookResult = ReturnType<typeof useUpdateBlockchainAddressMutation>;
export type UpdateBlockchainAddressMutationResult = Apollo.MutationResult<UpdateBlockchainAddressMutation>;
export type UpdateBlockchainAddressMutationOptions = Apollo.BaseMutationOptions<UpdateBlockchainAddressMutation, UpdateBlockchainAddressMutationVariables>;
export const OracleDetailsDocument = gql`
    query oracleDetails($oracleId: String!) {
  oracle(where: {id: $oracleId}) {
    id
    network
    address
    symbol
    version
    isActive
    managedBy
    updateSchedule
    updateScheduleOnWeekend
    latestTimestamp
    type
    answerUpdates {
      id
      createdAt
      updatedAt
      previousAnswer
      newAnswer
      status
      hash
      explorerUrl
      admin {
        id
        firstName
        lastName
        email
      }
    }
    forwarder {
      id
      network
      address
    }
  }
}
    `;

/**
 * __useOracleDetailsQuery__
 *
 * To run a query within a React component, call `useOracleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOracleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOracleDetailsQuery({
 *   variables: {
 *      oracleId: // value for 'oracleId'
 *   },
 * });
 */
export function useOracleDetailsQuery(baseOptions: Apollo.QueryHookOptions<OracleDetailsQuery, OracleDetailsQueryVariables> & ({ variables: OracleDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OracleDetailsQuery, OracleDetailsQueryVariables>(OracleDetailsDocument, options);
      }
export function useOracleDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OracleDetailsQuery, OracleDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OracleDetailsQuery, OracleDetailsQueryVariables>(OracleDetailsDocument, options);
        }
export function useOracleDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OracleDetailsQuery, OracleDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OracleDetailsQuery, OracleDetailsQueryVariables>(OracleDetailsDocument, options);
        }
export type OracleDetailsQueryHookResult = ReturnType<typeof useOracleDetailsQuery>;
export type OracleDetailsLazyQueryHookResult = ReturnType<typeof useOracleDetailsLazyQuery>;
export type OracleDetailsSuspenseQueryHookResult = ReturnType<typeof useOracleDetailsSuspenseQuery>;
export type OracleDetailsQueryResult = Apollo.QueryResult<OracleDetailsQuery, OracleDetailsQueryVariables>;
export const TokenDeploymentOwnershipDocument = gql`
    query tokenDeploymentOwnership {
  tokens {
    id
    name
    deployments {
      id
      network
      ownerAddress
      pauserAddress
      burnerAddress
      minterAddress
    }
  }
}
    `;

/**
 * __useTokenDeploymentOwnershipQuery__
 *
 * To run a query within a React component, call `useTokenDeploymentOwnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDeploymentOwnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDeploymentOwnershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenDeploymentOwnershipQuery(baseOptions?: Apollo.QueryHookOptions<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>(TokenDeploymentOwnershipDocument, options);
      }
export function useTokenDeploymentOwnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>(TokenDeploymentOwnershipDocument, options);
        }
export function useTokenDeploymentOwnershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>(TokenDeploymentOwnershipDocument, options);
        }
export type TokenDeploymentOwnershipQueryHookResult = ReturnType<typeof useTokenDeploymentOwnershipQuery>;
export type TokenDeploymentOwnershipLazyQueryHookResult = ReturnType<typeof useTokenDeploymentOwnershipLazyQuery>;
export type TokenDeploymentOwnershipSuspenseQueryHookResult = ReturnType<typeof useTokenDeploymentOwnershipSuspenseQuery>;
export type TokenDeploymentOwnershipQueryResult = Apollo.QueryResult<TokenDeploymentOwnershipQuery, TokenDeploymentOwnershipQueryVariables>;
export const SupplyControlOperationDetailsDocument = gql`
    query supplyControlOperationDetails($id: String!) {
  supplyControlOperation(where: {id: $id}) {
    id
    type
    amount
    status
    hash
    possibleSigners
    recipientWallet {
      address
    }
    signatures {
      id
      address
      admin {
        id
        firstName
        lastName
        email
      }
    }
    deployment {
      id
      network
      address
      minterAddress
      burnerAddress
      systemWallet {
        address
      }
      token {
        name
        symbol
      }
    }
  }
}
    `;

/**
 * __useSupplyControlOperationDetailsQuery__
 *
 * To run a query within a React component, call `useSupplyControlOperationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyControlOperationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyControlOperationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplyControlOperationDetailsQuery(baseOptions: Apollo.QueryHookOptions<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables> & ({ variables: SupplyControlOperationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>(SupplyControlOperationDetailsDocument, options);
      }
export function useSupplyControlOperationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>(SupplyControlOperationDetailsDocument, options);
        }
export function useSupplyControlOperationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>(SupplyControlOperationDetailsDocument, options);
        }
export type SupplyControlOperationDetailsQueryHookResult = ReturnType<typeof useSupplyControlOperationDetailsQuery>;
export type SupplyControlOperationDetailsLazyQueryHookResult = ReturnType<typeof useSupplyControlOperationDetailsLazyQuery>;
export type SupplyControlOperationDetailsSuspenseQueryHookResult = ReturnType<typeof useSupplyControlOperationDetailsSuspenseQuery>;
export type SupplyControlOperationDetailsQueryResult = Apollo.QueryResult<SupplyControlOperationDetailsQuery, SupplyControlOperationDetailsQueryVariables>;
export const AllSupplyControlOperationsDocument = gql`
    query allSupplyControlOperations($page: Int, $perPage: Int) {
  supplyControlOperations(page: $page, pageSize: $perPage) {
    nodes {
      id
      type
      status
      amount
      deployment {
        network
        token {
          name
          symbol
        }
      }
    }
    page {
      totalNodes
    }
  }
}
    `;

/**
 * __useAllSupplyControlOperationsQuery__
 *
 * To run a query within a React component, call `useAllSupplyControlOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSupplyControlOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSupplyControlOperationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAllSupplyControlOperationsQuery(baseOptions?: Apollo.QueryHookOptions<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>(AllSupplyControlOperationsDocument, options);
      }
export function useAllSupplyControlOperationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>(AllSupplyControlOperationsDocument, options);
        }
export function useAllSupplyControlOperationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>(AllSupplyControlOperationsDocument, options);
        }
export type AllSupplyControlOperationsQueryHookResult = ReturnType<typeof useAllSupplyControlOperationsQuery>;
export type AllSupplyControlOperationsLazyQueryHookResult = ReturnType<typeof useAllSupplyControlOperationsLazyQuery>;
export type AllSupplyControlOperationsSuspenseQueryHookResult = ReturnType<typeof useAllSupplyControlOperationsSuspenseQuery>;
export type AllSupplyControlOperationsQueryResult = Apollo.QueryResult<AllSupplyControlOperationsQuery, AllSupplyControlOperationsQueryVariables>;
export const SupplyControlOperationSignaturePayloadDocument = gql`
    query supplyControlOperationSignaturePayload($operationId: String!) {
  supplyControlOperation(where: {id: $operationId}) {
    id
    eip712Domain
    possibleSigners
  }
}
    `;

/**
 * __useSupplyControlOperationSignaturePayloadQuery__
 *
 * To run a query within a React component, call `useSupplyControlOperationSignaturePayloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyControlOperationSignaturePayloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyControlOperationSignaturePayloadQuery({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useSupplyControlOperationSignaturePayloadQuery(baseOptions: Apollo.QueryHookOptions<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables> & ({ variables: SupplyControlOperationSignaturePayloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>(SupplyControlOperationSignaturePayloadDocument, options);
      }
export function useSupplyControlOperationSignaturePayloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>(SupplyControlOperationSignaturePayloadDocument, options);
        }
export function useSupplyControlOperationSignaturePayloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>(SupplyControlOperationSignaturePayloadDocument, options);
        }
export type SupplyControlOperationSignaturePayloadQueryHookResult = ReturnType<typeof useSupplyControlOperationSignaturePayloadQuery>;
export type SupplyControlOperationSignaturePayloadLazyQueryHookResult = ReturnType<typeof useSupplyControlOperationSignaturePayloadLazyQuery>;
export type SupplyControlOperationSignaturePayloadSuspenseQueryHookResult = ReturnType<typeof useSupplyControlOperationSignaturePayloadSuspenseQuery>;
export type SupplyControlOperationSignaturePayloadQueryResult = Apollo.QueryResult<SupplyControlOperationSignaturePayloadQuery, SupplyControlOperationSignaturePayloadQueryVariables>;
export const ProvideSupplyControlOperationSignatureDocument = gql`
    mutation ProvideSupplyControlOperationSignature($input: ProvideSupplyControlOperationSignatureInput!) {
  provideSupplyControlOperationSignature(input: $input)
}
    `;
export type ProvideSupplyControlOperationSignatureMutationFn = Apollo.MutationFunction<ProvideSupplyControlOperationSignatureMutation, ProvideSupplyControlOperationSignatureMutationVariables>;

/**
 * __useProvideSupplyControlOperationSignatureMutation__
 *
 * To run a mutation, you first call `useProvideSupplyControlOperationSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideSupplyControlOperationSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideSupplyControlOperationSignatureMutation, { data, loading, error }] = useProvideSupplyControlOperationSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvideSupplyControlOperationSignatureMutation(baseOptions?: Apollo.MutationHookOptions<ProvideSupplyControlOperationSignatureMutation, ProvideSupplyControlOperationSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvideSupplyControlOperationSignatureMutation, ProvideSupplyControlOperationSignatureMutationVariables>(ProvideSupplyControlOperationSignatureDocument, options);
      }
export type ProvideSupplyControlOperationSignatureMutationHookResult = ReturnType<typeof useProvideSupplyControlOperationSignatureMutation>;
export type ProvideSupplyControlOperationSignatureMutationResult = Apollo.MutationResult<ProvideSupplyControlOperationSignatureMutation>;
export type ProvideSupplyControlOperationSignatureMutationOptions = Apollo.BaseMutationOptions<ProvideSupplyControlOperationSignatureMutation, ProvideSupplyControlOperationSignatureMutationVariables>;
export const SupplyControlDialogDocument = gql`
    query supplyControlDialog {
  tokens(includeUnpublished: true) {
    id
    name
    symbol
  }
}
    `;

/**
 * __useSupplyControlDialogQuery__
 *
 * To run a query within a React component, call `useSupplyControlDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyControlDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyControlDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplyControlDialogQuery(baseOptions?: Apollo.QueryHookOptions<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>(SupplyControlDialogDocument, options);
      }
export function useSupplyControlDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>(SupplyControlDialogDocument, options);
        }
export function useSupplyControlDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>(SupplyControlDialogDocument, options);
        }
export type SupplyControlDialogQueryHookResult = ReturnType<typeof useSupplyControlDialogQuery>;
export type SupplyControlDialogLazyQueryHookResult = ReturnType<typeof useSupplyControlDialogLazyQuery>;
export type SupplyControlDialogSuspenseQueryHookResult = ReturnType<typeof useSupplyControlDialogSuspenseQuery>;
export type SupplyControlDialogQueryResult = Apollo.QueryResult<SupplyControlDialogQuery, SupplyControlDialogQueryVariables>;
export const SupplyControlTokenDeploymentDocument = gql`
    query supplyControlTokenDeployment($tokenId: ID!) {
  tokenDeployments(where: {tokenId: $tokenId}) {
    id
    network
  }
}
    `;

/**
 * __useSupplyControlTokenDeploymentQuery__
 *
 * To run a query within a React component, call `useSupplyControlTokenDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyControlTokenDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyControlTokenDeploymentQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useSupplyControlTokenDeploymentQuery(baseOptions: Apollo.QueryHookOptions<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables> & ({ variables: SupplyControlTokenDeploymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>(SupplyControlTokenDeploymentDocument, options);
      }
export function useSupplyControlTokenDeploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>(SupplyControlTokenDeploymentDocument, options);
        }
export function useSupplyControlTokenDeploymentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>(SupplyControlTokenDeploymentDocument, options);
        }
export type SupplyControlTokenDeploymentQueryHookResult = ReturnType<typeof useSupplyControlTokenDeploymentQuery>;
export type SupplyControlTokenDeploymentLazyQueryHookResult = ReturnType<typeof useSupplyControlTokenDeploymentLazyQuery>;
export type SupplyControlTokenDeploymentSuspenseQueryHookResult = ReturnType<typeof useSupplyControlTokenDeploymentSuspenseQuery>;
export type SupplyControlTokenDeploymentQueryResult = Apollo.QueryResult<SupplyControlTokenDeploymentQuery, SupplyControlTokenDeploymentQueryVariables>;
export const RequestSupplyControlOperationDocument = gql`
    mutation requestSupplyControlOperation($input: RequestSupplyControlOperationInput!) {
  requestSupplyControlOperation(input: $input) {
    id
  }
}
    `;
export type RequestSupplyControlOperationMutationFn = Apollo.MutationFunction<RequestSupplyControlOperationMutation, RequestSupplyControlOperationMutationVariables>;

/**
 * __useRequestSupplyControlOperationMutation__
 *
 * To run a mutation, you first call `useRequestSupplyControlOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSupplyControlOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSupplyControlOperationMutation, { data, loading, error }] = useRequestSupplyControlOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestSupplyControlOperationMutation(baseOptions?: Apollo.MutationHookOptions<RequestSupplyControlOperationMutation, RequestSupplyControlOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestSupplyControlOperationMutation, RequestSupplyControlOperationMutationVariables>(RequestSupplyControlOperationDocument, options);
      }
export type RequestSupplyControlOperationMutationHookResult = ReturnType<typeof useRequestSupplyControlOperationMutation>;
export type RequestSupplyControlOperationMutationResult = Apollo.MutationResult<RequestSupplyControlOperationMutation>;
export type RequestSupplyControlOperationMutationOptions = Apollo.BaseMutationOptions<RequestSupplyControlOperationMutation, RequestSupplyControlOperationMutationVariables>;
export const IncomingTransactionDetailsDocument = gql`
    query incomingTransactionDetails($id: String!) {
  incomingTransaction(transactionId: $id) {
    id
    hash
    index
    network
    type
    amount
    type
    status
    contractAddress
    benefactorAddress
    beneficiaryAddress
    problems
    problemsChecked
    score
    scoreDetails
    scoreProvider
    report {
      id
      reportId
      reportFiles
      reportPayload
    }
  }
}
    `;

/**
 * __useIncomingTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useIncomingTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingTransactionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncomingTransactionDetailsQuery(baseOptions: Apollo.QueryHookOptions<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables> & ({ variables: IncomingTransactionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>(IncomingTransactionDetailsDocument, options);
      }
export function useIncomingTransactionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>(IncomingTransactionDetailsDocument, options);
        }
export function useIncomingTransactionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>(IncomingTransactionDetailsDocument, options);
        }
export type IncomingTransactionDetailsQueryHookResult = ReturnType<typeof useIncomingTransactionDetailsQuery>;
export type IncomingTransactionDetailsLazyQueryHookResult = ReturnType<typeof useIncomingTransactionDetailsLazyQuery>;
export type IncomingTransactionDetailsSuspenseQueryHookResult = ReturnType<typeof useIncomingTransactionDetailsSuspenseQuery>;
export type IncomingTransactionDetailsQueryResult = Apollo.QueryResult<IncomingTransactionDetailsQuery, IncomingTransactionDetailsQueryVariables>;
export const IncomingTransactionsDocument = gql`
    query incomingTransactions($where: IncomingTransactionWhereInput, $page: Int, $pageSize: Int) {
  incomingTransactions(where: $where, page: $page, pageSize: $pageSize) {
    nodes {
      id
      createdAt
      hash
      explorerUrl
      index
      amount
      status
      type
      displayAmount
      tokenSymbol
      network
      score
      scoreProvider
      problems
      client {
        id
        name
      }
      tokenDeployment {
        id
        network
        token {
          name
          symbol
        }
      }
      refund {
        id
        status
      }
      interaction {
        id
        status
        failureReason
      }
    }
    page {
      currentPage
      totalNodes
      totalPages
    }
  }
}
    `;

/**
 * __useIncomingTransactionsQuery__
 *
 * To run a query within a React component, call `useIncomingTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useIncomingTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>(IncomingTransactionsDocument, options);
      }
export function useIncomingTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>(IncomingTransactionsDocument, options);
        }
export function useIncomingTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>(IncomingTransactionsDocument, options);
        }
export type IncomingTransactionsQueryHookResult = ReturnType<typeof useIncomingTransactionsQuery>;
export type IncomingTransactionsLazyQueryHookResult = ReturnType<typeof useIncomingTransactionsLazyQuery>;
export type IncomingTransactionsSuspenseQueryHookResult = ReturnType<typeof useIncomingTransactionsSuspenseQuery>;
export type IncomingTransactionsQueryResult = Apollo.QueryResult<IncomingTransactionsQuery, IncomingTransactionsQueryVariables>;
export const RetriggerIncomingTransactionConfirmationDocument = gql`
    mutation retriggerIncomingTransactionConfirmation($transactionId: String!) {
  retriggerIncomingTransactionConfirmation(input: {transactionId: $transactionId})
}
    `;
export type RetriggerIncomingTransactionConfirmationMutationFn = Apollo.MutationFunction<RetriggerIncomingTransactionConfirmationMutation, RetriggerIncomingTransactionConfirmationMutationVariables>;

/**
 * __useRetriggerIncomingTransactionConfirmationMutation__
 *
 * To run a mutation, you first call `useRetriggerIncomingTransactionConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetriggerIncomingTransactionConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retriggerIncomingTransactionConfirmationMutation, { data, loading, error }] = useRetriggerIncomingTransactionConfirmationMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useRetriggerIncomingTransactionConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<RetriggerIncomingTransactionConfirmationMutation, RetriggerIncomingTransactionConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetriggerIncomingTransactionConfirmationMutation, RetriggerIncomingTransactionConfirmationMutationVariables>(RetriggerIncomingTransactionConfirmationDocument, options);
      }
export type RetriggerIncomingTransactionConfirmationMutationHookResult = ReturnType<typeof useRetriggerIncomingTransactionConfirmationMutation>;
export type RetriggerIncomingTransactionConfirmationMutationResult = Apollo.MutationResult<RetriggerIncomingTransactionConfirmationMutation>;
export type RetriggerIncomingTransactionConfirmationMutationOptions = Apollo.BaseMutationOptions<RetriggerIncomingTransactionConfirmationMutation, RetriggerIncomingTransactionConfirmationMutationVariables>;
export const ApproveIncomingTransactionQueryDocument = gql`
    query approveIncomingTransactionQuery($transactionId: String!) {
  incomingTransaction(transactionId: $transactionId) {
    id
    network
    amount
    decimals
    tokenSymbol
    value
    valueCurrency
    client {
      name
      jurisdiction
    }
  }
}
    `;

/**
 * __useApproveIncomingTransactionQueryQuery__
 *
 * To run a query within a React component, call `useApproveIncomingTransactionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproveIncomingTransactionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproveIncomingTransactionQueryQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useApproveIncomingTransactionQueryQuery(baseOptions: Apollo.QueryHookOptions<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables> & ({ variables: ApproveIncomingTransactionQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>(ApproveIncomingTransactionQueryDocument, options);
      }
export function useApproveIncomingTransactionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>(ApproveIncomingTransactionQueryDocument, options);
        }
export function useApproveIncomingTransactionQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>(ApproveIncomingTransactionQueryDocument, options);
        }
export type ApproveIncomingTransactionQueryQueryHookResult = ReturnType<typeof useApproveIncomingTransactionQueryQuery>;
export type ApproveIncomingTransactionQueryLazyQueryHookResult = ReturnType<typeof useApproveIncomingTransactionQueryLazyQuery>;
export type ApproveIncomingTransactionQuerySuspenseQueryHookResult = ReturnType<typeof useApproveIncomingTransactionQuerySuspenseQuery>;
export type ApproveIncomingTransactionQueryQueryResult = Apollo.QueryResult<ApproveIncomingTransactionQueryQuery, ApproveIncomingTransactionQueryQueryVariables>;
export const ApproveIncomingTransactionDocument = gql`
    mutation approveIncomingTransaction($input: ApproveIncomingTransactionInput!) {
  approveIncomingTransaction(input: $input)
}
    `;
export type ApproveIncomingTransactionMutationFn = Apollo.MutationFunction<ApproveIncomingTransactionMutation, ApproveIncomingTransactionMutationVariables>;

/**
 * __useApproveIncomingTransactionMutation__
 *
 * To run a mutation, you first call `useApproveIncomingTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveIncomingTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveIncomingTransactionMutation, { data, loading, error }] = useApproveIncomingTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveIncomingTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveIncomingTransactionMutation, ApproveIncomingTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveIncomingTransactionMutation, ApproveIncomingTransactionMutationVariables>(ApproveIncomingTransactionDocument, options);
      }
export type ApproveIncomingTransactionMutationHookResult = ReturnType<typeof useApproveIncomingTransactionMutation>;
export type ApproveIncomingTransactionMutationResult = Apollo.MutationResult<ApproveIncomingTransactionMutation>;
export type ApproveIncomingTransactionMutationOptions = Apollo.BaseMutationOptions<ApproveIncomingTransactionMutation, ApproveIncomingTransactionMutationVariables>;
export const InternalTransactionsDocument = gql`
    query internalTransactions($page: Int, $pageSize: Int, $where: InternalTransactionsWhereInput) {
  internalTransactions(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      updatedAt
      action
      status
      gaslessStrategy
      network
      hash
      explorerUrl
    }
    page {
      currentPage
      totalNodes
      totalPages
    }
  }
}
    `;

/**
 * __useInternalTransactionsQuery__
 *
 * To run a query within a React component, call `useInternalTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInternalTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<InternalTransactionsQuery, InternalTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InternalTransactionsQuery, InternalTransactionsQueryVariables>(InternalTransactionsDocument, options);
      }
export function useInternalTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalTransactionsQuery, InternalTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InternalTransactionsQuery, InternalTransactionsQueryVariables>(InternalTransactionsDocument, options);
        }
export function useInternalTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InternalTransactionsQuery, InternalTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InternalTransactionsQuery, InternalTransactionsQueryVariables>(InternalTransactionsDocument, options);
        }
export type InternalTransactionsQueryHookResult = ReturnType<typeof useInternalTransactionsQuery>;
export type InternalTransactionsLazyQueryHookResult = ReturnType<typeof useInternalTransactionsLazyQuery>;
export type InternalTransactionsSuspenseQueryHookResult = ReturnType<typeof useInternalTransactionsSuspenseQuery>;
export type InternalTransactionsQueryResult = Apollo.QueryResult<InternalTransactionsQuery, InternalTransactionsQueryVariables>;
export const ValueVerificationOutgoingTransactionDocument = gql`
    query valueVerificationOutgoingTransaction($transactionId: String!) {
  outgoingTransaction(transactionId: $transactionId) {
    id
    network
    amount
    decimals
    tokenSymbol
    value
    valueCurrency
    isDepegged
  }
}
    `;

/**
 * __useValueVerificationOutgoingTransactionQuery__
 *
 * To run a query within a React component, call `useValueVerificationOutgoingTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useValueVerificationOutgoingTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValueVerificationOutgoingTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useValueVerificationOutgoingTransactionQuery(baseOptions: Apollo.QueryHookOptions<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables> & ({ variables: ValueVerificationOutgoingTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>(ValueVerificationOutgoingTransactionDocument, options);
      }
export function useValueVerificationOutgoingTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>(ValueVerificationOutgoingTransactionDocument, options);
        }
export function useValueVerificationOutgoingTransactionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>(ValueVerificationOutgoingTransactionDocument, options);
        }
export type ValueVerificationOutgoingTransactionQueryHookResult = ReturnType<typeof useValueVerificationOutgoingTransactionQuery>;
export type ValueVerificationOutgoingTransactionLazyQueryHookResult = ReturnType<typeof useValueVerificationOutgoingTransactionLazyQuery>;
export type ValueVerificationOutgoingTransactionSuspenseQueryHookResult = ReturnType<typeof useValueVerificationOutgoingTransactionSuspenseQuery>;
export type ValueVerificationOutgoingTransactionQueryResult = Apollo.QueryResult<ValueVerificationOutgoingTransactionQuery, ValueVerificationOutgoingTransactionQueryVariables>;
export const VerifyOutgoingTransactionValueDocument = gql`
    mutation verifyOutgoingTransactionValue($input: VerifyOutgoingTransactionValueInput!) {
  verifyOutgoingTransactionValue(input: $input)
}
    `;
export type VerifyOutgoingTransactionValueMutationFn = Apollo.MutationFunction<VerifyOutgoingTransactionValueMutation, VerifyOutgoingTransactionValueMutationVariables>;

/**
 * __useVerifyOutgoingTransactionValueMutation__
 *
 * To run a mutation, you first call `useVerifyOutgoingTransactionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOutgoingTransactionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOutgoingTransactionValueMutation, { data, loading, error }] = useVerifyOutgoingTransactionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOutgoingTransactionValueMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOutgoingTransactionValueMutation, VerifyOutgoingTransactionValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOutgoingTransactionValueMutation, VerifyOutgoingTransactionValueMutationVariables>(VerifyOutgoingTransactionValueDocument, options);
      }
export type VerifyOutgoingTransactionValueMutationHookResult = ReturnType<typeof useVerifyOutgoingTransactionValueMutation>;
export type VerifyOutgoingTransactionValueMutationResult = Apollo.MutationResult<VerifyOutgoingTransactionValueMutation>;
export type VerifyOutgoingTransactionValueMutationOptions = Apollo.BaseMutationOptions<VerifyOutgoingTransactionValueMutation, VerifyOutgoingTransactionValueMutationVariables>;
export const ValueVerificationIncomingTransactionDocument = gql`
    query valueVerificationIncomingTransaction($transactionId: String!) {
  incomingTransaction(transactionId: $transactionId) {
    id
    network
    amount
    decimals
    tokenSymbol
    value
    valueCurrency
    isDepegged
  }
}
    `;

/**
 * __useValueVerificationIncomingTransactionQuery__
 *
 * To run a query within a React component, call `useValueVerificationIncomingTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useValueVerificationIncomingTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValueVerificationIncomingTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useValueVerificationIncomingTransactionQuery(baseOptions: Apollo.QueryHookOptions<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables> & ({ variables: ValueVerificationIncomingTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>(ValueVerificationIncomingTransactionDocument, options);
      }
export function useValueVerificationIncomingTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>(ValueVerificationIncomingTransactionDocument, options);
        }
export function useValueVerificationIncomingTransactionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>(ValueVerificationIncomingTransactionDocument, options);
        }
export type ValueVerificationIncomingTransactionQueryHookResult = ReturnType<typeof useValueVerificationIncomingTransactionQuery>;
export type ValueVerificationIncomingTransactionLazyQueryHookResult = ReturnType<typeof useValueVerificationIncomingTransactionLazyQuery>;
export type ValueVerificationIncomingTransactionSuspenseQueryHookResult = ReturnType<typeof useValueVerificationIncomingTransactionSuspenseQuery>;
export type ValueVerificationIncomingTransactionQueryResult = Apollo.QueryResult<ValueVerificationIncomingTransactionQuery, ValueVerificationIncomingTransactionQueryVariables>;
export const VerifyIncomingTransactionValueDocument = gql`
    mutation verifyIncomingTransactionValue($input: VerifyIncomingTransactionValueInput!) {
  verifyIncomingTransactionValue(input: $input)
}
    `;
export type VerifyIncomingTransactionValueMutationFn = Apollo.MutationFunction<VerifyIncomingTransactionValueMutation, VerifyIncomingTransactionValueMutationVariables>;

/**
 * __useVerifyIncomingTransactionValueMutation__
 *
 * To run a mutation, you first call `useVerifyIncomingTransactionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyIncomingTransactionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyIncomingTransactionValueMutation, { data, loading, error }] = useVerifyIncomingTransactionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyIncomingTransactionValueMutation(baseOptions?: Apollo.MutationHookOptions<VerifyIncomingTransactionValueMutation, VerifyIncomingTransactionValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyIncomingTransactionValueMutation, VerifyIncomingTransactionValueMutationVariables>(VerifyIncomingTransactionValueDocument, options);
      }
export type VerifyIncomingTransactionValueMutationHookResult = ReturnType<typeof useVerifyIncomingTransactionValueMutation>;
export type VerifyIncomingTransactionValueMutationResult = Apollo.MutationResult<VerifyIncomingTransactionValueMutation>;
export type VerifyIncomingTransactionValueMutationOptions = Apollo.BaseMutationOptions<VerifyIncomingTransactionValueMutation, VerifyIncomingTransactionValueMutationVariables>;
export const ApproveOutgoingTransactionQueryDocument = gql`
    query approveOutgoingTransactionQuery($transactionId: String!) {
  outgoingTransaction(transactionId: $transactionId) {
    id
    network
    amount
    decimals
    tokenSymbol
    receiverAddress
    contractAddress
    interaction {
      type
    }
  }
}
    `;

/**
 * __useApproveOutgoingTransactionQueryQuery__
 *
 * To run a query within a React component, call `useApproveOutgoingTransactionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproveOutgoingTransactionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproveOutgoingTransactionQueryQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useApproveOutgoingTransactionQueryQuery(baseOptions: Apollo.QueryHookOptions<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables> & ({ variables: ApproveOutgoingTransactionQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>(ApproveOutgoingTransactionQueryDocument, options);
      }
export function useApproveOutgoingTransactionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>(ApproveOutgoingTransactionQueryDocument, options);
        }
export function useApproveOutgoingTransactionQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>(ApproveOutgoingTransactionQueryDocument, options);
        }
export type ApproveOutgoingTransactionQueryQueryHookResult = ReturnType<typeof useApproveOutgoingTransactionQueryQuery>;
export type ApproveOutgoingTransactionQueryLazyQueryHookResult = ReturnType<typeof useApproveOutgoingTransactionQueryLazyQuery>;
export type ApproveOutgoingTransactionQuerySuspenseQueryHookResult = ReturnType<typeof useApproveOutgoingTransactionQuerySuspenseQuery>;
export type ApproveOutgoingTransactionQueryQueryResult = Apollo.QueryResult<ApproveOutgoingTransactionQueryQuery, ApproveOutgoingTransactionQueryQueryVariables>;
export const CanSystemWalletSatisfyOutgoingTransactionExecutionDocument = gql`
    query CanSystemWalletSatisfyOutgoingTransactionExecution($input: CanSystemWalletSatisfyOutgoingTransactionExecutionInput!) {
  canSystemWalletSatisfyOutgoingTransactionExecution(input: $input) {
    gas
    tokens
  }
}
    `;

/**
 * __useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery__
 *
 * To run a query within a React component, call `useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery(baseOptions: Apollo.QueryHookOptions<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables> & ({ variables: CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>(CanSystemWalletSatisfyOutgoingTransactionExecutionDocument, options);
      }
export function useCanSystemWalletSatisfyOutgoingTransactionExecutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>(CanSystemWalletSatisfyOutgoingTransactionExecutionDocument, options);
        }
export function useCanSystemWalletSatisfyOutgoingTransactionExecutionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>(CanSystemWalletSatisfyOutgoingTransactionExecutionDocument, options);
        }
export type CanSystemWalletSatisfyOutgoingTransactionExecutionQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery>;
export type CanSystemWalletSatisfyOutgoingTransactionExecutionLazyQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOutgoingTransactionExecutionLazyQuery>;
export type CanSystemWalletSatisfyOutgoingTransactionExecutionSuspenseQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOutgoingTransactionExecutionSuspenseQuery>;
export type CanSystemWalletSatisfyOutgoingTransactionExecutionQueryResult = Apollo.QueryResult<CanSystemWalletSatisfyOutgoingTransactionExecutionQuery, CanSystemWalletSatisfyOutgoingTransactionExecutionQueryVariables>;
export const ApproveOutgoingTransactionDocument = gql`
    mutation approveOutgoingTransaction($input: ApproveOutgoingTransactionInput!) {
  approveOutgoingTransaction(input: $input)
}
    `;
export type ApproveOutgoingTransactionMutationFn = Apollo.MutationFunction<ApproveOutgoingTransactionMutation, ApproveOutgoingTransactionMutationVariables>;

/**
 * __useApproveOutgoingTransactionMutation__
 *
 * To run a mutation, you first call `useApproveOutgoingTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOutgoingTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOutgoingTransactionMutation, { data, loading, error }] = useApproveOutgoingTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveOutgoingTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveOutgoingTransactionMutation, ApproveOutgoingTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveOutgoingTransactionMutation, ApproveOutgoingTransactionMutationVariables>(ApproveOutgoingTransactionDocument, options);
      }
export type ApproveOutgoingTransactionMutationHookResult = ReturnType<typeof useApproveOutgoingTransactionMutation>;
export type ApproveOutgoingTransactionMutationResult = Apollo.MutationResult<ApproveOutgoingTransactionMutation>;
export type ApproveOutgoingTransactionMutationOptions = Apollo.BaseMutationOptions<ApproveOutgoingTransactionMutation, ApproveOutgoingTransactionMutationVariables>;
export const OutgoingTransactionsDocument = gql`
    query outgoingTransactions($page: Int, $pageSize: Int, $where: OutgoingTransactionsWhereInput) {
  outgoingTransactions(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      type
      hash
      amount
      displayAmount
      status
      network
      explorerUrl
      refundId
      stablecoinContractAddress
      client {
        id
        name
      }
      token {
        id
        network
        token {
          symbol
          name
        }
      }
    }
    page {
      currentPage
      totalPages
      totalNodes
    }
  }
}
    `;

/**
 * __useOutgoingTransactionsQuery__
 *
 * To run a query within a React component, call `useOutgoingTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOutgoingTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>(OutgoingTransactionsDocument, options);
      }
export function useOutgoingTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>(OutgoingTransactionsDocument, options);
        }
export function useOutgoingTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>(OutgoingTransactionsDocument, options);
        }
export type OutgoingTransactionsQueryHookResult = ReturnType<typeof useOutgoingTransactionsQuery>;
export type OutgoingTransactionsLazyQueryHookResult = ReturnType<typeof useOutgoingTransactionsLazyQuery>;
export type OutgoingTransactionsSuspenseQueryHookResult = ReturnType<typeof useOutgoingTransactionsSuspenseQuery>;
export type OutgoingTransactionsQueryResult = Apollo.QueryResult<OutgoingTransactionsQuery, OutgoingTransactionsQueryVariables>;
export const OrderDvpFormDocument = gql`
    query OrderDvpForm($orderId: ID!) {
  order(id: $orderId) {
    id
    settlement {
      id
      endOfDayFormDownloadUrl
    }
  }
}
    `;

/**
 * __useOrderDvpFormQuery__
 *
 * To run a query within a React component, call `useOrderDvpFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDvpFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDvpFormQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDvpFormQuery(baseOptions: Apollo.QueryHookOptions<OrderDvpFormQuery, OrderDvpFormQueryVariables> & ({ variables: OrderDvpFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDvpFormQuery, OrderDvpFormQueryVariables>(OrderDvpFormDocument, options);
      }
export function useOrderDvpFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDvpFormQuery, OrderDvpFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDvpFormQuery, OrderDvpFormQueryVariables>(OrderDvpFormDocument, options);
        }
export function useOrderDvpFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrderDvpFormQuery, OrderDvpFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderDvpFormQuery, OrderDvpFormQueryVariables>(OrderDvpFormDocument, options);
        }
export type OrderDvpFormQueryHookResult = ReturnType<typeof useOrderDvpFormQuery>;
export type OrderDvpFormLazyQueryHookResult = ReturnType<typeof useOrderDvpFormLazyQuery>;
export type OrderDvpFormSuspenseQueryHookResult = ReturnType<typeof useOrderDvpFormSuspenseQuery>;
export type OrderDvpFormQueryResult = Apollo.QueryResult<OrderDvpFormQuery, OrderDvpFormQueryVariables>;
export const OrderExecutionDetailsDocument = gql`
    query OrderExecutionDetails($orderId: ID!) {
  order(id: $orderId) {
    id
    side
    status
    totalValue
    totalShares
    sharesToTrade
    pricePerShare
    interaction {
      id
      incomingTransactions {
        amount
      }
    }
    collateral {
      id
      symbol
      price
      balance
      usedBalance
      pendingBalance
      priceCurrency
      tokens {
        pendingBalance
      }
    }
  }
}
    `;

/**
 * __useOrderExecutionDetailsQuery__
 *
 * To run a query within a React component, call `useOrderExecutionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderExecutionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderExecutionDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderExecutionDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables> & ({ variables: OrderExecutionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>(OrderExecutionDetailsDocument, options);
      }
export function useOrderExecutionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>(OrderExecutionDetailsDocument, options);
        }
export function useOrderExecutionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>(OrderExecutionDetailsDocument, options);
        }
export type OrderExecutionDetailsQueryHookResult = ReturnType<typeof useOrderExecutionDetailsQuery>;
export type OrderExecutionDetailsLazyQueryHookResult = ReturnType<typeof useOrderExecutionDetailsLazyQuery>;
export type OrderExecutionDetailsSuspenseQueryHookResult = ReturnType<typeof useOrderExecutionDetailsSuspenseQuery>;
export type OrderExecutionDetailsQueryResult = Apollo.QueryResult<OrderExecutionDetailsQuery, OrderExecutionDetailsQueryVariables>;
export const InteractionExpectedPayoutDocument = gql`
    query InteractionExpectedPayout($tradedShares: Int!, $pricePerShare: String!, $interactionId: String!, $collateralId: String!) {
  interactionExpectedPayout(
    tradedShares: $tradedShares
    pricePerShare: $pricePerShare
    interactionId: $interactionId
    collateralId: $collateralId
  ) {
    payoutAmount
    payoutSymbol
    payoutDecimals
    payoutType
    feeAmount
    feeSymbol
    collateralShortage
    overcollateral
    collateral {
      symbol
    }
  }
}
    `;

/**
 * __useInteractionExpectedPayoutQuery__
 *
 * To run a query within a React component, call `useInteractionExpectedPayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionExpectedPayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionExpectedPayoutQuery({
 *   variables: {
 *      tradedShares: // value for 'tradedShares'
 *      pricePerShare: // value for 'pricePerShare'
 *      interactionId: // value for 'interactionId'
 *      collateralId: // value for 'collateralId'
 *   },
 * });
 */
export function useInteractionExpectedPayoutQuery(baseOptions: Apollo.QueryHookOptions<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables> & ({ variables: InteractionExpectedPayoutQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>(InteractionExpectedPayoutDocument, options);
      }
export function useInteractionExpectedPayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>(InteractionExpectedPayoutDocument, options);
        }
export function useInteractionExpectedPayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>(InteractionExpectedPayoutDocument, options);
        }
export type InteractionExpectedPayoutQueryHookResult = ReturnType<typeof useInteractionExpectedPayoutQuery>;
export type InteractionExpectedPayoutLazyQueryHookResult = ReturnType<typeof useInteractionExpectedPayoutLazyQuery>;
export type InteractionExpectedPayoutSuspenseQueryHookResult = ReturnType<typeof useInteractionExpectedPayoutSuspenseQuery>;
export type InteractionExpectedPayoutQueryResult = Apollo.QueryResult<InteractionExpectedPayoutQuery, InteractionExpectedPayoutQueryVariables>;
export const CanSystemWalletSatisfyOrderExecutionDocument = gql`
    query CanSystemWalletSatisfyOrderExecution($input: CanSystemWalletSatisfyOrderExecutionInput!) {
  canSystemWalletSatisfyOrderExecution(input: $input) {
    gas
    tokens
  }
}
    `;

/**
 * __useCanSystemWalletSatisfyOrderExecutionQuery__
 *
 * To run a query within a React component, call `useCanSystemWalletSatisfyOrderExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanSystemWalletSatisfyOrderExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSystemWalletSatisfyOrderExecutionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCanSystemWalletSatisfyOrderExecutionQuery(baseOptions: Apollo.QueryHookOptions<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables> & ({ variables: CanSystemWalletSatisfyOrderExecutionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>(CanSystemWalletSatisfyOrderExecutionDocument, options);
      }
export function useCanSystemWalletSatisfyOrderExecutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>(CanSystemWalletSatisfyOrderExecutionDocument, options);
        }
export function useCanSystemWalletSatisfyOrderExecutionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>(CanSystemWalletSatisfyOrderExecutionDocument, options);
        }
export type CanSystemWalletSatisfyOrderExecutionQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOrderExecutionQuery>;
export type CanSystemWalletSatisfyOrderExecutionLazyQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOrderExecutionLazyQuery>;
export type CanSystemWalletSatisfyOrderExecutionSuspenseQueryHookResult = ReturnType<typeof useCanSystemWalletSatisfyOrderExecutionSuspenseQuery>;
export type CanSystemWalletSatisfyOrderExecutionQueryResult = Apollo.QueryResult<CanSystemWalletSatisfyOrderExecutionQuery, CanSystemWalletSatisfyOrderExecutionQueryVariables>;
export const BrokersDocument = gql`
    query Brokers {
  brokers {
    nodes {
      id
      name
      settlementStrategy
    }
  }
}
    `;

/**
 * __useBrokersQuery__
 *
 * To run a query within a React component, call `useBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokersQuery(baseOptions?: Apollo.QueryHookOptions<BrokersQuery, BrokersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokersQuery, BrokersQueryVariables>(BrokersDocument, options);
      }
export function useBrokersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokersQuery, BrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokersQuery, BrokersQueryVariables>(BrokersDocument, options);
        }
export function useBrokersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrokersQuery, BrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrokersQuery, BrokersQueryVariables>(BrokersDocument, options);
        }
export type BrokersQueryHookResult = ReturnType<typeof useBrokersQuery>;
export type BrokersLazyQueryHookResult = ReturnType<typeof useBrokersLazyQuery>;
export type BrokersSuspenseQueryHookResult = ReturnType<typeof useBrokersSuspenseQuery>;
export type BrokersQueryResult = Apollo.QueryResult<BrokersQuery, BrokersQueryVariables>;
export const SubmitOrderDocument = gql`
    mutation SubmitOrder($orderId: ID!, $sharesToTrade: Int!, $priceAtSubmission: String!) {
  submitOrder(
    input: {orderId: $orderId, sharesToTrade: $sharesToTrade, priceAtSubmission: $priceAtSubmission}
  ) {
    id
  }
}
    `;
export type SubmitOrderMutationFn = Apollo.MutationFunction<SubmitOrderMutation, SubmitOrderMutationVariables>;

/**
 * __useSubmitOrderMutation__
 *
 * To run a mutation, you first call `useSubmitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrderMutation, { data, loading, error }] = useSubmitOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      sharesToTrade: // value for 'sharesToTrade'
 *      priceAtSubmission: // value for 'priceAtSubmission'
 *   },
 * });
 */
export function useSubmitOrderMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOrderMutation, SubmitOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOrderMutation, SubmitOrderMutationVariables>(SubmitOrderDocument, options);
      }
export type SubmitOrderMutationHookResult = ReturnType<typeof useSubmitOrderMutation>;
export type SubmitOrderMutationResult = Apollo.MutationResult<SubmitOrderMutation>;
export type SubmitOrderMutationOptions = Apollo.BaseMutationOptions<SubmitOrderMutation, SubmitOrderMutationVariables>;
export const ExecuteOrderDocument = gql`
    mutation ExecuteOrder($input: ExecuteOrderInput!) {
  executeOrder(input: $input) {
    id
  }
}
    `;
export type ExecuteOrderMutationFn = Apollo.MutationFunction<ExecuteOrderMutation, ExecuteOrderMutationVariables>;

/**
 * __useExecuteOrderMutation__
 *
 * To run a mutation, you first call `useExecuteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeOrderMutation, { data, loading, error }] = useExecuteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteOrderMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteOrderMutation, ExecuteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteOrderMutation, ExecuteOrderMutationVariables>(ExecuteOrderDocument, options);
      }
export type ExecuteOrderMutationHookResult = ReturnType<typeof useExecuteOrderMutation>;
export type ExecuteOrderMutationResult = Apollo.MutationResult<ExecuteOrderMutation>;
export type ExecuteOrderMutationOptions = Apollo.BaseMutationOptions<ExecuteOrderMutation, ExecuteOrderMutationVariables>;
export const OrdersListDocument = gql`
    query OrdersList($page: Int, $pageSize: Int, $where: OrdersWhereInput) {
  orders(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      updatedAt
      side
      status
      totalValue
      totalShares
      pricePerShare
      interactionId
      executionPath
      collateral {
        symbol
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
    }
  }
}
    `;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions?: Apollo.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
      }
export function useOrdersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export function useOrdersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export type OrdersListSuspenseQueryHookResult = ReturnType<typeof useOrdersListSuspenseQuery>;
export type OrdersListQueryResult = Apollo.QueryResult<OrdersListQuery, OrdersListQueryVariables>;
export const OrderDetailsDocument = gql`
    query OrderDetails($orderId: ID!) {
  order(id: $orderId) {
    id
    createdAt
    updatedAt
    executedAt
    side
    status
    totalValue
    totalShares
    pricePerShare
    collateral {
      id
      symbol
    }
    events {
      id
      createdAt
      updatedAt
      type
    }
  }
}
    `;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables> & ({ variables: OrderDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
      }
export function useOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
        }
export function useOrderDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
        }
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsSuspenseQueryHookResult = ReturnType<typeof useOrderDetailsSuspenseQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const CollateralsDocument = gql`
    query collaterals {
  collaterals {
    id
    symbol
  }
}
    `;

/**
 * __useCollateralsQuery__
 *
 * To run a query within a React component, call `useCollateralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollateralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollateralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollateralsQuery(baseOptions?: Apollo.QueryHookOptions<CollateralsQuery, CollateralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollateralsQuery, CollateralsQueryVariables>(CollateralsDocument, options);
      }
export function useCollateralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollateralsQuery, CollateralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollateralsQuery, CollateralsQueryVariables>(CollateralsDocument, options);
        }
export function useCollateralsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CollateralsQuery, CollateralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CollateralsQuery, CollateralsQueryVariables>(CollateralsDocument, options);
        }
export type CollateralsQueryHookResult = ReturnType<typeof useCollateralsQuery>;
export type CollateralsLazyQueryHookResult = ReturnType<typeof useCollateralsLazyQuery>;
export type CollateralsSuspenseQueryHookResult = ReturnType<typeof useCollateralsSuspenseQuery>;
export type CollateralsQueryResult = Apollo.QueryResult<CollateralsQuery, CollateralsQueryVariables>;
export const CreateEodFormDocument = gql`
    mutation createEODForm($input: CreateEODFormInput!) {
  createEODForm(input: $input) {
    url
  }
}
    `;
export type CreateEodFormMutationFn = Apollo.MutationFunction<CreateEodFormMutation, CreateEodFormMutationVariables>;

/**
 * __useCreateEodFormMutation__
 *
 * To run a mutation, you first call `useCreateEodFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEodFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEodFormMutation, { data, loading, error }] = useCreateEodFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEodFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateEodFormMutation, CreateEodFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEodFormMutation, CreateEodFormMutationVariables>(CreateEodFormDocument, options);
      }
export type CreateEodFormMutationHookResult = ReturnType<typeof useCreateEodFormMutation>;
export type CreateEodFormMutationResult = Apollo.MutationResult<CreateEodFormMutation>;
export type CreateEodFormMutationOptions = Apollo.BaseMutationOptions<CreateEodFormMutation, CreateEodFormMutationVariables>;
export const PendingStatementsDocument = gql`
    query PendingStatements($where: CustodyAccountWhereUniqueInput!) {
  custodyAccount(where: $where) {
    events {
      tookPlaceAt
      changeAmount
      changeCurrency
      description
      id
      type
    }
  }
}
    `;

/**
 * __usePendingStatementsQuery__
 *
 * To run a query within a React component, call `usePendingStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingStatementsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePendingStatementsQuery(baseOptions: Apollo.QueryHookOptions<PendingStatementsQuery, PendingStatementsQueryVariables> & ({ variables: PendingStatementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingStatementsQuery, PendingStatementsQueryVariables>(PendingStatementsDocument, options);
      }
export function usePendingStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingStatementsQuery, PendingStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingStatementsQuery, PendingStatementsQueryVariables>(PendingStatementsDocument, options);
        }
export function usePendingStatementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PendingStatementsQuery, PendingStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingStatementsQuery, PendingStatementsQueryVariables>(PendingStatementsDocument, options);
        }
export type PendingStatementsQueryHookResult = ReturnType<typeof usePendingStatementsQuery>;
export type PendingStatementsLazyQueryHookResult = ReturnType<typeof usePendingStatementsLazyQuery>;
export type PendingStatementsSuspenseQueryHookResult = ReturnType<typeof usePendingStatementsSuspenseQuery>;
export type PendingStatementsQueryResult = Apollo.QueryResult<PendingStatementsQuery, PendingStatementsQueryVariables>;
export const MarkSettlementAsExecutedDocument = gql`
    mutation markSettlementAsExecuted($input: MarkSettlementAsExecutedInput!) {
  markSettlementAsExecuted(input: $input) {
    id
  }
}
    `;
export type MarkSettlementAsExecutedMutationFn = Apollo.MutationFunction<MarkSettlementAsExecutedMutation, MarkSettlementAsExecutedMutationVariables>;

/**
 * __useMarkSettlementAsExecutedMutation__
 *
 * To run a mutation, you first call `useMarkSettlementAsExecutedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSettlementAsExecutedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSettlementAsExecutedMutation, { data, loading, error }] = useMarkSettlementAsExecutedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkSettlementAsExecutedMutation(baseOptions?: Apollo.MutationHookOptions<MarkSettlementAsExecutedMutation, MarkSettlementAsExecutedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkSettlementAsExecutedMutation, MarkSettlementAsExecutedMutationVariables>(MarkSettlementAsExecutedDocument, options);
      }
export type MarkSettlementAsExecutedMutationHookResult = ReturnType<typeof useMarkSettlementAsExecutedMutation>;
export type MarkSettlementAsExecutedMutationResult = Apollo.MutationResult<MarkSettlementAsExecutedMutation>;
export type MarkSettlementAsExecutedMutationOptions = Apollo.BaseMutationOptions<MarkSettlementAsExecutedMutation, MarkSettlementAsExecutedMutationVariables>;
export const SettlementsListDocument = gql`
    query settlementsList($page: Int, $pageSize: Int) {
  settlements(page: $page, pageSize: $pageSize) {
    nodes {
      id
      status
      startedAt
      executedAt
      fiatCurrency
      finalFiatDiff
      endOfDayFormDownloadUrl
      collateral {
        id
        symbol
        custodyAccounts {
          type
          asset
          accountId
        }
      }
      orders {
        id
        side
        totalShares
        pricePerShare
        interaction {
          client {
            name
          }
        }
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
    }
  }
}
    `;

/**
 * __useSettlementsListQuery__
 *
 * To run a query within a React component, call `useSettlementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSettlementsListQuery(baseOptions?: Apollo.QueryHookOptions<SettlementsListQuery, SettlementsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettlementsListQuery, SettlementsListQueryVariables>(SettlementsListDocument, options);
      }
export function useSettlementsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsListQuery, SettlementsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettlementsListQuery, SettlementsListQueryVariables>(SettlementsListDocument, options);
        }
export function useSettlementsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SettlementsListQuery, SettlementsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettlementsListQuery, SettlementsListQueryVariables>(SettlementsListDocument, options);
        }
export type SettlementsListQueryHookResult = ReturnType<typeof useSettlementsListQuery>;
export type SettlementsListLazyQueryHookResult = ReturnType<typeof useSettlementsListLazyQuery>;
export type SettlementsListSuspenseQueryHookResult = ReturnType<typeof useSettlementsListSuspenseQuery>;
export type SettlementsListQueryResult = Apollo.QueryResult<SettlementsListQuery, SettlementsListQueryVariables>;
export const BasicClientDocument = gql`
    query BasicClient($id: ID!) {
  client(clientId: $id) {
    id
    name
    status
    frozenUntil
  }
}
    `;

/**
 * __useBasicClientQuery__
 *
 * To run a query within a React component, call `useBasicClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasicClientQuery(baseOptions: Apollo.QueryHookOptions<BasicClientQuery, BasicClientQueryVariables> & ({ variables: BasicClientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BasicClientQuery, BasicClientQueryVariables>(BasicClientDocument, options);
      }
export function useBasicClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasicClientQuery, BasicClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BasicClientQuery, BasicClientQueryVariables>(BasicClientDocument, options);
        }
export function useBasicClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BasicClientQuery, BasicClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BasicClientQuery, BasicClientQueryVariables>(BasicClientDocument, options);
        }
export type BasicClientQueryHookResult = ReturnType<typeof useBasicClientQuery>;
export type BasicClientLazyQueryHookResult = ReturnType<typeof useBasicClientLazyQuery>;
export type BasicClientSuspenseQueryHookResult = ReturnType<typeof useBasicClientSuspenseQuery>;
export type BasicClientQueryResult = Apollo.QueryResult<BasicClientQuery, BasicClientQueryVariables>;
export const ClientBankAccountsAndWalletsDocument = gql`
    query clientBankAccountsAndWallets($clientId: ID!, $withScore: Boolean!) {
  bankAccounts(where: {clientId: $clientId}) {
    id
    createdAt
    updatedAt
    iban
    verificationStatus
  }
  externalWallets(where: {clientId: $clientId}) {
    id
    createdAt
    updatedAt
    scores @include(if: $withScore) {
      id
      score
      provider
      network
      payload
    }
    address
    status
    reports {
      id
      createdAt
      updatedAt
      reportId
      reportPayload
      reportFiles
    }
  }
}
    `;

/**
 * __useClientBankAccountsAndWalletsQuery__
 *
 * To run a query within a React component, call `useClientBankAccountsAndWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientBankAccountsAndWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientBankAccountsAndWalletsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      withScore: // value for 'withScore'
 *   },
 * });
 */
export function useClientBankAccountsAndWalletsQuery(baseOptions: Apollo.QueryHookOptions<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables> & ({ variables: ClientBankAccountsAndWalletsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>(ClientBankAccountsAndWalletsDocument, options);
      }
export function useClientBankAccountsAndWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>(ClientBankAccountsAndWalletsDocument, options);
        }
export function useClientBankAccountsAndWalletsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>(ClientBankAccountsAndWalletsDocument, options);
        }
export type ClientBankAccountsAndWalletsQueryHookResult = ReturnType<typeof useClientBankAccountsAndWalletsQuery>;
export type ClientBankAccountsAndWalletsLazyQueryHookResult = ReturnType<typeof useClientBankAccountsAndWalletsLazyQuery>;
export type ClientBankAccountsAndWalletsSuspenseQueryHookResult = ReturnType<typeof useClientBankAccountsAndWalletsSuspenseQuery>;
export type ClientBankAccountsAndWalletsQueryResult = Apollo.QueryResult<ClientBankAccountsAndWalletsQuery, ClientBankAccountsAndWalletsQueryVariables>;
export const ClientFolderDocument = gql`
    query ClientFolder($id: ID!) {
  client(clientId: $id) {
    folder {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useClientFolderQuery__
 *
 * To run a query within a React component, call `useClientFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientFolderQuery(baseOptions: Apollo.QueryHookOptions<ClientFolderQuery, ClientFolderQueryVariables> & ({ variables: ClientFolderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFolderQuery, ClientFolderQueryVariables>(ClientFolderDocument, options);
      }
export function useClientFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFolderQuery, ClientFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFolderQuery, ClientFolderQueryVariables>(ClientFolderDocument, options);
        }
export function useClientFolderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFolderQuery, ClientFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFolderQuery, ClientFolderQueryVariables>(ClientFolderDocument, options);
        }
export type ClientFolderQueryHookResult = ReturnType<typeof useClientFolderQuery>;
export type ClientFolderLazyQueryHookResult = ReturnType<typeof useClientFolderLazyQuery>;
export type ClientFolderSuspenseQueryHookResult = ReturnType<typeof useClientFolderSuspenseQuery>;
export type ClientFolderQueryResult = Apollo.QueryResult<ClientFolderQuery, ClientFolderQueryVariables>;
export const ClientFeaturesDocument = gql`
    query clientFeatures($clientId: ID!) {
  client(clientId: $clientId) {
    id
    name
    features
    isProfessional
    verificationProfile {
      approved
    }
    securityLogs(where: {type: FeatureToggled}) {
      id
      createdAt
      payload
      message
      actorType
      actor {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useClientFeaturesQuery__
 *
 * To run a query within a React component, call `useClientFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFeaturesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientFeaturesQuery(baseOptions: Apollo.QueryHookOptions<ClientFeaturesQuery, ClientFeaturesQueryVariables> & ({ variables: ClientFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFeaturesQuery, ClientFeaturesQueryVariables>(ClientFeaturesDocument, options);
      }
export function useClientFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFeaturesQuery, ClientFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFeaturesQuery, ClientFeaturesQueryVariables>(ClientFeaturesDocument, options);
        }
export function useClientFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFeaturesQuery, ClientFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFeaturesQuery, ClientFeaturesQueryVariables>(ClientFeaturesDocument, options);
        }
export type ClientFeaturesQueryHookResult = ReturnType<typeof useClientFeaturesQuery>;
export type ClientFeaturesLazyQueryHookResult = ReturnType<typeof useClientFeaturesLazyQuery>;
export type ClientFeaturesSuspenseQueryHookResult = ReturnType<typeof useClientFeaturesSuspenseQuery>;
export type ClientFeaturesQueryResult = Apollo.QueryResult<ClientFeaturesQuery, ClientFeaturesQueryVariables>;
export const UpdateClientFeaturesDocument = gql`
    mutation updateClientFeatures($input: UpdateClientFeaturesInput!) {
  updateClientFeatures(input: $input) {
    id
  }
}
    `;
export type UpdateClientFeaturesMutationFn = Apollo.MutationFunction<UpdateClientFeaturesMutation, UpdateClientFeaturesMutationVariables>;

/**
 * __useUpdateClientFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateClientFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientFeaturesMutation, { data, loading, error }] = useUpdateClientFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientFeaturesMutation, UpdateClientFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientFeaturesMutation, UpdateClientFeaturesMutationVariables>(UpdateClientFeaturesDocument, options);
      }
export type UpdateClientFeaturesMutationHookResult = ReturnType<typeof useUpdateClientFeaturesMutation>;
export type UpdateClientFeaturesMutationResult = Apollo.MutationResult<UpdateClientFeaturesMutation>;
export type UpdateClientFeaturesMutationOptions = Apollo.BaseMutationOptions<UpdateClientFeaturesMutation, UpdateClientFeaturesMutationVariables>;
export const ClientFilesDocument = gql`
    query ClientFiles($clientId: ID!) {
  client(clientId: $clientId) {
    id
    files
  }
}
    `;

/**
 * __useClientFilesQuery__
 *
 * To run a query within a React component, call `useClientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFilesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientFilesQuery(baseOptions: Apollo.QueryHookOptions<ClientFilesQuery, ClientFilesQueryVariables> & ({ variables: ClientFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFilesQuery, ClientFilesQueryVariables>(ClientFilesDocument, options);
      }
export function useClientFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFilesQuery, ClientFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFilesQuery, ClientFilesQueryVariables>(ClientFilesDocument, options);
        }
export function useClientFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFilesQuery, ClientFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFilesQuery, ClientFilesQueryVariables>(ClientFilesDocument, options);
        }
export type ClientFilesQueryHookResult = ReturnType<typeof useClientFilesQuery>;
export type ClientFilesLazyQueryHookResult = ReturnType<typeof useClientFilesLazyQuery>;
export type ClientFilesSuspenseQueryHookResult = ReturnType<typeof useClientFilesSuspenseQuery>;
export type ClientFilesQueryResult = Apollo.QueryResult<ClientFilesQuery, ClientFilesQueryVariables>;
export const ClientFileDocument = gql`
    query ClientFile($path: String!, $clientId: String!) {
  file(path: $path, clientId: $clientId) {
    path
    authorisedUrl
  }
}
    `;

/**
 * __useClientFileQuery__
 *
 * To run a query within a React component, call `useClientFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFileQuery({
 *   variables: {
 *      path: // value for 'path'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientFileQuery(baseOptions: Apollo.QueryHookOptions<ClientFileQuery, ClientFileQueryVariables> & ({ variables: ClientFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFileQuery, ClientFileQueryVariables>(ClientFileDocument, options);
      }
export function useClientFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFileQuery, ClientFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFileQuery, ClientFileQueryVariables>(ClientFileDocument, options);
        }
export function useClientFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFileQuery, ClientFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFileQuery, ClientFileQueryVariables>(ClientFileDocument, options);
        }
export type ClientFileQueryHookResult = ReturnType<typeof useClientFileQuery>;
export type ClientFileLazyQueryHookResult = ReturnType<typeof useClientFileLazyQuery>;
export type ClientFileSuspenseQueryHookResult = ReturnType<typeof useClientFileSuspenseQuery>;
export type ClientFileQueryResult = Apollo.QueryResult<ClientFileQuery, ClientFileQueryVariables>;
export const ClientOverviewDocument = gql`
    query clientOverview($clientId: ID!) {
  client(clientId: $clientId) {
    id
    name
    type
    humanId
    jurisdiction
    createdAt
    updatedAt
    frozenUntil
    contactEmail
    addresses {
      isVerified
      firstLine
      secondLine
      city
      region
      postalCode
      country
    }
    verificationProfile {
      riskLevel
      currentTier
    }
  }
}
    `;

/**
 * __useClientOverviewQuery__
 *
 * To run a query within a React component, call `useClientOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientOverviewQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientOverviewQuery(baseOptions: Apollo.QueryHookOptions<ClientOverviewQuery, ClientOverviewQueryVariables> & ({ variables: ClientOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientOverviewQuery, ClientOverviewQueryVariables>(ClientOverviewDocument, options);
      }
export function useClientOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientOverviewQuery, ClientOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientOverviewQuery, ClientOverviewQueryVariables>(ClientOverviewDocument, options);
        }
export function useClientOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientOverviewQuery, ClientOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientOverviewQuery, ClientOverviewQueryVariables>(ClientOverviewDocument, options);
        }
export type ClientOverviewQueryHookResult = ReturnType<typeof useClientOverviewQuery>;
export type ClientOverviewLazyQueryHookResult = ReturnType<typeof useClientOverviewLazyQuery>;
export type ClientOverviewSuspenseQueryHookResult = ReturnType<typeof useClientOverviewSuspenseQuery>;
export type ClientOverviewQueryResult = Apollo.QueryResult<ClientOverviewQuery, ClientOverviewQueryVariables>;
export const ClientStatisticsDocument = gql`
    query clientStatistics($clientId: ID!) {
  client(clientId: $clientId) {
    statistics {
      currentValue
      maxValue
      lastOrder
      counter
      fees
      issuances {
        value
        counter
      }
      redemptions {
        value
        counter
      }
    }
  }
  tokens(includeUnpublished: true) {
    id
    name
    symbol
    icon
    statistics(clientId: $clientId) {
      currentValue
      maxValue
      lastOrder
      counter
      fees
      issuances {
        value
        counter
      }
      redemptions {
        value
        counter
      }
    }
  }
}
    `;

/**
 * __useClientStatisticsQuery__
 *
 * To run a query within a React component, call `useClientStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientStatisticsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ClientStatisticsQuery, ClientStatisticsQueryVariables> & ({ variables: ClientStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientStatisticsQuery, ClientStatisticsQueryVariables>(ClientStatisticsDocument, options);
      }
export function useClientStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientStatisticsQuery, ClientStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientStatisticsQuery, ClientStatisticsQueryVariables>(ClientStatisticsDocument, options);
        }
export function useClientStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientStatisticsQuery, ClientStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientStatisticsQuery, ClientStatisticsQueryVariables>(ClientStatisticsDocument, options);
        }
export type ClientStatisticsQueryHookResult = ReturnType<typeof useClientStatisticsQuery>;
export type ClientStatisticsLazyQueryHookResult = ReturnType<typeof useClientStatisticsLazyQuery>;
export type ClientStatisticsSuspenseQueryHookResult = ReturnType<typeof useClientStatisticsSuspenseQuery>;
export type ClientStatisticsQueryResult = Apollo.QueryResult<ClientStatisticsQuery, ClientStatisticsQueryVariables>;
export const SweepingWalletsDocument = gql`
    query sweepingWallets($page: Int, $pageSize: Int, $clientId: ID!) {
  sweepingWallets(page: $page, pageSize: $pageSize, where: {clientId: $clientId}) {
    nodes {
      id
      address
      externalServiceId
      externalService
      type
      token {
        symbol
        icon
      }
    }
    page {
      pageSize
      totalPages
      totalNodes
    }
  }
}
    `;

/**
 * __useSweepingWalletsQuery__
 *
 * To run a query within a React component, call `useSweepingWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepingWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepingWalletsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSweepingWalletsQuery(baseOptions: Apollo.QueryHookOptions<SweepingWalletsQuery, SweepingWalletsQueryVariables> & ({ variables: SweepingWalletsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SweepingWalletsQuery, SweepingWalletsQueryVariables>(SweepingWalletsDocument, options);
      }
export function useSweepingWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SweepingWalletsQuery, SweepingWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SweepingWalletsQuery, SweepingWalletsQueryVariables>(SweepingWalletsDocument, options);
        }
export function useSweepingWalletsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SweepingWalletsQuery, SweepingWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SweepingWalletsQuery, SweepingWalletsQueryVariables>(SweepingWalletsDocument, options);
        }
export type SweepingWalletsQueryHookResult = ReturnType<typeof useSweepingWalletsQuery>;
export type SweepingWalletsLazyQueryHookResult = ReturnType<typeof useSweepingWalletsLazyQuery>;
export type SweepingWalletsSuspenseQueryHookResult = ReturnType<typeof useSweepingWalletsSuspenseQuery>;
export type SweepingWalletsQueryResult = Apollo.QueryResult<SweepingWalletsQuery, SweepingWalletsQueryVariables>;
export const GetUsageAndLimitsOfClientDocument = gql`
    query GetUsageAndLimitsOfClient($clientId: ID!) {
  client(clientId: $clientId) {
    limits {
      id
      dailyTransactionLimit
      monthlyTransactionLimit
      tier
    }
    usage {
      usages {
        value
        usageOf
      }
    }
  }
}
    `;

/**
 * __useGetUsageAndLimitsOfClientQuery__
 *
 * To run a query within a React component, call `useGetUsageAndLimitsOfClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageAndLimitsOfClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageAndLimitsOfClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetUsageAndLimitsOfClientQuery(baseOptions: Apollo.QueryHookOptions<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables> & ({ variables: GetUsageAndLimitsOfClientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>(GetUsageAndLimitsOfClientDocument, options);
      }
export function useGetUsageAndLimitsOfClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>(GetUsageAndLimitsOfClientDocument, options);
        }
export function useGetUsageAndLimitsOfClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>(GetUsageAndLimitsOfClientDocument, options);
        }
export type GetUsageAndLimitsOfClientQueryHookResult = ReturnType<typeof useGetUsageAndLimitsOfClientQuery>;
export type GetUsageAndLimitsOfClientLazyQueryHookResult = ReturnType<typeof useGetUsageAndLimitsOfClientLazyQuery>;
export type GetUsageAndLimitsOfClientSuspenseQueryHookResult = ReturnType<typeof useGetUsageAndLimitsOfClientSuspenseQuery>;
export type GetUsageAndLimitsOfClientQueryResult = Apollo.QueryResult<GetUsageAndLimitsOfClientQuery, GetUsageAndLimitsOfClientQueryVariables>;
export const UpdateClientUsageLimitsTierDocument = gql`
    mutation UpdateClientUsageLimitsTier($input: UpdateClientUsageLimitsInput!) {
  updateClientUsageLimits(input: $input) {
    id
  }
}
    `;
export type UpdateClientUsageLimitsTierMutationFn = Apollo.MutationFunction<UpdateClientUsageLimitsTierMutation, UpdateClientUsageLimitsTierMutationVariables>;

/**
 * __useUpdateClientUsageLimitsTierMutation__
 *
 * To run a mutation, you first call `useUpdateClientUsageLimitsTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientUsageLimitsTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientUsageLimitsTierMutation, { data, loading, error }] = useUpdateClientUsageLimitsTierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientUsageLimitsTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientUsageLimitsTierMutation, UpdateClientUsageLimitsTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientUsageLimitsTierMutation, UpdateClientUsageLimitsTierMutationVariables>(UpdateClientUsageLimitsTierDocument, options);
      }
export type UpdateClientUsageLimitsTierMutationHookResult = ReturnType<typeof useUpdateClientUsageLimitsTierMutation>;
export type UpdateClientUsageLimitsTierMutationResult = Apollo.MutationResult<UpdateClientUsageLimitsTierMutation>;
export type UpdateClientUsageLimitsTierMutationOptions = Apollo.BaseMutationOptions<UpdateClientUsageLimitsTierMutation, UpdateClientUsageLimitsTierMutationVariables>;
export const ClientUsersDocument = gql`
    query clientUsers($clientId: ID!) {
  client(clientId: $clientId) {
    users {
      id
      firstName
      lastName
      email
      hasPasswordSet
    }
  }
}
    `;

/**
 * __useClientUsersQuery__
 *
 * To run a query within a React component, call `useClientUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUsersQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientUsersQuery(baseOptions: Apollo.QueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables> & ({ variables: ClientUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
      }
export function useClientUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
        }
export function useClientUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
        }
export type ClientUsersQueryHookResult = ReturnType<typeof useClientUsersQuery>;
export type ClientUsersLazyQueryHookResult = ReturnType<typeof useClientUsersLazyQuery>;
export type ClientUsersSuspenseQueryHookResult = ReturnType<typeof useClientUsersSuspenseQuery>;
export type ClientUsersQueryResult = Apollo.QueryResult<ClientUsersQuery, ClientUsersQueryVariables>;
export const RemoveUserPasswordDocument = gql`
    mutation removeUserPassword($input: RemoveUserPasswordInput!) {
  removeUserPassword(input: $input)
}
    `;
export type RemoveUserPasswordMutationFn = Apollo.MutationFunction<RemoveUserPasswordMutation, RemoveUserPasswordMutationVariables>;

/**
 * __useRemoveUserPasswordMutation__
 *
 * To run a mutation, you first call `useRemoveUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPasswordMutation, { data, loading, error }] = useRemoveUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserPasswordMutation, RemoveUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserPasswordMutation, RemoveUserPasswordMutationVariables>(RemoveUserPasswordDocument, options);
      }
export type RemoveUserPasswordMutationHookResult = ReturnType<typeof useRemoveUserPasswordMutation>;
export type RemoveUserPasswordMutationResult = Apollo.MutationResult<RemoveUserPasswordMutation>;
export type RemoveUserPasswordMutationOptions = Apollo.BaseMutationOptions<RemoveUserPasswordMutation, RemoveUserPasswordMutationVariables>;
export const ClientsDocument = gql`
    query clients($page: Int, $pageSize: Int, $where: ClientsFilter) {
  clients(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      name
      type
      isProfessional
      jurisdiction
      status
      frozenUntil
      features
      verificationProfile {
        riskLevel
        currentTier
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export function useClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsSuspenseQueryHookResult = ReturnType<typeof useClientsSuspenseQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const CloseClientDocument = gql`
    mutation closeClient($input: CloseClientInput!) {
  closeClient(input: $input) {
    id
  }
}
    `;
export type CloseClientMutationFn = Apollo.MutationFunction<CloseClientMutation, CloseClientMutationVariables>;

/**
 * __useCloseClientMutation__
 *
 * To run a mutation, you first call `useCloseClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeClientMutation, { data, loading, error }] = useCloseClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseClientMutation(baseOptions?: Apollo.MutationHookOptions<CloseClientMutation, CloseClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseClientMutation, CloseClientMutationVariables>(CloseClientDocument, options);
      }
export type CloseClientMutationHookResult = ReturnType<typeof useCloseClientMutation>;
export type CloseClientMutationResult = Apollo.MutationResult<CloseClientMutation>;
export type CloseClientMutationOptions = Apollo.BaseMutationOptions<CloseClientMutation, CloseClientMutationVariables>;
export const ClientClosureDocument = gql`
    query clientClosure($clientId: ID!) {
  client(clientId: $clientId) {
    closure {
      id
      createdAt
      reason
      admin {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useClientClosureQuery__
 *
 * To run a query within a React component, call `useClientClosureQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientClosureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientClosureQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientClosureQuery(baseOptions: Apollo.QueryHookOptions<ClientClosureQuery, ClientClosureQueryVariables> & ({ variables: ClientClosureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientClosureQuery, ClientClosureQueryVariables>(ClientClosureDocument, options);
      }
export function useClientClosureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientClosureQuery, ClientClosureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientClosureQuery, ClientClosureQueryVariables>(ClientClosureDocument, options);
        }
export function useClientClosureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientClosureQuery, ClientClosureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientClosureQuery, ClientClosureQueryVariables>(ClientClosureDocument, options);
        }
export type ClientClosureQueryHookResult = ReturnType<typeof useClientClosureQuery>;
export type ClientClosureLazyQueryHookResult = ReturnType<typeof useClientClosureLazyQuery>;
export type ClientClosureSuspenseQueryHookResult = ReturnType<typeof useClientClosureSuspenseQuery>;
export type ClientClosureQueryResult = Apollo.QueryResult<ClientClosureQuery, ClientClosureQueryVariables>;
export const CreateComplianceReviewDocument = gql`
    mutation CreateComplianceReview($input: CreateComplianceReviewInput!) {
  createComplianceReview(input: $input) {
    id
  }
}
    `;
export type CreateComplianceReviewMutationFn = Apollo.MutationFunction<CreateComplianceReviewMutation, CreateComplianceReviewMutationVariables>;

/**
 * __useCreateComplianceReviewMutation__
 *
 * To run a mutation, you first call `useCreateComplianceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComplianceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComplianceReviewMutation, { data, loading, error }] = useCreateComplianceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateComplianceReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateComplianceReviewMutation, CreateComplianceReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComplianceReviewMutation, CreateComplianceReviewMutationVariables>(CreateComplianceReviewDocument, options);
      }
export type CreateComplianceReviewMutationHookResult = ReturnType<typeof useCreateComplianceReviewMutation>;
export type CreateComplianceReviewMutationResult = Apollo.MutationResult<CreateComplianceReviewMutation>;
export type CreateComplianceReviewMutationOptions = Apollo.BaseMutationOptions<CreateComplianceReviewMutation, CreateComplianceReviewMutationVariables>;
export const ArchiveVerificationDocument = gql`
    mutation archiveVerification($input: ArchiveVerificationInput!) {
  archiveVerification(input: $input)
}
    `;
export type ArchiveVerificationMutationFn = Apollo.MutationFunction<ArchiveVerificationMutation, ArchiveVerificationMutationVariables>;

/**
 * __useArchiveVerificationMutation__
 *
 * To run a mutation, you first call `useArchiveVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveVerificationMutation, { data, loading, error }] = useArchiveVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveVerificationMutation, ArchiveVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveVerificationMutation, ArchiveVerificationMutationVariables>(ArchiveVerificationDocument, options);
      }
export type ArchiveVerificationMutationHookResult = ReturnType<typeof useArchiveVerificationMutation>;
export type ArchiveVerificationMutationResult = Apollo.MutationResult<ArchiveVerificationMutation>;
export type ArchiveVerificationMutationOptions = Apollo.BaseMutationOptions<ArchiveVerificationMutation, ArchiveVerificationMutationVariables>;
export const ResetQuestionnaireDocument = gql`
    mutation resetQuestionnaire($input: ResetClientQuestionnaireInput!) {
  resetClientQuestionnaire(input: $input)
}
    `;
export type ResetQuestionnaireMutationFn = Apollo.MutationFunction<ResetQuestionnaireMutation, ResetQuestionnaireMutationVariables>;

/**
 * __useResetQuestionnaireMutation__
 *
 * To run a mutation, you first call `useResetQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetQuestionnaireMutation, { data, loading, error }] = useResetQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<ResetQuestionnaireMutation, ResetQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetQuestionnaireMutation, ResetQuestionnaireMutationVariables>(ResetQuestionnaireDocument, options);
      }
export type ResetQuestionnaireMutationHookResult = ReturnType<typeof useResetQuestionnaireMutation>;
export type ResetQuestionnaireMutationResult = Apollo.MutationResult<ResetQuestionnaireMutation>;
export type ResetQuestionnaireMutationOptions = Apollo.BaseMutationOptions<ResetQuestionnaireMutation, ResetQuestionnaireMutationVariables>;
export const CreateVerificationTokenDocument = gql`
    mutation createVerificationToken($input: CreateVerificationTokenInput!) {
  createVerificationToken(input: $input) {
    token
    expirationDate
  }
}
    `;
export type CreateVerificationTokenMutationFn = Apollo.MutationFunction<CreateVerificationTokenMutation, CreateVerificationTokenMutationVariables>;

/**
 * __useCreateVerificationTokenMutation__
 *
 * To run a mutation, you first call `useCreateVerificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVerificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVerificationTokenMutation, { data, loading, error }] = useCreateVerificationTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVerificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateVerificationTokenMutation, CreateVerificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVerificationTokenMutation, CreateVerificationTokenMutationVariables>(CreateVerificationTokenDocument, options);
      }
export type CreateVerificationTokenMutationHookResult = ReturnType<typeof useCreateVerificationTokenMutation>;
export type CreateVerificationTokenMutationResult = Apollo.MutationResult<CreateVerificationTokenMutation>;
export type CreateVerificationTokenMutationOptions = Apollo.BaseMutationOptions<CreateVerificationTokenMutation, CreateVerificationTokenMutationVariables>;
export const ClientComplianceDocument = gql`
    query clientCompliance($clientId: ID!) {
  client(clientId: $clientId) {
    id
    name
    status
    isProfessional
    questionnaires {
      id
      type
      status
      payload
    }
    verificationProfile {
      id
      currentTier
      riskLevel
      riskLevelFormatted
      verifications {
        id
        createdAt
        updatedAt
        status
        tier
        isArchived
        vendor
        vendorApplicantId
      }
    }
    riskAssessments {
      id
      createdAt
      updatedAt
      concludedRisk
      concludedRiskPoints
      concludedRiskFormatted
      status
      clientType
      filedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useClientComplianceQuery__
 *
 * To run a query within a React component, call `useClientComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientComplianceQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientComplianceQuery(baseOptions: Apollo.QueryHookOptions<ClientComplianceQuery, ClientComplianceQueryVariables> & ({ variables: ClientComplianceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientComplianceQuery, ClientComplianceQueryVariables>(ClientComplianceDocument, options);
      }
export function useClientComplianceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientComplianceQuery, ClientComplianceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientComplianceQuery, ClientComplianceQueryVariables>(ClientComplianceDocument, options);
        }
export function useClientComplianceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientComplianceQuery, ClientComplianceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientComplianceQuery, ClientComplianceQueryVariables>(ClientComplianceDocument, options);
        }
export type ClientComplianceQueryHookResult = ReturnType<typeof useClientComplianceQuery>;
export type ClientComplianceLazyQueryHookResult = ReturnType<typeof useClientComplianceLazyQuery>;
export type ClientComplianceSuspenseQueryHookResult = ReturnType<typeof useClientComplianceSuspenseQuery>;
export type ClientComplianceQueryResult = Apollo.QueryResult<ClientComplianceQuery, ClientComplianceQueryVariables>;
export const ClientComplianceReviewQueryDocument = gql`
    query clientComplianceReviewQuery($clientId: ID!) {
  client(clientId: $clientId) {
    complianceReview {
      id
      createdAt
      updatedAt
      status
      notes
      isProfessional
      complianceOfficer {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useClientComplianceReviewQueryQuery__
 *
 * To run a query within a React component, call `useClientComplianceReviewQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientComplianceReviewQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientComplianceReviewQueryQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientComplianceReviewQueryQuery(baseOptions: Apollo.QueryHookOptions<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables> & ({ variables: ClientComplianceReviewQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>(ClientComplianceReviewQueryDocument, options);
      }
export function useClientComplianceReviewQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>(ClientComplianceReviewQueryDocument, options);
        }
export function useClientComplianceReviewQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>(ClientComplianceReviewQueryDocument, options);
        }
export type ClientComplianceReviewQueryQueryHookResult = ReturnType<typeof useClientComplianceReviewQueryQuery>;
export type ClientComplianceReviewQueryLazyQueryHookResult = ReturnType<typeof useClientComplianceReviewQueryLazyQuery>;
export type ClientComplianceReviewQuerySuspenseQueryHookResult = ReturnType<typeof useClientComplianceReviewQuerySuspenseQuery>;
export type ClientComplianceReviewQueryQueryResult = Apollo.QueryResult<ClientComplianceReviewQueryQuery, ClientComplianceReviewQueryQueryVariables>;
export const CreateClientAssessmentDocument = gql`
    mutation createClientAssessment($input: CreateRiskAssessmentInput!) {
  createRiskAssessment(input: $input) {
    id
  }
}
    `;
export type CreateClientAssessmentMutationFn = Apollo.MutationFunction<CreateClientAssessmentMutation, CreateClientAssessmentMutationVariables>;

/**
 * __useCreateClientAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateClientAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientAssessmentMutation, { data, loading, error }] = useCreateClientAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientAssessmentMutation, CreateClientAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientAssessmentMutation, CreateClientAssessmentMutationVariables>(CreateClientAssessmentDocument, options);
      }
export type CreateClientAssessmentMutationHookResult = ReturnType<typeof useCreateClientAssessmentMutation>;
export type CreateClientAssessmentMutationResult = Apollo.MutationResult<CreateClientAssessmentMutation>;
export type CreateClientAssessmentMutationOptions = Apollo.BaseMutationOptions<CreateClientAssessmentMutation, CreateClientAssessmentMutationVariables>;
export const InjectVerificationDocument = gql`
    mutation injectVerification($input: InjectVerificationInput!) {
  injectVerification(input: $input) {
    id
  }
}
    `;
export type InjectVerificationMutationFn = Apollo.MutationFunction<InjectVerificationMutation, InjectVerificationMutationVariables>;

/**
 * __useInjectVerificationMutation__
 *
 * To run a mutation, you first call `useInjectVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInjectVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [injectVerificationMutation, { data, loading, error }] = useInjectVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInjectVerificationMutation(baseOptions?: Apollo.MutationHookOptions<InjectVerificationMutation, InjectVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InjectVerificationMutation, InjectVerificationMutationVariables>(InjectVerificationDocument, options);
      }
export type InjectVerificationMutationHookResult = ReturnType<typeof useInjectVerificationMutation>;
export type InjectVerificationMutationResult = Apollo.MutationResult<InjectVerificationMutation>;
export type InjectVerificationMutationOptions = Apollo.BaseMutationOptions<InjectVerificationMutation, InjectVerificationMutationVariables>;
export const ManuallyCreateClientDocument = gql`
    mutation manuallyCreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    result
    client {
      id
    }
  }
}
    `;
export type ManuallyCreateClientMutationFn = Apollo.MutationFunction<ManuallyCreateClientMutation, ManuallyCreateClientMutationVariables>;

/**
 * __useManuallyCreateClientMutation__
 *
 * To run a mutation, you first call `useManuallyCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallyCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallyCreateClientMutation, { data, loading, error }] = useManuallyCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManuallyCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<ManuallyCreateClientMutation, ManuallyCreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManuallyCreateClientMutation, ManuallyCreateClientMutationVariables>(ManuallyCreateClientDocument, options);
      }
export type ManuallyCreateClientMutationHookResult = ReturnType<typeof useManuallyCreateClientMutation>;
export type ManuallyCreateClientMutationResult = Apollo.MutationResult<ManuallyCreateClientMutation>;
export type ManuallyCreateClientMutationOptions = Apollo.BaseMutationOptions<ManuallyCreateClientMutation, ManuallyCreateClientMutationVariables>;
export const ClientFeeOverridesDocument = gql`
    query clientFeeOverrides($clientId: String!) {
  feeConfigurations(where: {clientId: $clientId}) {
    nodes {
      id
      issuanceInitialFee
      issuanceMinimumFee
      issuancePercentageFee
      redemptionInitialFee
      redemptionMinimumFee
      redemptionPercentageFee
      tokenDeployment {
        id
        network
        token {
          name
          symbol
        }
      }
    }
  }
}
    `;

/**
 * __useClientFeeOverridesQuery__
 *
 * To run a query within a React component, call `useClientFeeOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFeeOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFeeOverridesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientFeeOverridesQuery(baseOptions: Apollo.QueryHookOptions<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables> & ({ variables: ClientFeeOverridesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>(ClientFeeOverridesDocument, options);
      }
export function useClientFeeOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>(ClientFeeOverridesDocument, options);
        }
export function useClientFeeOverridesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>(ClientFeeOverridesDocument, options);
        }
export type ClientFeeOverridesQueryHookResult = ReturnType<typeof useClientFeeOverridesQuery>;
export type ClientFeeOverridesLazyQueryHookResult = ReturnType<typeof useClientFeeOverridesLazyQuery>;
export type ClientFeeOverridesSuspenseQueryHookResult = ReturnType<typeof useClientFeeOverridesSuspenseQuery>;
export type ClientFeeOverridesQueryResult = Apollo.QueryResult<ClientFeeOverridesQuery, ClientFeeOverridesQueryVariables>;
export const DeleteClientFeeOverrideDocument = gql`
    mutation deleteClientFeeOverride($input: DeleteFeeConfigurationInput!) {
  deleteFeeConfiguration(input: $input)
}
    `;
export type DeleteClientFeeOverrideMutationFn = Apollo.MutationFunction<DeleteClientFeeOverrideMutation, DeleteClientFeeOverrideMutationVariables>;

/**
 * __useDeleteClientFeeOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteClientFeeOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientFeeOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientFeeOverrideMutation, { data, loading, error }] = useDeleteClientFeeOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientFeeOverrideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientFeeOverrideMutation, DeleteClientFeeOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientFeeOverrideMutation, DeleteClientFeeOverrideMutationVariables>(DeleteClientFeeOverrideDocument, options);
      }
export type DeleteClientFeeOverrideMutationHookResult = ReturnType<typeof useDeleteClientFeeOverrideMutation>;
export type DeleteClientFeeOverrideMutationResult = Apollo.MutationResult<DeleteClientFeeOverrideMutation>;
export type DeleteClientFeeOverrideMutationOptions = Apollo.BaseMutationOptions<DeleteClientFeeOverrideMutation, DeleteClientFeeOverrideMutationVariables>;
export const ClientFeesConfigurationDialogDataDocument = gql`
    query clientFeesConfigurationDialogData {
  tokenDeployments {
    id
    network
    token {
      id
      name
    }
  }
}
    `;

/**
 * __useClientFeesConfigurationDialogDataQuery__
 *
 * To run a query within a React component, call `useClientFeesConfigurationDialogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFeesConfigurationDialogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFeesConfigurationDialogDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientFeesConfigurationDialogDataQuery(baseOptions?: Apollo.QueryHookOptions<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>(ClientFeesConfigurationDialogDataDocument, options);
      }
export function useClientFeesConfigurationDialogDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>(ClientFeesConfigurationDialogDataDocument, options);
        }
export function useClientFeesConfigurationDialogDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>(ClientFeesConfigurationDialogDataDocument, options);
        }
export type ClientFeesConfigurationDialogDataQueryHookResult = ReturnType<typeof useClientFeesConfigurationDialogDataQuery>;
export type ClientFeesConfigurationDialogDataLazyQueryHookResult = ReturnType<typeof useClientFeesConfigurationDialogDataLazyQuery>;
export type ClientFeesConfigurationDialogDataSuspenseQueryHookResult = ReturnType<typeof useClientFeesConfigurationDialogDataSuspenseQuery>;
export type ClientFeesConfigurationDialogDataQueryResult = Apollo.QueryResult<ClientFeesConfigurationDialogDataQuery, ClientFeesConfigurationDialogDataQueryVariables>;
export const CurrentClientFeesConfigurationDocument = gql`
    query currentClientFeesConfiguration($clientId: String!, $tokenDeploymentId: String!) {
  feeConfiguration(clientId: $clientId, tokenDeploymentId: $tokenDeploymentId) {
    id
    issuanceInitialFee
    issuanceMinimumFee
    issuancePercentageFee
    redemptionInitialFee
    redemptionMinimumFee
    redemptionPercentageFee
  }
}
    `;

/**
 * __useCurrentClientFeesConfigurationQuery__
 *
 * To run a query within a React component, call `useCurrentClientFeesConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentClientFeesConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentClientFeesConfigurationQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      tokenDeploymentId: // value for 'tokenDeploymentId'
 *   },
 * });
 */
export function useCurrentClientFeesConfigurationQuery(baseOptions: Apollo.QueryHookOptions<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables> & ({ variables: CurrentClientFeesConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>(CurrentClientFeesConfigurationDocument, options);
      }
export function useCurrentClientFeesConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>(CurrentClientFeesConfigurationDocument, options);
        }
export function useCurrentClientFeesConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>(CurrentClientFeesConfigurationDocument, options);
        }
export type CurrentClientFeesConfigurationQueryHookResult = ReturnType<typeof useCurrentClientFeesConfigurationQuery>;
export type CurrentClientFeesConfigurationLazyQueryHookResult = ReturnType<typeof useCurrentClientFeesConfigurationLazyQuery>;
export type CurrentClientFeesConfigurationSuspenseQueryHookResult = ReturnType<typeof useCurrentClientFeesConfigurationSuspenseQuery>;
export type CurrentClientFeesConfigurationQueryResult = Apollo.QueryResult<CurrentClientFeesConfigurationQuery, CurrentClientFeesConfigurationQueryVariables>;
export const UpsertClientFeesConfigDocument = gql`
    mutation upsertClientFeesConfig($input: UpsertFeeConfigurationInput!) {
  upsertFeeConfiguration(input: $input) {
    id
  }
}
    `;
export type UpsertClientFeesConfigMutationFn = Apollo.MutationFunction<UpsertClientFeesConfigMutation, UpsertClientFeesConfigMutationVariables>;

/**
 * __useUpsertClientFeesConfigMutation__
 *
 * To run a mutation, you first call `useUpsertClientFeesConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClientFeesConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClientFeesConfigMutation, { data, loading, error }] = useUpsertClientFeesConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertClientFeesConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpsertClientFeesConfigMutation, UpsertClientFeesConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertClientFeesConfigMutation, UpsertClientFeesConfigMutationVariables>(UpsertClientFeesConfigDocument, options);
      }
export type UpsertClientFeesConfigMutationHookResult = ReturnType<typeof useUpsertClientFeesConfigMutation>;
export type UpsertClientFeesConfigMutationResult = Apollo.MutationResult<UpsertClientFeesConfigMutation>;
export type UpsertClientFeesConfigMutationOptions = Apollo.BaseMutationOptions<UpsertClientFeesConfigMutation, UpsertClientFeesConfigMutationVariables>;
export const ActivateExternalWalletManuallyDocument = gql`
    mutation activateExternalWalletManually($input: ActivateExternalWalletManuallyInput!) {
  activateExternalWalletManually(input: $input) {
    id
  }
}
    `;
export type ActivateExternalWalletManuallyMutationFn = Apollo.MutationFunction<ActivateExternalWalletManuallyMutation, ActivateExternalWalletManuallyMutationVariables>;

/**
 * __useActivateExternalWalletManuallyMutation__
 *
 * To run a mutation, you first call `useActivateExternalWalletManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateExternalWalletManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateExternalWalletManuallyMutation, { data, loading, error }] = useActivateExternalWalletManuallyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateExternalWalletManuallyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateExternalWalletManuallyMutation, ActivateExternalWalletManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateExternalWalletManuallyMutation, ActivateExternalWalletManuallyMutationVariables>(ActivateExternalWalletManuallyDocument, options);
      }
export type ActivateExternalWalletManuallyMutationHookResult = ReturnType<typeof useActivateExternalWalletManuallyMutation>;
export type ActivateExternalWalletManuallyMutationResult = Apollo.MutationResult<ActivateExternalWalletManuallyMutation>;
export type ActivateExternalWalletManuallyMutationOptions = Apollo.BaseMutationOptions<ActivateExternalWalletManuallyMutation, ActivateExternalWalletManuallyMutationVariables>;
export const AddClientUserDocument = gql`
    mutation addClientUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type AddClientUserMutationFn = Apollo.MutationFunction<AddClientUserMutation, AddClientUserMutationVariables>;

/**
 * __useAddClientUserMutation__
 *
 * To run a mutation, you first call `useAddClientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientUserMutation, { data, loading, error }] = useAddClientUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClientUserMutation(baseOptions?: Apollo.MutationHookOptions<AddClientUserMutation, AddClientUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientUserMutation, AddClientUserMutationVariables>(AddClientUserDocument, options);
      }
export type AddClientUserMutationHookResult = ReturnType<typeof useAddClientUserMutation>;
export type AddClientUserMutationResult = Apollo.MutationResult<AddClientUserMutation>;
export type AddClientUserMutationOptions = Apollo.BaseMutationOptions<AddClientUserMutation, AddClientUserMutationVariables>;
export const CreateExternalWalletManuallyDocument = gql`
    mutation createExternalWalletManually($input: CreateExternalWalletManuallyInput!) {
  createExternalWalletManually(input: $input) {
    id
  }
}
    `;
export type CreateExternalWalletManuallyMutationFn = Apollo.MutationFunction<CreateExternalWalletManuallyMutation, CreateExternalWalletManuallyMutationVariables>;

/**
 * __useCreateExternalWalletManuallyMutation__
 *
 * To run a mutation, you first call `useCreateExternalWalletManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWalletManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWalletManuallyMutation, { data, loading, error }] = useCreateExternalWalletManuallyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWalletManuallyMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalWalletManuallyMutation, CreateExternalWalletManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalWalletManuallyMutation, CreateExternalWalletManuallyMutationVariables>(CreateExternalWalletManuallyDocument, options);
      }
export type CreateExternalWalletManuallyMutationHookResult = ReturnType<typeof useCreateExternalWalletManuallyMutation>;
export type CreateExternalWalletManuallyMutationResult = Apollo.MutationResult<CreateExternalWalletManuallyMutation>;
export type CreateExternalWalletManuallyMutationOptions = Apollo.BaseMutationOptions<CreateExternalWalletManuallyMutation, CreateExternalWalletManuallyMutationVariables>;
export const UnfreezeClientDocument = gql`
    mutation UnfreezeClient($input: UnfreezeClientInput!) {
  unfreezeClient(input: $input) {
    id
  }
}
    `;
export type UnfreezeClientMutationFn = Apollo.MutationFunction<UnfreezeClientMutation, UnfreezeClientMutationVariables>;

/**
 * __useUnfreezeClientMutation__
 *
 * To run a mutation, you first call `useUnfreezeClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfreezeClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfreezeClientMutation, { data, loading, error }] = useUnfreezeClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfreezeClientMutation(baseOptions?: Apollo.MutationHookOptions<UnfreezeClientMutation, UnfreezeClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfreezeClientMutation, UnfreezeClientMutationVariables>(UnfreezeClientDocument, options);
      }
export type UnfreezeClientMutationHookResult = ReturnType<typeof useUnfreezeClientMutation>;
export type UnfreezeClientMutationResult = Apollo.MutationResult<UnfreezeClientMutation>;
export type UnfreezeClientMutationOptions = Apollo.BaseMutationOptions<UnfreezeClientMutation, UnfreezeClientMutationVariables>;
export const UpdateClientEmailDocument = gql`
    mutation updateClientEmail($clientId: String!, $updatedEmail: String!) {
  updateClient(
    input: {clientId: $clientId, update: {contactEmail: $updatedEmail}}
  ) {
    id
    contactEmail
  }
}
    `;
export type UpdateClientEmailMutationFn = Apollo.MutationFunction<UpdateClientEmailMutation, UpdateClientEmailMutationVariables>;

/**
 * __useUpdateClientEmailMutation__
 *
 * To run a mutation, you first call `useUpdateClientEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientEmailMutation, { data, loading, error }] = useUpdateClientEmailMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      updatedEmail: // value for 'updatedEmail'
 *   },
 * });
 */
export function useUpdateClientEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientEmailMutation, UpdateClientEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientEmailMutation, UpdateClientEmailMutationVariables>(UpdateClientEmailDocument, options);
      }
export type UpdateClientEmailMutationHookResult = ReturnType<typeof useUpdateClientEmailMutation>;
export type UpdateClientEmailMutationResult = Apollo.MutationResult<UpdateClientEmailMutation>;
export type UpdateClientEmailMutationOptions = Apollo.BaseMutationOptions<UpdateClientEmailMutation, UpdateClientEmailMutationVariables>;
export const AdminsDocument = gql`
    query admins($page: Int, $pageSize: Int, $where: AdminsWhereInput) {
  admins(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      updatedAt
      firstName
      lastName
      email
      permissions
    }
    page {
      currentPage
      totalNodes
      totalPages
    }
  }
}
    `;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
      }
export function useAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
        }
export function useAdminsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsSuspenseQueryHookResult = ReturnType<typeof useAdminsSuspenseQuery>;
export type AdminsQueryResult = Apollo.QueryResult<AdminsQuery, AdminsQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CompaniesListPageDocument = gql`
    query companiesListPage($page: Int = 1, $pageSize: Int) {
  companies(page: $page, pageSize: $pageSize) {
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      createdAt
      name
      appHostPrefix
      logo
    }
  }
}
    `;

/**
 * __useCompaniesListPageQuery__
 *
 * To run a query within a React component, call `useCompaniesListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesListPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCompaniesListPageQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesListPageQuery, CompaniesListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesListPageQuery, CompaniesListPageQueryVariables>(CompaniesListPageDocument, options);
      }
export function useCompaniesListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesListPageQuery, CompaniesListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesListPageQuery, CompaniesListPageQueryVariables>(CompaniesListPageDocument, options);
        }
export function useCompaniesListPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesListPageQuery, CompaniesListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesListPageQuery, CompaniesListPageQueryVariables>(CompaniesListPageDocument, options);
        }
export type CompaniesListPageQueryHookResult = ReturnType<typeof useCompaniesListPageQuery>;
export type CompaniesListPageLazyQueryHookResult = ReturnType<typeof useCompaniesListPageLazyQuery>;
export type CompaniesListPageSuspenseQueryHookResult = ReturnType<typeof useCompaniesListPageSuspenseQuery>;
export type CompaniesListPageQueryResult = Apollo.QueryResult<CompaniesListPageQuery, CompaniesListPageQueryVariables>;
export const BasicCompanyDocument = gql`
    query BasicCompany($where: CompanyWhereUniqueInput!) {
  company(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useBasicCompanyQuery__
 *
 * To run a query within a React component, call `useBasicCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBasicCompanyQuery(baseOptions: Apollo.QueryHookOptions<BasicCompanyQuery, BasicCompanyQueryVariables> & ({ variables: BasicCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BasicCompanyQuery, BasicCompanyQueryVariables>(BasicCompanyDocument, options);
      }
export function useBasicCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasicCompanyQuery, BasicCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BasicCompanyQuery, BasicCompanyQueryVariables>(BasicCompanyDocument, options);
        }
export function useBasicCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BasicCompanyQuery, BasicCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BasicCompanyQuery, BasicCompanyQueryVariables>(BasicCompanyDocument, options);
        }
export type BasicCompanyQueryHookResult = ReturnType<typeof useBasicCompanyQuery>;
export type BasicCompanyLazyQueryHookResult = ReturnType<typeof useBasicCompanyLazyQuery>;
export type BasicCompanySuspenseQueryHookResult = ReturnType<typeof useBasicCompanySuspenseQuery>;
export type BasicCompanyQueryResult = Apollo.QueryResult<BasicCompanyQuery, BasicCompanyQueryVariables>;
export const CompanyFeaturesDocument = gql`
    query companyFeatures($companyId: String!) {
  company(where: {id: $companyId}) {
    id
    features
  }
}
    `;

/**
 * __useCompanyFeaturesQuery__
 *
 * To run a query within a React component, call `useCompanyFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFeaturesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyFeaturesQuery(baseOptions: Apollo.QueryHookOptions<CompanyFeaturesQuery, CompanyFeaturesQueryVariables> & ({ variables: CompanyFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>(CompanyFeaturesDocument, options);
      }
export function useCompanyFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>(CompanyFeaturesDocument, options);
        }
export function useCompanyFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>(CompanyFeaturesDocument, options);
        }
export type CompanyFeaturesQueryHookResult = ReturnType<typeof useCompanyFeaturesQuery>;
export type CompanyFeaturesLazyQueryHookResult = ReturnType<typeof useCompanyFeaturesLazyQuery>;
export type CompanyFeaturesSuspenseQueryHookResult = ReturnType<typeof useCompanyFeaturesSuspenseQuery>;
export type CompanyFeaturesQueryResult = Apollo.QueryResult<CompanyFeaturesQuery, CompanyFeaturesQueryVariables>;
export const UpdateCompanyFeaturesDocument = gql`
    mutation updateCompanyFeatures($input: UpdateCompanyFeaturesInput!) {
  updateCompanyFeatures(input: $input) {
    id
  }
}
    `;
export type UpdateCompanyFeaturesMutationFn = Apollo.MutationFunction<UpdateCompanyFeaturesMutation, UpdateCompanyFeaturesMutationVariables>;

/**
 * __useUpdateCompanyFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFeaturesMutation, { data, loading, error }] = useUpdateCompanyFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyFeaturesMutation, UpdateCompanyFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyFeaturesMutation, UpdateCompanyFeaturesMutationVariables>(UpdateCompanyFeaturesDocument, options);
      }
export type UpdateCompanyFeaturesMutationHookResult = ReturnType<typeof useUpdateCompanyFeaturesMutation>;
export type UpdateCompanyFeaturesMutationResult = Apollo.MutationResult<UpdateCompanyFeaturesMutation>;
export type UpdateCompanyFeaturesMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyFeaturesMutation, UpdateCompanyFeaturesMutationVariables>;
export const CompanyOverviewDocument = gql`
    query companyOverview($companyId: String!) {
  company(where: {id: $companyId}) {
    id
    name
    logo
    appHostPrefix
  }
}
    `;

/**
 * __useCompanyOverviewQuery__
 *
 * To run a query within a React component, call `useCompanyOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOverviewQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyOverviewQuery(baseOptions: Apollo.QueryHookOptions<CompanyOverviewQuery, CompanyOverviewQueryVariables> & ({ variables: CompanyOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyOverviewQuery, CompanyOverviewQueryVariables>(CompanyOverviewDocument, options);
      }
export function useCompanyOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyOverviewQuery, CompanyOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyOverviewQuery, CompanyOverviewQueryVariables>(CompanyOverviewDocument, options);
        }
export function useCompanyOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyOverviewQuery, CompanyOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyOverviewQuery, CompanyOverviewQueryVariables>(CompanyOverviewDocument, options);
        }
export type CompanyOverviewQueryHookResult = ReturnType<typeof useCompanyOverviewQuery>;
export type CompanyOverviewLazyQueryHookResult = ReturnType<typeof useCompanyOverviewLazyQuery>;
export type CompanyOverviewSuspenseQueryHookResult = ReturnType<typeof useCompanyOverviewSuspenseQuery>;
export type CompanyOverviewQueryResult = Apollo.QueryResult<CompanyOverviewQuery, CompanyOverviewQueryVariables>;
export const UploadLogoDocument = gql`
    mutation uploadLogo($input: UploadLogoInput!) {
  uploadLogo(input: $input)
}
    `;
export type UploadLogoMutationFn = Apollo.MutationFunction<UploadLogoMutation, UploadLogoMutationVariables>;

/**
 * __useUploadLogoMutation__
 *
 * To run a mutation, you first call `useUploadLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLogoMutation, { data, loading, error }] = useUploadLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadLogoMutation, UploadLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadLogoMutation, UploadLogoMutationVariables>(UploadLogoDocument, options);
      }
export type UploadLogoMutationHookResult = ReturnType<typeof useUploadLogoMutation>;
export type UploadLogoMutationResult = Apollo.MutationResult<UploadLogoMutation>;
export type UploadLogoMutationOptions = Apollo.BaseMutationOptions<UploadLogoMutation, UploadLogoMutationVariables>;
export const CompanyTokensDocument = gql`
    query companyTokens($companyId: ID!) {
  tokens(companyId: $companyId) {
    id
    icon
    name
    symbol
    deployments {
      network
    }
  }
}
    `;

/**
 * __useCompanyTokensQuery__
 *
 * To run a query within a React component, call `useCompanyTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTokensQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyTokensQuery(baseOptions: Apollo.QueryHookOptions<CompanyTokensQuery, CompanyTokensQueryVariables> & ({ variables: CompanyTokensQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyTokensQuery, CompanyTokensQueryVariables>(CompanyTokensDocument, options);
      }
export function useCompanyTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyTokensQuery, CompanyTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyTokensQuery, CompanyTokensQueryVariables>(CompanyTokensDocument, options);
        }
export function useCompanyTokensSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyTokensQuery, CompanyTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyTokensQuery, CompanyTokensQueryVariables>(CompanyTokensDocument, options);
        }
export type CompanyTokensQueryHookResult = ReturnType<typeof useCompanyTokensQuery>;
export type CompanyTokensLazyQueryHookResult = ReturnType<typeof useCompanyTokensLazyQuery>;
export type CompanyTokensSuspenseQueryHookResult = ReturnType<typeof useCompanyTokensSuspenseQuery>;
export type CompanyTokensQueryResult = Apollo.QueryResult<CompanyTokensQuery, CompanyTokensQueryVariables>;
export const CompanyUsersDocument = gql`
    query companyUsers($page: Int, $pageSize: Int, $where: UsersFilter) {
  users(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      updatedAt
      firstName
      lastName
      email
    }
    page {
      currentPage
      totalNodes
      totalPages
    }
  }
}
    `;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompanyUsersQuery(baseOptions?: Apollo.QueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, options);
      }
export function useCompanyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, options);
        }
export function useCompanyUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, options);
        }
export type CompanyUsersQueryHookResult = ReturnType<typeof useCompanyUsersQuery>;
export type CompanyUsersLazyQueryHookResult = ReturnType<typeof useCompanyUsersLazyQuery>;
export type CompanyUsersSuspenseQueryHookResult = ReturnType<typeof useCompanyUsersSuspenseQuery>;
export type CompanyUsersQueryResult = Apollo.QueryResult<CompanyUsersQuery, CompanyUsersQueryVariables>;
export const GetRiskAssessmentsDocument = gql`
    query getRiskAssessments {
  riskAssessments {
    id
    createdAt
    updatedAt
    concludedRisk
    concludedRiskPoints
    status
    clientType
    verificationProfile {
      riskLevel
      client {
        name
        type
      }
    }
  }
}
    `;

/**
 * __useGetRiskAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetRiskAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskAssessmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRiskAssessmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>(GetRiskAssessmentsDocument, options);
      }
export function useGetRiskAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>(GetRiskAssessmentsDocument, options);
        }
export function useGetRiskAssessmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>(GetRiskAssessmentsDocument, options);
        }
export type GetRiskAssessmentsQueryHookResult = ReturnType<typeof useGetRiskAssessmentsQuery>;
export type GetRiskAssessmentsLazyQueryHookResult = ReturnType<typeof useGetRiskAssessmentsLazyQuery>;
export type GetRiskAssessmentsSuspenseQueryHookResult = ReturnType<typeof useGetRiskAssessmentsSuspenseQuery>;
export type GetRiskAssessmentsQueryResult = Apollo.QueryResult<GetRiskAssessmentsQuery, GetRiskAssessmentsQueryVariables>;
export const DetailedRiskAssessmentDocument = gql`
    query DetailedRiskAssessment($id: ID!) {
  riskAssessment(id: $id) {
    id
    notes
    isBusinessActivityInNonServicedCountry
    isBusinessActivityInNonServicedCountryNotes
    isDomicileNonServicedCountryBased
    isDomicileNonServicedCountryBasedNotes
    isPoliticallyExposed
    isPoliticallyExposedNotes
    isDomiciledInMonitoredTerritory
    isDomiciledInMonitoredTerritoryNotes
    isUnderInvestigation
    isUnderInvestigationNotes
    isHighRiskForOtherReasons
    isHighRiskForOtherReasonsNotes
    isDomiciledInHighRiskCountry
    isDomiciledInHighRiskCountryNotes
    isBusinessActivityInHighRiskCountry
    isBusinessActivityInHighRiskCountryNotes
    isBusinessActivityInHighRiskSector
    isBusinessActivityInHighRiskSectorNotes
    isBusinessActivityStructureComplex
    isBusinessActivityStructureComplexNotes
    isFrequentTransactor
    isFrequentTransactorNotes
    isHighWealth
    isHighWealthNotes
    availableChecks {
      name
      reject
      displayName
      description
      riskFactor
    }
    verification {
      clientType
      vendorApplicantId
      client {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useDetailedRiskAssessmentQuery__
 *
 * To run a query within a React component, call `useDetailedRiskAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedRiskAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedRiskAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailedRiskAssessmentQuery(baseOptions: Apollo.QueryHookOptions<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables> & ({ variables: DetailedRiskAssessmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>(DetailedRiskAssessmentDocument, options);
      }
export function useDetailedRiskAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>(DetailedRiskAssessmentDocument, options);
        }
export function useDetailedRiskAssessmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>(DetailedRiskAssessmentDocument, options);
        }
export type DetailedRiskAssessmentQueryHookResult = ReturnType<typeof useDetailedRiskAssessmentQuery>;
export type DetailedRiskAssessmentLazyQueryHookResult = ReturnType<typeof useDetailedRiskAssessmentLazyQuery>;
export type DetailedRiskAssessmentSuspenseQueryHookResult = ReturnType<typeof useDetailedRiskAssessmentSuspenseQuery>;
export type DetailedRiskAssessmentQueryResult = Apollo.QueryResult<DetailedRiskAssessmentQuery, DetailedRiskAssessmentQueryVariables>;
export const UpdateRiskAssessmentDocument = gql`
    mutation UpdateRiskAssessment($input: UpdateRiskAssessmentInput!) {
  updateRiskAssessment(input: $input) {
    id
  }
}
    `;
export type UpdateRiskAssessmentMutationFn = Apollo.MutationFunction<UpdateRiskAssessmentMutation, UpdateRiskAssessmentMutationVariables>;

/**
 * __useUpdateRiskAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateRiskAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiskAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiskAssessmentMutation, { data, loading, error }] = useUpdateRiskAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRiskAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRiskAssessmentMutation, UpdateRiskAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRiskAssessmentMutation, UpdateRiskAssessmentMutationVariables>(UpdateRiskAssessmentDocument, options);
      }
export type UpdateRiskAssessmentMutationHookResult = ReturnType<typeof useUpdateRiskAssessmentMutation>;
export type UpdateRiskAssessmentMutationResult = Apollo.MutationResult<UpdateRiskAssessmentMutation>;
export type UpdateRiskAssessmentMutationOptions = Apollo.BaseMutationOptions<UpdateRiskAssessmentMutation, UpdateRiskAssessmentMutationVariables>;
export const FileRiskAssessmentDocument = gql`
    mutation FileRiskAssessment($id: ID!, $concludedRiskLevel: RiskLevel!) {
  fileRiskAssessment(id: $id, concludedRiskLevel: $concludedRiskLevel) {
    status
  }
}
    `;
export type FileRiskAssessmentMutationFn = Apollo.MutationFunction<FileRiskAssessmentMutation, FileRiskAssessmentMutationVariables>;

/**
 * __useFileRiskAssessmentMutation__
 *
 * To run a mutation, you first call `useFileRiskAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileRiskAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileRiskAssessmentMutation, { data, loading, error }] = useFileRiskAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      concludedRiskLevel: // value for 'concludedRiskLevel'
 *   },
 * });
 */
export function useFileRiskAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<FileRiskAssessmentMutation, FileRiskAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileRiskAssessmentMutation, FileRiskAssessmentMutationVariables>(FileRiskAssessmentDocument, options);
      }
export type FileRiskAssessmentMutationHookResult = ReturnType<typeof useFileRiskAssessmentMutation>;
export type FileRiskAssessmentMutationResult = Apollo.MutationResult<FileRiskAssessmentMutation>;
export type FileRiskAssessmentMutationOptions = Apollo.BaseMutationOptions<FileRiskAssessmentMutation, FileRiskAssessmentMutationVariables>;
export const RiskAssessmentDetailsDocument = gql`
    query riskAssessmentDetails($riskAssessmentId: ID!) {
  riskAssessment(id: $riskAssessmentId) {
    id
    createdAt
    updatedAt
    concludedRisk
    concludedRiskPoints
    filedBy {
      id
      firstName
      lastName
    }
    status
    availableChecks {
      name
      riskFactor
      description
      displayName
    }
    isBusinessActivityInHighRiskCountry
    isBusinessActivityInHighRiskCountryNotes
    isBusinessActivityInHighRiskSector
    isBusinessActivityInHighRiskSectorNotes
    isBusinessActivityInNonServicedCountry
    isBusinessActivityInNonServicedCountryNotes
    isBusinessActivityStructureComplex
    isBusinessActivityStructureComplexNotes
    isDomicileNonServicedCountryBased
    isDomicileNonServicedCountryBasedNotes
    isDomiciledInHighRiskCountry
    isDomiciledInHighRiskCountryNotes
    isDomiciledInMonitoredTerritory
    isDomiciledInMonitoredTerritoryNotes
    isFrequentTransactor
    isFrequentTransactorNotes
    isHighRiskForOtherReasons
    isHighRiskForOtherReasonsNotes
    isHighWealth
    isHighWealthNotes
    isPoliticallyExposed
    isPoliticallyExposedNotes
    isUnderInvestigation
    isUnderInvestigationNotes
    client {
      id
      name
    }
  }
}
    `;

/**
 * __useRiskAssessmentDetailsQuery__
 *
 * To run a query within a React component, call `useRiskAssessmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskAssessmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskAssessmentDetailsQuery({
 *   variables: {
 *      riskAssessmentId: // value for 'riskAssessmentId'
 *   },
 * });
 */
export function useRiskAssessmentDetailsQuery(baseOptions: Apollo.QueryHookOptions<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables> & ({ variables: RiskAssessmentDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>(RiskAssessmentDetailsDocument, options);
      }
export function useRiskAssessmentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>(RiskAssessmentDetailsDocument, options);
        }
export function useRiskAssessmentDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>(RiskAssessmentDetailsDocument, options);
        }
export type RiskAssessmentDetailsQueryHookResult = ReturnType<typeof useRiskAssessmentDetailsQuery>;
export type RiskAssessmentDetailsLazyQueryHookResult = ReturnType<typeof useRiskAssessmentDetailsLazyQuery>;
export type RiskAssessmentDetailsSuspenseQueryHookResult = ReturnType<typeof useRiskAssessmentDetailsSuspenseQuery>;
export type RiskAssessmentDetailsQueryResult = Apollo.QueryResult<RiskAssessmentDetailsQuery, RiskAssessmentDetailsQueryVariables>;
export const ClientRiskAssessmentDocument = gql`
    query ClientRiskAssessment($id: ID!) {
  riskAssessment(id: $id) {
    verification {
      clientType
      client {
        id
        name
        isProfessional
        type
        classification
        questionnaires(where: {type: OnboardingQuestionnaire}) {
          id
          status
          payload
        }
      }
    }
  }
}
    `;

/**
 * __useClientRiskAssessmentQuery__
 *
 * To run a query within a React component, call `useClientRiskAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientRiskAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientRiskAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientRiskAssessmentQuery(baseOptions: Apollo.QueryHookOptions<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables> & ({ variables: ClientRiskAssessmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>(ClientRiskAssessmentDocument, options);
      }
export function useClientRiskAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>(ClientRiskAssessmentDocument, options);
        }
export function useClientRiskAssessmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>(ClientRiskAssessmentDocument, options);
        }
export type ClientRiskAssessmentQueryHookResult = ReturnType<typeof useClientRiskAssessmentQuery>;
export type ClientRiskAssessmentLazyQueryHookResult = ReturnType<typeof useClientRiskAssessmentLazyQuery>;
export type ClientRiskAssessmentSuspenseQueryHookResult = ReturnType<typeof useClientRiskAssessmentSuspenseQuery>;
export type ClientRiskAssessmentQueryResult = Apollo.QueryResult<ClientRiskAssessmentQuery, ClientRiskAssessmentQueryVariables>;
export const DocumentDetailsDocument = gql`
    query DocumentDetails($documentId: ID!) {
  document(id: $documentId) {
    id
    client {
      id
      name
      type
    }
    url
    type
  }
}
    `;

/**
 * __useDocumentDetailsQuery__
 *
 * To run a query within a React component, call `useDocumentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDetailsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentDetailsQuery(baseOptions: Apollo.QueryHookOptions<DocumentDetailsQuery, DocumentDetailsQueryVariables> & ({ variables: DocumentDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDetailsQuery, DocumentDetailsQueryVariables>(DocumentDetailsDocument, options);
      }
export function useDocumentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDetailsQuery, DocumentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDetailsQuery, DocumentDetailsQueryVariables>(DocumentDetailsDocument, options);
        }
export function useDocumentDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentDetailsQuery, DocumentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentDetailsQuery, DocumentDetailsQueryVariables>(DocumentDetailsDocument, options);
        }
export type DocumentDetailsQueryHookResult = ReturnType<typeof useDocumentDetailsQuery>;
export type DocumentDetailsLazyQueryHookResult = ReturnType<typeof useDocumentDetailsLazyQuery>;
export type DocumentDetailsSuspenseQueryHookResult = ReturnType<typeof useDocumentDetailsSuspenseQuery>;
export type DocumentDetailsQueryResult = Apollo.QueryResult<DocumentDetailsQuery, DocumentDetailsQueryVariables>;
export const DocumentsListDocument = gql`
    query documentsList {
  documents {
    id
    type
    client {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useDocumentsListQuery__
 *
 * To run a query within a React component, call `useDocumentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentsListQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsListQuery, DocumentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsListQuery, DocumentsListQueryVariables>(DocumentsListDocument, options);
      }
export function useDocumentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsListQuery, DocumentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsListQuery, DocumentsListQueryVariables>(DocumentsListDocument, options);
        }
export function useDocumentsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsListQuery, DocumentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsListQuery, DocumentsListQueryVariables>(DocumentsListDocument, options);
        }
export type DocumentsListQueryHookResult = ReturnType<typeof useDocumentsListQuery>;
export type DocumentsListLazyQueryHookResult = ReturnType<typeof useDocumentsListLazyQuery>;
export type DocumentsListSuspenseQueryHookResult = ReturnType<typeof useDocumentsListSuspenseQuery>;
export type DocumentsListQueryResult = Apollo.QueryResult<DocumentsListQuery, DocumentsListQueryVariables>;
export const ComplianceReviewDocument = gql`
    query complianceReview($id: ID!) {
  complianceReview(complianceReviewId: $id) {
    id
    createdAt
    updatedAt
    notes
    isProfessional
    featuresAllowed
    client {
      id
      name
    }
  }
}
    `;

/**
 * __useComplianceReviewQuery__
 *
 * To run a query within a React component, call `useComplianceReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useComplianceReviewQuery(baseOptions: Apollo.QueryHookOptions<ComplianceReviewQuery, ComplianceReviewQueryVariables> & ({ variables: ComplianceReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComplianceReviewQuery, ComplianceReviewQueryVariables>(ComplianceReviewDocument, options);
      }
export function useComplianceReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComplianceReviewQuery, ComplianceReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComplianceReviewQuery, ComplianceReviewQueryVariables>(ComplianceReviewDocument, options);
        }
export function useComplianceReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ComplianceReviewQuery, ComplianceReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ComplianceReviewQuery, ComplianceReviewQueryVariables>(ComplianceReviewDocument, options);
        }
export type ComplianceReviewQueryHookResult = ReturnType<typeof useComplianceReviewQuery>;
export type ComplianceReviewLazyQueryHookResult = ReturnType<typeof useComplianceReviewLazyQuery>;
export type ComplianceReviewSuspenseQueryHookResult = ReturnType<typeof useComplianceReviewSuspenseQuery>;
export type ComplianceReviewQueryResult = Apollo.QueryResult<ComplianceReviewQuery, ComplianceReviewQueryVariables>;
export const UpdateComplianceReviewDocument = gql`
    mutation updateComplianceReview($input: UpdateComplianceReviewInput!) {
  updateComplianceReview(input: $input) {
    id
  }
}
    `;
export type UpdateComplianceReviewMutationFn = Apollo.MutationFunction<UpdateComplianceReviewMutation, UpdateComplianceReviewMutationVariables>;

/**
 * __useUpdateComplianceReviewMutation__
 *
 * To run a mutation, you first call `useUpdateComplianceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComplianceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComplianceReviewMutation, { data, loading, error }] = useUpdateComplianceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateComplianceReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComplianceReviewMutation, UpdateComplianceReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComplianceReviewMutation, UpdateComplianceReviewMutationVariables>(UpdateComplianceReviewDocument, options);
      }
export type UpdateComplianceReviewMutationHookResult = ReturnType<typeof useUpdateComplianceReviewMutation>;
export type UpdateComplianceReviewMutationResult = Apollo.MutationResult<UpdateComplianceReviewMutation>;
export type UpdateComplianceReviewMutationOptions = Apollo.BaseMutationOptions<UpdateComplianceReviewMutation, UpdateComplianceReviewMutationVariables>;
export const SubmitComplianceReviewDocument = gql`
    mutation submitComplianceReview($input: SubmitComplianceReviewInput!) {
  submitComplianceReview(input: $input) {
    id
  }
}
    `;
export type SubmitComplianceReviewMutationFn = Apollo.MutationFunction<SubmitComplianceReviewMutation, SubmitComplianceReviewMutationVariables>;

/**
 * __useSubmitComplianceReviewMutation__
 *
 * To run a mutation, you first call `useSubmitComplianceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitComplianceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitComplianceReviewMutation, { data, loading, error }] = useSubmitComplianceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitComplianceReviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitComplianceReviewMutation, SubmitComplianceReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitComplianceReviewMutation, SubmitComplianceReviewMutationVariables>(SubmitComplianceReviewDocument, options);
      }
export type SubmitComplianceReviewMutationHookResult = ReturnType<typeof useSubmitComplianceReviewMutation>;
export type SubmitComplianceReviewMutationResult = Apollo.MutationResult<SubmitComplianceReviewMutation>;
export type SubmitComplianceReviewMutationOptions = Apollo.BaseMutationOptions<SubmitComplianceReviewMutation, SubmitComplianceReviewMutationVariables>;
export const VerificationProfileDetailsDocument = gql`
    query verificationProfileDetails($profileId: ID!) {
  verificationProfile(id: $profileId) {
    id
  }
}
    `;

/**
 * __useVerificationProfileDetailsQuery__
 *
 * To run a query within a React component, call `useVerificationProfileDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationProfileDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationProfileDetailsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useVerificationProfileDetailsQuery(baseOptions: Apollo.QueryHookOptions<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables> & ({ variables: VerificationProfileDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>(VerificationProfileDetailsDocument, options);
      }
export function useVerificationProfileDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>(VerificationProfileDetailsDocument, options);
        }
export function useVerificationProfileDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>(VerificationProfileDetailsDocument, options);
        }
export type VerificationProfileDetailsQueryHookResult = ReturnType<typeof useVerificationProfileDetailsQuery>;
export type VerificationProfileDetailsLazyQueryHookResult = ReturnType<typeof useVerificationProfileDetailsLazyQuery>;
export type VerificationProfileDetailsSuspenseQueryHookResult = ReturnType<typeof useVerificationProfileDetailsSuspenseQuery>;
export type VerificationProfileDetailsQueryResult = Apollo.QueryResult<VerificationProfileDetailsQuery, VerificationProfileDetailsQueryVariables>;
export const VerificationsListDocument = gql`
    query verificationsList {
  verifications {
    id
    isArchived
    createdAt
    updatedAt
    tier
    vendor
    vendorApplicantId
    vendorVerificationId
    clientType
    status
    client {
      id
      name
      jurisdiction
    }
  }
}
    `;

/**
 * __useVerificationsListQuery__
 *
 * To run a query within a React component, call `useVerificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerificationsListQuery(baseOptions?: Apollo.QueryHookOptions<VerificationsListQuery, VerificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerificationsListQuery, VerificationsListQueryVariables>(VerificationsListDocument, options);
      }
export function useVerificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerificationsListQuery, VerificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerificationsListQuery, VerificationsListQueryVariables>(VerificationsListDocument, options);
        }
export function useVerificationsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerificationsListQuery, VerificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerificationsListQuery, VerificationsListQueryVariables>(VerificationsListDocument, options);
        }
export type VerificationsListQueryHookResult = ReturnType<typeof useVerificationsListQuery>;
export type VerificationsListLazyQueryHookResult = ReturnType<typeof useVerificationsListLazyQuery>;
export type VerificationsListSuspenseQueryHookResult = ReturnType<typeof useVerificationsListSuspenseQuery>;
export type VerificationsListQueryResult = Apollo.QueryResult<VerificationsListQuery, VerificationsListQueryVariables>;
export const AdminNotificationsDocument = gql`
    query adminNotifications {
  notifications(where: {domain: System, isAcknowledged: false}) {
    id
    title
    type
  }
}
    `;

/**
 * __useAdminNotificationsQuery__
 *
 * To run a query within a React component, call `useAdminNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<AdminNotificationsQuery, AdminNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminNotificationsQuery, AdminNotificationsQueryVariables>(AdminNotificationsDocument, options);
      }
export function useAdminNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminNotificationsQuery, AdminNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminNotificationsQuery, AdminNotificationsQueryVariables>(AdminNotificationsDocument, options);
        }
export function useAdminNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminNotificationsQuery, AdminNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminNotificationsQuery, AdminNotificationsQueryVariables>(AdminNotificationsDocument, options);
        }
export type AdminNotificationsQueryHookResult = ReturnType<typeof useAdminNotificationsQuery>;
export type AdminNotificationsLazyQueryHookResult = ReturnType<typeof useAdminNotificationsLazyQuery>;
export type AdminNotificationsSuspenseQueryHookResult = ReturnType<typeof useAdminNotificationsSuspenseQuery>;
export type AdminNotificationsQueryResult = Apollo.QueryResult<AdminNotificationsQuery, AdminNotificationsQueryVariables>;
export const DashboardDataDocument = gql`
    query dashboardData {
  riskAssessments(where: {status: Active}) {
    id
    client {
      id
      name
    }
  }
}
    `;

/**
 * __useDashboardDataQuery__
 *
 * To run a query within a React component, call `useDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDataQuery(baseOptions?: Apollo.QueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
      }
export function useDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
        }
export function useDashboardDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
        }
export type DashboardDataQueryHookResult = ReturnType<typeof useDashboardDataQuery>;
export type DashboardDataLazyQueryHookResult = ReturnType<typeof useDashboardDataLazyQuery>;
export type DashboardDataSuspenseQueryHookResult = ReturnType<typeof useDashboardDataSuspenseQuery>;
export type DashboardDataQueryResult = Apollo.QueryResult<DashboardDataQuery, DashboardDataQueryVariables>;
export const GetTokenStatisticsDocument = gql`
    query getTokenStatistics($tokenSymbol: String, $isLatest: Boolean, $isHistory: Boolean) {
  tokenStatistics(
    tokenSymbol: $tokenSymbol
    isLatest: $isLatest
    isHistory: $isHistory
  ) {
    createdAt
    statistics
  }
}
    `;

/**
 * __useGetTokenStatisticsQuery__
 *
 * To run a query within a React component, call `useGetTokenStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenStatisticsQuery({
 *   variables: {
 *      tokenSymbol: // value for 'tokenSymbol'
 *      isLatest: // value for 'isLatest'
 *      isHistory: // value for 'isHistory'
 *   },
 * });
 */
export function useGetTokenStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>(GetTokenStatisticsDocument, options);
      }
export function useGetTokenStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>(GetTokenStatisticsDocument, options);
        }
export function useGetTokenStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>(GetTokenStatisticsDocument, options);
        }
export type GetTokenStatisticsQueryHookResult = ReturnType<typeof useGetTokenStatisticsQuery>;
export type GetTokenStatisticsLazyQueryHookResult = ReturnType<typeof useGetTokenStatisticsLazyQuery>;
export type GetTokenStatisticsSuspenseQueryHookResult = ReturnType<typeof useGetTokenStatisticsSuspenseQuery>;
export type GetTokenStatisticsQueryResult = Apollo.QueryResult<GetTokenStatisticsQuery, GetTokenStatisticsQueryVariables>;
export const TokenDeploymentDetailsDocument = gql`
    query tokenDeploymentDetails($tokenId: ID!) {
  token(id: $tokenId) {
    id
    name
    symbol
    isPublic
    description
    deployments {
      network
      address
    }
    collateral {
      oracles {
        network
        isActive
      }
    }
  }
}
    `;

/**
 * __useTokenDeploymentDetailsQuery__
 *
 * To run a query within a React component, call `useTokenDeploymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDeploymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDeploymentDetailsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenDeploymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables> & ({ variables: TokenDeploymentDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>(TokenDeploymentDetailsDocument, options);
      }
export function useTokenDeploymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>(TokenDeploymentDetailsDocument, options);
        }
export function useTokenDeploymentDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>(TokenDeploymentDetailsDocument, options);
        }
export type TokenDeploymentDetailsQueryHookResult = ReturnType<typeof useTokenDeploymentDetailsQuery>;
export type TokenDeploymentDetailsLazyQueryHookResult = ReturnType<typeof useTokenDeploymentDetailsLazyQuery>;
export type TokenDeploymentDetailsSuspenseQueryHookResult = ReturnType<typeof useTokenDeploymentDetailsSuspenseQuery>;
export type TokenDeploymentDetailsQueryResult = Apollo.QueryResult<TokenDeploymentDetailsQuery, TokenDeploymentDetailsQueryVariables>;
export const CreateDeploymentDocument = gql`
    mutation createDeployment($input: CreateDeploymentInput!) {
  createDeployment(input: $input) {
    id
  }
}
    `;
export type CreateDeploymentMutationFn = Apollo.MutationFunction<CreateDeploymentMutation, CreateDeploymentMutationVariables>;

/**
 * __useCreateDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentMutation, { data, loading, error }] = useCreateDeploymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentMutation, CreateDeploymentMutationVariables>(CreateDeploymentDocument, options);
      }
export type CreateDeploymentMutationHookResult = ReturnType<typeof useCreateDeploymentMutation>;
export type CreateDeploymentMutationResult = Apollo.MutationResult<CreateDeploymentMutation>;
export type CreateDeploymentMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>;
export const DeploymentDetailsDocument = gql`
    query deploymentDetails($id: String!) {
  deployment(id: $id) {
    id
    status
    admin {
      id
      firstName
      lastName
      email
    }
    tasks {
      id
      createdAt
      updatedAt
      network
      status
      steps
      payload
      contractType
    }
    token {
      id
      name
      symbol
    }
  }
}
    `;

/**
 * __useDeploymentDetailsQuery__
 *
 * To run a query within a React component, call `useDeploymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeploymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeploymentDetailsQuery, DeploymentDetailsQueryVariables> & ({ variables: DeploymentDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>(DeploymentDetailsDocument, options);
      }
export function useDeploymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>(DeploymentDetailsDocument, options);
        }
export function useDeploymentDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>(DeploymentDetailsDocument, options);
        }
export type DeploymentDetailsQueryHookResult = ReturnType<typeof useDeploymentDetailsQuery>;
export type DeploymentDetailsLazyQueryHookResult = ReturnType<typeof useDeploymentDetailsLazyQuery>;
export type DeploymentDetailsSuspenseQueryHookResult = ReturnType<typeof useDeploymentDetailsSuspenseQuery>;
export type DeploymentDetailsQueryResult = Apollo.QueryResult<DeploymentDetailsQuery, DeploymentDetailsQueryVariables>;
export const DeploymentsHomeDocument = gql`
    query deploymentsHome($page: Int, $pageSize: Int, $where: DeploymentsWhereInput) {
  deployments(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      status
      admin {
        id
        firstName
        lastName
      }
      token {
        id
        name
        symbol
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
    }
  }
}
    `;

/**
 * __useDeploymentsHomeQuery__
 *
 * To run a query within a React component, call `useDeploymentsHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsHomeQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeploymentsHomeQuery(baseOptions?: Apollo.QueryHookOptions<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>(DeploymentsHomeDocument, options);
      }
export function useDeploymentsHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>(DeploymentsHomeDocument, options);
        }
export function useDeploymentsHomeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>(DeploymentsHomeDocument, options);
        }
export type DeploymentsHomeQueryHookResult = ReturnType<typeof useDeploymentsHomeQuery>;
export type DeploymentsHomeLazyQueryHookResult = ReturnType<typeof useDeploymentsHomeLazyQuery>;
export type DeploymentsHomeSuspenseQueryHookResult = ReturnType<typeof useDeploymentsHomeSuspenseQuery>;
export type DeploymentsHomeQueryResult = Apollo.QueryResult<DeploymentsHomeQuery, DeploymentsHomeQueryVariables>;
export const CustodyAccountDetailsDocument = gql`
    query custodyAccountDetails($where: CustodyAccountWhereUniqueInput!) {
  custodyAccount(where: $where) {
    id
    facets {
      id
      type
      balanceChanges {
        createdAt
        balance
      }
      asset
      balance
      label
    }
    events {
      id
      tookPlaceAt
      description
      type
      changeAmount
      changeCurrency
      flowDirection
    }
    externalId
  }
}
    `;

/**
 * __useCustodyAccountDetailsQuery__
 *
 * To run a query within a React component, call `useCustodyAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodyAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodyAccountDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustodyAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables> & ({ variables: CustodyAccountDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>(CustodyAccountDetailsDocument, options);
      }
export function useCustodyAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>(CustodyAccountDetailsDocument, options);
        }
export function useCustodyAccountDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>(CustodyAccountDetailsDocument, options);
        }
export type CustodyAccountDetailsQueryHookResult = ReturnType<typeof useCustodyAccountDetailsQuery>;
export type CustodyAccountDetailsLazyQueryHookResult = ReturnType<typeof useCustodyAccountDetailsLazyQuery>;
export type CustodyAccountDetailsSuspenseQueryHookResult = ReturnType<typeof useCustodyAccountDetailsSuspenseQuery>;
export type CustodyAccountDetailsQueryResult = Apollo.QueryResult<CustodyAccountDetailsQuery, CustodyAccountDetailsQueryVariables>;
export const AccountsListPageDocument = gql`
    query AccountsListPage {
  custodyAccounts {
    id
    externalId
    facets {
      id
      balance
      label
      type
      asset
      isWorkingCapital
      collateral {
        symbol
      }
    }
  }
}
    `;

/**
 * __useAccountsListPageQuery__
 *
 * To run a query within a React component, call `useAccountsListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsListPageQuery(baseOptions?: Apollo.QueryHookOptions<AccountsListPageQuery, AccountsListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsListPageQuery, AccountsListPageQueryVariables>(AccountsListPageDocument, options);
      }
export function useAccountsListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsListPageQuery, AccountsListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsListPageQuery, AccountsListPageQueryVariables>(AccountsListPageDocument, options);
        }
export function useAccountsListPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountsListPageQuery, AccountsListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountsListPageQuery, AccountsListPageQueryVariables>(AccountsListPageDocument, options);
        }
export type AccountsListPageQueryHookResult = ReturnType<typeof useAccountsListPageQuery>;
export type AccountsListPageLazyQueryHookResult = ReturnType<typeof useAccountsListPageLazyQuery>;
export type AccountsListPageSuspenseQueryHookResult = ReturnType<typeof useAccountsListPageSuspenseQuery>;
export type AccountsListPageQueryResult = Apollo.QueryResult<AccountsListPageQuery, AccountsListPageQueryVariables>;
export const PatchCustodyAccountFacetDocument = gql`
    mutation patchCustodyAccountFacet($input: PatchCustodyAccountFacetMutationInput!) {
  patchCustodyAccountFacet(input: $input) {
    id
  }
}
    `;
export type PatchCustodyAccountFacetMutationFn = Apollo.MutationFunction<PatchCustodyAccountFacetMutation, PatchCustodyAccountFacetMutationVariables>;

/**
 * __usePatchCustodyAccountFacetMutation__
 *
 * To run a mutation, you first call `usePatchCustodyAccountFacetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchCustodyAccountFacetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchCustodyAccountFacetMutation, { data, loading, error }] = usePatchCustodyAccountFacetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchCustodyAccountFacetMutation(baseOptions?: Apollo.MutationHookOptions<PatchCustodyAccountFacetMutation, PatchCustodyAccountFacetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchCustodyAccountFacetMutation, PatchCustodyAccountFacetMutationVariables>(PatchCustodyAccountFacetDocument, options);
      }
export type PatchCustodyAccountFacetMutationHookResult = ReturnType<typeof usePatchCustodyAccountFacetMutation>;
export type PatchCustodyAccountFacetMutationResult = Apollo.MutationResult<PatchCustodyAccountFacetMutation>;
export type PatchCustodyAccountFacetMutationOptions = Apollo.BaseMutationOptions<PatchCustodyAccountFacetMutation, PatchCustodyAccountFacetMutationVariables>;
export const CollateralisationPageDataDocument = gql`
    query CollateralisationPageData {
  tokens {
    id
    name
    symbol
  }
}
    `;

/**
 * __useCollateralisationPageDataQuery__
 *
 * To run a query within a React component, call `useCollateralisationPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollateralisationPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollateralisationPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollateralisationPageDataQuery(baseOptions?: Apollo.QueryHookOptions<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>(CollateralisationPageDataDocument, options);
      }
export function useCollateralisationPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>(CollateralisationPageDataDocument, options);
        }
export function useCollateralisationPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>(CollateralisationPageDataDocument, options);
        }
export type CollateralisationPageDataQueryHookResult = ReturnType<typeof useCollateralisationPageDataQuery>;
export type CollateralisationPageDataLazyQueryHookResult = ReturnType<typeof useCollateralisationPageDataLazyQuery>;
export type CollateralisationPageDataSuspenseQueryHookResult = ReturnType<typeof useCollateralisationPageDataSuspenseQuery>;
export type CollateralisationPageDataQueryResult = Apollo.QueryResult<CollateralisationPageDataQuery, CollateralisationPageDataQueryVariables>;
export const WorkingCapitalDocument = gql`
    query workingCapital {
  systemWallets {
    id
    title
    description
    nativeBalances {
      address
      balance
      network
    }
    tokenBalances {
      balance {
        network
        balance
        contractAddress
        walletAddress
      }
      token {
        id
        name
        symbol
        isPublic
      }
    }
  }
}
    `;

/**
 * __useWorkingCapitalQuery__
 *
 * To run a query within a React component, call `useWorkingCapitalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingCapitalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingCapitalQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkingCapitalQuery(baseOptions?: Apollo.QueryHookOptions<WorkingCapitalQuery, WorkingCapitalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingCapitalQuery, WorkingCapitalQueryVariables>(WorkingCapitalDocument, options);
      }
export function useWorkingCapitalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingCapitalQuery, WorkingCapitalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingCapitalQuery, WorkingCapitalQueryVariables>(WorkingCapitalDocument, options);
        }
export function useWorkingCapitalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkingCapitalQuery, WorkingCapitalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkingCapitalQuery, WorkingCapitalQueryVariables>(WorkingCapitalDocument, options);
        }
export type WorkingCapitalQueryHookResult = ReturnType<typeof useWorkingCapitalQuery>;
export type WorkingCapitalLazyQueryHookResult = ReturnType<typeof useWorkingCapitalLazyQuery>;
export type WorkingCapitalSuspenseQueryHookResult = ReturnType<typeof useWorkingCapitalSuspenseQuery>;
export type WorkingCapitalQueryResult = Apollo.QueryResult<WorkingCapitalQuery, WorkingCapitalQueryVariables>;
export const FlowsExecutionsListDocument = gql`
    query flowsExecutionsList($page: Int, $pageSize: Int, $where: FlowExecutionWhereInput) {
  flowExecutions(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      flowType
      status
      mint {
        id
        variant
        amount
        hash
        explorerUrl
        tokenDeployment {
          network
          token {
            name
            symbol
            icon
          }
        }
      }
      burn {
        id
        variant
        amount
        hash
        explorerUrl
        tokenDeployment {
          network
          token {
            name
            symbol
            icon
          }
        }
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useFlowsExecutionsListQuery__
 *
 * To run a query within a React component, call `useFlowsExecutionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowsExecutionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowsExecutionsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFlowsExecutionsListQuery(baseOptions?: Apollo.QueryHookOptions<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>(FlowsExecutionsListDocument, options);
      }
export function useFlowsExecutionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>(FlowsExecutionsListDocument, options);
        }
export function useFlowsExecutionsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>(FlowsExecutionsListDocument, options);
        }
export type FlowsExecutionsListQueryHookResult = ReturnType<typeof useFlowsExecutionsListQuery>;
export type FlowsExecutionsListLazyQueryHookResult = ReturnType<typeof useFlowsExecutionsListLazyQuery>;
export type FlowsExecutionsListSuspenseQueryHookResult = ReturnType<typeof useFlowsExecutionsListSuspenseQuery>;
export type FlowsExecutionsListQueryResult = Apollo.QueryResult<FlowsExecutionsListQuery, FlowsExecutionsListQueryVariables>;
export const BurnFlowDialogDocument = gql`
    query burnFlowDialog($tokenId: ID!) {
  token(id: $tokenId) {
    id
    name
    symbol
    icon
    deployments {
      id
      network
      circulatingSupply
      totalSupply
      burnAddress
      burnSupply
    }
    collateral {
      ISINNumber
    }
  }
}
    `;

/**
 * __useBurnFlowDialogQuery__
 *
 * To run a query within a React component, call `useBurnFlowDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBurnFlowDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBurnFlowDialogQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useBurnFlowDialogQuery(baseOptions: Apollo.QueryHookOptions<BurnFlowDialogQuery, BurnFlowDialogQueryVariables> & ({ variables: BurnFlowDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>(BurnFlowDialogDocument, options);
      }
export function useBurnFlowDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>(BurnFlowDialogDocument, options);
        }
export function useBurnFlowDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>(BurnFlowDialogDocument, options);
        }
export type BurnFlowDialogQueryHookResult = ReturnType<typeof useBurnFlowDialogQuery>;
export type BurnFlowDialogLazyQueryHookResult = ReturnType<typeof useBurnFlowDialogLazyQuery>;
export type BurnFlowDialogSuspenseQueryHookResult = ReturnType<typeof useBurnFlowDialogSuspenseQuery>;
export type BurnFlowDialogQueryResult = Apollo.QueryResult<BurnFlowDialogQuery, BurnFlowDialogQueryVariables>;
export const MintFlowDialogDocument = gql`
    query mintFlowDialog($tokenId: ID!) {
  token(id: $tokenId) {
    id
    name
    symbol
    icon
    deployments {
      id
      network
      circulatingSupply
    }
    collateral {
      ISINNumber
    }
  }
  blockchainAddresses {
    id
    address
    description
  }
}
    `;

/**
 * __useMintFlowDialogQuery__
 *
 * To run a query within a React component, call `useMintFlowDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMintFlowDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMintFlowDialogQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useMintFlowDialogQuery(baseOptions: Apollo.QueryHookOptions<MintFlowDialogQuery, MintFlowDialogQueryVariables> & ({ variables: MintFlowDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MintFlowDialogQuery, MintFlowDialogQueryVariables>(MintFlowDialogDocument, options);
      }
export function useMintFlowDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MintFlowDialogQuery, MintFlowDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MintFlowDialogQuery, MintFlowDialogQueryVariables>(MintFlowDialogDocument, options);
        }
export function useMintFlowDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MintFlowDialogQuery, MintFlowDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MintFlowDialogQuery, MintFlowDialogQueryVariables>(MintFlowDialogDocument, options);
        }
export type MintFlowDialogQueryHookResult = ReturnType<typeof useMintFlowDialogQuery>;
export type MintFlowDialogLazyQueryHookResult = ReturnType<typeof useMintFlowDialogLazyQuery>;
export type MintFlowDialogSuspenseQueryHookResult = ReturnType<typeof useMintFlowDialogSuspenseQuery>;
export type MintFlowDialogQueryResult = Apollo.QueryResult<MintFlowDialogQuery, MintFlowDialogQueryVariables>;
export const FlowExecutionDetailsDocument = gql`
    query flowExecutionDetails($flowId: String!) {
  flowExecution(where: {id: $flowId}) {
    status
  }
}
    `;

/**
 * __useFlowExecutionDetailsQuery__
 *
 * To run a query within a React component, call `useFlowExecutionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionDetailsQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useFlowExecutionDetailsQuery(baseOptions: Apollo.QueryHookOptions<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables> & ({ variables: FlowExecutionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>(FlowExecutionDetailsDocument, options);
      }
export function useFlowExecutionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>(FlowExecutionDetailsDocument, options);
        }
export function useFlowExecutionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>(FlowExecutionDetailsDocument, options);
        }
export type FlowExecutionDetailsQueryHookResult = ReturnType<typeof useFlowExecutionDetailsQuery>;
export type FlowExecutionDetailsLazyQueryHookResult = ReturnType<typeof useFlowExecutionDetailsLazyQuery>;
export type FlowExecutionDetailsSuspenseQueryHookResult = ReturnType<typeof useFlowExecutionDetailsSuspenseQuery>;
export type FlowExecutionDetailsQueryResult = Apollo.QueryResult<FlowExecutionDetailsQuery, FlowExecutionDetailsQueryVariables>;
export const FlowExecutionDetailsOverviewDocument = gql`
    query flowExecutionDetailsOverview($flowId: String!) {
  flowExecution(where: {id: $flowId}) {
    id
    createdAt
    status
    flowType
    mint {
      amount
      hash
      explorerUrl
      blockchainAddress {
        address
      }
      tokenDeployment {
        network
        token {
          id
          icon
          symbol
          name
          collateral {
            ISINNumber
          }
        }
      }
    }
    burn {
      amount
      hash
      explorerUrl
      tokenDeployment {
        network
        burnAddress
        token {
          id
          icon
          symbol
          name
          collateral {
            ISINNumber
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFlowExecutionDetailsOverviewQuery__
 *
 * To run a query within a React component, call `useFlowExecutionDetailsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionDetailsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionDetailsOverviewQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useFlowExecutionDetailsOverviewQuery(baseOptions: Apollo.QueryHookOptions<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables> & ({ variables: FlowExecutionDetailsOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>(FlowExecutionDetailsOverviewDocument, options);
      }
export function useFlowExecutionDetailsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>(FlowExecutionDetailsOverviewDocument, options);
        }
export function useFlowExecutionDetailsOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>(FlowExecutionDetailsOverviewDocument, options);
        }
export type FlowExecutionDetailsOverviewQueryHookResult = ReturnType<typeof useFlowExecutionDetailsOverviewQuery>;
export type FlowExecutionDetailsOverviewLazyQueryHookResult = ReturnType<typeof useFlowExecutionDetailsOverviewLazyQuery>;
export type FlowExecutionDetailsOverviewSuspenseQueryHookResult = ReturnType<typeof useFlowExecutionDetailsOverviewSuspenseQuery>;
export type FlowExecutionDetailsOverviewQueryResult = Apollo.QueryResult<FlowExecutionDetailsOverviewQuery, FlowExecutionDetailsOverviewQueryVariables>;
export const FlowSupportedTokensDocument = gql`
    query flowSupportedTokens {
  tokens(includeUnpublished: true) {
    id
    name
    symbol
    icon
    deployments {
      id
      network
    }
  }
}
    `;

/**
 * __useFlowSupportedTokensQuery__
 *
 * To run a query within a React component, call `useFlowSupportedTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowSupportedTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowSupportedTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlowSupportedTokensQuery(baseOptions?: Apollo.QueryHookOptions<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>(FlowSupportedTokensDocument, options);
      }
export function useFlowSupportedTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>(FlowSupportedTokensDocument, options);
        }
export function useFlowSupportedTokensSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>(FlowSupportedTokensDocument, options);
        }
export type FlowSupportedTokensQueryHookResult = ReturnType<typeof useFlowSupportedTokensQuery>;
export type FlowSupportedTokensLazyQueryHookResult = ReturnType<typeof useFlowSupportedTokensLazyQuery>;
export type FlowSupportedTokensSuspenseQueryHookResult = ReturnType<typeof useFlowSupportedTokensSuspenseQuery>;
export type FlowSupportedTokensQueryResult = Apollo.QueryResult<FlowSupportedTokensQuery, FlowSupportedTokensQueryVariables>;
export const CreateFlowExecutionDocument = gql`
    mutation createFlowExecution($input: CreateFlowExecutionInput!) {
  createFlowExecution(input: $input) {
    id
  }
}
    `;
export type CreateFlowExecutionMutationFn = Apollo.MutationFunction<CreateFlowExecutionMutation, CreateFlowExecutionMutationVariables>;

/**
 * __useCreateFlowExecutionMutation__
 *
 * To run a mutation, you first call `useCreateFlowExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlowExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlowExecutionMutation, { data, loading, error }] = useCreateFlowExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFlowExecutionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFlowExecutionMutation, CreateFlowExecutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFlowExecutionMutation, CreateFlowExecutionMutationVariables>(CreateFlowExecutionDocument, options);
      }
export type CreateFlowExecutionMutationHookResult = ReturnType<typeof useCreateFlowExecutionMutation>;
export type CreateFlowExecutionMutationResult = Apollo.MutationResult<CreateFlowExecutionMutation>;
export type CreateFlowExecutionMutationOptions = Apollo.BaseMutationOptions<CreateFlowExecutionMutation, CreateFlowExecutionMutationVariables>;
export const UpsertInjectionDocument = gql`
    mutation upsertInjection($input: UpsertInjectionInput!) {
  upsertInjection(input: $input) {
    id
  }
}
    `;
export type UpsertInjectionMutationFn = Apollo.MutationFunction<UpsertInjectionMutation, UpsertInjectionMutationVariables>;

/**
 * __useUpsertInjectionMutation__
 *
 * To run a mutation, you first call `useUpsertInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInjectionMutation, { data, loading, error }] = useUpsertInjectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertInjectionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertInjectionMutation, UpsertInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertInjectionMutation, UpsertInjectionMutationVariables>(UpsertInjectionDocument, options);
      }
export type UpsertInjectionMutationHookResult = ReturnType<typeof useUpsertInjectionMutation>;
export type UpsertInjectionMutationResult = Apollo.MutationResult<UpsertInjectionMutation>;
export type UpsertInjectionMutationOptions = Apollo.BaseMutationOptions<UpsertInjectionMutation, UpsertInjectionMutationVariables>;
export const ClientsAutocompleteSearchDocument = gql`
    query clientsAutocompleteSearch($where: ClientsFilter) {
  clients(pageSize: 10, where: $where) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useClientsAutocompleteSearchQuery__
 *
 * To run a query within a React component, call `useClientsAutocompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsAutocompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsAutocompleteSearchQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientsAutocompleteSearchQuery(baseOptions?: Apollo.QueryHookOptions<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>(ClientsAutocompleteSearchDocument, options);
      }
export function useClientsAutocompleteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>(ClientsAutocompleteSearchDocument, options);
        }
export function useClientsAutocompleteSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>(ClientsAutocompleteSearchDocument, options);
        }
export type ClientsAutocompleteSearchQueryHookResult = ReturnType<typeof useClientsAutocompleteSearchQuery>;
export type ClientsAutocompleteSearchLazyQueryHookResult = ReturnType<typeof useClientsAutocompleteSearchLazyQuery>;
export type ClientsAutocompleteSearchSuspenseQueryHookResult = ReturnType<typeof useClientsAutocompleteSearchSuspenseQuery>;
export type ClientsAutocompleteSearchQueryResult = Apollo.QueryResult<ClientsAutocompleteSearchQuery, ClientsAutocompleteSearchQueryVariables>;
export const CollateralAutocompleteSearchDocument = gql`
    query collateralAutocompleteSearch {
  collaterals {
    id
    symbol
  }
}
    `;

/**
 * __useCollateralAutocompleteSearchQuery__
 *
 * To run a query within a React component, call `useCollateralAutocompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollateralAutocompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollateralAutocompleteSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollateralAutocompleteSearchQuery(baseOptions?: Apollo.QueryHookOptions<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>(CollateralAutocompleteSearchDocument, options);
      }
export function useCollateralAutocompleteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>(CollateralAutocompleteSearchDocument, options);
        }
export function useCollateralAutocompleteSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>(CollateralAutocompleteSearchDocument, options);
        }
export type CollateralAutocompleteSearchQueryHookResult = ReturnType<typeof useCollateralAutocompleteSearchQuery>;
export type CollateralAutocompleteSearchLazyQueryHookResult = ReturnType<typeof useCollateralAutocompleteSearchLazyQuery>;
export type CollateralAutocompleteSearchSuspenseQueryHookResult = ReturnType<typeof useCollateralAutocompleteSearchSuspenseQuery>;
export type CollateralAutocompleteSearchQueryResult = Apollo.QueryResult<CollateralAutocompleteSearchQuery, CollateralAutocompleteSearchQueryVariables>;
export const InjectIncomingTransactionDefinitionDocument = gql`
    query injectIncomingTransactionDefinition($input: InteractionTransactionInjectDefinitionInput!) {
  injectionTransactionDefinitionQuery(input: $input) {
    index
    hash
    network
    contractAddress
    benefactorAddress
    beneficiaryAddress
    blockTimestamp
    decimals
    amount
    symbol
    client {
      id
      name
    }
  }
}
    `;

/**
 * __useInjectIncomingTransactionDefinitionQuery__
 *
 * To run a query within a React component, call `useInjectIncomingTransactionDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInjectIncomingTransactionDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInjectIncomingTransactionDefinitionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInjectIncomingTransactionDefinitionQuery(baseOptions: Apollo.QueryHookOptions<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables> & ({ variables: InjectIncomingTransactionDefinitionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>(InjectIncomingTransactionDefinitionDocument, options);
      }
export function useInjectIncomingTransactionDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>(InjectIncomingTransactionDefinitionDocument, options);
        }
export function useInjectIncomingTransactionDefinitionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>(InjectIncomingTransactionDefinitionDocument, options);
        }
export type InjectIncomingTransactionDefinitionQueryHookResult = ReturnType<typeof useInjectIncomingTransactionDefinitionQuery>;
export type InjectIncomingTransactionDefinitionLazyQueryHookResult = ReturnType<typeof useInjectIncomingTransactionDefinitionLazyQuery>;
export type InjectIncomingTransactionDefinitionSuspenseQueryHookResult = ReturnType<typeof useInjectIncomingTransactionDefinitionSuspenseQuery>;
export type InjectIncomingTransactionDefinitionQueryResult = Apollo.QueryResult<InjectIncomingTransactionDefinitionQuery, InjectIncomingTransactionDefinitionQueryVariables>;
export const InjectionDetailsDocument = gql`
    query injectionDetails($injectionId: String!) {
  injection(id: $injectionId) {
    id
    status
    payload
  }
}
    `;

/**
 * __useInjectionDetailsQuery__
 *
 * To run a query within a React component, call `useInjectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInjectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInjectionDetailsQuery({
 *   variables: {
 *      injectionId: // value for 'injectionId'
 *   },
 * });
 */
export function useInjectionDetailsQuery(baseOptions: Apollo.QueryHookOptions<InjectionDetailsQuery, InjectionDetailsQueryVariables> & ({ variables: InjectionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InjectionDetailsQuery, InjectionDetailsQueryVariables>(InjectionDetailsDocument, options);
      }
export function useInjectionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InjectionDetailsQuery, InjectionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InjectionDetailsQuery, InjectionDetailsQueryVariables>(InjectionDetailsDocument, options);
        }
export function useInjectionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InjectionDetailsQuery, InjectionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InjectionDetailsQuery, InjectionDetailsQueryVariables>(InjectionDetailsDocument, options);
        }
export type InjectionDetailsQueryHookResult = ReturnType<typeof useInjectionDetailsQuery>;
export type InjectionDetailsLazyQueryHookResult = ReturnType<typeof useInjectionDetailsLazyQuery>;
export type InjectionDetailsSuspenseQueryHookResult = ReturnType<typeof useInjectionDetailsSuspenseQuery>;
export type InjectionDetailsQueryResult = Apollo.QueryResult<InjectionDetailsQuery, InjectionDetailsQueryVariables>;
export const InjectionClientDocument = gql`
    query InjectionClient($clientId: ID!) {
  client(clientId: $clientId) {
    id
    humanId
    name
    jurisdiction
    contactEmail
  }
}
    `;

/**
 * __useInjectionClientQuery__
 *
 * To run a query within a React component, call `useInjectionClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useInjectionClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInjectionClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useInjectionClientQuery(baseOptions: Apollo.QueryHookOptions<InjectionClientQuery, InjectionClientQueryVariables> & ({ variables: InjectionClientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InjectionClientQuery, InjectionClientQueryVariables>(InjectionClientDocument, options);
      }
export function useInjectionClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InjectionClientQuery, InjectionClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InjectionClientQuery, InjectionClientQueryVariables>(InjectionClientDocument, options);
        }
export function useInjectionClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InjectionClientQuery, InjectionClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InjectionClientQuery, InjectionClientQueryVariables>(InjectionClientDocument, options);
        }
export type InjectionClientQueryHookResult = ReturnType<typeof useInjectionClientQuery>;
export type InjectionClientLazyQueryHookResult = ReturnType<typeof useInjectionClientLazyQuery>;
export type InjectionClientSuspenseQueryHookResult = ReturnType<typeof useInjectionClientSuspenseQuery>;
export type InjectionClientQueryResult = Apollo.QueryResult<InjectionClientQuery, InjectionClientQueryVariables>;
export const ExecuteInjectionDocument = gql`
    mutation executeInjection($input: ExecuteInjectionInput!) {
  executeInjection(input: $input) {
    id
    interaction {
      id
    }
  }
}
    `;
export type ExecuteInjectionMutationFn = Apollo.MutationFunction<ExecuteInjectionMutation, ExecuteInjectionMutationVariables>;

/**
 * __useExecuteInjectionMutation__
 *
 * To run a mutation, you first call `useExecuteInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeInjectionMutation, { data, loading, error }] = useExecuteInjectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteInjectionMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteInjectionMutation, ExecuteInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteInjectionMutation, ExecuteInjectionMutationVariables>(ExecuteInjectionDocument, options);
      }
export type ExecuteInjectionMutationHookResult = ReturnType<typeof useExecuteInjectionMutation>;
export type ExecuteInjectionMutationResult = Apollo.MutationResult<ExecuteInjectionMutation>;
export type ExecuteInjectionMutationOptions = Apollo.BaseMutationOptions<ExecuteInjectionMutation, ExecuteInjectionMutationVariables>;
export const InteractionStatusDocument = gql`
    query interactionStatus($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    status
  }
}
    `;

/**
 * __useInteractionStatusQuery__
 *
 * To run a query within a React component, call `useInteractionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionStatusQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionStatusQuery(baseOptions: Apollo.QueryHookOptions<InteractionStatusQuery, InteractionStatusQueryVariables> & ({ variables: InteractionStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionStatusQuery, InteractionStatusQueryVariables>(InteractionStatusDocument, options);
      }
export function useInteractionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionStatusQuery, InteractionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionStatusQuery, InteractionStatusQueryVariables>(InteractionStatusDocument, options);
        }
export function useInteractionStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionStatusQuery, InteractionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionStatusQuery, InteractionStatusQueryVariables>(InteractionStatusDocument, options);
        }
export type InteractionStatusQueryHookResult = ReturnType<typeof useInteractionStatusQuery>;
export type InteractionStatusLazyQueryHookResult = ReturnType<typeof useInteractionStatusLazyQuery>;
export type InteractionStatusSuspenseQueryHookResult = ReturnType<typeof useInteractionStatusSuspenseQuery>;
export type InteractionStatusQueryResult = Apollo.QueryResult<InteractionStatusQuery, InteractionStatusQueryVariables>;
export const InteractionDetailsInvoiceDocument = gql`
    query interactionDetailsInvoice($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    id
    fee {
      id
      fee
      principal
      currency
      invoice {
        id
        isPaid
        invoiceNumber
        downloadUrl
      }
      internalTransaction {
        hash
        explorerUrl
        network
        status
      }
    }
  }
}
    `;

/**
 * __useInteractionDetailsInvoiceQuery__
 *
 * To run a query within a React component, call `useInteractionDetailsInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionDetailsInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionDetailsInvoiceQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionDetailsInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables> & ({ variables: InteractionDetailsInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>(InteractionDetailsInvoiceDocument, options);
      }
export function useInteractionDetailsInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>(InteractionDetailsInvoiceDocument, options);
        }
export function useInteractionDetailsInvoiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>(InteractionDetailsInvoiceDocument, options);
        }
export type InteractionDetailsInvoiceQueryHookResult = ReturnType<typeof useInteractionDetailsInvoiceQuery>;
export type InteractionDetailsInvoiceLazyQueryHookResult = ReturnType<typeof useInteractionDetailsInvoiceLazyQuery>;
export type InteractionDetailsInvoiceSuspenseQueryHookResult = ReturnType<typeof useInteractionDetailsInvoiceSuspenseQuery>;
export type InteractionDetailsInvoiceQueryResult = Apollo.QueryResult<InteractionDetailsInvoiceQuery, InteractionDetailsInvoiceQueryVariables>;
export const RegenerateInvoiceDocument = gql`
    mutation regenerateInvoice($invoiceId: String!) {
  generateInvoicePDF(input: {invoiceId: $invoiceId}) {
    id
  }
}
    `;
export type RegenerateInvoiceMutationFn = Apollo.MutationFunction<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>;

/**
 * __useRegenerateInvoiceMutation__
 *
 * To run a mutation, you first call `useRegenerateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateInvoiceMutation, { data, loading, error }] = useRegenerateInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useRegenerateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>(RegenerateInvoiceDocument, options);
      }
export type RegenerateInvoiceMutationHookResult = ReturnType<typeof useRegenerateInvoiceMutation>;
export type RegenerateInvoiceMutationResult = Apollo.MutationResult<RegenerateInvoiceMutation>;
export type RegenerateInvoiceMutationOptions = Apollo.BaseMutationOptions<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>;
export const InteractionDetailsOrdersDocument = gql`
    query interactionDetailsOrders($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    id
    orders {
      id
      totalValue
      totalShares
      pricePerShare
      collateral {
        symbol
        priceCurrency
      }
      side
      status
    }
  }
}
    `;

/**
 * __useInteractionDetailsOrdersQuery__
 *
 * To run a query within a React component, call `useInteractionDetailsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionDetailsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionDetailsOrdersQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionDetailsOrdersQuery(baseOptions: Apollo.QueryHookOptions<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables> & ({ variables: InteractionDetailsOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>(InteractionDetailsOrdersDocument, options);
      }
export function useInteractionDetailsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>(InteractionDetailsOrdersDocument, options);
        }
export function useInteractionDetailsOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>(InteractionDetailsOrdersDocument, options);
        }
export type InteractionDetailsOrdersQueryHookResult = ReturnType<typeof useInteractionDetailsOrdersQuery>;
export type InteractionDetailsOrdersLazyQueryHookResult = ReturnType<typeof useInteractionDetailsOrdersLazyQuery>;
export type InteractionDetailsOrdersSuspenseQueryHookResult = ReturnType<typeof useInteractionDetailsOrdersSuspenseQuery>;
export type InteractionDetailsOrdersQueryResult = Apollo.QueryResult<InteractionDetailsOrdersQuery, InteractionDetailsOrdersQueryVariables>;
export const InteractionDetailsOverviewDocument = gql`
    query interactionDetailsOverview($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    id
    createdAt
    updatedAt
    type
    status
    failureReason
    flags
    isInjected
    currency
    actualTotalValue
    expectedTotalValue
    fee {
      id
      fee
      principal
    }
    client {
      id
      humanId
      name
      jurisdiction
      isProfessional
      contactEmail
      classification
      usage {
        issuanceDailyUsage
        issuanceMonthlyUsage
        redemptionDailyUsage
        redemptionMonthlyUsage
      }
    }
    events {
      id
      createdAt
      type
      payload
    }
  }
}
    `;

/**
 * __useInteractionDetailsOverviewQuery__
 *
 * To run a query within a React component, call `useInteractionDetailsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionDetailsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionDetailsOverviewQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionDetailsOverviewQuery(baseOptions: Apollo.QueryHookOptions<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables> & ({ variables: InteractionDetailsOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>(InteractionDetailsOverviewDocument, options);
      }
export function useInteractionDetailsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>(InteractionDetailsOverviewDocument, options);
        }
export function useInteractionDetailsOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>(InteractionDetailsOverviewDocument, options);
        }
export type InteractionDetailsOverviewQueryHookResult = ReturnType<typeof useInteractionDetailsOverviewQuery>;
export type InteractionDetailsOverviewLazyQueryHookResult = ReturnType<typeof useInteractionDetailsOverviewLazyQuery>;
export type InteractionDetailsOverviewSuspenseQueryHookResult = ReturnType<typeof useInteractionDetailsOverviewSuspenseQuery>;
export type InteractionDetailsOverviewQueryResult = Apollo.QueryResult<InteractionDetailsOverviewQuery, InteractionDetailsOverviewQueryVariables>;
export const InteractionDetailsLayoutDocument = gql`
    query interactionDetailsLayout($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    id
    createdAt
    updatedAt
    type
    status
    flags
  }
}
    `;

/**
 * __useInteractionDetailsLayoutQuery__
 *
 * To run a query within a React component, call `useInteractionDetailsLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionDetailsLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionDetailsLayoutQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionDetailsLayoutQuery(baseOptions: Apollo.QueryHookOptions<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables> & ({ variables: InteractionDetailsLayoutQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>(InteractionDetailsLayoutDocument, options);
      }
export function useInteractionDetailsLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>(InteractionDetailsLayoutDocument, options);
        }
export function useInteractionDetailsLayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>(InteractionDetailsLayoutDocument, options);
        }
export type InteractionDetailsLayoutQueryHookResult = ReturnType<typeof useInteractionDetailsLayoutQuery>;
export type InteractionDetailsLayoutLazyQueryHookResult = ReturnType<typeof useInteractionDetailsLayoutLazyQuery>;
export type InteractionDetailsLayoutSuspenseQueryHookResult = ReturnType<typeof useInteractionDetailsLayoutSuspenseQuery>;
export type InteractionDetailsLayoutQueryResult = Apollo.QueryResult<InteractionDetailsLayoutQuery, InteractionDetailsLayoutQueryVariables>;
export const CreateRefundDocument = gql`
    mutation createRefund($input: CreateRefundInput!) {
  createRefund(input: $input) {
    id
  }
}
    `;
export type CreateRefundMutationFn = Apollo.MutationFunction<CreateRefundMutation, CreateRefundMutationVariables>;

/**
 * __useCreateRefundMutation__
 *
 * To run a mutation, you first call `useCreateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefundMutation, { data, loading, error }] = useCreateRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRefundMutation(baseOptions?: Apollo.MutationHookOptions<CreateRefundMutation, CreateRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRefundMutation, CreateRefundMutationVariables>(CreateRefundDocument, options);
      }
export type CreateRefundMutationHookResult = ReturnType<typeof useCreateRefundMutation>;
export type CreateRefundMutationResult = Apollo.MutationResult<CreateRefundMutation>;
export type CreateRefundMutationOptions = Apollo.BaseMutationOptions<CreateRefundMutation, CreateRefundMutationVariables>;
export const UpdateInteractionProductsDocument = gql`
    mutation updateInteractionProducts($interactionId: String!) {
  updateInteraction(where: {id: $interactionId}, data: {updateProducts: true}) {
    id
  }
}
    `;
export type UpdateInteractionProductsMutationFn = Apollo.MutationFunction<UpdateInteractionProductsMutation, UpdateInteractionProductsMutationVariables>;

/**
 * __useUpdateInteractionProductsMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionProductsMutation, { data, loading, error }] = useUpdateInteractionProductsMutation({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useUpdateInteractionProductsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInteractionProductsMutation, UpdateInteractionProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInteractionProductsMutation, UpdateInteractionProductsMutationVariables>(UpdateInteractionProductsDocument, options);
      }
export type UpdateInteractionProductsMutationHookResult = ReturnType<typeof useUpdateInteractionProductsMutation>;
export type UpdateInteractionProductsMutationResult = Apollo.MutationResult<UpdateInteractionProductsMutation>;
export type UpdateInteractionProductsMutationOptions = Apollo.BaseMutationOptions<UpdateInteractionProductsMutation, UpdateInteractionProductsMutationVariables>;
export const InteractionsMonitoringDocument = gql`
    query interactionsMonitoring($page: Int, $pageSize: Int) {
  interactions(page: $page, pageSize: $pageSize) {
    nodes {
      id
      createdAt
      type
      status
      currency
      flags
      automatedChecks
      actualTotalValue
      expectedTotalValue
      comments {
        comment
      }
      reports {
        id
        type
        comments {
          comment
        }
      }
      client {
        id
        name
        contactEmail
      }
      incomingTransactions {
        id
        hash
        network
        amount
        value
        decimals
        explorerUrl
        type
        score
        scoreDetails
        scoreProvider
        isHighRiskScore
        problemsChecked
        tokenSymbol
      }
      outgoingTransactions {
        id
        hash
        network
        explorerUrl
        createdAt
        tokenSymbol
        amount
        type
        displayAmount
        value
        decimals
      }
      fee {
        fee
        currency
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useInteractionsMonitoringQuery__
 *
 * To run a query within a React component, call `useInteractionsMonitoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionsMonitoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionsMonitoringQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useInteractionsMonitoringQuery(baseOptions?: Apollo.QueryHookOptions<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>(InteractionsMonitoringDocument, options);
      }
export function useInteractionsMonitoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>(InteractionsMonitoringDocument, options);
        }
export function useInteractionsMonitoringSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>(InteractionsMonitoringDocument, options);
        }
export type InteractionsMonitoringQueryHookResult = ReturnType<typeof useInteractionsMonitoringQuery>;
export type InteractionsMonitoringLazyQueryHookResult = ReturnType<typeof useInteractionsMonitoringLazyQuery>;
export type InteractionsMonitoringSuspenseQueryHookResult = ReturnType<typeof useInteractionsMonitoringSuspenseQuery>;
export type InteractionsMonitoringQueryResult = Apollo.QueryResult<InteractionsMonitoringQuery, InteractionsMonitoringQueryVariables>;
export const CreateInteractionReportDocument = gql`
    mutation createInteractionReport($input: CreateInteractionReportInput!) {
  createInteractionReport(input: $input) {
    id
  }
}
    `;
export type CreateInteractionReportMutationFn = Apollo.MutationFunction<CreateInteractionReportMutation, CreateInteractionReportMutationVariables>;

/**
 * __useCreateInteractionReportMutation__
 *
 * To run a mutation, you first call `useCreateInteractionReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInteractionReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInteractionReportMutation, { data, loading, error }] = useCreateInteractionReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInteractionReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateInteractionReportMutation, CreateInteractionReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInteractionReportMutation, CreateInteractionReportMutationVariables>(CreateInteractionReportDocument, options);
      }
export type CreateInteractionReportMutationHookResult = ReturnType<typeof useCreateInteractionReportMutation>;
export type CreateInteractionReportMutationResult = Apollo.MutationResult<CreateInteractionReportMutation>;
export type CreateInteractionReportMutationOptions = Apollo.BaseMutationOptions<CreateInteractionReportMutation, CreateInteractionReportMutationVariables>;
export const RemoveInteractionReportDocument = gql`
    mutation removeInteractionReport($input: RemoveInteractionReportInput!) {
  removeInteractionReport(input: $input)
}
    `;
export type RemoveInteractionReportMutationFn = Apollo.MutationFunction<RemoveInteractionReportMutation, RemoveInteractionReportMutationVariables>;

/**
 * __useRemoveInteractionReportMutation__
 *
 * To run a mutation, you first call `useRemoveInteractionReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInteractionReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInteractionReportMutation, { data, loading, error }] = useRemoveInteractionReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveInteractionReportMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInteractionReportMutation, RemoveInteractionReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInteractionReportMutation, RemoveInteractionReportMutationVariables>(RemoveInteractionReportDocument, options);
      }
export type RemoveInteractionReportMutationHookResult = ReturnType<typeof useRemoveInteractionReportMutation>;
export type RemoveInteractionReportMutationResult = Apollo.MutationResult<RemoveInteractionReportMutation>;
export type RemoveInteractionReportMutationOptions = Apollo.BaseMutationOptions<RemoveInteractionReportMutation, RemoveInteractionReportMutationVariables>;
export const UpdateInteractionDocument = gql`
    mutation updateInteraction($data: UpdateInteractionDataInput!, $where: InteractionWhereUniqueInput!) {
  updateInteraction(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateInteractionMutationFn = Apollo.MutationFunction<UpdateInteractionMutation, UpdateInteractionMutationVariables>;

/**
 * __useUpdateInteractionMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionMutation, { data, loading, error }] = useUpdateInteractionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateInteractionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInteractionMutation, UpdateInteractionMutationVariables>(UpdateInteractionDocument, options);
      }
export type UpdateInteractionMutationHookResult = ReturnType<typeof useUpdateInteractionMutation>;
export type UpdateInteractionMutationResult = Apollo.MutationResult<UpdateInteractionMutation>;
export type UpdateInteractionMutationOptions = Apollo.BaseMutationOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>;
export const RefundsListDocument = gql`
    query refundsList($page: Int, $pageSize: Int) {
  refunds(page: $page, pageSize: $pageSize) {
    nodes {
      id
      status
      reason
      failureReason
      creationReason
      refundDefinition
      incomingTransactions {
        decimals
        tokenSymbol
      }
      client {
        id
        name
        isProfessional
      }
    }
    page {
      totalNodes
    }
  }
}
    `;

/**
 * __useRefundsListQuery__
 *
 * To run a query within a React component, call `useRefundsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useRefundsListQuery(baseOptions?: Apollo.QueryHookOptions<RefundsListQuery, RefundsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefundsListQuery, RefundsListQueryVariables>(RefundsListDocument, options);
      }
export function useRefundsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefundsListQuery, RefundsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefundsListQuery, RefundsListQueryVariables>(RefundsListDocument, options);
        }
export function useRefundsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RefundsListQuery, RefundsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefundsListQuery, RefundsListQueryVariables>(RefundsListDocument, options);
        }
export type RefundsListQueryHookResult = ReturnType<typeof useRefundsListQuery>;
export type RefundsListLazyQueryHookResult = ReturnType<typeof useRefundsListLazyQuery>;
export type RefundsListSuspenseQueryHookResult = ReturnType<typeof useRefundsListSuspenseQuery>;
export type RefundsListQueryResult = Apollo.QueryResult<RefundsListQuery, RefundsListQueryVariables>;
export const RefundDetailsDocument = gql`
    query refundDetails($refundId: String!) {
  refund(where: {refundId: $refundId}) {
    id
    status
    reason
    creationReason
    refundDefinition
    incomingTransactions {
      id
      hash
      benefactorAddress
      beneficiaryAddress
      amount
      network
      problems
      tokenSymbol
      decimals
      displayAmount
      contractAddress
    }
    client {
      id
      name
      frozenUntil
      verificationProfile {
        riskLevel
        currentTier
      }
    }
  }
}
    `;

/**
 * __useRefundDetailsQuery__
 *
 * To run a query within a React component, call `useRefundDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundDetailsQuery({
 *   variables: {
 *      refundId: // value for 'refundId'
 *   },
 * });
 */
export function useRefundDetailsQuery(baseOptions: Apollo.QueryHookOptions<RefundDetailsQuery, RefundDetailsQueryVariables> & ({ variables: RefundDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefundDetailsQuery, RefundDetailsQueryVariables>(RefundDetailsDocument, options);
      }
export function useRefundDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefundDetailsQuery, RefundDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefundDetailsQuery, RefundDetailsQueryVariables>(RefundDetailsDocument, options);
        }
export function useRefundDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RefundDetailsQuery, RefundDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefundDetailsQuery, RefundDetailsQueryVariables>(RefundDetailsDocument, options);
        }
export type RefundDetailsQueryHookResult = ReturnType<typeof useRefundDetailsQuery>;
export type RefundDetailsLazyQueryHookResult = ReturnType<typeof useRefundDetailsLazyQuery>;
export type RefundDetailsSuspenseQueryHookResult = ReturnType<typeof useRefundDetailsSuspenseQuery>;
export type RefundDetailsQueryResult = Apollo.QueryResult<RefundDetailsQuery, RefundDetailsQueryVariables>;
export const ExecuteRefundDocument = gql`
    mutation executeRefund($input: ExecuteRefundInput!) {
  executeRefund(input: $input)
}
    `;
export type ExecuteRefundMutationFn = Apollo.MutationFunction<ExecuteRefundMutation, ExecuteRefundMutationVariables>;

/**
 * __useExecuteRefundMutation__
 *
 * To run a mutation, you first call `useExecuteRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeRefundMutation, { data, loading, error }] = useExecuteRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteRefundMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteRefundMutation, ExecuteRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteRefundMutation, ExecuteRefundMutationVariables>(ExecuteRefundDocument, options);
      }
export type ExecuteRefundMutationHookResult = ReturnType<typeof useExecuteRefundMutation>;
export type ExecuteRefundMutationResult = Apollo.MutationResult<ExecuteRefundMutation>;
export type ExecuteRefundMutationOptions = Apollo.BaseMutationOptions<ExecuteRefundMutation, ExecuteRefundMutationVariables>;
export const InteractionsListDocument = gql`
    query interactionsList($page: Int, $pageSize: Int, $where: InteractionWhereInput) {
  interactions(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      type
      payout
      status
      currency
      flags
      actualTotalValue
      expectedTotalValue
      client {
        id
        name
        contactEmail
      }
      incomingTransactions {
        id
        hash
        network
        amount
        decimals
        explorerUrl
        type
        tokenSymbol
      }
      outgoingTransactions {
        id
        hash
        network
        explorerUrl
        createdAt
        tokenSymbol
        type
        amount
        decimals
      }
      incomingTransfers {
        ...InteractionBankTransferDetails
      }
      outgoingTransfers {
        ...InteractionBankTransferDetails
      }
    }
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${InteractionBankTransferDetailsFragmentDoc}`;

/**
 * __useInteractionsListQuery__
 *
 * To run a query within a React component, call `useInteractionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInteractionsListQuery(baseOptions?: Apollo.QueryHookOptions<InteractionsListQuery, InteractionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionsListQuery, InteractionsListQueryVariables>(InteractionsListDocument, options);
      }
export function useInteractionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionsListQuery, InteractionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionsListQuery, InteractionsListQueryVariables>(InteractionsListDocument, options);
        }
export function useInteractionsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InteractionsListQuery, InteractionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InteractionsListQuery, InteractionsListQueryVariables>(InteractionsListDocument, options);
        }
export type InteractionsListQueryHookResult = ReturnType<typeof useInteractionsListQuery>;
export type InteractionsListLazyQueryHookResult = ReturnType<typeof useInteractionsListLazyQuery>;
export type InteractionsListSuspenseQueryHookResult = ReturnType<typeof useInteractionsListSuspenseQuery>;
export type InteractionsListQueryResult = Apollo.QueryResult<InteractionsListQuery, InteractionsListQueryVariables>;
export const CompanyAdminPermissionsDocument = gql`
    query CompanyAdminPermissions($companyId: String!) {
  company(where: {id: $companyId}) {
    adminPermissions
  }
}
    `;

/**
 * __useCompanyAdminPermissionsQuery__
 *
 * To run a query within a React component, call `useCompanyAdminPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAdminPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAdminPermissionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyAdminPermissionsQuery(baseOptions: Apollo.QueryHookOptions<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables> & ({ variables: CompanyAdminPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>(CompanyAdminPermissionsDocument, options);
      }
export function useCompanyAdminPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>(CompanyAdminPermissionsDocument, options);
        }
export function useCompanyAdminPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>(CompanyAdminPermissionsDocument, options);
        }
export type CompanyAdminPermissionsQueryHookResult = ReturnType<typeof useCompanyAdminPermissionsQuery>;
export type CompanyAdminPermissionsLazyQueryHookResult = ReturnType<typeof useCompanyAdminPermissionsLazyQuery>;
export type CompanyAdminPermissionsSuspenseQueryHookResult = ReturnType<typeof useCompanyAdminPermissionsSuspenseQuery>;
export type CompanyAdminPermissionsQueryResult = Apollo.QueryResult<CompanyAdminPermissionsQuery, CompanyAdminPermissionsQueryVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($input: CreateAdminUserInput!) {
  createAdmin(input: $input) {
    id
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($input: UpdateAdminInput!) {
  updateAdmin(input: $input) {
    id
  }
}
    `;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const DisableAdminDocument = gql`
    mutation DisableAdmin($input: DisableAdminInput!) {
  disableAdmin(input: $input) {
    id
  }
}
    `;
export type DisableAdminMutationFn = Apollo.MutationFunction<DisableAdminMutation, DisableAdminMutationVariables>;

/**
 * __useDisableAdminMutation__
 *
 * To run a mutation, you first call `useDisableAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAdminMutation, { data, loading, error }] = useDisableAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableAdminMutation(baseOptions?: Apollo.MutationHookOptions<DisableAdminMutation, DisableAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAdminMutation, DisableAdminMutationVariables>(DisableAdminDocument, options);
      }
export type DisableAdminMutationHookResult = ReturnType<typeof useDisableAdminMutation>;
export type DisableAdminMutationResult = Apollo.MutationResult<DisableAdminMutation>;
export type DisableAdminMutationOptions = Apollo.BaseMutationOptions<DisableAdminMutation, DisableAdminMutationVariables>;
export const UpdateServiceAccountDocument = gql`
    mutation UpdateServiceAccount($input: UpdateServiceAccountInput!) {
  updateServiceAccount(input: $input) {
    id
  }
}
    `;
export type UpdateServiceAccountMutationFn = Apollo.MutationFunction<UpdateServiceAccountMutation, UpdateServiceAccountMutationVariables>;

/**
 * __useUpdateServiceAccountMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAccountMutation, { data, loading, error }] = useUpdateServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceAccountMutation, UpdateServiceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceAccountMutation, UpdateServiceAccountMutationVariables>(UpdateServiceAccountDocument, options);
      }
export type UpdateServiceAccountMutationHookResult = ReturnType<typeof useUpdateServiceAccountMutation>;
export type UpdateServiceAccountMutationResult = Apollo.MutationResult<UpdateServiceAccountMutation>;
export type UpdateServiceAccountMutationOptions = Apollo.BaseMutationOptions<UpdateServiceAccountMutation, UpdateServiceAccountMutationVariables>;
export const CreateAdminServiceAccountDocument = gql`
    mutation createAdminServiceAccount($input: CreateServiceAccountInput!) {
  createServiceAccount(input: $input) {
    key
    secret
  }
}
    `;
export type CreateAdminServiceAccountMutationFn = Apollo.MutationFunction<CreateAdminServiceAccountMutation, CreateAdminServiceAccountMutationVariables>;

/**
 * __useCreateAdminServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateAdminServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminServiceAccountMutation, { data, loading, error }] = useCreateAdminServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminServiceAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminServiceAccountMutation, CreateAdminServiceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminServiceAccountMutation, CreateAdminServiceAccountMutationVariables>(CreateAdminServiceAccountDocument, options);
      }
export type CreateAdminServiceAccountMutationHookResult = ReturnType<typeof useCreateAdminServiceAccountMutation>;
export type CreateAdminServiceAccountMutationResult = Apollo.MutationResult<CreateAdminServiceAccountMutation>;
export type CreateAdminServiceAccountMutationOptions = Apollo.BaseMutationOptions<CreateAdminServiceAccountMutation, CreateAdminServiceAccountMutationVariables>;
export const DisableServiceAccountDocument = gql`
    mutation disableServiceAccount($input: DisableServiceAccountMutationInput!) {
  disableServiceAccount(input: $input)
}
    `;
export type DisableServiceAccountMutationFn = Apollo.MutationFunction<DisableServiceAccountMutation, DisableServiceAccountMutationVariables>;

/**
 * __useDisableServiceAccountMutation__
 *
 * To run a mutation, you first call `useDisableServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableServiceAccountMutation, { data, loading, error }] = useDisableServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableServiceAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisableServiceAccountMutation, DisableServiceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableServiceAccountMutation, DisableServiceAccountMutationVariables>(DisableServiceAccountDocument, options);
      }
export type DisableServiceAccountMutationHookResult = ReturnType<typeof useDisableServiceAccountMutation>;
export type DisableServiceAccountMutationResult = Apollo.MutationResult<DisableServiceAccountMutation>;
export type DisableServiceAccountMutationOptions = Apollo.BaseMutationOptions<DisableServiceAccountMutation, DisableServiceAccountMutationVariables>;
export const ApiAccesesDocument = gql`
    query apiAcceses($page: Int, $pageSize: Int) {
  serviceAccounts(page: $page, pageSize: $pageSize) {
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      title
      status
      createdAt
      updatedAt
      permissions
    }
  }
}
    `;

/**
 * __useApiAccesesQuery__
 *
 * To run a query within a React component, call `useApiAccesesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAccesesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAccesesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useApiAccesesQuery(baseOptions?: Apollo.QueryHookOptions<ApiAccesesQuery, ApiAccesesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAccesesQuery, ApiAccesesQueryVariables>(ApiAccesesDocument, options);
      }
export function useApiAccesesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAccesesQuery, ApiAccesesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAccesesQuery, ApiAccesesQueryVariables>(ApiAccesesDocument, options);
        }
export function useApiAccesesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiAccesesQuery, ApiAccesesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiAccesesQuery, ApiAccesesQueryVariables>(ApiAccesesDocument, options);
        }
export type ApiAccesesQueryHookResult = ReturnType<typeof useApiAccesesQuery>;
export type ApiAccesesLazyQueryHookResult = ReturnType<typeof useApiAccesesLazyQuery>;
export type ApiAccesesSuspenseQueryHookResult = ReturnType<typeof useApiAccesesSuspenseQuery>;
export type ApiAccesesQueryResult = Apollo.QueryResult<ApiAccesesQuery, ApiAccesesQueryVariables>;
export const EventDetailsDocument = gql`
    query eventDetails($where: EventWhereUniqueInput!) {
  event(where: $where) {
    id
    createdAt
    type
    payload
    status
    failures
    retryAttempts {
      id
      createdAt
      comment
      forFailures
    }
  }
}
    `;

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables> & ({ variables: EventDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
      }
export function useEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
        }
export function useEventDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
        }
export type EventDetailsQueryHookResult = ReturnType<typeof useEventDetailsQuery>;
export type EventDetailsLazyQueryHookResult = ReturnType<typeof useEventDetailsLazyQuery>;
export type EventDetailsSuspenseQueryHookResult = ReturnType<typeof useEventDetailsSuspenseQuery>;
export type EventDetailsQueryResult = Apollo.QueryResult<EventDetailsQuery, EventDetailsQueryVariables>;
export const EventsListQueryDocument = gql`
    query eventsListQuery($page: Int, $pageSize: Int, $where: EventWhereInput) {
  events(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      type
      status
    }
    page {
      currentPage
      totalPages
      totalNodes
    }
  }
}
    `;

/**
 * __useEventsListQueryQuery__
 *
 * To run a query within a React component, call `useEventsListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventsListQueryQuery(baseOptions?: Apollo.QueryHookOptions<EventsListQueryQuery, EventsListQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsListQueryQuery, EventsListQueryQueryVariables>(EventsListQueryDocument, options);
      }
export function useEventsListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsListQueryQuery, EventsListQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsListQueryQuery, EventsListQueryQueryVariables>(EventsListQueryDocument, options);
        }
export function useEventsListQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsListQueryQuery, EventsListQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsListQueryQuery, EventsListQueryQueryVariables>(EventsListQueryDocument, options);
        }
export type EventsListQueryQueryHookResult = ReturnType<typeof useEventsListQueryQuery>;
export type EventsListQueryLazyQueryHookResult = ReturnType<typeof useEventsListQueryLazyQuery>;
export type EventsListQuerySuspenseQueryHookResult = ReturnType<typeof useEventsListQuerySuspenseQuery>;
export type EventsListQueryQueryResult = Apollo.QueryResult<EventsListQueryQuery, EventsListQueryQueryVariables>;
export const RetryEventDocument = gql`
    mutation retryEvent($input: RetryEventMutationInput!) {
  retryEvent(input: $input) {
    id
  }
}
    `;
export type RetryEventMutationFn = Apollo.MutationFunction<RetryEventMutation, RetryEventMutationVariables>;

/**
 * __useRetryEventMutation__
 *
 * To run a mutation, you first call `useRetryEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryEventMutation, { data, loading, error }] = useRetryEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryEventMutation(baseOptions?: Apollo.MutationHookOptions<RetryEventMutation, RetryEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryEventMutation, RetryEventMutationVariables>(RetryEventDocument, options);
      }
export type RetryEventMutationHookResult = ReturnType<typeof useRetryEventMutation>;
export type RetryEventMutationResult = Apollo.MutationResult<RetryEventMutation>;
export type RetryEventMutationOptions = Apollo.BaseMutationOptions<RetryEventMutation, RetryEventMutationVariables>;
export const FactoriesListDocument = gql`
    query factoriesList {
  factories {
    id
    address
    networks
    type
    ownership
    description
  }
}
    `;

/**
 * __useFactoriesListQuery__
 *
 * To run a query within a React component, call `useFactoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactoriesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFactoriesListQuery(baseOptions?: Apollo.QueryHookOptions<FactoriesListQuery, FactoriesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FactoriesListQuery, FactoriesListQueryVariables>(FactoriesListDocument, options);
      }
export function useFactoriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FactoriesListQuery, FactoriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FactoriesListQuery, FactoriesListQueryVariables>(FactoriesListDocument, options);
        }
export function useFactoriesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FactoriesListQuery, FactoriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FactoriesListQuery, FactoriesListQueryVariables>(FactoriesListDocument, options);
        }
export type FactoriesListQueryHookResult = ReturnType<typeof useFactoriesListQuery>;
export type FactoriesListLazyQueryHookResult = ReturnType<typeof useFactoriesListLazyQuery>;
export type FactoriesListSuspenseQueryHookResult = ReturnType<typeof useFactoriesListSuspenseQuery>;
export type FactoriesListQueryResult = Apollo.QueryResult<FactoriesListQuery, FactoriesListQueryVariables>;
export const RegisterFactoryDocument = gql`
    mutation registerFactory($input: RegisterContractFactoryInput!) {
  registerContractFactory(input: $input) {
    id
  }
}
    `;
export type RegisterFactoryMutationFn = Apollo.MutationFunction<RegisterFactoryMutation, RegisterFactoryMutationVariables>;

/**
 * __useRegisterFactoryMutation__
 *
 * To run a mutation, you first call `useRegisterFactoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFactoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFactoryMutation, { data, loading, error }] = useRegisterFactoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterFactoryMutation(baseOptions?: Apollo.MutationHookOptions<RegisterFactoryMutation, RegisterFactoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterFactoryMutation, RegisterFactoryMutationVariables>(RegisterFactoryDocument, options);
      }
export type RegisterFactoryMutationHookResult = ReturnType<typeof useRegisterFactoryMutation>;
export type RegisterFactoryMutationResult = Apollo.MutationResult<RegisterFactoryMutation>;
export type RegisterFactoryMutationOptions = Apollo.BaseMutationOptions<RegisterFactoryMutation, RegisterFactoryMutationVariables>;
export const FeesConfigurationDocument = gql`
    query feesConfiguration {
  tokens {
    id
    name
    symbol
    deployments {
      id
      network
      feeConfigurations {
        id
        tier
        notes
        issuanceInitialFee
        issuanceMinimumFee
        issuancePercentageFee
        redemptionInitialFee
        redemptionMinimumFee
        redemptionPercentageFee
      }
    }
  }
}
    `;

/**
 * __useFeesConfigurationQuery__
 *
 * To run a query within a React component, call `useFeesConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeesConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeesConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeesConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<FeesConfigurationQuery, FeesConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeesConfigurationQuery, FeesConfigurationQueryVariables>(FeesConfigurationDocument, options);
      }
export function useFeesConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeesConfigurationQuery, FeesConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeesConfigurationQuery, FeesConfigurationQueryVariables>(FeesConfigurationDocument, options);
        }
export function useFeesConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FeesConfigurationQuery, FeesConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeesConfigurationQuery, FeesConfigurationQueryVariables>(FeesConfigurationDocument, options);
        }
export type FeesConfigurationQueryHookResult = ReturnType<typeof useFeesConfigurationQuery>;
export type FeesConfigurationLazyQueryHookResult = ReturnType<typeof useFeesConfigurationLazyQuery>;
export type FeesConfigurationSuspenseQueryHookResult = ReturnType<typeof useFeesConfigurationSuspenseQuery>;
export type FeesConfigurationQueryResult = Apollo.QueryResult<FeesConfigurationQuery, FeesConfigurationQueryVariables>;
export const TokenDeploymentFeesDocument = gql`
    query tokenDeploymentFees($deploymentId: String!) {
  tokenDeployment(tokenDeploymentId: $deploymentId) {
    id
    network
    token {
      name
    }
    feeConfigurations {
      id
      tier
      notes
      issuanceInitialFee
      issuanceMinimumFee
      issuancePercentageFee
      redemptionInitialFee
      redemptionMinimumFee
      redemptionPercentageFee
    }
  }
}
    `;

/**
 * __useTokenDeploymentFeesQuery__
 *
 * To run a query within a React component, call `useTokenDeploymentFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDeploymentFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDeploymentFeesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useTokenDeploymentFeesQuery(baseOptions: Apollo.QueryHookOptions<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables> & ({ variables: TokenDeploymentFeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>(TokenDeploymentFeesDocument, options);
      }
export function useTokenDeploymentFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>(TokenDeploymentFeesDocument, options);
        }
export function useTokenDeploymentFeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>(TokenDeploymentFeesDocument, options);
        }
export type TokenDeploymentFeesQueryHookResult = ReturnType<typeof useTokenDeploymentFeesQuery>;
export type TokenDeploymentFeesLazyQueryHookResult = ReturnType<typeof useTokenDeploymentFeesLazyQuery>;
export type TokenDeploymentFeesSuspenseQueryHookResult = ReturnType<typeof useTokenDeploymentFeesSuspenseQuery>;
export type TokenDeploymentFeesQueryResult = Apollo.QueryResult<TokenDeploymentFeesQuery, TokenDeploymentFeesQueryVariables>;
export const UpsertFeeConfigurationDocument = gql`
    mutation upsertFeeConfiguration($input: UpsertFeeConfigurationInput!) {
  upsertFeeConfiguration(input: $input) {
    id
  }
}
    `;
export type UpsertFeeConfigurationMutationFn = Apollo.MutationFunction<UpsertFeeConfigurationMutation, UpsertFeeConfigurationMutationVariables>;

/**
 * __useUpsertFeeConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertFeeConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFeeConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFeeConfigurationMutation, { data, loading, error }] = useUpsertFeeConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertFeeConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFeeConfigurationMutation, UpsertFeeConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFeeConfigurationMutation, UpsertFeeConfigurationMutationVariables>(UpsertFeeConfigurationDocument, options);
      }
export type UpsertFeeConfigurationMutationHookResult = ReturnType<typeof useUpsertFeeConfigurationMutation>;
export type UpsertFeeConfigurationMutationResult = Apollo.MutationResult<UpsertFeeConfigurationMutation>;
export type UpsertFeeConfigurationMutationOptions = Apollo.BaseMutationOptions<UpsertFeeConfigurationMutation, UpsertFeeConfigurationMutationVariables>;
export const RoleDetailsDocument = gql`
    query roleDetails($where: RoleWhereUniqueInput!) {
  company {
    adminPermissions
  }
  role(where: $where) {
    id
    name
    description
    permissions
    admins {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useRoleDetailsQuery__
 *
 * To run a query within a React component, call `useRoleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRoleDetailsQuery(baseOptions: Apollo.QueryHookOptions<RoleDetailsQuery, RoleDetailsQueryVariables> & ({ variables: RoleDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleDetailsQuery, RoleDetailsQueryVariables>(RoleDetailsDocument, options);
      }
export function useRoleDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleDetailsQuery, RoleDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleDetailsQuery, RoleDetailsQueryVariables>(RoleDetailsDocument, options);
        }
export function useRoleDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RoleDetailsQuery, RoleDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RoleDetailsQuery, RoleDetailsQueryVariables>(RoleDetailsDocument, options);
        }
export type RoleDetailsQueryHookResult = ReturnType<typeof useRoleDetailsQuery>;
export type RoleDetailsLazyQueryHookResult = ReturnType<typeof useRoleDetailsLazyQuery>;
export type RoleDetailsSuspenseQueryHookResult = ReturnType<typeof useRoleDetailsSuspenseQuery>;
export type RoleDetailsQueryResult = Apollo.QueryResult<RoleDetailsQuery, RoleDetailsQueryVariables>;
export const UnassignRoleDocument = gql`
    mutation unassignRole($input: ToggleRoleInput!) {
  toggleRole(input: $input)
}
    `;
export type UnassignRoleMutationFn = Apollo.MutationFunction<UnassignRoleMutation, UnassignRoleMutationVariables>;

/**
 * __useUnassignRoleMutation__
 *
 * To run a mutation, you first call `useUnassignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignRoleMutation, { data, loading, error }] = useUnassignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignRoleMutation(baseOptions?: Apollo.MutationHookOptions<UnassignRoleMutation, UnassignRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignRoleMutation, UnassignRoleMutationVariables>(UnassignRoleDocument, options);
      }
export type UnassignRoleMutationHookResult = ReturnType<typeof useUnassignRoleMutation>;
export type UnassignRoleMutationResult = Apollo.MutationResult<UnassignRoleMutation>;
export type UnassignRoleMutationOptions = Apollo.BaseMutationOptions<UnassignRoleMutation, UnassignRoleMutationVariables>;
export const RolesListQueryDocument = gql`
    query rolesListQuery($page: Int, $pageSize: Int) {
  roles(page: $page, pageSize: $pageSize) {
    nodes {
      id
      name
      description
      type
      permissions
      admins {
        email
        firstName
        lastName
      }
    }
    page {
      currentPage
      totalPages
      totalNodes
    }
  }
}
    `;

/**
 * __useRolesListQueryQuery__
 *
 * To run a query within a React component, call `useRolesListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesListQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useRolesListQueryQuery(baseOptions?: Apollo.QueryHookOptions<RolesListQueryQuery, RolesListQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesListQueryQuery, RolesListQueryQueryVariables>(RolesListQueryDocument, options);
      }
export function useRolesListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesListQueryQuery, RolesListQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesListQueryQuery, RolesListQueryQueryVariables>(RolesListQueryDocument, options);
        }
export function useRolesListQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RolesListQueryQuery, RolesListQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesListQueryQuery, RolesListQueryQueryVariables>(RolesListQueryDocument, options);
        }
export type RolesListQueryQueryHookResult = ReturnType<typeof useRolesListQueryQuery>;
export type RolesListQueryLazyQueryHookResult = ReturnType<typeof useRolesListQueryLazyQuery>;
export type RolesListQuerySuspenseQueryHookResult = ReturnType<typeof useRolesListQuerySuspenseQuery>;
export type RolesListQueryQueryResult = Apollo.QueryResult<RolesListQueryQuery, RolesListQueryQueryVariables>;
export const AdminsForRoleDocument = gql`
    query adminsForRole($where: AdminsWhereInput) {
  admins(where: $where) {
    nodes {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useAdminsForRoleQuery__
 *
 * To run a query within a React component, call `useAdminsForRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsForRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsForRoleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminsForRoleQuery(baseOptions?: Apollo.QueryHookOptions<AdminsForRoleQuery, AdminsForRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsForRoleQuery, AdminsForRoleQueryVariables>(AdminsForRoleDocument, options);
      }
export function useAdminsForRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsForRoleQuery, AdminsForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsForRoleQuery, AdminsForRoleQueryVariables>(AdminsForRoleDocument, options);
        }
export function useAdminsForRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminsForRoleQuery, AdminsForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminsForRoleQuery, AdminsForRoleQueryVariables>(AdminsForRoleDocument, options);
        }
export type AdminsForRoleQueryHookResult = ReturnType<typeof useAdminsForRoleQuery>;
export type AdminsForRoleLazyQueryHookResult = ReturnType<typeof useAdminsForRoleLazyQuery>;
export type AdminsForRoleSuspenseQueryHookResult = ReturnType<typeof useAdminsForRoleSuspenseQuery>;
export type AdminsForRoleQueryResult = Apollo.QueryResult<AdminsForRoleQuery, AdminsForRoleQueryVariables>;
export const AssignRoleDocument = gql`
    mutation assignRole($input: ToggleRoleInput!) {
  toggleRole(input: $input)
}
    `;
export type AssignRoleMutationFn = Apollo.MutationFunction<AssignRoleMutation, AssignRoleMutationVariables>;

/**
 * __useAssignRoleMutation__
 *
 * To run a mutation, you first call `useAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoleMutation, { data, loading, error }] = useAssignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignRoleMutation, AssignRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRoleMutation, AssignRoleMutationVariables>(AssignRoleDocument, options);
      }
export type AssignRoleMutationHookResult = ReturnType<typeof useAssignRoleMutation>;
export type AssignRoleMutationResult = Apollo.MutationResult<AssignRoleMutation>;
export type AssignRoleMutationOptions = Apollo.BaseMutationOptions<AssignRoleMutation, AssignRoleMutationVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    id
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input)
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const SystemWalletsListDocument = gql`
    query systemWalletsList($where: GetSystemWalletsWhereInput!) {
  systemWallets(where: $where) {
    id
    address
    title
    description
    tokenBalances {
      token {
        symbol
      }
      balance {
        balance
        network
      }
    }
  }
}
    `;

/**
 * __useSystemWalletsListQuery__
 *
 * To run a query within a React component, call `useSystemWalletsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemWalletsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemWalletsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSystemWalletsListQuery(baseOptions: Apollo.QueryHookOptions<SystemWalletsListQuery, SystemWalletsListQueryVariables> & ({ variables: SystemWalletsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemWalletsListQuery, SystemWalletsListQueryVariables>(SystemWalletsListDocument, options);
      }
export function useSystemWalletsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemWalletsListQuery, SystemWalletsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemWalletsListQuery, SystemWalletsListQueryVariables>(SystemWalletsListDocument, options);
        }
export function useSystemWalletsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemWalletsListQuery, SystemWalletsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemWalletsListQuery, SystemWalletsListQueryVariables>(SystemWalletsListDocument, options);
        }
export type SystemWalletsListQueryHookResult = ReturnType<typeof useSystemWalletsListQuery>;
export type SystemWalletsListLazyQueryHookResult = ReturnType<typeof useSystemWalletsListLazyQuery>;
export type SystemWalletsListSuspenseQueryHookResult = ReturnType<typeof useSystemWalletsListSuspenseQuery>;
export type SystemWalletsListQueryResult = Apollo.QueryResult<SystemWalletsListQuery, SystemWalletsListQueryVariables>;
export const GenerateSystemWalletDocument = gql`
    mutation generateSystemWallet {
  createSystemWallet {
    id
  }
}
    `;
export type GenerateSystemWalletMutationFn = Apollo.MutationFunction<GenerateSystemWalletMutation, GenerateSystemWalletMutationVariables>;

/**
 * __useGenerateSystemWalletMutation__
 *
 * To run a mutation, you first call `useGenerateSystemWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSystemWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSystemWalletMutation, { data, loading, error }] = useGenerateSystemWalletMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSystemWalletMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSystemWalletMutation, GenerateSystemWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSystemWalletMutation, GenerateSystemWalletMutationVariables>(GenerateSystemWalletDocument, options);
      }
export type GenerateSystemWalletMutationHookResult = ReturnType<typeof useGenerateSystemWalletMutation>;
export type GenerateSystemWalletMutationResult = Apollo.MutationResult<GenerateSystemWalletMutation>;
export type GenerateSystemWalletMutationOptions = Apollo.BaseMutationOptions<GenerateSystemWalletMutation, GenerateSystemWalletMutationVariables>;
export const DeployTokenDocument = gql`
    mutation deployToken($token: CreateTokenInput!, $collateral: DefineTokenCollateralInput!, $deployment: DeploymentInput!) {
  createToken(token: $token, collateral: $collateral, deployment: $deployment) {
    id
  }
}
    `;
export type DeployTokenMutationFn = Apollo.MutationFunction<DeployTokenMutation, DeployTokenMutationVariables>;

/**
 * __useDeployTokenMutation__
 *
 * To run a mutation, you first call `useDeployTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployTokenMutation, { data, loading, error }] = useDeployTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      collateral: // value for 'collateral'
 *      deployment: // value for 'deployment'
 *   },
 * });
 */
export function useDeployTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeployTokenMutation, DeployTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployTokenMutation, DeployTokenMutationVariables>(DeployTokenDocument, options);
      }
export type DeployTokenMutationHookResult = ReturnType<typeof useDeployTokenMutation>;
export type DeployTokenMutationResult = Apollo.MutationResult<DeployTokenMutation>;
export type DeployTokenMutationOptions = Apollo.BaseMutationOptions<DeployTokenMutation, DeployTokenMutationVariables>;
export const DeployTokenDataDocument = gql`
    query deployTokenData($tokenId: ID!) {
  token(id: $tokenId) {
    id
    name
    symbol
    deployments {
      network
    }
  }
}
    `;

/**
 * __useDeployTokenDataQuery__
 *
 * To run a query within a React component, call `useDeployTokenDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeployTokenDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeployTokenDataQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useDeployTokenDataQuery(baseOptions: Apollo.QueryHookOptions<DeployTokenDataQuery, DeployTokenDataQueryVariables> & ({ variables: DeployTokenDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeployTokenDataQuery, DeployTokenDataQueryVariables>(DeployTokenDataDocument, options);
      }
export function useDeployTokenDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeployTokenDataQuery, DeployTokenDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeployTokenDataQuery, DeployTokenDataQueryVariables>(DeployTokenDataDocument, options);
        }
export function useDeployTokenDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeployTokenDataQuery, DeployTokenDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeployTokenDataQuery, DeployTokenDataQueryVariables>(DeployTokenDataDocument, options);
        }
export type DeployTokenDataQueryHookResult = ReturnType<typeof useDeployTokenDataQuery>;
export type DeployTokenDataLazyQueryHookResult = ReturnType<typeof useDeployTokenDataLazyQuery>;
export type DeployTokenDataSuspenseQueryHookResult = ReturnType<typeof useDeployTokenDataSuspenseQuery>;
export type DeployTokenDataQueryResult = Apollo.QueryResult<DeployTokenDataQuery, DeployTokenDataQueryVariables>;
export const TokenSupportingStructuresDocument = gql`
    query tokenSupportingStructures {
  systemWallets {
    id
    title
    address
  }
}
    `;

/**
 * __useTokenSupportingStructuresQuery__
 *
 * To run a query within a React component, call `useTokenSupportingStructuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSupportingStructuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSupportingStructuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenSupportingStructuresQuery(baseOptions?: Apollo.QueryHookOptions<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>(TokenSupportingStructuresDocument, options);
      }
export function useTokenSupportingStructuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>(TokenSupportingStructuresDocument, options);
        }
export function useTokenSupportingStructuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>(TokenSupportingStructuresDocument, options);
        }
export type TokenSupportingStructuresQueryHookResult = ReturnType<typeof useTokenSupportingStructuresQuery>;
export type TokenSupportingStructuresLazyQueryHookResult = ReturnType<typeof useTokenSupportingStructuresLazyQuery>;
export type TokenSupportingStructuresSuspenseQueryHookResult = ReturnType<typeof useTokenSupportingStructuresSuspenseQuery>;
export type TokenSupportingStructuresQueryResult = Apollo.QueryResult<TokenSupportingStructuresQuery, TokenSupportingStructuresQueryVariables>;
export const CreateTokenDeploymentDocument = gql`
    mutation createTokenDeployment($input: CreateTokenDeploymentInput!) {
  createTokenDeployment(input: $input) {
    id
  }
}
    `;
export type CreateTokenDeploymentMutationFn = Apollo.MutationFunction<CreateTokenDeploymentMutation, CreateTokenDeploymentMutationVariables>;

/**
 * __useCreateTokenDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateTokenDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenDeploymentMutation, { data, loading, error }] = useCreateTokenDeploymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTokenDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTokenDeploymentMutation, CreateTokenDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTokenDeploymentMutation, CreateTokenDeploymentMutationVariables>(CreateTokenDeploymentDocument, options);
      }
export type CreateTokenDeploymentMutationHookResult = ReturnType<typeof useCreateTokenDeploymentMutation>;
export type CreateTokenDeploymentMutationResult = Apollo.MutationResult<CreateTokenDeploymentMutation>;
export type CreateTokenDeploymentMutationOptions = Apollo.BaseMutationOptions<CreateTokenDeploymentMutation, CreateTokenDeploymentMutationVariables>;
export const ImportTokenDeploymentDocument = gql`
    mutation ImportTokenDeployment($input: ImportTokenDeploymentInput!) {
  importTokenDeployment(input: $input) {
    tokenId
  }
}
    `;
export type ImportTokenDeploymentMutationFn = Apollo.MutationFunction<ImportTokenDeploymentMutation, ImportTokenDeploymentMutationVariables>;

/**
 * __useImportTokenDeploymentMutation__
 *
 * To run a mutation, you first call `useImportTokenDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTokenDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTokenDeploymentMutation, { data, loading, error }] = useImportTokenDeploymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportTokenDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<ImportTokenDeploymentMutation, ImportTokenDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportTokenDeploymentMutation, ImportTokenDeploymentMutationVariables>(ImportTokenDeploymentDocument, options);
      }
export type ImportTokenDeploymentMutationHookResult = ReturnType<typeof useImportTokenDeploymentMutation>;
export type ImportTokenDeploymentMutationResult = Apollo.MutationResult<ImportTokenDeploymentMutation>;
export type ImportTokenDeploymentMutationOptions = Apollo.BaseMutationOptions<ImportTokenDeploymentMutation, ImportTokenDeploymentMutationVariables>;
export const ApplyOracleAnswerUpdateDocument = gql`
    mutation ApplyOracleAnswerUpdate($input: ApplyOracleAnswerUpdateInput!) {
  applyOracleAnswerUpdate(input: $input)
}
    `;
export type ApplyOracleAnswerUpdateMutationFn = Apollo.MutationFunction<ApplyOracleAnswerUpdateMutation, ApplyOracleAnswerUpdateMutationVariables>;

/**
 * __useApplyOracleAnswerUpdateMutation__
 *
 * To run a mutation, you first call `useApplyOracleAnswerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyOracleAnswerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyOracleAnswerUpdateMutation, { data, loading, error }] = useApplyOracleAnswerUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyOracleAnswerUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ApplyOracleAnswerUpdateMutation, ApplyOracleAnswerUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyOracleAnswerUpdateMutation, ApplyOracleAnswerUpdateMutationVariables>(ApplyOracleAnswerUpdateDocument, options);
      }
export type ApplyOracleAnswerUpdateMutationHookResult = ReturnType<typeof useApplyOracleAnswerUpdateMutation>;
export type ApplyOracleAnswerUpdateMutationResult = Apollo.MutationResult<ApplyOracleAnswerUpdateMutation>;
export type ApplyOracleAnswerUpdateMutationOptions = Apollo.BaseMutationOptions<ApplyOracleAnswerUpdateMutation, ApplyOracleAnswerUpdateMutationVariables>;
export const TokenCollateralizationDetailsDocument = gql`
    query TokenCollateralizationDetails($tokenId: ID!) {
  token(id: $tokenId) {
    id
    symbol
    collateral {
      symbol
      price
      balance
      usedBalance
      pendingBalance
      tokens {
        id
        pendingBalance
        deployments {
          circulatingSupply
          totalSupply
        }
      }
    }
    pendingBalance
  }
}
    `;

/**
 * __useTokenCollateralizationDetailsQuery__
 *
 * To run a query within a React component, call `useTokenCollateralizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenCollateralizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenCollateralizationDetailsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenCollateralizationDetailsQuery(baseOptions: Apollo.QueryHookOptions<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables> & ({ variables: TokenCollateralizationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>(TokenCollateralizationDetailsDocument, options);
      }
export function useTokenCollateralizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>(TokenCollateralizationDetailsDocument, options);
        }
export function useTokenCollateralizationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>(TokenCollateralizationDetailsDocument, options);
        }
export type TokenCollateralizationDetailsQueryHookResult = ReturnType<typeof useTokenCollateralizationDetailsQuery>;
export type TokenCollateralizationDetailsLazyQueryHookResult = ReturnType<typeof useTokenCollateralizationDetailsLazyQuery>;
export type TokenCollateralizationDetailsSuspenseQueryHookResult = ReturnType<typeof useTokenCollateralizationDetailsSuspenseQuery>;
export type TokenCollateralizationDetailsQueryResult = Apollo.QueryResult<TokenCollateralizationDetailsQuery, TokenCollateralizationDetailsQueryVariables>;
export const ClearanceAccountDetailsDocument = gql`
    query clearanceAccountDetails($input: ClearanceAccountWhereUniqueInput!) {
  clearanceAccount(where: $input) {
    id
    broker {
      id
      name
    }
    institutionName
    depositoryCode
    institutionBic
  }
}
    `;

/**
 * __useClearanceAccountDetailsQuery__
 *
 * To run a query within a React component, call `useClearanceAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClearanceAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClearanceAccountDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearanceAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables> & ({ variables: ClearanceAccountDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>(ClearanceAccountDetailsDocument, options);
      }
export function useClearanceAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>(ClearanceAccountDetailsDocument, options);
        }
export function useClearanceAccountDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>(ClearanceAccountDetailsDocument, options);
        }
export type ClearanceAccountDetailsQueryHookResult = ReturnType<typeof useClearanceAccountDetailsQuery>;
export type ClearanceAccountDetailsLazyQueryHookResult = ReturnType<typeof useClearanceAccountDetailsLazyQuery>;
export type ClearanceAccountDetailsSuspenseQueryHookResult = ReturnType<typeof useClearanceAccountDetailsSuspenseQuery>;
export type ClearanceAccountDetailsQueryResult = Apollo.QueryResult<ClearanceAccountDetailsQuery, ClearanceAccountDetailsQueryVariables>;
export const CollateralCustodyDocument = gql`
    query collateralCustody($collateralId: String!) {
  collateral(collateralId: $collateralId) {
    id
    symbol
    clearanceAccounts {
      id
      broker {
        id
        name
      }
      institutionName
      depositoryCode
      institutionBic
    }
  }
}
    `;

/**
 * __useCollateralCustodyQuery__
 *
 * To run a query within a React component, call `useCollateralCustodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollateralCustodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollateralCustodyQuery({
 *   variables: {
 *      collateralId: // value for 'collateralId'
 *   },
 * });
 */
export function useCollateralCustodyQuery(baseOptions: Apollo.QueryHookOptions<CollateralCustodyQuery, CollateralCustodyQueryVariables> & ({ variables: CollateralCustodyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollateralCustodyQuery, CollateralCustodyQueryVariables>(CollateralCustodyDocument, options);
      }
export function useCollateralCustodyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollateralCustodyQuery, CollateralCustodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollateralCustodyQuery, CollateralCustodyQueryVariables>(CollateralCustodyDocument, options);
        }
export function useCollateralCustodySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CollateralCustodyQuery, CollateralCustodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CollateralCustodyQuery, CollateralCustodyQueryVariables>(CollateralCustodyDocument, options);
        }
export type CollateralCustodyQueryHookResult = ReturnType<typeof useCollateralCustodyQuery>;
export type CollateralCustodyLazyQueryHookResult = ReturnType<typeof useCollateralCustodyLazyQuery>;
export type CollateralCustodySuspenseQueryHookResult = ReturnType<typeof useCollateralCustodySuspenseQuery>;
export type CollateralCustodyQueryResult = Apollo.QueryResult<CollateralCustodyQuery, CollateralCustodyQueryVariables>;
export const BrokersClearanceAccountsDocument = gql`
    query brokersClearanceAccounts {
  brokers {
    nodes {
      id
      name
      clearanceAccounts {
        id
        institutionName
        institutionBic
        depositoryCode
      }
    }
  }
}
    `;

/**
 * __useBrokersClearanceAccountsQuery__
 *
 * To run a query within a React component, call `useBrokersClearanceAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokersClearanceAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokersClearanceAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokersClearanceAccountsQuery(baseOptions?: Apollo.QueryHookOptions<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>(BrokersClearanceAccountsDocument, options);
      }
export function useBrokersClearanceAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>(BrokersClearanceAccountsDocument, options);
        }
export function useBrokersClearanceAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>(BrokersClearanceAccountsDocument, options);
        }
export type BrokersClearanceAccountsQueryHookResult = ReturnType<typeof useBrokersClearanceAccountsQuery>;
export type BrokersClearanceAccountsLazyQueryHookResult = ReturnType<typeof useBrokersClearanceAccountsLazyQuery>;
export type BrokersClearanceAccountsSuspenseQueryHookResult = ReturnType<typeof useBrokersClearanceAccountsSuspenseQuery>;
export type BrokersClearanceAccountsQueryResult = Apollo.QueryResult<BrokersClearanceAccountsQuery, BrokersClearanceAccountsQueryVariables>;
export const UpsertClearanceAccountDocument = gql`
    mutation upsertClearanceAccount($input: UpsertClearanceAccountInput!) {
  upsertClearanceAccount(input: $input) {
    id
  }
}
    `;
export type UpsertClearanceAccountMutationFn = Apollo.MutationFunction<UpsertClearanceAccountMutation, UpsertClearanceAccountMutationVariables>;

/**
 * __useUpsertClearanceAccountMutation__
 *
 * To run a mutation, you first call `useUpsertClearanceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClearanceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClearanceAccountMutation, { data, loading, error }] = useUpsertClearanceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertClearanceAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertClearanceAccountMutation, UpsertClearanceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertClearanceAccountMutation, UpsertClearanceAccountMutationVariables>(UpsertClearanceAccountDocument, options);
      }
export type UpsertClearanceAccountMutationHookResult = ReturnType<typeof useUpsertClearanceAccountMutation>;
export type UpsertClearanceAccountMutationResult = Apollo.MutationResult<UpsertClearanceAccountMutation>;
export type UpsertClearanceAccountMutationOptions = Apollo.BaseMutationOptions<UpsertClearanceAccountMutation, UpsertClearanceAccountMutationVariables>;
export const PriceUpdateDocument = gql`
    query priceUpdate($id: String!) {
  oracleAnswerUpdate(id: $id) {
    id
    createdAt
    newAnswer
    previousAnswer
  }
}
    `;

/**
 * __usePriceUpdateQuery__
 *
 * To run a query within a React component, call `usePriceUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePriceUpdateQuery(baseOptions: Apollo.QueryHookOptions<PriceUpdateQuery, PriceUpdateQueryVariables> & ({ variables: PriceUpdateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PriceUpdateQuery, PriceUpdateQueryVariables>(PriceUpdateDocument, options);
      }
export function usePriceUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PriceUpdateQuery, PriceUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PriceUpdateQuery, PriceUpdateQueryVariables>(PriceUpdateDocument, options);
        }
export function usePriceUpdateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PriceUpdateQuery, PriceUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PriceUpdateQuery, PriceUpdateQueryVariables>(PriceUpdateDocument, options);
        }
export type PriceUpdateQueryHookResult = ReturnType<typeof usePriceUpdateQuery>;
export type PriceUpdateLazyQueryHookResult = ReturnType<typeof usePriceUpdateLazyQuery>;
export type PriceUpdateSuspenseQueryHookResult = ReturnType<typeof usePriceUpdateSuspenseQuery>;
export type PriceUpdateQueryResult = Apollo.QueryResult<PriceUpdateQuery, PriceUpdateQueryVariables>;
export const CreateCustodyAccountFacetDocument = gql`
    mutation createCustodyAccountFacet($input: CreateCustodyAccountFacetInput!) {
  createCustodyAccountFacet(input: $input) {
    id
  }
}
    `;
export type CreateCustodyAccountFacetMutationFn = Apollo.MutationFunction<CreateCustodyAccountFacetMutation, CreateCustodyAccountFacetMutationVariables>;

/**
 * __useCreateCustodyAccountFacetMutation__
 *
 * To run a mutation, you first call `useCreateCustodyAccountFacetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustodyAccountFacetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustodyAccountFacetMutation, { data, loading, error }] = useCreateCustodyAccountFacetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustodyAccountFacetMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustodyAccountFacetMutation, CreateCustodyAccountFacetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustodyAccountFacetMutation, CreateCustodyAccountFacetMutationVariables>(CreateCustodyAccountFacetDocument, options);
      }
export type CreateCustodyAccountFacetMutationHookResult = ReturnType<typeof useCreateCustodyAccountFacetMutation>;
export type CreateCustodyAccountFacetMutationResult = Apollo.MutationResult<CreateCustodyAccountFacetMutation>;
export type CreateCustodyAccountFacetMutationOptions = Apollo.BaseMutationOptions<CreateCustodyAccountFacetMutation, CreateCustodyAccountFacetMutationVariables>;
export const CreateOracleDocument = gql`
    mutation createOracle($input: CreateOracleInput!) {
  createOracle(input: $input) {
    id
  }
}
    `;
export type CreateOracleMutationFn = Apollo.MutationFunction<CreateOracleMutation, CreateOracleMutationVariables>;

/**
 * __useCreateOracleMutation__
 *
 * To run a mutation, you first call `useCreateOracleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOracleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOracleMutation, { data, loading, error }] = useCreateOracleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOracleMutation(baseOptions?: Apollo.MutationHookOptions<CreateOracleMutation, CreateOracleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOracleMutation, CreateOracleMutationVariables>(CreateOracleDocument, options);
      }
export type CreateOracleMutationHookResult = ReturnType<typeof useCreateOracleMutation>;
export type CreateOracleMutationResult = Apollo.MutationResult<CreateOracleMutation>;
export type CreateOracleMutationOptions = Apollo.BaseMutationOptions<CreateOracleMutation, CreateOracleMutationVariables>;
export const RegisterOracleForwarderDocument = gql`
    mutation registerOracleForwarder($input: RegisterOracleForwarderInput!) {
  registerOracleForwarder(input: $input) {
    id
  }
}
    `;
export type RegisterOracleForwarderMutationFn = Apollo.MutationFunction<RegisterOracleForwarderMutation, RegisterOracleForwarderMutationVariables>;

/**
 * __useRegisterOracleForwarderMutation__
 *
 * To run a mutation, you first call `useRegisterOracleForwarderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOracleForwarderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOracleForwarderMutation, { data, loading, error }] = useRegisterOracleForwarderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterOracleForwarderMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOracleForwarderMutation, RegisterOracleForwarderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOracleForwarderMutation, RegisterOracleForwarderMutationVariables>(RegisterOracleForwarderDocument, options);
      }
export type RegisterOracleForwarderMutationHookResult = ReturnType<typeof useRegisterOracleForwarderMutation>;
export type RegisterOracleForwarderMutationResult = Apollo.MutationResult<RegisterOracleForwarderMutation>;
export type RegisterOracleForwarderMutationOptions = Apollo.BaseMutationOptions<RegisterOracleForwarderMutation, RegisterOracleForwarderMutationVariables>;
export const UpdateTokenMultiplierDocument = gql`
    mutation updateTokenMultiplier($input: UpdateTokenMultiplierInput!) {
  updateTokenMultiplier(input: $input)
}
    `;
export type UpdateTokenMultiplierMutationFn = Apollo.MutationFunction<UpdateTokenMultiplierMutation, UpdateTokenMultiplierMutationVariables>;

/**
 * __useUpdateTokenMultiplierMutation__
 *
 * To run a mutation, you first call `useUpdateTokenMultiplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenMultiplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenMultiplierMutation, { data, loading, error }] = useUpdateTokenMultiplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTokenMultiplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTokenMultiplierMutation, UpdateTokenMultiplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTokenMultiplierMutation, UpdateTokenMultiplierMutationVariables>(UpdateTokenMultiplierDocument, options);
      }
export type UpdateTokenMultiplierMutationHookResult = ReturnType<typeof useUpdateTokenMultiplierMutation>;
export type UpdateTokenMultiplierMutationResult = Apollo.MutationResult<UpdateTokenMultiplierMutation>;
export type UpdateTokenMultiplierMutationOptions = Apollo.BaseMutationOptions<UpdateTokenMultiplierMutation, UpdateTokenMultiplierMutationVariables>;
export const TokenCollateralCustodyDocument = gql`
    query tokenCollateralCustody($tokenId: String!) {
  collateral(tokenId: $tokenId) {
    id
    symbol
    priceCurrency
    clearanceAccounts {
      id
      broker {
        id
        name
      }
      institutionName
      depositoryCode
      institutionBic
      updatedAt
    }
  }
}
    `;

/**
 * __useTokenCollateralCustodyQuery__
 *
 * To run a query within a React component, call `useTokenCollateralCustodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenCollateralCustodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenCollateralCustodyQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenCollateralCustodyQuery(baseOptions: Apollo.QueryHookOptions<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables> & ({ variables: TokenCollateralCustodyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>(TokenCollateralCustodyDocument, options);
      }
export function useTokenCollateralCustodyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>(TokenCollateralCustodyDocument, options);
        }
export function useTokenCollateralCustodySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>(TokenCollateralCustodyDocument, options);
        }
export type TokenCollateralCustodyQueryHookResult = ReturnType<typeof useTokenCollateralCustodyQuery>;
export type TokenCollateralCustodyLazyQueryHookResult = ReturnType<typeof useTokenCollateralCustodyLazyQuery>;
export type TokenCollateralCustodySuspenseQueryHookResult = ReturnType<typeof useTokenCollateralCustodySuspenseQuery>;
export type TokenCollateralCustodyQueryResult = Apollo.QueryResult<TokenCollateralCustodyQuery, TokenCollateralCustodyQueryVariables>;
export const AvailableCustodyAccountsDocument = gql`
    query availableCustodyAccounts {
  custodyAccounts {
    externalId
    label
    facets {
      id
      type
      asset
      collateral {
        id
      }
    }
  }
}
    `;

/**
 * __useAvailableCustodyAccountsQuery__
 *
 * To run a query within a React component, call `useAvailableCustodyAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCustodyAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCustodyAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCustodyAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>(AvailableCustodyAccountsDocument, options);
      }
export function useAvailableCustodyAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>(AvailableCustodyAccountsDocument, options);
        }
export function useAvailableCustodyAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>(AvailableCustodyAccountsDocument, options);
        }
export type AvailableCustodyAccountsQueryHookResult = ReturnType<typeof useAvailableCustodyAccountsQuery>;
export type AvailableCustodyAccountsLazyQueryHookResult = ReturnType<typeof useAvailableCustodyAccountsLazyQuery>;
export type AvailableCustodyAccountsSuspenseQueryHookResult = ReturnType<typeof useAvailableCustodyAccountsSuspenseQuery>;
export type AvailableCustodyAccountsQueryResult = Apollo.QueryResult<AvailableCustodyAccountsQuery, AvailableCustodyAccountsQueryVariables>;
export const UpdateCollateralCustodyAccountDocument = gql`
    mutation updateCollateralCustodyAccount($input: UpdateCollateralCustodyAccountInput!) {
  updateCollateralCustodyAccount(input: $input) {
    id
  }
}
    `;
export type UpdateCollateralCustodyAccountMutationFn = Apollo.MutationFunction<UpdateCollateralCustodyAccountMutation, UpdateCollateralCustodyAccountMutationVariables>;

/**
 * __useUpdateCollateralCustodyAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCollateralCustodyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollateralCustodyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollateralCustodyAccountMutation, { data, loading, error }] = useUpdateCollateralCustodyAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollateralCustodyAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollateralCustodyAccountMutation, UpdateCollateralCustodyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollateralCustodyAccountMutation, UpdateCollateralCustodyAccountMutationVariables>(UpdateCollateralCustodyAccountDocument, options);
      }
export type UpdateCollateralCustodyAccountMutationHookResult = ReturnType<typeof useUpdateCollateralCustodyAccountMutation>;
export type UpdateCollateralCustodyAccountMutationResult = Apollo.MutationResult<UpdateCollateralCustodyAccountMutation>;
export type UpdateCollateralCustodyAccountMutationOptions = Apollo.BaseMutationOptions<UpdateCollateralCustodyAccountMutation, UpdateCollateralCustodyAccountMutationVariables>;
export const TokenCollateralizationDocument = gql`
    query tokenCollateralization($tokenId: ID) {
  token(id: $tokenId) {
    symbol
    collateral {
      symbol
    }
    currentMultiplier
    lastMultiplierUpdateTimestamp
    managementFee {
      amount
      feeAccruals {
        id
        createdAt
        nominalFeeCollected
      }
    }
    deployments {
      network
      multiplierChanges {
        id
        executedAt
        multiplier
        reason
        previousMultiplier
      }
    }
  }
}
    `;

/**
 * __useTokenCollateralizationQuery__
 *
 * To run a query within a React component, call `useTokenCollateralizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenCollateralizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenCollateralizationQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenCollateralizationQuery(baseOptions?: Apollo.QueryHookOptions<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>(TokenCollateralizationDocument, options);
      }
export function useTokenCollateralizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>(TokenCollateralizationDocument, options);
        }
export function useTokenCollateralizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>(TokenCollateralizationDocument, options);
        }
export type TokenCollateralizationQueryHookResult = ReturnType<typeof useTokenCollateralizationQuery>;
export type TokenCollateralizationLazyQueryHookResult = ReturnType<typeof useTokenCollateralizationLazyQuery>;
export type TokenCollateralizationSuspenseQueryHookResult = ReturnType<typeof useTokenCollateralizationSuspenseQuery>;
export type TokenCollateralizationQueryResult = Apollo.QueryResult<TokenCollateralizationQuery, TokenCollateralizationQueryVariables>;
export const TokenDeploymentsDocument = gql`
    query tokenDeployments($tokenId: ID!) {
  tokenDeployments(where: {tokenId: $tokenId}) {
    id
    deploymentStatus
    address
    network
  }
}
    `;

/**
 * __useTokenDeploymentsQuery__
 *
 * To run a query within a React component, call `useTokenDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDeploymentsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenDeploymentsQuery(baseOptions: Apollo.QueryHookOptions<TokenDeploymentsQuery, TokenDeploymentsQueryVariables> & ({ variables: TokenDeploymentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>(TokenDeploymentsDocument, options);
      }
export function useTokenDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>(TokenDeploymentsDocument, options);
        }
export function useTokenDeploymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>(TokenDeploymentsDocument, options);
        }
export type TokenDeploymentsQueryHookResult = ReturnType<typeof useTokenDeploymentsQuery>;
export type TokenDeploymentsLazyQueryHookResult = ReturnType<typeof useTokenDeploymentsLazyQuery>;
export type TokenDeploymentsSuspenseQueryHookResult = ReturnType<typeof useTokenDeploymentsSuspenseQuery>;
export type TokenDeploymentsQueryResult = Apollo.QueryResult<TokenDeploymentsQuery, TokenDeploymentsQueryVariables>;
export const TokenCollateralDocument = gql`
    query tokenCollateral($tokenId: String!) {
  collateral(tokenId: $tokenId) {
    id
    symbol
    oracles {
      id
      updateSchedule
      isActive
      type
      network
      address
      forwarder {
        address
      }
      answerUpdates(onlyPending: true) {
        id
        newAnswer
        previousAnswer
      }
    }
  }
}
    `;

/**
 * __useTokenCollateralQuery__
 *
 * To run a query within a React component, call `useTokenCollateralQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenCollateralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenCollateralQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenCollateralQuery(baseOptions: Apollo.QueryHookOptions<TokenCollateralQuery, TokenCollateralQueryVariables> & ({ variables: TokenCollateralQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenCollateralQuery, TokenCollateralQueryVariables>(TokenCollateralDocument, options);
      }
export function useTokenCollateralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenCollateralQuery, TokenCollateralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenCollateralQuery, TokenCollateralQueryVariables>(TokenCollateralDocument, options);
        }
export function useTokenCollateralSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenCollateralQuery, TokenCollateralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenCollateralQuery, TokenCollateralQueryVariables>(TokenCollateralDocument, options);
        }
export type TokenCollateralQueryHookResult = ReturnType<typeof useTokenCollateralQuery>;
export type TokenCollateralLazyQueryHookResult = ReturnType<typeof useTokenCollateralLazyQuery>;
export type TokenCollateralSuspenseQueryHookResult = ReturnType<typeof useTokenCollateralSuspenseQuery>;
export type TokenCollateralQueryResult = Apollo.QueryResult<TokenCollateralQuery, TokenCollateralQueryVariables>;
export const UpdateOracleAnswerDocument = gql`
    mutation updateOracleAnswer($input: UpdateOracleAnswerInput!) {
  updateOracleAnswer(input: $input)
}
    `;
export type UpdateOracleAnswerMutationFn = Apollo.MutationFunction<UpdateOracleAnswerMutation, UpdateOracleAnswerMutationVariables>;

/**
 * __useUpdateOracleAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateOracleAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOracleAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOracleAnswerMutation, { data, loading, error }] = useUpdateOracleAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOracleAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOracleAnswerMutation, UpdateOracleAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOracleAnswerMutation, UpdateOracleAnswerMutationVariables>(UpdateOracleAnswerDocument, options);
      }
export type UpdateOracleAnswerMutationHookResult = ReturnType<typeof useUpdateOracleAnswerMutation>;
export type UpdateOracleAnswerMutationResult = Apollo.MutationResult<UpdateOracleAnswerMutation>;
export type UpdateOracleAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateOracleAnswerMutation, UpdateOracleAnswerMutationVariables>;
export const TokenDetailDocument = gql`
    query tokenDetail($tokenId: ID!) {
  token(id: $tokenId) {
    name
    symbol
    description
    icon
    isin
    collateral {
      ISINNumber
      ISINName
      symbol
      lastKnownPrice
      publiclyListed
    }
    deployments {
      network
      address
      circulatingSupply
      totalSupply
    }
  }
}
    `;

/**
 * __useTokenDetailQuery__
 *
 * To run a query within a React component, call `useTokenDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenDetailQuery(baseOptions: Apollo.QueryHookOptions<TokenDetailQuery, TokenDetailQueryVariables> & ({ variables: TokenDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDetailQuery, TokenDetailQueryVariables>(TokenDetailDocument, options);
      }
export function useTokenDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDetailQuery, TokenDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDetailQuery, TokenDetailQueryVariables>(TokenDetailDocument, options);
        }
export function useTokenDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenDetailQuery, TokenDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenDetailQuery, TokenDetailQueryVariables>(TokenDetailDocument, options);
        }
export type TokenDetailQueryHookResult = ReturnType<typeof useTokenDetailQuery>;
export type TokenDetailLazyQueryHookResult = ReturnType<typeof useTokenDetailLazyQuery>;
export type TokenDetailSuspenseQueryHookResult = ReturnType<typeof useTokenDetailSuspenseQuery>;
export type TokenDetailQueryResult = Apollo.QueryResult<TokenDetailQuery, TokenDetailQueryVariables>;
export const TokenSettingsDocument = gql`
    query tokenSettings($id: ID!) {
  token(id: $id) {
    name
    description
    deployments {
      id
      network
      deploymentStatus
    }
  }
}
    `;

/**
 * __useTokenSettingsQuery__
 *
 * To run a query within a React component, call `useTokenSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTokenSettingsQuery(baseOptions: Apollo.QueryHookOptions<TokenSettingsQuery, TokenSettingsQueryVariables> & ({ variables: TokenSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSettingsQuery, TokenSettingsQueryVariables>(TokenSettingsDocument, options);
      }
export function useTokenSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSettingsQuery, TokenSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSettingsQuery, TokenSettingsQueryVariables>(TokenSettingsDocument, options);
        }
export function useTokenSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenSettingsQuery, TokenSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenSettingsQuery, TokenSettingsQueryVariables>(TokenSettingsDocument, options);
        }
export type TokenSettingsQueryHookResult = ReturnType<typeof useTokenSettingsQuery>;
export type TokenSettingsLazyQueryHookResult = ReturnType<typeof useTokenSettingsLazyQuery>;
export type TokenSettingsSuspenseQueryHookResult = ReturnType<typeof useTokenSettingsSuspenseQuery>;
export type TokenSettingsQueryResult = Apollo.QueryResult<TokenSettingsQuery, TokenSettingsQueryVariables>;
export const PublishTokenDocument = gql`
    mutation publishToken($tokenId: ID!) {
  publishToken(tokenId: $tokenId) {
    id
  }
}
    `;
export type PublishTokenMutationFn = Apollo.MutationFunction<PublishTokenMutation, PublishTokenMutationVariables>;

/**
 * __usePublishTokenMutation__
 *
 * To run a mutation, you first call `usePublishTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTokenMutation, { data, loading, error }] = usePublishTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function usePublishTokenMutation(baseOptions?: Apollo.MutationHookOptions<PublishTokenMutation, PublishTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishTokenMutation, PublishTokenMutationVariables>(PublishTokenDocument, options);
      }
export type PublishTokenMutationHookResult = ReturnType<typeof usePublishTokenMutation>;
export type PublishTokenMutationResult = Apollo.MutationResult<PublishTokenMutation>;
export type PublishTokenMutationOptions = Apollo.BaseMutationOptions<PublishTokenMutation, PublishTokenMutationVariables>;
export const TokenStatisticsDocument = gql`
    query tokenStatistics($tokenId: ID!) {
  token(id: $tokenId) {
    statistics {
      currentValue
      maxValue
      counter
      fees
      lastOrder
      perClient {
        clientId
        clientName
        currentValue
        lastOrder
        maxValue
        fees
        counter
      }
      issuances {
        value
        fees
        counter
      }
      redemptions {
        value
        fees
        counter
      }
    }
  }
}
    `;

/**
 * __useTokenStatisticsQuery__
 *
 * To run a query within a React component, call `useTokenStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenStatisticsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenStatisticsQuery(baseOptions: Apollo.QueryHookOptions<TokenStatisticsQuery, TokenStatisticsQueryVariables> & ({ variables: TokenStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenStatisticsQuery, TokenStatisticsQueryVariables>(TokenStatisticsDocument, options);
      }
export function useTokenStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenStatisticsQuery, TokenStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenStatisticsQuery, TokenStatisticsQueryVariables>(TokenStatisticsDocument, options);
        }
export function useTokenStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenStatisticsQuery, TokenStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenStatisticsQuery, TokenStatisticsQueryVariables>(TokenStatisticsDocument, options);
        }
export type TokenStatisticsQueryHookResult = ReturnType<typeof useTokenStatisticsQuery>;
export type TokenStatisticsLazyQueryHookResult = ReturnType<typeof useTokenStatisticsLazyQuery>;
export type TokenStatisticsSuspenseQueryHookResult = ReturnType<typeof useTokenStatisticsSuspenseQuery>;
export type TokenStatisticsQueryResult = Apollo.QueryResult<TokenStatisticsQuery, TokenStatisticsQueryVariables>;
export const TokenListDocument = gql`
    query tokenList {
  tokens(includeUnpublished: true) {
    id
    name
    symbol
    description
    icon
    isin
    isPublic
    collateral {
      ISINNumber
      price
      priceCurrency
    }
    deployments {
      network
      address
      stablecoinConfigs {
        symbol
      }
      circulatingSupply
      totalSupply
    }
  }
}
    `;

/**
 * __useTokenListQuery__
 *
 * To run a query within a React component, call `useTokenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenListQuery(baseOptions?: Apollo.QueryHookOptions<TokenListQuery, TokenListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
      }
export function useTokenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenListQuery, TokenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
        }
export function useTokenListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokenListQuery, TokenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
        }
export type TokenListQueryHookResult = ReturnType<typeof useTokenListQuery>;
export type TokenListLazyQueryHookResult = ReturnType<typeof useTokenListLazyQuery>;
export type TokenListSuspenseQueryHookResult = ReturnType<typeof useTokenListSuspenseQuery>;
export type TokenListQueryResult = Apollo.QueryResult<TokenListQuery, TokenListQueryVariables>;
export const CreateClientInvitationDocument = gql`
    mutation createClientInvitation($input: CreateInvitationInput!) {
  createInvitation(input: $input) {
    id
  }
}
    `;
export type CreateClientInvitationMutationFn = Apollo.MutationFunction<CreateClientInvitationMutation, CreateClientInvitationMutationVariables>;

/**
 * __useCreateClientInvitationMutation__
 *
 * To run a mutation, you first call `useCreateClientInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientInvitationMutation, { data, loading, error }] = useCreateClientInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientInvitationMutation, CreateClientInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientInvitationMutation, CreateClientInvitationMutationVariables>(CreateClientInvitationDocument, options);
      }
export type CreateClientInvitationMutationHookResult = ReturnType<typeof useCreateClientInvitationMutation>;
export type CreateClientInvitationMutationResult = Apollo.MutationResult<CreateClientInvitationMutation>;
export type CreateClientInvitationMutationOptions = Apollo.BaseMutationOptions<CreateClientInvitationMutation, CreateClientInvitationMutationVariables>;
export const InvitationsDocument = gql`
    query invitations($page: Int, $pageSize: Int, $where: InvitationsFilter) {
  invitations(page: $page, pageSize: $pageSize, where: $where) {
    nodes {
      id
      createdAt
      updatedAt
      email
      isClaimed
      claimedAt
    }
    page {
      totalNodes
    }
  }
}
    `;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export function useInvitationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsSuspenseQueryHookResult = ReturnType<typeof useInvitationsSuspenseQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>;
export const TradingAssetsDocument = gql`
    query TradingAssets {
  tradingAssets {
    id
    name
    type
  }
}
    `;

/**
 * __useTradingAssetsQuery__
 *
 * To run a query within a React component, call `useTradingAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTradingAssetsQuery(baseOptions?: Apollo.QueryHookOptions<TradingAssetsQuery, TradingAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradingAssetsQuery, TradingAssetsQueryVariables>(TradingAssetsDocument, options);
      }
export function useTradingAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradingAssetsQuery, TradingAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradingAssetsQuery, TradingAssetsQueryVariables>(TradingAssetsDocument, options);
        }
export function useTradingAssetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TradingAssetsQuery, TradingAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TradingAssetsQuery, TradingAssetsQueryVariables>(TradingAssetsDocument, options);
        }
export type TradingAssetsQueryHookResult = ReturnType<typeof useTradingAssetsQuery>;
export type TradingAssetsLazyQueryHookResult = ReturnType<typeof useTradingAssetsLazyQuery>;
export type TradingAssetsSuspenseQueryHookResult = ReturnType<typeof useTradingAssetsSuspenseQuery>;
export type TradingAssetsQueryResult = Apollo.QueryResult<TradingAssetsQuery, TradingAssetsQueryVariables>;
export const TradingBrokersDocument = gql`
    query TradingBrokers {
  brokers {
    nodes {
      id
      name
      tradingEngineSupport
    }
  }
}
    `;

/**
 * __useTradingBrokersQuery__
 *
 * To run a query within a React component, call `useTradingBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingBrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTradingBrokersQuery(baseOptions?: Apollo.QueryHookOptions<TradingBrokersQuery, TradingBrokersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradingBrokersQuery, TradingBrokersQueryVariables>(TradingBrokersDocument, options);
      }
export function useTradingBrokersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradingBrokersQuery, TradingBrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradingBrokersQuery, TradingBrokersQueryVariables>(TradingBrokersDocument, options);
        }
export function useTradingBrokersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TradingBrokersQuery, TradingBrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TradingBrokersQuery, TradingBrokersQueryVariables>(TradingBrokersDocument, options);
        }
export type TradingBrokersQueryHookResult = ReturnType<typeof useTradingBrokersQuery>;
export type TradingBrokersLazyQueryHookResult = ReturnType<typeof useTradingBrokersLazyQuery>;
export type TradingBrokersSuspenseQueryHookResult = ReturnType<typeof useTradingBrokersSuspenseQuery>;
export type TradingBrokersQueryResult = Apollo.QueryResult<TradingBrokersQuery, TradingBrokersQueryVariables>;
export const ExecuteTradeDocument = gql`
    mutation ExecuteTrade($input: ExecuteTradeInput!) {
  executeTrade(input: $input) {
    id
  }
}
    `;
export type ExecuteTradeMutationFn = Apollo.MutationFunction<ExecuteTradeMutation, ExecuteTradeMutationVariables>;

/**
 * __useExecuteTradeMutation__
 *
 * To run a mutation, you first call `useExecuteTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeTradeMutation, { data, loading, error }] = useExecuteTradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteTradeMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteTradeMutation, ExecuteTradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteTradeMutation, ExecuteTradeMutationVariables>(ExecuteTradeDocument, options);
      }
export type ExecuteTradeMutationHookResult = ReturnType<typeof useExecuteTradeMutation>;
export type ExecuteTradeMutationResult = Apollo.MutationResult<ExecuteTradeMutation>;
export type ExecuteTradeMutationOptions = Apollo.BaseMutationOptions<ExecuteTradeMutation, ExecuteTradeMutationVariables>;
export const TradesListPageDocument = gql`
    query TradesListPage {
  trades {
    id
    createdAt
    baseAsset {
      name
    }
    amount
    quoteAsset {
      name
    }
    side
    providerTradeId
    provider
    exchangeRate
    status
  }
}
    `;

/**
 * __useTradesListPageQuery__
 *
 * To run a query within a React component, call `useTradesListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradesListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradesListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTradesListPageQuery(baseOptions?: Apollo.QueryHookOptions<TradesListPageQuery, TradesListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradesListPageQuery, TradesListPageQueryVariables>(TradesListPageDocument, options);
      }
export function useTradesListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradesListPageQuery, TradesListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradesListPageQuery, TradesListPageQueryVariables>(TradesListPageDocument, options);
        }
export function useTradesListPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TradesListPageQuery, TradesListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TradesListPageQuery, TradesListPageQueryVariables>(TradesListPageDocument, options);
        }
export type TradesListPageQueryHookResult = ReturnType<typeof useTradesListPageQuery>;
export type TradesListPageLazyQueryHookResult = ReturnType<typeof useTradesListPageLazyQuery>;
export type TradesListPageSuspenseQueryHookResult = ReturnType<typeof useTradesListPageSuspenseQuery>;
export type TradesListPageQueryResult = Apollo.QueryResult<TradesListPageQuery, TradesListPageQueryVariables>;
export const UsersListPageDocument = gql`
    query usersListPage($page: Int = 1, $pageSize: Int, $where: UsersFilter) {
  users(page: $page, pageSize: $pageSize, where: $where) {
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      createdAt
      firstName
      secondName
      lastName
      email
      client {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUsersListPageQuery__
 *
 * To run a query within a React component, call `useUsersListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersListPageQuery(baseOptions?: Apollo.QueryHookOptions<UsersListPageQuery, UsersListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersListPageQuery, UsersListPageQueryVariables>(UsersListPageDocument, options);
      }
export function useUsersListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersListPageQuery, UsersListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersListPageQuery, UsersListPageQueryVariables>(UsersListPageDocument, options);
        }
export function useUsersListPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersListPageQuery, UsersListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersListPageQuery, UsersListPageQueryVariables>(UsersListPageDocument, options);
        }
export type UsersListPageQueryHookResult = ReturnType<typeof useUsersListPageQuery>;
export type UsersListPageLazyQueryHookResult = ReturnType<typeof useUsersListPageLazyQuery>;
export type UsersListPageSuspenseQueryHookResult = ReturnType<typeof useUsersListPageSuspenseQuery>;
export type UsersListPageQueryResult = Apollo.QueryResult<UsersListPageQuery, UsersListPageQueryVariables>;
export const NetworkSimulatorRuleListDocument = gql`
    query NetworkSimulatorRuleList($page: Int = 1, $pageSize: Int) {
  networkSimulatorRules(page: $page, pageSize: $pageSize) {
    nodes {
      id
      createdAt
      updatedAt
      pathRegex
      payloadRegex
      transformScript
    }
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useNetworkSimulatorRuleListQuery__
 *
 * To run a query within a React component, call `useNetworkSimulatorRuleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkSimulatorRuleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkSimulatorRuleListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useNetworkSimulatorRuleListQuery(baseOptions?: Apollo.QueryHookOptions<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>(NetworkSimulatorRuleListDocument, options);
      }
export function useNetworkSimulatorRuleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>(NetworkSimulatorRuleListDocument, options);
        }
export function useNetworkSimulatorRuleListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>(NetworkSimulatorRuleListDocument, options);
        }
export type NetworkSimulatorRuleListQueryHookResult = ReturnType<typeof useNetworkSimulatorRuleListQuery>;
export type NetworkSimulatorRuleListLazyQueryHookResult = ReturnType<typeof useNetworkSimulatorRuleListLazyQuery>;
export type NetworkSimulatorRuleListSuspenseQueryHookResult = ReturnType<typeof useNetworkSimulatorRuleListSuspenseQuery>;
export type NetworkSimulatorRuleListQueryResult = Apollo.QueryResult<NetworkSimulatorRuleListQuery, NetworkSimulatorRuleListQueryVariables>;
export const CreateNetworkSimulatorRuleDocument = gql`
    mutation createNetworkSimulatorRule($input: CreateNetworkSimulatorRuleInput!) {
  createNetworkSimulatorRule(input: $input) {
    id
  }
}
    `;
export type CreateNetworkSimulatorRuleMutationFn = Apollo.MutationFunction<CreateNetworkSimulatorRuleMutation, CreateNetworkSimulatorRuleMutationVariables>;

/**
 * __useCreateNetworkSimulatorRuleMutation__
 *
 * To run a mutation, you first call `useCreateNetworkSimulatorRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkSimulatorRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkSimulatorRuleMutation, { data, loading, error }] = useCreateNetworkSimulatorRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkSimulatorRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateNetworkSimulatorRuleMutation, CreateNetworkSimulatorRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNetworkSimulatorRuleMutation, CreateNetworkSimulatorRuleMutationVariables>(CreateNetworkSimulatorRuleDocument, options);
      }
export type CreateNetworkSimulatorRuleMutationHookResult = ReturnType<typeof useCreateNetworkSimulatorRuleMutation>;
export type CreateNetworkSimulatorRuleMutationResult = Apollo.MutationResult<CreateNetworkSimulatorRuleMutation>;
export type CreateNetworkSimulatorRuleMutationOptions = Apollo.BaseMutationOptions<CreateNetworkSimulatorRuleMutation, CreateNetworkSimulatorRuleMutationVariables>;
export const DeleteNetworkSimulatorRuleDocument = gql`
    mutation deleteNetworkSimulatorRule($input: DeleteNetworkSimulatorRuleInput!) {
  deleteNetworkSimulatorRule(input: $input) {
    id
  }
}
    `;
export type DeleteNetworkSimulatorRuleMutationFn = Apollo.MutationFunction<DeleteNetworkSimulatorRuleMutation, DeleteNetworkSimulatorRuleMutationVariables>;

/**
 * __useDeleteNetworkSimulatorRuleMutation__
 *
 * To run a mutation, you first call `useDeleteNetworkSimulatorRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNetworkSimulatorRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNetworkSimulatorRuleMutation, { data, loading, error }] = useDeleteNetworkSimulatorRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNetworkSimulatorRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNetworkSimulatorRuleMutation, DeleteNetworkSimulatorRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNetworkSimulatorRuleMutation, DeleteNetworkSimulatorRuleMutationVariables>(DeleteNetworkSimulatorRuleDocument, options);
      }
export type DeleteNetworkSimulatorRuleMutationHookResult = ReturnType<typeof useDeleteNetworkSimulatorRuleMutation>;
export type DeleteNetworkSimulatorRuleMutationResult = Apollo.MutationResult<DeleteNetworkSimulatorRuleMutation>;
export type DeleteNetworkSimulatorRuleMutationOptions = Apollo.BaseMutationOptions<DeleteNetworkSimulatorRuleMutation, DeleteNetworkSimulatorRuleMutationVariables>;
export const DissmisSetPasswordPromptDocument = gql`
    mutation dissmisSetPasswordPrompt {
  setPasswordPromptFeature
}
    `;
export type DissmisSetPasswordPromptMutationFn = Apollo.MutationFunction<DissmisSetPasswordPromptMutation, DissmisSetPasswordPromptMutationVariables>;

/**
 * __useDissmisSetPasswordPromptMutation__
 *
 * To run a mutation, you first call `useDissmisSetPasswordPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissmisSetPasswordPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissmisSetPasswordPromptMutation, { data, loading, error }] = useDissmisSetPasswordPromptMutation({
 *   variables: {
 *   },
 * });
 */
export function useDissmisSetPasswordPromptMutation(baseOptions?: Apollo.MutationHookOptions<DissmisSetPasswordPromptMutation, DissmisSetPasswordPromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DissmisSetPasswordPromptMutation, DissmisSetPasswordPromptMutationVariables>(DissmisSetPasswordPromptDocument, options);
      }
export type DissmisSetPasswordPromptMutationHookResult = ReturnType<typeof useDissmisSetPasswordPromptMutation>;
export type DissmisSetPasswordPromptMutationResult = Apollo.MutationResult<DissmisSetPasswordPromptMutation>;
export type DissmisSetPasswordPromptMutationOptions = Apollo.BaseMutationOptions<DissmisSetPasswordPromptMutation, DissmisSetPasswordPromptMutationVariables>;
export const ContactUsDocument = gql`
    mutation contactUs($input: ContactInput!) {
  contact(input: $input)
}
    `;
export type ContactUsMutationFn = Apollo.MutationFunction<ContactUsMutation, ContactUsMutationVariables>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactUsMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, options);
      }
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<ContactUsMutation, ContactUsMutationVariables>;
export const ExternalWalletsDropdownDocument = gql`
    query externalWalletsDropdown {
  externalWallets {
    id
    address
  }
}
    `;

/**
 * __useExternalWalletsDropdownQuery__
 *
 * To run a query within a React component, call `useExternalWalletsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalWalletsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalWalletsDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useExternalWalletsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>(ExternalWalletsDropdownDocument, options);
      }
export function useExternalWalletsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>(ExternalWalletsDropdownDocument, options);
        }
export function useExternalWalletsDropdownSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>(ExternalWalletsDropdownDocument, options);
        }
export type ExternalWalletsDropdownQueryHookResult = ReturnType<typeof useExternalWalletsDropdownQuery>;
export type ExternalWalletsDropdownLazyQueryHookResult = ReturnType<typeof useExternalWalletsDropdownLazyQuery>;
export type ExternalWalletsDropdownSuspenseQueryHookResult = ReturnType<typeof useExternalWalletsDropdownSuspenseQuery>;
export type ExternalWalletsDropdownQueryResult = Apollo.QueryResult<ExternalWalletsDropdownQuery, ExternalWalletsDropdownQueryVariables>;
export const AvailableDeploymentsDocument = gql`
    query availableDeployments {
  tokens {
    id
    name
    symbol
    deployments {
      id
      network
    }
  }
}
    `;

/**
 * __useAvailableDeploymentsQuery__
 *
 * To run a query within a React component, call `useAvailableDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDeploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>(AvailableDeploymentsDocument, options);
      }
export function useAvailableDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>(AvailableDeploymentsDocument, options);
        }
export function useAvailableDeploymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>(AvailableDeploymentsDocument, options);
        }
export type AvailableDeploymentsQueryHookResult = ReturnType<typeof useAvailableDeploymentsQuery>;
export type AvailableDeploymentsLazyQueryHookResult = ReturnType<typeof useAvailableDeploymentsLazyQuery>;
export type AvailableDeploymentsSuspenseQueryHookResult = ReturnType<typeof useAvailableDeploymentsSuspenseQuery>;
export type AvailableDeploymentsQueryResult = Apollo.QueryResult<AvailableDeploymentsQuery, AvailableDeploymentsQueryVariables>;
export const ActivateExternalWalletBySigningMessageDocument = gql`
    mutation activateExternalWalletBySigningMessage($input: ActivateExternalWalletBySigningMessageInput!) {
  activateExternalWalletBySigningMessage(input: $input) {
    id
  }
}
    `;
export type ActivateExternalWalletBySigningMessageMutationFn = Apollo.MutationFunction<ActivateExternalWalletBySigningMessageMutation, ActivateExternalWalletBySigningMessageMutationVariables>;

/**
 * __useActivateExternalWalletBySigningMessageMutation__
 *
 * To run a mutation, you first call `useActivateExternalWalletBySigningMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateExternalWalletBySigningMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateExternalWalletBySigningMessageMutation, { data, loading, error }] = useActivateExternalWalletBySigningMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateExternalWalletBySigningMessageMutation(baseOptions?: Apollo.MutationHookOptions<ActivateExternalWalletBySigningMessageMutation, ActivateExternalWalletBySigningMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateExternalWalletBySigningMessageMutation, ActivateExternalWalletBySigningMessageMutationVariables>(ActivateExternalWalletBySigningMessageDocument, options);
      }
export type ActivateExternalWalletBySigningMessageMutationHookResult = ReturnType<typeof useActivateExternalWalletBySigningMessageMutation>;
export type ActivateExternalWalletBySigningMessageMutationResult = Apollo.MutationResult<ActivateExternalWalletBySigningMessageMutation>;
export type ActivateExternalWalletBySigningMessageMutationOptions = Apollo.BaseMutationOptions<ActivateExternalWalletBySigningMessageMutation, ActivateExternalWalletBySigningMessageMutationVariables>;
export const CreateExternalWalletBySigningMessageDocument = gql`
    mutation createExternalWalletBySigningMessage($input: CreateExternalWalletInput!) {
  createExternalWalletBySigningMessage(input: $input) {
    id
  }
}
    `;
export type CreateExternalWalletBySigningMessageMutationFn = Apollo.MutationFunction<CreateExternalWalletBySigningMessageMutation, CreateExternalWalletBySigningMessageMutationVariables>;

/**
 * __useCreateExternalWalletBySigningMessageMutation__
 *
 * To run a mutation, you first call `useCreateExternalWalletBySigningMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWalletBySigningMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWalletBySigningMessageMutation, { data, loading, error }] = useCreateExternalWalletBySigningMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWalletBySigningMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalWalletBySigningMessageMutation, CreateExternalWalletBySigningMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalWalletBySigningMessageMutation, CreateExternalWalletBySigningMessageMutationVariables>(CreateExternalWalletBySigningMessageDocument, options);
      }
export type CreateExternalWalletBySigningMessageMutationHookResult = ReturnType<typeof useCreateExternalWalletBySigningMessageMutation>;
export type CreateExternalWalletBySigningMessageMutationResult = Apollo.MutationResult<CreateExternalWalletBySigningMessageMutation>;
export type CreateExternalWalletBySigningMessageMutationOptions = Apollo.BaseMutationOptions<CreateExternalWalletBySigningMessageMutation, CreateExternalWalletBySigningMessageMutationVariables>;
export const DeactivateExternalWalletDocument = gql`
    mutation deactivateExternalWallet($input: DeactivateExternalWalletInput!) {
  deactivateExternalWallet(input: $input) {
    id
  }
}
    `;
export type DeactivateExternalWalletMutationFn = Apollo.MutationFunction<DeactivateExternalWalletMutation, DeactivateExternalWalletMutationVariables>;

/**
 * __useDeactivateExternalWalletMutation__
 *
 * To run a mutation, you first call `useDeactivateExternalWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateExternalWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateExternalWalletMutation, { data, loading, error }] = useDeactivateExternalWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateExternalWalletMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateExternalWalletMutation, DeactivateExternalWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateExternalWalletMutation, DeactivateExternalWalletMutationVariables>(DeactivateExternalWalletDocument, options);
      }
export type DeactivateExternalWalletMutationHookResult = ReturnType<typeof useDeactivateExternalWalletMutation>;
export type DeactivateExternalWalletMutationResult = Apollo.MutationResult<DeactivateExternalWalletMutation>;
export type DeactivateExternalWalletMutationOptions = Apollo.BaseMutationOptions<DeactivateExternalWalletMutation, DeactivateExternalWalletMutationVariables>;
export const CreateExternalWalletMicroTransactionDocument = gql`
    mutation createExternalWalletMicroTransaction($input: CreateExternalWalletMicroTransactionInput!) {
  createExternalWalletMicroTransaction(input: $input) {
    id
    verifyAmount
    walletAddress
  }
}
    `;
export type CreateExternalWalletMicroTransactionMutationFn = Apollo.MutationFunction<CreateExternalWalletMicroTransactionMutation, CreateExternalWalletMicroTransactionMutationVariables>;

/**
 * __useCreateExternalWalletMicroTransactionMutation__
 *
 * To run a mutation, you first call `useCreateExternalWalletMicroTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWalletMicroTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWalletMicroTransactionMutation, { data, loading, error }] = useCreateExternalWalletMicroTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWalletMicroTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalWalletMicroTransactionMutation, CreateExternalWalletMicroTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalWalletMicroTransactionMutation, CreateExternalWalletMicroTransactionMutationVariables>(CreateExternalWalletMicroTransactionDocument, options);
      }
export type CreateExternalWalletMicroTransactionMutationHookResult = ReturnType<typeof useCreateExternalWalletMicroTransactionMutation>;
export type CreateExternalWalletMicroTransactionMutationResult = Apollo.MutationResult<CreateExternalWalletMicroTransactionMutation>;
export type CreateExternalWalletMicroTransactionMutationOptions = Apollo.BaseMutationOptions<CreateExternalWalletMicroTransactionMutation, CreateExternalWalletMicroTransactionMutationVariables>;
export const MicroTransactionWalletAddressDocument = gql`
    query microTransactionWalletAddress($network: BlockchainNetwork!) {
  blockchainNetworkConfiguration(network: $network) {
    microTransactionWalletAddress
    nativeCurrency
  }
}
    `;

/**
 * __useMicroTransactionWalletAddressQuery__
 *
 * To run a query within a React component, call `useMicroTransactionWalletAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicroTransactionWalletAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicroTransactionWalletAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useMicroTransactionWalletAddressQuery(baseOptions: Apollo.QueryHookOptions<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables> & ({ variables: MicroTransactionWalletAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>(MicroTransactionWalletAddressDocument, options);
      }
export function useMicroTransactionWalletAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>(MicroTransactionWalletAddressDocument, options);
        }
export function useMicroTransactionWalletAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>(MicroTransactionWalletAddressDocument, options);
        }
export type MicroTransactionWalletAddressQueryHookResult = ReturnType<typeof useMicroTransactionWalletAddressQuery>;
export type MicroTransactionWalletAddressLazyQueryHookResult = ReturnType<typeof useMicroTransactionWalletAddressLazyQuery>;
export type MicroTransactionWalletAddressSuspenseQueryHookResult = ReturnType<typeof useMicroTransactionWalletAddressSuspenseQuery>;
export type MicroTransactionWalletAddressQueryResult = Apollo.QueryResult<MicroTransactionWalletAddressQuery, MicroTransactionWalletAddressQueryVariables>;
export const CreateExternalWalletSigningMessageDocument = gql`
    mutation createExternalWalletSigningMessage($input: CreateExternalWalletSigningMessageInput!) {
  createExternalWalletSigningMessage(input: $input) {
    id
    message
    walletAddress
  }
}
    `;
export type CreateExternalWalletSigningMessageMutationFn = Apollo.MutationFunction<CreateExternalWalletSigningMessageMutation, CreateExternalWalletSigningMessageMutationVariables>;

/**
 * __useCreateExternalWalletSigningMessageMutation__
 *
 * To run a mutation, you first call `useCreateExternalWalletSigningMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWalletSigningMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWalletSigningMessageMutation, { data, loading, error }] = useCreateExternalWalletSigningMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWalletSigningMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalWalletSigningMessageMutation, CreateExternalWalletSigningMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalWalletSigningMessageMutation, CreateExternalWalletSigningMessageMutationVariables>(CreateExternalWalletSigningMessageDocument, options);
      }
export type CreateExternalWalletSigningMessageMutationHookResult = ReturnType<typeof useCreateExternalWalletSigningMessageMutation>;
export type CreateExternalWalletSigningMessageMutationResult = Apollo.MutationResult<CreateExternalWalletSigningMessageMutation>;
export type CreateExternalWalletSigningMessageMutationOptions = Apollo.BaseMutationOptions<CreateExternalWalletSigningMessageMutation, CreateExternalWalletSigningMessageMutationVariables>;
export const AssetReservesDocument = gql`
    query assetReserves {
  assetReserves {
    symbol
    provider
    reserves
    inTransit
    tokensInCirculation
    timestamp
  }
}
    `;

/**
 * __useAssetReservesQuery__
 *
 * To run a query within a React component, call `useAssetReservesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetReservesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetReservesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetReservesQuery(baseOptions?: Apollo.QueryHookOptions<AssetReservesQuery, AssetReservesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetReservesQuery, AssetReservesQueryVariables>(AssetReservesDocument, options);
      }
export function useAssetReservesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetReservesQuery, AssetReservesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetReservesQuery, AssetReservesQueryVariables>(AssetReservesDocument, options);
        }
export function useAssetReservesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AssetReservesQuery, AssetReservesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssetReservesQuery, AssetReservesQueryVariables>(AssetReservesDocument, options);
        }
export type AssetReservesQueryHookResult = ReturnType<typeof useAssetReservesQuery>;
export type AssetReservesLazyQueryHookResult = ReturnType<typeof useAssetReservesLazyQuery>;
export type AssetReservesSuspenseQueryHookResult = ReturnType<typeof useAssetReservesSuspenseQuery>;
export type AssetReservesQueryResult = Apollo.QueryResult<AssetReservesQuery, AssetReservesQueryVariables>;
export const DashboardDocument = gql`
    query dashboard {
  tokens {
    id
    name
    symbol
    collateral {
      tradingViewId
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export function useDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardSuspenseQueryHookResult = ReturnType<typeof useDashboardSuspenseQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const IssueDetailsDocument = gql`
    query issueDetails($network: BlockchainNetwork!) {
  tokens {
    id
    name
    symbol
    deployments {
      id
      network
      chainId
      address
      stablecoinConfigs {
        symbol
        decimals
        address
        network
        chainId
        minimumTransactionValue
      }
    }
    collateral {
      exchange {
        isOpen
      }
    }
    sweepingWallet(network: $network) {
      id
      address
    }
  }
}
    `;

/**
 * __useIssueDetailsQuery__
 *
 * To run a query within a React component, call `useIssueDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueDetailsQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useIssueDetailsQuery(baseOptions: Apollo.QueryHookOptions<IssueDetailsQuery, IssueDetailsQueryVariables> & ({ variables: IssueDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssueDetailsQuery, IssueDetailsQueryVariables>(IssueDetailsDocument, options);
      }
export function useIssueDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssueDetailsQuery, IssueDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssueDetailsQuery, IssueDetailsQueryVariables>(IssueDetailsDocument, options);
        }
export function useIssueDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IssueDetailsQuery, IssueDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssueDetailsQuery, IssueDetailsQueryVariables>(IssueDetailsDocument, options);
        }
export type IssueDetailsQueryHookResult = ReturnType<typeof useIssueDetailsQuery>;
export type IssueDetailsLazyQueryHookResult = ReturnType<typeof useIssueDetailsLazyQuery>;
export type IssueDetailsSuspenseQueryHookResult = ReturnType<typeof useIssueDetailsSuspenseQuery>;
export type IssueDetailsQueryResult = Apollo.QueryResult<IssueDetailsQuery, IssueDetailsQueryVariables>;
export const RedemptionWalletDocument = gql`
    query redemptionWallet($network: BlockchainNetwork!) {
  client {
    redemptionWallet(network: $network) {
      id
      address
      networks
    }
  }
  tokens {
    id
    name
    symbol
    minimumTransactionValue
    deployments {
      id
      network
      chainId
      address
    }
  }
}
    `;

/**
 * __useRedemptionWalletQuery__
 *
 * To run a query within a React component, call `useRedemptionWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedemptionWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedemptionWalletQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRedemptionWalletQuery(baseOptions: Apollo.QueryHookOptions<RedemptionWalletQuery, RedemptionWalletQueryVariables> & ({ variables: RedemptionWalletQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedemptionWalletQuery, RedemptionWalletQueryVariables>(RedemptionWalletDocument, options);
      }
export function useRedemptionWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedemptionWalletQuery, RedemptionWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedemptionWalletQuery, RedemptionWalletQueryVariables>(RedemptionWalletDocument, options);
        }
export function useRedemptionWalletSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RedemptionWalletQuery, RedemptionWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RedemptionWalletQuery, RedemptionWalletQueryVariables>(RedemptionWalletDocument, options);
        }
export type RedemptionWalletQueryHookResult = ReturnType<typeof useRedemptionWalletQuery>;
export type RedemptionWalletLazyQueryHookResult = ReturnType<typeof useRedemptionWalletLazyQuery>;
export type RedemptionWalletSuspenseQueryHookResult = ReturnType<typeof useRedemptionWalletSuspenseQuery>;
export type RedemptionWalletQueryResult = Apollo.QueryResult<RedemptionWalletQuery, RedemptionWalletQueryVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation updateQuestionnaire($input: UpdateClientQuestionnaireInput!) {
  updateClientQuestionnaire(input: $input) {
    id
  }
}
    `;
export type UpdateQuestionnaireMutationFn = Apollo.MutationFunction<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<UpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;
export const CreateClientDocument = gql`
    mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
    result
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const FinaliseQuestionnaireDocument = gql`
    mutation finaliseQuestionnaire($input: FinaliseClientQuestionnaireInput!) {
  finaliseClientQuestionnaire(input: $input) {
    id
    result
  }
}
    `;
export type FinaliseQuestionnaireMutationFn = Apollo.MutationFunction<FinaliseQuestionnaireMutation, FinaliseQuestionnaireMutationVariables>;

/**
 * __useFinaliseQuestionnaireMutation__
 *
 * To run a mutation, you first call `useFinaliseQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinaliseQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finaliseQuestionnaireMutation, { data, loading, error }] = useFinaliseQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinaliseQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<FinaliseQuestionnaireMutation, FinaliseQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinaliseQuestionnaireMutation, FinaliseQuestionnaireMutationVariables>(FinaliseQuestionnaireDocument, options);
      }
export type FinaliseQuestionnaireMutationHookResult = ReturnType<typeof useFinaliseQuestionnaireMutation>;
export type FinaliseQuestionnaireMutationResult = Apollo.MutationResult<FinaliseQuestionnaireMutation>;
export type FinaliseQuestionnaireMutationOptions = Apollo.BaseMutationOptions<FinaliseQuestionnaireMutation, FinaliseQuestionnaireMutationVariables>;
export const OnboardingRoutingDocument = gql`
    query onboardingRouting {
  client {
    status
    questionnaires(where: {type: OnboardingQuestionnaire}) {
      id
      status
      payload
    }
  }
}
    `;

/**
 * __useOnboardingRoutingQuery__
 *
 * To run a query within a React component, call `useOnboardingRoutingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingRoutingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingRoutingQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingRoutingQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>(OnboardingRoutingDocument, options);
      }
export function useOnboardingRoutingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>(OnboardingRoutingDocument, options);
        }
export function useOnboardingRoutingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>(OnboardingRoutingDocument, options);
        }
export type OnboardingRoutingQueryHookResult = ReturnType<typeof useOnboardingRoutingQuery>;
export type OnboardingRoutingLazyQueryHookResult = ReturnType<typeof useOnboardingRoutingLazyQuery>;
export type OnboardingRoutingSuspenseQueryHookResult = ReturnType<typeof useOnboardingRoutingSuspenseQuery>;
export type OnboardingRoutingQueryResult = Apollo.QueryResult<OnboardingRoutingQuery, OnboardingRoutingQueryVariables>;
export const CreateVerificationDocument = gql`
    mutation createVerification {
  createVerification {
    vendorToken
  }
}
    `;
export type CreateVerificationMutationFn = Apollo.MutationFunction<CreateVerificationMutation, CreateVerificationMutationVariables>;

/**
 * __useCreateVerificationMutation__
 *
 * To run a mutation, you first call `useCreateVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVerificationMutation, { data, loading, error }] = useCreateVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateVerificationMutation, CreateVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVerificationMutation, CreateVerificationMutationVariables>(CreateVerificationDocument, options);
      }
export type CreateVerificationMutationHookResult = ReturnType<typeof useCreateVerificationMutation>;
export type CreateVerificationMutationResult = Apollo.MutationResult<CreateVerificationMutation>;
export type CreateVerificationMutationOptions = Apollo.BaseMutationOptions<CreateVerificationMutation, CreateVerificationMutationVariables>;
export const TransactionFeesDocument = gql`
    query transactionFees {
  tokens {
    name
    symbol
    deployments {
      network
      chainId
      feeConfiguration {
        id
        issuanceInitialFee
        issuanceMinimumFee
        issuancePercentageFee
        redemptionInitialFee
        redemptionMinimumFee
        redemptionPercentageFee
      }
    }
  }
}
    `;

/**
 * __useTransactionFeesQuery__
 *
 * To run a query within a React component, call `useTransactionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionFeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionFeesQuery(baseOptions?: Apollo.QueryHookOptions<TransactionFeesQuery, TransactionFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionFeesQuery, TransactionFeesQueryVariables>(TransactionFeesDocument, options);
      }
export function useTransactionFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionFeesQuery, TransactionFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionFeesQuery, TransactionFeesQueryVariables>(TransactionFeesDocument, options);
        }
export function useTransactionFeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TransactionFeesQuery, TransactionFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionFeesQuery, TransactionFeesQueryVariables>(TransactionFeesDocument, options);
        }
export type TransactionFeesQueryHookResult = ReturnType<typeof useTransactionFeesQuery>;
export type TransactionFeesLazyQueryHookResult = ReturnType<typeof useTransactionFeesLazyQuery>;
export type TransactionFeesSuspenseQueryHookResult = ReturnType<typeof useTransactionFeesSuspenseQuery>;
export type TransactionFeesQueryResult = Apollo.QueryResult<TransactionFeesQuery, TransactionFeesQueryVariables>;
export const AccountDetailsDocument = gql`
    query accountDetails {
  client {
    id
    name
    type
    jurisdiction
  }
  user {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
      }
export function useAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
        }
export function useAccountDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
        }
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsSuspenseQueryHookResult = ReturnType<typeof useAccountDetailsSuspenseQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<AccountDetailsQuery, AccountDetailsQueryVariables>;
export const ApiSettingsDataDocument = gql`
    query apiSettingsData($page: Int, $pageSize: Int) {
  serviceAccounts(page: $page, pageSize: $pageSize) {
    page {
      totalNodes
      totalPages
      currentPage
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      createdAt
      updatedAt
      status
      title
    }
  }
}
    `;

/**
 * __useApiSettingsDataQuery__
 *
 * To run a query within a React component, call `useApiSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSettingsDataQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useApiSettingsDataQuery(baseOptions?: Apollo.QueryHookOptions<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>(ApiSettingsDataDocument, options);
      }
export function useApiSettingsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>(ApiSettingsDataDocument, options);
        }
export function useApiSettingsDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>(ApiSettingsDataDocument, options);
        }
export type ApiSettingsDataQueryHookResult = ReturnType<typeof useApiSettingsDataQuery>;
export type ApiSettingsDataLazyQueryHookResult = ReturnType<typeof useApiSettingsDataLazyQuery>;
export type ApiSettingsDataSuspenseQueryHookResult = ReturnType<typeof useApiSettingsDataSuspenseQuery>;
export type ApiSettingsDataQueryResult = Apollo.QueryResult<ApiSettingsDataQuery, ApiSettingsDataQueryVariables>;
export const FetchDefaultBankAccountDocument = gql`
    query fetchDefaultBankAccount {
  clientPreferences {
    defaultBankAccountId
  }
}
    `;

/**
 * __useFetchDefaultBankAccountQuery__
 *
 * To run a query within a React component, call `useFetchDefaultBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDefaultBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDefaultBankAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDefaultBankAccountQuery(baseOptions?: Apollo.QueryHookOptions<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>(FetchDefaultBankAccountDocument, options);
      }
export function useFetchDefaultBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>(FetchDefaultBankAccountDocument, options);
        }
export function useFetchDefaultBankAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>(FetchDefaultBankAccountDocument, options);
        }
export type FetchDefaultBankAccountQueryHookResult = ReturnType<typeof useFetchDefaultBankAccountQuery>;
export type FetchDefaultBankAccountLazyQueryHookResult = ReturnType<typeof useFetchDefaultBankAccountLazyQuery>;
export type FetchDefaultBankAccountSuspenseQueryHookResult = ReturnType<typeof useFetchDefaultBankAccountSuspenseQuery>;
export type FetchDefaultBankAccountQueryResult = Apollo.QueryResult<FetchDefaultBankAccountQuery, FetchDefaultBankAccountQueryVariables>;
export const UpdateDefaultBankAccountDocument = gql`
    mutation updateDefaultBankAccount($input: UpdateDefaultBankAccountInput!) {
  updateDefaultBankAccount(input: $input)
}
    `;
export type UpdateDefaultBankAccountMutationFn = Apollo.MutationFunction<UpdateDefaultBankAccountMutation, UpdateDefaultBankAccountMutationVariables>;

/**
 * __useUpdateDefaultBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultBankAccountMutation, { data, loading, error }] = useUpdateDefaultBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultBankAccountMutation, UpdateDefaultBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultBankAccountMutation, UpdateDefaultBankAccountMutationVariables>(UpdateDefaultBankAccountDocument, options);
      }
export type UpdateDefaultBankAccountMutationHookResult = ReturnType<typeof useUpdateDefaultBankAccountMutation>;
export type UpdateDefaultBankAccountMutationResult = Apollo.MutationResult<UpdateDefaultBankAccountMutation>;
export type UpdateDefaultBankAccountMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultBankAccountMutation, UpdateDefaultBankAccountMutationVariables>;
export const FetchAllBankAccountsDocument = gql`
    query fetchAllBankAccounts {
  bankAccounts {
    id
    description
    nameOnAccount
    iban
    swift
    bankName
    bankAddress
  }
}
    `;

/**
 * __useFetchAllBankAccountsQuery__
 *
 * To run a query within a React component, call `useFetchAllBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllBankAccountsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>(FetchAllBankAccountsDocument, options);
      }
export function useFetchAllBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>(FetchAllBankAccountsDocument, options);
        }
export function useFetchAllBankAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>(FetchAllBankAccountsDocument, options);
        }
export type FetchAllBankAccountsQueryHookResult = ReturnType<typeof useFetchAllBankAccountsQuery>;
export type FetchAllBankAccountsLazyQueryHookResult = ReturnType<typeof useFetchAllBankAccountsLazyQuery>;
export type FetchAllBankAccountsSuspenseQueryHookResult = ReturnType<typeof useFetchAllBankAccountsSuspenseQuery>;
export type FetchAllBankAccountsQueryResult = Apollo.QueryResult<FetchAllBankAccountsQuery, FetchAllBankAccountsQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: CreateBankAccountInput!) {
  createBankAccount(input: $input) {
    id
  }
}
    `;
export type CreateBankAccountMutationFn = Apollo.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, options);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = Apollo.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = Apollo.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const RegisteredWalletsDocument = gql`
    query registeredWallets {
  externalWallets {
    id
    createdAt
    updatedAt
    status
    address
  }
}
    `;

/**
 * __useRegisteredWalletsQuery__
 *
 * To run a query within a React component, call `useRegisteredWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisteredWalletsQuery(baseOptions?: Apollo.QueryHookOptions<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>(RegisteredWalletsDocument, options);
      }
export function useRegisteredWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>(RegisteredWalletsDocument, options);
        }
export function useRegisteredWalletsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>(RegisteredWalletsDocument, options);
        }
export type RegisteredWalletsQueryHookResult = ReturnType<typeof useRegisteredWalletsQuery>;
export type RegisteredWalletsLazyQueryHookResult = ReturnType<typeof useRegisteredWalletsLazyQuery>;
export type RegisteredWalletsSuspenseQueryHookResult = ReturnType<typeof useRegisteredWalletsSuspenseQuery>;
export type RegisteredWalletsQueryResult = Apollo.QueryResult<RegisteredWalletsQuery, RegisteredWalletsQueryVariables>;
export const SetUserPasswordDocument = gql`
    mutation setUserPassword($input: SetUserPasswordInput!) {
  setUserPassword(input: $input)
}
    `;
export type SetUserPasswordMutationFn = Apollo.MutationFunction<SetUserPasswordMutation, SetUserPasswordMutationVariables>;

/**
 * __useSetUserPasswordMutation__
 *
 * To run a mutation, you first call `useSetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPasswordMutation, { data, loading, error }] = useSetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPasswordMutation, SetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPasswordMutation, SetUserPasswordMutationVariables>(SetUserPasswordDocument, options);
      }
export type SetUserPasswordMutationHookResult = ReturnType<typeof useSetUserPasswordMutation>;
export type SetUserPasswordMutationResult = Apollo.MutationResult<SetUserPasswordMutation>;
export type SetUserPasswordMutationOptions = Apollo.BaseMutationOptions<SetUserPasswordMutation, SetUserPasswordMutationVariables>;
export const CreateServiceAccountDocument = gql`
    mutation createServiceAccount($input: CreateServiceAccountInput!) {
  createServiceAccount(input: $input) {
    key
  }
}
    `;
export type CreateServiceAccountMutationFn = Apollo.MutationFunction<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;

/**
 * __useCreateServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAccountMutation, { data, loading, error }] = useCreateServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>(CreateServiceAccountDocument, options);
      }
export type CreateServiceAccountMutationHookResult = ReturnType<typeof useCreateServiceAccountMutation>;
export type CreateServiceAccountMutationResult = Apollo.MutationResult<CreateServiceAccountMutation>;
export type CreateServiceAccountMutationOptions = Apollo.BaseMutationOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;
export const DepositPageRedemptionDataDocument = gql`
    query depositPageRedemptionData($network: BlockchainNetwork!) {
  client {
    redemptionWallet(network: $network) {
      id
      address
    }
  }
}
    `;

/**
 * __useDepositPageRedemptionDataQuery__
 *
 * To run a query within a React component, call `useDepositPageRedemptionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositPageRedemptionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositPageRedemptionDataQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useDepositPageRedemptionDataQuery(baseOptions: Apollo.QueryHookOptions<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables> & ({ variables: DepositPageRedemptionDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>(DepositPageRedemptionDataDocument, options);
      }
export function useDepositPageRedemptionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>(DepositPageRedemptionDataDocument, options);
        }
export function useDepositPageRedemptionDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>(DepositPageRedemptionDataDocument, options);
        }
export type DepositPageRedemptionDataQueryHookResult = ReturnType<typeof useDepositPageRedemptionDataQuery>;
export type DepositPageRedemptionDataLazyQueryHookResult = ReturnType<typeof useDepositPageRedemptionDataLazyQuery>;
export type DepositPageRedemptionDataSuspenseQueryHookResult = ReturnType<typeof useDepositPageRedemptionDataSuspenseQuery>;
export type DepositPageRedemptionDataQueryResult = Apollo.QueryResult<DepositPageRedemptionDataQuery, DepositPageRedemptionDataQueryVariables>;
export const DepositPageIssuanceDataDocument = gql`
    query depositPageIssuanceData {
  tokens {
    id
    name
    symbol
    deployments {
      network
      stablecoinConfigs {
        symbol
      }
      sweepingWallet {
        id
        address
      }
    }
  }
}
    `;

/**
 * __useDepositPageIssuanceDataQuery__
 *
 * To run a query within a React component, call `useDepositPageIssuanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositPageIssuanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositPageIssuanceDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepositPageIssuanceDataQuery(baseOptions?: Apollo.QueryHookOptions<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>(DepositPageIssuanceDataDocument, options);
      }
export function useDepositPageIssuanceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>(DepositPageIssuanceDataDocument, options);
        }
export function useDepositPageIssuanceDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>(DepositPageIssuanceDataDocument, options);
        }
export type DepositPageIssuanceDataQueryHookResult = ReturnType<typeof useDepositPageIssuanceDataQuery>;
export type DepositPageIssuanceDataLazyQueryHookResult = ReturnType<typeof useDepositPageIssuanceDataLazyQuery>;
export type DepositPageIssuanceDataSuspenseQueryHookResult = ReturnType<typeof useDepositPageIssuanceDataSuspenseQuery>;
export type DepositPageIssuanceDataQueryResult = Apollo.QueryResult<DepositPageIssuanceDataQuery, DepositPageIssuanceDataQueryVariables>;
export const LoadClientPreferencesDocument = gql`
    query loadClientPreferences {
  stablecoins {
    id
    symbol
  }
  clientPreferences {
    id
    payoutMethod
    executionMode
    bankAccountPayoutCurrency
    defaultStablecoin {
      id
      symbol
    }
  }
  bankAccounts {
    id
    iban
    verificationStatus
  }
}
    `;

/**
 * __useLoadClientPreferencesQuery__
 *
 * To run a query within a React component, call `useLoadClientPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadClientPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadClientPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadClientPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>(LoadClientPreferencesDocument, options);
      }
export function useLoadClientPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>(LoadClientPreferencesDocument, options);
        }
export function useLoadClientPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>(LoadClientPreferencesDocument, options);
        }
export type LoadClientPreferencesQueryHookResult = ReturnType<typeof useLoadClientPreferencesQuery>;
export type LoadClientPreferencesLazyQueryHookResult = ReturnType<typeof useLoadClientPreferencesLazyQuery>;
export type LoadClientPreferencesSuspenseQueryHookResult = ReturnType<typeof useLoadClientPreferencesSuspenseQuery>;
export type LoadClientPreferencesQueryResult = Apollo.QueryResult<LoadClientPreferencesQuery, LoadClientPreferencesQueryVariables>;
export const UpdateClientPreferencesDocument = gql`
    mutation updateClientPreferences($input: UpdateClientPreferencesInput!) {
  updateClientPreferences(input: $input) {
    id
  }
}
    `;
export type UpdateClientPreferencesMutationFn = Apollo.MutationFunction<UpdateClientPreferencesMutation, UpdateClientPreferencesMutationVariables>;

/**
 * __useUpdateClientPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateClientPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientPreferencesMutation, { data, loading, error }] = useUpdateClientPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientPreferencesMutation, UpdateClientPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientPreferencesMutation, UpdateClientPreferencesMutationVariables>(UpdateClientPreferencesDocument, options);
      }
export type UpdateClientPreferencesMutationHookResult = ReturnType<typeof useUpdateClientPreferencesMutation>;
export type UpdateClientPreferencesMutationResult = Apollo.MutationResult<UpdateClientPreferencesMutation>;
export type UpdateClientPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateClientPreferencesMutation, UpdateClientPreferencesMutationVariables>;
export const ClientInteractionsDocument = gql`
    query clientInteractions($where: InteractionWhereInput) {
  interactions(where: $where) {
    nodes {
      id
      createdAt
      status
      type
      failureReason
      orders {
        pricePerShare
      }
      fee {
        fee
        principal
      }
      tokenDeployments {
        token {
          symbol
        }
      }
      incomingTransactions {
        id
        hash
        amount
        confirmations
        confirmationsNeeded
        displayAmount
        tokenSymbol
        network
        explorerUrl
        decimals
        type
        refund {
          status
        }
      }
      outgoingTransactions {
        amount
        displayAmount
        type
      }
    }
  }
}
    `;

/**
 * __useClientInteractionsQuery__
 *
 * To run a query within a React component, call `useClientInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInteractionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientInteractionsQuery(baseOptions?: Apollo.QueryHookOptions<ClientInteractionsQuery, ClientInteractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientInteractionsQuery, ClientInteractionsQueryVariables>(ClientInteractionsDocument, options);
      }
export function useClientInteractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInteractionsQuery, ClientInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientInteractionsQuery, ClientInteractionsQueryVariables>(ClientInteractionsDocument, options);
        }
export function useClientInteractionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientInteractionsQuery, ClientInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientInteractionsQuery, ClientInteractionsQueryVariables>(ClientInteractionsDocument, options);
        }
export type ClientInteractionsQueryHookResult = ReturnType<typeof useClientInteractionsQuery>;
export type ClientInteractionsLazyQueryHookResult = ReturnType<typeof useClientInteractionsLazyQuery>;
export type ClientInteractionsSuspenseQueryHookResult = ReturnType<typeof useClientInteractionsSuspenseQuery>;
export type ClientInteractionsQueryResult = Apollo.QueryResult<ClientInteractionsQuery, ClientInteractionsQueryVariables>;
export const ClientInteractionDetailsDocument = gql`
    query clientInteractionDetails($interactionId: String!) {
  interaction(where: {id: $interactionId}) {
    id
    status
    type
    createdAt
    tokenDeployments {
      token {
        symbol
      }
    }
    incomingTransactions {
      id
      hash
      network
      amount
      decimals
      explorerUrl
      type
      tokenSymbol
    }
    outgoingTransactions {
      id
      hash
      network
      explorerUrl
      createdAt
      tokenSymbol
      type
      amount
      decimals
    }
    fee {
      id
      fee
      principal
    }
    orders {
      id
      createdAt
      pricePerShare
    }
    invoice {
      id
      downloadUrl
    }
    incomingTransfers {
      ...InteractionBankTransferDetails
    }
    outgoingTransfers {
      ...InteractionBankTransferDetails
    }
  }
}
    ${InteractionBankTransferDetailsFragmentDoc}`;

/**
 * __useClientInteractionDetailsQuery__
 *
 * To run a query within a React component, call `useClientInteractionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInteractionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInteractionDetailsQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useClientInteractionDetailsQuery(baseOptions: Apollo.QueryHookOptions<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables> & ({ variables: ClientInteractionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>(ClientInteractionDetailsDocument, options);
      }
export function useClientInteractionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>(ClientInteractionDetailsDocument, options);
        }
export function useClientInteractionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>(ClientInteractionDetailsDocument, options);
        }
export type ClientInteractionDetailsQueryHookResult = ReturnType<typeof useClientInteractionDetailsQuery>;
export type ClientInteractionDetailsLazyQueryHookResult = ReturnType<typeof useClientInteractionDetailsLazyQuery>;
export type ClientInteractionDetailsSuspenseQueryHookResult = ReturnType<typeof useClientInteractionDetailsSuspenseQuery>;
export type ClientInteractionDetailsQueryResult = Apollo.QueryResult<ClientInteractionDetailsQuery, ClientInteractionDetailsQueryVariables>;
export const AuthenticateWithTransitionTokenDocument = gql`
    mutation authenticateWithTransitionToken($input: AuthenticateWithTransitionTokenInput!) {
  authenticateWithTransitionToken(input: $input) {
    ...authResult
  }
}
    ${AuthResultFragmentDoc}`;
export type AuthenticateWithTransitionTokenMutationFn = Apollo.MutationFunction<AuthenticateWithTransitionTokenMutation, AuthenticateWithTransitionTokenMutationVariables>;

/**
 * __useAuthenticateWithTransitionTokenMutation__
 *
 * To run a mutation, you first call `useAuthenticateWithTransitionTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateWithTransitionTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateWithTransitionTokenMutation, { data, loading, error }] = useAuthenticateWithTransitionTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateWithTransitionTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateWithTransitionTokenMutation, AuthenticateWithTransitionTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateWithTransitionTokenMutation, AuthenticateWithTransitionTokenMutationVariables>(AuthenticateWithTransitionTokenDocument, options);
      }
export type AuthenticateWithTransitionTokenMutationHookResult = ReturnType<typeof useAuthenticateWithTransitionTokenMutation>;
export type AuthenticateWithTransitionTokenMutationResult = Apollo.MutationResult<AuthenticateWithTransitionTokenMutation>;
export type AuthenticateWithTransitionTokenMutationOptions = Apollo.BaseMutationOptions<AuthenticateWithTransitionTokenMutation, AuthenticateWithTransitionTokenMutationVariables>;
export const StartEmailChallengeDocument = gql`
    mutation startEmailChallenge($input: StartEmailChallengeInput!) {
  startEmailChallenge(input: $input) {
    successful
    errorMessage
    hasPassword
  }
}
    `;
export type StartEmailChallengeMutationFn = Apollo.MutationFunction<StartEmailChallengeMutation, StartEmailChallengeMutationVariables>;

/**
 * __useStartEmailChallengeMutation__
 *
 * To run a mutation, you first call `useStartEmailChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEmailChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEmailChallengeMutation, { data, loading, error }] = useStartEmailChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartEmailChallengeMutation(baseOptions?: Apollo.MutationHookOptions<StartEmailChallengeMutation, StartEmailChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartEmailChallengeMutation, StartEmailChallengeMutationVariables>(StartEmailChallengeDocument, options);
      }
export type StartEmailChallengeMutationHookResult = ReturnType<typeof useStartEmailChallengeMutation>;
export type StartEmailChallengeMutationResult = Apollo.MutationResult<StartEmailChallengeMutation>;
export type StartEmailChallengeMutationOptions = Apollo.BaseMutationOptions<StartEmailChallengeMutation, StartEmailChallengeMutationVariables>;
export const AuthenticateWithEmailChallengeDocument = gql`
    mutation authenticateWithEmailChallenge($input: AuthenticateEmailChallengeInput!) {
  authenticateWithEmailChallenge(input: $input) {
    ...authResult
  }
}
    ${AuthResultFragmentDoc}`;
export type AuthenticateWithEmailChallengeMutationFn = Apollo.MutationFunction<AuthenticateWithEmailChallengeMutation, AuthenticateWithEmailChallengeMutationVariables>;

/**
 * __useAuthenticateWithEmailChallengeMutation__
 *
 * To run a mutation, you first call `useAuthenticateWithEmailChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateWithEmailChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateWithEmailChallengeMutation, { data, loading, error }] = useAuthenticateWithEmailChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateWithEmailChallengeMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateWithEmailChallengeMutation, AuthenticateWithEmailChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateWithEmailChallengeMutation, AuthenticateWithEmailChallengeMutationVariables>(AuthenticateWithEmailChallengeDocument, options);
      }
export type AuthenticateWithEmailChallengeMutationHookResult = ReturnType<typeof useAuthenticateWithEmailChallengeMutation>;
export type AuthenticateWithEmailChallengeMutationResult = Apollo.MutationResult<AuthenticateWithEmailChallengeMutation>;
export type AuthenticateWithEmailChallengeMutationOptions = Apollo.BaseMutationOptions<AuthenticateWithEmailChallengeMutation, AuthenticateWithEmailChallengeMutationVariables>;
export const AuthenticateWithPasswordChallengeDocument = gql`
    mutation authenticateWithPasswordChallenge($input: AuthenticateWithPasswordInput!) {
  authenticateWithPassword(input: $input) {
    ...authResult
  }
}
    ${AuthResultFragmentDoc}`;
export type AuthenticateWithPasswordChallengeMutationFn = Apollo.MutationFunction<AuthenticateWithPasswordChallengeMutation, AuthenticateWithPasswordChallengeMutationVariables>;

/**
 * __useAuthenticateWithPasswordChallengeMutation__
 *
 * To run a mutation, you first call `useAuthenticateWithPasswordChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateWithPasswordChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateWithPasswordChallengeMutation, { data, loading, error }] = useAuthenticateWithPasswordChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateWithPasswordChallengeMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateWithPasswordChallengeMutation, AuthenticateWithPasswordChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateWithPasswordChallengeMutation, AuthenticateWithPasswordChallengeMutationVariables>(AuthenticateWithPasswordChallengeDocument, options);
      }
export type AuthenticateWithPasswordChallengeMutationHookResult = ReturnType<typeof useAuthenticateWithPasswordChallengeMutation>;
export type AuthenticateWithPasswordChallengeMutationResult = Apollo.MutationResult<AuthenticateWithPasswordChallengeMutation>;
export type AuthenticateWithPasswordChallengeMutationOptions = Apollo.BaseMutationOptions<AuthenticateWithPasswordChallengeMutation, AuthenticateWithPasswordChallengeMutationVariables>;
export const GetClientContextDocument = gql`
    query GetClientContext {
  client {
    id
    name
    type
    status
    classification
    jurisdiction
    features
    hasWalletRegistered
    externalWallets {
      address
      status
    }
    isProfessional
    shouldRegisterWallet
    shouldActivateWallet
    verificationProfile {
      currentTier
      approved
    }
    limits {
      tier
      dailyTransactionLimit
      monthlyTransactionLimit
    }
    usage {
      issuanceDailyUsage
      redemptionDailyUsage
      issuanceMonthlyUsage
      redemptionMonthlyUsage
    }
  }
}
    `;

/**
 * __useGetClientContextQuery__
 *
 * To run a query within a React component, call `useGetClientContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientContextQuery(baseOptions?: Apollo.QueryHookOptions<GetClientContextQuery, GetClientContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientContextQuery, GetClientContextQueryVariables>(GetClientContextDocument, options);
      }
export function useGetClientContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientContextQuery, GetClientContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientContextQuery, GetClientContextQueryVariables>(GetClientContextDocument, options);
        }
export function useGetClientContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClientContextQuery, GetClientContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientContextQuery, GetClientContextQueryVariables>(GetClientContextDocument, options);
        }
export type GetClientContextQueryHookResult = ReturnType<typeof useGetClientContextQuery>;
export type GetClientContextLazyQueryHookResult = ReturnType<typeof useGetClientContextLazyQuery>;
export type GetClientContextSuspenseQueryHookResult = ReturnType<typeof useGetClientContextSuspenseQuery>;
export type GetClientContextQueryResult = Apollo.QueryResult<GetClientContextQuery, GetClientContextQueryVariables>;
export const GetCompanyContextDocument = gql`
    query getCompanyContext {
  company {
    id
    name
    logo
    features
  }
}
    `;

/**
 * __useGetCompanyContextQuery__
 *
 * To run a query within a React component, call `useGetCompanyContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyContextQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyContextQuery, GetCompanyContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyContextQuery, GetCompanyContextQueryVariables>(GetCompanyContextDocument, options);
      }
export function useGetCompanyContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyContextQuery, GetCompanyContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyContextQuery, GetCompanyContextQueryVariables>(GetCompanyContextDocument, options);
        }
export function useGetCompanyContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyContextQuery, GetCompanyContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyContextQuery, GetCompanyContextQueryVariables>(GetCompanyContextDocument, options);
        }
export type GetCompanyContextQueryHookResult = ReturnType<typeof useGetCompanyContextQuery>;
export type GetCompanyContextLazyQueryHookResult = ReturnType<typeof useGetCompanyContextLazyQuery>;
export type GetCompanyContextSuspenseQueryHookResult = ReturnType<typeof useGetCompanyContextSuspenseQuery>;
export type GetCompanyContextQueryResult = Apollo.QueryResult<GetCompanyContextQuery, GetCompanyContextQueryVariables>;
export const GetUserContextDocument = gql`
    query GetUserContext {
  user {
    id
    firstName
    lastName
    email
    features
    hasPasswordSet
    passwordLastChange
  }
}
    `;

/**
 * __useGetUserContextQuery__
 *
 * To run a query within a React component, call `useGetUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserContextQuery(baseOptions?: Apollo.QueryHookOptions<GetUserContextQuery, GetUserContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserContextQuery, GetUserContextQueryVariables>(GetUserContextDocument, options);
      }
export function useGetUserContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserContextQuery, GetUserContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserContextQuery, GetUserContextQueryVariables>(GetUserContextDocument, options);
        }
export function useGetUserContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserContextQuery, GetUserContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserContextQuery, GetUserContextQueryVariables>(GetUserContextDocument, options);
        }
export type GetUserContextQueryHookResult = ReturnType<typeof useGetUserContextQuery>;
export type GetUserContextLazyQueryHookResult = ReturnType<typeof useGetUserContextLazyQuery>;
export type GetUserContextSuspenseQueryHookResult = ReturnType<typeof useGetUserContextSuspenseQuery>;
export type GetUserContextQueryResult = Apollo.QueryResult<GetUserContextQuery, GetUserContextQueryVariables>;
export const SystemConfigurationDocument = gql`
    query systemConfiguration($key: SystemConfigurationKey!) {
  systemConfiguration(key: $key) {
    value
  }
}
    `;

/**
 * __useSystemConfigurationQuery__
 *
 * To run a query within a React component, call `useSystemConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemConfigurationQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSystemConfigurationQuery(baseOptions: Apollo.QueryHookOptions<SystemConfigurationQuery, SystemConfigurationQueryVariables> & ({ variables: SystemConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemConfigurationQuery, SystemConfigurationQueryVariables>(SystemConfigurationDocument, options);
      }
export function useSystemConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemConfigurationQuery, SystemConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemConfigurationQuery, SystemConfigurationQueryVariables>(SystemConfigurationDocument, options);
        }
export function useSystemConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemConfigurationQuery, SystemConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemConfigurationQuery, SystemConfigurationQueryVariables>(SystemConfigurationDocument, options);
        }
export type SystemConfigurationQueryHookResult = ReturnType<typeof useSystemConfigurationQuery>;
export type SystemConfigurationLazyQueryHookResult = ReturnType<typeof useSystemConfigurationLazyQuery>;
export type SystemConfigurationSuspenseQueryHookResult = ReturnType<typeof useSystemConfigurationSuspenseQuery>;
export type SystemConfigurationQueryResult = Apollo.QueryResult<SystemConfigurationQuery, SystemConfigurationQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdminRelation": [
      "Deployment"
    ],
    "BaseEntity": [
      "Admin",
      "BankAccount",
      "BankTransfer",
      "BlockchainAddress",
      "BlockchainNetworkConfiguration",
      "BlockchainReport",
      "Broker",
      "Burn",
      "ClearanceAccount",
      "Client",
      "ClientClosure",
      "ClientPreferences",
      "ClientQuestionnaire",
      "Collateral",
      "Company",
      "ComplianceComment",
      "ComplianceReview",
      "ContractFactory",
      "CustodyAccount",
      "CustodyAccountEvent",
      "CustodyAccountFacet",
      "CustodyAccountFacetBalanceChange",
      "CustodyAccountTransfer",
      "Deployment",
      "DeploymentTask",
      "Document",
      "DocumentDeletionRequest",
      "DocumentFolder",
      "Event",
      "EventRetryAttempt",
      "Exchange",
      "ExternalWallet",
      "ExternalWalletMicroTransaction",
      "ExternalWalletScore",
      "ExternalWalletSigningMessage",
      "Fee",
      "FeeAccrual",
      "FeesConfiguration",
      "FlowExecution",
      "IncomingTransaction",
      "Injection",
      "Interaction",
      "InteractionReport",
      "InternalTransaction",
      "Invitation",
      "Invoice",
      "ManagementFee",
      "Mint",
      "NetworkSimulatorRule",
      "Notification",
      "Oracle",
      "OracleAnswerUpdate",
      "OracleForwarder",
      "Order",
      "OutgoingTransaction",
      "PhysicalAddress",
      "Refund",
      "RiskAssessment",
      "Role",
      "SecurityLog",
      "ServiceAccount",
      "Settlement",
      "Stablecoin",
      "SupplyControlOperation",
      "SupplyControlOperationSignature",
      "SweepingWallet",
      "SystemConfiguration",
      "SystemWallet",
      "Token",
      "TokenDeployment",
      "TokenMultiplierChange",
      "Trade",
      "TradingAsset",
      "Usage",
      "UsageLimits",
      "User",
      "Verification",
      "VerificationProfile"
    ],
    "ClientOwned": [
      "ExternalWallet",
      "ExternalWalletMicroTransaction",
      "ExternalWalletSigningMessage",
      "IncomingTransaction",
      "Interaction",
      "OutgoingTransaction",
      "VerificationProfile"
    ],
    "CompanyRelation": [
      "Token"
    ],
    "IInteractionStatistics": [
      "InteractionBaseStatisticsType",
      "InteractionPerClientStatistics",
      "InteractionStatistics"
    ],
    "ITokenInteractionStatistics": [
      "ClientInteractionStatistics",
      "TokenInteractionPerClientStatistics",
      "TokenInteractionStatistics"
    ],
    "TokenDeploymentTransaction": [
      "Burn",
      "Mint"
    ]
  }
};
      export default result;
    