import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { useToggle } from '@backed-fi/hooks';
import { Flex } from '@backed-fi/primitives';
import { FeesConfigurationQuery, FeeTier } from '@backed-fi/graphql';
import { FeeConfigureDialog } from '@backed-fi/admin/src/app/domain/Internal/Fees/components/FeeConfigureDialog';

// region Props

interface Props extends React.PropsWithChildren {
  valueKey: string,
  deployment: any,
  formatter: (value: any) => string,
  description?: string,
}

// endregion

export const FeeSection: React.FC<Props> = ({ children, deployment, description, valueKey, formatter }) => {
  const dialog = useToggle();

  // region Helpers

  const getFeesConfig = (deployment: FeesConfigurationQuery['tokens'][0]['deployments'][0], tier: FeeTier) => {
    const tierConfig = deployment.feeConfigurations
      .find((x) => x.tier === tier);

    return tierConfig ?? {
      tier,
      isDefault: true,

      issuanceInitialFee: 0,
      issuanceMinimumFee: 0,
      issuancePercentageFee: 0.005,

      redemptionInitialFee: 0,
      redemptionMinimumFee: 0,
      redemptionPercentageFee: 0.005
    };
  };

  const onFeeConfigured = async () => {
    dialog.setFalse();
  };

  // endregion

  return (
    <Box mb={3}>
      <FeeConfigureDialog
        {...dialog}
        deployment={deployment}
        onFeeConfigured={onFeeConfigured}
      />

      <Flex
        mb={.7}
        gap={.3}
        alignItems='center'
      >
        <Typography variant='subtitleSmall'>
          {children}
        </Typography>

        {description && (
          <Tooltip title={description}>
            <HelpOutlineRoundedIcon
              sx={{
                fontSize: '16px',
                color: 'utility.textHighContrast'
              }}
            />
          </Tooltip>
        )}
      </Flex>

      {Object.keys(FeeTier)
        .map((tier) => {
          const feeConfig = getFeesConfig(deployment, tier as FeeTier);

          return (
            <Typography onClick={dialog.setTrue}>
              <Typography
                component='span'
                variant='small'
              >
                {tier}
              </Typography>
              {' : '}

              <Tooltip title='Update the fees'>
                <Box
                  component='span'
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  {formatter((feeConfig as any)[valueKey])}
                </Box>
              </Tooltip>
            </Typography>
          );
        })
      }
    </Box>
  );
};
