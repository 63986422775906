import React from 'react';

import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';

import User from '@mui/icons-material/PersonRounded';
import Hourglass from '@mui/icons-material/HourglassBottomRounded';
import AssessmentEdit from '@mui/icons-material/AutoAwesomeRounded';
import Corporate from '@mui/icons-material/CorporateFareRounded';

import type { GetRiskAssessmentsQuery } from '@backed-fi/graphql';
import { useGetRiskAssessmentsQuery } from '@backed-fi/graphql';
import { PageHeading } from '@backed-fi/compound';

const GetRiskAssessmentsQueryDeclaration = gql`
  query getRiskAssessments {
    riskAssessments {
      id

      createdAt
      updatedAt

      concludedRisk
      concludedRiskPoints

      status
      clientType

      verificationProfile {
        riskLevel

        client {
          name
          type
        }
      }
    }
  }
`;


export const AssessmentsListPage: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { data, loading } = useGetRiskAssessmentsQuery();


  // ---- Helpers ---- //
  const getTableRows = (): GetRiskAssessmentsQuery['riskAssessments'] => {
    if (!data) {
      return [];
    }

    return data.riskAssessments;
  };

  return (
    <Box>
      <PageHeading
        title='Risk Assessments'
        breadcrumbs={[{
          text: 'Risk Assessments'
        }]}
      >
        <Box>
          <Button
            color='secondary'
            sx={{
              margin: '0 1em'
            }}
          >
            Filter
          </Button>
        </Box>
      </PageHeading>

      <Box
        sx={{
          width: '100%',
          height: '70vh'
        }}
      >
        <DataGrid
          hideFooter
          loading={loading}
          rows={getTableRows()}
          sx={{
            backgroundColor: 'utility.backgroundSubtle'
          }}
          columns={[{
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: ({ row }) => {
              return (
                <Chip
                  label={row.status}
                />
              );
            }
          }, {
            field: 'principal',
            headerName: 'Type',
            flex: 1,
            renderCell: ({ value }) => {
              return (
                <React.Fragment>
                  {value === 'User' && (
                    <User />
                  )}

                  {value === 'Institution' && (
                    <Corporate />
                  )}

                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {value}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: 'verificationProfile',
            headerName: 'Name',
            flex: 3,
            renderCell: ({ value }) => {
              return (
                <Typography>
                  {value.client.name}
                </Typography>
              );
            }
          }, {
            field: 'concludedRisk',
            headerName: 'Risk',
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ value, row }) => {
              return (
                <React.Fragment>
                  {row.status !== 'Completed' && (
                    <Tooltip
                      title='The assessment is still in progress'
                    >
                      <span>
                        <Hourglass />
                      </span>
                    </Tooltip>
                  )}

                  {row.status === 'Completed' && (
                    <Tooltip
                      title={`Concluded risk points: ${row.concludedRiskPoints}`}
                    >
                      <Chip
                        label={value}
                        color={
                          value === 'HighRisk'
                            ? 'error'
                            : value === 'Rejected'
                              ? 'warning'
                              : 'primary'
                        }
                      />
                    </Tooltip>
                  )}
                </React.Fragment>
              );
            }
          }, {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => {
              return (
                <Tooltip
                  title='Edit the assessment'
                >
                  <span
                    onClick={() => navigate(`/compliance/risk/assessment/file/${row.id}`)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <AssessmentEdit />
                  </span>
                </Tooltip>
              );
            }
          }]}
        />
      </Box>
    </Box>
  );
};
