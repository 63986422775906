import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

import FlareIcon from '@mui/icons-material/Flare';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import { FlowType } from '@backed-fi/graphql';

interface Props {
  type: FlowType
}

export const FlowTypeBadge: React.FC<Props> = ({ type }) => {
  return (
    <Box
      sx={{
        gap: '0.5rem',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {
        type === FlowType.Mint
          ? <FlareIcon width={24} height={24} />
          : <LocalFireDepartmentIcon width={24} height={24} />
      }

      <Typography>
        {type}
      </Typography>
    </Box>
  );
};
