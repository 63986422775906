import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent, Typography } from '@mui/material';

import { AdminPermission, useCompanyAdminPermissionsQuery, useCreateRoleMutation } from '@backed-fi/graphql';
import { RoleForm, Schema } from './shared/RoleForm';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';


// region Graph

gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
    }
  }
`;

// endregion

export const CreateRoleDialog: React.FC<React.ComponentProps<typeof Dialog>> = ({ onClose, ...props }) => {
  const snackbar = useSnackbar();

  const { id: companyId } = useCompanyContext();
  // region Networking

  const { data, loading } = useCompanyAdminPermissionsQuery({
    variables: {
      companyId: companyId
    }
  });

  const [createRoleMutatation] = useCreateRoleMutation();

  // endregion

  const onCloseInternal: typeof onClose = (...args) => {
    // form.reset();

    if (typeof onClose === 'function') {
      onClose(...args);
    }
  };

  // endregion

  const onCreate = async (data: Schema, permissions: AdminPermission[]) => {
    try {
      await createRoleMutatation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'rolesListQuery'
        ],
        variables: {
          input: {
            ...data,
            permissions
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully created role', { variant: 'success' });

      (onCloseInternal as any)();
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'Failed to create role', { variant: 'error' });
    }
  };


  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth='sm'
      onClose={onCloseInternal}
    >
      <DialogContent>
        <Typography variant='title'>
          Create Role
        </Typography>

        <RoleForm action='Create' onSubmit={onCreate} adminPermissions={data?.company?.adminPermissions || []} />
      </DialogContent>
    </Dialog>
  );
};
