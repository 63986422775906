import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { DateFormatter, USDFormatter } from '@backed-fi/shared';
import { InfoLabelCard, LoaderSection } from '@backed-fi/compound';
import { TokenStatisticsQuery, useTokenStatisticsQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';


gql`query tokenStatistics($tokenId: ID!) {
  token(id: $tokenId) {
    statistics {
      currentValue
      maxValue
      counter
      fees
      lastOrder
      perClient {
        clientId
        clientName
        currentValue
        lastOrder
        maxValue
        fees
        counter
      }

      issuances {
        value
        fees
        counter
      }

      redemptions {
        value 
        fees
        counter
      }
    }
  }
}
`;
export const TokenStatisticsPage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  // region Networking
  const { data, loading } = useTokenStatisticsQuery({
    variables: {
      tokenId: params.id!
    }
  });

  const { token: { statistics } } = (data || { token: { statistics: {} } } as TokenStatisticsQuery);


  return (
    <Box sx={{ marginRight: '3em' }}>
      <Box>
        {loading && (
          <LoaderSection
            description='Fetching latest statistics'
          />
        )}
        {!loading && (
          <Box>
            <Box>
              <Grid sx={{ marginBottom: '32px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Active Exposure</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Current'
                        sx={{ paddingX: '10px' }}
                        value={USDFormatter.format(statistics.currentValue / 100)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='All-time high'
                        sx={{ paddingX: '10px' }}
                        value={USDFormatter.format(statistics.maxValue / 100)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Last order'
                        sx={{ paddingX: '10px' }}
                        value={DateFormatter.format(new Date(statistics.lastOrder))}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Issuances</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Total value'
                        sx={{ paddingX: '10px' }}
                        value={USDFormatter.format(statistics.issuances!.value / 100)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Total number'
                        sx={{ paddingX: '10px' }}
                        value={statistics.issuances!.counter}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Fees</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Total value'
                        sx={{ paddingX: '10px' }}
                        value={USDFormatter.format(statistics.fees / 100)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Redemptions</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Total value'
                        sx={{ paddingX: '10px' }}
                        value={USDFormatter.format(statistics.redemptions!.value / 100)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Total number'
                        sx={{ paddingX: '10px' }}
                        value={statistics.redemptions!.counter}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: '16px' }} variant="title">Client Exposure</Typography>
              <DataGrid
                autoHeight
                loading={loading}
                rows={statistics.perClient || []}
                rowCount={statistics.perClient?.length || 0}
                getRowId={(row) => row.clientId}
                sx={{
                  backgroundColor: 'white'
                }}
                components={{
                  LoadingOverlay: LinearProgress
                }}
                initialState={{
                  sorting: {
                    sortModel: [{
                      field: 'currentValue',
                      sort: 'desc'
                    }]
                  }
                }}
                columns={[{
                  flex: 2,
                  field: 'clientName',
                  headerName: 'Client Name',
                  renderCell: ({ value, row }) => (
                    <Typography
                      variant="link"
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                      onClick={() => navigate(`/clients/${row.clientId}/details/overview`)}
                    >
                      {value}
                    </Typography>
                  )
                },
                {
                  flex: 2,
                  field: 'currentValue',
                  headerName: 'Current',
                  renderCell: ({ value }) => (
                    <Typography
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {USDFormatter.format(value / 100)}
                    </Typography>
                  )
                },
                {
                  flex: 2,
                  field: 'maxValue',
                  headerName: 'All-time high',
                  renderCell: ({ value }) => (
                    <Typography
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {USDFormatter.format(value / 100)}
                    </Typography>
                  )
                },

                {
                  flex: 2,
                  field: 'fees',
                  headerName: 'Fees',
                  renderCell: ({ value }) => (
                    <Typography
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {USDFormatter.format(value / 100)}
                    </Typography>
                  )
                },
                {
                  flex: 2,
                  field: 'counter',
                  headerName: 'Total number',
                  renderCell: ({ value }) => (
                    <Typography
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {value}
                    </Typography>
                  )
                },
                {
                  flex: 2,
                  field: 'lastOrder',
                  headerName: 'Last order',
                  renderCell: ({ value }) => (
                    <Typography
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {DateFormatter.format(new Date(value))}
                    </Typography>
                  )
                }]}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>);
};
