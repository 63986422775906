import React from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from '@mui/lab';

import { InfoLabel, PageHeading } from '@backed-fi/compound';
import {
  EventType,
  OrderDetailsQuery,
  useOrderDetailsQuery
} from '@backed-fi/graphql';
import {
  CentsFormatter,
  USDUnitFormatter
} from '@backed-fi/shared';

import CreatedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SubmittedIcon from '@mui/icons-material/ScheduleSendRounded';
import ExecutedIcon from '@mui/icons-material/FactCheckRounded';
import RequirementsCheckedIcon from '@mui/icons-material/ChecklistRounded';
import ManualIcon from '@mui/icons-material/EngineeringRounded';
import CompletedManuallyIcon from '@mui/icons-material/HowToRegRounded';

// region Graph

gql`
  query OrderDetails($orderId: ID!) {
    order(id: $orderId) {
      id

      createdAt
      updatedAt
      executedAt

      side
      status

      totalValue
      totalShares
      pricePerShare

      collateral {
        id
        symbol
      }

      events {
        id
        createdAt
        updatedAt
        type
      }
    }
  }
`;

// endregion

export const OrderDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  // region Networking

  const { data } = useOrderDetailsQuery({
    variables: {
      orderId: params.id!
    }
  });

  const { order } = (data || {}) as OrderDetailsQuery;

  // endregion

  return (
    <Box>
      <PageHeading
        title="Order Details"
        breadcrumbs={[
          {
            text: 'Brokerage'
          },
          {
            text: 'Orders',
            link: '/financials/brokerage/orders'
          },
          {
            text: 'Details'
          }
        ]}
      />

      {!order && <Box>Loading</Box>}

      {order && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              sm: '1fr'
              // md: '2fr 1fr'
            }
          }}
        >
          <Box>
            {/* region Order Details */}

            <Box>
              <Typography variant="titleSmall">Order Details</Typography>

              <Typography variant="subtitleSmall">
                Basic details about the order and its execution
              </Typography>

              <InfoLabel label="Order ID" content={order.id} />

              <InfoLabel
                label="Order Creation Date"
                content={order.createdAt}
              />

              <InfoLabel label="Order Last Update" content={order.updatedAt} />

              <InfoLabel label="Order Status" content={order.status} />

              <InfoLabel label="Order Side" content={order.side} />

              <InfoLabel
                label="Underlying Collateral"
                content={order.collateral.symbol}
              />
            </Box>

            {/* endregion */}

            {/* region Order Financials  */}

            <Box>
              <Typography variant="titleSmall">Order Financials</Typography>

              <Typography variant="subtitleSmall">
                Financial information, regarding the order
              </Typography>

              {order.status == 'Executed' ? (
                <InfoLabel
                  label="Order Executed Total Value"
                  content={USDUnitFormatter.format(
                    (Number(order.pricePerShare!) / 100) * order.totalShares
                  )}
                />
              ) : (
                <InfoLabel
                  label="Order Approximated Value"
                  content={CentsFormatter.format(order.totalValue!)}
                />
              )}

              {order.status == 'Executed' ? (
                <InfoLabel
                  label="Order Executed Price per Share"
                  content={CentsFormatter.format(
                    Number(order.pricePerShare!)
                  )}
                />
              ) : (
                <InfoLabel
                  label="Order Approximated Price per Share"
                  content={CentsFormatter.format(
                    Number(order.pricePerShare!)
                  )}
                />
              )}

              {order.status == 'Executed' ? (
                <InfoLabel
                  label="Order Executed Shares count"
                  content={order.totalShares}
                />
              ) : (
                <InfoLabel
                  label="Order Approximated Shares count (no rounding)"
                  content={order.totalShares}
                />
              )}

              {order.status == 'Executed' && (
                <InfoLabel
                  label="Executed value vs. prinicipal value (over-collateralization)"
                  content={USDUnitFormatter.format(
                    (Number(order.pricePerShare!) / 100) * order.totalShares -
                    order.totalValue! / 100
                  )}
                />
              )}

              {/*{order.status == 'Executed' &&*/}
              {/*  order.issuanceId &&*/}
              {/*  order.outgoingTransaction && (*/}
              {/*  <>*/}
              {/*    <InfoLabel*/}
              {/*      label="Principal Tokens sent to the user"*/}
              {/*      content={ethers.utils.formatUnits(*/}
              {/*          order.outgoingTransaction.amount!,*/}
              {/*          order.outgoingTransaction?.decimals*/}
              {/*      )}*/}
              {/*    />*/}

              {/*    /!* TODO: Add information from internal TX and not from math *!/*/}
              {/*    {order.totalShares >*/}
              {/*        Number(*/}
              {/*          ethers.utils.formatUnits(*/}
              {/*            order.outgoingTransaction.amount!,*/}
              {/*            order.outgoingTransaction?.decimals*/}
              {/*          )*/}
              {/*        ) && (*/}
              {/*      <InfoLabel*/}
              {/*        label="Over-collataralised Tokens sent to the WC"*/}
              {/*        content={*/}
              {/*          order.totalShares -*/}
              {/*            Number(*/}
              {/*              ethers.utils.formatUnits(*/}
              {/*                order.outgoingTransaction.amount!,*/}
              {/*                order.outgoingTransaction?.decimals*/}
              {/*              )*/}
              {/*            )*/}
              {/*        }*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  </>*/}
              {/*)}*/}

              {/*{order.status == 'Executed' &&*/}
              {/*  order.redemptionId &&*/}
              {/*  order.outgoingTransaction && (*/}
              {/*  <>*/}
              {/*    <InfoLabel*/}
              {/*      label={*/}
              {/*        'Principal ' +*/}
              {/*          order.redemption!.tokenDeployment.stablecoinConfig.symbol +*/}
              {/*          ' sent to the user'*/}
              {/*      }*/}
              {/*      content={ethers.utils.formatUnits(*/}
              {/*          order.outgoingTransaction.amount!,*/}
              {/*          order.outgoingTransaction?.decimals*/}
              {/*      )}*/}
              {/*    />*/}

              {/*    /!* TODO: Add information from internal TX and not from math *!/*/}
              {/*    {order.totalShares >*/}
              {/*        Number(*/}
              {/*          ethers.utils.formatUnits(*/}
              {/*            order.outgoingTransaction.amount!,*/}
              {/*            order.outgoingTransaction?.decimals*/}
              {/*          )*/}
              {/*        ) && (*/}
              {/*      <InfoLabel*/}
              {/*        label={*/}
              {/*          'Remainder ' +*/}
              {/*            order.redemption!.tokenDeployment.stablecoinConfig.symbol +*/}
              {/*            ' sent to the WC'*/}
              {/*        }*/}
              {/*        content={*/}
              {/*          order.totalShares -*/}
              {/*            Number(*/}
              {/*              ethers.utils.formatUnits(*/}
              {/*                order.outgoingTransaction.amount!,*/}
              {/*                order.outgoingTransaction?.decimals*/}
              {/*              )*/}
              {/*            )*/}
              {/*        }*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  </>*/}
              {/*)}*/}
            </Box>

            {/* endregion */}
          </Box>

          <Box>
            {/* region Order Timeline  */}

            <Box>
              <Typography variant="titleSmall">Order Timeline</Typography>

              <Typography variant="subtitleSmall">
                Events, regarding the order progression in time
              </Typography>

              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                }}
              >
                {order.events.map((event) => (
                  <TimelineItem key={event.id}>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        {
                          {
                            [EventType.OrderCreated]: <CreatedIcon />,
                            [EventType.OrderSubmitted]: <SubmittedIcon />,
                            [EventType.OrderSubmittedManually]: (
                              <SubmittedIcon />
                            ),
                            [EventType.OrderExecuted]: <ExecutedIcon />,
                            [EventType.OrderRequirementsChecked]: <RequirementsCheckedIcon />,
                            [EventType.OrderMarkedForManualExecution]: <ManualIcon />,
                            [EventType.OrderSimulated]: <CompletedManuallyIcon />
                          }[event.type as string]
                        }
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography>
                        {event.type.replace(/([a-z](?=[A-Z]))/g, '$1 ')} on{' '}
                        {new Date(event.createdAt).toDateString()} at{' '}
                        {new Date(event.createdAt).toTimeString()}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>

            {/* endregion */}
          </Box>
        </Box>
      )}
    </Box>
  );
};
