import React, { useEffect } from 'react';
import {
  InfoLabel,
  LoaderSection,
  PageHeading,
  Title,
} from '@backed-fi/compound';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  DeploymentDetailsQuery,
  DeploymentStatus,
  DeploymentTaskContractType,
  useDeploymentDetailsQuery,
  useTokenListLazyQuery,
} from '@backed-fi/graphql';

import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';

import ErrorRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import CanceledRoundedIcon from '@mui/icons-material/NotInterestedRounded';
import SuccessRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { LoadingButton } from '@mui/lab';

// region Graph

const Graph = gql`
  query deploymentDetails($id: String!) {
    deployment(id: $id) {
      id
      status

      admin {
        id

        firstName
        lastName

        email
      }

      tasks {
        id

        createdAt
        updatedAt

        network

        status

        steps
        payload
        contractType
      }

      token {
        id
        name
        symbol
      }
    }
  }
`;

// endregion

// region Helpers

// @warn This may get out of sync with the backend. If you update it update the backend version as well
export interface DeploymentTaskStep {
  index: number;
  name: string;
  status: 'Pending' | 'InProgress' | 'Completed' | 'Failed' | 'Canceled';

  hash?: string;

  message?: string;
}

// endregion

export const DeploymentDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  // region Networking

  const { data } = useDeploymentDetailsQuery({
    pollInterval: 3_000,
    variables: {
      id: params.id!,
    },
  });
  const [, { refetch }] = useTokenListLazyQuery({});

  const { deployment } = (data || {}) as DeploymentDetailsQuery;

  useEffect(() => {
    if (data?.deployment.status === DeploymentStatus.Completed) {
      refetch();
    }
  }, [data?.deployment.status]);

  // endregion

  return (
    <Box>
      <PageHeading
        title="Deployment Details"
        // breadcrumbs={[
        //   {
        //     text: 'Internal'
        //   }, {
        //     text: 'Deployments',
        //     link: '/internal/deployments'
        //   }, {
        //     text: 'Details'
        //   }
        // ]}
      />

      {!deployment && (
        <LoaderSection description="Fetching latest deployment details" />
      )}

      {deployment && (
        <Box
          sx={{
            maxWidth: '800px',
          }}
        >
          <Title
            title=""
            subtitle="The most important details of the deployment"
          />

          <InfoLabel label="Current Status" content={deployment.status} />

          <InfoLabel label="Token" content={deployment.token.name} />

          <InfoLabel
            label="Admin"
            content={`${deployment.admin.firstName} ${deployment.admin.lastName}`}
          />

          <Title
            title="Deployment Tasks"
            subtitle="Deployment progress of the all the tasks in this deployment cycle"
          />

          {[...deployment.tasks]
            .sort((a, b) => b.id.localeCompare(a.id))
            // .filter(x => x.contractType === DeploymentTaskContractType.Token)
            .map((deploymentTask) => (
              <Box
                key={deploymentTask.id}
                sx={{
                  my: '1rem',
                }}
              >
                <Accordion
                  sx={{
                    border: '1px solid',
                    borderColor: 'utility.borderElement',
                    boxShadow: 'unset',
                  }}
                >
                  <AccordionSummary>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <BlockchainNetworkLabel
                        size={32}
                        network={deploymentTask.network}
                        label={
                          <Typography>
                            Deployment of{' '}
                            {deploymentTask.contractType.toLowerCase()} contract
                            on {deploymentTask.network}
                          </Typography>
                        }
                      />

                      <Chip
                        label={deploymentTask.status}
                        color={
                          (
                            {
                              Failed: 'error',
                              FailedPartially: 'error',
                              Completed: 'success',
                            } as any
                          )[deploymentTask.status] ?? 'primary'
                        }
                      />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Title title="Deployment Steps" />

                    {(deploymentTask.steps as Array<DeploymentTaskStep>).map(
                      (step) => (
                        <Box
                          key={step.index}
                          sx={{
                            my: '1rem',
                            gap: '.5rem',
                            display: 'flex',
                          }}
                        >
                          <Box
                            sx={{
                              width: '50px',
                              maxHeight: '50px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',

                              color: (
                                {
                                  Pending: 'violet.textLowContrast',
                                  Completed: 'success.textLowContrast',
                                  Canceled: 'error.textLowContrast',
                                  Failed: 'error.textLowContrast',
                                } as any
                              )[step.status],
                            }}
                          >
                            <Tooltip title={step.message}>
                              {
                                {
                                  Pending: <HourglassBottomRoundedIcon />,
                                  InProgress: (
                                    <CircularProgress
                                      size="1.33rem"
                                      color="info"
                                    />
                                  ),
                                  Completed: <SuccessRoundedIcon />,
                                  Failed: <ErrorRoundedIcon />,
                                  Canceled: <CanceledRoundedIcon />,
                                }[step.status]
                              }
                            </Tooltip>
                          </Box>

                          <Box
                            sx={{
                              flex: 1,
                            }}
                          >
                            <Title
                              small
                              title={step.name.replace(/([A-Z][a-z])/g, ' $1')}
                              subtitle={
                                {
                                  DeployToken:
                                    'Deploys a token contract on a blockchain network.',
                                  InferContractAddress:
                                    'Infers the address of the deployed token contract.',
                                  SetDelegateMode:
                                    'Enables the delegate mode for the working capital.',
                                  TransferOwnership:
                                    'Transfers the ownership of the token contract to a specified owner.',
                                  CreateTokenEntity:
                                    'Creates the token entity in the system',
                                }[step.name]
                              }
                            />
                          </Box>
                        </Box>
                      )
                    )}

                    <Title
                      title="Deployment Payload"
                      subtitle="Custom payload needed for the task execution and provided by the admin"
                    />

                    <Box
                      sx={{
                        my: '1rem',
                        padding: '1rem',
                        border: '1px solid',
                        borderRadius: '.5rem',
                        borderColor: 'utility.borderSubtle',
                        backgroundColor: 'utility.backgroundSubtle',
                      }}
                    >
                      <Typography variant="code">
                        {JSON.stringify(deploymentTask.payload, null, 4)}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
        </Box>
      )}
      {deployment?.status === DeploymentStatus.Completed && (
        <LoadingButton
          onClick={() => navigate('/blockchain/tokens/all')}
          sx={{
            float: 'right',
          }}
        >
          Go back to Products page
        </LoadingButton>
      )}
    </Box>
  );
};
