import React from 'react';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { gql } from '@apollo/client';
import { Box, FormControlLabel } from '@mui/material';

import { CompanyFeature, useCompanyFeaturesQuery, useUpdateCompanyFeaturesMutation } from '@backed-fi/graphql';

import { Switch } from '@backed-fi/compound';

gql`
  query companyFeatures($companyId: String!) {
    company(where: {
        id: $companyId
      }) {
        id
        features
      }
  }

  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
    }
  }

  mutation updateCompanyFeatures($input: UpdateCompanyFeaturesInput!) {
    updateCompanyFeatures(input: $input) {
      id
    }
  }
`;

const schema = z.object({
});

export const CompanyFeaturesPage: React.FC = () => {
  // ---- Hooks ---- //
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const [updating, setUpdating] = React.useState<CompanyFeature[]>([]);

  // ---- Networking ---- //
  const { data } = useCompanyFeaturesQuery({
    variables: {
      companyId: params.id!
    }
  });

  const [updateFeatures] = useUpdateCompanyFeaturesMutation({
    refetchQueries: ['companyFeatures'],
    // awaitRefetchQueries: true
  });

  const onUpdateFeature = (feature: CompanyFeature) => {
    return async () => {
      setUpdating((prev) => [
        ...prev,
        feature
      ]);

      try {
        await updateFeatures({
          variables: {
            input: {
              companyId: params.id!,
              ...(data?.company.features?.includes(feature) ? {
                featuresToRemove: [
                  feature
                ]
              } : {
                featuresToAdd: [
                  feature
                ]
              })
            }
          }
        });
        snackbar.enqueueSnackbar(`Successfully changes ${feature} feature`, {
          variant: 'success'
        });
      } finally {
        setUpdating((updating) => {
          return updating.filter((f) => f !== feature);
        });
      }
    };
  };
  // ---- Safe destructuring ---- //
  const { company } = data || {};

  return (
    <Box>

      {/* // ---- Cards quick glance ---- // */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'stretch'
        }}
      >
      </Box>
      <Box display="flex">
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box>
            {Object.values(CompanyFeature)
              .map((feature) => (
                <Box
                  key={feature}
                  sx={{
                    my: '.5rem',
                    maxWidth: '225px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={onUpdateFeature(feature)}
                        disabled={updating.includes(feature as CompanyFeature)}
                        checked={data?.company.features.includes(feature as CompanyFeature)}
                      />
                    }
                    label={feature}
                  />
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyFeaturesPage;
