import React from "react";
import { Select, MenuItem, Box } from "@mui/material";

import { BlockchainNetwork } from "@backed-fi/graphql";

interface Props {
  selectedNetwork: BlockchainNetwork;
  availableNetworks: {
    name?: string;
    network: BlockchainNetwork;
  }[];
  // eslint-disable-next-line no-unused-vars
  onNetworkChange: (network: BlockchainNetwork) => void;
}

export const SimpleNetworkSelector: React.FC<Props> = ({
  selectedNetwork,
  availableNetworks,
  onNetworkChange,
}) => {
  const [network, setNetwork] = React.useState(selectedNetwork);

  return (
    <Select
      label="Network"
      value={network}
      onChange={(event) => {
        const network = availableNetworks.find(
          ({ network }) => network === event.target.value
        );

        if (network) {
          setNetwork(network.network);
          onNetworkChange(network.network);
        }
      }}
      sx={{
        fieldset: {
          borderWidth: "1px !important",
          borderColor: "#E2E5EB !important",
        },

        "label.Mui-focused:not(.Mui-error)": {
          color: "#0066ff !important",
        },

        "&.Mui-focused:not(.Mui-error) fieldset": {
          borderColor: "#0066ff !important",
          boxShadow: "0 0.25rem 0.5rem #0066ff33 !important",
        },

        ".MuiSelect-select": {
          padding: "1rem 1.25rem",
        },

        ".MuiSvgIcon-root": {
          color: "#A4AAB6",
          right: "1rem",
        },
      }}
    >
      {availableNetworks.map((network) => (
        <MenuItem key={network.network} value={network.network}>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Box
              src={`/assets/icons/assets/native/${network.network}.svg`}
              component="img"
              width={20}
              height={20}
            />
            {network.name || network.network}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
