import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import EditIcon from '@mui/icons-material/Edit';
import { AdminPermission, useUpdateCompanyMutation } from '@backed-fi/graphql';
import { Protector, Title } from '@backed-fi/compound';

gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
    }
  }
`;


// region Form Schema

const Schema = z.object({
  name: z.string()
});

type Schema = z.infer<typeof Schema>;

// endregion


type Props = {
  id?: string;
  name: string;
};

export const EditCompanyDialog: React.FC<Props> = ({ id, name }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const [updateCompany, { loading }] = useUpdateCompanyMutation();

  // region Form Control

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
    defaultValues: {
      name: name
    }
  });

  const { errors } = form.formState;

  // endregion

  // region Actions

  const onCloseInternal: typeof dialog.onClose = (...args) => {
    form.reset();


    if (typeof dialog.onClose === 'function') {
      dialog.onClose(...args);
    }
  };

  const onUpdate = form.handleSubmit(async (data) => {
    try {
      await updateCompany({
        awaitRefetchQueries: true,
        refetchQueries: [
          'companyOverview'
        ],
        variables: {
          input: {
            id,
            ...data
          }
        }
      });

      onCloseInternal();

      snackbar.enqueueSnackbar('Successfully updated company', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while updating a company', {
        variant: 'error'
      });
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Protector
        hide
        permissions={[AdminPermission.CompaniesManage]}
      >
        <Tooltip title="Edit company">
          <IconButton
            onClick={dialog.setTrue}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Protector>

      {dialog.open && (
        <Dialog
          {...dialog}
          onClose={onCloseInternal}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Update Company"
              subtitle="Provide new company details"
            />

            {/* region Labels */}
            <Controller
              name="name"
              control={form.control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  sx={{
                    marginTop: '24px'
                  }}
                  fullWidth
                  label="Name"
                  error={!!errors?.name}
                  helperText={errors?.name?.message ?? 'Name of the company'}
                  {...field}
                />

              )}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                loading={loading}
                onClick={onUpdate}
              >
                Edit
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
