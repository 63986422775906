import React from 'react';
import { Avatar, Box, LinearProgress, Typography } from '@mui/material';
import { gql } from '@apollo/client';


import { PageHeading } from '@backed-fi/compound';
import { CompaniesListPageQuery, useCompaniesListPageQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';


import { DateFormatter } from '@backed-fi/shared';
import { usePaginationModel } from '@backed-fi/shared/hooks/usePaginationModel';
import { useNavigate } from 'react-router-dom';
import { CreateCompanyDialog } from '../components/CreateCompanyDialog';

gql`
  query companiesListPage(
    $page: Int = 1,
    $pageSize: Int
  ) {
    companies(
      page: $page,
      pageSize: $pageSize
    ) {
      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }

      nodes {
        id

        createdAt

        name
        appHostPrefix
        logo
      }
    }
  }
`;

export const CompaniesListPage = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();
  const navigate = useNavigate();

  // ---- Networking ---- //
  const query = useCompaniesListPageQuery({
    variables: paginationVariables
  });

  // ---- Destructuring ---- //
  const { companies } = (query.data || {}) as CompaniesListPageQuery;

  React.useEffect(() => {
    (async () => {
      await query.refetch(paginationVariables);
    })();
  }, [paginationVariables]);


  return (
    <Box>
      <PageHeading
        title="Companies"
      >
        <CreateCompanyDialog />
      </PageHeading>

      <DataGrid
        {...pagination}

        loading={query.loading}

        slots={{
          loadingOverlay: LinearProgress
        }}

        autoHeight
        hideFooter={false}
        rows={companies?.nodes || query.previousData?.companies?.nodes || []}
        rowCount={companies?.page.totalNodes}
        rowHeight={70}
        sx={{
          marginBottom: '2rem',
          backgroundColor: 'utility.backgroundSubtle'
        }}

        onRowClick={(row) => navigate(`/companies/${row['id']}/details`)}

        columns={[{
          flex: 10,
          sortable: false,
          field: 'name',
          headerName: 'Name',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Avatar
                  // NOTE: if we provide undefined or null the fallback for alt is not working.
                  src={row.logo ?? '/undefined'}
                  alt={row.name}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    ml: '0.5rem'
                  }}
                >
                  <Typography>
                    {row.name}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }, {
          flex: 2,
          sortable: false,
          field: 'appHostPrefix',
          headerName: 'Web Domain prefix',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        }, {
          flex: 2,
          sortable: false,
          field: 'createdAt',
          headerName: 'Registration Date',
          renderCell: ({ row }) => (
            <Typography>
              {DateFormatter.format(new Date(row.createdAt))}
            </Typography>
          )
        }]}
      />
    </Box>
  );
};
