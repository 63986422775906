import React from 'react';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { RefundStatus, useExecuteRefundMutation, useRefundsListQuery } from '@backed-fi/graphql';

import { ExecuteRefundDialog } from './components/ExecuteRefundDialog';
import { CryptoFormatter } from '@backed-fi/shared';
import { usePaginationModel } from '@backed-fi/hooks';
import { useSearchParams } from 'react-router-dom';

// region Graph

const Graph = gql`
query refundsList($page: Int, $pageSize: Int) {
    refunds(page: $page, pageSize: $pageSize) {
      nodes {

        id

        status
        reason
        failureReason
        creationReason

        refundDefinition

        incomingTransactions {
          decimals
          tokenSymbol
        }

        client {
          id

          name
          isProfessional
        }
      }

      page {
        totalNodes
      }
    }
  }
`;

// endregion

export const RefundsListPage: React.FC = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  const [searchParams] = useSearchParams();

  // region Networking

  const query = useRefundsListQuery({
    variables: {
      ...paginationVariables
    }
  });

  const [executeRefund] = useExecuteRefundMutation();

  // endregion

  // region State

  const [executeRefundId, setExecuteRefundId] = React.useState<string>();

  // endregion

  // region Actions

  const onExecuteRefund = (refundId: string) => async () => {
    setExecuteRefundId(refundId);
  };

  const onCloseExecuteDialog = async () => {
    // Refresh the data in the table
    await query.refetch();

    // Clean the refund ID in order to close the dialog
    setExecuteRefundId(undefined);
  };

  // endregion

  // region Actions

  React.useEffect(() => {
    const searchParamsId = searchParams.get('approveRefundId');

    if (searchParamsId) {
      setExecuteRefundId(searchParamsId);
    }
  }, [searchParams]);

  // endregion

  return (
    <Box>
      {executeRefundId && (
        <ExecuteRefundDialog
          refundId={executeRefundId}
          onClose={onCloseExecuteDialog}
        />
      )}

      <PageHeading
        title="Refunds"
        breadcrumbs={[{
          text: 'Interactions'
        }, {
          text: 'Refunds'
        }]}
      />

      <DataGrid
        autoHeight
        {...pagination}
        loading={query.loading}
        rows={query.data?.refunds?.nodes || []}
        columns={[{
          flex: 3,
          minWidth: 200,
          field: 'client.id',
          headerName: 'Client',
          renderCell: ({ row }) => (
            <Typography
              variant="link"
            >
              {row.client.name}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 200,
          field: 'creationReason',
          headerName: 'Reason',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 200,
          field: 'refundAmount',
          headerName: 'Refund Amount',
          renderCell: ({
            value,
            row
          }) => (
            <Tooltip title={value}>
              <Typography>
                {
                  row.incomingTransactions
                    .map((tx) => (
                      CryptoFormatter.format(value, tx.decimals, tx.tokenSymbol)
                    ))
                    .join(', ')
                }
              </Typography>
            </Tooltip>
          )
        }, {
          flex: 1,
          minWidth: 250,
          field: 'status',
          headerName: 'Status',
          renderCell: ({
            value,
            row
          }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Chip
                label={`${value}${value === RefundStatus.Failed ? ` (${row.failureReason})` : ''}`}
              />
            </Box>
          )
        }, {
          width: 200,
          field: 'id',
          headerName: 'Actions',
          renderCell: ({ row }) => (
            <Box>
              {row.status === RefundStatus.Available && (
                <Button
                  onClick={onExecuteRefund(row.id)}
                >
                  Execute Refund
                </Button>
              )}
            </Box>
          )
        }]}
      />
    </Box>
  );
};
