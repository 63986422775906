import { Route } from 'react-router-dom';
import { CompaniesListPage } from './pages/CompaniesListPage';
import { CompanyDetails } from './pages/CompanyDetails';

export const CompanyPages = (
  <>
    <Route
      path=':id/details'
      element={
        <CompanyDetails />
      }
    >
    </Route>

    <Route
      path='all'
      element={<CompaniesListPage />}
    />
  </>
);
