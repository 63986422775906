import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';
import { ContentTile, Title } from '@backed-fi/compound';

import { AdminPermission, BasicCompanyQuery, useBasicCompanyQuery } from '@backed-fi/graphql';
import { gql } from '@apollo/client';
import { useAdminContext } from '../../../context/AdminContext';

import CompanyOverviewPage from './CompanyOverviewPage';
import CompanyAdminsPage from './CompanyAdminsPage';
import CompanyFeaturesPage from './CompanyFeaturesPage';
import CompanyUsersPage from './CompanyUsersPage';
import CompanyTokensPage from './CompanyTokensPage';
import { SystemWalletsListPage } from '../../Internal/SystemWallets/SystemWalletsListPage';
// region Graph

const Graph = gql`
  query BasicCompany($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      id

      name
    }
  }
`;

// endregion

export const CompanyDetails: React.FC = () => {
  // ---- Hooks ---- //
  const params = useParams<{ id: string }>();
  const adminContext = useAdminContext();

  const isSuperAdmin = useMemo(() => adminContext.permissions.includes(AdminPermission.CompaniesManage), [adminContext]);

  // region Networking

  const { data } = useBasicCompanyQuery({
    variables: {
      where: {
        id: params.id!
      }
    }
  });

  const { company } = (data || {}) as BasicCompanyQuery;

  // endregion

  return (
    <Box>
      <Box
        sx={{
          mb: '2rem'
        }}
      >
        {!company ? (
          <Skeleton
            animation="wave"
            variant="text"
            sx={{
              maxWidth: '350px',
              fontSize: '2.5rem'
            }}
          />
        ) : (
          <Box
            sx={{
              gap: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="title">
              {company.name}
            </Typography>
          </Box>
        )}
      </Box>
      <CompanyOverviewPage></CompanyOverviewPage>
      <CompanyTokensPage companyId={params.id!}></CompanyTokensPage>
      <CompanyAdminsPage companyId={params.id!}></CompanyAdminsPage>
      <CompanyUsersPage companyId={params.id!}></CompanyUsersPage>
      {isSuperAdmin && <>
        <SystemWalletsListPage companyId={params.id!}></SystemWalletsListPage>
        <Title title='Features' subtitle='Functionalities enabled for this company' />
        <ContentTile sx={{ marginTop: '32px' }}>
          <CompanyFeaturesPage></CompanyFeaturesPage>
        </ContentTile>
      </>
      }
    </Box >
  );
};
