/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  amber,
  amberDark,
  blackA,
  blue,
  blueDark as blueDarkBase,
  grass,
  grassDark,
  gray,
  grayDark,
  slate,
  slateDark as slateDarkImport,
  tomato,
  tomatoDark,
  violet,
  violetDark as violetDarkBase,
  yellow,
  yellowDark as yellowDarkBase,
} from '@radix-ui/colors';

declare module '@mui/material/styles/createPalette' {
  interface ColorRamp {
    background: string;
    backgroundSubtle: string;
    elementBackground: string;
    elementBackgroundHovered: string;
    elementBackgroundActive: string;
    borderSubtle: string;
    borderElement: string;
    borderHovered: string;
    solidBackground: string;
    solidBackgroundHovered: string;
    textLowContrast: string;
    textHighContrast: string;
  }

  interface PaletteColor extends ColorRamp { }

  interface Palette {
    utility: ColorRamp;
    slate: ColorRamp;
    black: ColorRamp;
  }
}

export const violetLight = {
  background: violet.violet1,
  backgroundSubtle: violet.violet2,
  elementBackground: violet.violet3,
  elementBackgroundHovered: violet.violet4,
  elementBackgroundActive: violet.violet5,
  borderSubtle: violet.violet6,
  borderElement: violet.violet7,
  borderHovered: violet.violet8,
  solidBackground: violet.violet9,
  solidBackgroundHovered: violet.violet10,
  textLowContrast: violet.violet11,
  textHighContrast: violet.violet12,

  main: violet.violet3,
};

export const violetDark = {
  background: violetDarkBase.violet1,
  backgroundSubtle: violetDarkBase.violet2,
  elementBackground: violetDarkBase.violet3,
  elementBackgroundHovered: violetDarkBase.violet4,
  elementBackgroundActive: violetDarkBase.violet5,
  borderSubtle: violetDarkBase.violet6,
  borderElement: violetDarkBase.violet7,
  borderHovered: violetDarkBase.violet8,
  solidBackground: violetDarkBase.violet9,
  solidBackgroundHovered: violetDarkBase.violet10,
  textLowContrast: violetDarkBase.violet11,
  textHighContrast: violetDarkBase.violet12,

  main: violetDarkBase.violet3,
};

export const yellowLight = {
  background: yellow.yellow1,
  backgroundSubtle: yellow.yellow2,
  elementBackground: yellow.yellow3,
  elementBackgroundHovered: yellow.yellow4,
  elementBackgroundActive: yellow.yellow5,
  borderSubtle: yellow.yellow6,
  borderElement: yellow.yellow7,
  borderHovered: yellow.yellow8,
  solidBackground: yellow.yellow9,
  solidBackgroundHovered: yellow.yellow10,
  textLowContrast: yellow.yellow11,
  textHighContrast: yellow.yellow12,

  light: yellow.yellow7,
  main: yellow.yellow9,
  dark: yellow.yellow11,
};

export const yellowDark = {
  background: yellowDarkBase.yellow1,
  backgroundSubtle: yellowDarkBase.yellow2,
  elementBackground: yellowDarkBase.yellow3,
  elementBackgroundHovered: yellowDarkBase.yellow4,
  elementBackgroundActive: yellowDarkBase.yellow5,
  borderSubtle: yellowDarkBase.yellow6,
  borderElement: yellowDarkBase.yellow7,
  borderHovered: yellowDarkBase.yellow8,
  solidBackground: yellowDarkBase.yellow9,
  solidBackgroundHovered: yellowDarkBase.yellow10,
  textLowContrast: yellowDarkBase.yellow11,
  textHighContrast: yellowDarkBase.yellow12,
};

export const blueDark = {
  background: blueDarkBase.blue1,
  backgroundSubtle: blueDarkBase.blue2,
  elementBackground: blueDarkBase.blue3,
  elementBackgroundHovered: blueDarkBase.blue4,
  elementBackgroundActive: blueDarkBase.blue5,
  borderSubtle: blueDarkBase.blue6,
  borderElement: blueDarkBase.blue7,
  borderHovered: blueDarkBase.blue8,
  solidBackground: blueDarkBase.blue9,
  solidBackgroundHovered: blueDarkBase.blue10,
  textLowContrast: blueDarkBase.blue11,
  textHighContrast: blueDarkBase.blue12,

  main: blueDarkBase.blue3,
};

export const blueLight = {
  background: blue.blue1,
  backgroundSubtle: blue.blue2,
  elementBackground: blue.blue3,
  elementBackgroundHovered: blue.blue4,
  elementBackgroundActive: blue.blue5,
  borderSubtle: blue.blue6,
  borderElement: blue.blue7,
  borderHovered: blue.blue8,
  solidBackground: blue.blue9,
  solidBackgroundHovered: blue.blue10,
  textLowContrast: blue.blue11,
  textHighContrast: blue.blue12,

  light: blue.blue7,
  main: blue.blue9,
  dark: blue.blue11,
};

export const utilityColorLight = {
  background: gray.gray1,
  backgroundSubtle: gray.gray2,
  elementBackground: gray.gray3,
  elementBackgroundHovered: gray.gray4,
  elementBackgroundActive: gray.gray5,
  borderSubtle: gray.gray6,
  borderElement: gray.gray7,
  borderHovered: gray.gray8,
  solidBackground: gray.gray9,
  solidBackgroundHovered: gray.gray10,
  textLowContrast: gray.gray11,
  textHighContrast: gray.gray12,

  main: gray.gray3,
};

export const utilityColorDark = {
  background: grayDark.gray1,
  backgroundSubtle: grayDark.gray2,
  elementBackground: grayDark.gray3,
  elementBackgroundHovered: grayDark.gray4,
  elementBackgroundActive: grayDark.gray5,
  borderSubtle: grayDark.gray6,
  borderElement: grayDark.gray7,
  borderHovered: grayDark.gray8,
  solidBackground: grayDark.gray9,
  solidBackgroundHovered: grayDark.gray10,
  textLowContrast: grayDark.gray11,
  textHighContrast: grayDark.gray12,

  main: grayDark.gray3,
};

export const errorLight = {
  background: tomato.tomato1,
  backgroundSubtle: tomato.tomato2,
  elementBackground: tomato.tomato3,
  elementBackgroundHovered: tomato.tomato4,
  elementBackgroundActive: tomato.tomato5,
  borderSubtle: tomato.tomato6,
  borderElement: tomato.tomato7,
  borderHovered: tomato.tomato8,
  solidBackground: tomato.tomato9,
  solidBackgroundHovered: tomato.tomato10,
  textLowContrast: tomato.tomato11,
  textHighContrast: tomato.tomato12,

  main: tomato.tomato10,
};

export const errorDark = {
  background: tomatoDark.tomato1,
  backgroundSubtle: tomatoDark.tomato2,
  elementBackground: tomatoDark.tomato3,
  elementBackgroundHovered: tomatoDark.tomato4,
  elementBackgroundActive: tomatoDark.tomato5,
  borderSubtle: tomatoDark.tomato6,
  borderElement: tomatoDark.tomato7,
  borderHovered: tomatoDark.tomato8,
  solidBackground: tomatoDark.tomato9,
  solidBackgroundHovered: tomatoDark.tomato10,
  textLowContrast: tomatoDark.tomato11,
  textHighContrast: tomatoDark.tomato12,

  main: tomatoDark.tomato3,
};

export const warningLight = {
  background: amber.amber1,
  backgroundSubtle: amber.amber2,
  elementBackground: amber.amber3,
  elementBackgroundHovered: amber.amber4,
  elementBackgroundActive: amber.amber5,
  borderSubtle: amber.amber6,
  borderElement: amber.amber7,
  borderHovered: amber.amber8,
  solidBackground: amber.amber9,
  solidBackgroundHovered: amber.amber10,
  textLowContrast: amber.amber11,
  textHighContrast: amber.amber12,

  main: amber.amber3,
};

export const warningDark = {
  background: amberDark.amber1,
  backgroundSubtle: amberDark.amber2,
  elementBackground: amberDark.amber3,
  elementBackgroundHovered: amberDark.amber4,
  elementBackgroundActive: amberDark.amber5,
  borderSubtle: amberDark.amber6,
  borderElement: amberDark.amber7,
  borderHovered: amberDark.amber8,
  solidBackground: amberDark.amber9,
  solidBackgroundHovered: amberDark.amber10,
  textLowContrast: amberDark.amber11,
  textHighContrast: amberDark.amber12,

  main: amberDark.amber3,
};

export const successLight = {
  background: grass.grass1,
  backgroundSubtle: grass.grass2,
  elementBackground: grass.grass3,
  elementBackgroundHovered: grass.grass4,
  elementBackgroundActive: grass.grass5,
  borderSubtle: grass.grass6,
  borderElement: grass.grass7,
  borderHovered: grass.grass8,
  solidBackground: grass.grass9,
  solidBackgroundHovered: grass.grass10,
  textLowContrast: grass.grass11,
  textHighContrast: grass.grass12,

  main: grass.grass3,
};

export const successDark = {
  background: grassDark.grass1,
  backgroundSubtle: grassDark.grass2,
  elementBackground: grassDark.grass3,
  elementBackgroundHovered: grassDark.grass4,
  elementBackgroundActive: grassDark.grass5,
  borderSubtle: grassDark.grass6,
  borderElement: grassDark.grass7,
  borderHovered: grassDark.grass8,
  solidBackground: grassDark.grass9,
  solidBackgroundHovered: grassDark.grass10,
  textLowContrast: grassDark.grass11,
  textHighContrast: grassDark.grass12,

  main: grassDark.grass3,
};

export const slateLight = {
  background: slate.slate1,
  backgroundSubtle: slate.slate2,
  elementBackground: slate.slate3,
  elementBackgroundHovered: slate.slate4,
  elementBackgroundActive: slate.slate5,
  borderSubtle: slate.slate6,
  borderElement: slate.slate7,
  borderHovered: slate.slate8,
  solidBackground: slate.slate9,
  solidBackgroundHovered: slate.slate10,
  textLowContrast: slate.slate11,
  textHighContrast: slate.slate12,

  main: slate.slate3,
};

export const slateDark = {
  background: slateDarkImport.slate1,
  backgroundSubtle: slateDarkImport.slate2,
  elementBackground: slateDarkImport.slate3,
  elementBackgroundHovered: slateDarkImport.slate4,
  elementBackgroundActive: slateDarkImport.slate5,
  borderSubtle: slateDarkImport.slate6,
  borderElement: slateDarkImport.slate7,
  borderHovered: slateDarkImport.slate8,
  solidBackground: slateDarkImport.slate9,
  solidBackgroundHovered: slateDarkImport.slate10,
  textLowContrast: slateDarkImport.slate11,
  textHighContrast: slateDarkImport.slate12,

  main: slateDarkImport.slate3,
};

export const black = {
  background: blackA.blackA1,
  backgroundSubtle: blackA.blackA2,
  elementBackground: blackA.blackA3,
  elementBackgroundHovered: blackA.blackA4,
  elementBackgroundActive: blackA.blackA5,
  borderSubtle: blackA.blackA6,
  borderElement: blackA.blackA7,
  borderHovered: blackA.blackA8,
  solidBackground: blackA.blackA9,
  solidBackgroundHovered: blackA.blackA10,
  textLowContrast: blackA.blackA11,
  textHighContrast: blackA.blackA12,

  main: blackA.blackA3,
};
