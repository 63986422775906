import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Box, Button } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { PageHeading, Protector, Title } from '@backed-fi/compound';
import { AdminPermission, useBlockchainAddressesQuery } from '@backed-fi/graphql';
import { useToggle } from '@backed-fi/hooks';

import { AddBlockchainAddressDialog } from './components/AddBlockchainAddressDialog';
import { DeleteBlockchainAddressDialog } from './components/DeleteBlockchainAddressDialog';
import { EditBlockchainAddressDialog } from './components/EditBlockchainAddressDialog';

// region Graph

gql`
  query blockchainAddresses {
    blockchainAddresses {
      id

      address
      networks

      type
      description
      }
  }
`;

// endregion

export const BlockchainAddressesListPage: React.FC = () => {
  const addToggle = useToggle();
  const navigate = useNavigate();

  const {
    data,
    loading
  } = useBlockchainAddressesQuery();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AddBlockchainAddressDialog {...addToggle} />

      <PageHeading
        title="Address Book"
      >
        <Protector
          hide
          permissions={[AdminPermission.BlockchainManage]}
        >
          <Button onClick={addToggle.setTrue}>
            Add Address
          </Button>
        </Protector>

      </PageHeading>

      <DataGrid
        loading={loading}
        autoHeight
        rows={data?.blockchainAddresses ?? []}
        onCellClick={({ field, row }) => {
          if (field === 'id')
            return;

          navigate(`/blockchain/address/details/${row.id}`);
        }}
        columns={[
          {
            flex: 1,
            minWidth: 400,
            headerName: 'Name',
            field: 'description'
          },
          {
            flex: 1,
            minWidth: 600,
            field: 'address',
            headerName: 'Address'
          },
          {
            field: 'id',
            minWidth: 120,
            headerName: '',
            renderCell: ({ row }) =>
              <Box display='flex'>
                <EditBlockchainAddressDialog name={row.description} blockchainAddressId={row.id} />
                <DeleteBlockchainAddressDialog blockchainAddress={row.address} blockchainAddressId={row.id} />
              </Box>
          }
        ]}

        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Title
                title="No Addresses"
                subtitle="There are no registered blockchain addresses"
              />
            </Box>
          )
        }}
        sx={{
          flexGrow: 1
        }}
      />

    </Box >
  );
};
