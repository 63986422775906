import React from 'react';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Grid, Typography } from '@mui/material';

import { CryptoFormatter } from '@backed-fi/shared';
import { ContentTile, InfoLabel, PageHeading, } from '@backed-fi/compound';
import { BlockchainAddressQuery, useBlockchainAddressQuery } from '@backed-fi/graphql';
import { EditBlockchainAddressDialog } from './components/EditBlockchainAddressDialog';
import { DeleteBlockchainAddressDialog } from './components/DeleteBlockchainAddressDialog';

// region Graph

gql`
  query blockchainAddress($id: ID!) {
    blockchainAddress(id: $id) {
      id

      address
      networks

      type
      description

      tokenBalances {
        token {
          name
          symbol
          icon
        }
        balance {
          balance
          network
        }
      }
    }
  }
`;

// endregion

export const BlockchainAddressDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { data } = useBlockchainAddressQuery({
    variables: {
      id: params.id!
    }
  });

  return data && (
    <Box>
      <PageHeading
        title={`Address book`}
      />
      <ContentTile>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='subtitle'>Details</Typography>
          <Box display='flex'>
            <EditBlockchainAddressDialog name={data.blockchainAddress.description} blockchainAddressId={data.blockchainAddress.id} />
            <DeleteBlockchainAddressDialog blockchainAddress={data.blockchainAddress.address} blockchainAddressId={data.blockchainAddress.id} />
          </Box>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <InfoLabel sx={{ marginBottom: 0 }} label='Name' content={data.blockchainAddress.description} />
            </Grid>
            <Grid item xs={6}>
              <InfoLabel sx={{ marginBottom: 0 }} label='Address' content={data.blockchainAddress.address} copy />
            </Grid>
          </Grid>
        </Box>
      </ContentTile >
      <Typography variant='title' my={2}>Holdings:</Typography>
      <Grid container spacing={4}>
        {data.blockchainAddress.tokenBalances.map((t) => {
          return (
            <Grid item sx={{
              display: 'flex',
              flexGrow: "1"
            }} lg={6} xl={4} xxl={3}>
              <ContentTile sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                flexGrow: '1',
              }}>
                <Box sx={{
                  display: 'flex',
                  marginBottom: '16px',
                  alignItems: 'stretch'
                }}>
                  <Avatar
                    sx={{
                      width: '80px',
                      height: '80px'
                    }}
                    alt={t.token.symbol}
                    src={t.token.icon ?? `/assets/icons/assets/${t.token.symbol}.svg`}
                  />
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '16px'
                  }}>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                      }}
                      flex={1}
                      variant='subtitle'
                    >
                      {t.token.symbol}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex'
                      }}
                      flex={1}
                      variant='subtitleSmall'
                    >
                      {t.token.name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant='subtitle' mb={0.5}>Networks</Typography>
                  {t.balance.map((b, index) => (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      ':not(:last-child)': {
                        marginBottom: '8px'
                      }
                    }}>
                      <Box
                        src={`/assets/icons/assets/native/${b.network}.svg`}
                        sx={{ marginRight: '8px' }}
                        component='img'
                        width={24}
                        height={24}
                      />
                      <Typography variant='subtitleSmall'>{CryptoFormatter.format(b.balance, 18, t.token.symbol, false, true)}</Typography>
                    </Box>
                  ))}
                </Box>
              </ContentTile>
            </Grid>
          );
        })}
      </Grid>
    </Box >
  );
};
