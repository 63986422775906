import React from "react";

import { Box, Button, Chip, Typography } from "@mui/material";
import { gql } from "@apollo/client";
import {
  ClientFeature,
  ClientStatus,
  ClientType,
  RiskLevel,
  useAllSupplyControlOperationsQuery,
} from "@backed-fi/graphql";
import { usePaginationModel, useToggle } from "@backed-fi/hooks";
import { PageHeading, Title } from "@backed-fi/compound";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CrossIcon from "@mui/icons-material/HighlightOffRounded";
import { countries } from "countries-list";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RequestSupplyControlOperationDialog } from "@backed-fi/admin/src/app/domain/Blockchain/supply/components/RequestSupplyControlOperationDialog";
import { CryptoFormatter } from "@backed-fi/shared";

// region Graph

gql`
  query allSupplyControlOperations($page: Int, $perPage: Int) {
    supplyControlOperations(page: $page, pageSize: $perPage) {
      nodes {
        id

        type
        status

        amount

        deployment {
          network

          token {
            name
            symbol
          }
        }
      }

      page {
        totalNodes
      }
    }
  }
`;

// endregion

export const SupplyControlOperationsListPage: React.FC = () => {
  const navigate = useNavigate();

  const { paginationVariables, ...pagination } = usePaginationModel();

  // region Networking

  const { data, loading } = useAllSupplyControlOperationsQuery({
    variables: paginationVariables,
  });

  // endregion

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 6rem)",
      }}
    >
      <PageHeading
        title="Mint & Burn"
        // breadcrumbs={[{
        //   text: 'Mint & Burn Operations'
        // }]}
      ></PageHeading>

      <DataGrid
        {...pagination}
        autoHeight
        loading={loading}
        rows={data?.supplyControlOperations?.nodes ?? []}
        rowCount={data?.supplyControlOperations?.page.totalNodes}
        onRowClick={({ row }) => {
          navigate(`/blockchain/supply/operation/details/${row.id}`);
        }}
        columns={[
          {
            width: 300,
            field: "deployment.token.name",
            headerName: "Product Name",
            renderCell: ({ row }) => <>{row.deployment.token.name}</>,
          },
          {
            width: 200,
            field: "deployment.token.symbol",
            headerName: "Product Symbol",
            renderCell: ({ row }) => <>{row.deployment.token.symbol}</>,
          },
          {
            width: 200,
            field: "type",
            headerName: "Type",
          },
          {
            width: 200,
            field: "amount",
            headerName: "Amount",
            headerAlign: "right",
            align: "right",
            renderCell: ({ value }) => (
              <> {CryptoFormatter.format(value, 18, undefined, false)}</>
            ),
          },
          {
            width: 200,
            field: "deployment.network",
            headerName: "Network",
            renderCell: ({ row }) => <Chip label={row.deployment.network} />,
          },
          {
            width: 200,
            field: "status",
            headerName: "Status",
            renderCell: ({ value }) => <Chip label={value} />,
          },
        ]}
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Title
                title="No Operations"
                subtitle="There are no mint or burn operations"
              />
            </Box>
          ),
        }}
        sx={{
          flexGrow: 1,
        }}
      />
    </Box>
  );
};
