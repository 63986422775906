import React from 'react';
import { gql } from '@apollo/client';
import { Box, TextField, Typography } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

import { Flex } from '@backed-fi/primitives';
import { PageHeading } from '@backed-fi/compound';
import { useCollateralisationPageDataQuery } from '@backed-fi/graphql';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';
import { CollateralisationGraph } from '@backed-fi/admin/src/app/domain/Internal/Tokens/pages/Details/components/CollaterisationGraph';

// region Graph

const Graph = gql`
  query CollateralisationPageData {
    tokens {
      id

      name
      symbol
    }
  }
`;

// endregion

export const CollateralisationOverviewPage: React.FC = () => {
  // region Networking

  const { data } = useCollateralisationPageDataQuery();

  // endregion

  // region State

  const [selectedTokenId, setSelectedTokenId] = React.useState<string>();

  const selectedToken = React.useMemo(() => {
    return data?.tokens.find((x) => x.id === selectedTokenId);
  }, [data]);


  // endregion

  // region Effects

  React.useEffect(() => {
    if (data?.tokens && !!data.tokens.length) {
      setSelectedTokenId(data.tokens[0].id);
    }
  }, [data]);

  // endregion

  // region Actions

  const onChangeSelectedToken: StandardInputProps['onChange'] = (event) => {
    setSelectedTokenId(
      data!?.tokens.find(x => x.id === event.target.value)!.id
    );
  };

  // endregion

  return data ? (
    <Box>
      <PageHeading
        title='Collateralisation'
        breadcrumbs={[
          {
            text: 'Financial Operations'
          }
        ]}
      />


      {/* region Product Selector */}


      <Flex
        mb={3}
        alignItems='center'
        justifyContent='space-between'
      >
        <Box>
          <Flex
            gap={1.5}
            alignContent='center'
          >
            <AssetIcon
              symbol={selectedToken?.symbol!}
            />

            <Typography variant='titleSmall'>
              {selectedToken?.name}
            </Typography>
          </Flex>
        </Box>

        <Flex>
          <TextField
            select
            size='small'
            value={selectedTokenId}
            onChange={onChangeSelectedToken}
          >
            {data!?.tokens.map((token) => (
              <option
                key={token.id}
                value={token.id}
              >
                {token.name}
              </option>
            ))}
          </TextField>
        </Flex>
      </Flex>

      {/* endregion */}

      {/* region Collateralisation Graph */}

      {selectedTokenId && (
        <CollateralisationGraph
          tokenId={selectedTokenId}
        />
      )}

      {/* endregion */}
    </Box>
  ) : null;
};
