import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import { useGetTokenStatisticsQuery } from '@backed-fi/graphql';
import { getTokenColor } from './colorUtil';
import chartColors from './chartColors';

const transformData = (data: any): any => {
  if (!data?.[0]?.statistics?.tokens) {
    return {
      pieData: [],
      totalAum: 0,
      colors: []
    };
  }

  const parsedStatistics = data[0].statistics;
  const totalAum = parsedStatistics.totalAum.toFixed(2);
  const allTokens = parsedStatistics.tokens.map((token: any) => token.symbol).sort();

  const pieData = parsedStatistics.tokens.map((token: any) => ({
    value: token.aum,
    name: token.symbol,
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      },
      label: {
        show: true,
        fontSize: '30',
        fontWeight: 'bold',
        formatter: `${token.symbol}\n$${token.aum.toLocaleString()}`
      }
    }
  }));

  // Get the color for each token
  const colors = parsedStatistics.tokens.map((token: any) => getTokenColor(token.symbol, allTokens));

  return {
    pieData,
    totalAum,
    colors
  };
};

const CurrentAumChart: React.FC = () => {
  const [options, setOptions] = useState<echarts.EChartsOption | null>(null);

  const query = useGetTokenStatisticsQuery({ variables: { isLatest: true } });

  const { loading, error } = query;
  const tokenStatistics = query.data?.tokenStatistics;

  useEffect(() => {
    if (tokenStatistics) {
      const transformedData = transformData(tokenStatistics);

      if (transformedData.pieData.length === 0) {
        return;
      }

      let option: echarts.EChartsOption = {
        title: {
          text: 'Latest AUM'
        },
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            return `${params.name} : ${params.percent}%`;
          }
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Tokens',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              position: 'center',
              fontSize: '30',
              fontWeight: 'bold',
              formatter: `Total AUM:\n$${parseFloat(transformedData.totalAum).toLocaleString()}`
            },
            labelLine: {
              show: false
            },
            data: transformedData.pieData,
            color: chartColors
          }
        ]
      };

      setOptions(option);
    }
  }, [tokenStatistics]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return options && <ReactECharts option={options} style={{ height: '400px' }} />;
};

export default CurrentAumChart;
