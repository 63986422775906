import React, { useMemo } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  useOrderExecutionDetailsQuery,
  OrderSide,
  OrderExecutionDetailsQuery,
  useSubmitOrderMutation,
  OrderStatus,
  useExecuteOrderMutation,
  useInteractionExpectedPayoutLazyQuery,
  useBrokersQuery,
  FiatCurrency,
  useCanSystemWalletSatisfyOrderExecutionLazyQuery,
  CurrencyType,
  SettlementStrategy
} from '@backed-fi/graphql';
import { InfoLabel } from '@backed-fi/compound';
import { CentsFormatter, CryptoFormatter, DateFormatter } from '@backed-fi/shared';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Decimal from 'decimal.js';
import { addBusinessDays } from 'date-fns';
import { DownloadDvpForm } from './DownloadDvpForm';

// region Props

interface Props {
  orderId: string;

  onDismiss: (shouldRefresh?: boolean) => void;
}

// endregion

// region Graph Declarations

gql`
  query OrderExecutionDetails($orderId: ID!) {
    order(id: $orderId) {
      id

      side
      status

      totalValue
      totalShares
      sharesToTrade
      pricePerShare
      interaction {
        id
        incomingTransactions {
          amount
        }
      }
      collateral {
        id
        symbol
        price
        balance
        usedBalance
        pendingBalance
        priceCurrency
        tokens {
          pendingBalance
        }
      }
    }
  }

  query InteractionExpectedPayout(
    $tradedShares: Int!
    $pricePerShare: String!
    $interactionId: String!
    $collateralId: String!
  ) {
    interactionExpectedPayout(
      tradedShares: $tradedShares
      pricePerShare: $pricePerShare
      interactionId: $interactionId
      collateralId: $collateralId
    ) {
      payoutAmount
      payoutSymbol
      payoutDecimals
      payoutType
      feeAmount
      feeSymbol
      collateralShortage
      overcollateral
      collateral {
        symbol
      }
    }
  }

  query CanSystemWalletSatisfyOrderExecution($input: CanSystemWalletSatisfyOrderExecutionInput!) {
    canSystemWalletSatisfyOrderExecution(input: $input) {
      gas,
      tokens
    }
  }

  query Brokers {
    brokers {
      nodes {
        id
        name
        settlementStrategy
      }
    }
  }

  mutation SubmitOrder(
    $orderId: ID!
    $sharesToTrade: Int!
    $priceAtSubmission: String!
  ) {
    submitOrder(
      input: {
        orderId: $orderId
        sharesToTrade: $sharesToTrade
        priceAtSubmission: $priceAtSubmission
      }
    ) {
      id
    }
  }

  mutation ExecuteOrder($input: ExecuteOrderInput!) {
    executeOrder(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Schema

const priceSchema = z
  .string()
  .transform((value) => {
    return new Decimal(value as string).mul(100).toFixed(6);
  })
  .refine((rawValue) => {
    const value = new Decimal(rawValue);
    return !value.isNaN() && value.gt(0);
  }, 'Invalid price');

const Schema = z.object({
  pricePerShare: priceSchema,

  totalValue: z.string().transform<number>((value) => {
    return Math.round(Number(value) * 100);
  }),

  totalShares: z.preprocess(
    (value) => Number(value),
    z.number().int().nonnegative()
  ),

  exchangeRate: z
    .string()
    .refine(
      (rate) => Number.parseFloat(rate) !== Number.NaN,
      'The exchange rate need to be a valid number'
    ),

  brokerId: z.string().nonempty(),

  providerReferenceId: z.string().nonempty(),

  notes: z.string().optional(),

  settlementDelay: z.preprocess(
    (value) => Number(value),
    z.number().int().nonnegative()
  )
});

type Schema = z.infer<typeof Schema>;

// endregion

export const ExecuteOrderDialog: React.FC<Props> = ({
  orderId,
  onDismiss
}) => {
  const snackbar = useSnackbar();

  // region Networking

  const [submitOrder] = useSubmitOrderMutation();
  const [executeOrder] = useExecuteOrderMutation();
  const {
    data,
    refetch
  } = useOrderExecutionDetailsQuery({
    variables: {
      orderId
    }
  });
  const [getExpectedPayout, { data: coverable }] =
    useInteractionExpectedPayoutLazyQuery();

  const [canSystemWalletSatisfyExecution,
    {
      data: systemWalletSatisfy,
      loading: isVerificatingSystemWallet,
      error: isVerificatingSystemWalletError
    }] =
    useCanSystemWalletSatisfyOrderExecutionLazyQuery();

  const { data: brokers } = useBrokersQuery();

  const { order } = (data || {}) as OrderExecutionDetailsQuery;

  const isSystemWalletReady =
    systemWalletSatisfy?.canSystemWalletSatisfyOrderExecution?.tokens &&
    systemWalletSatisfy?.canSystemWalletSatisfyOrderExecution?.gas;
  const systemWalletErrors = [
    ...!systemWalletSatisfy?.canSystemWalletSatisfyOrderExecution?.gas ? ['gas'] : [],
    ...!systemWalletSatisfy?.canSystemWalletSatisfyOrderExecution?.tokens ? ['funds'] : []
  ];

  // endregion

  // region Form Control

  const {
    formState,
    register,
    ...form
  } = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors } = formState;

  const totalShares = form.watch('totalShares');
  const pricePerShareRaw = form.watch('pricePerShare');
  const pricePerShare = useMemo(() => {
    try {
      return new Decimal(priceSchema.parse(pricePerShareRaw));
    } catch {
      return new Decimal(NaN);
    }
  }, [pricePerShareRaw]);

  // endregion

  // region State

  const [loading, setLoading] = React.useState<boolean>(false);
  const [confirmStep, setConfirmStep] = React.useState<boolean>(false);
  const [awaitDvpStep, setAwaitDvpStep] = React.useState<boolean>(false);
  const [systemWalletStateAcknowledged, setSystemWalletStateAcknowledged] = React.useState<boolean>(false);
  const [valueDelta, setValueDelta] = React.useState<number>(0);
  const [sharesToTrade, setSharesToTrade] = React.useState<number>(0);
  const [amountToSendToTheUser, setAmountToSendToTheUser] =
    React.useState<number>(0);
  const [overcollateral, setOvercollateral] = React.useState<number>(0);
  const [collateralShortage, setCollateralShortage] = React.useState<number>(0);

  // endregion

  // region Effects

  React.useEffect(() => {
    return () => {
      form.reset();
    };
  }, []);

  React.useEffect(() => {
    if (order && !pricePerShare.isNaN() && totalShares) {
      if (order.side === OrderSide.Buy) {
        form.setValue(
          'totalValue',
          (order.totalValue! / 100).toString() as any
        );
      } else {
        form.setValue(
          'totalValue',
          pricePerShare.mul(totalShares).div(100).toString() as any
        );
      }
      if (order?.totalValue && pricePerShare && totalShares) {
        setValueDelta(
          order!.totalValue! - pricePerShare.toNumber() * Number(totalShares)
        );
      }
    }
  }, [order, pricePerShare, totalShares]);

  React.useEffect(() => {
    if (sharesToTrade && !form.getValues('totalShares')) {
      form.setValue('totalShares', sharesToTrade.toString() as any);
    }
  }, [sharesToTrade]);

  React.useEffect(() => {
    if (!pricePerShare.isNaN() && Number.isInteger(Number(totalShares))) {
      getExpectedPayout({
        variables: {
          tradedShares: Number(totalShares),
          pricePerShare: pricePerShare.toFixed(6),
          interactionId: order?.interaction.id!,
          collateralId: order?.collateral.id!
        }
      });
    }
  }, [order, pricePerShare, totalShares]);

  React.useEffect(() => {
    if (order && confirmStep) {
      const data = form.getValues();
      canSystemWalletSatisfyExecution({
        variables: {
          input: {
            orderId: order!.id,
            pricePerShare: pricePerShare.toFixed(6),
            totalShares: Number(data.totalShares)
          }
        },
        fetchPolicy: 'network-only'
      });
    }
  }, [confirmStep]);

  React.useEffect(() => {
    if (coverable && Number.isInteger(parseInt(`${totalShares}`))) {
      setAmountToSendToTheUser(
        new Decimal(coverable.interactionExpectedPayout.payoutAmount).toNumber()
      );
      setOvercollateral(
        new Decimal(coverable.interactionExpectedPayout.overcollateral).div(1e18)
          .toNumber());
      setCollateralShortage(
        new Decimal(coverable.interactionExpectedPayout.collateralShortage).div(1e18)
          .toNumber()
      );
    }
  }, [coverable, totalShares]);

  React.useEffect(() => {
    if (order?.sharesToTrade) {
      setSharesToTrade(order?.sharesToTrade);
    } else if (order?.totalValue) {
      const amountOfbTokensPledged = new Decimal(
        order!.collateral.usedBalance
      ).div(1e18);
      const amountOfbTokensPending = order!.collateral.tokens
        .reduce((acc, token) => acc.add(token.pendingBalance), new Decimal(0))
        .div(1e18);
      const presumablyOwnedCollateral = new Decimal(
        order.collateral.balance
      ).add(order.collateral.pendingBalance);
      if (order.side === OrderSide.Buy) {
        const amountOfbTokensForUser = new Decimal(order!.totalValue).div(
          order.collateral.price!
        );
        const shortageOfCollateral = amountOfbTokensForUser
          .add(amountOfbTokensPledged)
          .add(amountOfbTokensPending)
          .sub(presumablyOwnedCollateral);
        setSharesToTrade(Math.max(shortageOfCollateral.ceil().toNumber(), 0));
      } else {
        const amountOfbTokensForUser = new Decimal(
          order!.interaction!.incomingTransactions[0].amount
        ).div(1e18);
        const surplusOfCollateral = presumablyOwnedCollateral
          .sub(amountOfbTokensPledged)
          .sub(amountOfbTokensPending)
          .add(amountOfbTokensForUser);
        setSharesToTrade(Math.max(surplusOfCollateral.floor().toNumber(), 0));
      }
    }

    if (order && order.collateral.priceCurrency === FiatCurrency.Usd) {
      form.setValue('exchangeRate', '1');
    }

    form.setValue(
      'settlementDelay',
      order?.side === OrderSide.Buy ? 0 : 2 // Default values for Buy and Sell
    );
  }, [order]);

  // endregion

  // region Actions

  const onSubmitOrder = async () => {
    setLoading(true);

    try {
      await submitOrder({
        variables: {
          orderId,
          sharesToTrade,
          priceAtSubmission: order?.collateral.price!.toFixed(6)!
        }
      });

      await refetch();

      snackbar.enqueueSnackbar('Successfully marked order as submitted');
    } finally {
      setLoading(false);
    }
  };

  const onBackToExecutionDetails = form.handleSubmit(async () => {
    setConfirmStep(false);
  });

  const onProvidedExecutionDetails = form.handleSubmit(async () => {
    setConfirmStep(true);
  });

  const onSubmitExecutionDetails = form.handleSubmit(async (fullData) => {
    setLoading(true);
    const {
      // eslint-disable-next-line no-unused-vars
      totalValue,
      ...data
    } = fullData;

    try {
      await executeOrder({
        variables: {
          input: {
            ...data,
            pricePerShare: data.pricePerShare.toString(),
            orderId
          }
        }
      });

      await refetch();

      if (brokers?.brokers.nodes.find((x) => x.id === data.brokerId)?.settlementStrategy === SettlementStrategy.PerOrder) {
        setAwaitDvpStep(true);
      } else {
        onDismiss(true);
      }

      snackbar.enqueueSnackbar('Successfully marked order as executed');
    } finally {
      setLoading(false);
    }
  });

  // endregion

  return (
    <Dialog open fullWidth onClose={() => onDismiss()}>
      <DialogContent>
        {/* region Loader */}

        {!order && <Typography>Loading</Typography>}

        {/* endregion */}

        {order && (
          <React.Fragment>
            <Box>
              {/* region Submission Data */}

              {order.status === OrderStatus.MarkedForManualExecution && (
                <React.Fragment>
                  <Typography variant="titleSmall">
                    MANUAL ORDER FORM
                  </Typography>

                  <Typography variant="subtitleSmall">
                    Step1 - Manually execute the following order on the
                    Brokerage
                  </Typography>

                  {/* region Shared Properties */}

                  <InfoLabel label="Order Side" content={order.side} />

                  <InfoLabel
                    copy
                    label="Collateral Symbol"
                    content={order.collateral.symbol}
                  />

                  {/* endregion */}

                  {/* region Buy Only Properties */}

                  <InfoLabel
                    label="Principal Transaction Value"
                    content={CentsFormatter.format(order.totalValue!)}
                  />

                  <InfoLabel
                    label="Expected Price"
                    content={CentsFormatter.format(order.collateral.price!)}
                  />

                  {/* endregion */}

                  {/* region Sell Only Properties */}

                  <InfoLabel
                    label={`Amount of shares to ${order.side === OrderSide.Sell ? 'sell' : 'buy'
                      }`}
                    content={sharesToTrade as number}
                  />

                  {/* endregion */}

                  <LoadingButton
                    loading={loading}
                    onClick={onSubmitOrder}
                    sx={{
                      float: 'right'
                    }}
                  >
                    Next
                  </LoadingButton>
                </React.Fragment>
              )}

              {/* endregion */}

              {/* region Execution Details Entry */}

              {order.status === OrderStatus.SubmittedManually && !confirmStep && (
                <React.Fragment>
                  <Box mb={2}>
                    <Typography variant="titleSmall">
                      MANUAL ORDER FORM
                    </Typography>

                    <Typography variant="subtitleSmall">
                      Step2 - Please insert the EXECUTION DETAILS of the order
                    </Typography>
                  </Box>

                  {order.collateral.priceCurrency !== FiatCurrency.Usd && (
                    <Alert sx={{ mb: '2px' }} severity="warning">
                      Detected collateral with underlying not native to system.
                      Please provide Price Order in USD.
                    </Alert>
                  )}

                  <TextField
                    fullWidth
                    label="Price Order Executed"
                    error={Boolean(errors.pricePerShare)}
                    helperText={errors.pricePerShare?.message}
                    {...register('pricePerShare')}
                  />

                  <TextField
                    fullWidth
                    label={`Units ${order.side === OrderSide.Sell ? 'Sold' : 'Bought'
                      }`}
                    error={Boolean(errors.totalShares)}
                    helperText={errors.totalShares?.message}
                    {...register('totalShares')}
                  />

                  <TextField
                    select
                    size="small"
                    fullWidth
                    label="Provider"
                    error={Boolean(errors.brokerId)}
                    helperText={errors.brokerId?.message}
                    {...register('brokerId')}
                  >
                    {brokers?.brokers.nodes.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    label="Provider Reference ID"
                    error={Boolean(errors.providerReferenceId)}
                    helperText={errors.providerReferenceId?.message}
                    {...register('providerReferenceId')}
                  />

                  <TextField
                    select
                    size="small"
                    fullWidth
                    label="Settlement Delay"
                    error={Boolean(errors.brokerId)}
                    helperText={errors.brokerId?.message}
                    {...register('settlementDelay')}
                  >
                    {[0, 1, 2, 3, 4].map((value) => (
                      <option key={value} value={value}>
                        T + {value}
                      </option>
                    ))}
                  </TextField>

                  {order.collateral.priceCurrency !== FiatCurrency.Usd && (
                    <TextField
                      fullWidth
                      label="Exchange Rate"
                      error={Boolean(errors.exchangeRate)}
                      helperText={
                        errors.exchangeRate?.message ?? '1 USDC = This Value'
                      }
                      {...register('exchangeRate')}
                    />
                  )}

                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="General Notes (optional)"
                    error={Boolean(errors.notes)}
                    helperText={errors.notes?.message}
                    {...register('notes')}
                  />

                  {valueDelta !== 0 && (
                    <Alert severity="warning">
                      There is delta of {CentsFormatter.format(valueDelta)}{' '}
                      between the expected order value and the data that you
                      have provided
                    </Alert>
                  )}

                  <LoadingButton
                    onClick={onProvidedExecutionDetails}
                    loading={loading}
                    sx={{
                      mt: '8px',
                      float: 'right'
                    }}
                  >
                    Next
                  </LoadingButton>
                </React.Fragment>
              )}

              {/* endregion */}

              {order.status === OrderStatus.SubmittedManually && confirmStep && !awaitDvpStep && (
                <React.Fragment>
                  <Typography variant="titleSmall">
                    MANUAL ORDER FORM
                  </Typography>

                  <Typography variant="subtitleSmall">
                    Step3 - Confirmation page
                  </Typography>

                  {/* region Shared Properties */}

                  <InfoLabel
                    label="Incoming Transaction Value"
                    content={CentsFormatter.format(order.totalValue!)}
                  />

                  <InfoLabel
                    copy
                    label={`Outgoing ${coverable?.interactionExpectedPayout.payoutType === CurrencyType.Crypto
                      ? 'Blockchain Transaction'
                      : 'Bank Transfer'}`}
                    content={`${coverable?.interactionExpectedPayout.payoutType === CurrencyType.Crypto
                      ? CryptoFormatter.format(
                        amountToSendToTheUser,
                        // Crypto paytout type will always have payoutDecimals
                        coverable?.interactionExpectedPayout.payoutDecimals!,
                        coverable?.interactionExpectedPayout.payoutSymbol)
                      : CentsFormatter.format(amountToSendToTheUser)}`}
                  />

                  <InfoLabel
                    copy
                    label={'Fee to be collected'}
                    content={`${CryptoFormatter.format(
                      coverable?.interactionExpectedPayout.feeAmount,
                      6,
                      coverable?.interactionExpectedPayout.feeSymbol)
                      }`}
                  />

                  <InfoLabel
                    copy
                    label="Total overcollateral after tx"
                    content={`${overcollateral} ${coverable?.interactionExpectedPayout.collateral.symbol}`}
                  />

                  <InfoLabel
                    copy
                    label="Settlement Date"
                    content={`${DateFormatter.format(addBusinessDays(new Date(), form.getValues('settlementDelay')))
                      } (T + ${form.getValues('settlementDelay')})`}
                  />
                  {collateralShortage !== 0 && (<InfoLabel
                    copy
                    label="Collateral shortage for satisfying interaction needs"
                    content={`${collateralShortage} ${coverable?.interactionExpectedPayout.collateral.symbol}`}
                  />)}
                  {/* endregion */}

                  {/* region Buy Only Properties */}

                  {/* endregion */}

                  {/* region Sell Only Properties */}

                  <InfoLabel
                    label={`Amount of shares ${order.side === OrderSide.Sell ? 'sold' : 'bought'
                      }`}
                    content={`${totalShares as number} ${coverable?.interactionExpectedPayout.collateral.symbol}`}
                  />
                  {isVerificatingSystemWallet && (<Alert
                    severity="info"
                    sx={{
                      mt: '.5rem'
                    }}
                  >
                    Verifying if there is enough tokens and gas to cover the transaction.
                  </Alert>)}

                  {
                    !isVerificatingSystemWallet && (

                      <React.Fragment>
                        {isSystemWalletReady && (<Alert
                          severity="success"
                          sx={{
                            mt: '.5rem'
                          }}>
                          System wallet has enough gas and funds to cover the transaction
                        </Alert>)
                        }
                        {!isVerificatingSystemWalletError && systemWalletErrors.length > 0 && (<Box><Alert
                          severity="error"
                          sx={{
                            mt: '.5rem'
                          }}
                        >
                          Not enough <b>{systemWalletErrors.join(', ').concat(' ')}</b>
                          to cover the transaction, please prepare the account for the order execution.
                        </Alert>
                          <Alert
                            severity="info"
                            sx={{
                              mt: '.5rem'
                            }}
                          >
                            <FormControlLabel
                              label="I acknowledge issue above"
                              control={
                                <Checkbox
                                  onClick={(event) => setSystemWalletStateAcknowledged(!systemWalletStateAcknowledged)}
                                  value={systemWalletStateAcknowledged}
                                />
                              }
                              sx={{
                                ml: '0px'
                              }}
                            /></Alert></Box>)
                        }
                        {isVerificatingSystemWalletError && (<Alert
                          severity="warning"
                          sx={{
                            mt: '.5rem'
                          }}
                        >
                          Could not verify system wallet, before you execute the order make sure that there are
                          enough tokens and gas to cover the transaction that will follow
                        </Alert>)

                        }
                      </React.Fragment>)
                  }
                  {/* endregion */}

                  {!loading && (
                    <LoadingButton
                      onClick={onBackToExecutionDetails}
                      sx={{
                        mt: '8px',
                        float: 'left'
                      }}
                    >
                      Back
                    </LoadingButton>
                  )}

                  <LoadingButton
                    disabled={(!isVerificatingSystemWalletError && systemWalletErrors.length > 0 && !systemWalletStateAcknowledged) || amountToSendToTheUser === 0}
                    onClick={onSubmitExecutionDetails}
                    loading={loading || isVerificatingSystemWallet}
                    sx={{
                      mt: '8px',
                      float: 'right'
                    }}
                  >
                    Submit
                  </LoadingButton>
                </React.Fragment>
              )}


              {awaitDvpStep && (
                <React.Fragment>
                  <Typography variant="titleSmall">
                    MANUAL ORDER FORM
                  </Typography>

                  <Typography variant="subtitleSmall">
                    Awaiting DVP form generation
                  </Typography>
                  <DownloadDvpForm orderId={order.id} onDismiss={onDismiss} />
                </React.Fragment>
              )}
            </Box>

            <Stepper
              orientation="horizontal"
              activeStep={
                order.status === OrderStatus.MarkedForManualExecution
                  ? 0
                  : order.status === OrderStatus.SubmittedManually &&
                    !confirmStep
                    ? 1
                    : 2
              }
              sx={{
                mt: '8px',

                '.MuiStepConnector-root': {
                  margin: '0px'
                }
              }}
            >
              {[...Array(3).keys()].map((label) => (
                <Step
                  key={label}
                  sx={{
                    border: 'unset',
                    padding: 'unset',
                    backgroundColor: 'unset !important'
                  }}
                >
                  <StepLabel />
                </Step>
              ))}
            </Stepper>

            {/* endregion */}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};
