import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdminPermission, useCompanyAdminPermissionsQuery } from '@backed-fi/graphql';
import { useToggle } from '@backed-fi/hooks';
import { Box, Checkbox, Chip, Dialog, DialogContent, FormControlLabel, FormGroup, Tooltip, Typography } from '@mui/material';
import { useCompanyContext } from '../providers/context/CompanyContext';
import { AdminPermissionDescription } from '@backed-fi/constants';
import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';

export interface Props {
  identifier: string,
  assignedPermissions: AdminPermission[],

  isLoading: boolean,
  onUpdate: (permissions: AdminPermission[]) => Promise<boolean>
}

export const PermissionsChip: React.FC<Props> = ({ identifier, assignedPermissions, isLoading, onUpdate }) => {
  const { permissions } = useAdminContext();
  const { id: companyId } = useCompanyContext();

  const updateRolesDialogToggle = useToggle();

  const [updatedPermissions, setPermissions] = React.useState<AdminPermission[]>(assignedPermissions);

  // region Networking
  const { data } = useCompanyAdminPermissionsQuery({
    variables: {
      companyId: companyId
    }
  });

  const onPermissionToggle = (permission: AdminPermission) => (e: any, checked: boolean) => {
    if (checked) {
      setPermissions((prev) => ([
        ...prev,
        permission
      ]));
    } else {
      setPermissions((prev) => prev.filter((p) => p !== permission));
    }
  };

  const onChipClick = () => {
    if (permissions.includes(AdminPermission.SystemPermissionsManage)) {
      updateRolesDialogToggle.setTrue();
    }
  }

  const onClose = () => {
    setPermissions(assignedPermissions);

    updateRolesDialogToggle.onClose();
  }

  const updateRoles = async () => {
    const success = await onUpdate(updatedPermissions);

    if (success) {
      updateRolesDialogToggle.onClose()
    }
  }

  return (
    <>
      <Tooltip title={assignedPermissions.join(', ')}>
        <Chip
          onClick={onChipClick}
          label={`${assignedPermissions.length} permission${assignedPermissions.length !== 1 ? 's' : ''}`}
        />
      </Tooltip>
      {
        updateRolesDialogToggle.open && (
          <Dialog
            {...updateRolesDialogToggle}
            onClose={onClose}
            fullWidth
            maxWidth='sm'
          >
            <DialogContent>
              <Typography variant='title'>
                Permissions
              </Typography>

              <Box mb={1}>
                <Typography variant='subtitleSmall'>
                  The set of permissions that {identifier} posses:
                </Typography>
              </Box>

              <FormGroup sx={{ marginLeft: '11px' }}>
                {data?.company.adminPermissions.map((permission) => (
                  <FormControlLabel
                    key={permission}
                    label={AdminPermissionDescription[permission]}
                    control={(
                      <Checkbox
                        checked={updatedPermissions.includes(permission)}
                        onChange={onPermissionToggle(permission as AdminPermission)} />
                    )}
                  />
                ))}
              </FormGroup>

              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <LoadingButton
                  loading={isLoading}
                  onClick={updateRoles}
                >
                  Update
                </LoadingButton>
              </Box>
            </DialogContent>
          </Dialog>
        )}
    </>
  )
}