import { Components } from '@mui/material/styles/components';
import { Box, SxProps, Theme } from '@mui/material';
import { Application } from '@backed-fi/constants';
import React from 'react';

const Container: React.FC<React.PropsWithChildren<{ sx?: SxProps }>> = ({
  children,
  sx
}) => (
  <Box
    className="MuiSvgIcon-root"
    sx={{
      ...(sx || {})
    }}
  >
    {children}
  </Box>
);

const Unchecked = (
  <Container
    sx={{
      padding: '8px'
    }}
  />
);

const Checked = (
  <Container
  >
    <Box
      className='MuiSvgIcon-content'
      sx={{
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage:
            'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
            ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
            '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
          content: '""'
        }
      }}
    />
  </Container>
);


export const createMuiCheckbox = (
  application: Application
): Components<Theme>['MuiCheckbox'] => ({
  defaultProps: {
    disableRipple: true,

    icon: Unchecked,
    checkedIcon: Checked
  },

  styleOverrides: {
    root: ({
      theme,
      ownerState
    }) => {
      const {
        utility,
        primary,
        error
      } = theme.palette;

      return {
        padding: '0',
        color: utility.borderHovered,

        '.MuiSvgIcon-root': {
          overflow: 'hidden',
          borderRadius: '4px',
          border: '1px solid',
          backgroundColor: '#fff',
          borderColor: utility.borderSubtle


        },
        '&.Mui-focusVisible ': {
          '.MuiSvgIcon-root': {
            outline: '2px auto',
            outlineColor: primary.borderHovered,
            outlineOffset: 2
          }
        },

        'input:hover ~ .MuiSvgIcon-root': {
          borderColor: primary.borderElement,
          boxShadow: '0 0.25rem 0.5rem #0066ff33'
        },

        '&.Mui-checked': {
          color: primary.solidBackground,

          '.MuiSvgIcon-root': {
            boxShadow: '0 0.25rem 0.75rem #0066ff33',
            borderColor: primary.solidBackground,
            backgroundColor: primary.solidBackground,

            '.MuiSvgIcon-content': {

              backgroundColor: primary.solidBackground
            }
          },

          'input:hover ~ .MuiSvgIcon-root': {
            borderColor: primary.borderHovered
          }
        },

        '&.Mui-checked.Mui-disabled': {
          '.MuiSvgIcon-root': {
            boxShadow: 'unset',
            backgroundColor: '#CCCCCF',

            '.MuiSvgIcon-content': {
              backgroundColor: '#CCCCCF'
            }
          }
        },

        '&.Mui-disabled': {
          '.MuiSvgIcon-root': {
            borderColor: '#CCCCCF'
          }
        },

        ...(ownerState.color === 'error' && {
          color: error.textLowContrast,

          '&.Mui-checked': {
            color: error.solidBackground
          }
        })
      };
    }
  }
});
