import React from 'react';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { DocumentDetailsQuery, useDocumentDetailsLazyQuery } from '@backed-fi/graphql';
import { Comments } from '../../../../components/Comments';

interface Props extends React.ComponentProps<typeof Dialog> {
  documentId: string;
}

// region Graph

const Graph = gql`
  query DocumentDetails($documentId: ID!) {
    document(id: $documentId) {
      id

      client {
        id
        name
        type
      }

      url
      type
      }
  }
`;


// endregion

export const DocumentDetailsModal: React.FC<Props> = ({ documentId, ...props }) => {
  const snackbar = useSnackbar();

  // ---- Networking ---- //
  const [fetchDocument, { data }] = useDocumentDetailsLazyQuery();

  // ---- Effects ---- //
  React.useEffect(() => {
    if (props.open && documentId?.length) {
      fetchDocument({
        variables: {
          documentId
        }
      });
    }
  }, [documentId, props.open]);


  // ---- Destructure ---- //
  const { document } = (data ?? {}) as DocumentDetailsQuery;

  return (
    <Dialog fullWidth maxWidth='md' {...props}>
      <DialogContent>
        {document && (
          <Box>
            <Typography variant='title'>
              Document Preview
            </Typography>

            {document.url ? (
              <React.Fragment>
                <Box
                  component='embed'
                  src={document.url}
                  sx={{
                    m: '1rem auto',
                    borderRadius: '5px',
                    maxWidth: '100%'
                  }}
                />

                <Button
                  onClick={() => {
                    window.open(document.url!, '_blank')
                      ?.focus();
                  }}
                >
                  Download
                </Button>
              </React.Fragment>
            ) : (
              <Box>
                <Typography>
                  The user has not yet provided that document
                </Typography>
              </Box>
            )}

            <Comments
              documentId={documentId}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
