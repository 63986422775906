import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import { IncomingTransactionsListPage } from './transactions/incoming/pages/IncomingTransactionsListPage';
import { IncomingTransactionDetailsPage } from './transactions/incoming/pages/IncomingTransactionDetailsPage';
import { OutgoingTransactionsListPage } from './transactions/outgoing/pages/OutgoingTransactionsListPage';
import { BlockchainAddressesListPage } from '@backed-fi/admin/src/app/domain/Blockchain/addresses/BlockchainAddressesListPage';
import { SupplyControlOperationsListPage } from '@backed-fi/admin/src/app/domain/Blockchain/supply/SupplyControlOperationsListPage';
import { SupplyControlOperationDetailsPage } from '@backed-fi/admin/src/app/domain/Blockchain/supply/SupplyControlOperationDetailsPage';
import { InternalTransactionsListPage } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/internal/pages/InternalTransactionsListPage';

import { OracleRoutes } from '@backed-fi/admin/src/app/domain/Blockchain/oracles/Routes';
import { BlockchainOwnershipPage } from '@backed-fi/admin/src/app/domain/Blockchain/ownership/BlockchainOwnershipPage';
import { CreateTokenPage } from '../Internal/Tokens/pages/CreateTokenPage';
import { DeployTokenPage } from '../Internal/Tokens/pages/Deployment/DeployTokenPage';
import { ImportTokenPage } from '../Internal/Tokens/pages/Deployment/ImportTokenPage';
import { TokenCollateralCustodyPage } from '../Internal/Tokens/pages/Details/pages/CollateralCustodyPage';
import { TokenDeploymentsListPage } from '../Internal/Tokens/pages/Details/pages/DeploymentsPage';
import { TokenDeploymentsPage } from '../Internal/Tokens/pages/Details/pages/NetworksPage';
import { TokenOperationsPage } from '../Internal/Tokens/pages/Details/pages/OperationsPage';
import { TokenOraclesPage } from '../Internal/Tokens/pages/Details/pages/OraclesPage';
import { TokenDetailsOverviewPage } from '../Internal/Tokens/pages/Details/pages/OverviewPage';
import { TokenReconciliationPage } from '../Internal/Tokens/pages/Details/pages/ReconciliationPage';
import { TokenSettingsPage } from '../Internal/Tokens/pages/Details/pages/SettingsPage';
import { TokenStatisticsPage } from '../Internal/Tokens/pages/Details/pages/StatisticsPage';
import { TokenDetailsLayout } from '../Internal/Tokens/pages/Details/TokenDetailsLayout';
import { TokensListPage } from '../Internal/Tokens/pages/TokenListPage';
import { BlockchainAddressDetailsPage } from './addresses/BlockchainAddressDetailsPages';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, CompanyFeature } from '@backed-fi/graphql';
import { TokenCollateralizationPage } from '../Internal/Tokens/pages/Details/pages/CollateralizationPage';



export const BlockchainRoutes = (
  <Route path="blockchain" element={
    <Protector redirect permissions={[AdminPermission.BlockchainRead]}>
      <Outlet />
    </Protector>
  }>
    <Route
      path="addresses"
      element={
        <BlockchainAddressesListPage />
      }
    />

    <Route
      path="address"
    >
      <Route
        path="details/:id"
        element={<BlockchainAddressDetailsPage />}
      />
    </Route>

    <Route
      path="ownership"
      element={
        <BlockchainOwnershipPage />
      }
    />


    {OracleRoutes}
    <Route
      path="supply"
      element={
        <Protector companyFeature={CompanyFeature.Interactions}>
          <Outlet />
        </Protector>
      }>
      <Route
        path="operations"
        element={
          <SupplyControlOperationsListPage />
        }
      />

      <Route
        path="operation"
      >
        <Route
          path="details/:id"
          element={<SupplyControlOperationDetailsPage />}
        />
      </Route>
    </Route>
    <Route path="transactions"
      element={
        <Protector companyFeature={CompanyFeature.Interactions}>
          <Outlet />
        </Protector>
      }>
      <Route
        path="incoming"
      >
        <Route
          path="all"
          element={<IncomingTransactionsListPage />}
        />

        <Route
          path=":id/details"
          element={<IncomingTransactionDetailsPage />}
        />
      </Route>

      <Route path="outgoing">
        <Route
          path="all"
          element={<OutgoingTransactionsListPage />}
        />
      </Route>

      <Route path="internal">
        <Route
          path="all"
          element={<InternalTransactionsListPage />}
        />
      </Route>
    </Route>
    {/* // ---- Token domain ---- // */}
    <Route path="tokens" element={
      <Protector companyFeature={CompanyFeature.Product} permissions={[AdminPermission.ProductRead]} redirect>
        <Outlet />
      </Protector>
    }>
      <Route
        path="all"
        element={
          <TokensListPage />
        }
      />

      <Route path="create" element={
        <Protector companyFeature={CompanyFeature.Product} permissions={[AdminPermission.ProductManage]} redirect>
          <CreateTokenPage />
        </Protector>
      } />

      <Route
        path="deployments/import"
        element={
          <ImportTokenPage />
        }
      />

      {/* // ---- Settings & details domain  ---- // */}
      <Route
        path="details"
        element={
          <TokenDetailsLayout />
        }
      >
        <Route
          path=":id/overview"
          element={<TokenDetailsOverviewPage />}
        />
        <Route
          path=":id/statistics"
          element={<TokenStatisticsPage />}
        />
        <Route
          path=":id/multiplier"
          element={<TokenCollateralizationPage />}
        />
        <Route
          path=":id/deployments"
          element={<TokenDeploymentsListPage />}
        />

        <Route
          path=":id/operations"
          element={<TokenOperationsPage />}
        />


        <Route
          path=":id/oracles"
          element={<TokenOraclesPage />}
        />

        <Route
          path=":id/custody"
          element={<TokenCollateralCustodyPage />}
        />

        <Route
          path=":id/networks"
        >
          <Route
            index
            element={<TokenDeploymentsPage />}
          />


          <Route
            path="deploy"
            element={<DeployTokenPage />}
          />
        </Route>

        <Route
          path=":id/settings"
          element={<TokenSettingsPage />}
        />

        <Route
          path=":id/reconciliation"
          element={<TokenReconciliationPage />}
        />
      </Route>
    </Route>
  </Route>
);
