import { Box, Typography } from '@mui/material';
import { DomainRestricted, Logo } from '@backed-fi/primitives';
import { ActionTile, NavigationTile } from '@backed-fi/compound';
import Dashboard from '@mui/icons-material/DashboardRounded';
import Users from '@mui/icons-material/PeopleAltRounded';
import Companies from '@mui/icons-material/Business';
import { AdminPermission, CompanyFeature } from '@backed-fi/graphql';
import Interactions from '@mui/icons-material/CurrencyExchangeRounded';
import Flows from '@mui/icons-material/Waves';
import Blockchain from '@mui/icons-material/CurrencyBitcoinRounded';
import FinancialsIcon from '@mui/icons-material/CandlestickChartRounded';
import EuroIcon from '@mui/icons-material/EuroRounded';
import BankIcon from '@mui/icons-material/AccountBalanceRounded';
import AddressBookIcon from '@mui/icons-material/ImportContacts';
import Internal from '@mui/icons-material/Dns';
import MintBurnIcon from '@mui/icons-material/SsidChart';
import UtilsIcon from '@mui/icons-material/ConstructionRounded';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import { useAuthContext } from '@backed-fi/context';

// region Props

interface Props {
  inDrawer?: boolean;
  onNavigate?: () => any;
}

// endregion

export const AdminNavigation: React.FC<Props> = ({ inDrawer, onNavigate }) => {
  // ---- Hooks ---- //
  const authContext = useAuthContext();

  const onLogout = async () => {
    await authContext.logout();
  };

  return (
    <Box
      sx={{
        width: inDrawer ? '100%' : '20%',
        maxWidth: '300px',
        maxHeight: '100vh',

        display: 'flex',
        flexFlow: 'column',
        color: '#fff',
        padding: '2rem 1rem',
        backgroundColor: '#121419'
      }}
    >
      <Box
        sx={{
          width: '100%',
          margin: '0 1rem 2rem'
        }}
      >
        <Logo full />
      </Box>

      <Box
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          padding: '0 0.5rem',
          flexFlow: 'column',
          maxHeight: '100%',
          overflowY: 'auto'
        }}
      >
        {!inDrawer && (
          <Typography
            fontSize={12}
            sx={{
              textTransform: 'uppercase',
              margin: '0 0.5rem 0.5rem',
              color: '#747B89'
            }}
          >
            Navigation
          </Typography>
        )}

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Dashboard}
          companyFeature={CompanyFeature.Dashboard}
          path="/"
          title="Dashboard"
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={Blockchain}
          permission={AdminPermission.ProductRead}
          path="/blockchain/tokens/all"
          title="Products"
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={MintBurnIcon}
          path="/blockchain/supply/operations"
          companyFeature={CompanyFeature.Interactions}
          title="Mint/Burn"
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={AddressBookIcon}
          path="/blockchain/addresses"
          permission={AdminPermission.BlockchainRead}
          title="Address Book"
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={Companies}
          path="/companies/all"
          title="Companies"
          permission={AdminPermission.CompaniesManage}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Users}
          path="/clients"
          title="Clients"
          companyFeature={CompanyFeature.Clients}
          sections={[
            {
              title: 'Onboarding',
              path: '/list/onboarding',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Clients',
              path: '/list/onboarded',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Users',
              path: '/users',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Invitations',
              path: '/invitations/all',
              permission: AdminPermission.ClientRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Interactions}
          path="/interactions"
          title="Interactions"
          companyFeature={CompanyFeature.Interactions}
          sections={[
            {
              title: 'Interactions',
              path: '/all',
              permission: AdminPermission.InteractionRead
            },
            {
              title: 'Monitoring',
              path: '/monitoring',
              permission: AdminPermission.InteractionRead
            },
            {
              title: 'Refunds',
              path: '/refunds',
              permission: AdminPermission.InteractionRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Flows}
          path="/activities/all"
          title="Activities"
          companyFeature={CompanyFeature.Flows}
          permission={AdminPermission.FlowsRead}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Blockchain}
          path="/blockchain"
          title="Blockchain"
          companyFeature={CompanyFeature.Interactions}
          sections={[
            {
              title: 'Incoming Transactions',
              path: '/transactions/incoming/all'
            },
            {
              title: 'Internal Transactions',
              path: '/transactions/internal/all'
            },
            {
              title: 'Outgoing Transactions',
              path: '/transactions/outgoing/all'
            }

          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={FinancialsIcon}
          path="/financials"
          title="Brokerage"
          companyFeature={CompanyFeature.Brokerage}
          sections={[
            {
              title: 'Individual Orders',
              path: '/brokerage/orders',
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Delivery Versus Payment',
              path: '/brokerage/settlements',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={EuroIcon}
          path="/finops"
          title="FinOps"
          companyFeature={CompanyFeature.Brokerage}
          sections={[
            {
              title: 'Working Capital',
              path: '/capital',
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Accounts',
              path: '/accounts/list',
              companyFeature: CompanyFeature.Custody,
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Collateralisation',
              path: '/collateralisation',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={BankIcon}
          path="/banking"
          title="Banking"
          companyFeature={CompanyFeature.InteractionsBankTransfers}
          sections={[
            {
              title: 'Bank Transfers',
              path: '/transfers',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={Internal}
          path="/internal"
          permission={AdminPermission.SystemRead}
          title="Admin Settings"
          sections={[
            {
              title: 'Factories',
              path: '/factories/all',
              companyFeature: CompanyFeature.BlockchainAdvanced,
              permission: AdminPermission.BlockchainRead
            },
            {
              title: 'Users',
              path: '/users/all',
              permission: AdminPermission.SystemRead
            },
            {
              title: 'Product Fees',
              path: '/fees/overview',
              companyFeature: CompanyFeature.Interactions,
              permission: AdminPermission.FinancialsManage
            },
            {
              title: 'System Wallets',
              path: '/wallets/all',
              companyFeature: CompanyFeature.BlockchainAdvanced,
              permission: AdminPermission.BlockchainRead
            },
            {
              title: 'Roles',
              path: '/roles',
              permission: AdminPermission.SystemRead
            },
            {
              title: 'API Access',
              path: '/api-access/all',
              companyFeature: CompanyFeature.ApiAccess,
              permission: AdminPermission.SystemRead
            },
            {
              title: 'Events',
              path: '/events',
              companyFeature: CompanyFeature.Utils,
              permission: AdminPermission.DeveloperManage
            }
          ]}
        />
        <Box flex={1} />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={UtilsIcon}
          title="Utils"
          path="/utils"
          companyFeature={CompanyFeature.Utils}
        />

        <DomainRestricted dev local stage sandbox>
          <NavigationTile
            onNavigate={onNavigate}
            Icon={SwitchAccessShortcutIcon}
            title="Simulate"
            path="/simulate"
            companyFeature={CompanyFeature.Utils}
          />
        </DomainRestricted>

        <Box>
          <ActionTile Icon={LogoutIcon} title="Log out" onClick={onLogout} />
        </Box>

        <Box>
          <Typography
            fontSize={14}
            sx={{
              margin: '1.5rem 0.5rem 0',
              color: '#747B89'
            }}
          >
            2024 &copy; Backed Finance.<br /> All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
