import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { FlowExecutionDetailsQuery, FlowExecutionStatus, FlowType, useFlowExecutionDetailsQuery } from "@backed-fi/graphql";
import { gql } from "@apollo/client";

gql`
  query flowExecutionDetails($flowId: String!) {
    flowExecution(
      where: {
        id: $flowId
      }
    ) {
      status
    }
  }
`

interface Props {
  onSuccess: () => void;
  onFail: () => void;

  flowExecutionId: string;

  flowType: FlowType;
  symbol: string;
}

const FlowTypeLabel = {
  [FlowType.Mint]: "Minting",
  [FlowType.Burn]: "Burning",
};

export const FlowLoading: React.FC<Props> = ({
  flowExecutionId,

  onSuccess,
  onFail,

  symbol,
  flowType,
}) => {
  const { data } = useFlowExecutionDetailsQuery({
    // @note: keeping it this high to allow for the change to settle.
    pollInterval: 5_000,
    variables: {
      flowId: flowExecutionId
    }
  });

  React.useEffect(() => {
    const { flowExecution } = (data || {}) as FlowExecutionDetailsQuery;

    if (flowExecution) {
      switch (flowExecution.status) {
        case FlowExecutionStatus.Completed:
          onSuccess();
          break;

        case FlowExecutionStatus.Failed:
          onFail();
          break;

        default:
          break;
      }
    }
  }, [data])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "4rem",
      }}
    >
      <Typography fontSize={28} fontWeight={600}>
        {FlowTypeLabel[flowType]} {symbol} Tokens
      </Typography>
      <Typography fontSize={14} sx={{ color: "#747B89" }}>
        Operation in progress, please wait...
      </Typography>
      <CircularProgress sx={{ marginTop: "2rem" }} />
    </Box>
  );
};
