import React from 'react';
import { gql } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';

import { Flex } from '@backed-fi/primitives';
import { PageHeading } from '@backed-fi/compound';
import { CentsFormatter } from '@backed-fi/shared';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { FeesConfigurationQuery, useFeesConfigurationQuery } from '@backed-fi/graphql';
import { FeeSection } from '@backed-fi/admin/src/app/domain/Internal/Fees/components/FeeSection';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';

// region Graph

const Graph = gql`
  query feesConfiguration {
    tokens {
      id

      name
      symbol

      deployments {
        id
        network

        feeConfigurations {
          id

          tier
          notes
          
          issuanceInitialFee
          issuanceMinimumFee
          issuancePercentageFee

          redemptionInitialFee
          redemptionMinimumFee
          redemptionPercentageFee
        }
      }
    }
  }
`;

// endregion

export const FeesConfigurationOverviewPage: React.FC = () => {
  // region State

  const [selectedToken, setSelectedToken] = React.useState<FeesConfigurationQuery['tokens'][0]>();

  // endregion

  // region Networking

  const query = useFeesConfigurationQuery();

  const { data } = query;

  // endregion

  // region Effects

  React.useEffect(() => {
    if (!selectedToken && data) {
      setSelectedToken(data.tokens[0]);
    }

    if (selectedToken && data) {
      setSelectedToken(data.tokens.find((x) => x.id === selectedToken.id) ?? selectedToken);
    }
  }, [data]);

  // endregion

  // region Helpers

  const percentageFormatter = (percentage: number) => `${percentage * 100}%`;

  // endregion

  // region Actions

  const onChangeSelectedToken: StandardInputProps['onChange'] = (event) => {
    setSelectedToken(
      data!?.tokens.find(x => x.id === event.target.value)!
    );
  };

  // endregion

  return (
    <Box>
      <PageHeading
        title='Product Fees'
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Product Fees'
          }
        ]}
      />

      {selectedToken && (
        <Box key={selectedToken.id} my={3}>
          <Flex
            mb={3}
            alignItems='center'
            justifyContent='space-between'
          >
            <Box>
              <Flex
                gap={1.5}
                alignContent='center'
              >
                <AssetIcon
                  symbol={selectedToken.symbol}
                />

                <Typography variant='titleSmall'>
                  {selectedToken.name}
                </Typography>
              </Flex>
            </Box>

            <Flex>
              <TextField
                select
                size='small'
                value={selectedToken.id}
                onChange={onChangeSelectedToken}
              >
                {data!?.tokens.map((token) => (
                  <option
                    key={token.id}
                    value={token.id}
                  >
                    {token.name}
                  </option>
                ))}
              </TextField>
            </Flex>
          </Flex>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Network
                </TableCell>

                <TableCell>
                  Issuance Fees
                </TableCell>

                <TableCell>
                  Redemption Fees
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedToken.deployments.map((deployment) => (
                <TableRow key={deployment.id}>
                  <TableCell
                    sx={{
                      verticalAlign: 'top !important'
                    }}
                  >
                    <BlockchainNetworkLabel network={deployment.network} />
                  </TableCell>

                  {['issuance', 'redemption'].map((key) => (
                    <TableCell key={key}>
                      <FeeSection
                        deployment={deployment}
                        valueKey={`${key}InitialFee`}
                        formatter={CentsFormatter.format}
                        description='The first component of the fee calculation'
                      >
                        Initial Fee
                      </FeeSection>

                      <FeeSection
                        deployment={deployment}
                        valueKey={`${key}MinimumFee`}
                        formatter={CentsFormatter.format}
                        description='The minimum possible fee. If the calculated fee is lower the minimum fee will be used instead'
                      >
                        Minimum Fee
                      </FeeSection>


                      <FeeSection
                        deployment={deployment}
                        valueKey={`${key}PercentageFee`}
                        formatter={percentageFormatter}
                        description='The percentage of the total transaction value that will be charged as fee'
                      >
                        Percentage Fee
                      </FeeSection>
                    </TableCell>
                  ))}
                </TableRow>
              ))}


            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};
