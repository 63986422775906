import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { countries } from 'countries-list';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Chip, Typography } from '@mui/material';
import CrossIcon from '@mui/icons-material/HighlightOffRounded';
import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { PageHeading } from '@backed-fi/compound';
import { ClientFeature, ClientStatus, ClientType, RiskLevel, useClientsQuery } from '@backed-fi/graphql';
import { Filters, filterToWhereQuery, FilterType } from '@backed-fi/admin/src/app/components/filter/Filters';
import { usePaginationModel } from '@backed-fi/hooks';
import { ClientStatusChip } from '../components/ClientStatusChip';

// region Graph Declaration

gql`
  query clients(
    $page: Int,
    $pageSize: Int,
    $where: ClientsFilter
  ) {
    clients(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
    nodes {
      id

      createdAt

      name
      type
      isProfessional
      jurisdiction
      status
      frozenUntil

      features

      verificationProfile {
        riskLevel
        currentTier
      }
    }

    page {
      totalNodes
      totalPages
      currentPage

      hasNextPage
      hasPreviousPage
    }
  }
  }
`;


// endregion

export const ClientsListPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ filter: string }>();

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region State

  const [pageFilter, setPageFilter] = React.useState<{}>({});
  const [filters, setFilters] = useState<Record<string, any>>({});
  const queryFilters = useMemo(() => {
    return {
      ...pageFilter,
      ...filters
    };
  }, [pageFilter, filters]);
  // endregion

  // region Networking

  const query = useClientsQuery({
    variables: {
      ...paginationVariables,
      where: queryFilters
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  // region Effects

  React.useEffect(() => {
    const { filter } = params;

    if (filter && filter === 'onboarding') {
      setPageFilter({
        statusFilter: {
          in: [
            ClientStatus.Onboarding
          ]
        }
      });
    }

    if (filter && filter === 'onboarded') {
      setPageFilter({
        statusFilter: {
          notIn: [
            ClientStatus.Onboarding
          ]
        }
      });
    }
  }, [params]);

  // endregion


  return (
    <Box>
      <PageHeading
        title="Clients"
        breadcrumbs={[{
          text: 'Clients'
        }]}
      />

      <Box
        marginBottom={1}
      >
        <Filters
          onFiltersChanged={(filters) => setFilters(filterToWhereQuery(filters))}
          filters={[
            FilterType.ClientType,
            FilterType.ClientName,
            FilterType.ClientStatus
          ]}
        />
      </Box>

      <DataGrid
        {...pagination}
        autoHeight
        loading={loading}
        rows={data?.clients?.nodes ?? []}
        rowCount={data?.clients?.page.totalNodes}
        rowHeight={70}
        columns={[{
          flex: 1,
          minWidth: 300,
          field: 'name',
          headerName: 'Name',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    ml: '1rem'
                  }}
                >
                  <Typography
                    variant="link"
                    onClick={() => navigate(`/clients/${row['id']}/details/overview`)}
                  >
                    {row.name}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }, {
          width: 150,
          field: 'type',
          headerName: 'Client Type',
          renderCell: ({ row }) => {
            return (
              <Chip
                color={row.type === ClientType.Individual ? 'success' : 'info'}
                label={row.type}
              />
            );
          }
        }, {
          width: 150,
          field: 'status',
          headerName: 'Client Status',
          renderCell: ({ row }) => {
            return (
              <ClientStatusChip clientId={row.id} status={row.status} frozenUntil={row.frozenUntil} />
            );
          }
        }, {
          width: 175,
          field: 'isProfessional',
          headerName: 'Professional Investor',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value
                  ? 'Pro-Investor'
                  : 'Non pro'
                }
              </Typography>
            );
          }
        }, {
          width: 200,
          field: 'verificationProfile.riskLevel',
          headerName: 'Risk Level',
          renderCell: ({ row }) => {
            const riskLevel = row.verificationProfile?.riskLevel;

            return (
              <Chip
                color={
                  riskLevel === 'HighRisk'
                    ? 'error'
                    : riskLevel === 'StandardRisk'
                      ? 'primary'
                      : riskLevel === 'Rejected'
                        ? 'warning'
                        : 'default'
                }
                label={riskLevel === RiskLevel.Unknown ? 'No Assessment Yet' : riskLevel}
              />
            );
          }
        }, {
          width: 110,
          field: 'issuance',
          headerName: 'Can issue',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {
                  row.features.includes(ClientFeature.Issuance)
                    ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CrossIcon color="error" />
                    )
                }
              </Box>
            );
          }
        }, {
          width: 110,
          field: 'redemption',
          headerName: 'Can redeem',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {
                  row.features.includes(ClientFeature.Redemption)
                    ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CrossIcon color="error" />
                    )
                }
              </Box>
            );
          }
        }, {
          width: 250,
          field: 'jurisdiction',
          headerName: 'Jurisdiction',
          renderCell: ({ row }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const country = countries[row.jurisdiction];

            return (
              <Typography>
                {row.jurisdiction !== 'Unknown'
                  ? `${country.emoji} ${country.name}`
                  : 'Unknown'
                }
              </Typography>
            );
          }
        }]}
      />
    </Box>
  );
};
