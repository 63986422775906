import React from 'react';

import { z } from 'zod';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import CreateTokenHeader from './CreateTokenHeader';
import CreateTokenLoading from './CreateTokenLoading';
import CreateTokenFormTab from './CreateTokenFormTab';
import CreateTokenFormLogo from './CreateTokenFormLogo';
import CreateTokenFormNetwork from './CreateTokenFormNetwork';
import CreateTokenFormSummary from './CreateTokenFormSummary';
import { gql } from '@apollo/client';
import { BlockchainNetwork, useDeployTokenMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

gql`
  mutation deployToken(
    $token: CreateTokenInput!
    $collateral: DefineTokenCollateralInput!
    $deployment: DeploymentInput!
  ) {
    createToken(
      token: $token
      collateral: $collateral
      deployment: $deployment
    ) {
      id
    }
  }
`;

const MAX_FILE_SIZE = 500_000;

export const availableNetworks = [
  {
    network: BlockchainNetwork.Ethereum,
    name: 'Ethereum',
  },
  {
    network: BlockchainNetwork.Gnosis,
    name: 'Gnosis',
  },
  {
    network: BlockchainNetwork.Polygon,
    name: 'Polygon',
  },
  {
    network: BlockchainNetwork.Arbitrum,
    name: 'Arbitrum',
  },
  {
    network: BlockchainNetwork.Avalanche,
    name: 'Avalanche',
  },
  {
    network: BlockchainNetwork.Fantom,
    name: 'Fantom',
  },
  {
    network: BlockchainNetwork.BinanceSmartChain,
    name: 'BNB Smart Chain',
  },
  {
    network: BlockchainNetwork.Base,
    name: 'Base',
  },
];

const tokenSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  symbol: z.string().min(1, { message: 'Symbol is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  isin: z.string(),
  icon: z
    .any()
    .refine((file) => !!file, 'Image is required.')
    .refine((file) => file?.size <= MAX_FILE_SIZE, 'Max file size is 5MB.') // this should be greater than or equals (>=) not less that or equals (<=)
    .refine((file) => 'image/png' === file?.type, '.png files are accepted.'),
});
const underlyingSchema = z.discriminatedUnion('publiclyListed', [
  z.object({
    publiclyListed: z.literal(true),
    name: z.string().min(1, { message: 'Name is required' }),
    symbol: z.string().min(1, { message: 'Symbol is required' }),
    isin: z.string(),
  }),
  z.object({
    publiclyListed: z.literal(false),
    startingNav: z.number().optional(),
  }),
]);

const deploymentSchema = z.object({
  network: z.string().min(1, { message: 'Network is required' }),
});

const CreateTokenForm: React.FC = () => {
  const snackbar = useSnackbar();

  const [activeTab, setActiveTab] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [deploymentId, setDeploymentId] = React.useState<string>();
  const [icon, setIcon] = React.useState<string>();

  const [deployToken, { loading: deploying }] = useDeployTokenMutation();

  const tokenForm = useForm({
    resolver: zodResolver(tokenSchema),
  });
  const underlyingForm = useForm({
    resolver: zodResolver(underlyingSchema),
    defaultValues: {
      publiclyListed: true,
      isin: '',
      name: '',
      symbol: '',
      startingNav: null as Number | null,
    },
  });
  const deploymentForm = useForm({ resolver: zodResolver(deploymentSchema) });

  const { publiclyListed } = underlyingForm.watch();

  const registerField = (
    form: any,
    name: string,
    valueAsNumber: boolean = false
  ) => ({
    helperText: form.formState.errors[name]?.message,
    error: !!form.formState.errors[name],
    ...form.register(name, {
      valueAsNumber,
    }),
  });

  const toPreviousTab = () => setActiveTab(activeTab - 1);
  const toNextTab = () => setActiveTab(activeTab + 1);

  const deploy = async () => {
    try {
      const { data } = await deployToken({
        variables: {
          token: {
            name: tokenForm.getValues('name'),
            tokenSymbol: tokenForm.getValues('symbol'),
            description: tokenForm.getValues('description'),
            icon: tokenForm.getValues('icon'),
            isin: tokenForm.getValues('isin'),
            constantRatio: false,
          },
          collateral: {
            publiclyListed: underlyingForm.getValues('publiclyListed'),
            startingNav:
              (underlyingForm.getValues('startingNav') as number) * 100,
            ISINName: underlyingForm.getValues('name') ?? '-/-',
            ISINNumber: underlyingForm.getValues('isin') ?? '-/-',
            symbol: underlyingForm.getValues('symbol') ?? '-/-',
          },
          deployment: {
            network: deploymentForm.getValues('network'),
          },
        },
      });

      setDeploymentId(data?.createToken?.id);
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to deploy token', { variant: 'error' });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    tokenForm.setValue('logo', '');
    deploymentForm.setValue('network', availableNetworks[0].network);
  }, []);

  return isLoading ? (
    <CreateTokenLoading
      deploymentId={deploymentId!}
      symbol={tokenForm.getValues('symbol')}
    />
  ) : (
    <>
      <CreateTokenHeader />
      <CreateTokenFormTab
        label="Token Details"
        onSubmitButtonClick={async () => {
          if (await tokenForm.trigger()) {
            toNextTab();
          }
        }}
        complete={activeTab > 0}
        active={activeTab === 0}
      >
        <TextField label="Name" {...registerField(tokenForm, 'name')} />
        <TextField label="Symbol" {...registerField(tokenForm, 'symbol')} />
        <TextField
          rows={4}
          multiline
          label="Description"
          {...registerField(tokenForm, 'description')}
        />
        <TextField
          label="ISIN of the token (Optional)"
          {...registerField(tokenForm, 'isin')}
        />
        <CreateTokenFormLogo
          selectedLogo={icon}
          onIconChange={(icon) => setIcon(icon)}
          onChange={(logo) => tokenForm.setValue('icon', logo)}
          helperText={tokenForm.formState.errors.logo?.message?.toString()}
          error={!!tokenForm.formState.errors.logo}
        />
      </CreateTokenFormTab>
      <CreateTokenFormTab
        label="Underlying Details"
        onBackButtonClick={toPreviousTab}
        onSubmitButtonClick={async () => {
          if (await underlyingForm.trigger()) {
            toNextTab();
          }
        }}
        complete={activeTab > 1}
        active={activeTab === 1}
      >
        <FormControlLabel
          control={
            <Switch
              {...registerField(underlyingForm, 'publiclyListed')}
              checked={publiclyListed}
            />
          }
          label="Underlying Is Publicly Listed"
        />
        {publiclyListed ? (
          <>
            <TextField
              label="Name"
              {...registerField(underlyingForm, 'name')}
            />
            <TextField
              label="Symbol"
              {...registerField(underlyingForm, 'symbol')}
            />
            <TextField
              label="ISIN of the underlying"
              {...registerField(underlyingForm, 'isin')}
            />
          </>
        ) : (
          <>
            <TextField
              label="Starting Price (NAV) of the token"
              {...registerField(underlyingForm, 'startingNav', true)}
            />
          </>
        )}
      </CreateTokenFormTab>
      <CreateTokenFormTab
        label="Deployment Details"
        onBackButtonClick={toPreviousTab}
        onSubmitButtonClick={async () => {
          if (await deploymentForm.trigger()) {
            toNextTab();
          }
        }}
        complete={activeTab > 2}
        active={activeTab === 2}
      >
        <CreateTokenFormNetwork
          availableNetworks={availableNetworks}
          selectedNetwork={deploymentForm.getValues('network')}
          onChange={(network) => deploymentForm.setValue('network', network)}
        />
      </CreateTokenFormTab>
      <CreateTokenFormTab
        label="Token Summary"
        onBackButtonClick={toPreviousTab}
        onSubmitButtonClick={async () => {
          setLoading(true);

          await deploy();
        }}
        submitButtonLabel={
          tokenForm.getValues('symbol') &&
          deploymentForm.getValues('network') &&
          `Deploy ${tokenForm.getValues('symbol')} on ${
            availableNetworks.find(
              ({ name }) => name === deploymentForm.getValues('network')
            )!.name
          }`
        }
        active={activeTab === 3}
      >
        <CreateTokenFormSummary
          tokenDetails={{
            name: tokenForm.getValues('name'),
            symbol: tokenForm.getValues('symbol'),
            description: tokenForm.getValues('description'),
            isin: tokenForm.getValues('isin'),
            icon: icon!,
          }}
          underlyingDetails={{
            name: underlyingForm.getValues('name'),
            symbol: underlyingForm.getValues('symbol'),
            isin: underlyingForm.getValues('isin'),
            publiclyListed: underlyingForm.getValues('publiclyListed'),
            startingNav: Number(underlyingForm.getValues('startingNav')),
          }}
          deploymentDetails={{
            network: deploymentForm.getValues('network'),
          }}
        />
      </CreateTokenFormTab>
    </>
  );
};

export default CreateTokenForm;
