import React from 'react';
import { gql } from '@apollo/client';
import { AccessDocumentQuery, FolderQuery, useAccessDocumentLazyQuery, useFolderLazyQuery } from '@backed-fi/graphql';

// region Props

interface Props {
  folderId?: string;
  documentId?: string;

  name?: string;
  description?: string;
}

// endregion

// region Context Type

type ContextType = Props & {
  isFolder: boolean;
  folder?: {
    id: string;
    name?: string;
    fetched: false;
    description?: string;
    fetch: () => Promise<void>;
  } | ({
    fetched: true;
    fetch: () => Promise<void>;
  } & FolderQuery['folder'])

  isDocument: boolean;
  document?: {
    id: string;
    name?: string;
    fetched: false;
    fetch: () => Promise<AccessDocumentQuery>;
  } | {
    fetched: true;
    fetch: () => Promise<AccessDocumentQuery>;
  } & AccessDocumentQuery['document']

  fetchFolder: () => Promise<void>;
};

// endregion

// region Graph

gql`
  query accessDocument($documentId: ID!) {
    document(id: $documentId) {
      id

      name

      createdAt
      updatedAt
      
      isPendingDeletion

      url
      
      commentsCount
      
      uploader {
        id
        
        firstName
        lastName
        
        email
      }
      
      client {
        id
        
        name
        classification
      }
    }
  }

  query folder($folderId: String!) {
    folder(id: $folderId) {
      id

      createdAt
      updatedAt

      name
      description

      subFolders {
        id

        createdAt
        updatedAt

        name
        description
      }

      files {
        id

        createdAt
        updatedAt

        name
      }
    }
  }
`;

// endregion

const Context = React.createContext<ContextType>(undefined!);

export const useDocumentContext = () => React.useContext<ContextType>(Context);

export const DocumentContext: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  documentId,
  folderId,

  ...props
}) => {
  // region Folder

  const [_fetchFolder, {
    data: _folderData,
    refetch: _refetchFolder
  }] = useFolderLazyQuery();

  const fetchFolder = async () => {
    if (!folderId) {
      throw new Error('Cannot fetch folder in non-folder context');
    }

    if (_folderData) {
      await _refetchFolder();
    } else {
      await _fetchFolder({
        variables: {
          folderId
        }
      });
    }
  };


  const [folderData, setFolderData] = React.useState<ContextType['folder']>(folderId
    ? {
      id: folderId,
      fetched: false,
      name: props.name,
      fetch: fetchFolder,
      description: props.description
    } : undefined as any);

  // endregion

  // region Document

  const [_fetchDocument, {
    data: _documentData,
    refetch: _refetchDocument
  }] = useAccessDocumentLazyQuery();

  const fetchDocument = async () => {
    if (!documentId) {
      throw new Error('Cannot fetch folder in non-folder context');
    }

    if (_documentData) {
      return (
        await _refetchDocument()
      ).data;
    } else {
      return (
        await _fetchDocument({
          variables: {
            documentId
          }
        })
      ).data;
    }
  };

  const [documentData, setDocumentData] = React.useState<ContextType['document']>(documentId
    ? {
      id: documentId,
      fetched: false,
      name: props.name,
      fetch: fetchDocument
    } : undefined as any);

  // endregion

  // region State

  // endregion

  // region Effects

  // region Proper initialisation check

  React.useEffect(() => {
    if ((documentId && folderId) || (!documentId && !folderId)) {
      throw new Error('You must provide only one of the folderId or documentId');
    }
  }, [documentId, folderId]);

  // endregion

  // region Fetch if missing properties

  React.useEffect(() => {
    if ((folderId) && (!props.name || !props.description)) {
      new Promise(async () => {
        await fetchFolder();
      });
    }

    if ((documentId) && (!props.name)) {
      new Promise(async () => {
        await fetchDocument();
      });
    }
  }, []);

  React.useEffect(() => {
    if (_folderData?.folder) {
      setFolderData({
        fetched: true,
        fetch: fetchFolder,
        ..._folderData.folder
      });
    }
  }, [_folderData]);

  React.useEffect(() => {
    if (_documentData?.document) {
      setDocumentData({
        fetched: true,
        fetch: fetchDocument as any,
        ..._documentData.document
      });
    }
  }, [_documentData]);

  // endregion

  // endregion

  return (
    <Context.Provider
      value={{
        folderId,
        documentId,

        folder: folderData,
        document: documentData,

        fetchFolder,

        isFolder: !!folderId,
        isDocument: !!documentId
      }}
    >
      {children}
    </Context.Provider>
  );
};
