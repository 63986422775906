import React from 'react';
import { FormControl, InputLabel } from '@mui/material';

import { SimpleNetworkSelector } from '@backed-fi/compound';
import { BlockchainNetwork } from '@backed-fi/graphql';

interface Props {
  selected: string;

  networks: {
    id: string;
    network: BlockchainNetwork;
  }[];

  onSelect: (id: string) => void;
}

export const FlowNetwork: React.FC<Props> = ({
  selected,
  networks,
  onSelect
}) => {
  const [selectedNetwork, setSelectedNetwork] = React.useState<{
    id: string;
    network: BlockchainNetwork;
  }>(networks.find((n) => n.id === selected) || networks[0]);

  React.useEffect(() => {
    if (selectedNetwork) {
      onSelect(selectedNetwork.id);
    }
  }, [selectedNetwork]);

  return (
    <FormControl fullWidth sx={{ margin: '0.5rem 0' }}>
      <InputLabel
        sx={{
          color: '#747B89 !important',

          '&.Mui-focused:not(.Mui-error)': {
            color: '#0066ff !important'
          }
        }}
      >
        Network
      </InputLabel>
      <SimpleNetworkSelector
        availableNetworks={networks}
        selectedNetwork={selectedNetwork!.network}
        onNetworkChange={(network: BlockchainNetwork) =>
          setSelectedNetwork(networks.find((d) => d.network === network)!)
        }
      />
    </FormControl>
  );
};
