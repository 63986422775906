import React from 'react';
import { gql } from '@apollo/client';

import { AdminPermission, useUpdateAdminMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { PermissionsChip } from '@backed-fi/compound';

gql`
  mutation UpdateAdmin($input: UpdateAdminInput!) {
    updateAdmin(input: $input) {
      id
    }
  }
`;

interface Props {
  admin: {
    id: string,
    email: string,

    permissions: AdminPermission[]
  }
}

export const AdminPermissions: React.FC<Props> = ({ admin }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [updateAdminMutation, { loading }] = useUpdateAdminMutation();

  // endregion


  const onUpdate = async (permissions: AdminPermission[]) => {
    try {
      await updateAdminMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'admins'
        ],
        variables: {
          input: {
            adminId: admin.id,
            update: {
              permissions
            }
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully updated admin permissions', { variant: 'success' });

      return true;
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to update admin permissions', { variant: 'error' });
    }

    return false;
  };

  return (
    <>
      <PermissionsChip
        identifier={admin.email}
        assignedPermissions={admin.permissions}
        isLoading={loading}
        onUpdate={onUpdate}
      />
    </>
  );
};
