import React from 'react';
import { Box } from '@mui/material';
import { BlockchainNetwork, FiatCurrency, IncomingTransactionType, OutgoingTransactionType } from '@backed-fi/graphql';
import { AssetInteraction } from '@backed-fi/compound';

// region Props

interface Props {
  small?: boolean;
  clickable?: boolean;

  assets: ({
    type: 'Blockchain'
    id: string;

    hash?: string | null;
    explorerUrl?: string | null;
    confirmations?: number;
    confirmationsNeeded?: number;
    valueFormatter?: () => string;

    network: BlockchainNetwork;
    tokenSymbol: string;
    icon?: string | null;
    amount: string;
    decimals: number;
    assetType: IncomingTransactionType | OutgoingTransactionType
  } | {
    type: 'Fiat'
    id: string;
    amount: string;
    currency: FiatCurrency;
  })[];
}

// endregion

export const InteractionAssetsList: React.FC<Props> = ({
  assets,
  small,
  clickable
}) => {
  return (
    <Box
      sx={{
        gap: '1rem',
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      {assets.map((asset) => (
        <AssetInteraction
          small={small}
          clickable={clickable}
          asset={asset}
        />))}
    </Box>
  );
};
