import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import {
  AdminPermission,
  useCompanyAdminPermissionsQuery,
  useCreateAdminServiceAccountMutation
} from '@backed-fi/graphql';
import { InfoLabel, Protector, Title } from '@backed-fi/compound';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';
import { AdminPermissionDescription } from '@backed-fi/constants';

gql`
  mutation createAdminServiceAccount($input: CreateServiceAccountInput!) {
    createServiceAccount(input: $input) {
      key
      secret
    }
  }
`;


// region Form Schema

const Schema = z.object({
  name: z.string(),
  ipRange: z.string()
    .optional()
});

type Schema = z.infer<typeof Schema>;

// endregion


type Props = {
};

export const CreateApiAccessDialog: React.FC<Props> = ({ }) => {
  const { id: companyId } = useCompanyContext();

  const dialog = useToggle();
  const isCreating = useToggle();
  const snackbar = useSnackbar();


  const [permissions, setPermissions] = React.useState<AdminPermission[]>([]);
  const [serviceAccount, setServiceAccount] = React.useState<{ key: string, secret: string }>();


  const { data } = useCompanyAdminPermissionsQuery({
    variables: {
      companyId: companyId
    }
  });
  const [createAdminServiceAccount] = useCreateAdminServiceAccountMutation();


  // region Form Control

  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors } = form.formState;

  // endregion

  const onPermissionToggle = (permission: AdminPermission) => (e: any, checked: boolean) => {
    if (checked) {
      setPermissions((prev) => ([
        ...prev,
        permission
      ]));
    } else {
      setPermissions((prev) => prev.filter((p) => p !== permission));
    }
  };

  const onCloseInternal = () => {
    form.reset();

    setServiceAccount(undefined);
    setPermissions([]);

    dialog.onClose();
  }

  // region On Create

  const onCreate = form.handleSubmit(async (data) => {
    try {
      isCreating.setTrue();

      const { data: serviceAccount } = await createAdminServiceAccount({
        awaitRefetchQueries: true,
        refetchQueries: [
          'apiAcceses'
        ],
        variables: {
          input: {
            title: data.name,
            ipRange: data.ipRange,
            permissions
          }
        }
      });
      if (serviceAccount) {
        setServiceAccount({ key: serviceAccount.createServiceAccount.key, secret: serviceAccount.createServiceAccount.secret });
      }

      snackbar.enqueueSnackbar('Successfully created new API access', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while creating an API access', {
        variant: 'error'
      });
    } finally {
      isCreating.setFalse();
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Protector
        hide
        permissions={[AdminPermission.SystemManage]}
      >
        <Button onClick={dialog.setTrue}>
          Create new
        </Button>
      </Protector>

      {dialog.open && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
          onClose={onCloseInternal}
        >
          {!serviceAccount &&
            <DialogContent>
              <Title
                title="Create API Access"
                subtitle="Select API Access permissions"
              />

              {/* region Labels */}
              <Controller
                name="name"
                control={form.control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    fullWidth
                    label="Name"
                    error={!!errors?.name}
                    helperText={errors?.name?.message ?? 'Name of the API Access'}
                    {...field}
                  />

                )}
              />

              <Controller
                name="ipRange"
                control={form.control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    fullWidth
                    label="IP address restrictions"
                    error={!!errors?.ipRange}
                    helperText={errors?.ipRange?.message ?? 'Allow IP addresses, comma seperated (Optional)'}
                    {...field}
                  />

                )}
              />

              <Box
                mt={3}
                mb={1}
              >
                <Typography
                  variant='titleSmall'
                >
                  Permissions
                </Typography>

                <Typography
                  variant='subtitleSmall'
                >
                  The set of permissions that the created API access will posses
                </Typography>
              </Box>

              <FormGroup>
                {data?.company.adminPermissions.map((permission) => (
                  <FormControlLabel
                    key={permission}
                    label={AdminPermissionDescription[permission]}
                    control={(
                      <Checkbox onChange={onPermissionToggle(permission as AdminPermission)} sx={{ marginLeft: '0.875rem' }} />
                    )}
                  />
                ))}
              </FormGroup>

              {/* endregion */}

              <Box
                sx={{
                  gap: '.5rem',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <LoadingButton
                  color="secondary"
                  disabled={isCreating.value}
                  onClick={dialog.setFalse}
                >
                  Later
                </LoadingButton>

                <LoadingButton
                  loading={isCreating.value}
                  onClick={onCreate}
                >
                  Create
                </LoadingButton>
              </Box>
            </DialogContent>
          }
          {
            serviceAccount &&
            <>
              <DialogContent>
                <Title
                  title=" Your new API key"
                  subtitle=" Please save your API key! You won't be able to see it again."
                />
                <InfoLabel
                  copy
                  label='Access Key'
                  content={serviceAccount.key}
                />
                <InfoLabel
                  copy
                  label='Access Secret'
                  content={serviceAccount.secret}
                />

                <Button
                  onClick={onCloseInternal}
                  variant='publicDashboard'
                  fullWidth
                >
                  Close
                </Button>
              </DialogContent>
            </>
          }
        </Dialog>
      )
      }
    </React.Fragment >
  );
};
