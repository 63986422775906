import React from "react";
import Decimal from "decimal.js";
import { Box, Typography } from "@mui/material";
import { AssetBadge, InfoLabel } from "@backed-fi/compound";
import {
  BlockchainNetwork,
  FlowType,
} from "@backed-fi/graphql";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CryptoFormatter } from "@backed-fi/shared";

interface Props {
  flowType: FlowType;

  token: {
    name: string;
    symbol: string;
    icon?: string | null;
    collateral: {
      ISINNumber?: string | null;
    };
  };
  flow?: {
    amount: string;
    totalSupply: string;
    network: BlockchainNetwork;
    wallet: string;
  };
}

const FlowTypeLabel = {
  [FlowType.Mint]: {
    title: "Mint",
    walletLabel: "Destination Wallet",
    action: "+",
  },
  [FlowType.Burn]: {
    title: "Burn",
    walletLabel: "Burn Wallet",
    action: "-",
  },
};

export const FlowInformation: React.FC<Props> = ({
  token,
  flow,
  flowType,
}) => {
  const updatedTotalSupply = ((flow, flowType) => {
    if (!flow) {
      return null;
    }

    switch (flowType) {
      case FlowType.Burn:
        return new Decimal(flow.totalSupply)
          .sub(flow.amount)
          .toString();
      case FlowType.Mint:
      default:
        return new Decimal(flow.totalSupply)
          .add(flow.amount)
          .toString();
    }
  })(flow, flowType);

  return (
    <>
      <Box
        sx={{
          marginTop: "32px",
          marginBottom: "32px",

          userSelect: "none",
          display: "flex",
          gap: "1.25rem",

          padding: "1.5rem",
          alignItems: "center",
          backgroundColor: "#F2F4F8",
          borderRadius: "0.5rem",
          ...(!!flow && {
            marginBottom: "0",
            borderRadius: "0.5rem 0.5rem 0 0",
          }),
        }}
      >
        <Box>
          <AssetBadge symbol={token.symbol} icon={token.icon} large={true} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {token.symbol}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {token.name} ({token.collateral.ISINNumber})
          </Typography>
        </Box>
      </Box>
      {flow && (
        <Box
          sx={{
            padding: "32px",
            paddingBottom: "16px",
            marginBottom: "16px",
            border: "1px solid #EDEFF4",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Box pb={1.5}>
            <Typography pb={0.5} variant="subtitleSmall">
              {FlowTypeLabel[flowType].title} Amount
            </Typography>
            <Typography sx={{ font: "normal normal normal 24px/22px Inter" }}>
              {FlowTypeLabel[flowType].action}
              {CryptoFormatter.format(flow.amount, 18, token.symbol)}
            </Typography>
          </Box>
          <Box>
            <InfoLabel
              copy
              copyValue={flow.wallet}
              label={FlowTypeLabel[flowType].walletLabel}
              content={
                <Typography variant="code">{flow.wallet}</Typography>
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <Box>
              <InfoLabel
                label="Network"
                content={
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <Box
                      src={`/assets/icons/assets/native/${flow.network}.svg`}
                      component="img"
                      width={20}
                      height={20}
                    />
                    <Typography
                      sx={{ font: "normal normal normal 14px/22px Inter" }}
                    >
                      {flow.network}
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box>
              <InfoLabel
                sx={{
                  marginBottom: "0",
                }}
                label="Total Supply"
                content={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{ font: "normal normal normal 14px/22px Inter" }}
                    >
                      {CryptoFormatter.format(
                        flow.totalSupply,
                        18,
                        token.symbol
                      )}
                    </Typography>

                    <ArrowForwardIcon sx={{ width: "20px", height: "20px" }} />

                    <Typography
                      sx={{ font: "normal normal normal 14px/22px Inter" }}
                    >
                      {CryptoFormatter.format(
                        updatedTotalSupply!,
                        18,
                        token.symbol
                      )}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
