import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import {
  OrderSide,
  useBrokersQuery,
  useCollateralsQuery,
  useCreateEodFormMutation
} from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { EnumSelect } from '@backed-fi/compound';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

// region Graph

gql`
  query collaterals {
    collaterals {
      id
      symbol
    }
  }

  mutation createEODForm($input: CreateEODFormInput!) {
    createEODForm(input: $input) {
      url
    }
  }
`;

// endregion

const schema = z.object({
  collateralId: z.string(),
  quantity: z.string().transform((x) => Number(x)),
  price: z.string().transform((x) => Number(x) * 100),
  brokerId: z.string(),
  tradeDate: z.string(),
  settlementDate: z.string(),
  orderSide: z.nativeEnum(OrderSide)
});
// region Props

interface Props {
  settlementId?: string;
}

// endregion

export const GenerateDvpDialog: React.FC<Props> = ({ settlementId }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const { data: brokers } = useBrokersQuery();
  const { data: collaterals } = useCollateralsQuery();
  const [createEodForm] = useCreateEodFormMutation();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });
  const { isSubmitting, errors } = form.formState;

  // region On Approve

  const onRequest = form.handleSubmit(async (data) => {
    const response = await createEodForm({
      variables: {
        input: {
          ...data
        }
      }
    });
    window.open(response!.data?.createEODForm?.url!, '_blank');

    await dialog.onClose();

    form.reset();

    snackbar.enqueueSnackbar('Operation successfully requested', {
      variant: 'success'
    });
  });
  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>Generate DVP</Button>

      {dialog.open && (
        <Dialog {...dialog} fullWidth maxWidth="sm">
          <DialogContent>
            <Typography variant="title">Generate DVP</Typography>

            {/* Collateral */}
            <TextField
              select
              fullWidth
              label="Collateral"
              disabled={!!settlementId}
              error={!!errors?.collateralId}
              helperText={errors?.collateralId?.message}
              {...form.register('collateralId')}
            >
              <option disabled>
                {collaterals ? 'Please select' : 'Loading...'}
              </option>

              {collaterals?.collaterals.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.symbol}
                </option>
              ))}
            </TextField>

            {/* Broker */}
            <TextField
              select
              fullWidth
              label="Broker"
              disabled={!!settlementId}
              error={!!errors?.brokerId}
              helperText={errors?.brokerId?.message}
              {...form.register('brokerId')}
            >
              <option disabled>
                {collaterals ? 'Please select' : 'Loading...'}
              </option>

              {brokers?.brokers.nodes.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </TextField>

            {/* Amount */}
            <TextField
              fullWidth
              label="Amount"
              error={!!errors?.quantity}
              helperText={errors?.quantity?.message}
              {...form.register('quantity')}
            />

            {/* Price */}
            <TextField
              fullWidth
              label="Price"
              error={!!errors?.price}
              helperText={errors?.price?.message}
              {...form.register('price')}
            />

            {/* Order side */}
            <EnumSelect
              fullWidth
              label="Trade Side"
              {...form.register('orderSide')}
              disabled={!!settlementId}
              error={!!errors?.orderSide}
              helperText={errors?.orderSide?.message}
              enum={OrderSide}
            />

            <DatePicker
              label="Trade Date"
              timezone="UTC"
              onChange={(date) => {
                if (date) {
                  form.setValue('tradeDate', (date as any).$d.toISOString());
                }
              }}
            />
            <DatePicker
              label="Settlement Date"
              timezone="UTC"
              onChange={(date) => {
                if (date) {
                  form.setValue(
                    'settlementDate',
                    (date as any).$d.toISOString()
                  );
                }
              }}
            />

            <Box
              sx={{
                pt: '1rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton loading={isSubmitting} onClick={onRequest}>
                Generate
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
