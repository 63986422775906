import React from 'react';

import { Box } from '@mui/material';

import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';
import { AdminsTable } from '../components/AdminsTable';

interface Props {
  companyId?: string
}

export const CompanyAdminsPage: React.FC<Props> = ({ companyId }) => {
  // ---- Hooks ---- //
  const { id } = useCompanyContext();

  return (
    <Box sx={{ marginBottom: '32px' }}>
      <Box display="flex">
        <AdminsTable companyId={companyId ?? id} hideFilters={true} title='Admins'></AdminsTable>
      </Box>
    </Box>
  );
};

export default CompanyAdminsPage;
