import React from 'react';
import { Link as RouterLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Link, Skeleton, Typography } from '@mui/material';
import { NavigationButton, Protector } from '@backed-fi/compound';
import { gql } from '@apollo/client';

import { AdminPermission, BasicClientQuery, useBasicClientQuery } from '@backed-fi/graphql';
import { ClientStatusChip } from './components/ClientStatusChip';

// region Graph

const Graph = gql`
  query BasicClient($id: ID!) {
    client(clientId: $id) {
      id

      name
      status
      frozenUntil
    }
  }
`;

// endregion

export const ClientsLayout: React.FC = () => {
  // ---- Hooks ---- //
  const location = useLocation();
  const params = useParams<{ id: string }>();

  // region Networking

  const { data } = useBasicClientQuery({
    variables: {
      id: params.id!
    }
  });

  const { client } = (data || {}) as BasicClientQuery;

  // endregion

  // region Helper Elements

  const PageTitle = (
    <React.Fragment>
      {location.pathname.endsWith('overview') && 'Overview'}
      {location.pathname.endsWith('usage') && 'Usage & limits'}
      {location.pathname.endsWith('external') && 'Bank Accounts & Wallets'}
      {location.pathname.endsWith('interactions') && 'Interactions'}
      {location.pathname.endsWith('memberships') && 'Organisations & Memberships'}
      {location.pathname.endsWith('timeline') && 'Timeline'}
      {location.pathname.endsWith('features') && 'Features'}
      {location.pathname.endsWith('compliance') && 'Compliance'}
      {location.pathname.endsWith('documents') && 'Documents'}
      {location.pathname.endsWith('files') && 'Files'}
      {location.pathname.endsWith('users') && 'Users'}
      {location.pathname.endsWith('fees') && 'Fees'}
    </React.Fragment>
  );

  // endregion

  // region Helpers

  const getUrl = (section: string) => `/clients/${params.id}/details/${section}`;

  // endregion

  return (
    <Box>
      <Box
        sx={{
          mb: '2rem'
        }}
      >
        {!client ? (
          <Skeleton
            variant="text"
            sx={{
              maxWidth: '350px',
              fontSize: '2.5rem'
            }}
          />
        ) : (
          <Box
            sx={{
              gap: '1rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="title">
              {client.name} - {PageTitle}
            </Typography>

            <ClientStatusChip clientId={client.id} status={client.status} frozenUntil={client.frozenUntil} />
          </Box>
        )}


        <Breadcrumbs
          sx={{
            color: 'utility.textHighContrast'
          }}
        >
          <Box>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              Dashboard
            </Link>
          </Box>

          <Box>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/clients/all"
            >
              Clients
            </Link>
          </Box>

          <Box>
            {!client ? (
              <Skeleton
                variant="text"
                sx={{
                  width: '200px',
                  fontSize: '1rem'
                }}
              />
            ) : (
              <Typography color="primary.textLowContrast">
                [{client.name}]
              </Typography>
            )}
          </Box>


          <Box>
            {PageTitle}
          </Box>
        </Breadcrumbs>
      </Box>

      <Box>
        <NavigationButton first to={getUrl('overview')}>
          Overview
        </NavigationButton>

        <NavigationButton to={getUrl('statistics')}>
          Statistics
        </NavigationButton>

        <NavigationButton to={getUrl('users')}>
          Users
        </NavigationButton>

        <NavigationButton to={getUrl('usage')}>
          Usage & limits
        </NavigationButton>

        <NavigationButton to={getUrl('fees')}>
          Fees
        </NavigationButton>

        <NavigationButton to={getUrl('external')}>
          Bank Accounts & Wallets
        </NavigationButton>

        <NavigationButton to={getUrl('interactions')}>
          Interactions
        </NavigationButton>

        <NavigationButton to={getUrl('sweeping-wallets')}>
          Sweeping Wallets
        </NavigationButton>

        <Protector
          hide
          permissions={[AdminPermission.ComplianceManage]}
        >
          <NavigationButton to={getUrl('features')}>
            Features
          </NavigationButton>
        </Protector>

        <Protector
          hide
          permissions={[AdminPermission.ComplianceRead]}
        >
          <NavigationButton to={getUrl('compliance')}>
            Compliance
          </NavigationButton>
        </Protector>

        <Protector
          hide
          permissions={[AdminPermission.ComplianceReadSensitive]}
        >
          <NavigationButton to={getUrl('documents')}>
            Documents
          </NavigationButton>

          <NavigationButton to={getUrl('files')}>
            Files
          </NavigationButton>
        </Protector>
      </Box>

      <Box
        sx={{
          mt: '2rem'
        }}
      >
        <React.Suspense fallback={'Loading the code...'}>
          <Outlet />
        </React.Suspense>
      </Box>
    </Box>
  );
};
