import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import { Application } from '@backed-fi/constants';
import { createBackedTheme } from '../../theme';
import { useDarkMode } from '@backed-fi/shared/hooks/useDarkMode';

interface Props {
  application: Application;
}

export const ThemeProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  application
}) => {
  const darkMode = useDarkMode();

  const theme = React.useMemo(
    () => createBackedTheme(application, darkMode?.enabled ? 'dark' : 'light'),
    [application]
  );

  return (
    <>
      <CssBaseline />

      <LocalizationProvider dateAdapter={DateAdapter}>
        <MuiThemeProvider theme={theme}>
          {children}
        </MuiThemeProvider>
      </LocalizationProvider>
    </>
  );
};
