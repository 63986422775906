import React from 'react';
import { gql } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, useDisableServiceAccountMutation } from '@backed-fi/graphql';

gql`
   mutation disableServiceAccount($input: DisableServiceAccountMutationInput!) {
    disableServiceAccount(input: $input)
  }
`;

interface Props {
  serviceAccountId: string,
  name: string
}

export const DisableServiceAccountDialog: React.FC<Props> = ({ name, serviceAccountId }) => {
  const disableApiAccessDialogToggle = useToggle();
  const snackbar = useSnackbar();

  // region Networking

  const [disableServiceAccount, { loading }] = useDisableServiceAccountMutation();

  // endregion

  const onUpdate = async () => {
    try {
      await disableServiceAccount({
        awaitRefetchQueries: true,
        refetchQueries: [
          'apiAcceses',
        ],
        variables: {
          input: {
            serviceAccountId: serviceAccountId
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully disabled API access', { variant: 'success' });

      disableApiAccessDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to disable API access', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Protector permissions={[AdminPermission.BlockchainManage]}>
          <Tooltip title="Disable API access">
            <IconButton
              onClick={() => {
                disableApiAccessDialogToggle.toggle();
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Protector>

      </Box>
      {disableApiAccessDialogToggle.open && (
        <Dialog
          {...disableApiAccessDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Box mb={1}>
              <Typography variant='title'>
                Disable {name} API access
              </Typography>

              <Typography
                variant="subtitleSmall"
                sx={{
                  margin: '16px 0'
                }}
              >
                Are you sure you want to delete this API access?
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Box mr={1}>
                <Button
                  onClick={disableApiAccessDialogToggle.onClose}
                >
                  Cancel
                </Button>
              </Box>
              <LoadingButton
                loading={loading}
                onClick={onUpdate}
              >
                Disable
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
