import React from "react";

import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { SimpleNetworkSelector } from "@backed-fi/compound";
import { BlockchainNetwork } from "@backed-fi/graphql";

type ComponentProps = {
  availableNetworks: { name: string; network: BlockchainNetwork }[];
  onChange: (network: string) => void;
  selectedNetwork: BlockchainNetwork;
};

const CreateTokenFormNetwork: React.FC<ComponentProps> = ({
  availableNetworks,
  selectedNetwork,
  onChange,
}) => {
  return (
    <FormControl fullWidth sx={{ margin: "0.3rem 0" }}>
      <InputLabel
        sx={{
          color: "#747B89 !important",

          "&.Mui-focused:not(.Mui-error)": {
            color: "#0066ff !important",
          },
        }}
      >
        Network
      </InputLabel>
      <SimpleNetworkSelector
        selectedNetwork={selectedNetwork}
        availableNetworks={availableNetworks}
        onNetworkChange={onChange}
      />
    </FormControl>
  );
};

export default CreateTokenFormNetwork;
