import React from "react";
import { Box, Typography } from "@mui/material";
import { BlockchainNetwork, FiatCurrency, IncomingTransactionType, OutgoingTransactionType } from "@backed-fi/graphql";
import { CentsFormatter, CryptoFormatter } from "@backed-fi/shared/helpers/formatters";
import { AssetBadge } from "./AssetBadge";


import BankAccountIcon from '@mui/icons-material/AccountBalanceRounded';

export interface AssetInteractionProps {
  small?: boolean;
  clickable?: boolean;

  asset: ({
    type: 'Blockchain'
    id: string;

    hash?: string | null;
    explorerUrl?: string | null;
    confirmations?: number;
    confirmationsNeeded?: number;
    valueFormatter?: () => string;

    network: BlockchainNetwork;
    tokenSymbol: string;
    icon?: string | null;
    amount: string;
    decimals: number;
    assetType: IncomingTransactionType | OutgoingTransactionType
  } | {
    type: 'Fiat'
    id: string;
    amount: string;
    currency: FiatCurrency;

  })
}

export const AssetInteraction: React.FC<AssetInteractionProps> = ({ small, clickable, asset }) => (
  <React.Fragment>
    {asset.type === 'Blockchain' && (
      <Box
        key={asset.hash}
        sx={{
          gap: '0.5rem',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <AssetBadge
          symbol={asset.tokenSymbol}
          network={asset.network}
          icon={asset.icon}
          small={small} />

        <Typography
          sx={{
            ...((clickable && !!asset.explorerUrl) && ({
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'primary.textLowContrast',

              '&:hover': {
                color: 'primary.textHighContrast'
              }
            })),

            ...((small) && ({
              fontSize: '14px'
            }))
          }}

          onClick={(event) => {
            if (clickable && !!asset.explorerUrl) {
              window.open(asset.explorerUrl, '_blank');
              event.stopPropagation();
            }
          }}
        >
          {CryptoFormatter.format(
            asset.amount,
            asset.decimals,
            undefined,
            asset.assetType === 'Stablecoin'
          )}

          {asset.valueFormatter && ` (${asset.valueFormatter()})`}

          <Box
            component="span"
            sx={{
              fontWeight: 700
            }}
          >
            {' '}{asset.tokenSymbol}{' '}
          </Box>

          on {asset.network}

          {!!asset.confirmations && (
            (() => {
              if (!asset.confirmationsNeeded) {
                return ` (${asset.confirmations} confirmations)`;
              }

              if (asset.confirmations > asset.confirmationsNeeded) {
                return ' (Confirmed)';
              }

              return ` (${asset.confirmations}/${asset.confirmationsNeeded} confirmations)`;
            })()
          )}
        </Typography>
      </Box>
    )}

    {asset.type === 'Fiat' && (
      <Box
        sx={{
          gap: '.5rem',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '32px',
            height: '32px',
            borderRadius: '32px',
            color: 'yellow.textLowContrast',
            backgroundColor: 'yellow.elementBackgroundActive',
            border: '1px solid',
            borderColor: 'yellow.borderSubtle',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <BankAccountIcon />
        </Box>

        <Typography>
          Bank transfer for {CentsFormatter.format(asset.amount, asset.currency)}
        </Typography>
      </Box>
    )}
  </React.Fragment>
)