import { Route } from 'react-router-dom';
import { ApiAccessListPage } from './pages/ApiAccessListPage';

export const ApiAccessPages = (
  <>
    <Route
      path='all'
      element={<ApiAccessListPage />}
    />
  </>
);
