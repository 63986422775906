import '@mui/lab/themeAugmentation';
import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Application } from '@backed-fi/constants';
import { black, utilityColorLight } from '../colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;

    publicDashboard: true;
    publicDashboardGhost: true;

    secondary: true;
  }

  interface ButtonPropsColorOverrides {
    ghost: true;
  }
}

export const createMuiLoadingButton = (
  application: Application
): Components<Theme>['MuiLoadingButton'] => {
  return {
    defaultProps: {
      variant: 'outlined',
      disableRipple: true
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const { primary, error, utility } = theme.palette;

        return {
          color: '#fff',
          fontWeight: 600,
          padding: '0.75rem 1.25rem',
          boxShadow: 'none !important',
          border: 'none !important',
          backgroundColor: '#0066FF',

          '&:hover': {
            color: '#fff',
            backgroundColor: '#0052CC'
          },

          '&:active': {
            color: '#fff',
            backgroundColor: '#0052CC'
          },

          ...(ownerState.color === 'ghost' && {
            backgroundColor: 'transparent',
            borderColor: 'white',
            color: 'white',

            '&:hover': {
              color: primary.textHighContrast,
              borderColor: primary.borderHovered,
              backgroundColor: primary.elementBackgroundHovered
            },

            '&:active': {
              color: primary.textHighContrast,
              backgroundColor: primary.elementBackgroundActive
            }
          }),

          // ...(ownerState.variant === 'publicDashboardGhost' && ({})),

          '&.MuiButton-secondary': {
            background: utilityColorLight.elementBackground,
            color: utilityColorLight.solidBackground,

            '&:hover': {
              backgroundColor: utilityColorLight.elementBackgroundHovered,
              color: utilityColorLight.solidBackground,
            },
            '&:active': {
              backgroundColor: utilityColorLight.elementBackgroundHovered,
              color: utilityColorLight.solidBackground,
            },
            '&:disabled': {
              opacity: .5
            },
          },

          '&.MuiButton-text': {
            height: '32px',
            fontSize: '14px',
            color: '#0066FF',
            backgroundColor: 'transparent',
            padding: '7.5px 24px',
            borderRadius: '8px',
            border: 'unset',

            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            },

            '&:disabled': {
              opacity: .5
            },
          },


          '&.MuiButton-publicDashboard': {
            height: '32px',
            fontSize: '14px',
            color: 'white',
            backgroundColor: '#0066FF',
            padding: '7.5px 24px',
            borderRadius: '8px',
            border: 'unset',

            '&:hover': {
              color: 'white',
              backgroundColor: 'black'
            },

            '&:disabled': {
              opacity: .5
            },

            ...(application === Application.Main && {
              height: '40px',
              textTransform: 'none',
              fontWeight: 600,
              fontSize: 14,

              '&.MuiLoadingButton-loading': {
                color: 'transparent',
                opacity: 1,

                '.MuiLoadingButton-loadingIndicator': {
                  color: 'white'
                }
              }
            })
          },

          '&.MuiButton-publicDashboardGhost': {
            fontSize: '14px',
            height: '32px',
            boxSizing: 'border-box',
            color: '#0066FF',
            padding: '4px 24px',
            borderRadius: '8px',
            border: '2px solid',
            borderColor: '#0066FF',
            backgroundColor: 'white !important',

            '&:hover': {
              color: 'black',
              borderColor: 'black'
            },

            '&:disabled': {
              opacity: .5
            },

            ...(application === Application.Main && {
              height: '40px',
              textTransform: 'none',
              fontWeight: 600,
              fontSize: 14,
              backgroundColor: 'transparent !important',

              '&.MuiLoadingButton-loading': {
                color: 'transparent',
                opacity: 1,

                '.MuiLoadingButton-loadingIndicator': {
                  color: '#0066FF'
                }
              }
            })
          },

          '&.MuiButton-outlinedSecondary, &.MuiLoadingButton-outlinedSecondary':
            {
              color: utility.textLowContrast,

              border: `1px solid ${utility.borderElement}`,
              backgroundColor: utility.elementBackground,

              '&:hover': {
                color: utility.textHighContrast,
                borderColor: utility.borderHovered,
                backgroundColor: utility.elementBackgroundHovered
              },

              '&:active': {
                color: utility.textHighContrast,
                backgroundColor: utility.elementBackgroundActive
              }
            },

          '&.MuiButton-outlinedError, &.MuiLoadingButton-outlinedError': {
            color: error.textLowContrast,

            border: `1px solid ${error.borderElement}`,
            backgroundColor: error.elementBackground,

            '&:hover': {
              color: error.textHighContrast,
              borderColor: error.borderHovered,
              backgroundColor: error.elementBackgroundHovered
            },

            '&:active': {
              color: error.textHighContrast,
              backgroundColor: error.elementBackgroundActive
            }
          },

          ...(application === Application.Admin && {
            textTransform: 'none'
          }),

          ...(application === Application.Main && {
            color: primary.textLowContrast,
            border: `1px solid ${utility.borderSubtle}`,
            backgroundColor: 'white'
          })
        };
      }
    }
  };
};
