import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import { useGetTokenStatisticsQuery } from '@backed-fi/graphql';
import { TokenStatisticsData } from './types';
import { getTokenColor } from './colorUtil';
import chartColors from './chartColors';

type OptionType = {
  title: { text?: string };
  tooltip: any;
  legend: any;
  grid: any;
  xAxis: Array<{ boundaryGap: boolean; data: string[] }>;
  yAxis: Array<{ type: string }>;
  series: any[];
  color: any[];
};

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}`;
};

const transformData = (data: TokenStatisticsData[]): any => {
  const result: any = {
    legendData: [],
    seriesData: [],
    xAxisData: []
  };

  // Extract unique dates and sort them
  const uniqueDates = [...new Set(data.map((item) => formatDate(new Date(item.createdAt))))].sort();

  result.xAxisData = uniqueDates;

  const allTokens: string[] = [];
  data.forEach((item: TokenStatisticsData) => {
    item.statistics.tokens.forEach((token) => {
      if (!allTokens.includes(token.symbol)) {
        allTokens.push(token.symbol);
      }
    });
  });

  allTokens.sort(); // ensure same token color matching across charts

  data.forEach((item: TokenStatisticsData) => {
    const { createdAt, statistics: { tokens } } = item;
    const dateStr = formatDate(new Date(createdAt));

    tokens.forEach((token) => {
      if (!result.legendData.includes(token.symbol)) {
        result.legendData.push(token.symbol);
        const initialData = uniqueDates.map((date) => (date === dateStr ? token.aum : null));
        result.seriesData.push({
          name: token.symbol,
          type: 'line',
          // itemStyle: { normal: { color: getTokenColor(token.symbol, allTokens) } },
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: initialData
        });
      } else {
        const seriesIndex = result.legendData.indexOf(token.symbol);
        const dateIndex = uniqueDates.indexOf(dateStr);
        result.seriesData[seriesIndex].data[dateIndex] = token.aum;
      }
    });
  });

  return result;
};


const AumOverTimeChart: React.FC = () => {
  const [option, setOption] = useState<OptionType>({
    title: {},
    tooltip: {},
    legend: {},
    grid: {},
    xAxis: [],
    yAxis: [],
    series: [],
    color: []
  });

  const query = useGetTokenStatisticsQuery({ variables: { isHistory: true } });

  const { loading, error } = query;

  const tokenStatistics = query.data?.tokenStatistics as unknown as TokenStatisticsData[];

  useEffect(() => {
    if (tokenStatistics && tokenStatistics.length) {
      const transformedData = transformData(tokenStatistics);

      setOption({
        title: {
          text: 'AUM Over Time'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: transformedData.legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false,
            data: transformedData.xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: transformedData.seriesData,
        color: chartColors
      });
    }
  }, [tokenStatistics]);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error loading chart data</p>;
  }

  return <ReactECharts option={option} style={{ height: '400px' }} />;
};

export default AumOverTimeChart;
