import React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { EventsListPage } from './pages/EventsListPage';
import { EventDetailsPage } from './pages/EventDetailsPage';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, CompanyFeature } from '@backed-fi/graphql';

export const EventsPages = (
  <Route path='events' element={
    <Protector redirect permissions={[AdminPermission.DeveloperManage]} companyFeature={CompanyFeature.Utils}>
      <Outlet />
    </Protector>
  }>
    <Route
      index
      element={
        <EventsListPage />
      }
    />

    <Route
      path='details/:id'
      element={
        <EventDetailsPage />
      }
    />
  </Route>
);
