import React from 'react';
import Decimal from 'decimal.js';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Box, Button, Grid, Skeleton } from '@mui/material';

import { ContentTile, PageHeading, Protector } from '@backed-fi/compound';
import { CentsFormatter, CryptoFormatter } from '@backed-fi/shared';
import { AdminPermission, useTokenListQuery } from '@backed-fi/graphql';

import { TokenCard } from '../components/TokenCard';

gql`
  query tokenList {
    tokens(includeUnpublished: true) {
      id

      name
      symbol
      description
      icon
      isin

      isPublic
      collateral {
        ISINNumber
        price
        priceCurrency
      }

      deployments {
        network
        address
        stablecoinConfigs {
          symbol
        }
        circulatingSupply
        totalSupply
      }
    }
  }
`;

export const TokensListPage: React.FC = () => {
  const navigate = useNavigate();


  // ---- Networking ---- //
  const { data, loading } = useTokenListQuery();

  // ---- Actions ---- //
  const onCreateToken = () => {
    navigate('/blockchain/tokens/create');
  };


  // ---- Destructuring ---- //
  const { tokens } = data || {};

  const tokenOverviews = React.useMemo(() => {
    if (tokens) {
      return tokens.map((t) => {
        const totalSupply = t.deployments.reduce((acc, val: any) => acc.add(val.totalSupply), new Decimal(0));
        const circulatingSupply = t.deployments.reduce((acc, val: any) => acc.add(val.circulatingSupply), new Decimal(0));

        const circulatingValue = t.collateral.price
          ? CentsFormatter.format(
            new Decimal(circulatingSupply)
              .div(1e18)
              .mul(t.collateral.price)
              .toNumber(),
            t.collateral.priceCurrency,
            true
          )
          : 'N/A $';
        const totalValue = t.collateral.price
          ? CentsFormatter.format(
            new Decimal(totalSupply)
              .div(1e18)
              .mul(t.collateral.price)
              .toNumber(),
            t.collateral.priceCurrency,
            true
          )
          : 'N/A $';

        return {
          ...t,
          totalSupply: CryptoFormatter.format(totalSupply.toString(), 18, t.symbol, false, true),
          circulatingSupply: CryptoFormatter.format(circulatingSupply.toString(), 18, t.symbol, false, true),
          totalValue,
          circulatingValue
        };
      });
    }
    return [];
  }, [tokens]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 6rem)'
      }}
    >
      <PageHeading title="Products">
        <Protector
          hide
          permissions={[AdminPermission.ProductManage]}
        >
          <Button onClick={onCreateToken}>Add Product</Button>
        </Protector>
      </PageHeading>

      <Grid container spacing={4}>
        {loading && <>
          {
            [1, 2, 3].map((i) =>
              <Grid key={i} item lg={12} xl={6} xxl={4}>
                <ContentTile>
                  <Skeleton />
                </ContentTile>
              </Grid>
            )
          }
        </>}
        {!loading && tokenOverviews && tokenOverviews.map((t) => (
          <Grid key={t.id} item sx={{ flexGrow: '1' }} lg={12} xl={6} xxl={4}>
            <TokenCard token={t}></TokenCard>
          </Grid>
        ))}
      </Grid>
    </Box >
  );
};
