import React from 'react';
import { Avatar, Box, LinearProgress, Typography } from '@mui/material';
import { gql } from '@apollo/client';


import { PageHeading } from '@backed-fi/compound';
import { UsersListPageQuery, useUsersListPageQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';


import { DateFormatter } from '@backed-fi/shared';
import { usePaginationModel } from '@backed-fi/shared/hooks/usePaginationModel';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';

gql`
  query usersListPage(
    $page: Int = 1,
    $pageSize: Int,
    $where: UsersFilter
  ) {
    users(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }

      nodes {
        id

        createdAt

        firstName
        secondName
        lastName

        email

        client {
          id
          name
        }
      }
    }
    }
`;

export const UsersListPage = () => {
  const { id: companyId } = useCompanyContext();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // ---- Networking ---- //
  const query = useUsersListPageQuery({
    variables: {
      ...paginationVariables,
      where: {
        companyId
      }
    }
  });

  // ---- Destructuring ---- //
  const { users } = query.data as UsersListPageQuery;

  React.useEffect(() => {
    (async () => {
      await query.refetch({
        ...paginationVariables,
        where: {
          companyId
        }
      });
    })();
  }, [paginationVariables]);


  return (
    <Box>
      <PageHeading
        title="Users"
        breadcrumbs={[{
          text: 'Users'
        }]}
      />

      <DataGrid
        {...pagination}

        loading={query.loading}

        slots={{
          loadingOverlay: LinearProgress
        }}

        autoHeight
        hideFooter={false}
        rows={users?.nodes || query.previousData?.users.nodes || []}
        rowCount={users?.page.totalNodes}
        rowHeight={70}
        sx={{
          marginBottom: '2rem',
          backgroundColor: 'utility.backgroundSubtle'
        }}

        columns={[{
          flex: 10,
          sortable: false,
          field: 'name',
          headerName: 'Name',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Avatar>
                  {row.firstName?.[0]}{row.lastName?.[0]}
                </Avatar>


                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    ml: '1rem'
                  }}
                >
                  <Typography>
                    {row.firstName} {row.lastName}
                  </Typography>

                  <Box
                    sx={{
                      mt: '-7.5px'
                    }}
                  >
                    {row.email}
                  </Box>
                </Box>
              </Box>
            );
          }
        }, {
          flex: 8,
          field: 'client',
          sortable: false,
          headerName: 'Client',
          renderCell: ({ row }) => (
            <Typography>
              {row.client ? row.client.name : 'The user is not linked to a client'}
            </Typography>
          )
        }, {
          flex: 2,
          sortable: false,
          field: 'createdAt',
          headerName: 'Registration Date',
          renderCell: ({ row }) => (
            <Typography>
              {DateFormatter.format(new Date(row.createdAt))}
            </Typography>
          )
        }]}
      />
    </Box>
  );
};
