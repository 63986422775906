import { Box, Chip, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { HashTile, InfoCard, InfoLabel, PageHeading, Title } from '@backed-fi/compound';
import { gql } from '@apollo/client';
import { OracleAnswerUpdateStatus, OracleDetailsQuery, useOracleDetailsQuery, useUpdateOracleAnswerMutation } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import formatRelative from 'date-fns/formatRelative';
import { CapitaliseFormatter, DateTimeFormatter } from '@backed-fi/shared';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import OpenIcon from '@mui/icons-material/OpenInFullRounded';

// region Graph

gql`
  query oracleDetails($oracleId: String!) {
    oracle(
      where: {
        id: $oracleId
      }
    ) {
      id

      network
      address

      symbol

      version
      isActive

      managedBy
      updateSchedule
      updateScheduleOnWeekend

      latestTimestamp

      type

      answerUpdates {
        id
        createdAt
        updatedAt

        previousAnswer
        newAnswer
        status

        hash
        explorerUrl

        admin {
          id
          firstName
          lastName
          email
        }
      }

      forwarder {
        id

        network
        address
      }
    }
  }
`;

// endregion

export const OracleDetailsPage: React.FC = () => {
  const snackbar = useSnackbar();
  const params = useParams<{ id: string }>();

  // region State

  const [answerUpdateDetails, setAnswerUpdateDetails] = React.useState<OracleDetailsQuery['oracle']['answerUpdates'][number]>();

  // endregion

  // region Networking

  const [updateOracleAnswerMutation, { loading: requestingUpdate }] = useUpdateOracleAnswerMutation();
  const { data } = useOracleDetailsQuery({
    variables: {
      oracleId: params.id!
    }
  });

  const { oracle } = (data || {}) as OracleDetailsQuery;

  // endregion

  // region Actions

  const onRequestAnswerUpdate = async () => {
    await updateOracleAnswerMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'oracleDetails'
      ],
      variables: {
        input: {
          oracleId: params.id!
        }
      }
    });

    snackbar.enqueueSnackbar('Oracle answer update successfully requested');
  };

  // endregion

  return (
    <Box>
      <PageHeading
        title="Oracle Details"
        breadcrumbs={[
          {
            text: 'Blockchain'
          }, {
            text: 'Oracles'
          }, {
            text: `[${params.id}}]`
          }, {
            text: 'Details'
          }
        ]}
      />

      {/* region Dialogs */}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!answerUpdateDetails}
        onClose={() => setAnswerUpdateDetails(undefined)}
      >
        <DialogContent>
          <Title
            title="Oracle Answer Update Details"
          />

          {answerUpdateDetails && (
            <React.Fragment>
              <InfoLabel
                label="New Answer"
                content={answerUpdateDetails.newAnswer}
              />

              <InfoLabel
                label="Previous Answer"
                content={answerUpdateDetails.previousAnswer}
              />

              <InfoLabel
                label="Requested By"
                content={
                  answerUpdateDetails.admin
                    ? answerUpdateDetails.admin.email
                    : 'System'
                }
              />


              <InfoLabel
                label="Status"
                content={answerUpdateDetails.status}
              />

              <InfoLabel
                copy={!!answerUpdateDetails.hash}
                label="Hash"
                content={answerUpdateDetails.hash || 'Not Available'}
              />
            </React.Fragment>
          )}
        </DialogContent>
      </Dialog>

      {/* endregion */}

      <Box
        sx={{
          gap: '1rem',
          display: 'grid'
        }}
      >
        <Title
          boxed
          title="Details"
          subtitle="The most important oracle details"
        />

        <Box
          sx={{
            gap: '1rem',
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr'
            }
          }}
        >
          <InfoCard
            title="Update Schedule"
            data={oracle?.updateSchedule}
          />

          <InfoCard
            title="Managed By"
            data={oracle?.managedBy}
          />

          <InfoCard
            title="Last Price Update"
            data={
              oracle
                ? CapitaliseFormatter(formatRelative(new Date(oracle.latestTimestamp * 1000), new Date()))
                : undefined
            }
          />
        </Box>


        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr'
            }
          }}
        >
          <InfoLabel
            copy
            label="Oracle ID"
            content={oracle?.id}
          />

          <InfoLabel
            copy
            label="Address"
            content={oracle?.address}
          />

          <InfoLabel
            label="Network"
            content={oracle?.network}
          />

          <InfoLabel
            label="Oracle Type"
            content={oracle?.type}
          />

          <InfoLabel
            label="Managed By"
            content={oracle?.updateSchedule}
          />

          <InfoLabel
            label="Version"
            content={oracle?.version}
          />


          <InfoLabel
            label="Weekend Updates"
            content={
              oracle
                ? oracle.updateScheduleOnWeekend
                  ? 'Yes'
                  : 'No'
                : undefined
            }
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: '2rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Title
            boxed
            title="Answer Updates"
            subtitle="The latest answer updates for that oracle"
          />

          <LoadingButton
            onClick={onRequestAnswerUpdate}
            loading={requestingUpdate}
          >
            Request Answer Update
          </LoadingButton>
        </Box>

        <DataGrid
          sx={{
            mt: '1rem'
          }}
          autoHeight
          rows={oracle?.answerUpdates ?? []}
          columns={[
            {
              minWidth: 250,
              field: 'createdAt',
              headerName: 'Created At',
              renderCell: ({ value }) => (
                <Typography>
                  {DateTimeFormatter.format(value)}
                </Typography>
              )
            }, {
              width: 175,
              field: 'hash',
              headerName: 'Transaction Hash',
              renderCell: ({ row }) => (
                <HashTile
                  hash={row.hash}
                  explorerUrl={row.explorerUrl}
                />
              )
            }, {
              width: 300,
              field: 'status',
              headerName: 'Status',
              renderCell: ({
                value,
                row
              }) => (
                <Chip
                  label={value}
                  color={({
                    [OracleAnswerUpdateStatus.Applied]: 'success',
                    [OracleAnswerUpdateStatus.Failed]: 'error',
                    [OracleAnswerUpdateStatus.Pending]: 'primary'
                  } as const)[row.status]}
                />
              )
            }, {
              width: 200,
              field: 'newAnswer',
              headerName: 'New Answer',
              renderCell: ({
                value,
                row
              }) => (
                <React.Fragment>
                  <Typography mr={1}>
                    {value}
                  </Typography>

                  <Tooltip
                    title="View full update information"
                  >
                    <IconButton
                      onClick={() => {
                        setAnswerUpdateDetails(row);
                      }}
                    >
                      <OpenIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
            }, {
              width: 400,
              field: 'id',
              headerName: 'Triggered By',
              renderCell: ({ row }) => (
                row.admin
                  ? (
                    <Typography>
                      Manually by {row.admin.firstName} {row.admin.lastName}
                    </Typography>
                  ) : (
                    <Typography>
                      System (following the update schedule)
                    </Typography>
                  )
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
