import React from 'react';
import { Box, Typography } from '@mui/material';
import { PageHeading, Title } from '@backed-fi/compound';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { useCustodyAccountDetailsQuery, CustodyAccountDetailsQuery, CustodyAccountEvent, CustodyAccountEventFlowDirection } from '@backed-fi/graphql';

import { BalanceHistoryChart } from './components/BalanceHistoryChart';
import { DateTimeFormatter } from '@backed-fi/shared';

// region Graph

gql`
  query custodyAccountDetails($where: CustodyAccountWhereUniqueInput!) {
    custodyAccount(where: $where) {
      id
      
      facets {
        id
        type
        balanceChanges {
          createdAt
          balance
        }
        asset
        balance
        label
      }
      events {
        id
        tookPlaceAt
        description
        type
        changeAmount
        changeCurrency
        flowDirection
      }
      externalId
    }
  }
`;

// endregion

export const AccountDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  // region Networking

  const { data } = useCustodyAccountDetailsQuery({
    variables: {
      where: {
        id: params.id!
      }
    }
  });

  const { custodyAccount } = (data ?? {}) as CustodyAccountDetailsQuery;

  const dataseries = React.useMemo(() => {
    if (!custodyAccount) {
      return [];
    }

    return custodyAccount.facets.map((facet) => (
      facet
    )
    );
  }, [custodyAccount]);
  // endregion

  return (
    <Box>
      <PageHeading
        title="Custody Account Details"
        breadcrumbs={[
          {
            text: 'FinOps'
          }, {
            text: 'Accounts',
            link: '/finops/accounts/list'
          }, {
            text: 'Details'
          }
        ]}
      />

      {custodyAccount && (
        <React.Fragment>
          <Title
            title={`Details of ${custodyAccount.externalId} account`}
            subtitle="Breakdown of Incore account"
          />


          <Box sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            my: '8px'
          }}>

            {dataseries.map((data) => (
              <BalanceHistoryChart key={data!.id} data={data! as any} />
            ))}
          </Box>

          <Box
            sx={{
              mt: '2rem',
              mb: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Title
                title="Past account events"
                subtitle="List of incore statements observed on the account"
              />
            </Box>
          </Box>
          <DataGrid
            autoHeight
            rows={custodyAccount.events.filter((x): x is CustodyAccountEvent => !!x)}
            columns={[
              {
                flex: 3,
                field: 'tookPlaceAt',
                minWidth: 150,
                headerName: 'Date',
                renderCell: ({ value }) => (
                  <Box>
                    <Typography
                    >
                      {DateTimeFormatter.format(new Date(value))}
                    </Typography>
                  </Box>
                )
              },
              {
                flex: 8,
                field: 'description',
                minWidth: 300,
                headerName: 'Description',
                renderCell: ({ value }) => (
                  <Box>
                    <Typography
                    >
                      {value}
                    </Typography>
                  </Box>
                )
              },
              {
                flex: 2,
                field: 'changeAmount',
                minWidth: 175,
                headerName: 'Amount Exchanged',
                renderCell: ({ value, row }) => (
                  <Box>
                    <Typography
                    >
                      {{
                        [CustodyAccountEventFlowDirection.Debit]: '-',
                        [CustodyAccountEventFlowDirection.Credit]: '+'
                      }[row.flowDirection!] ?? '(?)'} {Number(value) / 100} {row.changeCurrency}
                    </Typography>
                  </Box>
                )
              },
              {
                flex: 1,
                field: 'type',
                minWidth: 100,
                headerName: 'Type',
                renderCell: ({ value }) => (
                  <Box>
                    <Typography
                    >
                      {value}
                    </Typography>
                  </Box>
                )
              }
            ]}
          />

        </React.Fragment>
      )}
    </Box>
  );
};
