import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { FlowsExecutionsTable } from '../components/FlowsExecutionsTable';


export const FlowExecutionsListPage = () => {
  return (
    <Box>
      <PageHeading
        title="Activities"
      >
      </PageHeading>

      <FlowsExecutionsTable />
    </Box>
  );
};
