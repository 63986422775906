import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Grid, Skeleton, Typography } from '@mui/material';

import { gql } from '@apollo/client';
import { useCompanyTokensQuery } from '@backed-fi/graphql';
import { ContentTile, InfoLabel, Title } from '@backed-fi/compound';

gql`
  query companyTokens(
    $companyId: ID!
  ) {
    tokens(companyId: $companyId) {
      id
      icon
      name
      symbol
      deployments {
        network
      }
    }
  }
`;

interface Props {
  companyId: string;
}

export const CompanyTokensPage: React.FC<Props> = ({ companyId }) => {
  // ---- Hooks ---- //
  const navigate = useNavigate();

  const query = useCompanyTokensQuery({
    variables: {
      companyId: companyId
    }
  });

  const {
    data,
    loading
  } = query;

  return (
    <Box mt={4}>
      <Title title="Tokens" />
      <Grid container spacing={2}>
        {!loading && data?.tokens.map(token => (
          <Grid item xs={6} lg={4} xl={3}>
            <ContentTile sx={{
              marginTop: '16px'
            }}>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => navigate(`/blockchain/tokens/details/${token.id}/overview`)}
              >
                <Avatar
                  sx={{
                    width: '80px',
                    height: '80px'
                  }}
                  alt={token.symbol}
                  src={token.icon ?? `/assets/icons/assets/${token.symbol}.svg`}
                />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '16px'
                }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                    }}
                    flex={1}
                    variant='subtitle'
                  >
                    {token.symbol}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex'
                    }}
                    flex={1}
                    variant='subtitleSmall'
                  >
                    {token.name}
                  </Typography>
                </Box>
              </Box>
              <InfoLabel
                label='Networks' content={
                  <>
                    {token.deployments.map(d => (
                      <Box
                        src={`/assets/icons/assets/native/${d.network}.svg`}
                        sx={{ marginTop: '4px', marginRight: '8px' }}
                        component='img'
                        width={32}
                        height={32}
                      />))}
                  </>}>
              </InfoLabel>
            </ContentTile>
          </Grid>
        ))}
        {!loading && !data?.tokens?.length && <>
          <Grid item xs={12}>
            <Typography variant="subtitleSmall">Company has yet to deploy tokens</Typography>
          </Grid>
        </>}
        {loading &&
          [1, 2].map(i => (
            <Grid item xs={6} lg={4} xl={3}>
              <ContentTile
                sx={{
                  minHeight: '230px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: '32px',
                }}
              >
                <Skeleton animation="wave"></Skeleton>
              </ContentTile>
            </Grid>
          ))
        }
      </Grid>
    </Box >
  );
};

export default CompanyTokensPage;
