import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { gql } from '@apollo/client';
import { AdminPermission, RoleType, useRolesListQueryQuery } from '@backed-fi/graphql';
import { PageHeading, Protector } from '@backed-fi/compound';
import { DataGrid } from '@mui/x-data-grid';
import DetailsIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { useNavigate } from 'react-router-dom';
import { CreateRoleDialog } from './components/CreateRoleDialog';
import { useToggle } from '@backed-fi/hooks';
import { DeleteRoleDialog } from './components/DeleteRoleDialog';
import { UpdateRoleDialog } from './components/UpdateRoleDialog';


// region Graph

gql`
  query rolesListQuery(
    $page: Int,
    $pageSize: Int
  ) {
    roles (
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        name
        description
        type
        permissions
        admins {
          email
          firstName
          lastName
        }
      }

      page {
        currentPage
        totalPages
        totalNodes
      }
    }
  }
`;

// endregion

export const RolesListPage: React.FC = () => {
  const navigate = useNavigate();
  const createRoleDialogToggle = useToggle();

  // region Networking

  const query = useRolesListQueryQuery();

  const { roles } = (query.data ?? {});

  // endregion

  return (
    <Box>
      {/* region Dialogs */}
      <CreateRoleDialog {...createRoleDialogToggle} />
      {/* endregion */}

      <PageHeading
        title="Roles"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Roles'
          }
        ]}
      />

      <Box
        sx={{
          my: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Protector permissions={[AdminPermission.SystemPermissionsManage]} hide>
          <Button onClick={createRoleDialogToggle.setTrue}>
            Create Role
          </Button>
        </Protector>
      </Box>

      <DataGrid
        autoHeight
        rows={(roles?.nodes ?? [])}
        columns={[
          {
            flex: 2,
            minWidth: 200,
            field: 'name',
            headerName: 'Role Name'
          }, {
            flex: 1,
            minWidth: 200,
            field: 'type',
            headerName: 'Type'
          },
          {
            width: 75,
            field: 'description',
            headerName: '',
            renderCell: ({ row }) => (
              row.type !== RoleType.PredefinedBySystem && <UpdateRoleDialog adminRole={row} />
            )
          },
          {
            width: 75,
            field: 'permissions',
            headerName: '',
            renderCell: ({ row }) => (
              row.type !== RoleType.PredefinedBySystem && <DeleteRoleDialog adminRole={row} />
            )
          },
          {
            width: 75,
            field: 'id',
            headerName: '',
            renderCell: ({ value }) => (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  onClick={() => {
                    navigate(`/internal/roles/details/${value}`);
                  }}
                >
                  <DetailsIcon />
                </IconButton>
              </Box>
            )
          }
        ]}

      />

    </Box>
  );
};
