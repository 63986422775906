import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import React from 'react';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { usePaginationModel } from '@backed-fi/hooks';
import { useSweepingWalletsQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';


gql`
  query sweepingWallets(
    $page: Int,
    $pageSize: Int,
    $clientId: ID!,
  ) {
    sweepingWallets(
      page: $page,
      pageSize: $pageSize,
      where: {
          clientId: $clientId
      }
    ) {
      nodes {
        id
        address
        externalServiceId
        externalService
        type
        token {
          symbol
          icon
        }
      }

      page {
        pageSize
        totalPages
        totalNodes
      }
    }
  }
`;


export const ClientSweepingWalletsPage = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region Networking

  const { data, loading } = useSweepingWalletsQuery({
    variables: {
      ...paginationVariables,
      clientId: params.id!
    }
  });

  return (
    <Box>
      {/* region Wallets  */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="titleSmall">Sweeping wallets</Typography>

          <Typography variant="subtitleSmall">
            List of all the sweeping wallets registered in the system for the client
          </Typography>
        </Box>
      </Box>
      <DataGrid
        {...pagination}

        autoHeight
        loading={loading}
        rows={data ? data.sweepingWallets!.nodes : []}
        rowCount={data?.sweepingWallets?.page.totalNodes}
        components={{
          LoadingOverlay: LinearProgress
        }}
        columns={[{
          flex: 2,
          field: 'externalServiceId',
          headerName: 'External Id',
          renderCell: ({ value }) => (
            <Typography
              variant='code'
              sx={{
                height: '21px',
                gap: '0.25rem',
                display: 'flex',
                alignItems: 'center',
                fontSize: 14
              }}
            >
              {value}

              <IconButton
                color='inherit'
                onClick={() => {
                  navigator.clipboard.writeText(value).then(() => {
                    snackbar.enqueueSnackbar('Sweeping wallet external id to clipboard', {
                      variant: 'success'
                    });
                  });
                }}
              >
                <ContentCopyIcon fontSize='small' />
              </IconButton>
            </Typography>
          )
        },
        {
          flex: 1,
          field: 'externalService',
          headerName: 'External Service',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        },
        {
          flex: 2,
          field: 'address',
          headerName: 'Address',
          renderCell: ({ value }) => (
            <Box
              sx={{
                flex: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                variant='code'
                sx={{
                  height: '21px',
                  gap: '0.25rem',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14
                }}
              >
                {value}

                <IconButton
                  color='inherit'
                  onClick={() => {
                    navigator.clipboard.writeText(value).then(() => {
                      snackbar.enqueueSnackbar('Sweeping wallet address copied to clipboard', {
                        variant: 'success'
                      });
                    });
                  }}
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Typography>
            </Box>
          )
        },
        {
          flex: 1,
          field: 'type',
          headerName: 'Type',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        },
        {
          flex: 1,
          field: 'sweepingWallet.token',
          sortable: false,
          headerName: 'Token',
          renderCell: ({ row }) => (
            <Typography>
              {row.token && <Box sx={{
                display: 'flex',
                marginRight: '4px'
              }}>
                <Box
                  flex={0}
                  mr={1}
                  src={`/assets/icons/assets/${row.token.symbol}.svg`}
                  component="img"
                  width={24}
                  height={24}
                />
                <Typography flex={1}>{row.token.symbol}</Typography>
              </Box>
              }
            </Typography>
          )
        }]}
      />
    </Box>
  );
};
