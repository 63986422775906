import * as z from 'zod';
import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

import { Box, Dialog, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import { BlockchainAddressType, useAddBlockchainAddressMutation } from '@backed-fi/graphql';
import { customBlockchainAddressValidator } from '@backed-fi/shared';

// region Schema


const schema = z.object({
  /**
   * The address that is being created
   */
  address: customBlockchainAddressValidator('Address is required', 'Address format is not valid'),

  /**
   * The type of the address that we are adding
   */
  addressType: z.nativeEnum(BlockchainAddressType),

  /**
   * Short description of the address being created
   */
  description: z.string()
    .min(1, 'Name is required')
});

// endregion

// region Graph

gql`
  mutation addBlockchainAddress($input: CreateBlockchainAddressInput!) {
    addBlockchainAddress: createBlockchainAddress(input: $input) {
      id
    }
  }
`;

// endregion

export const AddBlockchainAddressDialog: React.FC<DialogProps> = (props) => {
  const snackbar = useSnackbar();

  const [addBlockchainAddressMutation] = useAddBlockchainAddressMutation();

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      addressType: BlockchainAddressType.Wallet
    }
  });

  const {
    isSubmitting,
    errors
  } = form.formState;

  // endregion

  // region Actions

  const onCloseInternal: typeof props.onClose = (...args) => {
    form.reset();


    if (typeof props.onClose === 'function') {
      props.onClose(...args);
    }
  };

  const onCreate = form.handleSubmit(async (data) => {
    try {
      await addBlockchainAddressMutation({
        refetchQueries: [
          'blockchainAddresses'
        ],
        awaitRefetchQueries: true,
        variables: {
          input: data
        }
      });

      snackbar.enqueueSnackbar('Address added successfully', {
        variant: 'success'
      });


      (onCloseInternal as any)();
    } catch (e: any) {
      snackbar.enqueueSnackbar(`Unable to add address due to '${e.message}'`, {
        variant: 'error'
      });
    }
  });

  // endregion


  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '12px'
        }
      }}
      {...props}
      onClose={onCloseInternal}
    >
      <DialogContent
        sx={{
          padding: '32px'
        }}
      >
        <Typography variant="title">Add Address</Typography>


        <Typography
          variant="subtitleSmall"
          sx={{
            margin: '16px 0'
          }}
        >
          Add an address to perform token operations and monitor its current holdings. Burning tokens is only possible from the dedicated system burn-wallet.
        </Typography>

        <Controller
          name="description"
          control={form.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              fullWidth
              label="Name"
              sx={{
                margin: '4px 0'
              }}
              {...field}
              error={!!errors.description}
            />
          )}
        />

        <Controller
          name="address"
          control={form.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              fullWidth
              label="Address"
              sx={{
                margin: '4px 0'
              }}
              {...field}
              error={!!errors.address}
            />

          )}
        />

        <Box
          sx={{
            mt: '.5rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            onClick={onCreate}
            loading={isSubmitting}
          >
            Add Address
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog >
  );
};
