import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { UseControllerProps, useController } from 'react-hook-form';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type FormValues = {
  img?: any
}
export const ImageUpload = (props: UseControllerProps<FormValues>) => {
  const { field, fieldState } = useController(props);
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (typeof (field.value) === 'string') {
      setPreview(field.value as string);
    } else if (field.value) {
      setPreview(URL.createObjectURL(field.value as File));
    }
  }, [field.value]);

  const inputId = useMemo(() => Math.random().toString(32).substring(2), []);

  const handleOnAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    field.onChange(file);
  };

  return (
    <>
      <label htmlFor={inputId}>
        <Grid container sx={{
          height: '100px',
          width: '100px',
          cursor: 'pointer'
        }}>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            {
              preview && <img
                src={preview}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  aspectRatio: '1 / 1',
                  borderRadius: '360px'
                }}
                alt=''
              />
            }
            {
              !preview && <CloudUploadIcon sx={{
                width: '100%',
                height: '100%',
              }} />
            }
          </Grid>
        </Grid>
        <input
          id={inputId}
          type='file'
          accept='image/*,.png,.jpg,.jpeg,.gif'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAddImage(e)}
          style={{ display: 'none' }}
        />
      </label>
    </>
  );
};
