import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { Application } from '@backed-fi/constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { GraphProvider } from './GraphProvider';
import { ThemeProvider } from './ThemeProvider';
import { SentryProvider } from './SentryProvider';
import { HeapAnalyticsProvider } from './HeapAnalyticsProvider';
import { UserContextProvider } from './context/UserContext';
import { ClientContextProvider } from './context/ClientContext';
import { AuthContextProvider } from './context/AuthContext';
import { BrowserContextProvider } from './context/BrowserContext';
import { AdminContextProvider } from '@backed-fi/admin/src/app/context/AdminContext';
import { ConfettiProvider } from '@backed-fi/shared/components/providers/ConfettiProvider';
import { Web3ContextProvider } from './context/Web3Context';
import { CompanyContextProvider } from './context/CompanyContext';
import { UsageContextProvider } from './context/UsageContext';

dayjs.extend(utc);

interface Props {
  pages: JSX.Element;
  application: Application;
}

export const BackedProvider: React.FC<any & Props> = ({
  application,
  pages
}) => (
  <BrowserRouter>

    {/* Analytics and monitoring */}
    <HeapAnalyticsProvider />
    <SentryProvider />

    <HelmetProvider>
      <ThemeProvider application={application}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Routes>
              <Route
                element={
                  <AuthContextProvider>
                    <Web3ContextProvider>
                      <GraphProvider application={application}>
                        <CompanyContextProvider>
                          <BrowserContextProvider>
                            {application === Application.Admin && (
                              <AdminContextProvider>
                                {/* Important! */}
                                <ConfettiProvider>
                                  <Outlet />
                                </ConfettiProvider>
                              </AdminContextProvider>
                            )}

                            {application === Application.Main && (
                              <UserContextProvider>
                                <ClientContextProvider>
                                  <UsageContextProvider>
                                    <Outlet />
                                  </UsageContextProvider>
                                </ClientContextProvider>
                              </UserContextProvider>
                            )}
                          </BrowserContextProvider>
                        </CompanyContextProvider>
                      </GraphProvider>
                    </Web3ContextProvider>
                  </AuthContextProvider>
                }
              >
                {pages}
              </Route>
            </Routes>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  </BrowserRouter>
);
