import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, Typography } from "@mui/material";

import {
  BlockchainNetwork,
  FlowType
} from "@backed-fi/graphql";
import { FlowInformation } from "./FlowInformation";

interface Props {
  flowType: FlowType;

  token: {
    id: string;
    name: string;
    symbol: string;
    icon?: string | null;
    collateral: {
      ISINNumber?: string | null;
    };
  };
  flow: {
    amount: string;
    totalSupply: string;
    network: BlockchainNetwork;
    wallet: string;
  };

  onSubmit: () => Promise<void>;
  onBack: () => void;
}

const FlowTypeLabel = {
  [FlowType.Mint]: {
    title: "minting",
    button: "Mint",
  },
  [FlowType.Burn]: {
    title: "burning",
    button: "Burn",
  },
};

export const FlowPreview: React.FC<Props> = ({
  token,
  flow,
  flowType,
  onSubmit,
  onBack,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onClick = async () => {
    setIsLoading(true);

    await onSubmit();
  };

  return (
    <>
      <Typography
        variant="subtitleSmall"
        sx={{
          marginTop: "12px",
        }}
      >
        Please review the operation details thoroughly before confirming. <br />
        Having trouble {FlowTypeLabel[flowType].title}
        tokens?&nbsp;
        <Link
          sx={{ color: "#747B89", textDecorationColor: "currentColor" }}
          href="mailto:support@backed.fi"
        >
          Contact Support
        </Link>
      </Typography>

      <FlowInformation
        token={token}
        flow={flow}
        flowType={flowType}
      />

      <Box
        sx={{
          pt: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="secondary" onClick={onBack}>
          Go back
        </Button>
        <LoadingButton loading={isLoading} onClick={onClick}>
          {FlowTypeLabel[flowType].button} {token.symbol}
          &nbsp; tokens
        </LoadingButton>
      </Box>
    </>
  );
};
