import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAuthContext } from '@backed-fi/context';
import { AdminPermission } from '@backed-fi/graphql';
import { Protector } from '@backed-fi/compound';

import { MFADialog } from '@backed-fi/admin/src/app/components/MFADialog';
import { CommandMenu } from '@backed-fi/admin/src/app/components/CommandMenu';
import { AdminNavigation } from './AdminNavigation';
import { AdminMobileHeader } from '@backed-fi/admin/src/app/layouts/AdminMobileHeader';

export const AdminLayout: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // ---- Hooks ---- //
  const authContext = useAuthContext();

  return (
    <Protector permissions={[AdminPermission.AdminAccess]}>
      {!authContext.authenticated && <Box>Loading...</Box>}

      {authContext.authenticated && (
        <React.Fragment>
          <MFADialog />
          <CommandMenu />

          <Box
            sx={{
              width: '100vw',
              minHeight: '100vh',
              minWidth: '648px',

              color: '#121419',
              backgroundColor: '#F2F4F8',

              ...(isDesktop && ({
                display: 'flex'
              }))
            }}
          >
            {isDesktop ? (
              <AdminNavigation />
            ) : (
              <AdminMobileHeader />
            )}

            <Box
              sx={() => ({
                flex: 1,
                height: '100vh',
                overflow: 'scroll',
                backgroundImage: 'url(/assets/images/backgrounds/body-background-light.svg)',
                backgroundPosition: 'top right',
                backgroundRepeat: 'no-repeat',

                ...(isDesktop ? ({
                  px: '3rem',
                  pt: '3rem'
                }) : ({
                  px: '2rem',
                  pt: '2rem'
                }))
              })}
            >
              <Protector permissions={[AdminPermission.AdminAccess]}>
                <Outlet />
              </Protector>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Protector>
  );
};
