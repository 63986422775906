import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { registerField } from '@backed-fi/shared';

interface Props {
  symbol: string;
  inputProps: ReturnType<typeof registerField>;
}

export const MintFlowAmount: React.FC<Props> = ({
  symbol,
  inputProps
}) => {
  return (
    <FormControl fullWidth sx={{ margin: '0.5rem 0' }} error={inputProps.error}>
      <InputLabel
        sx={{
          color: '#747B89 !important',

          '&.Mui-focused:not(.Mui-error)': {
            color: '#0066ff !important'
          }
        }}
        error={inputProps.error}
      >
        Mint Amount
      </InputLabel>
      <OutlinedInput
        {...inputProps}
        label={'Mint Amount'}
        endAdornment={
          <InputAdornment sx={{ maxWidth: '150px' }} position="end">
            <Typography sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{symbol}</Typography>
          </InputAdornment>
        }
      />
      {inputProps.helperText && (
        <FormHelperText error={inputProps.error}>
          {inputProps.helperText.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
};
