import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Application } from '@backed-fi/constants';

export const createMuiTextField = (
  application: Application
): Components<Theme>['MuiTextField'] => ({
  defaultProps: {
    SelectProps: {
      native: true
    }
  },
  styleOverrides: {
    root: ({
      theme,
      ownerState
    }) => {
      const {
        utility,
        error
      } = theme.palette;

      const color = ownerState.error
        ? error.textLowContrast
        : '#747B89' // utility.textHighContrast;

      const borderColor = ownerState.error
        ? error.borderElement
        : '#E2E5EB' // utility.borderElement;

      const borderColorHover = ownerState.error
        ? error.borderHovered
        : utility.borderHovered;

      return {
        margin: '.3rem 0',
        color: color,

        'label, p, select, svg': {
          color: `${color} !important`
        },

        fieldset: {
          borderColor: `${borderColor} !important`,

          '&:hover': {
            borderColor: `${borderColorHover} !important`
          }
        },

        '.MuiOutlinedInput-root': {
          borderRadius: '8px',
          backgroundColor: '#fff'
        },

        '.MuiInputLabel-root[data-shrink="false"]': {
          transform: 'translate(1.25rem, 1rem)'
        },

        '.MuiOutlinedInput-input': {
          padding: '1rem 1.25rem'
        },

        'textarea.MuiOutlinedInput-input': {
          padding: '0 0.375rem'
        },

        'label.Mui-focused:not(.Mui-error)': {
          color: '#0066ff !important'
        },

        '.Mui-focused:not(.Mui-error) fieldset': {
          borderWidth: '1px !important',
          borderColor: '#0066ff !important',
          boxShadow: '0 0.25rem 0.5rem #0066ff33'
        },

        '.Mui-disabled fieldset': {
          backgroundColor: '#0000000a'
        }
      };
    }
  }
});
