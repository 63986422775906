import React from 'react';

import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { availableNetworks } from './CreateTokenForm';

type ComponentProps = {
  tokenDetails: {
    name: string;
    symbol: string;
    description: string;
    isin?: string;
    icon: string;
  };
  underlyingDetails: {
    name: string;
    symbol: string;
    isin: string;
    publiclyListed: boolean;
    startingNav: number | undefined;
  };
  deploymentDetails: {
    network: string;
  };
};

const CreateTokenFormSummary: React.FC<ComponentProps> = ({
  tokenDetails,
  underlyingDetails,
  deploymentDetails,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2.5rem',
          backgroundColor: '#F2F4F8',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',

          img: {
            overflow: 'hidden',
            borderRadius: '100%',
          },
        }}
      >
        <Box src={tokenDetails.icon} component="img" width={48} height={48} />
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '6rem',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              borderTop: '1px solid #D4D8E0',
            }}
          />
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '2rem',
              height: '2rem',
              backgroundColor: 'white',
              border: '1px solid #D4D8E0',
              borderRadius: '100%',
            }}
          >
            <ArrowForwardIcon fontSize="small" sx={{ color: '#747B89' }} />
          </Box>
        </Box>
        <Box
          src={`/assets/icons/assets/native/${deploymentDetails.network}.svg`}
          component="img"
          width={48}
          height={48}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '2rem',
          gap: '1.5rem 4rem',
          backgroundColor: 'white',
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
          border: '1px solid #EDEFF4',
          borderTop: 0,
        }}
      >
        {[
          {
            label: 'Token',
            value:
              `${tokenDetails.symbol} - ${tokenDetails.name}` +
              (tokenDetails.isin && ` (${tokenDetails.isin})`),
          },
          { label: 'Description', value: tokenDetails.description },
          ...(underlyingDetails.publiclyListed
            ? [
                {
                  label: 'Underlying',
                  value: `${underlyingDetails.symbol} - ${underlyingDetails.name} (${underlyingDetails.isin})`,
                },
              ]
            : []),
          ...(underlyingDetails.startingNav
            ? [
                {
                  label: 'Starting NAV',
                  value: `${underlyingDetails.startingNav} $`,
                },
              ]
            : []),
          {
            label: 'Network',
            value: availableNetworks.find(
              ({ network }) => network === deploymentDetails.network
            )!.name,
          },
        ].map((item, index) => (
          <Box key={item.label} sx={{ ...(index < 2 && { width: '100%' }) }}>
            <Typography
              sx={{
                fontSize: 14,
                color: '#747B89',
                marginBottom: '0.125rem',
              }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CreateTokenFormSummary;
