import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  useOrderDvpFormQuery
} from '@backed-fi/graphql';

// region Props

interface Props {
  orderId: string;

  onDismiss: (shouldRefresh?: boolean) => void;
}

// endregion

// region Graph Declarations

gql`
  query OrderDvpForm($orderId: ID!) {
    order(id: $orderId) {
      id
      settlement {
        id
        endOfDayFormDownloadUrl
      }
    }
  }
`;

// endregion

export const DownloadDvpForm: React.FC<Props> = ({
  orderId,
  onDismiss
}) => {
  // region Networking
  const {
    data
  } = useOrderDvpFormQuery({
    variables: {
      orderId
    },
    pollInterval: 2000
  });
  // endregion

  useEffect(() => {
    if (data?.order?.settlement?.endOfDayFormDownloadUrl) {
      window.open(data?.order?.settlement?.endOfDayFormDownloadUrl!, '_blank');
      onDismiss(false);
    }
  }, [data, onDismiss]);

  return (
    <Box
      sx={{
        display: 'flex',
        paddingBottom: '1rem',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  );
};
