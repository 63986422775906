import React from 'react';
import { Route } from 'react-router-dom';

import { FlowExecutionDetailsOverview } from './pages/Details/OverviewPage';
import { FlowExecutionsListPage } from './pages/FlowsExecutionsListPage';

export const FlowPages = (
  <React.Fragment>
    <Route path="all"
      element={
        <FlowExecutionsListPage />
      }>
    </Route>
    <Route
      path="details/:flowExecutionId"
      element={
        <FlowExecutionDetailsOverview />
      }
    />
  </React.Fragment>
);
