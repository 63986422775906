import { z } from 'zod';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Dialog, DialogContent, TextField, TextFieldProps, Typography } from '@mui/material';

import { FeesConfigurationQuery, FeeTier, TokenDeploymentFeesQuery, useTokenDeploymentFeesQuery, useUpsertFeeConfigurationMutation } from '@backed-fi/graphql';

// region Graph

const Graph = gql`
  query tokenDeploymentFees($deploymentId: String!) {
    tokenDeployment(tokenDeploymentId: $deploymentId) {
      id
      network

      token {
        name
      }

      feeConfigurations {
        id

        tier
        notes
        
        issuanceInitialFee
        issuanceMinimumFee
        issuancePercentageFee

        redemptionInitialFee
        redemptionMinimumFee
        redemptionPercentageFee
      }
    }
  }

  mutation upsertFeeConfiguration($input: UpsertFeeConfigurationInput!) {
    upsertFeeConfiguration(input: $input) {
      id
    }
  }
`;

// endregion

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  deployment: FeesConfigurationQuery['tokens'][0]['deployments'][0];

  onFeeConfigured?: () => any;
}

// endregion

// region Form Schema

const Schema = z.object({
  issuanceInitialFee: z.number()
    .nonnegative()
    .int(),

  issuanceMinimumFee: z.number()
    .nonnegative()
    .int(),

  issuancePercentageFee: z.number()
    .nonnegative(),

  redemptionInitialFee: z.number()
    .nonnegative()
    .int(),

  redemptionMinimumFee: z.number()
    .nonnegative()
    .int(),

  redemptionPercentageFee: z.number()
    .nonnegative()
});

// endregion

const HelperTexts: Record<keyof typeof Schema.shape, string> = {
  issuanceInitialFee: 'The default is $0.00. It represents the upfront fee for every issuance',
  issuanceMinimumFee: 'The default is $0.00. It represents the minimum amount that will be charged for one issuance',
  issuancePercentageFee: 'The default is 0.5%. It represents the percentage charged of the total issuance amount',
  redemptionInitialFee: 'The default is $0.00. It represents the upfront fee for every redemption',
  redemptionMinimumFee: 'The default is $0.00. It represents the minimum amount that will be charged for one redemption',
  redemptionPercentageFee: 'The default is 0.5%. It represents the percentage charged of the total redemption amount'
};


export const FeeConfigureDialog: React.FC<Props> = ({
  deployment,
  onFeeConfigured,
  ...props
}) => {
  const snackbar = useSnackbar();

  // region State

  const [tier, setTier] = React.useState<FeeTier>();
  const [isNewTier, setIsNewTier] = React.useState(false);

  // endregion

  // region Networking

  const query = useTokenDeploymentFeesQuery({
    variables: {
      deploymentId: deployment.id
    }
  });

  const [upsertFeeConfigMutation] = useUpsertFeeConfigurationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'feesConfiguration'
    ]
  });

  const { tokenDeployment } = (query.data || {}) as TokenDeploymentFeesQuery;
  const { loading } = query;

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const { isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onFeeTierChange: TextFieldProps['onChange'] = (event) => {
    const selectedTier = event.target.value as FeeTier;
    const existingFeeTier = deployment.feeConfigurations.find((x) => x.tier === selectedTier);

    setTier(selectedTier);
    setIsNewTier(!existingFeeTier);

    if (existingFeeTier) {
      Object.keys(Schema.shape)
        .map((key: any) => {
          const value = (existingFeeTier as any)[key];

          form.setValue(key, key.includes('Percentage') ? value * 100 : value / 100);
        });
    } else {
      form.reset();
    }
  };

  const onUpsert = form.handleSubmit(async (data) => {
    await upsertFeeConfigMutation({
      variables: {
        input: {
          tier: tier!,
          tokenDeploymentId: tokenDeployment!.id,

          issuancePercentageFee: data.issuancePercentageFee / 100,
          issuanceMinimumFee: data.issuanceMinimumFee * 100,
          issuanceInitialFee: data.issuanceInitialFee * 100,

          redemptionPercentageFee: data.redemptionPercentageFee / 100,
          redemptionMinimumFee: data.redemptionMinimumFee * 100,
          redemptionInitialFee: data.redemptionInitialFee * 100
        }
      }
    });

    snackbar.enqueueSnackbar(`${tier} fee tier successfully configured`, {
      variant: 'success'
    });

    if (typeof onFeeConfigured === 'function') {
      await onFeeConfigured();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        {!tokenDeployment && (
          <Typography>
            Loading
          </Typography>
        )}

        {tokenDeployment && (
          <React.Fragment>
            <Box mb={3}>
              <Typography variant='titleSmall'>
                Configure fees
              </Typography>

              <Typography variant='subtitleSmall'>
                Configure the transaction fees for {tokenDeployment.token.name} on {tokenDeployment.network}
              </Typography>
            </Box>

            <TextField
              select
              fullWidth
              label='Fee Tier'
              value={tier}
              onChange={onFeeTierChange}
              SelectProps={{
                native: true
              }}
            >
              <option selected disabled>
                Select Fee Tier
              </option>

              {Object.keys(FeeTier).map((tier) => (
                <option value={tier} key={tier}>
                  {tier}
                </option>
              ))}
            </TextField>

            {!!tier && (
              <React.Fragment>
                <Typography
                  sx={{
                    mt: '1rem',
                    mb: '.5rem'
                  }}
                >
                  Fee Configuration
                </Typography>

                {Object.keys(Schema.shape)
                  .map((key: string) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      key={key}
                      sx={{
                        my: 1.5,
                        '& > .MuiFormLabel-root': {
                          textTransform: 'capitalize'
                        }
                      }}
                      helperText={HelperTexts[key as keyof typeof Schema.shape]}
                      label={key.replace(/(?=[A-Z])/g, ' ')}
                      {
                      ...form.register(key as any, {
                        valueAsNumber: true
                      })
                      }
                    />
                  )
                  )}


                {isNewTier && (
                  <Alert
                    severity='warning'
                    sx={{
                      my: '.5rem'
                    }}
                  >
                    You are creating new fee tier
                  </Alert>
                )}

                <LoadingButton
                  onClick={onUpsert}
                  loading={isSubmitting}
                  sx={{ float: 'right' }}
                >
                  {isNewTier ? 'Create' : 'Update'} Fee Tier
                </LoadingButton>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};
