import React from 'react';
import { gql } from '@apollo/client';

import { AdminPermission, useUpdateServiceAccountMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { PermissionsChip } from '@backed-fi/compound';

gql`
  mutation UpdateServiceAccount($input: UpdateServiceAccountInput!) {
    updateServiceAccount(input: $input) {
      id
    }
  }
`;

interface Props {
  id: string,
  name: string,

  permissions: AdminPermission[]
}

export const ApiPermissions: React.FC<Props> = ({ id, name, permissions }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [updateServiceAccount, { loading }] = useUpdateServiceAccountMutation();

  // endregion


  const onUpdate = async (permissions: AdminPermission[]) => {
    try {
      await updateServiceAccount({
        awaitRefetchQueries: true,
        refetchQueries: [
          'apiAcceses'
        ],
        variables: {
          input: {
            serviceAccountId: id,
            update: {
              permissions
            }
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully updated service account permissions', { variant: 'success' });

      return true;
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to update service account permissions', { variant: 'error' });
    }

    return false;
  };

  return (
    <>
      <PermissionsChip
        identifier={name}
        assignedPermissions={permissions}
        isLoading={loading}
        onUpdate={onUpdate}
      />
    </>
  );
};
