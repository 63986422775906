import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { config } from '@backed-fi/config';
import { SharedPages } from '@backed-fi/shared';

import { TestPage } from './Test';
import { AssessmentsListPage } from './domain/Compliance/Assessments/AssessmentsListPage';
import { FileRiskAssessmentPage } from './domain/Compliance/Assessments/FileRiskAssessmentPage';
import { AdminLayout } from './layouts/AdminLayout';
import { HomePage } from './domain/Home/HomePage';

import { SystemWalletsListPage } from './domain/Internal/SystemWallets/SystemWalletsListPage';
import { VerificationProfileDetailsPage } from './domain/Compliance/VerificationProfiles/VerificationProfileDetailsPage';
import { FactoriesListPage } from './domain/Internal/Factories/FactoriesListPage';
import { RiskAssessmentDetailsPage } from './domain/Compliance/Assessments/RiskAssessmentDetailsPage';

import { UserPages } from './domain/User';
import { ClientPages } from './domain/Clients';
import { UtilsRoutes } from './domain/Utils/UtilsRoutes';
import { InteractionRoutes } from './domain/Interactions/routes';
import { BrokeragePages } from './domain/Brokerage/pages/Brokerage/BrokeragePages';
import { DocumentRoutes } from './domain/Compliance/Documents/Routes';
import { FinancialsRoutes } from './domain/Brokerage/FinancialsRoutes';
import { BlockchainRoutes } from './domain/Blockchain/BlockchainRoutes';
import { VerificationPages } from './domain/Compliance/Verifications/pages';
import { ComplianceReviewsRoutes } from './domain/Compliance/Reviews/Routes';
import { InvitationPages } from './domain/Invitations/InvitationPages';
import { BankingPages } from './domain/Banking/pages';
import { InternalPages } from '@backed-fi/admin/src/app/domain/Internal/InternalPages';
import { CustodyRoutes } from './domain/FinOps';
import { ExperimentalSettings } from '@backed-fi/admin/src/app/domain/Experimental/ExperimentalSettings';
import { Shortcuts } from '@backed-fi/admin/src/app/components/Shortcuts';
import { CompanyPages } from './domain/Company';
import { FlowPages } from './domain/Flows/FlowPages';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, CompanyFeature } from '@backed-fi/graphql';

export const AdminPages = (
  <Route
    element={<Shortcuts />}
  >

    {SharedPages}

    {/* // ---- Dashboard domain ---- // */}
    <Route element={<AdminLayout />}>
      <Route path='companies'
        element={
          <Protector redirect permissions={[AdminPermission.CompaniesManage]}>
            <Outlet />
          </Protector>
        }>
        {CompanyPages}
      </Route>
      <Route path="utils" element={
        <Protector redirect companyFeature={CompanyFeature.Utils}>
          <Outlet />
        </Protector>
      }>
        {UtilsRoutes}
      </Route>
      <Route path="clients" element={
        <Protector redirect companyFeature={CompanyFeature.Clients}>
          <Outlet />
        </Protector>
      }>
        {UserPages}
        {ClientPages}
        {InvitationPages}
      </Route>

      <Route path="banking" element={
        <Protector redirect companyFeature={CompanyFeature.InteractionsBankTransfers}>
          <Outlet />
        </Protector>
      }>
        {BankingPages}
      </Route>


      {InternalPages}
      {BlockchainRoutes}

      <Route path="financials" element={
        <Protector redirect companyFeature={CompanyFeature.Brokerage}>
          <Outlet />
        </Protector>
      }>
        {FinancialsRoutes}
        {BrokeragePages}

      </Route>

      <Route path="finops" element={
        <Protector redirect companyFeature={CompanyFeature.Brokerage}>
          <Outlet />
        </Protector>
      }>
        {CustodyRoutes}
      </Route>

      <Route path="interactions" element={
        <Protector redirect companyFeature={CompanyFeature.Interactions}>
          <Outlet />
        </Protector>
      }>
        {InteractionRoutes}
      </Route>

      <Route path='activities'
        element={
          <Protector redirect companyFeature={CompanyFeature.Flows}>
            <Outlet />
          </Protector>
        }>
        {FlowPages}
      </Route>

      <Route
        path="/"
        element={
          <HomePage />
        }
      />

      <Route
        path="/experimental"
        element={
          <ExperimentalSettings />
        }
      />


      <Route path='compliance'
        element={
          <Protector redirect companyFeature={CompanyFeature.Compliance}>
            <Outlet />
          </Protector>
        }>

        <Route path="risk">
          <Route
            path="assessments"
            element={
              <AssessmentsListPage />
            }
          />

          <Route path="assessment">
            <Route
              path="file/:assessmentId"
              element={
                <FileRiskAssessmentPage />
              }
            />

            <Route
              path="details/:id"
              element={
                <RiskAssessmentDetailsPage />
              }
            />
          </Route>
        </Route>
        {DocumentRoutes}
        {ComplianceReviewsRoutes}
        {/* // ---- Verification domain ---- // */}
        {VerificationPages}
        <Route
          path="profiles"
        >
          <Route
            path="all"
          />
        </Route>
        <Route
          path="profile"
        >
          <Route
            path="details/:id"
            element={<VerificationProfileDetailsPage />}
          />
        </Route>
      </Route>
    </Route>

    {
      (config.isDev) && (
        <Route
          path="/test"
          element={<TestPage />}
        />
      )
    }

    <Route
      path="*"
      element={
        <Navigate
          to="/"
        />
      }
    />
  </Route >
);
