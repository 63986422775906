import React from 'react';
import { Typography } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';

import { AdminPermission, ClientFeature, CompanyFeature } from '@backed-fi/graphql';
import { useAuthContext, useClientContext, useUserContext } from '@backed-fi/context';
import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';
import { useCompanyContext } from '../providers/context/CompanyContext';

interface ProtectorProps {

  redirect?: boolean;

  /**
   * Only valid for permission checks. If set to true,
   * it will only not render children, without showing
   * any forbidden messages
   */
  hide?: boolean;

  feature?: ClientFeature;
  companyFeature?: CompanyFeature;
  permissions?: AdminPermission[];
}

export const Protector: React.FC<React.PropsWithChildren<ProtectorProps>> = ({ children, ...props }) => {
  const location = useLocation();
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const adminContext = useAdminContext();
  const clientContext = useClientContext();
  const companyContext = useCompanyContext();

  // region Actions

  const onForbidden = () => {
    if (props.redirect) {
      return (
        <Navigate
          replace
          to='/forbidden'
        />
      );
    }

    return (
      <Typography>
        Forbidden
      </Typography>
    );
  };

  // endregion

  // ---- Redirect to auth page if the user is not authenticate ---- //
  if (!authContext.authenticated) {
    const pathname = '/authenticate';

    if (location.pathname !== pathname) {
      return (
        <Navigate
          replace
          to='/authenticate'
          state={{
            from: location.pathname
          }}
        />
      );
    }
  }

  // Check if the data has been fetched
  if ((!userContext?.loaded && !adminContext?.loaded) || companyContext.loading) {
    return null;
  }

  if (props.permissions) {
    if (!props.permissions.some(permission => adminContext.permissions?.includes(permission))) {
      return props.hide
        ? null
        : onForbidden();
    }
  }

  if (props.companyFeature) {
    if (!companyContext.features?.includes(props.companyFeature)) {
      return props.hide
        ? null
        : onForbidden();
    }
  }

  if (props.feature) {
    if (!clientContext.features?.includes(props.feature) && !clientContext.loading) {
      if (props.feature === ClientFeature.PlatformAccess) {
        if (!location.pathname.includes('onboarding')) {
          return (
            <Navigate
              replace
              to={'/onboarding'}
            />
          );
        }
      }

      return null;
    }
  }

  return <>{children}</>;
};
