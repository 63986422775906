import React from 'react';

import { gql } from '@apollo/client';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Button, TextField } from '@mui/material';

import { FormGroup } from '@backed-fi/compound';
import { TokenSettingsQuery, usePublishTokenMutation, useTokenSettingsQuery } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

const GraphQl = gql`
  query tokenSettings($id: ID!) {
    token(id: $id) {
      name
      description

      deployments {
        id
        network
        deploymentStatus
      }
    }
  }

  mutation publishToken($tokenId: ID!) {
    publishToken(tokenId: $tokenId) {
      id
    }
  }
`;

const BasicSettingsSchema = z.object({
  name: z.string()
    .nonempty(),

  description: z.string()
    .nonempty()
});


export const TokenSettingsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  // ---- State ---- //
  const [publishing, setPublishing] = React.useState<boolean>(false);

  // ---- Networking ---- //
  const [publishToken] = usePublishTokenMutation();

  const { data, refetch } = useTokenSettingsQuery({
    variables: {
      id: params.id!
    }
  });

  // ---- Form Control ---- //
  const basicForm = useForm<z.infer<typeof BasicSettingsSchema>>({
    resolver: zodResolver(BasicSettingsSchema),
    defaultValues: {
      name: ' ',
      description: ' '
    }
  });


  // ---- Destructuring ---- //
  const { token } = (data || {}) as TokenSettingsQuery;

  // ---- Effects ---- //
  React.useEffect(() => {
    // If the user has not touched the form and token data is present set that data in the form
    if (token && !basicForm.formState.isDirty) {
      basicForm.setValue('name', token.name);
      basicForm.setValue('description', token.description!);
    }
  }, [token]);


  return (
    <React.Fragment>
      {/* // ---- Page content ---- // */}
      <Box>
        <FormGroup
          title="Basic Settings"
        >
          <TextField
            label="Name"
            {...basicForm.register('name')}
          />

          <TextField
            multiline
            minRows={5}
            label="Description"
            {...basicForm.register('description')}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '.5rem'
            }}
          >
            <Button>
              Discard
            </Button>

            <Button>
              Save
            </Button>
          </Box>
        </FormGroup>

        {/* <FormGroup
          title="Publishing Information"
        >
          <TextField
            label="Privacy Status"
            select
            disabled
            helperText="At the moment the only way to publish token is to manually do so in the database"
          />
        </FormGroup> */}
      </Box>
    </React.Fragment>
  );
};
