import React from 'react';
import { Route } from 'react-router-dom';

import { ClientsLayout } from './ClientsLayout';

import { ClientBankAccountsAndWalletsPage } from './pages/ClientBankAccountsAndWalletsPage';
import { ClientTimelinePage } from './pages/ClientTimelinePage';
import { ClientUsageAndLimitsPage } from './pages/ClientUsageAndLimitsPage';
import { ClientsListPage } from './pages/ClientsListPage';
import { ClientFeaturesPage } from './pages/ClientFeaturesPage';
import { ClientDocumentsPage } from './pages/ClientDocumentsPage';
import { ClientFilesPage } from '@backed-fi/admin/src/app/domain/Clients/pages/ClientFilesPage';
import { ClientUsersPage } from '@backed-fi/admin/src/app/domain/Clients/pages/ClientUsersPage';
import { ClientFeesPage } from '@backed-fi/admin/src/app/domain/Clients/pages/Fees/ClientFeesPage';
import { CreateClientPage } from '@backed-fi/admin/src/app/domain/Clients/pages/Create/CreateClientPage';
import { ClientSweepingWalletsPage } from '@backed-fi/admin/src/app/domain/Clients/pages/ClientSweepingWalletsPage';
import { ClientStatisticsPage } from './pages/ClientStatisticsPage';
import { ClientInteractionsPage } from '@backed-fi/admin/src/app/domain/Clients/pages/ClientInteractionsPage';

const ClientOverviewPage = React.lazy(() => import('./pages/ClientOverviewPage'));
const ClientsCompliancePage = React.lazy(() => import('./pages/Compliance/ClientsCompliancePage'));

export const ClientPages = (
  <>
    <Route
      path='create'
      element={
        <CreateClientPage />
      }
    />

    <Route
      path=':id/details'
      element={
        <ClientsLayout />
      }
    >
      <Route
        path='overview'
        element={<ClientOverviewPage />}
      />

      <Route
        path='users'
        element={<ClientUsersPage />}
      />

      <Route
        path='statistics'
        element={<ClientStatisticsPage />}
      />

      <Route
        path='documents'
        element={<ClientDocumentsPage />}
      />

      <Route
        path='interactions'
        element={<ClientInteractionsPage />}
      />

      <Route
        path='external'
        element={<ClientBankAccountsAndWalletsPage />}
      />

      <Route
        path='timeline'
        element={<ClientTimelinePage />}
      />

      <Route
        path='usage'
        element={<ClientUsageAndLimitsPage />}
      />

      <Route
        path='fees'
        element={<ClientFeesPage />}
      />

      <Route
        path='sweeping-wallets'
        element={<ClientSweepingWalletsPage />}
      />

      <Route
        path='features'
        element={<ClientFeaturesPage />}
      />

      <Route
        path='compliance'
        element={<ClientsCompliancePage />}
      />


      <Route
        path='files'
        element={<ClientFilesPage />}
      />
    </Route>

    <Route
      path='list/:filter'
      element={
        <ClientsListPage />
      }
    />
  </>
);
