import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Chip, LinearProgress, Tooltip, Typography } from '@mui/material';

import { ExportButton } from '@backed-fi/admin/src/app/components/ExportButton';

import { usePaginationModel, useToggle } from '@backed-fi/hooks';
import { useAdminsQuery } from '@backed-fi/graphql';
import { Filters, filterToWhereQuery, FilterType } from '@backed-fi/admin/src/app/components/filter/Filters';
import { AddAdminDialog } from '../../Internal/Admins/components/AddAdminDialog';
import { DeleteAdminDialog } from '../../Internal/Admins/components/DeleteAdminDialog';

gql`
  query admins(
    $page: Int,
    $pageSize: Int,
    $where: AdminsWhereInput
  ) {
    admins(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id

        createdAt
        updatedAt

        firstName
        lastName

        email
        permissions
      }

      page {
        currentPage
        totalNodes
        totalPages
      }
    }
  }
`;

// region Props

interface Props {
  title?: string,
  hideFilters?: boolean,
  companyId: string
}

// endregion

export const AdminsTable: React.FC<Props> = ({
  title,
  hideFilters,
  companyId
}) => {
  const addDialogToggle = useToggle();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region State

  const [filters, setFilters] = useState<Record<string, any>>({});
  const queryFilters = useMemo(() => {
    return {
      ...filters
    };
  }, [filters]);

  // endregion

  // region Networking

  const query = useAdminsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,
      where: {
        ...queryFilters,
        ...(companyId && {
          companyId: companyId
        } as any)
      }
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  // region Effects

  React.useEffect(() => {
    (async () => {
      if (!pagination.isDisabled) {
        await query.refetch(paginationVariables);
      }
    })();
  }, [paginationVariables]);

  // endregion

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        flexDirection: 'column'
      }}
    >

      <AddAdminDialog {...addDialogToggle} id={companyId} />
      {!hideFilters && (
        <Box
          mb={1}
          gap={1}
          display="flex"
        >
          {/* region Filters */}

          <Filters
            onFiltersChanged={(filters) => setFilters(filterToWhereQuery(filters))}
            filters={[
              FilterType.IncomingTransactionStatus,
              FilterType.IncomingTransactionType,
              FilterType.ClientType,
              FilterType.ClientName,
              FilterType.Network,
              FilterType.TokenSymbol
            ]}
          />

          {/* endregion */}

          {/* region Export */}

          <ExportButton
            csv
            json
            exportName="admins"
            data={data?.admins!.nodes ?? []}
          >
            Export Current Page
          </ExportButton>

          {/* endregion */}
        </Box>
      )}

      <Box>
        <Box
          sx={{
            my: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: title ? 'space-between' : 'flex-end'
          }}
        >
          {title && <Typography variant='titleSmall'>{title}</Typography>}
          <Button onClick={addDialogToggle.setTrue}>
            Add Admin
          </Button>
        </Box>
      </Box>

      <DataGrid
        {...pagination}
        autoHeight
        slots={{
          loadingOverlay: LinearProgress
        }}
        loading={loading}
        rows={data?.admins?.nodes || []}
        rowCount={data?.admins!.page.totalNodes}
        columns={[{
          flex: 1,
          minWidth: 200,
          field: 'name',
          headerName: 'Name',
          renderCell: ({ row }) => (
            <Typography>
              {row.firstName} {row.lastName}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 200,
          field: 'email',
          headerName: 'Email',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 300,
          field: 'permissions',
          headerName: 'Permissions',
          renderCell: ({ value }) => (
            <Tooltip title={value.join(', ')}>
              <Chip
                label={`${value.length} permission${value.length !== 1 ? 's' : ''}`}
              />
            </Tooltip>
          )
        },
        {
          flex: 1,
          minWidth: 300,
          field: "id",
          headerName: "",
          renderCell: ({ row }) => (
            <DeleteAdminDialog admin={row} />
          ),
        },
        ]}
      />
    </Box>
  );
};
