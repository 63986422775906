export default [
  // '#3fb1e3',
  // '#6be6c1',
  // '#626c91',
  // '#a0a7e6',
  // '#c4ebad',
  // '#96dee8'

  // '#003D99',
  // '#0066FF',
  // '#5297FF',
  // '#A3C8FF'

  '#0066ff',
  '#00d3ff',
  '#00ffbd',
  '#8aff00',
  '#e200ff',
  '#7500ff'
];
