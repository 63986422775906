import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  ContentTile,
  InfoLabel,
  PageHeading,
  Protector,
} from '@backed-fi/compound';
import { AdminPermission, useTokenDetailQuery } from '@backed-fi/graphql';
import { CentsFormatter, CryptoFormatter } from '@backed-fi/shared';

import MenuIcon from '@mui/icons-material/Menu';
import FlareIcon from '@mui/icons-material/Flare';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { MintFlowDialog } from '@backed-fi/admin/src/app/domain/Flows/components/mint/MintFlowDialog';
import { useToggle } from '@backed-fi/hooks';
import { BurnFlowDialog } from '@backed-fi/admin/src/app/domain/Flows/components/burn/BurnFlowDialog';

gql`
  query tokenDetail($tokenId: ID!) {
    token(id: $tokenId) {
      name
      symbol
      description
      icon
      isin

      collateral {
        ISINNumber
        ISINName
        symbol
        lastKnownPrice
        publiclyListed
      }

      deployments {
        network
        address
        circulatingSupply
        totalSupply
      }
    }
  }
`;

export const TokenDetailsOverviewPage: React.FC = () => {
  const mintToggle = useToggle();
  const burnToggle = useToggle();
  const params = useParams<{ id: string }>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [totalSupply, setTotalSupply] = useState(new Decimal(0));
  const [circulatingSupply, setCirculatingSupply] = useState(new Decimal(0));

  const onMint = () => {
    mintToggle.setOpen(true);

    handleClose();
  };
  const onBurn = () => {
    burnToggle.setOpen(true);

    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ---- Networking ---- //
  const { data } = useTokenDetailQuery({
    variables: {
      tokenId: params.id!,
    },
    pollInterval: 10_000,
  });

  // ---- Destructor ---- //
  const { token } = data || {};

  React.useEffect(() => {
    if (token) {
      setTotalSupply(
        token.deployments.reduce(
          (acc, val) => acc.add(val.totalSupply),
          new Decimal(0)
        )
      );
      setCirculatingSupply(
        token.deployments.reduce(
          (acc, val) => acc.add(val.circulatingSupply),
          new Decimal(0)
        )
      );
    }
  }, [token]);

  return (
    <>
      {mintToggle.open && (
        <MintFlowDialog tokenId={params.id!} toggle={mintToggle} />
      )}
      {burnToggle.open && (
        <BurnFlowDialog tokenId={params.id!} toggle={burnToggle} />
      )}

      <PageHeading title={`${token?.name}`} loading={!token}>
        <Protector permissions={[AdminPermission.FlowsManage]} hide>
          <Button onClick={handleClick}>
            Actions
            <MenuIcon sx={{ paddingLeft: '8px' }}></MenuIcon>
          </Button>
        </Protector>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={onMint}>
            <FlareIcon sx={{ paddingRight: '8px' }}></FlareIcon>
            Mint
          </MenuItem>
          <MenuItem onClick={onBurn}>
            <LocalFireDepartmentIcon
              sx={{ paddingRight: '8px' }}
            ></LocalFireDepartmentIcon>
            Burn
          </MenuItem>
        </Menu>
      </PageHeading>
      <Box
        sx={{
          height: 'calc(100vh - 4rem)',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: '1rem',
            gridTemplateColumns: {
              lg: '1fr',
              xl: '1fr 1fr',
            },
          }}
        >
          {token &&
            [
              <Box key={1}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle">Token</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                    flex={1}
                  >
                    <Box sx={{ display: 'flex' }} flex={1}>
                      <Avatar
                        sx={{
                          width: '120px',
                          height: '120px',
                        }}
                        alt={token.symbol}
                        src={
                          token.icon ??
                          `/assets/icons/assets/${token.symbol}.svg`
                        }
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',

                          paddingLeft: '32px',
                        }}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'end',
                          }}
                          flex={1}
                          variant="subtitle"
                        >
                          {token.symbol}
                        </Typography>
                        <Typography
                          sx={{ display: 'flex' }}
                          flex={1}
                          variant="subtitleSmall"
                        >
                          {token.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      flex={1}
                    >
                      <InfoLabel label="ISIN" content={token.isin}></InfoLabel>
                      <InfoLabel
                        label="Circulating supply"
                        content={CryptoFormatter.format(
                          circulatingSupply.toString(),
                          18,
                          token.symbol
                        )}
                      ></InfoLabel>
                      <InfoLabel
                        label="Total supply"
                        content={CryptoFormatter.format(
                          totalSupply.toString(),
                          18,
                          token.symbol
                        )}
                      ></InfoLabel>
                    </Box>
                  </Box>
                </Box>
              </Box>,
              token.collateral.publiclyListed ? (
                <Box key={2}>
                  <Typography variant="subtitle">Collateral</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InfoLabel
                      label="Name"
                      content={token.collateral.ISINName}
                    ></InfoLabel>
                    <InfoLabel
                      label="ISIN"
                      content={token.collateral.ISINNumber}
                    ></InfoLabel>
                    <InfoLabel
                      label="Symbol"
                      content={token.collateral.symbol}
                    ></InfoLabel>
                  </Box>
                </Box>
              ) : token.collateral.lastKnownPrice ? (
                <Box key={2}>
                  <Typography variant="subtitle">Pricing</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InfoLabel
                      label="Price (NAV) of the token"
                      content={CentsFormatter.format(
                        token.collateral.lastKnownPrice!
                      )}
                    ></InfoLabel>
                  </Box>
                </Box>
              ) : undefined,
            ]
              .filter((x) => !!x)
              .map((card, index) => <ContentTile>{card}</ContentTile>)}
          {!token &&
            [1, 2].map((i) => (
              <ContentTile>
                <Skeleton />
              </ContentTile>
            ))}
        </Box>

        {token && (
          <>
            <Typography sx={{ margin: '2rem 0' }} variant="title">
              Deployments
            </Typography>

            <Box
              sx={{
                display: 'grid',
                gap: '1rem',
                gridTemplateColumns: {
                  xs: '1fr',
                  md: '1fr 1fr',
                  lg: '1fr 1fr 1fr',
                  xl: '1fr 1fr 1fr 1fr',
                },
              }}
            >
              {token.deployments.map((d, index) => (
                <ContentTile>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        marginBottom: '16px',
                      }}
                    >
                      <Avatar
                        sx={{
                          width: '60px',
                          height: '60px',
                        }}
                        alt={d.network}
                        src={`/assets/icons/assets/native/${d.network}.svg`}
                      />
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',

                          paddingLeft: '16px',
                        }}
                        variant="subtitle"
                      >
                        {d.network}
                      </Typography>
                    </Box>
                    <Box>
                      <InfoLabel
                        label="Address"
                        content={d.address}
                        copy
                      ></InfoLabel>
                      <InfoLabel
                        label="Circulating supply"
                        content={CryptoFormatter.format(
                          d.circulatingSupply,
                          18,
                          token.symbol
                        )}
                      ></InfoLabel>
                      <InfoLabel
                        label="Total supply"
                        content={CryptoFormatter.format(
                          d.totalSupply,
                          18,
                          token.symbol
                        )}
                      ></InfoLabel>
                    </Box>
                  </Box>
                </ContentTile>
              ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
