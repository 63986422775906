import { AdminPermission } from "@backed-fi/graphql";

export const AdminPermissionDescription: { [key in AdminPermission]: string } = {
  Admin_Access: 'Admin application access',
  Operational_Approval: 'AML approval - low risk level',
  AML_Approval: 'AML approval - medium risk level',
  Director_Approval: 'AML Director approval - high risk level',
  Blockchain_Manage: 'Blockchain manager',
  Blockchain_Read: 'Blockchain access',
  Client_Manage: 'Client manager',
  Client_Read: 'Client access',
  Companies_Manage: 'Company manager',
  Compliance_Manage: 'Compliance manager',
  Compliance_Read: 'Compliance access',
  Compliance_ReadSensitive: 'Compliance sensitive access',
  Developer_Manage: 'Advanced utils access',
  Financials_Manage: 'Financials manager',
  Financials_Read: 'Financials access',
  Financials_Executor: 'Financials executor',
  Flows_Manage: 'Flows executor',
  Flows_Read: 'Flows access',
  Interaction_Manage: 'Interaction manager',
  Interaction_Read: 'Interaction access',
  Product_Manage: 'Product manager',
  Product_Read: 'Product access',
  System_Manage: 'System manager',
  System_Read: 'System details access',
  System_Permissions_Manage: 'System permission manager'
}