import React from 'react';
import { Box, Typography } from '@mui/material';

interface InternalProps {
  title: string;
  Icon: React.FC;
  onClick: () => void
}

export const ActionTile: React.FC<InternalProps> = ({
  Icon,
  title,
  onClick
}) => {
  // ---- Actions ---- //
  const onMainClick = () => {
    onClick();
  };

  return (
    <Box
      sx={({ palette }) => {
        const { primary } = palette;

        return {
          display: 'flex',
          flexFlow: 'column',
          userSelect: 'none',
          marginTop: '.25rem',
          borderRadius: '0.5rem',

          border: '1px solid transparent',

          color: '#fff',

          '&:hover': {
            borderColor: '#252931',
            backgroundColor: '#252931'
          }
        };
      }}
    >
      <Box
        onClick={onMainClick}
        display="flex"
        sx={{
          cursor: 'pointer',
          padding: '.5rem'
        }}
      >
        <Icon />

        <Typography
          sx={{
            marginLeft: '.5rem'
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
