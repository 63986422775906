import React from 'react';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { AdminPermission, useDeleteRoleMutation, useDisableAdminMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

import ClearIcon from '@mui/icons-material/Clear';
import { Protector, Title } from '@backed-fi/compound';

gql`
  mutation DisableAdmin($input: DisableAdminInput!) {
    disableAdmin(input: $input) {
      id
    }
  }
`;

interface Props {
  admin: {
    id: string,
    email: string,
  }
}

export const DeleteAdminDialog: React.FC<Props> = ({ admin }) => {
  const deleteAdminDialogToggle = useToggle();
  const snackbar = useSnackbar();

  // region Networking

  const [disableAdmin, { loading }] = useDisableAdminMutation();

  // endregion

  const onDelete = async () => {
    try {
      await disableAdmin({
        awaitRefetchQueries: true,
        refetchQueries: [
          'admins',
        ],
        variables: {
          input: {
            adminId: admin.id
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully deleted admin', { variant: 'success' });

      deleteAdminDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to delete admin', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Protector permissions={[AdminPermission.SystemManage]} hide>
          <Tooltip title="Remove admin">
            <IconButton
              onClick={() => {
                deleteAdminDialogToggle.toggle();
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Protector>

      </Box>
      {deleteAdminDialogToggle.open && (
        <Dialog
          {...deleteAdminDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Box mb={1}>
              <Typography variant='subtitle'>
                Are you sure you want to delete {admin.email}?
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Box mr={1}>
                <Button
                  onClick={deleteAdminDialogToggle.onClose}
                >
                  Cancel
                </Button>
              </Box>
              <LoadingButton
                loading={loading}
                onClick={onDelete}
              >
                Delete
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
