import React from "react";

import { Box, Typography } from "@mui/material";

const CreateTokenHeader: React.FC = () => (
  <Box sx={{ padding: "2rem" }}>
    <Typography fontSize={28} fontWeight={600}>
      Create Token
    </Typography>
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        color: "#747B89",
      }}
    >
      <Typography fontSize={14}>Products</Typography>
      <Typography fontSize={14}>/</Typography>
      <Typography fontSize={14} sx={{ color: "#0066FF" }}>
        Create
      </Typography>
    </Box>
  </Box>
);

export default CreateTokenHeader;
