import React from 'react';
import { Box, TextField } from '@mui/material';
import { EnumSelect, FormGroup, PageHeading } from '@backed-fi/compound';
import { z } from 'zod';
import { ClientClassification, ClientType, CreateClientResultEnum, useInjectVerificationMutation, useManuallyCreateClientMutation } from '@backed-fi/graphql';
import { FieldError, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// region Schemas

const CreateClientSchema = z.object({
  client: z.object({
    type: z.nativeEnum(ClientType),
    classification: z.nativeEnum(ClientClassification),

    email: z.string()
      .email()
  }),

  verification: z.object({
    vendorApplicantId: z.string(),
    vendorVerificationId: z.string()
  })
});

// endregion

// region Graph

gql`
  mutation injectVerification($input: InjectVerificationInput!) {
    injectVerification(input: $input) {
      id
    }
  }


  mutation manuallyCreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      result
      client {
        id
      }
    }
  }
`;

// endregion

export const CreateClientPage: React.FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // region Networking

  const [createClientMutation] = useManuallyCreateClientMutation();
  const [injectVerificationMutation] = useInjectVerificationMutation();

  // endregion

  // region From Control

  const form = useForm<z.infer<typeof CreateClientSchema>>({
    resolver: zodResolver(CreateClientSchema),
    defaultValues: {
      client: {
        type: ClientType.Individual,
        classification: ClientClassification.Unknown
      }
    }
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  const clientType = form.watch('client.type');

  // endregion

  // region Effects

  // Every type the client type is changed reset the classification
  React.useEffect(() => {
    form.resetField('client.classification');
  }, [clientType]);

  // endregion

  // region Actions

  const onCreateClient = form.handleSubmit(async (data) => {
    const {
      email,
      classification,
      ...clientData
    } = data.client;

    // Create the client
    const client = await createClientMutation({
      variables: {
        input: {
          ...clientData,
          classification:
            classification !== ClientClassification.Unknown
              ? classification
              : undefined,
          user: {
            email
          }
        }
      }
    });

    if (client.data?.createClient.result !== CreateClientResultEnum.Successful) {
      snackbar.enqueueSnackbar('There was a problem creating the client', {
        variant: 'error'
      });

      return;
    }

    const clientId = client.data?.createClient.client?.id!;

    // Create the verification
    await injectVerificationMutation({
      variables: {
        input: {
          ...data.verification,
          clientId
        }
      }
    });

    // Give it 5 seconds to process things in the background. Nothing bad
    // will happen if you don't wait, but UX will be better that way
    await new Promise((res) => {
      setTimeout(() => {
        snackbar.enqueueSnackbar('Client successfully created');

        navigate(`/clients/${clientId}/details/compliance`);

        res(undefined);
      }, 5000);
    });
  });

  // endregion

  return (
    <Box>
      {/* region Page Header */}

      <PageHeading
        title="Create Client"
        breadcrumbs={[
          {
            text: 'Clients',
            link: 'clients/list/onboarded'
          }, {
            text: 'Create'
          }
        ]}
      />

      {/* endregion */}

      {/* Create the client */}
      <FormGroup title="Client Information">
        <Box>
          <TextField
            fullWidth
            label="Client Contact Email"
            {...form.register('client.email')}

            error={!!errors.client?.email}
            helperText={
              errors.client?.email?.message ??
              'The main contact email for the client. Will be user for all the communication sent from the platform'
            }
          />

          <EnumSelect
            fullWidth
            enum={ClientType}

            label="Client Type"
            {...form.register('client.type')}

            error={!!errors.client?.type}
            helperText={
              (errors.client?.type as FieldError)?.message ??
              'The type of entity that the client represents'
            }
          />


          {[
            ClientType.Institution,
            ClientType.Foundation
          ].includes(clientType) && (
              <EnumSelect
                fullWidth
                enum={ClientClassification}

                label="Client Classification"
                {...form.register('client.classification')}

                error={!!errors.client?.classification}
                helperText={errors.client?.classification?.message}
              />
            )}


        </Box>
      </FormGroup>

      {/* Create the users */}
      {/*<FormGroup title="User Information">*/}
      {/*  <CreateClientUsers />*/}
      {/*</FormGroup>*/}

      {/* Create the compliance */}
      <FormGroup title="Verification Information">
        <TextField
          label="Vendor Applicant Id"
          {...form.register('verification.vendorApplicantId')}

          error={!!errors.verification?.vendorApplicantId}
          helperText={errors.verification?.vendorApplicantId?.message}
        />

        <TextField
          label="Vendor Verification Id"
          {...form.register('verification.vendorVerificationId')}

          error={!!errors.verification?.vendorVerificationId}
          helperText={errors.verification?.vendorVerificationId?.message}
        />
      </FormGroup>


      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <LoadingButton
          loading={isSubmitting}
          onClick={onCreateClient}
        >
          Create Client
        </LoadingButton>
      </Box>
    </Box>
  );
};
