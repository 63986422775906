import React from 'react';

import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import { InteractionDetailsInvoiceQuery, useInteractionDetailsInvoiceQuery, useRegenerateInvoiceMutation } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { InfoLabel, Title } from '@backed-fi/compound';
import { CentsFormatter } from '@backed-fi/shared';
import { LoadingButton } from '@mui/lab';

// region Graph

gql`
  query interactionDetailsInvoice($interactionId: String!) {
    interaction(
      where: {
        id: $interactionId
      }
    ) {
      id

      fee {
        id
        fee
        principal
        currency

        invoice {
          id
          isPaid
          invoiceNumber
          downloadUrl
        }

        internalTransaction {
          hash
          explorerUrl
          network
          status
        }
      }
    }
  }

  mutation regenerateInvoice($invoiceId: String!) {
    generateInvoicePDF(
      input: {
        invoiceId: $invoiceId
      }
    ) {
      id
    }
  }
`;


// endregion


export const InteractionInvoicesPage: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  // region Networking

  const {
    data,
    previousData
  } = useInteractionDetailsInvoiceQuery({
    pollInterval: 9 * 60_000, // Every 9 minutes
    variables: {
      interactionId: params.interactionId!
    }
  });

  const { interaction } = (data || previousData || {}) as InteractionDetailsInvoiceQuery;

  const [regenerateInvoice, { loading: regeneratingInvoice }] = useRegenerateInvoiceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'interactionDetailsInvoice'
    ]
  });

  // endregion

  return !!interaction
    ? (
      <Box
        sx={{
          minHeight: '70vh'
        }}
      >
        <Box>
          <Title
            title="Fee & Internal Fee Movement"
            subtitle="The fee that the client was charged and the internal movements of that fee"
          />

          <InfoLabel
            label="Fee Principal"
            tooltip="The original value on which the fee is calculated"
            content={
              interaction.fee
                ? CentsFormatter.format(interaction.fee?.principal, interaction.fee?.currency)
                : 'Not Present'
            }
          />

          <InfoLabel
            label="Charged Fee"
            tooltip="The fee that was charged"
            content={
              interaction.fee
                ? CentsFormatter.format(interaction.fee?.fee, interaction.fee?.currency)
                : 'Not Present'
            }
          />

          <InfoLabel
            label="Network"
            tooltip="The network, on which the internal fee collection ocurred"
            content={interaction.fee?.internalTransaction?.network ?? 'Not Present'}
          />

          <InfoLabel
            externalLink
            label="Internal Fee Movement Hash"
            tooltip="Click to open in explorer"
            to={interaction.fee?.internalTransaction?.explorerUrl ?? undefined}
            content={interaction.fee?.internalTransaction?.hash ?? 'Not Present'}
          />
        </Box>

        <Box
          sx={{
            mt: '2rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Title
              boxed
              title="Invoice"
              subtitle="The generated invoice PDF"
            />


            {(interaction && interaction.fee?.invoice) && (
              <LoadingButton
                loading={regeneratingInvoice}
                onClick={() => (
                  regenerateInvoice({
                    variables: {
                      invoiceId: interaction?.fee?.invoice?.id!
                    }
                  })
                )}
              >
                Regenerate Invoice
              </LoadingButton>
            )}
          </Box>

          {(interaction && !interaction.fee?.invoice) && (
            <Box
              sx={{
                mt: '10vh',
                textAlign: 'center'
              }}
            >
              <Title
                title="No Invoice"
                subtitle="The interaction does not yet have generated invoice"
              />
            </Box>
          )}

          {interaction?.fee?.invoice && (
            <Box
              component="object"
              data={interaction.fee.invoice.downloadUrl!}
              width="100%"
              height="100%"

              sx={{
                mt: '1rem',
                width: '100%',
                minHeight: '70vh'

              }}
            >
              <p>Unable to display PDF file. <a href={interaction.fee.invoice.downloadUrl!}>Download</a> instead.</p>
            </Box>
          )}
        </Box>
      </Box>
    )
    : null;
};
