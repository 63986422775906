import { gql } from '@apollo/client';
import React from 'react';
import { usePaginationModel } from '@backed-fi/hooks';
import { useNavigate } from 'react-router-dom';
import { InternalTransactionAction, InternalTransactionStatus, useInternalTransactionsQuery } from '@backed-fi/graphql';
import { Box, Chip, LinearProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { DateTimeFormatter } from '@backed-fi/shared';
import { HashTile } from '@backed-fi/compound';

// region GraphQL

gql`
  query internalTransactions(
    $page: Int
    $pageSize: Int
    $where: InternalTransactionsWhereInput
  ) {
    internalTransactions(
      page: $page
      pageSize: $pageSize
      where: $where
    ) {
      nodes {
        id

        createdAt
        updatedAt

        action

        status
        gaslessStrategy

        network

        hash
        explorerUrl
      }

      page {
        currentPage
        totalNodes
        totalPages
      }
      }
  }
`;

// endregion

// region Props

interface Props {
  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  interactionId?: string;
}

// endregion

export const InternalTransactionsTable: React.FC<Props> = ({
  disablePagination,
  interactionId
}) => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(disablePagination);

  const navigate = useNavigate();

  // region Networking


  const query = useInternalTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,

      where: {
        interactionId
      }
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  // region Effects

  React.useEffect(() => {
    (async () => {
      if (!pagination.isDisabled) {
        await query.refetch(paginationVariables);
      }
    })();
  }, [paginationVariables]);

  // endregion

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Box
        sx={{
          overflowX: 'scroll'
        }}
      >
        <DataGrid
          {...pagination}
          autoHeight
          slots={{
            loadingOverlay: LinearProgress
          }}
          loading={loading}
          hideFooter={!!disablePagination}
          rows={data?.internalTransactions?.nodes || []}
          rowCount={data?.internalTransactions.page.totalNodes}
          columns={[
            {
              width: 200,
              field: 'createdAt',
              headerName: 'Timestamp',
              renderCell: ({
                value,
                row
              }) => {
                return (
                  <Typography
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={() => {
                      navigate(`/blockchain/transactions/incoming/${row['id']}/details`);
                    }}
                  >
                    {DateTimeFormatter.format(new Date(value))}
                  </Typography>
                );
              }
            }, {
              width: 200,
              field: 'network',
              headerName: 'Network',
              renderCell: ({ row }) => {
                return <BlockchainNetworkLabel network={row.network!} />;
              }
            }, {
              flex: 1,
              minWidth: 150,
              field: 'action',
              headerName: 'Action',
              renderCell: ({ value }) => {
                return (
                  <Chip
                    label={value}
                    color={
                      {
                        IssuanceFeeCollection: 'secondary',
                        RedemptionFeeCollection: 'warning',
                        Sweeping: 'primary',
                        RefundFeeCollection: 'error'
                      }[value as InternalTransactionAction] as any
                    }
                  />
                );
              }
            }, {
              minWidth: 150,
              field: 'status',
              headerName: 'Transaction Status',
              renderCell: ({ value }) => {
                return (
                  <Chip
                    label={value}
                    color={
                      {
                        Created: 'warning',
                        Submitted: 'primary',
                        Succeeded: 'success',
                        Failed: 'error'
                      }[value as InternalTransactionStatus] as any
                    }
                  />
                );
              }
            }, {
              flex: 2,
              minWidth: 200,
              field: 'hash',
              headerName: 'Hash',
              renderCell: ({
                value,
                row
              }) => (
                <HashTile
                  hash={value}
                  explorerUrl={row.explorerUrl ?? undefined}
                />
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
