import React from 'react';
import { gql } from '@apollo/client';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import { BlockchainNetwork, useTokenDeploymentOwnershipQuery } from '@backed-fi/graphql';

// region Graph

gql`
  query tokenDeploymentOwnership {
    tokens {
      id
      name

      deployments {
        id

        network

        ownerAddress
        pauserAddress
        burnerAddress
        minterAddress
      }
    }
  }
`;

// endregion

const HighlightBox: React.FC<{
  text: string;
  title: string;
  highlight: string;
}> = ({
  text,
  title,
  highlight
}) => {
    const isHighlighted = React.useMemo(() => {
      const segments = highlight.split(', ').map((x) => x.trim().toLowerCase());

      return segments.some((x) => text.toLowerCase().includes(x) && x.length > 0);
    }, [text, highlight]);

    return (
      <Tooltip
        title={title}
      >
        <Box
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            borderRadius: '4px',
            alignItems: 'center',
            paddingX: '16px',

            ...(isHighlighted && ({
              color: 'warning.textHighContrast',
              backgroundColor: 'warning.solidBackground'
            }))
          }}
        >
          <Typography>
            {text}
          </Typography>
        </Box>
      </Tooltip>
    );
  };


export const BlockchainOwnershipPage: React.FC = () => {
  const { data } = useTokenDeploymentOwnershipQuery();

  const [highlight, setHighlight] = React.useState<string>('');

  return !data?.tokens ? (
    <Box>
      Loading
    </Box>
  ) : (
    <Box>
      <Box>
        <TextField
          label="Highlight"
          onChange={(e) => setHighlight(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          display: 'flex'
        }}
      >
        <Box>
          <Box
            sx={{
              height: '50px',
              backgroundColor: 'utility.elementBackground'
            }}
          />

          {Object.keys(BlockchainNetwork)
            .map((network) => (
              <Box
                sx={{
                  backgroundColor: 'utility.elementBackground'
                }}
              >
                <Box
                  sx={{
                    px: '16px',
                    height: '50px',
                    backgroundColor: 'utility.elementBackgroundActive'
                  }}
                >
                  <Typography
                    variant="titleSmall"
                  >
                    {network}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    px: '16px',
                    height: '200px',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'space-around'
                  }}
                >
                  <Typography>
                    Owner
                  </Typography>

                  <Typography>
                    Minter
                  </Typography>

                  <Typography>
                    Burner
                  </Typography>

                  <Typography>
                    Pauser
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Box>

        {data.tokens.map((token) => (
          <Box>
            <Box
              sx={{
                display: 'flex',
                alingItems: 'center',
                justifyContent: 'center',
                padding: '8px',
                height: '50px',
                backgroundColor: 'utility.elementBackground'
              }}
            >
              <Typography variant="titleSmall">
                {token.name}
              </Typography>
            </Box>

            {Object.keys(BlockchainNetwork)
              .map((network) => {
                const deployment = token.deployments.find((x) => x.network === network);

                return (
                  <Box
                    sx={{
                      height: '250px'
                    }}
                  >
                    <Box
                      sx={{
                        height: '50px',
                        backgroundColor: 'utility.elementBackgroundActive'
                      }}
                    />

                    {!deployment ? (
                      <Typography
                        sx={{
                          mt: '32px',
                          textAlign: 'center'
                        }}
                      >
                        No deployment on that network
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          height: '200px',
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'space-around',

                          '& > *:nth-child(even)': {
                            backgroundColor: 'primary.backgroundSubtle'
                          }
                        }}
                      >
                        <HighlightBox
                          highlight={highlight}
                          text={deployment.ownerAddress}
                          title={`${network} ${token.name}'s Owner`}
                        />

                        <HighlightBox
                          highlight={highlight}
                          text={deployment.minterAddress}
                          title={`${network} ${token.name}'s Minter`}
                        />

                        <HighlightBox
                          highlight={highlight}
                          text={deployment.burnerAddress}
                          title={`${network} ${token.name}'s Burner`}
                        />

                        <HighlightBox
                          highlight={highlight}
                          text={deployment.pauserAddress}
                          title={`${network} ${token.name}'s Pauser`}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })
            }
          </Box>
        ))}
      </Box>
    </Box>
  );
};
