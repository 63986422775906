import { ExecuteBankTransferDialog } from '@backed-fi/admin/src/app/domain/Banking/tranfers/dialogs/ExecuteBankTransferDialog';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { BankTransferStatus, useBankTransfersQuery } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { usePaginationModel } from '@backed-fi/hooks';
import { gql } from '@apollo/client';

import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { useNavigate } from 'react-router-dom';

// region Graph

gql`
  query bankTransfers(
    $page: Int,
    $pageSize: Int,
    $where: BankTransfersWhereInput
  ) {
      bankTransfers(
      page: $page,
      pageSize: $pageSize,

      where: $where
      ) {
        nodes {
          id

          amount
          status
          currency

          payoutForInteractionId
          paymentForInteractionId

          client {
            id
            name
          }
        }

        page {
          pageSize
          totalPages
          totalNodes
        }
      }
  }
`;

// endregion

// region Props

interface Props {
  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  clientId?: string;
  payoutForInteractionId?: string;
  paymentForInteractionId?: string;
}

// endregion

export const BankTransfersTable: React.FC<Props> = ({
  disablePagination,
  ...where
}) => {
  const navigate = useNavigate();

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(disablePagination);

  // region State

  const [executingTransferId, setExecutingTransferId] = React.useState<string>();

  // endregion

  // region Networking

  const query = useBankTransfersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,
      where
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  // region Action

  const onOpenDialog = (transferId: string) => () => {
    setExecutingTransferId(transferId);
  };

  const onCloseDialog = () => {
    setExecutingTransferId(undefined);
  };

  // endregion

  return (
    <Box>
      {/* region Dialogs */}

      {executingTransferId && (
        <ExecuteBankTransferDialog
          transferId={executingTransferId}
          onDialogClose={onCloseDialog}
        />
      )}

      {/* endregion */}

      {/* region Transfers Table */}

      <DataGrid
        {...pagination}
        autoHeight
        loading={loading}
        rows={data?.bankTransfers.nodes ?? []}
        rowCount={data?.bankTransfers.page.totalNodes}
        slots={{
          loadingOverlay: LinearProgress
        }}
        columns={[{
          flex: 3,
          minWidth: 200,
          field: 'client',
          headerName: 'Client',
          renderCell: ({ row }) => (
            <Typography>
              {row.client.name}
            </Typography>
          )
        }, {
          flex: 2,
          minWidth: 200,
          field: 'amount',
          headerName: 'Payout Amount',
          renderCell: ({
            value,
            row
          }) => (
            <Typography>
              {(value / 100).toFixed(2)} {row.currency}
            </Typography>
          )
        }, {
          flex: 2,
          minWidth: 200,
          field: 'status',
          headerName: 'Status',
          renderCell: ({ value }) => (
            <Chip
              label={value}
            />
          )
        }, {
          flex: 2,
          minWidth: 100,
          field: 'paymentForInteractionId',
          headerName: 'Type',
          renderCell: ({ row }) => (
            <Chip
              label={row.paymentForInteractionId ? 'Incoming' : row.payoutForInteractionId ? 'Outgoing' : 'Unknown'}
            />
          )
        }, {
          field: 'id',
          minWidth: 200,
          headerName: '',
          renderCell: ({
            value,
            row
          }) => (
            <React.Fragment>
              <Tooltip
                title="View linked interaction"
              >
                <IconButton
                  onClick={() => {
                    navigate(`/interactions/details/${row.payoutForInteractionId ?? row.paymentForInteractionId}/overview`);
                  }}
                >
                  <ViewIcon />
                </IconButton>
              </Tooltip>

              {row.status === BankTransferStatus.Pending && (
                <LoadingButton
                  loading={executingTransferId === value}
                  onClick={onOpenDialog(value)}
                >
                  Mark As Executed
                </LoadingButton>
              )}
            </React.Fragment>
          )
        }]}
      />

      {/* endregion */}
    </Box>
  );
};
