import React from 'react';
import Decimal from 'decimal.js';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Box,
  Dialog,
  DialogContent,
  Skeleton,
  Typography
} from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import {
  BurnFlowDialogQuery,
  FlowType,
  useBurnFlowDialogLazyQuery,
  useCreateFlowExecutionMutation
} from '@backed-fi/graphql';
import { FlowLoading } from '../shared/FlowLoading';
import { BurnFlowSchema, BurnFlowSetup } from './BurnFlowSetup';
import { useNavigate } from 'react-router-dom';
import { FlowPreview } from '../shared/FlowPreview';

gql`
  query burnFlowDialog($tokenId: ID!) {
    token(id: $tokenId) {
      id
      name
      symbol
      icon
      deployments {
        id
        network
        circulatingSupply
        totalSupply
        burnAddress
        burnSupply
      }
      collateral {
        ISINNumber
      }
    }
  }
`;

type Props = {
  tokenId: string;

  toggle: ReturnType<typeof useToggle>
};

export const BurnFlowDialog: React.FC<Props> = ({ tokenId, toggle }) => {
  const [isPreview, setIsPreview] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [flowExecutionId, setFlowExecutionId] = React.useState<string>();
  const [setup, setSetup] = React.useState<BurnFlowSchema>();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  // region Networking
  const [burnFlowQuery, { data }] = useBurnFlowDialogLazyQuery({
    variables: {
      tokenId
    }
  });

  const [createFlowExecution] =
    useCreateFlowExecutionMutation();


  const { token } = data || ({} as BurnFlowDialogQuery);

  const onRequest = async () => {
    if (setup) {
      setIsLoading(true);

      try {
        const { data } = await createFlowExecution({
          variables: {
            input: {
              flowType: FlowType.Burn,
              flowExecutionPayload: {
                tokenDeploymentId: setup.deploymentId,
                amount: setup.amount
              }
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: ['flowsExecutionsList']
        });

        setFlowExecutionId(data!.createFlowExecution.id);

        snackbar.enqueueSnackbar('Burn flow successfully requested', {
          variant: 'success'
        });
      } catch (e) {
        toggle.onClose();

        snackbar.enqueueSnackbar('Burn flow request has failed', {
          variant: 'error'
        });
      }
    }
  };

  const onSuccess = () => {
    snackbar.enqueueSnackbar('Burn flow successfully processed', {
      variant: 'success'
    });

    toggle.onClose();

    navigate(`/blockchain/tokens/details/${tokenId}/overview`);
  };

  const onFail = () => {
    snackbar.enqueueSnackbar('Burn flow failed', {
      variant: 'error'
    });

    toggle.onClose();
  };

  React.useEffect(() => {
    burnFlowQuery();
  }, []);

  const onSetup = (setup: BurnFlowSchema) => {
    setSetup(setup);
    setIsPreview(true);
  };

  return (
    <Dialog
      fullWidth
      {...toggle}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '12px'
        },
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '708px'
          }
        }
      }}
    >
      <DialogContent
        sx={{
          padding: '32px'
        }}
      >
        {isLoading && flowExecutionId ? (
          <FlowLoading
            onSuccess={onSuccess}
            onFail={onFail}
            flowExecutionId={flowExecutionId}
            symbol={token.symbol}
            flowType={FlowType.Burn}
          />
        ) : (
          <>
            <Typography variant="title">Burn Tokens</Typography>

            {token && (
              <Box>
                {!isPreview && (
                  <BurnFlowSetup
                    token={token}
                    previous={setup}
                    onSetup={onSetup}
                  />
                )}
                {isPreview && setup && (
                  <FlowPreview
                    token={token}
                    flow={{
                      amount: setup.amount,
                      network: token.deployments.find(
                        (d) => d.id === setup.deploymentId
                      )!.network,
                      wallet: token.deployments.find(
                        (d) => d.id === setup.deploymentId
                      )!.burnAddress,
                      totalSupply: token.deployments
                        .reduce(
                          (prev, acc) => prev.add(acc.totalSupply),
                          new Decimal(0)
                        )
                        .toString()
                    }}
                    flowType={FlowType.Burn}
                    onBack={() => setIsPreview(false)}
                    onSubmit={onRequest}
                  />
                )}
              </Box>
            )}
            {!token && (
              <Box>
                <Skeleton
                  height={144}
                  animation="wave"
                  sx={{ marginY: '32px' }}></Skeleton>
                <Skeleton
                  animation="wave"
                  height={56}
                  sx={{ marginBottom: '8px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  height={56}
                  sx={{ marginBottom: '8px' }}
                ></Skeleton>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
