import React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AdminsListPage } from '@backed-fi/admin/src/app/domain/Internal/Admins/AdminsListPage';
import { FeesConfigurationOverviewPage } from '@backed-fi/admin/src/app/domain/Internal/Fees/FeesConfigurationOverviewPage';
import { DeploymentPages } from '@backed-fi/admin/src/app/domain/Deployment/DeploymentPages';
import { RolesPages } from '@backed-fi/admin/src/app/domain/Internal/Roles/RolesPages';
import { EventsPages } from '@backed-fi/admin/src/app/domain/Internal/Events/EventsPages';
import { ApiAccessPages } from './ApiAccess';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, CompanyFeature } from '@backed-fi/graphql';
import { SystemWalletsListPage } from './SystemWallets/SystemWalletsListPage';
import { FactoriesListPage } from './Factories/FactoriesListPage';

export const InternalPages = (
  <Route path='internal' element={
    <Protector redirect permissions={[AdminPermission.SystemRead]}>
      <Outlet />
    </Protector>
  }>

    <Route
      path='users/all'
      element={<AdminsListPage />}
    />

    <Route path="fees" element={
      <Protector redirect companyFeature={CompanyFeature.Fees}>
        <Outlet />
      </Protector>
    }>
      <Route
        path='overview'
        element={<FeesConfigurationOverviewPage />}
      />
    </Route>

    <Route path="api-access" element={
      <Protector redirect companyFeature={CompanyFeature.ApiAccess}>
        <Outlet />
      </Protector>
    }>
      {ApiAccessPages}
    </Route>

    <Route path="wallets" element={
      <Protector redirect companyFeature={CompanyFeature.BlockchainAdvanced}>
        <Outlet />
      </Protector>
    }>
      <Route
        path="all"
        element={<SystemWalletsListPage />}
      />
    </Route>

    <Route path="factories" element={
      <Protector redirect companyFeature={CompanyFeature.BlockchainAdvanced}>
        <Outlet />
      </Protector>
    }>
      <Route
        path="all"
        element={<FactoriesListPage />}
      />
    </Route>

    {DeploymentPages}
    {RolesPages}
    {EventsPages}
  </Route>
);
