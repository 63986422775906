import React from "react";

import { Box, TextField, Typography, FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type ComponentProps = {
  onChange: (logo: File) => void;
  onIconChange: (icon: string) => void;
  selectedLogo?: string;
  helperText?: string;
  error?: boolean;
};

const CreateTokenFormLogo: React.FC<ComponentProps> = ({
  onChange,
  onIconChange,
  selectedLogo,
  helperText,
  error,
}) => {
  const [logo, setLogo] = React.useState(selectedLogo);
  const fileInput = React.useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const fileReader = new FileReader();

      fileReader.addEventListener("load", () => {
        if (fileReader.result) {
          const logo = fileReader.result.toString();
          
          setLogo(logo);
          onIconChange(logo);
        }
      });

      fileReader.readAsDataURL(event.target.files![0]);
      onChange(event.target.files![0]);
    }
  };

  return (
    <Box sx={{ margin: "0.3rem 0" }}>
      <Box
        sx={{
          userSelect: "none",
          cursor: "pointer",
          display: "flex",
          gap: "1.25rem",

          padding: "1.25rem",
          alignItems: "center",
          borderRadius: "0.5rem",
          backgroundColor: "#F2F4F8",

          ...(error && {
            border: 1,
            borderColor: "error.borderElement",
          }),
        }}
        onClick={() => fileInput.current?.click()}
      >
        <Box
          sx={{
            overflow: " hidden",
            display: "flex",
            width: "3rem",
            height: "3rem",
            color: "#A4AAB6",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#E2E5EB",
            borderRadius: "100%",
          }}
        >
          {logo ? (
            <Box component="img" src={logo} width={48} height={48} />
          ) : (
            <CloudUploadIcon />
          )}
        </Box>
        <Box>
          <Typography fontSize={12} sx={{ color: "#747B89" }}>
            Logo (512x512 PNG)
          </Typography>
          <Typography fontSize={14}>
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                marginTop: "0.125rem",
              }}
            >
              Click here
            </Box>{" "}
            to upload a new logo
          </Typography>
          <TextField
            type="file"
            onChange={handleFileSelect}
            inputProps={{ accept: "image/png", ref: fileInput }}
            sx={{ display: "none" }}
          />
        </Box>
      </Box>
      {helperText && (
        <FormHelperText
          sx={{
            margin: "3px 14px 0",
            ...(error && { color: "error.textLowContrast" }),
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CreateTokenFormLogo;
