import { gql } from "@apollo/client";
import { PageHeading } from "@backed-fi/compound";
import { ServiceAccountStatus, useApiAccesesQuery } from "@backed-fi/graphql";
import { usePaginationModel } from "@backed-fi/hooks";
import { Badge, Box, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CreateApiAccessDialog } from "../components/CreateApiAccessDialog";
import { ApiPermissions } from "../components/ApiPermissions";
import { DisableServiceAccountDialog } from "../components/DisableApiAccessDialog";

const Graph = gql`
  query apiAcceses(
    $page: Int,
    $pageSize: Int,
  ) {
    serviceAccounts(
      page: $page,
      pageSize: $pageSize
    ) {
      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }
      nodes {
        id

        title
        status

        createdAt
        updatedAt

        permissions
      }
    }
  }
`;

export const ApiAccessListPage = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region Networking

  const query = useApiAccesesQuery({
    variables: {
      ...paginationVariables,
    }
  });

  const {
    data,
    previousData,
    loading
  } = query;

  // endregion

  const finalData = data || previousData;

  return (
    <Box>
      <PageHeading
        title="API Accesses"
      >
        <CreateApiAccessDialog />
      </PageHeading>

      <DataGrid
        {...pagination}

        loading={query.loading}

        slots={{
          loadingOverlay: LinearProgress
        }}

        autoHeight
        hideFooter={false}
        rows={finalData?.serviceAccounts?.nodes || []}
        rowCount={finalData?.serviceAccounts?.page.totalNodes}


        columns={[{
          flex: 10,
          sortable: false,
          field: 'title',
          headerName: 'Name',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        },
        {
          flex: 2,
          sortable: false,
          field: 'status',
          headerName: 'status',
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  gap: '0.75rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Badge
                  variant='dot'
                  sx={{
                    marginLeft: '0.5rem',

                    '.MuiBadge-badge': {
                      backgroundColor: row.status === ServiceAccountStatus.Active
                        ? 'success.solidBackground'
                        : '#c5c9d3'
                    }
                  }}
                />

                {row.status === ServiceAccountStatus.Active ? 'Active' :
                  row.status === ServiceAccountStatus.Expired ? 'Expired' :
                    'Disabled'
                }
              </Box>
            );
          }
        },
        {
          flex: 2,
          sortable: false,
          field: 'permissions',
          headerName: 'Permissions',
          renderCell: ({ row }) => (
            <ApiPermissions
              id={row.id}
              name={row.title}
              permissions={row.permissions}
            />
          )
        }, {
          flex: 2,
          sortable: false,
          field: 'createdAt',
          headerName: 'Created At',
          renderCell: ({ value }) => (
            <Typography>

              {new Date(value).toLocaleString()}
            </Typography>
          )
        }, {
          flex: 2,
          sortable: false,
          field: 'updatedAt',
          headerName: '',
          renderCell: ({ row }) => (
            <>
              {
                row.status === ServiceAccountStatus.Active && <DisableServiceAccountDialog
                  name={row.title}
                  serviceAccountId={row.id}
                />
              }
            </>
          )
        }
        ]}
      />
    </Box>
  )
}