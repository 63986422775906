import React, { useMemo, useState } from 'react';


import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Typography } from '@mui/material';

import { OutgoingTransactionStatus, useOutgoingTransactionsQuery } from '@backed-fi/graphql';
import { ExportButton } from '@backed-fi/admin/src/app/components/ExportButton';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { Filters, filterToWhereQuery, FilterType } from '@backed-fi/admin/src/app/components/filter/Filters';
import { usePaginationModel } from '@backed-fi/hooks';
import { OutgoingTransactionApproval } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/outgoing/components/OutgoingTransactionApproval';
import { DateTimeFormatter } from '@backed-fi/shared';
import { HashTile } from '@backed-fi/compound';
import { TransactionValueVerification } from '../../internal/components/TransactionValueVerification';

// region Graph Definition


gql`
  query outgoingTransactions(
    $page: Int,
    $pageSize: Int,

    $where: OutgoingTransactionsWhereInput
  ) {
    outgoingTransactions(
      page: $page,
      pageSize: $pageSize,

      where: $where
    ) {
      nodes {
        id
        createdAt

        type
        hash
        amount
        displayAmount
        status
        network
        explorerUrl

        refundId
        
        stablecoinContractAddress

        client {
          id
          name
        }

        token {
          id
          network

          token {
            symbol
            name
          }
        }
      }

      page {
        currentPage
        totalPages
        totalNodes
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  hideFilters?: boolean;
  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  clientId?: string;
  interactionId?: string;
}

// endregion

export const OutgoingTransactionsTable: React.FC<Props> = ({
  hideFilters,
  disablePagination,
  clientId,
  interactionId
}) => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(disablePagination);

  // region State

  const [filters, setFilters] = useState<Record<string, any>>({});
  const queryFilters = useMemo(() => {
    return {
      clientId,
      interactionId,
      ...filters
    };
  }, [filters, clientId, interactionId]);

  // endregion

  // region Networking

  const query = useOutgoingTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,
      where: queryFilters
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  return (
    <Box>
      {!hideFilters && (
        <Box
          mb={1}
          gap={1}
          display="flex"
        >
          {/* region Filters */}

          <Filters
            onFiltersChanged={(filters) => setFilters(filterToWhereQuery(filters))}
            filters={[
              FilterType.OutgoingTransactionStatus,
              FilterType.OutgoingTransactionType,
              FilterType.ClientType,
              FilterType.ClientName,
              FilterType.Network,
              FilterType.TokenSymbol
            ]}
          />


          {/* endregion */}

          {/* region Export Button */}

          {data && (
            <Box>
              <ExportButton
                csv
                json
                exportName="outgoing-transactions"
                data={data.outgoingTransactions.nodes ?? []}
              >
                Export Current Page
              </ExportButton>
            </Box>
          )}

          {/* endregion */}
        </Box>
      )}

      <Box
        sx={{
          overflowX: 'scroll'
        }}
      >
        {/* region Data Grid */}

        <DataGrid
          {...pagination}
          autoHeight
          loading={loading}
          rows={data?.outgoingTransactions.nodes || []}
          rowCount={data?.outgoingTransactions.page.totalNodes}
          columnVisibilityModel={{
            client: (!clientId && !interactionId),
            actions: !!interactionId
          }}
          columns={[{
            flex: 2,
            field: 'client',
            headerName: 'Client',
            renderCell: ({ value }) => (
              <Typography>
                {value.name}
              </Typography>
            )
          }, {
            flex: 1,
            field: 'type',
            headerName: 'Transaction Type',
            renderCell: ({
              value,
              row
            }) => (
              <React.Fragment>
                {row.refundId && (
                  <Chip
                    color='warning'
                    label="Refund"
                    sx={{
                      mr: '8px'
                    }}
                  />
                )}

                <Typography>
                  {
                    row.token
                      ? `${row.token?.token.symbol} (${row.token?.network})`
                      : value
                  }
                </Typography>
              </React.Fragment>
            )
          }, {
            width: 200,
            field: 'network',
            headerName: 'Network',
            renderCell: ({ row }) => {
              return (
                <BlockchainNetworkLabel network={row.network} />
              );
            }
          }, {
            flex: 1,
            field: 'displayAmount',
            headerName: 'Amount Sent',
            renderCell: ({ value }) => (
              <Typography>
                {value}
              </Typography>
            )
          }, {
            flex: 2,
            field: 'createdAt',
            headerName: 'Started At',
            renderCell: ({ value }) => (
              <Typography>
                {DateTimeFormatter.format(new Date(value))}
              </Typography>
            )
          }, {
            flex: 1,
            minWidth: 150,
            field: 'hash',
            headerName: 'Transaction Hash',
            renderCell: ({ value, row }) => (
              <HashTile
                hash={value}
                explorerUrl={row.explorerUrl ?? undefined}
              />
            )
          }, {
            width: 150,
            field: 'status',
            headerName: 'Status',
            renderCell: ({ row }) => (
              <Chip
                // @ts-ignore
                color={{
                  Executed: 'success',
                  Failed: 'error'
                }[row.status] ?? 'primary'}
                label={row.status}
              />
            )
          }, {
            width: 150,
            field: 'actions',
            headerName: '',
            renderCell: ({ row }) => (
              <Box>
                {row.status === OutgoingTransactionStatus.PendingApproval && (
                  <OutgoingTransactionApproval transactionId={row.id} />
                )}
                {row.status === OutgoingTransactionStatus.PendingValueVerification && (
                  <TransactionValueVerification transactionId={row.id} onVerified={() => {
                    query.refetch()
                  }} transactionType='Outgoing' />
                )}
              </Box>
            )
          }]}
        />

        {/* endregion */}
      </Box>
    </Box>
  );
};
