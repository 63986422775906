import React from 'react';
import { Box, Dialog, DialogContent, Tooltip, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useToggle } from '@backed-fi/hooks';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useApproveDeletionRequestMutation, useDocumentDeletionRequestLazyQuery } from '@backed-fi/graphql';
import { InfoLabel } from '@backed-fi/compound';
import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';
import { useSnackbar } from 'notistack';

// region Graph

gql`
query documentDeletionRequest($documentId: String!) {
    documentDeletionRequest(documentId: $documentId) {
      id
      createdAt

      note
      wasExecuted

      document {
        url
      }

      requesterId
      requester {
        id

        firstName
        lastName
      }

      approvers {
        id

        firstName
        lastName
      }
    }
  }

  mutation approveDeletionRequest($input: ApproveDocumentDeletionRequestInput!) {
    approveDocumentDeletion(input: $input)
  }
`;

// endregion

export const DeletionDialog: React.FC = () => {
  const snackbar = useSnackbar();
  const toggle = useToggle();
  const [params, setParams] = useSearchParams();

  const adminContext = useAdminContext();

  const [approveDocumentDeletionMutation, { loading: approving }] = useApproveDeletionRequestMutation();
  const [fetchDocumentDeletionRequest, {
    data
  }] = useDocumentDeletionRequestLazyQuery();

  React.useEffect(() => {
    const documentId = params.get('documentId');

    if (documentId) {
      toggle.setTrue();

      fetchDocumentDeletionRequest({
        variables: {
          documentId
        }
      });
    } else {
      toggle.setFalse();
    }
  }, [params]);

  // region Actions

  const onClose = () => {
    setParams({});
  };

  const onRequestApprove = async () => {
    try {
      await approveDocumentDeletionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'folder'
        ],
        variables: {
          input: {
            requestId: data!.documentDeletionRequest.id!
          }
        }
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred approving the document deletion', {
        variant: 'error'
      });
    }

    onClose();
  };

  // endregion

  return (
    <Dialog
      open={toggle.open}
      onClose={onClose}
      fullWidth
    >
      <DialogContent>
        <Typography variant="titleSmall">
          Document Deletion
        </Typography>

        <Typography variant="subtitleSmall">
          Approve the document deletion
        </Typography>

        {data && (
          <React.Fragment>
            <InfoLabel
              label="Requested By"
              content={`${data.documentDeletionRequest.requester.firstName} ${data.documentDeletionRequest.requester.lastName}`}
            />

            {data.documentDeletionRequest.document && (
              <InfoLabel
                label="Document"
                content={(
                  <Typography
                    component="a"
                    variant="link"
                    target="_blank"
                    href={data.documentDeletionRequest.document.url!}
                  >
                    Click to open
                  </Typography>
                )}
              />
            )}


            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Tooltip
                title={
                  data.documentDeletionRequest.requesterId === adminContext.id
                    ? 'You cannot approve your own deletion requests'
                    : ''
                }
              >
                <Box>
                  <LoadingButton
                    loading={approving}
                    onClick={onRequestApprove}
                    disabled={data.documentDeletionRequest.requesterId === adminContext.id}
                  >
                    Approve
                  </LoadingButton>
                </Box>
              </Tooltip>
            </Box>
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};
