import React from 'react';

import { useSnackbar } from 'notistack';
import { Box, Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  explorerUrl?: string;
  hash?: string;
}

export const TransactionActions: React.FC<Props> = ({ explorerUrl, hash }) => {
  const snackbar = useSnackbar();

  return (
    <Box
      sx={{
        gap: '1rem',
        display: 'flex',
        alignItems: 'center',
        color: '#0066ff'
      }}
    >
      {!!explorerUrl && (
        <Link
          onClick={(event) => event.stopPropagation()}
          href={explorerUrl}
          underline='hover'
          target='_blank'
          variant='link'
          color='inherit'
          sx={{
            gap: '0.25rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <LaunchIcon
            sx={{
              width: '20px',
              height: 'auto'
            }}
          />
          Explorer
        </Link>
      )}

      {!!hash && (
        <Box
          onClick={(event) => {
            navigator.clipboard.writeText(hash).then(() => {
              snackbar.enqueueSnackbar('Copied hash to clipboard', {
                variant: 'success'
              });
            });

            event.stopPropagation();
          }}
          sx={{
            gap: '0.25rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',

            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          <ContentCopyIcon
            sx={{
              width: '20px',
              height: 'auto'
            }}
          />
          TX Hash
        </Box>
      )}
    </Box>
  );
};
