import React from 'react';
import { gql } from '@apollo/client';
import { GetCompanyContextQuery, useGetCompanyContextQuery } from '@backed-fi/graphql';
import { useAuthContext } from './AuthContext';

// ---- Client state types ---- //

interface CompanyContext {
  loading: boolean;
  refetch: () => Promise<void>;
}

export const CompanyContext = React.createContext<CompanyContext & GetCompanyContextQuery['company']>(
  null!
);

const CompanyContextQuery = gql`
  query getCompanyContext {
    company {
      id

      name
      logo

      features
    }
  }
`;

const defaultContext: Partial<GetCompanyContextQuery['company']> = {
  features: []
};

export const CompanyContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const authContext = useAuthContext();

  // ---- The Networking ---- //
  const { data, loading, refetch, ...query } = useGetCompanyContextQuery({
    pollInterval: 10000,
    skip: !authContext.authenticated
  });

  // region Refetching Logic

  React.useEffect(() => {
    const company = data?.company;

    if (company) {
      query.startPolling(10000);
    } else {
      // If the onboarding status of the company is final poll every 10 seconds,
      // otherwise poll every second
      query.startPolling(10000);
    }
  }, [data]);

  // endregion

  // ---- The Networking Trigger ---- //

  return (
    <CompanyContext.Provider
      value={{
        ...(data?.company || (defaultContext as any)),
        refetch,
        loading
      }}
      children={children}
    />
  );
};

export const useCompanyContext = () => {
  return React.useContext(CompanyContext);
};
