import React from 'react';
import { gql } from '@apollo/client';
import { Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { ExecuteBankTransferDetailsQuery, useExecuteBankTransferDetailsQuery, useExecuteBankTransferMutation, useSetTransferBankAccountMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { InfoLabel } from '@backed-fi/compound';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Decimal from 'decimal.js';
import { CentsFormatter } from '@backed-fi/shared';

// region Graph

const Graph = gql`
  query ExecuteBankTransferDetails($bankTransferId: String!) {
  bankTransfer(bankTransferId: $bankTransferId) {
    id

    amount
    currency

    bankAccount {
      id

      bankName
      nameOnAccount

      iban
      swift
    }

    client {
      preferences {
        defaultBankAccount {
          id

          bankName
          nameOnAccount

          iban
          swift
        }
      }
    }
  }
  }

  mutation setTransferBankAccount($input: SetTransferBankAccountInput!) {
    setTransferBankAccount(input: $input) {
      id
    }
  }

  mutation executeBankTransfer($input: ExecuteBankTransferInput!) {
    executeBankTransfer(input: $input) {
      id
    }
  }
`;

// endregion

// region Prop Types

interface Props {
  transferId: string;
  onDialogClose: () => any;
}

// endregion

// region Helper Elements

const BankAccountDetails: React.FC<ExecuteBankTransferDetailsQuery['bankTransfer']['bankAccount']> = (bankAccount) => {
  return (
    <React.Fragment>
      <InfoLabel
        label="Bank Name"
        content={bankAccount?.bankName}
      />

      <InfoLabel
        label="Name on account"
        content={bankAccount?.nameOnAccount}
      />

      <InfoLabel
        copy
        label="SWIFT"
        content={bankAccount?.swift}
      />

      <InfoLabel
        copy
        label="IBAN"
        content={bankAccount?.iban}
      />
    </React.Fragment>
  );
};

// endregion

// region Form Schema

const schema = z.object({
  amount: z.string()
    .refine((x) => new Decimal(x), 'The value must be a valid decimal number')
    .transform((x) => new Decimal(x).mul(100).toString()),

  reference: z.string()
    .optional()
});

// endregion

export const ExecuteBankTransferDialog: React.FC<Props> = ({
  transferId,
  onDialogClose
}) => {
  const snackbar = useSnackbar();

  // region Networking

  const {
    data,
    loading
  } = useExecuteBankTransferDetailsQuery({
    variables: {
      bankTransferId: transferId
    }
  });

  const { bankTransfer } = (data || {}) as ExecuteBankTransferDetailsQuery;

  const [executeTransfer, { loading: executingTransfer }] = useExecuteBankTransferMutation();
  const [setTransferBankAccount, { loading: settingBankAccount }] = useSetTransferBankAccountMutation();

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { errors } = form.formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    if (data?.bankTransfer) {
      // Divide by 100 because the amount is already in cents,
      // and if set though here it will go through the
      // transformer (will be multiplied by 100)
      form.setValue('amount', (data.bankTransfer.amount / 100).toString());
    }
  }, [data]);

  // endregion

  // region Actions

  const onSetTransferBankAccount = async () => {
    await setTransferBankAccount({
      awaitRefetchQueries: true,
      refetchQueries: [
        'ExecuteBankTransferDetails'
      ],
      variables: {
        input: {
          bankTransferId: transferId,
          bankAccountId: bankTransfer.client.preferences.defaultBankAccount?.id!
        }
      }
    });

    snackbar.enqueueSnackbar('Successfully set the bank account for this transfer', {
      variant: 'success'
    });
  };

  const onExecuteTransfer = form.handleSubmit(async (data) => {
    try {
      await executeTransfer({
        awaitRefetchQueries: true,
        refetchQueries: [
          'bankTransfers'
        ],
        variables: {
          input: {
            ...data,
            bankTransferId: transferId
          }
        }
      });

      snackbar.enqueueSnackbar('Transfer successfully marked as excuted', {
        variant: 'success'
      });
    } finally {
      onDialogClose();
    }
  });

  // endregion

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onDialogClose}
    >
      <DialogContent>
        <Typography
          mb="2rem"
          variant="title"
        >
          Bank Transfer Details
        </Typography>

        {/* region Loaded Successfully */}

        {(!loading && bankTransfer) && (
          <React.Fragment>
            <Typography
              mb="1rem"
              variant="subtitle"
            >
              Transfer Details
            </Typography>

            <InfoLabel
              label="Payment Amount"
              content={CentsFormatter.format(bankTransfer.amount, bankTransfer.currency)}
            />

            <InfoLabel
              label="Currency"
              content={bankTransfer.currency}
            />

            <Typography
              variant="subtitle"
              mt="2rem"
            >
              Bank Account Details
            </Typography>

            {!bankTransfer.bankAccount && (
              <React.Fragment>
                <Typography variant="small">
                  The client did not had a default bank account at the time of the payout transfer creation.
                  {' '}
                  {bankTransfer.client.preferences.defaultBankAccount
                    ? 'Since then they have added new bank account as default: '
                    : 'In order for this bank transfer to be executed they must add a bank account and make it as default'
                  }
                </Typography>


                {(!!bankTransfer.client.preferences.defaultBankAccount) && (
                  <React.Fragment>
                    <BankAccountDetails
                      {...bankTransfer.client.preferences.defaultBankAccount}
                    />

                    <LoadingButton
                      loading={settingBankAccount}
                      onClick={onSetTransferBankAccount}
                    >
                      Set for payout
                    </LoadingButton>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {bankTransfer.bankAccount && (
              <React.Fragment>
                <BankAccountDetails
                  {...bankTransfer.bankAccount}
                />

                <Typography
                  variant="subtitle"
                  mt="2rem"
                  mb="1rem"
                >
                  Transfer Execution Details
                </Typography>

                <TextField
                  fullWidth
                  label="Payment Amount"
                  error={!!errors?.amount}
                  helperText={errors?.amount?.message ?? 'The actual amount that was sent to the client'}
                  {...form.register('amount')}

                  InputProps={{
                    endAdornment: (
                      <Typography>
                        {bankTransfer.currency}
                      </Typography>
                    )
                  }}
                />

                <TextField
                  fullWidth
                  label="Payment Reference"
                  error={!!errors?.reference}
                  helperText={errors?.reference?.message ?? 'The unique reference, with which the client can track the payment'}
                  {...form.register('reference')}
                />

                <LoadingButton
                  loading={executingTransfer}
                  onClick={onExecuteTransfer}
                  sx={{
                    float: 'right'
                  }}
                >
                  Mark as executed
                </LoadingButton>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {/* endregion */}

      </DialogContent>
    </Dialog>
  );
};
