import React from 'react';
import { Route } from 'react-router-dom';
import { SubmitComplianceReviewPage } from './pages/SubmitComplianceReviewPage';

export const ComplianceReviewsRoutes = (
  <Route path='reviews'>
    <Route
      path='submit/:id'
      element={<SubmitComplianceReviewPage />}
    />
  </Route>
);
