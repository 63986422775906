import { z } from 'zod';
import { Box, Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { AdminPermission, useCompanyAdminPermissionsQuery, useCreateAdminMutation } from '@backed-fi/graphql';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useCompanyContext } from '@backed-fi/shared/components/providers/context/CompanyContext';
import { AdminPermissionDescription } from '@backed-fi/constants';


// region Form Schema

const Schema = z.object({
  firstName: z.string()
    .nonempty(),

  lastName: z.string()
    .nonempty(),

  email: z.string()
    .email()
});

type Schema = z.infer<typeof Schema>;

// endregion

// region Graph

const Graph = gql`
  query CompanyAdminPermissions($companyId: String!) {
    company(where: {id: $companyId}) {
      adminPermissions
    }
  }

  mutation CreateAdmin($input: CreateAdminUserInput!) {
    createAdmin(input: $input) {
      id
    }
  }
`;

// endregion

export const AddAdminDialog: React.FC<React.ComponentProps<typeof Dialog & { id?: string }>> = ({ onClose, id, ...props }) => {
  const { id: companyId } = useCompanyContext();
  // region Networking

  const { data, loading } = useCompanyAdminPermissionsQuery({
    variables: {
      companyId: id ?? companyId
    }
  });


  const [createAdminMutation] = useCreateAdminMutation();

  // endregion

  // region State

  const [permissions, setPermissions] = React.useState<AdminPermission[]>([]);

  // endregion

  // region Form Control

  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onCloseInternal: typeof onClose = (...args) => {
    form.reset({});
    setPermissions([]);

    if (typeof onClose === 'function') {
      onClose(...args);
    }
  };


  const onPermissionToggle = (permission: AdminPermission) => (e: any, checked: boolean) => {
    if (checked) {
      setPermissions((prev) => ([
        ...prev,
        permission
      ]));
    } else {
      setPermissions((prev) => prev.filter((p) => p !== permission));
    }
  };

  const onCreate = form.handleSubmit(async (data) => {
    try {
      await createAdminMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'admins'
        ],
        variables: {
          input: {
            ...data,
            companyId: id,
            permissions
          }
        }
      });

      (onCloseInternal as any)();
    } catch (e) {
      console.error(e);
    }
  });

  // endregion

  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth='sm'
      onClose={onCloseInternal}
    >
      <DialogContent>
        <Typography variant='title'>
          Add User
        </Typography>

        <Box
          mt={3}
          mb={1}
        >
          <Typography
            variant='titleSmall'
          >
            General Information
          </Typography>

          <Typography
            variant='subtitleSmall'
          >
            Some basic information about the account being created
          </Typography>
        </Box>

        <Controller
          name="firstName"
          control={form.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              fullWidth
              label='First Name'
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              {...field}
            />

          )}
        />
        <Controller
          name="lastName"
          control={form.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              fullWidth
              label='Last Name'
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              {...field}
            />

          )}
        />
        <Controller
          name="email"
          control={form.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              fullWidth
              label='Email'
              error={!!errors?.email}
              helperText={errors?.email?.message}
              {...field}
            />

          )}
        />

        <Box
          mt={3}
          mb={1}
        >
          <Typography
            variant='titleSmall'
          >
            Permissions
          </Typography>

          <Typography
            variant='subtitleSmall'
          >
            The set of permissions that the created admin account will posses
          </Typography>
        </Box>

        <FormGroup>
          {data?.company.adminPermissions.map((permission) => (
            <FormControlLabel
              key={permission}
              label={AdminPermissionDescription[permission]}
              control={(
                <Checkbox onChange={onPermissionToggle(permission as AdminPermission)} sx={{ marginLeft: '0.875rem' }} />
              )}
            />
          ))}
        </FormGroup>

        <Box
          sx={{
            mt: 1,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            loading={isSubmitting}
            onClick={onCreate}
          >
            Create Admin
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
