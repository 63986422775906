import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, LinearProgress, Typography } from '@mui/material';

import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { usePaginationModel } from '@backed-fi/hooks';
import { useCompanyUsersQuery } from '@backed-fi/graphql';
import { Title } from '@backed-fi/compound';


gql`
  query companyUsers(
    $page: Int,
    $pageSize: Int,
    $where: UsersFilter
  ) {
    users(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id

        createdAt
        updatedAt

        firstName
        lastName

        email
      }

      page {
        currentPage
        totalNodes
        totalPages
      }
    }
  }
`;

interface Props {
  companyId: string;
}

export const CompanyUsersPage: React.FC<Props> = ({ companyId }) => {
  // ---- Hooks ---- //
  const params = useParams<{ id: string }>();

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();


  // region Networking

  const query = useCompanyUsersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,
      where: {
        companyId: companyId
      }
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  return (
    <Box sx={{ marginBottom: '32px' }}>
      <Box display="flex"
        sx={{
          flexDirection: 'column'
        }}
      >
        <Title title="Users" />
        <DataGrid
          sx={{
            marginTop: '32px'
          }}
          {...pagination}
          autoHeight
          slots={{
            loadingOverlay: LinearProgress
          }}
          loading={loading}
          rows={data?.users?.nodes || []}
          rowCount={data?.users!.page.totalNodes}
          columns={[{
            flex: 1,
            minWidth: 300,
            field: 'name',
            headerName: 'Name',
            renderCell: ({ row }) => (
              <Typography>
                {row.firstName} {row.lastName}
              </Typography>
            )
          }, {
            flex: 1,
            minWidth: 300,
            field: 'email',
            headerName: 'Email',
            renderCell: ({ value }) => (
              <Typography>
                {value}
              </Typography>
            )
          }]}
        />
      </Box>
    </Box>
  );
};

export default CompanyUsersPage;
