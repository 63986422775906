import { FormGroup, PageHeading } from '@backed-fi/compound';
import { BlockchainNetwork, TokenSupportingStructuresQuery, useImportTokenDeploymentMutation, useTokenSupportingStructuresLazyQuery } from '@backed-fi/graphql';
import { Box, TextField } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const Schema = z.object({
  address: z.string()
    .nonempty(),

  network: z.string()
    .transform<BlockchainNetwork>(x => (x as BlockchainNetwork)),

  systemWalletId: z.string()
    .nonempty()
});

const Graph = gql`
  mutation ImportTokenDeployment($input: ImportTokenDeploymentInput!) {
    importTokenDeployment(input: $input) {
      tokenId
    }
  }
`;


export const ImportTokenPage = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- Networking ---- //
  const [importTokenDeployment] = useImportTokenDeploymentMutation();
  const [loadTokenSupportingStructures, { data: tokenSupportingStructures }] = useTokenSupportingStructuresLazyQuery();

  // ---- State ---- //
  const [systemWalletId, setSystemWalletId] = React.useState<string>();

  // ---- Form State ---- //
  const form = useForm<z.infer<typeof Schema>>({ resolver: zodResolver(Schema) });

  // ---- Actions ---- //
  const onDeploymentNetworkSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedNetworkName = e.target.value as BlockchainNetwork;

    form.setValue('network', selectedNetworkName);
    form.resetField('systemWalletId');

    await loadTokenSupportingStructures();
  };

  const onImport = async (input: z.infer<typeof Schema>) => {
    try {
      const { data } = await importTokenDeployment({
        variables: {
          input: input
        }
      });

      navigate(`/blockchain/tokens/details/${data?.importTokenDeployment?.tokenId}/networks`);
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred', {
        variant: 'error'
      });
    }
  };

  // ---- Destructuring ---- //
  const { systemWallets } = (tokenSupportingStructures || {}) as TokenSupportingStructuresQuery;

  return (
    <Box>
      <PageHeading
        title='Import Deployment'
        breadcrumbs={[{
          text: 'Tokens',
          link: '/blockchain/tokens'
        }, {
          text: 'Import'
        }]}
      />

      <FormGroup
        title='Contract information'
        subtitle='Select on which network you the contract is deployed and on what address'
      >
        <TextField
          select
          label='Deployment Network'
          onChange={onDeploymentNetworkSelected}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {Object.keys(BlockchainNetwork).map((network) => {
            return (
              <option key={network} value={network}>
                {network}
              </option>
            );
          })}
        </TextField>

        <TextField
          label='Deployment Address'
          {...form.register('address')}
        />
      </FormGroup>


      <FormGroup
        title='Token Operations'
        subtitle='Configure the operation details of the token in the system'
      >
        <TextField
          select
          fullWidth
          {...form.register('systemWalletId')}
          helperText={
            'The system wallet that will pay for all actions, executed on this network ' +
            'for this token. It will also be used for storing the unpledged tokens'
          }
          SelectProps={{
            native: true
          }}
        >
          <option
            selected
            disabled
          >
            Select Operating Wallet
          </option>

          {systemWalletId ? (
            systemWallets?.filter(x => x.id.toLowerCase() === systemWalletId.toLowerCase())
              .map((systemWallet) => (
                <option value={systemWallet.id} key={systemWallet.id}>
                  {systemWallet.title} ({systemWallet.address})
                </option>
              ))
          ) : (
            systemWallets?.map((systemWallet) => (
              <option value={systemWallet.id} key={systemWallet.id}>
                {systemWallet.title} ({systemWallet.address})
              </option>
            )
            )
          )}
        </TextField>
      </FormGroup>

      <LoadingButton
        onClick={form.handleSubmit(onImport)}
        sx={{
          float: 'right'
        }}
      >
        Import
      </LoadingButton>
    </Box>
  );
};
