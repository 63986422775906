import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

import { PageHeading, Title } from '@backed-fi/compound';
import { IncomingTransactionType, useGenerateSystemWalletMutation, useSystemWalletsListQuery } from '@backed-fi/graphql';
import { InteractionAssetsList } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionAssetsList';
import { useAdminContext } from '../../../context/AdminContext';

gql`
  query systemWalletsList($where: GetSystemWalletsWhereInput!) {
    systemWallets(where: $where) {
      id

      address

      title
      description

      tokenBalances {
        token {
          symbol
        }
        balance {
          balance
          network
        }
      }
    }
  }

  mutation generateSystemWallet {
    createSystemWallet {
      id
    }
  }
`;

interface Props {
  companyId?: string;
}


export const SystemWalletsListPage: React.FC<Props> = ({ companyId }) => {
  const companyCtx = useAdminContext();
  const snackbar = useSnackbar();

  // ---- State ---- //
  const [generating, setGenerating] = React.useState(false);

  // ---- Networking ---- //
  const {
    data,
    loading,
    refetch
  } = useSystemWalletsListQuery({
    variables: {
      where: {
        companyId: companyId ?? companyCtx.id
      }
    }
  });
  const [generateSystemWallet] = useGenerateSystemWalletMutation();

  // ---- Actions ---- //
  const generateWallet = async () => {
    setGenerating(true);

    await generateSystemWallet();

    snackbar.enqueueSnackbar('Wallet was successfully generated', {
      variant: 'success'
    });

    await refetch();

    setGenerating(false);
  };


  // ---- Data destructuring ---- //
  const { systemWallets } = data || {};

  return (
    <Box sx={{ marginBottom: '32px' }}>
      <Box display="flex"
        sx={{
          flexDirection: 'column'
        }}
      >
        <Title title="System Wallets" />
        <DataGrid
          sx={{
            marginTop: '32px'
          }}
          loading={loading}
          rows={systemWallets || []}

          autoHeight

          getRowHeight={({ model }) => {
            return Math.max(
              model.tokenBalances.flatMap((z: { balance: any[] }) => z.balance).length * 50 + 12,
              70
            );
          }}
          columns={[{
            width: 400,
            field: 'address',
            headerName: 'Address'
          }, {
            flex: 2,
            field: 'title',
            headerName: 'Description',
            renderCell: ({ row }) => (
              <Typography>
                {row.title}
              </Typography>
            )
          }, {
            width: 400,
            field: 'tokenBalances',
            headerName: 'Holdings',
            renderCell: ({ row }) => (
              <Box
                sx={{
                  mt: '20px',
                  alignSelf: 'flex-start'
                }}
              >
                <InteractionAssetsList
                  small
                  assets={[
                    ...row.tokenBalances.flatMap((z, i) => z.balance.map((x, i2) => ({
                      ...x,
                      id: `${i * 1000}${i2}`,
                      tokenSymbol: z.token.symbol,
                      amount: x.balance,
                      decimals: 18,
                      assetType: IncomingTransactionType.BackedToken,
                      type: 'Blockchain' as const
                    }))) ?? [
                    ]]}
                />
              </Box>
            )
          }]}
        />
      </Box >
    </Box >
  );
};
