import React from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { DateFormatter, USDFormatter } from '@backed-fi/shared';
import { InfoLabel, InfoLabelCard, LoaderSection } from '@backed-fi/compound';
import { ClientStatisticsQuery, useClientStatisticsQuery } from '@backed-fi/graphql';


gql`query clientStatistics(
  $clientId: ID!
) {
  client(clientId: $clientId) {
    statistics {
      currentValue
      maxValue
      lastOrder
      counter
      fees

      issuances {
        value
        counter
      }

      redemptions {
        value
        counter
      }
    }
  }
  tokens(includeUnpublished: true) {
    id
    name
    symbol
    icon

    statistics(clientId: $clientId) {
      currentValue
      maxValue
      lastOrder
      counter
      fees

      issuances {
        value
        counter
      }

      redemptions {
        value
        counter
      }
    }
  }
}
`;
export const ClientStatisticsPage = () => {
  const params = useParams<{ id: string }>();

  // region Networking
  const { data } = useClientStatisticsQuery({
    variables: {
      clientId: params.id!
    }
  });

  const { tokens, client: { statistics: clientStatistics } } =
    (data || { client: { statistics: {} } } as ClientStatisticsQuery);

  return (
    <Box>
      {
        !data && (
          <LoaderSection
            description='Fetching latest statistics'
          />
        )
      }
      {data && (
        <Box>
          <Grid sx={{ marginBottom: '32px' }} container spacing={2}>
            <Grid item xs={6}>
              <Typography marginRight={1} variant="title">Active Exposure</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Current'
                    sx={{ paddingX: '10px' }}
                    value={USDFormatter.format(clientStatistics.currentValue / 100)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='All-time high'
                    sx={{ paddingX: '10px' }}
                    value={USDFormatter.format(clientStatistics.maxValue / 100)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Last order'
                    sx={{ paddingX: '10px' }}
                    value={DateFormatter.format(new Date(clientStatistics.lastOrder))}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography marginRight={1} variant="title">Issuances</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Total value'
                    sx={{ paddingX: '10px' }}
                    value={USDFormatter.format(clientStatistics.issuances.value / 100)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Total number'
                    sx={{ paddingX: '10px' }}
                    value={clientStatistics.issuances.counter}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography marginRight={1} variant="title">Fees</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Total value'
                    sx={{ paddingX: '10px' }}
                    value={USDFormatter.format(clientStatistics.fees / 100)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography marginRight={1} variant="title">Redemptions</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Total value'
                    sx={{ paddingX: '10px' }}
                    value={USDFormatter.format(clientStatistics.redemptions.value / 100)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoLabelCard
                    label='Total number'
                    sx={{ paddingX: '10px' }}
                    value={clientStatistics.redemptions.counter}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            {Array.from(tokens).map((value, index) => (
              <Card key={index} sx={{
                minWidth: 275,
                maxWidth: 400,
                marginRight: '32px',
                marginBottom: '32px'
              }}>
                <CardHeader
                  avatar={
                    <Avatar src={`/assets/icons/assets/${value.symbol}.svg`} />
                  }
                  title={value.name}
                  subheader={value.symbol}
                />
                <CardContent sx={{ display: 'flex' }}>

                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='titleSmall'>Holdings</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Current'
                        content={USDFormatter.format(value.statistics.currentValue / 100)} />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='All-time high'
                        content={USDFormatter.format(value.statistics.maxValue / 100)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='titleSmall'>Issuances</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Total value'
                        content={USDFormatter.format(value.statistics.issuances.value / 100)} />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Total number'
                        content={value.statistics.issuances.counter} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='titleSmall'>Redemptions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Total value'
                        content={USDFormatter.format(value.statistics.redemptions.value / 100)} />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Total number'
                        content={value.statistics.redemptions.counter} />
                    </Grid> <Grid item xs={12}>
                      <Typography variant='titleSmall'>Fees</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel
                        label='Total value'
                        content={USDFormatter.format((value.statistics.fees) / 100)} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </Box>);
};
