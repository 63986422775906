import React from 'react';
import { Avatar, Box, Grid, Skeleton, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { FlowExecutionDetailsOverviewQuery, FlowType, useFlowExecutionDetailsOverviewQuery } from '@backed-fi/graphql';
import { ContentTile, InfoLabel, Title, TransactionActions } from '@backed-fi/compound';
import { CryptoFormatter, DateTimeFormatter } from '@backed-fi/shared';

// region Graph

gql`
  query flowExecutionDetailsOverview($flowId: String!) {
    flowExecution(
      where: {
        id: $flowId
      }
    ) {
      id

      createdAt

      status
      flowType

      mint {
        amount
        hash
        explorerUrl

        blockchainAddress {
          address
        }

        tokenDeployment {
          network

          token {
            id
            icon
            symbol
            name

            collateral {
              ISINNumber
            }
          }
        }
      }
      burn {
        amount
        hash
        explorerUrl

        tokenDeployment {
          network

          burnAddress

          token {
            id
            icon
            symbol
            name

            collateral {
              ISINNumber
            }
          }
        }
      }

    }
  }
`;


// endregion

const FlowTypeLabel = {
  [FlowType.Mint]: {
    title: "Mint",
    walletLabel: "Destination Wallet",
    action: "+",
  },
  [FlowType.Burn]: {
    title: "Burn",
    walletLabel: "Burn Wallet",
    action: "-",
  },
};


export const FlowExecutionDetailsOverview: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ flowExecutionId: string }>();

  // region Networking

  const { data } = useFlowExecutionDetailsOverviewQuery({
    pollInterval: 30_000,
    variables: {
      flowId: params.flowExecutionId!
    }
  });

  const { flowExecution: rawFlowExecution } = (data || {}) as FlowExecutionDetailsOverviewQuery;

  // endregion

  const flowExecution = React.useMemo(() => {
    if (!rawFlowExecution) {
      return null;
    }

    return {
      ...rawFlowExecution,
      ...(() => {
        switch (rawFlowExecution.flowType) {
          case FlowType.Mint:
            return {
              token: rawFlowExecution.mint!.tokenDeployment.token,
              hash: rawFlowExecution.mint!.hash,
              explorerUrl: rawFlowExecution.mint!.explorerUrl,
              network: rawFlowExecution.mint!.tokenDeployment.network,
              amount: rawFlowExecution.mint!.amount,
              wallet: rawFlowExecution.mint!.blockchainAddress.address
            }
          case FlowType.Burn:
            return {
              token: rawFlowExecution.burn!.tokenDeployment.token,
              hash: rawFlowExecution.burn!.hash,
              explorerUrl: rawFlowExecution.burn!.explorerUrl,
              network: rawFlowExecution.burn!.tokenDeployment.network,
              amount: rawFlowExecution.burn!.amount,
              wallet: rawFlowExecution.burn!.tokenDeployment.burnAddress
            }
          default:
            return null;
        }
      })()
    }
  }, [rawFlowExecution])

  return <Box>
    <Box
      sx={{
        mb: '2rem'
      }}
    >
      {!flowExecution ? (
        <Skeleton
          animation="wave"
          variant="text"
          sx={{
            maxWidth: '350px',
            fontSize: '2.5rem'
          }}
        />
      ) : (
        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="title">
            {flowExecution.flowType}
          </Typography>
        </Box>
      )}
    </Box>
    <Grid container spacing={2}>
      <Grid display='flex' flexGrow={1} item xs={12} xl={6}>
        <ContentTile sx={{
          flexDirection: 'column',
          flexGrow: 1,
        }}>
          <Title title="Activity information"></Title>
          {flowExecution && <Box display='flex' sx={{ flexDirection: 'column' }}>
            <Box>
              <InfoLabel content={flowExecution.id} label='ID' copy></InfoLabel>
            </Box>
            <Box display='flex'>
              <InfoLabel sx={{ flexGrow: '1' }} content={<>
                <TransactionActions hash={flowExecution.hash!} />
              </>} label='Hash'></InfoLabel>
              <InfoLabel sx={{ flexGrow: '1' }} content={<>
                <TransactionActions explorerUrl={flowExecution.explorerUrl!} />
              </>} label='Explorer'></InfoLabel>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <InfoLabel sx={{ flexGrow: '1' }} content={flowExecution.flowType} label='Type'></InfoLabel>
              </Grid>
              <Grid item xs={6}>
                <InfoLabel sx={{ flexGrow: '1' }} content={DateTimeFormatter.format(flowExecution.createdAt)} label="Date"></InfoLabel>
              </Grid>
            </Grid>
          </Box>}
          {!flowExecution && <Skeleton></Skeleton>}
        </ContentTile>
      </Grid>
      <Grid display='flex' flexGrow={1} item xs={12} xl={6}>
        <ContentTile sx={{
          flexDirection: 'column',
          flexGrow: 1,
        }}>
          <Title title="Activity Details"></Title>
          {flowExecution && <Box display='flex' sx={{ gap: '1rem', flexGrow: '1' }}>
            <Box display='flex' flexDirection='column' flexGrow='1'>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => navigate(`/blockchain/tokens/details/${flowExecution.token!.id}/overview`)}
              >
                <InfoLabel label='Token' content={<Box display='flex'>
                  <Avatar
                    sx={{
                      width: '80px',
                      height: '80px'
                    }}
                    alt={flowExecution.token!.symbol}
                    src={flowExecution.token!.icon ?? `/assets/icons/assets/${flowExecution.token!.symbol}.svg`}
                  />
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '16px'
                  }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                      }}
                      flex={1}
                      variant='subtitle'
                    >
                      {flowExecution.token!.symbol}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex'
                      }}
                      flex={1}
                      variant='subtitleSmall'
                    >
                      {flowExecution.token!.name}
                    </Typography>
                  </Box>
                </Box>} />
              </Box>

              <Box>
                <InfoLabel
                  label="Network"
                  content={
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      <Box
                        src={`/assets/icons/assets/native/${flowExecution.network}.svg`}
                        component="img"
                        width={20}
                        height={20}
                      />
                      <Typography
                        sx={{ font: "normal normal normal 14px/22px Inter" }}
                      >
                        {flowExecution.network}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' flexGrow='1'>
              <Box display='flex' flexDirection='column' flexGrow='1'>
                <InfoLabel label={`${FlowTypeLabel[flowExecution.flowType].title} Amount`} content={
                  <>
                    <Typography sx={{ font: "normal normal normal 30px/28px Inter", lineHeight: '64px' }}>
                      {FlowTypeLabel[flowExecution.flowType].action}
                      {CryptoFormatter.format(flowExecution.amount, 18, flowExecution.token!.symbol)}
                    </Typography>
                  </>
                } />
              </Box>
              <Box>
                {
                  flowExecution.wallet && <InfoLabel
                    copy
                    copyValue={flowExecution.wallet}
                    label={FlowTypeLabel[flowExecution.flowType].walletLabel}
                    content={
                      <Typography variant="code">{flowExecution.wallet}</Typography>
                    }
                  />
                }
              </Box>
            </Box>
          </Box>
          }
          {!flowExecution && <Skeleton></Skeleton>}
        </ContentTile>
      </Grid>
    </Grid >
  </Box >
};
