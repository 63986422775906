import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { ClientStatus, FilteringMode, useClientsAutocompleteSearchLazyQuery } from '@backed-fi/graphql';

// region Graph

gql`
  query clientsAutocompleteSearch(
    $where: ClientsFilter
  ) {
    clients(
      pageSize: 10,
      where: $where
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

// endregion

export const ClientSearch: React.FC<Partial<React.ComponentProps<typeof Autocomplete>>> = (props) => {
  const [clientsAutocompleteSearch, { data }] = useClientsAutocompleteSearchLazyQuery();

  return (
    <Autocomplete
      options={data?.clients.nodes ?? []}
      onInputChange={async (e, value) => {
        await clientsAutocompleteSearch({
          variables: {
            where: {
              name: {
                contains: value,
                mode: FilteringMode.Insensitive
              },
              status: ClientStatus.Active
            }
          }
        });
      }}
      getOptionLabel={(option: any) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client"
        />
      )}
      {...props}
    />
  );
};
