import React from "react";

import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

type ComponentProps = {
  label: string;
  active?: boolean;
  complete?: boolean;
  children?: React.ReactNode;
  onBackButtonClick?: () => void;
  onSubmitButtonClick?: () => void;
  submitButtonLabel?: string;
};

const CreateTokenFormTab: React.FC<ComponentProps> = ({
  label,
  active,
  complete,
  children,
  onBackButtonClick,
  onSubmitButtonClick,
  submitButtonLabel,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem",
          justifyContent: "space-between",
          borderTop: "1px solid #EDEFF4",
        }}
      >
        <Typography
          sx={{
            color: "#747B89",
            ...(active && { color: "#121419", fontWeight: 600 }),
          }}
        >
          {label}
        </Typography>
        {!active &&
          (complete ? (
            <CheckCircleIcon sx={{ color: "#0066FF" }} fontSize="small" />
          ) : (
            <RemoveCircleOutlineIcon
              sx={{ color: "#C5C9D3" }}
              fontSize="small"
            />
          ))}
      </Box>
      {active && (
        <Box
          sx={{
            display: "flex",
            gap: "0.8125rem",
            flexDirection: "column",
            padding: "1rem 2rem 2rem",
          }}
        >
          {children}
          <Box
            sx={{
              display: "flex",
              marginTop: "0.625rem",
            }}
          >
            {onBackButtonClick && (
              <Button
                sx={{
                  fontWeight: 400,
                  color: "#747B89",
                  backgroundColor: "#F2F4F8",

                  "&:hover": {
                    color: "#747B89",
                    backgroundColor: "#E2E5EB",
                  },
                }}
                onClick={onBackButtonClick}
              >
                Back
              </Button>
            )}
            {onSubmitButtonClick && (
              <Button sx={{ marginLeft: "auto" }} onClick={onSubmitButtonClick}>
                {submitButtonLabel || "Continue"}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreateTokenFormTab;
