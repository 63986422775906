import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  sx?: SxProps<Theme>;
}

export const ContentTile: React.FC<React.PropsWithChildren<Props>> = ({ children, sx }) => {

  return (
    <Box
      sx={{
        padding: '2rem',
        backgroundColor: '#fff',
        borderRadius: '1rem',
        boxShadow: '0 0.25rem 0.75rem #12141912',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
