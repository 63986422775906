import React from "react";

import { Box, Typography, Link } from "@mui/material";
import CreateTokenForm from "../components/CreateTokenForm";

export const CreateTokenPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100%",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "40rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.75rem",
            backgroundColor: "white",
            boxShadow: "0 2rem 4rem #C5C9D380",
          }}
        >
          <CreateTokenForm />
        </Box>
        <Box
          sx={{
            color: "#747B89",
            padding: "2rem 0",
            textAlign: "center",
          }}
        >
          <Typography fontSize={14}>
            Need help creating a token?{" "}
            <Link
              sx={{ color: "#747B89", textDecorationColor: "currentColor" }}
              href="mailto:support@backed.fi"
            >
              Contact Support
            </Link>
          </Typography >
        </Box >
      </Box >
    </Box >
  );
};
